import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
import AddressFinderAux from '../../../../../src/shared/inputFields/AddressFinderAux'
interface PropsModelsInfo {
  modelData?: any
  conectionsData?: any
  handleChange?: any
  handleComboChange?: any
  handleAddressChange?: any
  sendingOTP?: any
  showVerifyPhoneModal?: any
  showVerifyOTPModalFunc?: any
  handlePhoneChange?: any
}


const ModelsInfo: React.FC<PropsModelsInfo> = ({
  modelData,
  handleChange,
  handleAddressChange,
  sendingOTP,
  showVerifyPhoneModal,
  showVerifyOTPModalFunc,
  handlePhoneChange
}) => {
  const btnRef = React.useRef<any>()
  const { Phone, Address, Address2, State, City, ZipCode } = modelData
  const handleAddressDataChange = ({ value }: any) => {
    handleAddressChange(value)
  }
  const renderPhoneButton = () => {
    const unClickable = sendingOTP === 'Phone' || showVerifyPhoneModal
    return (
      <small
        ref={btnRef}
        className={`input-field-button ${unClickable ? 'text-muted' : ''}`}
        onClick={() => {
          !unClickable && showVerifyOTPModalFunc({ type: 'Phone', sendTo: Phone })
        }}
      >
        Verify
      </small>
    )
  }

  return (
    <FieldsSection header='Inspector Information'>
      <CFormGroup row>
        <CCol xs='12' sm='6'>
          <InputField
            name='FirstName'
            type='text'
            label='First Name'
            value={modelData.FirstName}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' sm='6'>
          <InputField
            name='lastName'
            type='text'
            label='Last Name'
            value={modelData.LastName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup>
        <AddressFinderAux
          address={{ Address,Address2, City, State, ZipCode }}
          handleChange={handleChange}
          handleAddressDataChange={handleAddressDataChange}
        />
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='6'>
          <InputField
            name='Phone'
            type='phone'
            label='Phone Number'
            value={Phone}
            onChange={handlePhoneChange}
            verifyFunc={() => showVerifyOTPModalFunc({ type: 'Phone', sendTo: Phone })}
            showButton
            ButtonComponent={renderPhoneButton}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default ModelsInfo
