import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { MembersRepository } from 'src/repository/admin/member/MembersRepository'
import { OTP_ID } from 'src/services/routeConst'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'

interface PropsEditMemberOTPModal {
  showModal?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}

const fields = ['OtpId', 'Code', 'Method', 'IsConfirmed', 'ConfirmedOn', 'Status']

const EditMemberOtpModal: React.FC<PropsEditMemberOTPModal> = ({
  showModal = false,
  setShowModal,
  modalData,
  callBack = () => {},
}: PropsEditMemberOTPModal) => {
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<any>()
  const { id, UsersId, modelName } = modalData

  const membersRepository = React.useMemo(() => new MembersRepository(), [])

  useEffect(() => {
    const getDataInEffect = async () => {
      try {
        const { mainData } = await membersRepository.getData({
          id,
          modelName: modelName,
          defaultMainData: { UsersId, SocialsId: 0 },
        })
        setData(mainData)
      } catch (error: any) {
        console.error(error)
      }
    }
    loadingData && getDataInEffect()
  }, [UsersId, id, loadingData, membersRepository, modelName])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    data && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    const dataToSave: any = data
    try {
      await membersRepository.saveData({
        modelName: modelName,
        idName: OTP_ID,
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
      })
    } catch (error: any) {
      console.error(error)
    }
    // setData({})
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setData((st: any) => ({ ...st, [name]: value }))
  }

  if (loadingData) {
    return null
  }
  // console.log(data)
  return (
    <CModal
      show={showModal}
      onClose={() => {
        // setData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} OTP</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='OTP Info'>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Code'
                    type='text'
                    label='Code'
                    value={data.Code}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Method'
                    label='Method'
                    value={data.Method}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    disabled={true}
                    name='GeneratedOn'
                    label='GeneratedOn'
                    value={data.GeneratedOn}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='ConfirmedOn'
                    label='ConfirmedOn'
                    disabled={true}
                    value={data.ConfirmedOn}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Status'
                    label='Status'
                    value={data.Status}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Is Confirmed'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Secured'
                  >
                    <CustomSwitch
                      name='IsConfirmed'
                      className='d-block'
                      value={data.IsConfirmed}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            // setData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default EditMemberOtpModal
