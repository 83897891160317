export const SETUSER = 'SETUSER'

export const setUser = (loggedInUser: any) => {
  return {
    type: SETUSER,
    payload: loggedInUser,
  }
}

export const SET_APPLICATION_RETRIEVE = 'SET_APPLICATION_RETRIEVE'

export const setApplicationRetrieve = (applicationRetrieve: any) => {
  return {
    type: SET_APPLICATION_RETRIEVE,
    payload: applicationRetrieve,
  }
}
