import { CCol, CFormGroup } from '@coreui/react'
// import { RadioGroup, Radio } from 'react-radio-input'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange?: any
  title?: string
}
// const RadioGroupTs: any = RadioGroup
const MechInfo = ({ title, modelData }: PropsInfoForm) => {
  const { 
    // CondKeyQuantity,
    // CondInterior,
    // CondExterior,
    // CondTire,
    
    // CondAccidentHadAccident,
    // CondAccidentRepaired,
    // CondAccidentEstimate,
    // CondAccidentRepairedCost,
    // CondAccidentEstimatedCost,
    
    // CondModsEngine,
    // CondModsExhaustEmissions,
    // CondModsRaisedSuspension,
    // CondModsLoweredSuspension,
    
    // CondElectricalRadio,
    // CondElectricalDvdBluRay,
    // CondElectricalNavigation,
    // CondElectricalSunroofMoonroof,
    
    CondMechEngine,
    CondMechTransmission,
    CondMechAircon,
    CondMechHeater,
    CondMechPowerLiftgate,
    CondMechPowerSlidingdoors,

  } = modelData
  // const mapComboToData = () =>

  return (
    <>
      <FieldsSection header={title}>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputLabel label={'Engine'} value={CondMechEngine? CondMechEngine.toString() :'False'}/>
            <InputLabel label={'Transmission'} value={CondMechTransmission? CondMechTransmission.toString() :'False'}/>
            <InputLabel label={'Aircon'} value={CondMechAircon? CondMechAircon.toString() :'False'}/>
            <InputLabel label={'Heater'} value={CondMechHeater? CondMechHeater.toString() :'False'}/>
            <InputLabel label={'Power Liftgate'} value={CondMechPowerLiftgate? CondMechPowerLiftgate.toString() :'False'}/>
            <InputLabel label={'Power Sliding doors'} value={CondMechPowerSlidingdoors? CondMechPowerSlidingdoors.toString() :'False'}/>
          </CCol>
        </CFormGroup>

      </FieldsSection>
    </>
  )
}

export default MechInfo