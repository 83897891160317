import HeaderComponent from './Header'
import TabsInformation from './TabsInformation'
import TabsLeadInformation from './TabsLeadInformation'
import ConditionsInfo from './Conditions/ConditionsInfo'
import ExceptionsInfo from './Exceptions/ExceptionsInfo'
import ServicesInfo from './Services/ServicesInfo'
import LeadsInfo from './Leads/LeadsInfo'
// import InspectorInfo from './InspectorInfo'
// import Calendar from './Calendar'

export { 
  HeaderComponent,
  TabsLeadInformation, 
  TabsInformation,
  ConditionsInfo,
  ExceptionsInfo,
  ServicesInfo,
  LeadsInfo
  
  // InspectorInfo,
  // Calendar
}
