import React from 'react'
import { FieldsSection } from 'src/shared'
import AddressFinderAux from 'src/shared/inputFields/AddressFinderAux'
interface PropsApplicantsAddress {
  memberData?: any
  handleChange?: any
  handleAddressChange?: any
}
const ApplicantsAddress: React.FC<PropsApplicantsAddress> = ({
  memberData,
  handleChange,
  handleAddressChange,
}) => {
  const { Address, Address2, State, City, ZipCode } = memberData

  const handleAddressDataChange = ({ value }: any) => {
    handleAddressChange(value)
  }

  return (
    <FieldsSection header='Applicant Address'>
      <AddressFinderAux
        address={{ Address, Address2, City, State, ZipCode }}
        handleChange={handleChange}
        handleAddressDataChange={handleAddressDataChange}
      />
    </FieldsSection>
  )
}
export default React.memo(ApplicantsAddress)
