import React from 'react'

const RedX = ({ width = '1.75rem', height = '1.75rem' }) => {
  return (
    // <svg
    //   version='1.1'
    //   id='Layer_1'
    //   xmlns='http://www.w3.org/2000/svg'
    //   x='0px'
    //   y='0px'
    //   width={width}
    // height={height}
    // viewBox='0 0 122.879 122.879'
    // enableBackground='new 0 0 122.879 122.879'
    // >
    //   <g>
    //     <path
    //       fillRule='evenodd'
    //       clipRule='evenodd'
    //       fill='#FF4141'
    //       d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'
    //     />
    //   </g>
    // </svg>
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 122.879 122.879'
      enableBackground='new 0 0 122.879 122.879'
    >
      <g>
        <title>background</title>
        <rect fill='none' id='canvas_background' height='514' width='514' y='-1' x='-1' />
      </g>
      <g>
        <title>Layer 1</title>
        <ellipse
          ry='35.962145'
          rx='35.33123'
          id='svg_3'
          cy='62.859057'
          cx='60.808594'
          strokeWidth='0.001'
          stroke='#FF4141'
          fill='#ffffff'
        />
        <g id='svg_1'>
          <path
            id='svg_2'
            d='m61.44,0c33.933,0 61.439,27.507 61.439,61.439s-27.506,61.439 -61.439,61.439c-33.933,0.001 -61.44,-27.506 -61.44,-61.439s27.507,-61.439 61.44,-61.439l0,0zm12.011,39.151c2.75,-2.793 7.221,-2.805 9.986,-0.027c2.764,2.776 2.775,7.292 0.027,10.083l-12.064,12.238l12.076,12.249c2.729,2.77 2.689,7.257 -0.08,10.022c-2.773,2.765 -7.23,2.758 -9.955,-0.013l-11.995,-12.163l-12.018,12.188c-2.75,2.793 -7.22,2.805 -9.986,0.027c-2.763,-2.776 -2.776,-7.293 -0.027,-10.084l12.065,-12.237l-12.077,-12.249c-2.728,-2.769 -2.689,-7.256 0.082,-10.022c2.772,-2.765 7.229,-2.758 9.953,0.013l11.997,12.165l12.016,-12.19l0,0z'
            fill='#FF4141'
            clipRule='evenodd'
            fillRule='evenodd'
          />
        </g>
      </g>
    </svg>
  )
}

export default RedX
