// Generated with util/create-search-page.js
import { CButton, CCardHeader } from '@coreui/react'
// import LoadingButton from 'src/shared/components/LoadingButton'

const QueriesHeader = ({ onCancel }) => {
  return (
    <CCardHeader className='d-flex align-items-center card-accent-secondary'>
      Querie Details
      <CButton
        className='mr-1 ml-auto mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancel}
      >
        Cancel
      </CButton>
    </CCardHeader>
  )
}

export default QueriesHeader
