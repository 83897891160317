import { CButton, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, CUSTOMERS_MODEL, MODELS, SERVICES, LIST } from 'src/services/routeConst'
import { routeToPage } from 'src/services/routingHelper'
import { DeleteModal } from 'src/shared'
import { TableColumns } from './CustomersTableColumns'
import { ModalNewCustomer } from './components'

const ModelSearch: React.FC<any> = ({ history, modelName = CUSTOMERS_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const clickedIDRef = useRef()
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g)!.join(' ')
  const tableRef = useRef<any>()

  const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Operations/Customers/Detail/${id}`)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <>
      <CCardHeader
        className='d-flex align-items-center'
        style={{
          backgroundColor: '#2A3446',
          marginBottom: '1rem',
          fontSize: 16,
          fontWeight: 600,
          borderRadius: 0,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ color: 'white' }}>{title} Search</span>
        <CButton
          color='primary'
          onClick={() => {
            setShowModal((value) => !value)
          }}
        >
          New Customer
        </CButton>
      </CCardHeader>
      <CCardBody className={'custom-card-body'}>
        <DataTable
          columnData={TableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={modelName}
          actionClick={(type: any, data: any) => {
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          paginationPosition='bottom-right'
          extension='ByGroupStatus'
          queryParams={[{Name: "GroupName", Value: "Completed"}]}
        />
      </CCardBody>
      <ModalNewCustomer
        history={history}
        show={showModal}
        onClose={() => {
          setShowModal(false)
        }}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'User', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </>
  )
}

export default ModelSearch
