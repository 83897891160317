// import { ComboModel } from 'src/services/modelNew/Combo/Combo'
import { RootRepository } from '../rootRepository'

export class MembersRepository extends RootRepository {
  // async getData({ id, modelName }) {
  //   const { data } = await ComboModel({ modelName: 'Roles' })
  //   return await super.getData({
  //     id,
  //     modelName,
  //     defaultMainData: { UsersId: 0, RolesId: '1' },
  //     defaultSubData: data,
  //   })
  // }
}
