import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, MODELS, MODEL_COMBO } from 'src/services/routeConst'
interface PropsComboModel {
  modelName?: string
  postData?: any
}
export const ComboModel = async ({ modelName = '', postData }: PropsComboModel) => {
  //URL {api}/Models/Combo/{ModelName}
  try {
    const { data } = await axios.post(
      `${BASE_API}:${MODELS}/${MODEL_COMBO}/${modelName}`,
      postData,
      {
        ...addAuthToHeader('ADMIN'),
      },
    )
    return {
      data: data.data,
      status: 'success',
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error getting ${modelName} list!`,
      error: error.response,
    })
  }
}
