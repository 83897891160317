import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  CCreateElement,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  // CSidebarNavDropdown,
  // CSidebarNavItem,
  // CSidebar,
} from '@coreui/react'
import { setSidebarShow } from '../redux/actions/SidebarActions'
import { CSidebarCustom, CSidebarNavDropdownCustom, CSidebarNavItemCustom } from 'src/shared'
import TheFooter from './TheFooter'
//import axios from 'axios'
import { BASE_API, SERVICES, WIDGETS } from 'src/services/routeConst'
//import { addAuthAccessTokenToHeader, logoutVerifyErrors } from 'src/services/authHelper'
//import authReducer from 'src/redux/reducers/authReducer'
// import CSidebarMinimizer from 'src/shared/components/CSidebarMinimizer/CSidebarMinimizer'

const TheSidebar: React.FC<any> = (props: any) => {
  const { applicationRetrieve = {} } = props
  const { LogoGuid } = applicationRetrieve
  const itemsSidebar = props.items
 // const [appData, setAppData] = useState({})
  const components: any = {
    CSidebarNavDivider,
    CSidebarNavDropdown: CSidebarNavDropdownCustom,
    CSidebarNavItem: CSidebarNavItemCustom,
    CSidebarNavTitle,
  }

  //let location = window.location.href;
  // let location = 'https://jemadmin.ss'
  // let location = 'https://quantumadmin.aaadev.info'
  // let location = 'dispatchadmin.aaadev.info'
  // console.log('location pathname',location) 

  // const fetchAppData = async () => {
  //   try {
  //     const execData = await axios.post(
  //       `${BASE_API}${SERVICES}/data/execute`,
  //       {
  //         // [idName]: Id,
  //         DbName: 'DbAdmin',
  //         QueryOrProcedureName: "access.APPLICATIONS_Retrieve",
  //         // Id: id,
  //         // Extensions: body.Extension ?? null,
  //         Params: [
  //           {
  //             Name: "UrlRoot", Value: location
  //           }
  //         ],
  //       },
  //       { ...addAuthAccessTokenToHeader() },
  //     )
  //     console.log("DATA EXECUTE", execData);
      
  //     const {data} = await axios.post(
  //       `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
  //       {
  //         // [idName]: Id,
  //         Model: 'Applications',
  //         Id: execData.data.data.Execution[0].Id,
  //         // Extensions: body.Extension ?? null,
  //         Params: [
  //           // Name: "FilesId_Avatar", Value: data.data?.FileInfo?.Id
  //         ],
  //       },
  //       { ...addAuthAccessTokenToHeader() },
  //     )
  //     setAppData(data.data.RetrieveInfo[0])

  //     console.log("RETRIEVE EXECUTE", data);

  //   } catch (error: any) {
  //     const status = error.response.status 
  //     logoutVerifyErrors(status)
  //   }
  // }

  useEffect(() => {
   // fetchAppData()
  }, [])
  

  return (
    <CSidebarCustom
      show={props.show}
      onShowChange={(val: any) => {
        props.setSidebarShow(val)
      }}
    >
      <CSidebarBrand className='d-md-down-none' to='/'>
        {/* <img
          src='/admin/default-logo.png'
          alt=''
          width={180}
          style={{ padding: '20px' }}
          className='c-sidebar-brand-full'
        /> */}
        {/* Logo CoreUi React .js */}
        {/* <CIcon
          className='c-sidebar-brand-full'
          name='logo-negative'
          height={35}
        />
        
         */}
        <img
          src={`${BASE_API}${SERVICES}${WIDGETS}/Files/Download/${LogoGuid || ''}`}
          style={{width: '150px',maxWidth:'180px' }}
          alt=''
        />
      </CSidebarBrand>
      <CSidebarNav style={{ borderTop: '1px solid red' }}>
        <CCreateElement items={itemsSidebar} components={components} />
      </CSidebarNav>
      <TheFooter show />
      {/* <CSidebarMinimizer className='c-d-md-down-none' /> */}
    </CSidebarCustom>
  )
}

const mapStateToProps = (state: any) => {
  const { sidebar, auth } = state
  return {
    show: sidebar.sidebarShow,
    applicationRetrieve : auth.applicationRetrieve
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSidebarShow: (data: any) => dispatch(setSidebarShow(data)),
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TheSidebar))
