import React from 'react'
import { CTabPane } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, MODELS, SCENARIOS_MODEL } from 'src/services/routeConst'
// import { FieldsSection } from 'src/shared'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import { scenariosTableColumns } from '../endpointsTableColumns'
interface PropsRolesDetailTabSection {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  Guid?: any
}
const RolesDetailTabSection: React.FC<PropsRolesDetailTabSection> = ({
  id,
  modelName,
  actionFunctions,
  tableRef,
  changesRef,
  Guid,
}) => {
  return (
    <DetailTabSection
      id={id}
      Guid={Guid}
      modelName={modelName}
      tabs={[{ name: 'DataTable', label: 'Test Scenarios' }]}
      changesRef={changesRef}
    >
      <CTabPane data-tab='DataTable' className='detail-tab-section-pane'>
        {/* <FieldsSection header='Test Scenarios'> */}
        <DataTable
          columnData={scenariosTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={SCENARIOS_MODEL}
          idName='ScenariosId'
          actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          rootStyles={{ fontSize: '0.75rem' }}
          apiKey={getAuthToken('ADMIN')}
        />
        {/* </FieldsSection> */}
      </CTabPane>
    </DetailTabSection>
  )
}

export default RolesDetailTabSection
