export const TableColumns = [
  {
    label: 'Name',
    name: 'CustomerName',
  },
  {
    label: 'Mobile',
    name: 'CustomerMobile',
  },
  {
    label: 'City',
    name: 'CustomerCity',
  },
  {
    label: 'State',
    name: 'CustomerState',
  },
  {
    label: 'Zip',
    name: 'CustomerZip',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
