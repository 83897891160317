import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { InputField } from 'src/shared'
// import AddressFinder from 'src/shared/inputFields/AddressFinder'
interface PropsMemberAddress {
  modelData?: any
  handleChange?: any
  handleAddressChange?: any
  modelName?: string
}
const AddressComponent = ({ handleChange, modelData }: PropsMemberAddress) => {
  // const [state, setState] = React.useState({
  //   Address: modelData.Address || '',
  //   State: modelData.State || '',
  //   City: modelData.City || '',
  //   Zip: modelData.Zip || '',
  // })

  // modelData.Address = state.Address
  // modelData.State = state.State
  // modelData.City = state.City
  // modelData.Zip = state.Zip

  return (
    <div style={{ marginRight: '15px' }}>
      {/* <AddressFinder
        address={state}
        modelData={modelData}
        handleAddressDataChange={(data: any) => {
          setState({ ...data.value })
        }}
        handleChange={(data: any) => {
          setState((state) => ({ ...state, Address: data.value }))
        }}
      /> */}
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='Address'
            type='text'
            label='Address '
            value={modelData?.Address ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='City'
            type='text'
            label='City'
            value={modelData?.City ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='3' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='State'
            type='text'
            label='State'
            value={modelData?.State ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='3' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='Zip'
            type='text'
            label='Zip'
            value={modelData?.Zip ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </div>
  )
}
export default React.memo(AddressComponent)
