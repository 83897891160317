// Generated with util/create-search-page.js
import { CButton, CCardHeader } from '@coreui/react'

const ProceduresHeader = ({ onCancel }) => {
  return (
    <CCardHeader className='d-flex align-items-center card-accent-secondary'>
      Procedure Details
      {/* <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton> */}
      <CButton
        className='mr-2 mr-1 ml-auto'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancel}
      >
        Cancel
      </CButton>
    </CCardHeader>
  )
}

export default ProceduresHeader
