import { ColumnData } from 'library-soft-datatable'

export const TableColumns: ColumnData[] = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: 'Cost',
    name: 'Cost',
    type: 'currency',
    customWidth: 10,
  },
  {
    label: 'Price',
    name: 'Price',
    type: 'currency',
    customWidth: 10,
  },
  {
    label: 'Cost Measure',
    name: 'CostMeasure',
    type: 'text',
    customWidth: 10,
  },
  {
    label: 'Work Hours',
    name: 'WorkHours',
    type: 'number',
    customWidth: 10,
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    customWidth: 10,
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
