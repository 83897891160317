// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { addAuthAccessTokenToHeader, logoutVerifyErrors } from 'src/services/authHelper'
import { BASE_API, DELETE, LIST, MODELS, SERVICES } from 'src/services/routeConst'
import Avatar from 'src/shared/components/Avatar/Avatar'
import { CostHeader } from '.'
// import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const CostServicesInfo = ({
  modelData,
  title = 'Model Name',
}: PropsInfoForm) => {
  const [customersCosts, setCustomersCosts] = useState([])
  
  const [stateCostAdd, setStateCostAdd] = useState(false)

  const fetchCustomerCosts = async () => {
    try{
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}${LIST}`,
        {
          // DbName: "DbOltp",
          // QueryOrProcedureName: "deal.CUSTOMERS_TRANS_List",
          Model: "CustomersCosts",
          Extension: null,
          Params: [
              {
                  "Name": "CustomersId",
                  "Value": modelData['Id']
              }
          ]
        },
        { ...addAuthAccessTokenToHeader() }
      );
      data.data && setCustomersCosts(data.data)
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }
  const deleteCost = async (id) => {
    try{
      await axios.delete(
        `${BASE_API}${SERVICES}${MODELS}/${DELETE}`,
        {
          data:{
            Id: id,
            Model: 'CustomersCosts',
            // Extensions: body.Extension ?? null,
            Params: [],
          },
          headers: {
            Authorization: addAuthAccessTokenToHeader().headers.Authorization
          }
        },
      )
      fetchCustomerCosts()
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }
  useEffect(() => {
    // fetchServices()
    fetchCustomerCosts()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateCostAdd, setStateCostAdd])

  const CostInput = (Cost) => {
    // const found = customerServices.find(element => element['ServicesId'] === Service['Id'] ) || {id:0};

    return (
        <div className='customer-costs-card'>
          <div className="item-avatar">
            {/* <span style={{ border: 'solid 1px #ddd', borderRadius: '100%' }}> */}
              <Avatar name={Cost['Inspector'] || ' '} FilesId_Avatar={0}></Avatar>
            {/* </span> */}
          </div>
          <div className='item-name'>
            {Cost['Date']}
          </div>
          <div className='item-price'>
            <i>
              ${ Cost['Amount'] ? Cost['Amount'].toFixed(2) : `0.00`}
            </i>
          </div>
          <div className='item-description'>
            {Cost['Description']}
          </div>
          <div className='item-inspector'>
            {Cost['Inspector']}
          </div>
          <div className='c-sidebar-brand-full item-button'>
            <img
              src='/admin/delete-mark.png'
              alt=''
              width={10}
              height={10}
              onClick={()=>deleteCost(Cost['Id'])}
            />
          </div>
        </div>
      )
    }
  const renderAllCosts = customersCosts.map(( Cost ) => {
    return (
      <div key={Cost['Id']}>
        {CostInput(Cost)}
      </div>
    )
  })

  const AmountTotal = () => {
    if (customersCosts[0]) return (customersCosts[0]['TotalAmount'])
    return 0;    
  }

  return (
    <CostHeader header={title} customersId={modelData['Id']} setStateAdd={setStateCostAdd} stateAdd={stateCostAdd} totalAmount={AmountTotal()}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
         {renderAllCosts}
        </CCol>
      </CFormGroup>
    </CostHeader>
    )
  }
  
export default CostServicesInfo
