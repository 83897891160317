import imageCompression from 'browser-image-compression'
import { FileUpload } from 'src/services/modelNew/File/Upload'

abstract class FileServer {
  abstract metasId: string
  abstract process(): Promise<File>
}
export class Image extends FileServer {
  private file: File
  public metasId: string
  constructor(file: File, metasId: string) {
    super()
    this.file = file
    this.metasId = metasId
    this.validateFile(this.file, new RegExp('\\.(jpe?g|tiff?|png|webp|heic|heif|jpg)$', 'i'))
  }
  validateFile(image: File, regex: RegExp) {
    if (!regex.test(image.name)) {
      throw new Error('The file is not an image')
    }
  }
  async compressImage(image: File) {
    const compressedFile = await imageCompression(image, {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
      maxIteration: 15,
    })
    return compressedFile
  }
  async process(): Promise<File> {
    const compressedImage = await this.compressImage(this.file)
    const fileToUpload = new File([compressedImage], `${Date.now().toString()}-${this.file.name}`, {
      type: this.file.type,
    })
    return fileToUpload
  }
}
export class AnyFile extends FileServer {
  private file: File
  public metasId: string
  constructor(file: File, metasId: string) {
    super()
    this.file = file
    this.metasId = metasId
  }

  async process(): Promise<File> {
    return this.file
  }
}

export class UploadFileServer {
  static async uploadServer(file: FileServer) {
    const fileProcessed = await file.process()
    await FileUpload({
      FilesId: 0,
      fileToUpload: fileProcessed,
      MetasId: file.metasId,
    })
  }
}
