import { ColumnData } from 'library-soft-datatable'

export const TableColumns: ColumnData[] = [
  {
    label: 'First Name',
    name: 'FirstName',
  },
  {
    label: 'Last Name',
    name: 'LastName',
  },
  {
    label: 'Hourly Rate',
    name: 'HourlyRate',
    type: 'currency',
    customWidth: 8
  },
  {
    label: 'City',
    name: 'City',
  },
  {
    label: 'Timezone',
    name: 'Timezone',
  },
  {
    label: 'State',
    name: 'State',
    customWidth: 6,
  },
  {
    label: 'Phone Mobile',
    name: 'PhoneMobile',
    customWidth: 10,
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    customWidth: 10,
    sortable: false,
  },
]
