// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { InputField, ComboBox } from 'src/shared'
import { useState, useEffect, useCallback } from 'react'
import { ListModel } from 'src/services/modelNew/List/List'

const FacilitiesInfo = ({ modelData, handleChange, usersData = [] }) => {
  const { Code, Name, City, StateCode, ZipCode, Phone, UsersList } = modelData
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [theUsersData, setTheUsersData] = useState<any>({ CodeId: 0, Description: '' })

  const fetchUsersList = useCallback<any>(async () => {
    const { data } = await ListModel({
      body: {
        Model: 'AdminUsers',
        Extension: null,
        Params: {
          ApplicationsId: 131,
        },
      },
    })
    let add = data.map((route) => {
      return {
        CodeId: route.Id,
        Description: route.FirstName,
      }
    })
    setTheUsersData(add)
  }, [])

  // const mapComboCheckData = () =>
  // usersData.map((route) => {
  //     return {
  //       CodeId: route.Id,
  //       Description: route.FirstName,
  //     }
  // })

  const handleComboChangeCheck = (e: any, newValue: any = {}) => {
    if (!e.target) return
    setSelectedUsers(newValue)
    var commaSeparated = newValue
      .map((item) => {
        return item.CodeId
      })
      .join(',')
    handleChange({ name: 'UsersList', value: commaSeparated })
  }

  useEffect(() => {
    fetchUsersList()
    if (modelData.Id != 0) {
      const splitUsers = UsersList.split(',')
      const filterUsersArray = async () => {
        const myArrayFiltered = await usersData.filter((el) => {
          return splitUsers.some((f) => {
            return el.Id == f
          })
        })

        const myF = myArrayFiltered.map((route) => {
          return {
            CodeId: route.Id,
            Description: route.FirstName,
          }
        })
        setSelectedUsers(myF)
      }
      filterUsersArray()
    }
  }, [])

  return (
    <>
      <CFormGroup row>
        <CCol xs='6' sm='6'>
          <InputField
            name='Code'
            type='text'
            label='Code'
            value={Code ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='6' sm='6'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={Name ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='6' sm='6'>
          <InputField
            name='City'
            type='text'
            label='City'
            value={City ?? ''}
            onChange={handleChange}
          />
        </CCol>

        <CCol xs='6' sm='6'>
          <InputField
            name='StateCode'
            type='text'
            label='State Code'
            value={StateCode ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol xs='6' sm='6'>
          <InputField
            name='ZipCode'
            type='text'
            label='Zip Code'
            value={ZipCode ?? ''}
            onChange={handleChange}
          />
        </CCol>

        <CCol xs='6' sm='6'>
          <InputField
            name='Phone'
            type='phone'
            label='Phone'
            value={Phone ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <ComboBox
            showButton
            multiple
            name='UsersList'
            label='Users'
            data={theUsersData}
            //value={usersSelectedData ?? []}
            value={selectedUsers ?? []}
            handleChange={handleComboChangeCheck}
            classNames={{ input: 'input-field' }}
            customClassNames={{
              button: 'comboBoxInput',
              root: 'input-field-root upscaled custom-multi',
            }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
            )}
          />
        </CCol>
      </CFormGroup>
    </>
  )
}

export default FacilitiesInfo
