import { CCol, CRow } from '@coreui/react'
// import axios from 'axios'
import React from 'react'
// import { parseBooleans } from 'src/services/util'
import MemberCard from './MemberCard'
interface PropsMemberCardsSection {
  id?: any
  memberData?: any
  showEditModalFunc?: any
  modelName?: any
}
const MemberCardsSection: React.FC<PropsMemberCardsSection> = ({ memberData }: any) => {
  // const [memberCards, setMemberCards] = React.useState({})

  // React.useImperativeHandle(ref, () => ({
  //   fetchNewData() {
  //     fetchData()
  //   },
  // }))

  // const fetchData = async () => {
  //   const { data } = await ListModel({ modelName: 'Card' })
  //   console.log(data)
  //   setMemberCards(data)
  // }

  // React.useEffect(() => {
  //   fetchData()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // console.log(memberCards)
  const {
    CardColor = '',
    CardCvv = '',
    CardExpiration = '',
    CardName = '',
    CardNumber = '',
    CardStyle = '',
  } = memberData
  const memberCards: any = [{ CardColor, CardCvv, CardExpiration, CardName, CardNumber, CardStyle }]
  return !memberCards.length ? (
    <h5 className='text-center mt-4'>No Cards Found</h5>
  ) : (
    <CRow style={{ justifyContent: 'center' }} className='px-xxl-5'>
      {memberCards.map((idx: any) =>
        !memberCards.hidden ? (
          <CCol xs='12' md='6' key={idx}>
            <MemberCard background='' />
          </CCol>
        ) : null,
      )}
    </CRow>
  )
}

export default MemberCardsSection
