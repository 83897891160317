import React from 'react'
import { CCol, CFormGroup, CTabPane } from '@coreui/react'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
// import { LeadContext } from '../Detail'
import {
  LeadsInfo,
  ConditionsInfo,
  ExceptionsInfo,
  TabsInformation,
} from './index'

// import {
//   AccidentHistoryInfo,
//   ElectricalInfo,
//   MechanicalIssuesInfo,
//   ModificationsInfo,
//   VehicleConditionsInfo,
//   VehicleInfo,
// } from '../components/VehiclesServices'
// import OwnershipInfo from './OwnershipInfo'
// import CustomerServicesInfo from './CustomerServicesInfo'
// import CustomerFiles from './Files/Search'
// import { InvoiceServicesInfo } from './InvoicesServices'
// import { CostServicesInfo } from './CostsServices'
// import CustomerServiceTable from './CustomerService/CustomerServiceTable'
// import WorkFlow from 'src/pages/Workflow/Workflow/Search'
// import Breadcrumb from 'src/pages/Workflow/Workflow/components/Bread'
interface PropsLeadsServices {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  modelData?: any
  modelDataVehicle?: any
  modelDataDispatch?: any
  Guid?: any
  handleChange?: any
  reloadNote?: any
  handleChangeMaxLength?: any
  handleAddressChange?: any
}
const LeadsServices: React.FC<PropsLeadsServices> = ({
  // id,
  // modelName,
  // changesRef,
  // Guid,
  modelData,
  // modelDataVehicle,
  // modelDataDispatch,
  handleChange,
  reloadNote,
  // handleChangeMaxLength,
  // handleAddressChange,
}) => {
  // const { MetasId } = useContext(LeadContext)
  // const { SourceName } = modelData
  // const actionClick = () => {}
  
  return (
    <>
      {/* <WorkFlow customerId={modelData.Id} /> */}
      <CFormGroup row>
        <CCol md={8} className='pr-lg-2 pl-lg-2 mb-1'>
        <DetailTabSection
                id={0}
                MetasId={modelData.MetasId}
                Guio={0}
                modelName={''}
                hideNotes
                tabs={[
                  { name: 'LeadInfo', label: 'Lead Info' },
                  { name: 'Conditions', label: 'Conditions' },
                  { name: 'Exceptions', label: 'Exceptions' },
                ]}
              >
                <CTabPane data-tab='LeadInfo' className='detail-tab-section-pane'>
                  <LeadsInfo
                    title={'Details'}
                    modelData={modelData}
                    handleChange={handleChange}
                  />
                </CTabPane>
                <CTabPane data-tab='Conditions' className='detail-tab-section-pane'>
                  <ConditionsInfo
                    title={'Conditions'}
                    modelData={modelData}
                    handleChange={handleChange}
                  />
                </CTabPane>
                <CTabPane data-tab='Exceptions' className='detail-tab-section-pane'>
                  <ExceptionsInfo
                    title={'Exceptions'}
                    modelData={modelData}
                    handleChange={handleChange}
                  />
                </CTabPane>
          
        </DetailTabSection>
        </CCol>
        <CCol xs={4} style={{paddingTop: '1.1rem'}}>
          { (reloadNote) ? <TabsInformation modelData={modelData} /> : <div className='mt-5 text-dark text-center font-bold'>Loading...</div>}
        </CCol>
      </CFormGroup>
    </>
  )
}

export default LeadsServices
