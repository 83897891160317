import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { RootRepository } from 'src/repository/admin/rootRepository'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
import { COLUMNS_MODEL } from 'src/services/routeConst'
import { ProcImportColumn } from 'src/services/modelNew/ProcGen/Imports/ProcColumn'

const fields = [
  'ColumnsId',
  'ModelsId',
  'Name',
  'Type',
  'Size',
  'OrderIndex',
  'IsPrimary',
  'IsIdentity',
  'Seed',
  'Increment',
  'IsEncrypted',
]
interface PropsEditTableFieldModal {
  showModal?: any
  modelName?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}
const EditTableFieldModal: React.FC<PropsEditTableFieldModal> = ({
  showModal = false,
  modelName = COLUMNS_MODEL,
  setShowModal,
  modalData,
  callBack = () => {},
}) => {
  const [status, setStatus] = useState('loading')
  const [apiData, setApiData] = useState<any>({ fieldData: null })
  const { id, ModelsId } = modalData

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(async () => {
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { ModelsId, ColumnsId: 0 },
      })
      setApiData({ fieldData: mainData })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }, [ModelsId, id, modelName, rootRepository])
  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    const IsEncrypted = apiData.fieldData?.IsEncrypted ?? false
    await rootRepository.saveData({
      modelName,
      idName: 'ColumnsId',
      dataToSave: Object.assign(
        {},
        ...fields.map((field) => ({ [field]: apiData.fieldData[field] })),
      ),
    })
    await ProcImportColumn({
      procType: 'EncryptedColumn',
      params: {
        IsEncrypted,
        ColumnsId: id,
      },
    })
    // setApiData({ fieldData: null })
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setApiData((st: any) => ({ ...st, fieldData: { ...st.fieldData, [name]: value } }))
  }

  const handleSwitchChange = (name: any, value: any) => {
    const newValue = [1, true, 'True'].includes(value) ? 1 : 0
    let newData: any = { [name]: newValue }
    if (!newValue && name === 'IsPrimary') {
      newData.IsIdentity = 0
      newData.Seed = 0
      newData.Increment = 0
    } else if (!newValue && name === 'IsIdentity') {
      newData.Seed = 0
      newData.Increment = 0
    }
    // console.log(name, newValue, newData)
    setApiData((st: any) => ({
      ...st,
      fieldData: {
        ...st.fieldData,
        ...newData,
      },
    }))
  }

  if (!apiData.fieldData || status === 'loading') {
    return null
  }

  const { fieldData } = apiData
  return (
    <CModal
      show={showModal}
      onClose={() => {
        // setApiData({ fieldData: null })
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} Field</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='Field Info'>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Name'
                    type='text'
                    label='Name'
                    value={fieldData.Name}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Type'
                    type='text'
                    label='Type'
                    value={fieldData.Type}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Size'
                    type='text'
                    label='Size'
                    value={fieldData.Size}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='OrderIndex'
                    type='text'
                    label='OrderIndex'
                    value={fieldData.OrderIndex}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField name='IsPrimary' type='switch' label='Primary Key'>
                    <CustomSwitch
                      name='IsPrimary'
                      className='d-block'
                      value={fieldData.IsPrimary}
                      updateData={handleSwitchChange}
                    />
                  </InputField>
                </CCol>
              </CFormGroup>
              {fieldData.IsPrimary ? (
                <CFormGroup row>
                  <CCol xs='12' sm='12'>
                    <InputField name='IsIdentity' type='switch' label='Identity'>
                      <CustomSwitch
                        name='IsIdentity'
                        className='d-block'
                        value={fieldData.IsIdentity}
                        updateData={handleSwitchChange}
                      />
                    </InputField>
                  </CCol>
                </CFormGroup>
              ) : null}
              {fieldData.IsIdentity ? (
                <>
                  <CFormGroup row>
                    <CCol xs='12' sm='6' className='mb-3 mb-lg-0 pr-lg-1'>
                      <InputField
                        name='Seed'
                        label='Seed'
                        type='number'
                        value={fieldData.Seed}
                        onChange={handleChange}
                      />
                    </CCol>
                    <CCol xs='12' sm='6' className='pl-lg-1'>
                      <InputField
                        name='Increment'
                        label='Increment'
                        type='number'
                        value={fieldData.Increment}
                        onChange={handleChange}
                      />
                    </CCol>
                  </CFormGroup>
                </>
              ) : null}
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField name='IsEncrypted' type='switch' label='Is Encrypted'>
                    <CustomSwitch
                      name='IsEncrypted'
                      className='d-block'
                      value={fieldData.IsEncrypted}
                      updateData={(e, value) => {
                        handleChange({
                          name: e,
                          value,
                        })
                      }}
                    />
                  </InputField>
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            // setApiData({ fieldData: null })
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default EditTableFieldModal
