// Generated with util/create-search-page.js
import {
  CButton,
  CCardHeader,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
// import { useState } from 'react'

import LoadingButton from 'src/shared/components/LoadingButton'
import CustomDateField from 'src/shared/inputFields/CustomDateField'
// import Confirmation from '../Confirmation'
// import Under18Form from '../Under18Form'
interface PropsHeaderComponent {
  onCancelHandle: any
  status: any
  saveData: any
  modelName: any
  modelData: any
  handleChange: any
  jsonChange: any
  statusData?: any
  facilitiesData?: any
}
const HeaderComponent = ({
  onCancelHandle,
  status,
  saveData,
  modelData,
  modelName = 'Model Name',
  handleChange,
  statusData = [],
  facilitiesData = [],
}: // jsonChange
PropsHeaderComponent) => {
  const isLoading = status === 'saving'
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g).join(' ')
  // const [showModal, setShowModal] = useState(false);
  // const [showModalUnder18, setShowModalUnder18] = useState(false);
  let { FollowUpDate } = modelData
  // let JsonForm = JSON.parse(modelData.JsonForm);

  const formatDate = (date) => {
    if (date) {
      date = date.split('-')
      const day = String(date[0])
      const month = String(date[1])
      const year = String(date[2])
      if (day.length === 2) {
        return `${year}-${day}-${month}`
      }
      return `${day}-${month}-${year}`
    }

    return ''
  }

  const today = () => {
    const date = new window.Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()

    console.log(
      `${year}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`}`,
    )
    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${day < 10 ? `0${day}` : `${day}`}`
  }

  FollowUpDate = formatDate(FollowUpDate)

  // const confirmModal = (<CModal show={showModal} size="lg" onClose={() => setShowModal(false)}>
  //   <CModalBody>
  //     <Confirmation data={JsonForm} mData={modelData} handleChange={handleChange} jsonChange={jsonChange} />
  //   </CModalBody>
  // </CModal>
  // );

  // const confirmUnder18Modal = (<CModal show={showModalUnder18} size="lg"  onClose={() => setShowModalUnder18(false)}>
  //   <CModalBody>
  //     <Under18Form data={JsonForm} mData={modelData} handleChange={handleChange} jsonChange={jsonChange} />
  //   </CModalBody>
  // </CModal>
  // );
  const handleCheck = (event) => {
    handleChange({ name: event?.target.name, value: event?.target.checked })
    if (event?.target.checked) {
      if (!modelData.FollowUpDate) {
        FollowUpDate = today()
        handleChange({ name: 'FollowUpDate', value: today() })
      }
    }
  }

  return (
    <>
      <CCardHeader
        className='d-flex align-items-center'
        style={{
          backgroundColor: '#2A3446',
          marginBottom: '0',
          fontSize: 16,
          fontWeight: 600,
          borderRadius: 0,
        }}
      >
        <span style={{ color: 'white' }}>{title} </span>

        <div className='mr-1 ml-auto'></div>
        {/* {
        (modelData.AdultType === "Adult") ?
          <CButton
            className='mr-2 d-none'
            type='button'
            color='info'
            style={{ float: 'right' }}
            onClick={() => setShowModal(true)}
          >
            Form
          </CButton> : 
              <CButton
              className='mr-2 d-none'
              type='button'
              color='info'
              style={{ float: 'right' }}
              onClick={() => setShowModalUnder18(true)}
            >
              Form
            </CButton>
    } */}
        <CDropdown className='mr-2'>
          <CDropdownToggle color='warning' style={{ minWidth: '150px' }}>
            {modelData.FacilitiesId
              ? facilitiesData.map((item: any) => {
                  if (item.Id === modelData.FacilitiesId) {
                    return item.Name
                  } else {
                    return ''
                  }
                })
              : 'Select Facility'}
          </CDropdownToggle>
          <CDropdownMenu>
            {facilitiesData.map((item: any, k: any) => {
              return (
                <CDropdownItem
                  onClick={() => handleChange({ name: 'FacilitiesId', value: item.Id })}
                  href='#'
                  key={k}
                >
                  {item.Name}
                </CDropdownItem>
              )
            })}
          </CDropdownMenu>
        </CDropdown>

        <CDropdown className='mr-2'>
          <CDropdownToggle color='success' style={{ minWidth: '150px' }}>
            {modelData.OnboardStatusId
              ? statusData.map((item: any) => {
                  if (item.Id === modelData.OnboardStatusId) {
                    return item.Description
                  } else {
                    return ''
                  }
                })
              : 'Select Status'}
          </CDropdownToggle>
          <CDropdownMenu>
            {statusData.map((item: any, k: any) => {
              return (
                <CDropdownItem
                  onClick={() => handleChange({ name: 'OnboardStatusId', value: item.Id })}
                  href='#'
                  key={k}
                >
                  {item.Description}
                </CDropdownItem>
              )
            })}
          </CDropdownMenu>
        </CDropdown>

        <div className='follw-check'>
          <label htmlFor='IsFollowUp' className='m-0'>
            <input
              className='mr-2'
              id='IsFollowUp'
              type='checkbox'
              name='IsFollowUp'
              checked={modelData.IsFollowUp == true}
              onChange={handleCheck}
            />
            Follow Up
          </label>
        </div>
        {modelData.IsFollowUp ? (
          <div>
            <CustomDateField
              className='header-input-date mr-2'
              name='FollowUpDate'
              type='date'
              value={FollowUpDate ?? ''}
              onChange={handleChange}
            />
          </div>
        ) : null}

        <LoadingButton
          className='mr-1'
          style={{ float: 'right' }}
          loading={isLoading}
          onClick={saveData}
        >
          Save
        </LoadingButton>
        <CButton
          className='mr-2'
          type='button'
          color='secondary'
          style={{ float: 'right' }}
          onClick={onCancelHandle}
        >
          Close
        </CButton>
      </CCardHeader>
      {/* {confirmModal}
    {confirmUnder18Modal} */}
    </>
  )
}

export default HeaderComponent
