import React from 'react'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { RootRepository } from 'src/repository/admin/rootRepository'
// import { routeToPage } from 'src/services/routingHelper'
import { DeleteModal, Loader } from 'src/shared'
import { EndpointsHeader, EndpointsInfo } from './components'
import { ENDPOINTS_MODEL } from 'src/services/routeConst'

const EndpointDetails: React.FC<any> = ({ history, match, modelName = ENDPOINTS_MODEL }) => {
  const [apiData, setApiData] = React.useState<any>({ endpointData: null, servicesData: [] })
  const [status, setStatus] = React.useState<any>('loading')
  const [showDeleteModal, setShowDeleteModal] = React.useState<any>(false)
  const clickedIDRef = React.useRef<any>(-1)
  const tableRef = React.useRef<any>()
  const changesRef = React.useRef<any>()
  const { id = 0 } = match.params
  const fields = [
    'EndpointsId',
    'Name',
    'Description',
    'Header',
    'HttpMethod',
    'Enabled',
    'AuthApplication',
    'AuthMember',
    'AuthUser',
  ]

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(
    async (shouldUpdateRefs = true) => {
      try {
        const { mainData }: any = await rootRepository.getData({
          id,
          modelName,
          defaultMainData: { EndpointsId: 0 },
        })
        setApiData({ endpointData: mainData })
        setStatus('idle')
        id && shouldUpdateRefs && updateRefs()
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [id, modelName, rootRepository],
  )

  const updateRefs = () => {
    tableRef.current.fetchNewData()
    changesRef.current.fetchNewData()
  }

  React.useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ endpointData: null, servicesData: [] })
      history.goBack()
    }, delay)
  }

  const saveData = async () => {
    const dataToSave: any = apiData.endpointData
    setStatus('saving')
    try {
      await rootRepository.saveData({
        modelName,
        idName: 'EndpointsId',
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        getData,
        goBack,
      })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({ ...st, endpointData: { ...st.endpointData, [name]: value } }))
  }

  const handleSwitchChange = (name: any, value: any) => {
    setApiData((st: any) => ({
      ...st,
      endpointData: {
        ...st.endpointData,
        [name]: [1, true, 'True'].includes(value) ? 1 : 0,
      },
    }))
  }

  const handleComboChange = (e: any, newValue: any = {}, name: any) => {
    if (!e.target) return
    const CodeId = newValue ? newValue.CodeId : 0
    setApiData((st: any) => ({ ...st, endpointData: { ...st.endpointData, [name]: CodeId } }))
  }

  /* const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Micro/Scenarios/Detail/${id}`)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  } */

  /*  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  } */

  if (!apiData.endpointData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { endpointData } = apiData
  return (
    <>
      <CCard>
        <EndpointsHeader
          modelName={modelName}
          status={status}
          saveData={saveData}
          onCancel={history.goBack}
        />
        <CCardBody>
          <CRow>
            <CCol xs='12' md='6'>
              <EndpointsInfo
                endpointData={endpointData}
                handleChange={handleChange}
                handleSwitchChange={handleSwitchChange}
                handleComboChange={handleComboChange}
              />
            </CCol>
            {/* {id ? (
              <CCol xs='12' md='6'>
                <EndpointsDetailTabSection
                  id={id}
                  Guid={endpointData.Guid}
                  modelName={modelName}
                  actionFunctions={actionFunctions}
                  tableRef={tableRef}
                  changesRef={changesRef}
                />
              </CCol>
            ) : null} */}
          </CRow>
        </CCardBody>
      </CCard>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName: 'Users', label: 'User', id: clickedIDRef.current }}
        callBack={getData}
      />
    </>
  )
}

export default EndpointDetails
