import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, MODELS, RETRIEVE, SERVICES } from 'src/services/routeConst'
import { parseNulls } from 'src/services/util'
interface Params {
  Name?: any
  Value?: any
}
interface PropsRetrieveModel {
  data?: {
    Model?: string
    Extension?: string
    Id?: any
    Params?: Params[]
  }
}

interface ResponseRetrieve {
  data: any
  status: string
  message: any
}
export const RetrieveModel = async ({
  data = {},
}: PropsRetrieveModel): Promise<ResponseRetrieve> => {
  //URL {api}/Models/Retrieve/{ModelName}/:id
  const baseApi = `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`
  try {
    const response = await axios.post<any>(
      baseApi,
      { ...data, Model: data?.Model, Params: data?.Params ?? [] },
      {
        ...addAuthToHeader('ADMIN'),
      },
    )
    parseNulls([response.data])
    return {
      data: response.data,
      status: 'success',
      message: response.data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error getting ${data.Model} data!`,
      error: error.response,
    })
  }
}
