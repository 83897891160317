import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsMemberStats {
  memberData?: any
  handleChange?: any
  handleSwitchChange?: any
}
const MemberStats: React.FC<PropsMemberStats> = ({
  memberData,
  handleChange,
  handleSwitchChange,
}) => {
  const {
    StatNumShares,
    StatNumSignups,
    StatVitalScore,
    StatCreditRewardsBalance,
    StatReferralRewardsBalance,
    StatCashbackBalance,
    StatVitalCashTotal,
    CardCreditAmount,
    CardCreditBalance,
    StatVitalCashReceived,
    StatVitalCashSent,
    StatCreditScore,
    StatYourRank,
    StatIsCashback2Pct,
    StatIsCashback3Pct,
  } = memberData

  return (
    <FieldsSection header='Member Stats'>
      <CFormGroup row>
        <CCol xs='12' lg='4'>
          <InputField
            name='StatNumShares'
            label='Number of Shares'
            type='text'
            value={StatNumShares}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='4' className='px-lg-0 my-3 my-lg-0'>
          <InputField
            name='StatNumSignups'
            type='text'
            label='Number of Signups'
            value={StatNumSignups}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='4'>
          <InputField
            name='StatVitalScore'
            label='Vital Score'
            type='text'
            value={StatVitalScore}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='4'>
          <InputField
            name='StatCreditScore'
            label='Credit Score'
            type='text'
            value={StatCreditScore}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='4' className='px-lg-0 my-3 my-lg-0'>
          <InputField
            name='StatYourRank'
            label='Member Rank'
            type='text'
            value={StatYourRank}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='4'>
          <InputField
            name='StatCreditRewardsBalance'
            label='Credit Rewards Balance'
            type='currency'
            alignment='right'
            value={StatCreditRewardsBalance}
            decimals={2}
            onChange={handleChange}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='4'>
          <InputField
            name='StatReferralRewardsBalance'
            label='Referral Rewards Balance'
            type='currency'
            alignment='right'
            value={StatReferralRewardsBalance}
            decimals={2}
            onChange={handleChange}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>
        <CCol xs='12' lg='4' className='px-lg-0 my-3 my-lg-0'>
          <InputField
            name='CardCreditAmount'
            label='Credit Amount'
            type='currency'
            alignment='right'
            value={CardCreditAmount}
            onChange={handleChange}
            decimals={2}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>
        <CCol xs='12' lg='4'>
          <InputField
            name='CardCreditBalance'
            label='Credit Balance'
            type='currency'
            alignment='right'
            value={CardCreditBalance}
            onChange={handleChange}
            decimals={2}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='4'>
          <InputField
            name='StatVitalCashReceived'
            label='Vital Cash Received'
            type='currency'
            alignment='right'
            value={StatVitalCashReceived}
            onChange={handleChange}
            decimals={2}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>
        <CCol xs='12' lg='4' className='px-lg-0 my-3 my-lg-0'>
          <InputField
            name='StatVitalCashSent'
            label='Vital Cash Sent'
            type='currency'
            alignment='right'
            value={StatVitalCashSent}
            onChange={handleChange}
            decimals={2}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>

        <CCol xs='12' lg='4'>
          <InputField
            name='StatVitalCashTotal'
            label='Vital Cash Total'
            type='currency'
            alignment='right'
            value={StatVitalCashTotal}
            onChange={handleChange}
            decimals={2}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='4'>
          <InputField
            name='StatCashbackBalance'
            label='Cashback Balance'
            type='currency'
            alignment='right'
            value={StatCashbackBalance}
            onChange={handleChange}
            decimals={2}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>
        <CCol xs='12' lg='4' className='px-lg-0 my-3 my-lg-0'>
          <InputField
            name='StatIsCashback2Pct'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Is Cashback 2Pct'
          >
            <CustomSwitch
              name='StatIsCashback2Pct'
              className='d-block'
              value={StatIsCashback2Pct}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
        <CCol xs='12' lg='4'>
          <InputField
            name='StatIsCashback3Pct'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Is Cashback 3Pct'
          >
            <CustomSwitch
              name='StatIsCashback3Pct'
              className='d-block'
              value={StatIsCashback3Pct}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}
export default React.memo(MemberStats)
