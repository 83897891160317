import React from 'react'
import { FieldsSection } from '../../../../shared'
import AddressTripInfo from '../../../../shared/inputFields/AddressFinderTripInfo'

interface PropsCustomerTripInfo {
  id?: any
  allMembersData?: any
  memberData?: any
  handleChange?: any
  handleComboChange?: any
  handlePhoneChange?: any
  showVerifyOTPModalFunc?: any
  sendingOTP?: any
  showVerifyPhoneModal?: any
  showVerifyEmailModal?: any
  handleAddressChange?: any
}
const CustomerTripInfo: React.FC<PropsCustomerTripInfo> = ({
  /* id, */
  /* allMembersData, */
  memberData,
  handleChange,
  handleAddressChange,
  /* handleComboChange, */
  /* handlePhoneChange,
  showVerifyOTPModalFunc,
  sendingOTP,
  showVerifyPhoneModal, */
  // showVerifyEmailModal,
}) => {
  // const btnRef = React.useRef<any>()

  const { PickUpLocation, ScheduledTime, DropOffLocation, LatestDropOffTime } = memberData
  const handleAddressDataChange = ({ value }: any) => {
    handleAddressChange(value)
  }

  return (
    <FieldsSection header='Trip Information'>
      <AddressTripInfo
        address={{ PickUpLocation, ScheduledTime, DropOffLocation, LatestDropOffTime }}
        handleChange={handleChange}
        handleAddressDataChange={handleAddressDataChange}
      />
    </FieldsSection>
  )
}
export default React.memo(CustomerTripInfo)
