import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useContext, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { DashboardContext } from 'src/containers/DashBoard'
import { getAuthToken } from 'src/services/authHelper'
import { ProcGenerateImport } from 'src/services/modelNew/ProcGen/Imports/ProcImport'
import { BASE_API, DATASCHEMAS_ID, DATASCHEMAS_MODEL, MODELS } from 'src/services/routeConst'
import { routeToPage } from 'src/services/routingHelper'
import { DeleteModal } from 'src/shared'
import { TableColumns } from './DataSchemasTableColumns'

const ModelSearch: React.FC<any> = ({ history, modelName = DATASCHEMAS_MODEL }) => {
  const { setShowLinear, showLinear } = useContext(DashboardContext)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const clickedIDRef = useRef()
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g)!.join(' ')
  const tableRef = useRef<any>()

  const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Database/DataSchemas/Detail/${id}`)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }
  const procGenerate = async () => {
    try {
      setShowLinear(true)
      toast.info('Importing Schemas this could take a few minutes.')
      const { message } = await ProcGenerateImport({
        procType: 'Schemas',
      })
      toast.success(message)
      setShowLinear(false)
    } catch (error: any) {
      setShowLinear(false)
      toast.error(`${error.message}`)
    }
  }
  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        {title} Search
        <CButton
          disabled={showLinear}
          className='ml-1'
          type='button'
          size='sm'
          color='primary'
          style={{ float: 'left' }}
          onClick={procGenerate}
        >
          Import Schema
        </CButton>
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, `/Database/DataSchemas/New`)}
        >
          New Data
        </CButton>
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={TableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={modelName}
          idName={DATASCHEMAS_ID}
          actionClick={(type: any, data: any) => {
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          scopedSlots={
            {
              /* AvatarUrl: ({ row }: any) => (
              <MemberAvatar
                name={row.original?.FirstName + ' ' + row.original?.LastName}
                avatar={row.original?.FilesId_Avatar}
                noChip
              />
            ), */
            }
          }
          paginationPosition='top-right'
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'User', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CCard>
  )
}

export default ModelSearch
