// Generated with util/create-search-page.js
import { CButton, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { FacilitiesTableColumns } from './FacilitiesTableColumns'
import { BASE_API, FACILITIES_MODEL, MODELS, SERVICES, LIST } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'

const FacilitiesSearch = ({ history, modelName = FACILITIES_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)

  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }) => {
    routeToPage(history, '/Admin/Facilities/Detail/' + id)
  }

  const showDeleteModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <>
      <CCardHeader
        className='d-flex align-items-center card-accent-secondary'
        style={{
          backgroundColor: '#2A3446',
          marginBottom: '1rem',
          fontSize: 16,
          fontWeight: 600,
          borderRadius: 0,
        }}
      >
        <span style={{ color: 'white' }}>{modelName} Search</span>
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, '/Admin/Facilities/New')}
        >
          New Facility
        </CButton>
      </CCardHeader>
      <CCardBody className={'custom-card-body'}>
        <DataTable
          columnData={FacilitiesTableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={modelName}
          scopedSlots={{
            GroupName: ({ value }) => {
              return <div>{value}</div>
            },
            Name: ({ value }) => {
              return <div>{value}</div>
            },
            Value: ({ value }) => {
              return <div style={{ color: '#F2AB26' }}>{value}</div>
            },
          }}
          actionClick={(type, data) => {
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          paginationPosition='bottom-right'
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Propertie', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </>
  )
}

export default FacilitiesSearch
