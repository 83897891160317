import React from 'react'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
interface PropsMemberAddress {
  modelData: any
  handleChange: any
  handleAddressChange: any
  modelName?: string
}
const AddressComponent = ({ modelName = '', modelData }: PropsMemberAddress) => {
  /* const mapModelNameToObject = (data: any): Object => {
    const addressValue = {}
    Object.keys(data?.value ?? ({} as Object)).forEach((key) => {
      addressValue[`${modelName}${key}`] = data.value[key]
    })
    return addressValue
  } */
  return (
    <>
      <InputLabel label='Address' value={modelData[`${modelName}Address`] ?? ''} />
      <InputLabel label='City' value={modelData[`${modelName}City`] ?? ''} />
      <InputLabel label='State' value={modelData[`${modelName}State`] ?? ''} />
      <InputLabel label='Zip' value={modelData[`${modelName}Zip`] ?? ''} />
    </>
  )
}
export default React.memo(AddressComponent)
