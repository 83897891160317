import { CCard, CCardBody } from '@coreui/react'

const Menus = ({ roleMenuData = [] }) => {
  return (
    <>
      { roleMenuData.map((f:any, index: any) => {
        return <CCard key={index}>
          <CCardBody>
          <div style={{ color: 'dark !important' }}>
            <div>
              <b> Name:</b> {f.Name}
            </div>
          </div>
          </CCardBody>
        </CCard>
        })
      }
    </>
  )
}
export default Menus