import React, { useContext } from 'react'
import { CTabPane } from '@coreui/react'

// import { FieldsSection } from 'src/shared'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
// import SearchTab from './CustomersServices/Search'
import { LeadContext } from '../Detail'

import NotesInfo from './NotesInfo'
interface PropsCustomersServices {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  modelData?: any
  Guid?: any
}
const CustomersServices: React.FC<PropsCustomersServices> = ({
  id,
  modelName,
  changesRef,
  Guid,
  modelData,
}) => {
  const { MetasId } = useContext(LeadContext)

  return (
    <DetailTabSection
      id={id}
      metasId={MetasId}
      Guio={Guid}
      headerStyle= {{padding: '0px'}}
      modelName={modelName}
      hideNotes
      tabs={[{ name: 'Notes', label: 'Notes' }]}
      changesRef={changesRef}
    >
      <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
        {modelData.Id !== 0 ? (
          <NotesInfo title={'Notes'} id={id} MetasId={modelData.MetasId} modelData={modelData} />
        ) : (
          <></>
        )}
      </CTabPane>
    </DetailTabSection>
  )
}

export default CustomersServices
