import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeaderFormData, logoutVerifyErrors } from 'src/services/authHelper'
import { BASE_API, SERVICES, WIDGETS } from 'src/services/routeConst'
interface PropsFileUpload {
  fileToUpload?: any
  MetasId?: any
  Description?: any
  Id?: any
  modelName?: any
}
export const FileUpload = async ({
  Id = 0,
  fileToUpload,
  MetasId = 0,
  Description,
}: PropsFileUpload) => {
  //URL {api}/Widgets/Files/Upload
  const form = new FormData()
  form.append('Id', Id)
  form.append('File', fileToUpload)
  form.append('MetasId', MetasId)
  form.append('Description', Description)

  try {
    const { data } = await axios.post(`${BASE_API}${SERVICES}${WIDGETS}/Files/Upload`, form, {
      ...addAuthToHeaderFormData('APPLICATION'),
    })
    return {
      data: data.data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    const status = error.response.status 
    logoutVerifyErrors(status)
    throw new APIError({
      status: 'error',
      message: `Error saving File!`,
      error: error.response,
    })
  }
}
