import { SETUSER, SET_APPLICATION_RETRIEVE } from '../actions/authActions'
const loggedInUser: any = localStorage.getItem('VCA_LoggedInUser')
const applicationRetrieve: any = localStorage.getItem('VCA_ApplicationRetrieve')
const initialState: { loggedInUser?: any, applicationRetrieve?: any } = {
  loggedInUser: JSON.parse(loggedInUser) || {},
  applicationRetrieve: JSON.parse(applicationRetrieve) || {},
}

function authReducer(state = initialState, action: any) {
  // console.log({ state, action })
  switch (action.type) {
    case SETUSER:
      if (Object.keys(action.payload).length <= 0) {
        localStorage.removeItem('VCA_AUTH')
        localStorage.removeItem('VCA_LoggedInUser')
      } else {
        localStorage.setItem('VCA_LoggedInUser', JSON.stringify(action.payload))
      }
      return { ...state, loggedInUser: action.payload }
    case SET_APPLICATION_RETRIEVE:
      if (Object.keys(action.payload).length <= 0) {
        localStorage.removeItem('VCA_ApplicationRetrieve')
      } else {
        localStorage.setItem('VCA_ApplicationRetrieve', JSON.stringify(action.payload))
      }
      return { ...state, applicationRetrieve: action.payload }
    default:
      return state
  }
}

export default authReducer
