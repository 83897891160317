import { CButton, CCardHeader } from '@coreui/react'
import React from 'react'
import LoadingButton from 'src/shared/components/LoadingButton'
interface PropsSceneraiosHeader {
  id?: any
  status?: any
  saveData?: any
  scenarioHandler?: any
  onCancel?: any
  modelName?: any
}
const ScenariosHeader: React.FC<PropsSceneraiosHeader> = ({
  id,
  status,
  saveData,
  scenarioHandler,
  onCancel,
}: any) => {
  const isSaving = status === 'saving'
  const isTesting = status === 'testing'
  return (
    <CCardHeader className='d-flex align-items-center card-accent-secondary'>
      Scenario Details
      {id ? (
        <LoadingButton
          className='mr-1 ml-auto'
          style={{ float: 'right' }}
          loading={isTesting}
          onClick={() => scenarioHandler({ action: 'test' })}
          color='info'
        >
          Test
        </LoadingButton>
      ) : null}
      <LoadingButton
        className={`mr-1 ${id ? '' : 'ml-auto'}`}
        style={{ float: 'right' }}
        loading={isSaving}
        onClick={saveData}
      >
        Save
      </LoadingButton>
      <CButton
        className='mr-1'
        type='button'
        color='secondary'
        onClick={() => scenarioHandler({ action: 'reset' })}
      >
        Reset
      </CButton>
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancel}
      >
        Cancel
      </CButton>
    </CCardHeader>
  )
}

export default ScenariosHeader
