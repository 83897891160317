import React from 'react'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { RootRepository } from 'src/repository/admin/rootRepository'
// import { routeToPage } from 'src/services/routingHelper'
import { Loader } from 'src/shared'
import { FieldsHeader, FieldsInfo } from './components'
import { ALERTS_FIELDS_MODEL } from 'src/services/routeConst'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'

const FieldDetails: React.FC<any> = ({ history, match, modelName = ALERTS_FIELDS_MODEL }) => {
  const [apiData, setApiData] = React.useState<any>({ fieldData: null })
  const [status, setStatus] = React.useState<any>('loading')
  // const [showDeleteModal, setShowDeleteModal] = useState<any>(false)
  // const clickedIDRef = useRef()
  // const tableRef = useRef()
  const changesRef = React.useRef<any>()
  const { id = 0 } = match.params
  const fields = ['FieldsId', 'Name', 'Description', 'SelectQuery']

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(
    async (shouldUpdateRefs = true) => {
      try {
        const { mainData }: any = await rootRepository.getData({
          id,
          modelName,
          defaultMainData: { FieldsId: 0 },
        })
        setApiData({ fieldData: mainData })
        setStatus('idle')
        id && shouldUpdateRefs && updateRefs()
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [id, modelName, rootRepository],
  )

  const updateRefs = () => {
    // tableRef.current.fetchNewData()
    changesRef.current.fetchNewData()
  }
  React.useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ fieldData: null })
      history.goBack()
    }, delay)
  }

  const saveData = async () => {
    const dataToSave: any = apiData.fieldData
    setStatus('saving')
    try {
      await rootRepository.saveData({
        modelName,
        idName: 'FieldsId',
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        getData,
        goBack,
      })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setApiData((st: any) => ({ ...st, fieldData: { ...st.fieldData, [name]: value } }))
    // console.log(apiData)
  }

  if (!apiData.fieldData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { fieldData } = apiData
  return (
    <>
      <CCard>
        <FieldsHeader
          modelName={modelName}
          status={status}
          saveData={saveData}
          onCancel={history.goBack}
        ></FieldsHeader>
        <CCardBody>
          <CRow>
            <CCol xs='12' md='6'>
              <FieldsInfo fieldData={fieldData} handleChange={handleChange}></FieldsInfo>
            </CCol>
            {id ? (
              <CCol xs='12' md='6'>
                <DetailTabSection
                  id={id}
                  Guid={fieldData.Guid}
                  modelName={modelName}
                  changesRef={changesRef}
                />
              </CCol>
            ) : null}
          </CRow>
        </CCardBody>
      </CCard>
      {/* <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName: 'Models', label: 'Model', id: clickedIDRef.current }}
        callBack={getData}
      /> */}
    </>
  )
}

export default FieldDetails
