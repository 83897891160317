import CIcon from '@coreui/icons-react'
import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useGeolocation } from '../../../src/hooks/useGeolocation'
import InputField from './InputField'
interface AddressData {
  PickUpLocation: string
  ScheduledTime:string
  DropOffLocation:string
  LatestDropOffTime:string
}
interface PropsAddressTripInfo {
  address?: AddressData
  handleChange?: any
  handleAddressDataChange?: any
  name?: string
}
const AddressTripInfo: React.FC<PropsAddressTripInfo> = ({
  address = { PickUpLocation:'', ScheduledTime:'', DropOffLocation:'', LatestDropOffTime:'' },
  handleChange,
  handleAddressDataChange,
  name = '',
}) => {
  const btnRef = React.useRef<any>()
  const [locationData, getLocationData] = useGeolocation()

  React.useEffect(() => {
    locationData && console.log(locationData)
  }, [locationData])

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => handleContentChange(place),
    options: { types: ['geocode'] },
  })

  const handleContentChange = (data: any) => {
    const { address_components = [] } = data
    const components: any = {}
    address_components.forEach(({ types, short_name, long_name }: any) => {
      types.forEach((type: any) => {
        components[type] = ['route', 'administrative_area_level_1'].includes(type)
          ? short_name
          : long_name
      })
    })
    const {
      premise = '',
      street_number = '',
      route = '',
      postal_code = '',
      neighborhood = '',
      locality = '',
      administrative_area_level_1 = '',
    } = components
    const streetAddress = street_number
      ? `${street_number} ${route}`
      : route
      ? route
      : premise
      ? premise
      : ''
    handleAddressDataChange({
      name,
      value: {
        Address: streetAddress,
        City: neighborhood ? neighborhood : locality,
        State: administrative_area_level_1,
        ZipCode: postal_code,
      },
    })
    // handleAddressDataChange({ name, value: components })
  }

  const renderLocationButton = () => {
    return (
      <small
        ref={btnRef}
        className={`input-field-button`}
        onClick={() => {
          getLocationData()
        }}
      >
        <CIcon name='cil-location-pin' />
      </small>
    )
  }

  const { PickUpLocation, ScheduledTime, DropOffLocation, LatestDropOffTime } = address
  return (
    <>
      <CFormGroup row>
        <CCol xs='12' lg='8' className='pr-lg-0 mb-3 my-lg-0'>
          <InputField
            innerRef={ref}
            name={`${name}PickUpLocation`}
            label={name ? `${name} Pick Up Location Address` : 'Pick Up Location Address'}
            type='text'
            value={PickUpLocation}
            onChange={handleChange}
            showButton
            ButtonComponent={renderLocationButton}
          />
        </CCol>
        <CCol>
          <InputField
            name={`${name}ScheduledTime`}
            type='text'
            label={name ? `${name} Scheduled Time` : 'Scheduled Time'}
            value={ScheduledTime}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='8' className='pr-lg-0 mb-3 my-lg-0'>
          <InputField
            innerRef={ref}
            name={`${name}DropOffLocation`}
            label={name ? `${name} Drop Off Location Address` : 'Drop Off Location Address'}
            type='text'
            value={DropOffLocation}
            onChange={handleChange}
            showButton
            ButtonComponent={renderLocationButton}
          />
        </CCol>
        <CCol>
          <InputField
            name={`${name}LatestDropOffTime`}
            type='text'
            label={name ? `${name} Latest Drop Off Time` : 'Latest Drop Off Time'}
            value={LatestDropOffTime}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </>
  )
}

export default AddressTripInfo
