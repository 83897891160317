import { CButton, CCardBody, CCardHeader, CCol, CContainer, CRow, CTabPane } from '@coreui/react'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { CUSTOMERS_MODEL } from 'src/services/routeConst'
import { Loader } from 'src/shared'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import AppointmentInfo from './Customer/AppointmentInfo'
import DropOffInfo from './Customer/DropOffInfo'
import InspectorInfo from './Customer/InspectorInfo'
import PickUpInfo from './Customer/PickupInfo'
import VehicleCustomerInfo from './Customer/VehicleCustomerInfo'
import {
  AccidentHistoryInfo,
  ApptPickupInfo,
  ApptTimeSlotInfo,
  ComplianceInfo,
  CustomerInfo,
  DeductionInfo,
  DnServiceInfo,
  ElectricalInfo,
  HistoryInfo,
  MechanicalIssuesInfo,
  ModificationsInfo,
  PaymentInfo,
  TitleInfo,
  VehicleConditionsInfo,
  VehicleInfo,
} from './CustomersDispatches'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'
import NotesInfo from './NotesInfo'
function useQuery() {
  const { search } = useLocation()

  return useMemo(() => new URLSearchParams(search), [search])
}
const External = ({ history, match, modelName = CUSTOMERS_MODEL }: any) => {
  const { id = 0 } = match.params
  const [apiData, status, , handleChange] = useActionsModels({
    id,
    modelName,
    history,
  })
  const [token, setToken] = useState<any>(null)
  const query = useQuery()
  
  useEffect(() => {
    setToken(query.get('Tokens'))
  }, [query])
  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }

  const { modelData, quantumData } = apiData
  
  return (
    <CContainer
      fluid
      style={{ margin: 0, padding: 0, backgroundColor: 'white', minHeight: '100vh' }}
    >
      {token === null || token === '' || typeof token === 'undefined' ? (
        <div>Token no valid</div>
      ) : (
        <>
          <CCardHeader
            className='d-flex align-items-center'
            style={{
              backgroundColor: '#2A3446',
              marginBottom: '1rem',
              fontSize: 16,
              fontWeight: 600,
              borderRadius: 0,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span style={{ color: 'white' }}>External Customer</span>
            <CButton color='primary' onClick={() => {}}>
              Submit
            </CButton>
          </CCardHeader>
          <CCardBody className={'custom-card-body'}>
            <DetailTabSection
              id={null}
              metasId={null}
              Guio={null}
              modelName={null}
              hideNotes
              tabs={[
                { name: 'CustomersDetails', label: 'Customers' },
                { name: 'DispatchesDetails', label: `Quantum` },
                { name: 'NotesDetails', label: `Notes` },
              ]}
              changesRef={null}
            >
              <CTabPane data-tab='CustomersDetails' className='detail-tab-section-pane'>
                <CRow>
                  <CCol md='7' sm='12'>
                    <InspectorInfo
                      handleChange={handleChange}
                      modelData={modelData}
                      handleAddressChange={() => {}}
                      title='Details'
                    ></InspectorInfo>
                  </CCol>
                  <CCol md='5' sm='12' className='pl-lg-1'>
                    <AppointmentInfo
                      title={'Appointment'}
                      modelData={modelData}
                      handleChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className='pr-lg-1' sm='12'>
                    <PickUpInfo
                      title={'Pick up'}
                      modelData={modelData}
                      handleChange={handleChange}
                      handleAddressChange={() => {}}
                    />
                  </CCol>
                  <CCol className='pl-lg-1' sm='12'>
                    <DropOffInfo
                      title={'Drop off'}
                      modelData={modelData}
                      handleChange={handleChange}
                      handleAddressChange={() => {}}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className='pr-lg-1' sm='12'>
                    <VehicleCustomerInfo
                      title={'Vehicle'}
                      modelData={modelData}
                      handleChange={handleChange}
                      handleAddressChange={() => {}}
                    />
                  </CCol>
                </CRow>
              </CTabPane>
              <CTabPane data-tab='DispatchesDetails' className='detail-tab-section-pane'>
                <CButton
                  color='primary'
                  width={'100%'}
                  onClick={() => {
                    window.open(`${quantumData?.InspectionInspectorPhotoToolUrl ?? ''}`)
                  }}
                >
                  {' '}
                  Inspection Inspector Photo Tool Url
                </CButton>
                <CRow>
                  <CCol className='pr-lg-1' sm='12'>
                    <VehicleInfo
                      title={'Vehicle info'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                  {/* <CCol>
            <TitleInfo title={'Title Info'} modelData={modelData} handleChange={handleChange} />
          </CCol>
        </CRow>
        <CRow row>
          <CCol>
            <OwnershipInfo title={'Ownership Info'} modelData={modelData} handleChange={handleChange} />
          </CCol> */}
                  <CCol className='pl-lg-1' sm='12'>
                    <AccidentHistoryInfo
                      title={'Accident History'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className='pr-lg-1' sm='12'>
                    <VehicleConditionsInfo
                      title={'General Vehicle Conditions'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                  <CCol className='pl-lg-1' sm='12'>
                    <MechanicalIssuesInfo
                      title={'Mechanical Issues'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className='pr-lg-1' sm='12'>
                    <ModificationsInfo
                      title={'Modifications Info'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                  <CCol className='pl-lg-1' sm='12'>
                    <ElectricalInfo
                      title={'Electrical Info'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                </CRow>

                <CRow>
                  <CCol className='pr-lg-1' sm='12'>
                    <CustomerInfo
                      title={'Customer'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                  <CCol className='pl-lg-1' sm='12'>
                    <ComplianceInfo
                      title={'Compliance Info'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className='pr-lg-1' sm='12'>
                    <TitleInfo
                      title={'Title Info'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                  <CCol className='pl-lg-1' sm='12'>
                    <HistoryInfo
                      title={'History Info'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className='pr-lg-1' sm='12'>
                    <PaymentInfo
                      title={'Payment Info'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                  <CCol className='pl-lg-1' sm='12'>
                    <DeductionInfo
                      title={'Deduction Info'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className='pr-lg-1' sm='12'>
                    <ApptPickupInfo
                      title={'Appt Pickup Info'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                  <CCol className='pl-lg-1' sm='12'>
                    <ApptTimeSlotInfo
                      title={'Appt Time Slot Info'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className='pr-lg-1' sm='12'>
                    <DnServiceInfo
                      title={'Dn Service Info'}
                      modelData={quantumData}
                      handleChange={handleChange}
                    />
                  </CCol>
                  <CCol className='pl-lg-1'>
                    notes
                    {/* <NotesInfo
                  title={'Notes Info'}
                  modelData={quantumData}
                  handleChange={handleChange}
                /> */}
                  </CCol>
                </CRow>
              </CTabPane>
              <CTabPane data-tab='NotesDetails' className='detail-tab-section-pane'>
                <NotesInfo MetasId={modelData.MetasId} idCustomer={modelData.Id} accessToken={token} />
              </CTabPane>
            </DetailTabSection>
          </CCardBody>
        </>
      )}
    </CContainer>
  )
}
export default External
