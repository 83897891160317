import { useCallback, useEffect, useMemo, useState } from 'react'
import { StatusInfo } from '../enum/Status'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'
import { logoutVerifyErrors } from 'src/services/authHelper'
import { ListModel } from 'src/services/modelNew/List/List'

interface PropsActionsModels {
  id: any
  modelName: any
  history: any
  modelsId?: string
}
type ApiDataState = { modelData: any, statusData: any[] }
const fields: string[] = [
  'Id',
  'FirstName',
  'LastName',
  'DateOfBirth',
  'MedicAidNumber',
  'OtherCareManagement',
  'Gender',
  'LanguagePreference',
  'HomePhone',
  'CellPhone',
  'Address',
  'AptNo',
  'City',
  'State',
  'ZipCode',
  'IsMentalHealthCondition',
  'IsHeartDiseaseCondition',
  'IsPrivateResidence',
  'IsHomeless',
  'IsMHSupportive',
  'IsEmergencyHousing',
  'OtherHousing',
  'IsOtherHousing',
  'IsSeriousEmotional',
  'IsHivAids',
  'IsRecentRelease',
  'IsNeedsAssistance',
  'IsUnableToAccess',
  'IsMedications',
  'IsVictim',
  'IsPCPOrSpecialist',
  'IsDifficultyNavigating',
  'IsDeficits',
  'IsDeficitsLifestyle',
  'IsAsthmaCondition',
  'IsSubstanceCondition',
  'IsDiabetesCondition',
  'IsBMICondition',
  'IsOtherCondition',
  'riskFactorHistory',
  'AdultType',
  'CurrentDate',
  'Email',
  'IsTreatmentsOrMedications',
  'IsMembersParent',
  'IsTwoOrMoreCondition',
  'IsComplexTrauma',
  'IsAtRiskAdverse',
  'IsSocialSerious',
  'IsHasConnectivity',
  'IsDoesNotAdhere',
  'IsRecentlyReleased',
  'IsConcurrently',
  'ExistingParticipatPartners',
  'ManualParticipatPartners',
  'Signature',
  'MemberInitials',
  'StaffInitials',
  'StaffSignature',
  'IsFollowUp',
  'FollowUpDate',
  'OnboardStatusId',
  'MedicaidEligibility',
  'IsHarp',
  'Race',
  'IsLackofSocialSupports',
  'IsDeficitsWithDailyLiving',
  'IsAnyCondition',
  'FacilitiesId',
  'CreatedDate'
]
export const useActionsModels = ({
  id,
  modelName,
  modelsId = 'Id',
  // history,
}: PropsActionsModels): [ApiDataState, StatusInfo, () => Promise<void>, Function, Function] => {
  const [apiData, setApiData] = useState<ApiDataState>({ modelData: null, statusData: [] })
  const [status, setStatus] = useState<StatusInfo>(StatusInfo.loading)
  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback<any>(async () => {
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [modelsId]: 0 },
      })

      const { data } = await ListModel({
        body: {
          Model: "Lookups",
          Params: {
            TypesId: "14"
          },
        }
      })
      const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
      setApiData({ modelData: dataResponse, statusData: data })
      setStatus(StatusInfo.idle)
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }, [rootRepository, id, modelName, modelsId])

  useEffect(() => {
    getData()
  }, [getData])

  // const goBack = (delay = 0) => {
  //   setTimeout(() => {
  //     setApiData({ modelData: null, statusData: [] })
  //     history.goBack()
  //   }, delay)
  // }
  const mappingFields = (apiData) => {
    const dataToSave: any = apiData.modelData
    if (typeof dataToSave.ExistingParticipatPartners !== 'string') {
      dataToSave.ExistingParticipatPartners = JSON.stringify(dataToSave.ExistingParticipatPartners);
    }
    if (typeof dataToSave.Race !== 'string') {
      dataToSave.Race = JSON.stringify(dataToSave.Race);
    }
    if (typeof dataToSave.ManualParticipatPartners !== 'string') {
      dataToSave.ManualParticipatPartners = JSON.stringify(dataToSave.ManualParticipatPartners);
    }
    if (typeof dataToSave.IsAnyCondition !== 'string') {
      dataToSave.IsAnyCondition = JSON.stringify(dataToSave.IsAnyCondition);
    }
    return {
      ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
    }
  }
  const saveData = async () => {
    setStatus(StatusInfo.saving)
    try {
      const { message } = await SaveModel({
        body: {
          Model: modelName,
          Params: mappingFields(apiData),
        },
      })

      toast.success(message)
      getData()
      // goBack()
      setStatus(StatusInfo.idle)
    } catch (error: any) {
      const status = error.response.status
      logoutVerifyErrors(status)
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }
  const handleAddressChange = ({
    Address = '',
    Address2 = '',
    City = '',
    State = '',
    ZipCode = '',
  }) => {
    // console.log({ Address, Address2, City, State, ZipCode })
    setApiData((st: any) => ({
      ...st,
      modelData: { ...st.modelData, Address, Address2, City, State, ZipCode },
    }))
  }
  return [apiData, status, saveData, handleChange, handleAddressChange]
}
