import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const FacilitiesTableColumns: ColumnData[] = [
  {
    label: 'Code',
    name: 'Code',
  },
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'City',
    name: 'City',
  },
  {
    label: 'State Code',
    name: 'StateCode',
  },
  {
    label: 'Zip Code',
    name: 'ZipCode',
  },
  {
    label: 'Phone',
    name: 'Phone',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    customWidth: 10,
    sortable: false,
  },
]
