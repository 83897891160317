import React from 'react'
import { CTabPane } from '@coreui/react'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
// import { LeadContext } from '../Detail'
import {
  BuyerInfo
} from './index'
import ContactsInfo from './Contacts/ContactsInfo'

// import {
//   AccidentHistoryInfo,
//   ElectricalInfo,
//   MechanicalIssuesInfo,
//   ModificationsInfo,
//   VehicleConditionsInfo,
//   VehicleInfo,
// } from '../components/VehiclesServices'
// import OwnershipInfo from './OwnershipInfo'
// import CustomerServicesInfo from './CustomerServicesInfo'
// import CustomerFiles from './Files/Search'
// import { InvoiceServicesInfo } from './InvoicesServices'
// import { CostServicesInfo } from './CostsServices'
// import CustomerServiceTable from './CustomerService/CustomerServiceTable'
// import WorkFlow from 'src/pages/Workflow/Workflow/Search'
// import Breadcrumb from 'src/pages/Workflow/Workflow/components/Bread'
interface PropsBuyersTabInformation {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  modelData?: any
  modelDataVehicle?: any
  modelDataDispatch?: any
  Guid?: any
  handleChange?: any
  handleChangeMaxLength?: any
  handleAddressChange?: any
}
const BuyersTabInformation: React.FC<PropsBuyersTabInformation> = ({
  // id,
  // modelName,
  // changesRef,
  // Guid,
  modelData,
  // modelDataVehicle,
  // modelDataDispatch,
  handleChange,
  // handleChangeMaxLength,
  // handleAddressChange,
}) => {
  // const { MetasId } = useContext(LeadContext)
  // const { SourceName } = modelData
  // const actionClick = () => {}
  
  return (
    <>
      {/* <WorkFlow customerId={modelData.Id} /> */}
      <DetailTabSection
              id={0}
              MetasId={modelData.MetasId}
              Guio={0}
              modelName={''}
              hideNotes
              tabs={[
                { name: 'BuyerInfo', label: 'Buyer Info' },
                { name: 'Contacts', label: 'Contacts' },
              ]}
            >
              <CTabPane data-tab='BuyerInfo' className='detail-tab-section-pane'>
                <BuyerInfo
                  title={'Details'}
                  modelData={modelData}
                  handleChange={handleChange}
                />
              </CTabPane>
              <CTabPane data-tab='Contacts' className='detail-tab-section-pane'>
                <ContactsInfo
                  title={'Contacts'}
                  modelData={modelData}
                  handleChange={handleChange}
                />
              </CTabPane>
              {/* <CTabPane data-tab='Profile-Picture' className='detail-tab-section-pane'>
                {modelData.Id !== 0 && (
                  <CRow>
                    <CCol>
                      <InspectorProfilePicture modelData={modelData} />
                    </CCol>
                  </CRow>
                )}
              </CTabPane>
              <CTabPane data-tab='Address' className='detail-tab-section-pane'>
                <Address modelData={modelData} handleChange={handleChange} />
              </CTabPane>
              <CTabPane data-tab='Calendar' className='detail-tab-section-pane'>
                {modelData.Id !== 0 ? (
                  <Calendar title={'Timeslots'} modelData={modelData} handleChange={handleChange} />
                ) : (
                  <></>
                )}
              </CTabPane> */}
        
        {/* <CTabPane data-tab='VehiclesDetails' className='detail-tab-section-pane'>
      <CFormGroup row>
        <CCol>
          <VehicleInfo
            title={'Vehicle info'}
            modelData={modelDataVehicle}
            handleChange={handleChange}
          />
        </CCol>
        <CCol>
          <TitleInfo title={'Title Info'} modelData={modelData} handleChange={handleChange} />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <OwnershipInfo title={'Ownership Info'} modelData={modelData} handleChange={handleChange} />
        </CCol>
        <CCol>
          <AccidentHistoryInfo
            title={'Accident History'}
            modelData={modelDataVehicle}
            handleChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <VehicleConditionsInfo
            title={'General Vehicle Conditions'}
            modelData={modelDataVehicle}
            handleChange={handleChange}
          />
        </CCol>
        <CCol>
          <MechanicalIssuesInfo
            title={'Mechanical Issues'}
            modelData={modelDataVehicle}
            handleChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <ModificationsInfo title={'Modifications Info'} modelData={modelData} handleChange={handleChange} />
        </CCol>
        <CCol>
          <ElectricalInfo title={'Electrical Info'} modelData={modelData} handleChange={handleChange} />
        </CCol>
      </CFormGroup>
      </CTabPane> */}
        
      </DetailTabSection>
    </>
  )
}

export default BuyersTabInformation
