import { ColumnData } from 'library-soft-datatable'

export const SMSTableColumns: ColumnData[] = [
  // {
  //   label: 'ID',
  //   name: 'SmsId',
  // },
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
