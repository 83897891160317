// Generated with util/create-search-page.js
import { CButton } from '@coreui/react'

import LoadingButton from 'src/shared/components/LoadingButton'
interface PropsHeaderComponent {
  onCancelHandle: any
  status: any
  saveData: any
  modelName: any
}
const HeaderComponent = ({ onCancelHandle, status, saveData }: PropsHeaderComponent) => {
  const isLoading = status === 'saving'
  return (
    <div className='d-flex align-items-center'>
      <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton>
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancelHandle}
      >
        Close
      </CButton>
    </div>
  )
}

export default HeaderComponent
