import { CButton, CCardFooter, CModal, CModalBody, CModalHeader } from '@coreui/react'
import CropperComponent from 'src/shared/components/cropper/Cropper'

type Props = {
  showModal: boolean
  setShowModal: Function | VoidFunction | any
}

const ImageModal = ({ showModal, setShowModal }: Props) => {
  return (
    <CModal show={showModal} onClose={() => setShowModal((modal: any) => !modal)}>
      <CModalHeader closeButton>Modal title</CModalHeader>
      <CModalBody>
        <div style={{ width: '100%' }}>
          <CropperComponent guid={''} onChangeCropper={console.log} />
        </div>
      </CModalBody>
      <CCardFooter>
        <CButton color='primary'>Update</CButton>
      </CCardFooter>
    </CModal>
  )
}
export default ImageModal
