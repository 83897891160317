import { CFormGroup } from '@coreui/react'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { DashboardContext } from 'src/containers/DashBoard'
import { FileUpload } from 'src/services/modelNew/Widget/File/Upload'
import {  Cropper } from 'src/shared'
interface PropsUserProfilePicture {
  userData?: any
  updateProfilePictureID?: any
}
const UserProfilePicture: React.FC<PropsUserProfilePicture> = ({
  userData,
  updateProfilePictureID,
}) => {
  const { setUpdateAvatarProfile } = useContext(DashboardContext)
  const uploadFile = async (file: any) => {
    
    const { data, error } = await FileUpload({
      Id: userData.Id,
      MetasId: userData.MetasId,
      fileToUpload: file,
      Description: 'File',
    })
    !error && data && data.FilesId && updateProfilePictureID(data.FilesId)
    toast.success('Profile Picture Updated!')
  }

  return (
    <>
     {/* <FieldsSection header='Profile Picture'> */}
      <CFormGroup row className='justify-content-center'>
        <Cropper
          cropShape='round'
          maxSizeMib={3}
          guid={userData?.Guid ?? ''}
          onChangeCropper={async ({ fileCropped }: any) => {
            setUpdateAvatarProfile(true)
            await uploadFile(fileCropped)
            setUpdateAvatarProfile(false)
          }}
        />
      </CFormGroup>
     {/* </FieldsSection> */}
    </>
  )
}

export default React.memo(UserProfilePicture)
