import { CCol, CFormGroup } from '@coreui/react'
import React, { useEffect, useRef } from 'react'
import { ComboBox, FieldsSection, InputField } from 'src/shared'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'

// const tokenTypeData = [
//   {
//     CodeId: 'Admin',
//     Description: 'Admin',
//   },
//   {
//     CodeId: 'Application',
//     Description: 'Application',
//   },
//   {
//     CodeId: 'Member',
//     Description: 'Member',
//   },
// ]
interface PropsScenariosInfo {
  scenarioData?: any
  setIsJSONValid?: any
  handleChange?: any
  endpointsData?: any
  handleComboChange?: any
}
const ScenariosInfo: React.FC<PropsScenariosInfo> = ({
  scenarioData,
  setIsJSONValid,
  handleChange,
  endpointsData,
  handleComboChange,
}) => {
  const isValid = (value: any) => {
    setIsJSONValid(value)
  }
  const editorRef = useRef<any>(null)
  useEffect(() => {
    editorRef.current?.beautifyCode()
  }, [])
  return (
    <FieldsSection header='Scenario Info'>
      <CFormGroup row>
        <CCol xs='12'>
          <ComboBox
            showButton
            name='ServicesId'
            label='Endpoint'
            data={endpointsData}
            value={endpointsData.find(
              (endpoint: any) => endpoint.CodeId === scenarioData.EndpointsId,
            )}
            handleChange={(e: any, newValue = {}) => handleComboChange(e, newValue, 'EndpointsId')}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
            )}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={scenarioData.Name}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Description'
            type='text'
            label='Description'
            value={scenarioData.Description}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      {/* <CFormGroup row>
        <CCol xs='12'>
          <ComboBox
            showButton
            name='TokenType'
            label='Token Type'
            data={tokenTypeData}
            value={tokenTypeData.find((tokenData) => tokenData.CodeId === scenarioData.TokenType)}
            handleChange={(e, newValue = {}) => handleComboChange(e, newValue, 'TokenType')}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
            )}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Token'
            type='textarea'
            label='Token'
            value={scenarioData.Token}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup> */}
      <CFormGroup row>
        <CCol xs='12' sm='6' className='pr-lg-0 mb-3 my-lg-0'>
          <InputField
            name='ExpectedStatusCode'
            type='text'
            label='Expected Status Code'
            value={scenarioData.ExpectedStatusCode}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' sm='6'>
          <InputField
            name='ExpectedStatusMessage'
            type='text'
            label='Expected Status Message'
            value={scenarioData.ExpectedStatusMessage}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='ExpectedResultDescription'
            type='textarea'
            label='ExpectedResultDescription'
            value={scenarioData.ExpectedResultDescription}
            onChange={handleChange}
            textAreaProps={{ rows: 5 }}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Payload'
            labelStyles={{ marginBottom: '1rem' }}
            rootStyles={{ padding: '20px 15px 5px', height: '300px' }}
            label='Payload'
          >
            <CustomCodeEditor
              ref={editorRef}
              name='Payload'
              mode='json'
              value={scenarioData.Payload}
              height='250px'
              onChange={(value: any) => handleChange({ name: 'Payload', value })}
              isValid={isValid}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      {/* <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='ExpectedResultJson'
            labelStyles={{ marginBottom: '1rem' }}
            rootStyles={{ padding: '20px 15px 5px' }}
            label='ExpectedResultJson'
          >
            <CustomCodeEditor
              name='ExpectedResultJson'
              mode='json'
              value={scenarioData.ExpectedResultJson}
              height='250px'
              onChange={(value) => handleChange({ name: 'ExpectedResultJson', value })}
              isValid={isValid}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </FieldsSection>
  )
}

export default ScenariosInfo
