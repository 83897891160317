// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  handleChangeMaxLength: any
  handleAddressChange: any
  title: string
}

const Vehicle = ({ modelData, handleChange, handleChangeMaxLength, title = 'Model Name' }: PropsInfoForm) => {
  const { Vehicle, VehicleVin, VehicleMileage, VehicleColor } = modelData

  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='Vehicle'
            type='text'
            label='Name'
            value={Vehicle ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='VehicleVin'
            type='text'
            label='Vin'
            value={VehicleVin ?? ''}
            max={2}
            onChange={handleChangeMaxLength}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='VehicleMileage'
            type='number'
            label='Mileage'
            value={VehicleMileage ?? ''}
            onChange={handleChange}
            alignment='right'
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='VehicleColor'
            type='text'
            label='Color'
            value={VehicleColor ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default Vehicle
