// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const OwnershipInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const {
    RegisteredOwner,
    IsLienholder,
    Lienholder,
    Payoff,
    PayoffExpiresOn,
    LastTitleIssuedOn,
    TitleNumber,
    LastOdometer,
  } = modelData
  
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            label='Registered Owner'
            value={RegisteredOwner ?? '⠀'}
          />
          <InputLabel 
            label='Lien'
            value={IsLienholder ? IsLienholder.toString() : 'False'}
          />
          <InputLabel 
            label='Lien Bank'
            value={Lienholder ?? '⠀'}
          />
          <InputLabel 
            label='Payoff'
            value={Payoff ? `$${Payoff}` : '⠀'}
          />
          <InputLabel 
            label='Payoff Expires On'
            value={PayoffExpiresOn ?? '⠀'}
          />
          <InputLabel 
            label='Last Title Issued Date'
            value={LastTitleIssuedOn ?? '⠀'}
          />
          <InputLabel 
            label='Title Number'
            value={TitleNumber ?? '⠀'}
          />
          <InputLabel 
            label='Last Odometer'
            value={LastOdometer ?? '⠀'}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default OwnershipInfo
