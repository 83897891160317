import { CButton, CCol, CFormGroup, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react'
import { InputField } from 'src/shared'
// import { useActionsModels } from './hooks/ActionsModels'
import { StatusInfo } from './enum/Status'

export const CustomerService = ({
  serviceData, 
  customerData, 
  modal, 
  toggle,
  apiData,
  status,
  saveData,
  handleChange,
  inspectorData,
  fetchCustomerServicesList
}) => {
  // const id = 0 
  // const modelName = 'CustomersServices'
  // const [apiData, status, saveData, handleChange] = useActionsModels({
  //   id,
  //   modelName,
  //   // history,
  // })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <>.</>
  }
  const { modelData } = apiData

  // modelData.Description = serviceData.Description
  // const defaultCost = 
  const defaultPrice = serviceData.Price
  const defaultCost = serviceData.CostMeasure === 'Per Hour' ? (inspectorData.HourlyRate || 1)*(serviceData.WorkHours || 1) : serviceData.Cost
  const today = () => {
    const date = new window.Date()
    let year = date.getFullYear()
    let month = date.getMonth()+1
    let day = date.getDate()

    return `${year}-${month<10?`0${month}`:`${month}`}-${day<10?`0${day}`:`${day}`}`
  }
  let {
    InspectorFirstName = inspectorData?.FirstName,
    InspectorLastName = inspectorData?.LastName,
    // CustomersId = customerData.Id,
    // ServicesId = serviceData.Id,
    Date = today(),
    // Name = serviceData.Name,
    Description = serviceData.Description,
    Cost = defaultCost,
    Price = defaultPrice,
  } = modelData

  modelData.InspectorsId = inspectorData?.Id  
  if (!modelData.Name) modelData.Name = serviceData.Name
  if (!modelData.Description) modelData.Description = serviceData.Description
  if (!modelData.Cost) modelData.Cost = defaultCost
  if (!modelData.Price) modelData.Price = defaultPrice
  if (!modelData.Date) modelData.Date = today()

  modelData.Id = 0
  modelData.ServicesId = serviceData['Id']
  modelData.CustomersId = customerData.Id
  // modelData.Description = Description
  
  return (
    <>
    <CModal
        size='xl'
        show={modal}
        onClose={toggle}
      >      
      <CModalHeader closeButton>Add Customer Service</CModalHeader>
        <CModalBody>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2'>
            <div style={{fontSize: '17px' , color:'#000', display:'flex', alignItems: 'center'}}>
              Assigned To - {InspectorFirstName} {InspectorLastName}
            </div>
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2'>
            <div style={{fontSize: '17px' , color:'#000', display:'flex', alignItems: 'center'}}>
              <b>{serviceData.Name}</b>
            </div>
          </CCol>
        </CFormGroup>
            <CFormGroup row>
              <CCol md='4' className='pr-lg-2 pl-lg-2'>
                <InputField
                  name='Date'
                  type='date'
                  label='Date'
                  value={Date ?? ''}
                  onChange={handleChange}
                />
              </CCol>
              <CCol md='4' className='pr-lg-2 pl-lg-2'>
                <InputField
                  name='Price'
                  type='currency'
                  label='Amount'
                  value={Price ?? ''}
                  decimals={2}
                  showSeparator
                  alignment='right'
                  onChange={({ name, value }) => {
                    handleChange({
                      name,
                      value: value.replaceAll('$', ''),
                    })
                  }}
                />
              </CCol>
              <CCol md='4' className='pr-lg-2 pl-lg-2'>
                <InputField
                  name='Cost'
                  type='currency'
                  label='Cost'
                  value={Cost ?? ''}
                  decimals={2}
                  showSeparator
                  alignment='right'
                  onChange={({ name, value }) => {
                    handleChange({
                      name,
                      value: value.replaceAll('$', ''),
                    })
                  }}
                />
              </CCol>
            </CFormGroup>
        <CFormGroup row>
          <CCol style={{marginBottom: '4em', marginTop: '1em'}} md='12' className='pr-lg-2 pl-lg-2'>
            <InputField
              name='Description'
              type='textarea'
              label='Description'
              value={Description ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>

        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={()=>{saveData();fetchCustomerServicesList();toggle();}}>Save</CButton>{' '}
          <CButton
            color="secondary"
            onClick={toggle}
          >Cancel</CButton>
        </CModalFooter>
      </CModal>  

            {/* <CCol md='6' className='pr-lg-2 pl-lg-2'>
              <InputField
                name='Amount'
                type='text'
                label='Amount'
                value={Amount ?? ''}
                onChange={handleNumberChange}
                alignment='right'
                className='item-number-input'
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md='12' className='pr-lg-2 pl-lg-2'>
              <InputField
                name='Description'
                type='textarea'
                label='Description'
                value={Description ?? ''}
                onChange={handleChange}
              />
            </CCol> */}
          {/* </CFormGroup> */}
    </>
    
  )
}
