import React from 'react'
import {
  CHeader,
  // CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  // CHeaderNavLink,
  // CSubheader,
  // CLink,
} from '@coreui/react'
// import CIcon from '@coreui/icons-react'

// routes config
// import routes from '../../routes'
import { connect } from 'react-redux'
import { setSidebarShow } from '../../redux/actions/SidebarActions'

import { TheHeaderDropdown } from '../index'
// import { CBreadcrumbRouter } from './components'

const TheHeader: React.FC<any> = (props) => {
  // const toggleSidebar = () => {
  //   const val = [true, 'responsive'].includes(props.show) ? false : 'responsive'
  //   props.setSidebarShow(val)
  // }

  // const toggleSidebarMobile = () => {
  //   const val = [false, 'responsive'].includes(props.show) ? true : 'responsive'
  //   props.setSidebarShow(val)
  // }

  return (
    <CHeader withSubheader style={{borderBottom: '1.2pt solid red'}}>
      {/* <CToggler inHeader className='ml-md-3 d-lg-none' onClick={toggleSidebarMobile} /> */}
      {/* <CToggler inHeader className='ml-3 d-md-down-none' onClick={toggleSidebar} /> */}
      <CHeaderBrand className='mx-auto d-lg-none' to='/'>
        {/* <CIcon name='logo' height='48' alt='Logo' /> */}
        {/* <img src='/logo.png' alt='logo' height='42' /> */}
      </CHeaderBrand>

      <CHeaderNav className='d-md-down-none mr-auto'>
        <CHeaderNavItem className='px-3'>
          {/* <CHeaderNavLink to='/dashboard'>Vital Card Dashboard</CHeaderNavLink> */}
          {/* <CBreadcrumbRouter
            className='border-0 c-subheader-nav m-0 px-0 px-md-3'
            routes={props.routes}
          /> */}
        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav>
        <TheHeaderDropdown logoutHandler={props.logoutHandler} />
      </CHeaderNav>

      {/* <CSubheader className='px-3 justify-content-between'>
        <CBreadcrumbRouter className='border-0 c-subheader-nav m-0 px-0 px-md-3' routes={routes} />
      </CSubheader> */}
    </CHeader>
  )
}

const mapStateToProps = (state: any) => {
  const { sidebar } = state
  return {
    show: sidebar.sidebarShow,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setSidebarShow: (data: any) => dispatch(setSidebarShow(data)),
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(TheHeader))
