import React from 'react'
import { CCard, CCardBody, CCol, CRow, CTabPane } from '@coreui/react'
import { LookupsRepository } from 'src/repository/admin/lookups/LookupsRepository'
import { DeleteModal, Loader } from 'src/shared'
import {
  LookupsHeader,
  LookupsTypeInformation,
  // LookupsDetailTabSection,
  EditLookupValueModal,
  NotesInfo,
  LookupInfo,
} from './components'
import { LOOKUP_TYPES_MODEL, LOOKUPS_MODEL, PROCGENERATE_LOOKUPVIEW } from 'src/services/routeConst'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'

const fields = ['Id', 'Name', 'Description', 'DatabaseName']
interface PropsLookupDetails {
  history?: any
  match?: any
  modelName?: any
}
const LookupDetails: React.FC<PropsLookupDetails> = ({
  history,
  match,
  modelName = LOOKUP_TYPES_MODEL,
}) => {
  const [apiData, setApiData] = React.useState<any>({ lookupsData: null })
  const [status, setStatus] = React.useState<any>('loading')
  const [showDeleteModal, setShowDeleteModal] = React.useState<any>(false)
  const [showEditModal, setShowEditModal] = React.useState<any>(false)
  const clickedIDRef = React.useRef<any>(-1)
  const tableRef = React.useRef<any>()
  const changesRef = React.useRef<any>()

  const { id = 0 } = match.params

  const lookupsRepository = React.useMemo(() => new LookupsRepository(), [])

  const getData = React.useCallback(
    async (shouldUpdateRefs = true) => {
      try {
        const { mainData } = await lookupsRepository.getData({
          id,
          modelName,
          defaultMainData: { Id: 0 },
        })
        // console.log(mainData)
        const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
        setApiData({ lookupsData: dataResponse })
        setStatus('idle')
        id && shouldUpdateRefs && updateRefs()
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [id, lookupsRepository, modelName],
  )

  const updateRefs = () => {
    tableRef.current.fetchNewData()
    changesRef.current.fetchNewData()
  }

  React.useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ lookupsData: null })
      history.goBack()
    }, delay)
  }

  const saveData = async () => {
    setStatus('saving')
    const dataToSave: any = apiData.lookupsData
    try {
      await lookupsRepository.saveData({
        modelName,
        idName: 'Id',
        dataToSave: Object.assign(
          {},
          ...fields.map((field) => ({
            [field]: dataToSave[field],
          })),
        ),
        getData,
        goBack,
      })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const generateView = async () => {
    setStatus('generating')
    try {
      await lookupsRepository.procGen({
        procType: PROCGENERATE_LOOKUPVIEW,
        params: {
          TypesId: apiData.lookupsData.TypesId,
          DatabaseName: 'ADMIN',
          SchemaName: 'lookup',
        },
      })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setApiData((st: any) => ({ ...st, lookupsData: { ...st.lookupsData, [name]: value } }))
  }

  // const viewDetails = ({ id }) => {
  //   setApiData({ lookupsData: null, lookupValues: [] })
  //   routeToPage(history, `/Admin/Lookups/Detail/${id}`)
  // }

  /* const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const showEditModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    // console.log({ id })
    setShowEditModal(true)
  } */

  /* const actionFunctions: any = {
    edit: showEditModalFunc,
    delete: showDeleteModalFunc,
  } */

  if (!apiData.lookupsData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }
  const { lookupsData } = apiData
  return (
    <>
      <CCard style={{ border: 'none' }}>
        <LookupsHeader
          id={id}
          generateView={generateView}
          status={status}
          saveData={saveData}
          onClickCancel={() => {
            setApiData({ lookupsData: null })
            history.goBack()
          }}
        />
        <CCardBody>
          <CRow>
            <CCol>
              <DetailTabSection
                id={id}
                hideNotes={true}
                modelName={modelName}
                tabs={[
                  { name: 'Details', label: 'Type Information' },
                  { name: 'lookup', label: 'Lookup' },
                ]}
              >
                <CTabPane data-tab='Details' className='detail-tab-section-pane'>
                  <LookupsTypeInformation lookupsData={lookupsData} handleChange={handleChange} />

                  {/* {id ? (
              <CCol xs='12' md='6'>
                <LookupsDetailTabSection
                  id={id}
                  Guid={lookupsData.Guid}
                  lookupsData={lookupsData}
                  modelName={modelName}
                  actionFunctions={actionFunctions}
                  tableRef={tableRef}
                  changesRef={changesRef}
                  showEditModalFunc={showEditModalFunc}
                />
              </CCol>
            ) : null} */}
                </CTabPane>
                <CTabPane data-tab='lookup' className='detail-tab-section-pane'>
                  <LookupInfo id={id} />
                </CTabPane>
              </DetailTabSection>
            </CCol>
            <CCol xs={5} style={{ marginTop: '0' }}>
              {lookupsData.Id !== 0 && lookupsData.MetasId !== null ? (
                <DetailTabSection
                  id={0}
                  metasId={lookupsData.MetasId}
                  hideNotes
                  tabs={[{ name: 'Notes', label: 'Notes' }]}
                >
                  <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
                    <NotesInfo MetasId={lookupsData.MetasId}></NotesInfo>
                  </CTabPane>
                </DetailTabSection>
              ) : (
                <></>
              )}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{
          modelName: LOOKUPS_MODEL,
          label: 'Lookup',
          id: clickedIDRef.current,
        }}
        callBack={updateRefs}
      />
      <EditLookupValueModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        modelName={LOOKUPS_MODEL}
        modalData={{ id: clickedIDRef.current, lookup_id: id, lookupTypeData: lookupsData }}
        callBack={updateRefs}
      />
    </>
  )
}

export default LookupDetails
