import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
interface PropsUserInfo {
  userData?: any
  handleChange?: any
  handlePhoneChange?: any
  showVerifyOTPModalFunc?: any
  sendingOTP?: any
  showVerifyEmailModal?: any
  showVerifyPhoneModal?: any
}
const UserInfo: React.FC<PropsUserInfo> = ({
  userData,
  handleChange,
  handlePhoneChange,
  showVerifyOTPModalFunc,
  sendingOTP,
  showVerifyEmailModal,
  showVerifyPhoneModal,
}) => {
  const btnRef = React.useRef<any>()
  const { FirstName, LastName, Phone, Email, RoleName, RoleDescription } = userData

  const renderPhoneButton = () => {
    const unClickable = sendingOTP === 'Phone' || showVerifyPhoneModal
    return (
      <small
        ref={btnRef}
        className={`input-field-button ${unClickable ? 'text-muted' : ''}`}
        onClick={() => {
          !unClickable && showVerifyOTPModalFunc({ type: 'Phone', sendTo: Phone })
        }}
      >
        Verify
      </small>
    )
  }

  const renderEmailButton = () => {
    const unClickable = sendingOTP === 'Email' || showVerifyEmailModal
    return (
      <small
        ref={btnRef}
        className={`input-field-button ${unClickable ? 'text-muted' : ''}`}
        onClick={() => {
          !unClickable && showVerifyOTPModalFunc({ type: 'Email', sendTo: Email })
        }}
      >
        Verify
      </small>
    )
  }

  return (
    <FieldsSection header='User Info'>
      <CFormGroup row>
        {/* <CCol xs='12' sm='4'>
          <InputField
            name='UserName'
            type='username'
            label='User Name'
            value={UserName}
            onChange={handleChange}
          />
        </CCol> */}
        <CCol xs='12' sm='6' className='pr-lg-0 mb-3 my-lg-0'>
          <InputField
            name='FirstName'
            type='text'
            label='First Name'
            value={FirstName ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' sm='6'>
          <InputField
            name='LastName'
            type='text'
            label='Last Name'
            value={LastName ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12'>
          <InputField
            name='Phone'
            type='phone'
            label='Phone'
            value={Phone ?? ''}
            onChange={handlePhoneChange}
            verifyFunc={() => showVerifyOTPModalFunc({ type: 'Phone', sendTo: Phone })}
            showButton
            ButtonComponent={renderPhoneButton}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12'>
          <InputField
            name='Email'
            type='email'
            label='Email'
            value={Email ?? ''}
            onChange={handleChange}
            verifyFunc={() => showVerifyOTPModalFunc({ type: 'Email', sendTo: Email })}
            showButton
            ButtonComponent={renderEmailButton}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12'>
          <InputField
            name='RoleName'
            type='text'
            label='Role Name'
            value={RoleName ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12'>
          <InputField
            name='RoleDescription'
            type='text'
            label='Role Description'
            value={RoleDescription ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default React.memo(UserInfo)

// const UserInfo = ({ userData, handleChange, handlePhoneChange }) => {
//   return (
//     <FieldsSection header='User Info'>
//       <CFormGroup row>
//         <CCol xs='12' sm='4'>
//           <CLabel htmlFor='UsersId'>User ID</CLabel>
//           <TextField
//             name='UsersId'
//             id='UsersId'
//             type='number'
//             alignment='left'
//             disabled
//             initialValue={userData.UsersId}
//             handleChange={handleChange}
//             className='mb-3'
//             autoComplete='off'
//             component={CInput}
//           />
//         </CCol>
//       </CFormGroup>
//       <CFormGroup row>
//         <CCol xs='12' sm='4'>
//           <CLabel htmlFor='UserName'>User Name</CLabel>
//           <TextField
//             name='UserName'
//             id='UserName'
//             initialValue={userData.UserName}
//             handleChange={handleChange}
//             className='mb-3'
//             autoComplete='username'
//             component={CInput}
//           />
//         </CCol>
//         <CCol xs='12' sm='4'>
//           <CLabel htmlFor='FirstName'>First Name</CLabel>
//           <TextField
//             name='FirstName'
//             id='FirstName'
//             initialValue={userData.FirstName}
//             handleChange={handleChange}
//             className='mb-3'
//             autoComplete='off'
//             component={CInput}
//           />
//         </CCol>
//         <CCol xs='12' sm='4'>
//           <CLabel htmlFor='LastName'>Last Name</CLabel>
//           <TextField
//             name='LastName'
//             id='LastName'
//             initialValue={userData.LastName}
//             handleChange={handleChange}
//             className='mb-3'
//             autoComplete='off'
//             component={CInput}
//           />
//         </CCol>
//       </CFormGroup>
//       <CFormGroup row>
//         <CCol xs='12'>
//           <CLabel htmlFor='Phone'>Phone</CLabel>
//           <TextField
//             name='Phone'
//             id='Phone'
//             type='phone'
//             initialValue={userData.Phone}
//             handleChange={handlePhoneChange}
//             mask='(###) ###-####'
//             maskCharacter='#'
//             className='mb-3'
//             autoComplete='phone'
//             component={CInput}
//           />
//         </CCol>
//       </CFormGroup>
//       <CFormGroup row>
//         <CCol xs='12'>
//           <CLabel htmlFor='Email'>Email</CLabel>
//           <TextField
//             name='Email'
//             id='Email'
//             type='email'
//             initialValue={userData.Email}
//             handleChange={handleChange}
//             className='mb-3'
//             autoComplete='email'
//             component={CInput}
//           />
//         </CCol>
//       </CFormGroup>
//     </FieldsSection>
//   )
// }
// export default React.memo(UserInfo)
