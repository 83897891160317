import { CFormGroup } from '@coreui/react'
import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { addAuthAccessTokenToHeader } from 'src/services/authHelper'
import { RetrieveModel } from 'src/services/modelNew/Retrieve/Retrieve'
import { FileUpload } from 'src/services/modelNew/Widget/File/Upload'
import { BASE_API, MODELS, SAVE, SERVICES } from 'src/services/routeConst'
import { CropperLogo } from 'src/shared'
interface PropsApplicationProfilePicture {
  modelData?: any
  updateProfilePictureID?: any
}
const ApplicationProfilePicture: React.FC<PropsApplicationProfilePicture> = ({
  modelData,
  updateProfilePictureID,
}) => {
  //console.log(modelData, 'ModelData')
  const [filesId, setFilesId] = useState(0)
  const uploadFile = async (file: any) => {
    const { data, error } = await FileUpload({
      Id: modelData.FilesId_Avatar,
      MetasId: modelData.MetasId,
      fileToUpload: file,
      Description: 'File',
    })
    await console.log('dataFILES LOGO',data);
    
    await axios.patch(
      `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
      {
        // [idName]: Id,
        Model: 'Applications',
        Id: modelData.Id,
        // Extensions: body.Extension ?? null,
        Params: [{
          Name: "FilesId_Avatar", Value: data?.FileInfo?.Id
        }],
      },
      { ...addAuthAccessTokenToHeader() },
    )

    !error && data && data.FilesId && updateProfilePictureID(data.FilesId)
    toast.success('Profile Picture Updated!')
  }
  const getData = useCallback(async () => {
    const { data } = await RetrieveModel({
      data: {
        Id: modelData.FilesId_Avatar,
        Model: 'Files',
        Params: [],
      },
    })
    console.log('data,logo',data);
    
    setFilesId(data.data.RetrieveInfo[0].Guid)
  }, [modelData.FilesId_Avatar])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <>
    {/* <FieldsSection header='Profile Picture'> */}
      <CFormGroup row className='justify-content-center'>
        <CropperLogo
          cropShape={'round'}
          maxSizeMib={3}
          aspectRatio={1/1}
          guid={filesId ?? 0}
          onChangeCropper={({fileCropped, originalFile }: any) => {
            console.log(originalFile, 'originalFile')
            uploadFile(fileCropped)
          }}
        />
      </CFormGroup>
    {/* </FieldsSection> */}
    </>
  )
}

export default React.memo(ApplicationProfilePicture)
