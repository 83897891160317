// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { InputField } from 'src/shared'
import LoadingButton from 'src/shared/components/LoadingButton'

const SmsInfo = ({ status, saveData ,modelData, handleChange }) => {
  const { PhoneNo } = modelData
  const isLoading = status === 'saving'
  return (
    <>
      {/* <CFormGroup row>
        <CCol xs='6' sm='6'>
          <InputField
            name='GroupName'
            type='text'
            label='GroupName'
            value={GroupName ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='6' sm='6'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={Name ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup> */}
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='PhoneNo'
            type='phone'
            label='PhoneNo'
            value={PhoneNo ?? ''}
            onChange={handleChange}
          />
        </CCol>

        
      </CFormGroup>

      <CFormGroup row>
        <CCol xs='12' sm='12'>
            <LoadingButton
            className='mr-1 ml-auto'
            style={{ float: 'right' }}
            loading={isLoading}
            onClick={saveData}
          >
            Submit
          </LoadingButton>
        </CCol>
      </CFormGroup>
    </>
  )
}

export default SmsInfo
