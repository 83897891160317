// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
const ProceduresInfo = ({ modelData, handleChange }) => {
  const { StampStart, StampEnd, ExecutionTime, IsError, ChangedOn, ProcedureName } = modelData

  return (
    <FieldsSection header='Procedure Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='ProcedureName'
            type='text'
            label='ProcedureName'
            value={ProcedureName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='StampStart'
            type='text'
            label='StampStart'
            value={StampStart}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='StampEnd'
            type='text'
            label='StampEnd'
            value={StampEnd}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='ExecutionTime'
            type='text'
            label='ExecutionTime'
            value={ExecutionTime}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='ChangedOn'
            type='text'
            label='Changed On'
            value={ChangedOn}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default ProceduresInfo
