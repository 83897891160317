import { useEffect } from 'react';
// import { DropdownButton, Dropdown } from "react-bootstrap";
import axios from 'axios';
import { BASE_API, LIST, MODELS, SERVICES } from 'src/services/routeConst';
import { getAuthToken } from 'src/services/authHelper';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';

type ButtonGroupDrpProps = {
    isCalled: boolean;
    drpText: string;
    setDrpBtnData: any;
    setWorkFlowActions: any;
    LookupStartId: any;
    clickWorkFlow: any
    drpBtnData: any[];
}

const ButtonGroupDrpComponent = ({ isCalled, drpText, setDrpBtnData, setWorkFlowActions, LookupStartId, clickWorkFlow, drpBtnData }: ButtonGroupDrpProps) => {

    useEffect(() => {
        if (isCalled) {
            getDropdownBtnData();
        }
    }, [isCalled]);


    const getDropdownBtnData = () => {

        let token = getAuthToken('ADMIN')
        axios({
          method: 'POST',
          url: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
          data: {
            Model: 'WorkflowsActions',
            Extension: "",
            Params: [
              {
                Name: 'SearchString',
                Value: null,
              },
              {
                Name: 'OrderBy',
                Value: null,
              },
              {
                Name: 'OrderByDir',
                Value: null,
              },
              {
                Name: 'Page',
                Value: null,
              },
              {
                Name: 'PageSize',
                Value: null,
              },
              {
                Name: 'LookupsId_Start',
                Value: LookupStartId,
              },
            ],
          },
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        }).then((res) => {
            if (res.data) {
                setWorkFlowActions(res.data.data);
                let wData = res.data.data.map((route: any) => {
                    return {
                        action: route.LookupsId_End,
                        title: route.Name
                    }
                })
                setDrpBtnData(wData)
            }
        }).catch(() => {
            
        })
    }

    return (
        <div className="breadCrumbsBtn">
            <CDropdown>
                <CDropdownToggle caret color="info" size={'lg'}>
                    {drpText}
                </CDropdownToggle>
                <CDropdownMenu>
                    { drpBtnData.map((res: any, key: any) => {
                        return (
                            <CDropdownItem key={key} onClick={() => { clickWorkFlow(res.action) }} as="button"><span style={{ whiteSpace: 'break-spaces' }}>{res.title}</span></CDropdownItem>
                            )
                        })
                    }
                </CDropdownMenu>
            </CDropdown>
        </div>
    )

}


export default ButtonGroupDrpComponent