import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import PeopleIcon from 'src/assets/icons/PeopleIcon'
import ChartBarSimple from 'src/shared/components/charts/ChartBarSimple'
import ChartLineSimple from 'src/shared/components/charts/ChartLineSimple'
import WidgetDropdown from 'src/shared/components/widgets/WidgetDropdown'
import CardCustomer from './CardCustomer'
import MainBarChart from './MainBarChart'

export function JemDashboard({
  cardData,
  customersData,
  routeChangeJemcare,
  loggedInUserId,
  leadsList,
}) {
  return (
    <div style={{ paddingBottom: '80px' }}>
      <CRow style={{ padding: 10 }} className='custom-width'>
        {/* <CCol sm='6' lg='3'>
          <KPIWidget
            data={{ membersToday }}
            color='gradient-primary'
            colorName='primary'
            header='9,823'
            text='Members online'
            label='Members'
            labels='months'
            dataText='Today'
            dataLabel={`${membersToday}%`}
          />
        </CCol> */}
        <CCol sm='6' lg='3'>
          <WidgetDropdown
            onClick={() => routeChangeJemcare('Search')}
            color='gradient-primary'
            header={cardData.Applications == 0 ? '0' : cardData.Applications}
            text='Applications'
            footerSlot={
              <ChartLineSimple
                pointed
                className='c-chart-wrapper mt-3 mx-3'
                style={{ height: '70px' }}
                dataPoints={[65, 59, 84, 84, 51, 55, 40]}
                pointHoverBackgroundColor='primary'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/* <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            onClick={() => routeChangeJemcare('Received')}
            color='gradient-success'
            header={cardData.Pending == 0 ? '0' : cardData.Pending}
            text='Pending/Arrived'
            footerSlot={
              <ChartLineSimple
                pointed
                className='c-chart-wrapper mt-3 mx-3'
                style={{ height: '70px' }}
                dataPoints={[65, 59, 84, 84, 51, 55, 40]}
                pointHoverBackgroundColor='primary'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          ></WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            onClick={() => routeChangeJemcare('InProcess')}
            color='gradient-info'
            header={cardData.InProcess == 0 ? '0' : cardData.InProcess}
            text='Intake In Process'
            footerSlot={
              <ChartLineSimple
                pointed
                className='mt-3 mx-3'
                style={{ height: '70px' }}
                dataPoints={[1, 18, 9, 17, 34, 22, 11]}
                pointHoverBackgroundColor='info'
                options={{ elements: { line: { tension: 0.00001 } } }}
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/*  <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday2 >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday2}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday2 >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            onClick={() => routeChangeJemcare('Approved')}
            color='gradient-warning'
            header={cardData.Approved == 0 ? '0' : cardData.Approved}
            text='Intake Approved'
            footerSlot={
              <ChartLineSimple
                className='mt-3'
                style={{ height: '70px' }}
                backgroundColor='rgba(255,255,255,.2)'
                dataPoints={[78, 81, 80, 45, 34, 12, 40]}
                options={{ elements: { line: { borderWidth: 2.5 } } }}
                pointHoverBackgroundColor='warning'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/*  <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday3 >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday3}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday3 >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            onClick={() => routeChangeJemcare('Decline')}
            color='gradient-danger'
            header={cardData.Declined == 0 ? '0' : cardData.Declined}
            text='Intake Decline'
            footerSlot={
              <ChartBarSimple
                className='mt-3 mx-3'
                style={{ height: '70px' }}
                backgroundColor='rgb(250, 152, 152)'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/* <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday4 >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday4}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday4 >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>
      </CRow>
      <CRow style={{ padding: 10 }} className='custom-width'>
        <CCol md='8' className='pr-lg-0 pl-lg-3 pb-5'>
          <table className='leadsTable' style={{ width: '99%' }}>
            <thead>
              <tr>
                <th>Status</th>
                <th>Group Name</th>
                <th>Count No</th>
              </tr>
            </thead>

            {leadsList
              .sort((a) => (a.GroupName == 'InProgress' ? 1 : -1))
              .map((list) => (
                <tr>
                  <td>{list.Status}</td>
                  <td>{list.GroupName}</td>
                  <td>{list.CountNo}</td>
                </tr>
              ))}
          </table>
        </CCol>
        <CCol className='pr-lg-3 pl-lg-0'>
          <CCard>
            <CCardBody>
              <h5>Patients List</h5>
              {customersData.map((value) => (
                <div key={value['Id']} onClick={() => routeChangeJemcare(value['Id'])}>
                  <CardCustomer
                    key={value['id']}
                    firstname={value['FirstName']}
                    lastname={value['LastName']}
                    followupdate={value['FollowUpDate']}
                    phonemobile={value['CellPhone']}
                  />
                </div>
              ))}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow style={{ marginTop: '-25px' }}>
        <CCol md='8' className='pr-lg-0 pl-lg-3'>
          <CCard style={{ margin: 10 }}>
            <CCardBody>
              <CRow>
                <CCol sm='12'>
                  <h5 id='traffic' className='card-title mb-0 '>
                    No. of Applications vs. No. of Onboarded
                  </h5>
                </CCol>
              </CRow>
              <MainBarChart
                style={{ height: '300px', marginTop: '20px' }}
                loggedInUserId={loggedInUserId}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  )
}
