import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
interface PropsFieldsInfo {
  fieldData?: any
  handleChange?: any
}
const FieldsInfo: React.FC<PropsFieldsInfo> = ({ fieldData, handleChange }) => {
  return (
    <FieldsSection header='Model Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={fieldData.Name}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Description'
            type='text'
            label='Description'
            value={fieldData.Description}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='SelectQuery'
            type='textarea'
            label='Select SQL'
            value={fieldData.SelectQuery}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default FieldsInfo
