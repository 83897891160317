// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const PaymentInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const { 
    PaymentVehiclePurchasePrice,
    PaymentPayoffExpiration,
    PaymentPayoffOvernightAddress,
    PaymentPayoffAmount,
    PaymentPayoffDailyPerDiem,
    PaymentTotalAmountToCustomer,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            label={'Vehicle Purchase Price'}
            value={PaymentVehiclePurchasePrice ?? '⠀'}
          />
          <InputLabel
            label='Payoff Expiration'
            value={PaymentPayoffExpiration ?? '⠀'}
          />
          <InputLabel
            label='Payoff Overnight Address'
            value={PaymentPayoffOvernightAddress ?? '⠀'}
          />
          <InputLabel
            label='Payoff Amount'
            value={PaymentPayoffAmount ?? '⠀'}
          />
          <InputLabel
            label='Payoff Daily Per Diem'
            value={PaymentPayoffDailyPerDiem ?? '⠀'}
          />
          <InputLabel
            label='Total Amount To Customer'
            value={PaymentTotalAmountToCustomer ?? '⠀'}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default PaymentInfo
