import { CCol, CFormGroup } from '@coreui/react'
// import { ComboBox } from 'src/shared'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
import AddressFinderAux from 'src/shared/inputFields/AddressFinderAux'
interface PropsModelsInfo {
  modelData?: any
  conectionsData?: any
  handleChange?: any
  handleComboChange?: any
  handleAddressChange?: any
}

const ModelsInfo: React.FC<PropsModelsInfo> = ({
  modelData,
  handleAddressChange,
  handleChange
}) => {
  const { Address, Address2, State, City, ZipCode } = modelData
  const handleAddressDataChange = ({ value }: any) => {
    handleAddressChange(value)
  }

  return (
    <FieldsSection header='Account Information'>
      <CFormGroup row>
        <CCol xs='12' sm='6'>
          <InputField
            name='CompanyName'
            type='text'
            label='Company Name'
            value={modelData.Name}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' sm='6'>
          <InputField
            name='CompanyPhone'
            type='text'
            label='Company Phone'
            value={modelData.ShortName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup>
        <AddressFinderAux
          address={{ Address, Address2, City, State, ZipCode }}
          handleChange={handleChange}
          handleAddressDataChange={handleAddressDataChange}
        />
      </CFormGroup>
    </FieldsSection>
  )
}

export default ModelsInfo
