import { CSwitch } from '@coreui/react'
import React, { useRef } from 'react'
interface PropsCustomSwitch {
  name: any
  updateData: any
  value: boolean
  className?: string
  color?: string
  disabled?: boolean
}
const CustomSwitch: React.FC<PropsCustomSwitch> = ({
  name,
  updateData,
  value = false,
  className = '',
  color = 'primary',
  disabled = false,
}) => {
  const inputRef = useRef<any>()
  return (
    <CSwitch
      disabled={disabled}
      innerRef={inputRef}
      className={className}
      color={color}
      checked={value}
      style={{ marginBottom: '2rem !important', paddingBottom: '2rem !important' }}
      onChange={() => updateData(name, inputRef.current.checked)}
    />
  )
}

export default React.memo(CustomSwitch)
