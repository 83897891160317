import { SET_ICON } from '../actions/IconActions'

const initialState = { iconFontAwesome: '', iconName: '' }

function iconReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_ICON:
      return { ...state, iconFontAwesome: action.payload }
    default:
      return state
  }
}

export default iconReducer
