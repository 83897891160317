import React, { useEffect, useState } from 'react'
import {
  CRow,
  CCol,
  CCard,
  CCardBody,
} from '@coreui/react'
import { connect } from 'react-redux'
import ChartLineSimple from '../../shared/components/charts/ChartLineSimple'
import ChartBarSimple from '../../shared/components/charts/ChartBarSimple'
import WidgetDropdown from '../../shared/components/widgets/WidgetDropdown'
import PeopleIcon from 'src/assets/icons/PeopleIcon'
import axios from 'axios'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, LIST, MODELS, SERVICES } from 'src/services/routeConst'
import { useHistory } from 'react-router-dom'
import MainChart from './components/MainChart'
import CardCustomer from './components/CardCustomer'
const Dashboard: React.FC<any> = (props: any) => {
  const { loggedInUser = {} } = props;
  const [cardData, setCardData] = useState({ Applications: 0, Approved: 0, Declined: 0, InProcess: 0, Pending: 0 })
  const [customersData, setCustomersData] = useState([])

  const fetchCardData = async () => {
    try {
      const response = await axios.post(
        `https://api.softsmartinc.com/Services/vOld/Data/Execute`,
        {
          "DbName": "DbAdmin",
          "QueryOrProcedureName": "access.APPLICATIONS_DashboardJemcare",
          "Params": [
          ]
        },
        { ...addAuthToHeader('ADMIN') },
      )

      setCardData(response.data.data.Execution[0])
    } catch (error) {
      console.log(error)
    }
  }

  const fetchCustomersData = async () => {
    try {
      const responseCustomer = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}${LIST}`,
        {
          Model: "JemcareLeads",
          Extension: "FollowUp",
          Params: [{ Name: "UsersId", Value: loggedInUser.Auth.User.Id }]
        },
        { ...addAuthToHeader('ADMIN') },
      )
      setCustomersData(responseCustomer.data.data.ListInfo)
    } catch (error) {
      console.log(error)
    }
  }
  const history = useHistory()
  const routeChangeCustomer = (id: any) => {
    let path = `/Jemcare/Detail/${id}`;
    history.push(path);
  }

  const routeChangeJemcare = (id: any) => {
    let path = `/Jemcare/${id}`;
    history.push(path);
  }

  useEffect(() => {
    fetchCardData()
    fetchCustomersData()
  }, [])

  // const currencyFormat = new Intl.NumberFormat('en-US', {
  //   style: 'currency',
  //   currency: 'USD',
  //   minimumFractionDigits: 2
  // })

  // const todayMMDDAAAA = () => {
  //   const date = new window.Date()
  //   let year = date.getFullYear()
  //   let month = date.getMonth()+1
  //   let day = date.getDate()

  //   return `${month<10?`0${month}`:`${month}`}-${day<10?`0${day}`:`${day}`}-${year}`
  // }

  return (
    <div style={{ paddingBottom: '80px' }}>
      <CRow style={{ padding: 10 }} className='custom-width'>
        {/* <CCol sm='6' lg='3'>
          <KPIWidget
            data={{ membersToday }}
            color='gradient-primary'
            colorName='primary'
            header='9,823'
            text='Members online'
            label='Members'
            labels='months'
            dataText='Today'
            dataLabel={`${membersToday}%`}
          />
        </CCol> */}
        <CCol sm='6' lg='3'>
          <WidgetDropdown
            onClick={() => routeChangeJemcare('Search')}
            color='gradient-primary'
            header={cardData.Applications}
            text='Applications'
            footerSlot={
              <ChartLineSimple
                pointed
                className='c-chart-wrapper mt-3 mx-3'
                style={{ height: '70px' }}
                dataPoints={[65, 59, 84, 84, 51, 55, 40]}
                pointHoverBackgroundColor='primary'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/* <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            onClick={() => routeChangeJemcare('Received')}
            color='gradient-success'
            header={cardData.Pending}
            text='Pending/Arrived'
            footerSlot={
              <ChartLineSimple
                pointed
                className='c-chart-wrapper mt-3 mx-3'
                style={{ height: '70px' }}
                dataPoints={[65, 59, 84, 84, 51, 55, 40]}
                pointHoverBackgroundColor='primary'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            onClick={() => routeChangeJemcare('InProcess')}
            color='gradient-info'
            header={cardData.InProcess}
            text='Intake In Process'
            footerSlot={
              <ChartLineSimple
                pointed
                className='mt-3 mx-3'
                style={{ height: '70px' }}
                dataPoints={[1, 18, 9, 17, 34, 22, 11]}
                pointHoverBackgroundColor='info'
                options={{ elements: { line: { tension: 0.00001 } } }}
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/*  <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday2 >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday2}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday2 >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            onClick={() => routeChangeJemcare('Approved')}
            color='gradient-warning'
            header={cardData.Approved}
            text='Intake Approved'
            footerSlot={
              <ChartLineSimple
                className='mt-3'
                style={{ height: '70px' }}
                backgroundColor='rgba(255,255,255,.2)'
                dataPoints={[78, 81, 80, 45, 34, 12, 40]}
                options={{ elements: { line: { borderWidth: 2.5 } } }}
                pointHoverBackgroundColor='warning'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/*  <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday3 >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday3}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday3 >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            onClick={() => routeChangeJemcare('Decline')}
            color='gradient-danger'
            header={cardData.Declined}
            text='Intake Decline'
            footerSlot={
              <ChartBarSimple
                className='mt-3 mx-3'
                style={{ height: '70px' }}
                backgroundColor='rgb(250, 152, 152)'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/* <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday4 >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday4}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday4 >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>
      </CRow>
      <CRow style={{ marginTop: '-25px' }}>
        <CCol md='8' className='pr-lg-0 pl-lg-3'>
          <CCard style={{ margin: 10 }}>
            <CCardBody>
              <CRow>
                <CCol sm='12'>
                  <h5 id='traffic' className='card-title mb-0 '>
                    No. of Applications vs. No. of Onboarded
                  </h5>
                </CCol>
              </CRow>
              <MainChart style={{ height: '300px', marginTop: '20px' }} />

            </CCardBody>
          </CCard>
        </CCol>

        <CCol className='pr-lg-3 pl-lg-0'>
          <CCard style={{ margin: 10 }}>
            <CCardBody>
              <h5>Patients</h5>
              {customersData.map((value) => <div key={value['Id']} onClick={() => routeChangeCustomer(value['Id'])}>
                <CardCustomer
                  key={value['id']}
                  firstname={value['FirstName']}
                  lastname={value['LastName']}
                  followupdate={value['FollowUpDate']}
                  phonemobile={value['CellPhone']} />
              </div>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  return {
    loggedInUser: auth.loggedInUser
  }
}

export default React.memo(connect(mapStateToProps, null)(Dashboard))
