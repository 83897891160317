import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, PROC_GENERATE } from 'src/services/routeConst'
interface PropsProcImportColumn {
  procType: 'ChangeTracking' | 'EncryptedColumn'
  params?: any
}
interface Response {
  data: any
  status: any
  message: any
}
export const ProcImportColumn = async ({
  procType,
  params,
}: PropsProcImportColumn): Promise<Response> => {
  //URL {api}/ProcGenerate/List/{ModelName}
  try {
    const { data } = await axios.post(
      `${BASE_API}:${PROC_GENERATE}/Imports/${procType}`,
      { ...params },
      {
        ...addAuthToHeader('APPLICATION'),
      },
    )
    // console.log(data)
    return {
      data: data?.data,
      status: 'success',
      message: data?.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error Generating SP ${procType}!`,
      error: error.response,
    })
  }
}
