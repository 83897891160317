import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react'
import { useCallback, useEffect, useState } from 'react'

import { LinearProgressIndicator } from 'src/shared'
import { ListFiles } from 'src/services/modelNew/File/ListFiles'
import { DeleteFiles } from 'src/services/modelNew/File/DeleteFile'
import { FileUpload } from 'src/services/modelNew/Widget/File/Upload'
import axios from 'axios'
import { DragAndDropFile } from 'smart-inputs'
import { toast } from 'react-toastify'
import { getAuthToken } from 'src/services/authHelper'

export const JemcareFiles = ({ modelData }) => {
  const [file, setFile] = useState<any>([])
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const getAllData = useCallback(async () => {
    const { data } = await ListFiles({
      MetasId: modelData.MetasId,
    })
    console.log('files', data);

    setFiles(data.FilesList)
  }, [modelData.MetasId])

  const handleChange = (file) => {
    const filesToUpload = Array.from(file ?? [])
    setFile(filesToUpload)
  }

  const renderFilesToUpload = () => {
    return file.map((f: any, index) => (
      <CCard key={index}>
        <CCardHeader>
          <div style={{ color: 'dark !important' }}>
            <div>
              <b> OriginalName:</b> {f.name}
            </div>
            <div>
              <b> Type:</b> {f.type}
            </div>
          </div>
          <CButton
            disabled={loading}
            className='mr-2'
            type='button'
            color='primary'
            style={{ float: 'right' }}
            onClick={async () => {
              setLoading(true)
              await FileUpload({
                Id: 0,
                MetasId: modelData.MetasId,
                Description: f.name,
                fileToUpload: f,
              })

              const newArray = file.filter((e) => e.name !== f.name)
              await getAllData()
              setFile(newArray)
              toast.success('File uploaded successfully!')
              setLoading(false)
            }}
          >
            Upload
          </CButton>
        </CCardHeader>
      </CCard>
    ))
  }

  useEffect(() => {
    getAllData()
  }, [getAllData])

  const viewFile = async (url, originalName) => {
    axios({
      url,
      method: 'GET',
      headers: {
        authorization: `Bearer ${getAuthToken('ADMIN')}`,
      },
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', originalName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  return (
    <>
      {loading && <LinearProgressIndicator />}

      {renderFilesToUpload()}
      <DragAndDropFile
        handleChange={handleChange}
        multiple
        files={files}
        renderFile={(f) => (
          <CCard>
            <CCardBody className={'custom-card-body'}>
              <CRow>
                <CCol xs={2}>
                  {(f.OriginalName.indexOf('.pdf') !== -1) ? <img src="/icons/PDF.png" alt="img-fluid" /> : <img src="/icons/JPG.png" alt="img-fluid" />}
                </CCol>
                <CCol xs={10}>
                  <div>
                    <b> Original Name:</b> {f.OriginalName}
                  </div>
                  <div>
                    <b>Uploaded On:</b> {f.ChangedOn}
                  </div>
                  <CButton
                    className='mr-2 mt-3'
                    type='button'
                    size='sm'
                    color='primary'
                    onClick={() => {
                      const nameUrl = f.FileUrl.split('/')
                      const url = nameUrl[1].split('.')
                      viewFile(
                        `https://api.softsmartinc.com/Services/vOld/Widget/Files/Download/${url[0]}`,
                        f.OriginalName,
                      )
                    }}
                  >
                    Show
                  </CButton>
                  <CButton
                    className='mr-2 mt-3'
                    type='button'
                    color='danger'
                    size='sm'
                    onClick={async () => {
                      setLoading(true)
                      await DeleteFiles({
                        Id: f.Id,
                      })
                      await getAllData()
                      toast.success('Successfully deleted!')
                      setLoading(false)
                    }}
                  >
                    Delete
                  </CButton>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        )}
        name='File'
        types={['JPG', 'PNG', 'GIF', 'PDF']}
      />
    </>
  )
}

export default JemcareFiles
