import { ColumnData } from 'library-soft-datatable'

export const CustomerServicesTableColumns: ColumnData[] = [
  {
    label: 'Customer Name',
    name: 'CustomerName',
  },
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: 'Cost',
    name: 'Cost',
  },
  {
    label: 'Price',
    name: 'Price',
  },

  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
