import React from 'react'
import { useEffect, useState } from 'react'
import { useGetMenuRoute } from 'src/hooks'
import { connect } from 'react-redux'
import { LinearProgressIndicator, Loader } from 'src/shared'
import { TheContent, TheSidebar, TheFooter, TheHeader } from './index'
interface DashBoardContext {
  showLinear?: boolean
  getMenuRoutes?: any
  setZoomMode?: any
  zoomMode?: any
  upscaled?: any
  setShowLinear?: any
  toggleUpscaled?: any
  updateAvatarProfile?: any
  setUpdateAvatarProfile?: any
}
export const DashboardContext = React.createContext<DashBoardContext>({})

const DashBoard: React.FC<any> = (props: any, { logoutHandler }: any) => {
  const { applicationRetrieve = {} } = props
  const { Id } = applicationRetrieve
  const [navData, getMenuRoutes] = useGetMenuRoute(Id)
  const [updateAvatarProfile, setUpdateAvatarProfile] = useState(false)
  const [data, setData] = useState(navData)
  const [showLinear, setShowLinear] = useState(false)
  // const dashBoardRef = useRef<HTMLDivElement>(null)
  const [zoomMode, setZoomMode] = useState('disabled')
  const [upscaled, setUpscaled] = useState(localStorage.getItem('upscaled') !== 'false')

  useEffect(() => {
    setData(navData)
  }, [navData])

  if (!navData.routes.length) {
    return <Loader fullScreen />
  }

  const toggleUpscaled = () => {
    localStorage.setItem('upscaled', '' + !upscaled)
    setUpscaled((st) => !st)
  }
  //console.log('RENDER DASHBOARD')
  return (
    <DashboardContext.Provider
      value={{
        showLinear,
        getMenuRoutes,
        setZoomMode,
        zoomMode,
        upscaled,
        setShowLinear,
        toggleUpscaled,
        setUpdateAvatarProfile,
        updateAvatarProfile,
      }}
    >
      <div
        style={{ fontSize: zoomMode === 'enabled' ? '1rem' : '0.875rem' }}
        className='c-app  c-default-layout'
      >
        <TheSidebar items={data.nav} />
        <div className='c-wrapper'>
          <TheHeader routes={data.routes} logoutHandler={logoutHandler} />
          <div
            style={{
              margin: 0,
              padding: 0,
              // height: 'calc(100vh - 107px)',
              // overflowY: 'auto',
              // overflowX: 'hidden',
            }}
          >
            {showLinear && <LinearProgressIndicator />}
            <TheContent routes={data.routes} />
          </div>
          <TheFooter />
        </div>
      </div>
    </DashboardContext.Provider>
  )
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  return {
    applicationRetrieve : auth.applicationRetrieve
  }
}

export default React.memo(connect(mapStateToProps, null)(DashBoard))
