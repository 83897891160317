import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, PROC_GENERATE } from 'src/services/routeConst'
interface PropsProcGenerate {
  procType?: string
  params?: any
}
export const ProcGenerate = async ({ procType = '', params = {} }: PropsProcGenerate) => {
  //URL {api}/ProcGenerate/List/{ModelName}
  try {
    const { data } = await axios.post(`${BASE_API}:${PROC_GENERATE}/${procType}`, params, {
      ...addAuthToHeader('ADMIN'),
    })
    // console.log(data)
    return {
      data: data.data,
      status: 'success',
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error Generating SP ${procType}!`,
      error: error.response,
    })
  }
}
