import {
  CAlert,
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import axios from 'axios'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Radio, RadioGroup } from 'react-radio-input'
import { toast } from 'react-toastify'
import { CheckBoxGroup } from 'smart-inputs'
import { ListModel } from 'src/services/modelNew/List/List'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { FACILITIES_MODEL, PHARMA_MODEL } from 'src/services/routeConst'
import { InputField, ComboBox } from 'src/shared'
import LoadingButton from '../components/LoadingButton'
const RadioGroupTs: any = RadioGroup

const fields = [
  'Id',
  'City',
  'State',
  'ZipCode',
  'FirstName',
  'LastName',
  'Address',
  'FacilitiesId',
  'Bin',
  'Pcn',
  'MedicareYesNo',
  'InsuranceName',
  'InsuranceGroup',
  'DateOfBirth',
  'InsuranceId',
  'PhoneCell',
  'UsersId',
  'PharmacyStatus',
  'PharmacyGuid',
  'IsSent'
]

const NewPharmaModal = ({ showModal, setShowModal, apiData, setApiData, pharmaId, /**theFacilitiesData, selectedFacility, setSelectedFacility,**/ callBack = () => { } }: any) => {

  const [theFacilitiesData, setTheFacilitiesData] = useState<any>([{ FacilitiesId: 0, Description: '' }])
  const [selectedFacility, setSelectedFacility] = useState<any>("")
  const [status, setStatus] = useState<any>('loading')
  const [theUsersData, setTheUsersData] = useState<any>([{ CodeId: 0, Description: '' }])
  const [selectedUsers, setSelectedUsers] = useState<any>([])
  const [loggedInUserId, setLoggedInUserId] = useState<any>([])
  const [intakeResponse, setIntakeResponse] = useState<any>([{ Id: null, IsError: '', Message: '' }])
  const [medicareYesNoState, setMedicareYesNoState] = useState<any>("empty")

  const changesRef = useRef<any>()

  const tableRef = useRef<any>()

  const formatDate = (date) => {
    if (date) {
      date = date.split('-')
      const day = String(date[0])
      const month = String(date[1])
      const year = String(date[2])
      if (day.length === 2) {
        return `${year}-${day}-${month}`
      }
      return `${day}-${month}-${year}`
    }
    return ''
  }

  const formatDateP = (date) => {
    if (date) {
      date = date.split('-')
      const year = String(date[0])
      const month = String(date[1])
      const day = String(date[2])
      // if (day.length === 2) {
      //   return `${year}/${day}/${month}`
      // }
      return `${month}/${day}/${year}`
    }
    return ''
  }

  function camelToSpaces(str) {
    return str.replace(/[A-Z]/g, (match) => ` ${match.toLowerCase()}`)
      .replace(/\b\w/g, (match) => match.toUpperCase());
  }

  const fetchFacilitiesList = useCallback<any>(async () => {
    const { data } = await ListModel({
      body: {
        Model: FACILITIES_MODEL,
        Extension: null,
        Params: {},
      },
    })

    let add = data.map((route) => {
      if (route.id == apiData.modelData?.FacilitiesId) {
        setSelectedFacility({
          FacilitiesId: route.Id,
          Description: route.Name,
        })
      }
      return {
        FacilitiesId: route.Id,
        Description: route.Name,
      }
    })
    setTheFacilitiesData(add)
  }, [])

  const fetchUsersList = useCallback<any>(async () => {
    const { data } = await ListModel({
      body: {
        Model: 'AdminUsers',
        Extension: null,
        Params: {
          ApplicationsId: 131,
        },
      },
    })
    let add = data.map((route) => {
      return {
        CodeId: route.Id,
        Description: route.FirstName,
      }
    })
    setTheUsersData(add)
  }, [])

  const updateRefs = () => {
    changesRef?.current?.fetchNewData()
  }


  const saveData = async () => {
    try {
      const dataToSave: any = apiData.modelData
      setStatus('saving')
      const { message } = await SaveModel({
        body: {
          Model: PHARMA_MODEL,
          Params: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        },
      })
      toast.success(message)
      updateRefs()
      setStatus('idle')
      callBack()
      setShowModal(false)
      tableRef.current.fetchNewData()
      //await goBack(250)
    } catch (error) {
      setStatus('idle')
      toast.error('Ups! The action could not be executed')
      console.error(error)
    }
  }

  const handleComboChangeCheck = (e: any, newValue: any = {}) => {

    if (!e.target) return
    setSelectedFacility(newValue)
    // var commaSeparated = newValue
    //   .map((item) => {
    //     return item.CodeId
    //   })
    //   .join(',')
    //handleChange({ name: 'FacilitiesId', value: newValue.FacilitiesId })
    setApiData((st: any) => ({
      ...st,
      modelData: { ...st.modelData, ['FacilitiesId']: newValue?.FacilitiesId },
    }))
  }


  const handleUserComboChangeCheck = (e: any, newValue: any = {}) => {
    if (!e.target) return
    setSelectedUsers(newValue)
    setApiData((st: any) => ({
      ...st,
      modelData: { ...st.modelData, ['UsersId']: newValue?.CodeId },
    }))
  }

  const handleChange = ({ name = '', value = '' }) => {
    if (name == 'MedicareYesNo') {
      setMedicareYesNoState(value)
    }
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }

  const handleCheck = (event) => {
    handleChange({ name: event?.target.name, value: event?.target.checked })
  }

  useEffect(() => {

    fetchFacilitiesList()
    fetchUsersList()

    const loggedInUser: any = localStorage.getItem('VCA_LoggedInUser');
    let loggedInUserRetrieveJson = JSON.parse(loggedInUser);
    setLoggedInUserId(loggedInUserRetrieveJson?.Auth?.User?.Id);
  }, []);

  const handleIsSend = async (_arg0: boolean) => {
    setStatus('saving')
    if (medicareYesNoState == "empty") {
      setIntakeResponse([{ Id: null, IsError: 'Yes', Message: 'Please select medicare' }]);
      setStatus('idle')
      return;
    }

    const dataToSave: any = apiData.modelData
    let hasError = false;
    let theIntakeResp = [{ Id: null, IsError: '', Message: '' }];
    let medicareYesFields = ['Bin', 'Pcn'];
    let medicareNoFields = ['InsuranceGroup', 'InsuranceName', 'InsuranceId'];
    let ignoreFields = ['UsersId', 'PharmacyStatus', 'PharmacyGuid', 'IsSent'];
    fields.map((field) => {
      if (dataToSave[field] == "" && field != "Id") {
        if (medicareYesFields.includes(field) && dataToSave["MedicareYesNo"] == 'Yes') {
          hasError = true;
          theIntakeResp.push({ Id: null, IsError: 'Yes', Message: camelToSpaces(field) + ' is required' })
        } else if (medicareNoFields.includes(field) && dataToSave["MedicareYesNo"] == 'No') {
          hasError = true;
          theIntakeResp.push({ Id: null, IsError: 'Yes', Message: camelToSpaces(field) + ' is required' })
        } else if (!medicareNoFields.includes(field) && !medicareYesFields.includes(field) && field != "MedicareYesNo" && !ignoreFields.includes(field)) {
          hasError = true;
          theIntakeResp.push({ Id: null, IsError: 'Yes', Message: camelToSpaces(field) + ' is required' })
        }

      }

    })
    if (hasError) {
      setIntakeResponse(theIntakeResp);
      setStatus('idle')
      return;
    }

    let response: any = await fetchIntake();
    if (response?.data.data.IsError == 'No') {
      await saveData()
      setStatus('idle')
    }
  }

  const fetchIntake = async () => {
    var data = JSON.stringify({
      "firstName": apiData.modelData?.FirstName,
      "lastName": apiData.modelData?.LastName,
      "DOB": formatDateP(apiData.modelData?.DateOfBirth),
      "sex": "Male",
      "street": apiData.modelData?.City,
      "city": apiData.modelData?.City,
      "state": apiData.modelData?.State,
      "zip": apiData.modelData?.ZipCode,
      "phone": apiData.modelData?.PhoneCell,
      "MedicareYesNo": apiData.modelData?.MedicareYesNo,
      "BIN": apiData.modelData?.Bin,
      "PCN": apiData.modelData?.Pcn,
      "Grp": apiData.modelData?.InsuranceGroup,
      "insuranceName": apiData.modelData?.InsuranceName,
      "insuranceID": apiData.modelData?.InsuranceId
    });
    var config: any = {
      method: 'post',
      url: 'https://api.softsmartinc.com/Services/vOld/Jemcare/Pharma/Intake',
      headers: {
        'Authorization': 'Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9',
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(config)
      .then(async function (response) {
        if (response?.data.data.IsError == 'No') {
          try {

            // const dataToSave: any = apiData.modelData
            fetchStatus(response?.data.data.Id);
            setIntakeResponse([response?.data.data]);

            updateRefs()
            // setStatus('idle')
            // callBack()
            // setShowModal(false)
            // await goBack(250)
          } catch (error) {
            setStatus('idle')
            toast.error('Ups! The action could not be executed')
            console.error(error)
          }
        } else {
          setIntakeResponse([response?.data.data]);
          setStatus('idle')
        }
        return response;
      })
      .catch(function (error) {
        setStatus('idle')
        console.log(error);
      });
  }


  const fetchStatus = async (guid: string) => {
    var data = JSON.stringify({
      "id": guid
    });

    let theApiData: any = apiData.modelData
    theApiData['PharmacyGuid'] = guid;

    theApiData['IsSent'] = true;

    var config: any = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.softsmartinc.com/Services/vOld/Jemcare/Pharma/Status',
      headers: {
        'Authorization': 'Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9',
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(async function (response) {
        let status = response.data.data.Message;
        theApiData['PharmacyStatus'] = status;
        setStatus('saving')
        const { message } = await SaveModel({
          body: {
            Model: PHARMA_MODEL,
            Params: Object.assign({}, ...fields.map((field) => ({ [field]: theApiData[field] }))),
          },
        })
        toast.success(message)
        updateRefs()
        setStatus('idle')
        callBack()
        setShowModal(false)
        //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        setStatus('idle')
        console.log(error);
      });
  }


  return (
    <CModal
      show={showModal}
      size="xl"
      onClose={() => {
        //  setUserData({})
        setSelectedFacility({})
        setApiData({
          modelData: {
            FirstName: "",
            LastName: "",
            DateOfBirth: "",
            "City": "",
            "Street": "",
            "State": "",
            "ZipCode": "",
            "PhoneCell": "",
            "MedicareYesNo": "empty",
            "Bin": "",
            "Pcn": "",
            "InsuranceGroup": "",
            "InsuranceName": "",
            "InsuranceId": "",
            "IsSent": false
          }
        })
        setIntakeResponse([{ Id: null, IsError: '', Message: '' }]);
        setShowModal(false)

      }}
      color="#151a25"
    >
      <CModalHeader closeButton>
        <CModalTitle>{pharmaId == 0 ? 'Create' : 'Edit'} Pharma</CModalTitle>
        <div style={{ marginLeft: "70%", display: 'flex', gap: '10px' }}>
          {/* <label htmlFor='IsSent' className='m-0'>
            <input
              className='mr-2'
              id='IsSent'
              type='checkbox'
              name='IsSent'
              checked={apiData.modelData?.IsSent == true}
              onChange={handleCheck}
            />
            Is Sent
          </label> */}

          {/* <label htmlFor='IsFilled' className='m-0'>
            <input
              className='mr-2'
              id='IsFilled'
              type='checkbox'
              name='IsFilled'
              checked={apiData.modelData?.IsFilled == true}
              onChange={handleCheck}
            />
            Is Filled
          </label> */}
        </div>
      </CModalHeader>
      <CModalBody>
        {intakeResponse && intakeResponse.map(element => {
          if (element.IsError != '') {
            return <CRow key={element.Message}>
              <CCol xs='12' sm='12'>
                <CAlert color={element.IsError == 'Yes' ? 'danger' : 'success'}>
                  {element.Message}
                </CAlert>
              </CCol>
            </CRow>
          } else {
            return <></>
          }
        })}
        <CRow>

          <CCol xs='6'>
            <CFormGroup row>
              <CCol xs='12' sm='12'>
                <ComboBox
                  showButton
                  disabled={apiData.modelData?.IsSent}
                  multiple={false}
                  name='UsersList'
                  label='Facilities'
                  data={theFacilitiesData}
                  //value={selectedFacility ?? ""}
                  value={theFacilitiesData.find((e: any) => e.FacilitiesId === apiData.modelData?.FacilitiesId)}
                  handleChange={handleComboChangeCheck}
                  classNames={{ input: 'input-field' }}
                  customClassNames={{
                    button: 'comboBoxInput',
                    root: 'input-field-root upscaled custom-multi',
                  }}
                  renderOption={(option: any, { selected }: any) => (
                    <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
                  )}
                />
              </CCol>
            </CFormGroup>

            <CFormGroup row>
              <CCol xs='12' sm='12'>
                <ComboBox
                  showButton
                  multiple={false}

                  name='UsersId'
                  label='Created By'
                  data={theUsersData}
                  disabled={true}
                  //value={usersSelectedData ?? []}
                  //value={selectedUsers ?? []}
                  value={theUsersData.find((e: any) => {
                    return e.CodeId === loggedInUserId;
                  })}

                  handleChange={handleUserComboChangeCheck}
                  classNames={{ input: 'input-field' }}
                  customClassNames={{
                    button: 'comboBoxInput',
                    root: 'input-field-root upscaled custom-multi',
                  }}
                  renderOption={(option: any, { selected }: any) => (
                    <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
                  )}
                />
              </CCol>
            </CFormGroup>

            <CFormGroup row>
              <CCol xs='6' sm='6'>
                <InputField
                  name='FirstName'
                  type='text'
                  label='First Name'
                  disabled={apiData.modelData?.IsSent}
                  value={apiData.modelData?.FirstName ?? ''}
                  onChange={handleChange}
                />
              </CCol>
              <CCol xs='6' sm='6'>
                <InputField
                  name='LastName'
                  type='text'
                  label='Last Name'
                  disabled={apiData.modelData?.IsSent}
                  value={apiData.modelData?.LastName ?? ''}
                  onChange={handleChange}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs='6' sm='6'>
                <InputField
                  name='PhoneCell'
                  type='phone'
                  label='Phone'
                  disabled={apiData.modelData?.IsSent}
                  value={apiData.modelData?.PhoneCell ?? ''}
                  onChange={handleChange}
                />
              </CCol>
              <CCol xs='6' sm='6'>
                <InputField
                  name='DateOfBirth'
                  type='date'
                  label='DOB'
                  disabled={apiData.modelData?.IsSent}
                  value={apiData.modelData?.DateOfBirth ? formatDate(apiData.modelData?.DateOfBirth) : ''}
                  onChange={handleChange}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs='6' sm='6'>

              </CCol>

              <CCol xs='6' sm='6'>

              </CCol>
            </CFormGroup>
            <hr></hr>
            <CFormGroup row>
              <CCol xs='12' sm='12'>
                <InputField
                  name='Address'
                  type='text'
                  label='Address'
                  disabled={apiData.modelData?.IsSent}
                  value={apiData.modelData?.Address ?? ''}
                  onChange={handleChange}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs='6' sm='6'>
                <InputField
                  name='City'
                  type='text'
                  label='City'
                  disabled={apiData.modelData?.IsSent}
                  value={apiData.modelData?.City ?? ''}
                  onChange={handleChange}
                />
              </CCol>
              <CCol xs='3' sm='3'>
                <InputField
                  name='State'
                  type='text'
                  label='State'
                  disabled={apiData.modelData?.IsSent}
                  value={apiData.modelData?.State ?? ''}
                  onChange={handleChange}
                />
              </CCol>
              <CCol xs='3' sm='3'>

                <InputField
                  name='ZipCode'
                  disabled={apiData.modelData?.IsSent}
                  type='text'
                  label='Zip Code'
                  value={apiData.modelData?.ZipCode ?? ''}
                  onChange={handleChange}
                />
              </CCol>


            </CFormGroup>

          </CCol>
          <CCol xs='6'>
            <CFormGroup row>
              <CCol xs='12' sm='12'>
                <div className='radio-group-label'>
                  <b>Medicare</b>
                  <RadioGroupTs
                    disabled={apiData.modelData?.IsSent}
                    required={true}
                    // label='Cost'
                    name='MedicareYesNo'
                    onChange={(value) => {
                      handleChange({ name: 'MedicareYesNo', value })
                    }}
                    className='radio-group-ts custom-radio customer-custom-radio'
                    //selectedValue={String(modelData.Timezone)}
                    selectedValue={String(apiData.modalData?.MedicareYesNo)}
                  >
                    <label className='radio-group-ts-label' htmlFor='Yes'>
                      <Radio id='Yes' value='Yes' />
                      <div className='radio-group-ts-value'>
                        Yes
                      </div>
                    </label>
                    <label className='radio-group-ts-label' htmlFor='No'>
                      <Radio id='No' value='No' />
                      <div className='radio-group-ts-value'>
                        No
                      </div>
                    </label>
                    <label className='radio-group-ts-label' htmlFor='empty'>
                      <Radio id='empty' value='' />
                      <div className='radio-group-ts-value'>
                        Empty
                      </div>
                    </label>
                  </RadioGroupTs>
                </div>
              </CCol>

            </CFormGroup>
            {medicareYesNoState == "Yes" ?
              <>
                <CFormGroup row>
                  <CCol xs='6' sm='6'>
                    <InputField
                      name='Bin'
                      disabled={apiData.modelData?.IsSent}
                      type='text'
                      label='Bin'
                      value={apiData.modelData?.Bin ?? ''}
                      onChange={handleChange} />
                  </CCol>
                  <CCol xs='6' sm='6'>
                    <InputField
                      name='Pcn'
                      type='text'
                      disabled={apiData.modelData?.IsSent}
                      label='Pcn'
                      value={apiData.modelData?.Pcn ?? ''}
                      onChange={handleChange} />
                  </CCol>
                </CFormGroup>

              </> : <></>}


            {medicareYesNoState == "No" ?
              <>
                <CFormGroup row>
                  <CCol xs='12' sm='12'>
                    <InputField
                      name='InsuranceName'
                      type='text'
                      disabled={apiData.modelData?.IsSent}
                      label='Insurance Name'
                      value={apiData.modelData?.InsuranceName ?? ''}
                      onChange={handleChange} />
                  </CCol>


                </CFormGroup><CFormGroup row>
                  <CCol xs='6' sm='6'>
                    <InputField
                      name='InsuranceId'
                      type='text'
                      label='Insurance Id'
                      disabled={apiData.modelData?.IsSent}
                      value={apiData.modelData?.InsuranceId ?? ''}
                      onChange={handleChange} />
                  </CCol>

                  <CCol xs='6' sm='6'>
                    <InputField
                      name='InsuranceGroup'
                      type='text'
                      label='Insurance Group'
                      disabled={apiData.modelData?.IsSent}
                      value={apiData.modelData?.InsuranceGroup ?? ''}
                      onChange={handleChange} />
                  </CCol>
                </CFormGroup></> : <></>}


          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        {apiData.modelData?.IsSent != true &&

          <LoadingButton
            className='mr-1'
            style={{ float: 'right' }}
            loading={status === 'saving'}
            onClick={() => handleIsSend(!apiData.modelData?.IsSent)}
          >Save and Send</LoadingButton>



          // <CButton color='primary' onClick={() => handleIsSend(!apiData.modelData?.IsSent)}>
          //   Save and Send
          // </CButton>
        }
        {/* <CButton color='info' onClick={saveData}>
          Save
        </CButton> */}
        <CButton
          color='secondary'
          onClick={() => {
            //setUserData({})
            setApiData({
              modelData: {
                FirstName: "",
                LastName: "",
                DateOfBirth: "",
                "City": "",
                "Street": "",
                "State": "",
                "ZipCode": "",
                "PhoneCell": "",
                "MedicareYesNo": "empty",
                "Bin": "",
                "Pcn": "",
                "InsuranceGroup": "",
                "InsuranceName": "",
                "InsuranceId": "",
                "IsSent": false
              }
            })
            setIntakeResponse([{ Id: null, IsError: '', Message: '' }]);
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal >
  )
}

export default NewPharmaModal
