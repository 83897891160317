// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const MechanicalIssuesInfo = ({ modelData, handleChange, title = 'Model Name' }: PropsInfoForm) => {
  const {
    MechIssuesEngine,
    MechIssuesTransmission,
    MechIssuesAircon,
    MechIssuesHeater,
    MechIssuesPowerLiftgate,
    MechIssuesPowerSlidingdoors
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField 
            type='fancyswitch'
            name='MechIssuesEngine'
            label={`Mech Issues Engine`}
          >
            <CustomSwitch
              name='MechIssuesEngine'
              className='d-block'
              value={MechIssuesEngine}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechIssuesEngine })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField 
            type='fancyswitch'
            name='MechIssuesTransmission'
            label={`Mech Issues Transmission`}
          >
            <CustomSwitch
              name='MechIssuesTransmission'
              className='d-block'
              value={MechIssuesTransmission}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechIssuesTransmission })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField 
            type='fancyswitch'
            name='MechIssuesAircon'
            label={`Mech Issues Aircon`}
          >
            <CustomSwitch
              name='MechIssuesAircon'
              className='d-block'
              value={MechIssuesAircon}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechIssuesAircon })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField 
            type='fancyswitch'
            name='MechIssuesHeater'
            label={`Mech Issues Heater`}
          >
            <CustomSwitch
              name='MechIssuesHeater'
              className='d-block'
              value={MechIssuesHeater}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechIssuesHeater })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField 
            type='fancyswitch'
            name='MechIssuesPowerLiftgate'
            label={`MechIssues Power Liftgate`}
          >
            <CustomSwitch
              name='MechIssuesPowerLiftgate'
              className='d-block'
              value={MechIssuesPowerLiftgate}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechIssuesPowerLiftgate })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField 
            type='fancyswitch'
            name='MechIssuesPowerSlidingdoors'
            label={`MechIssues Power Slidingdoors`}
          >
            <CustomSwitch
              name='MechIssuesPowerSlidingdoors'
              className='d-block'
              value={MechIssuesPowerSlidingdoors}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechIssuesPowerSlidingdoors })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default MechanicalIssuesInfo
