interface InputLabelProps {
  label: string,
  value: string,
  // alignment?: string
}

export const InputLabelAux: React.FC<InputLabelProps> = ({label, value}) => {
  return (
    <>
      <div style={{
        border: 'solid 1px #ccc', 
        padding: '15px 5px 1px',
        display: 'flex',
        position: 'relative',
        flexWrap: 'wrap',
        }}>
        <div style={{
          color: '#000',
          fontSize: '10px',
          position: 'absolute',
          left: '7.5px',
          top: '2px',
          transform: 'translateY(0px)',
          transition: 'all 200ms ease 0s',
          fontWeight: 'bold',
          userSelect: 'none',
          margin: '0px 0px 8px',
          }}>
          {label}
        </div>
        <div style={{
          color: '#000',
          border: 'none',
          outline: 'none',
          width: '100%',
          cursor: 'inherit',
          padding: '1px 2px',
          fontSize: '16px',
          // textAlign: alignment || 'left'
          }}>
          {value}
        </div>
      </div>
    </>
  )
}

export const InputLabel: React.FC<InputLabelProps> = ({label, value}) => {
  return (
    <>
      <div style={{
        // border: 'solid 1px #ccc', 
        // padding: '10px 10px 1px',
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        paddingTop: '5px',
        marginTop: '1px',
        marginBottom: '2px'
        }}>
        <div style={{
          // paddingLeft: '10%',
          color: '#000',
          fontSize: '15px',
          width: '33%',
          // position: 'absolute',
          left: '7.5px',
          top: '2px',
          transform: 'translateY(0px)',
          transition: 'all 200ms ease 0s',
          fontWeight: 'bold',
          userSelect: 'none',
          // margin: '0px 0px 8px',
          alignItems: 'center',
          textAlign: 'right'
          }}>
          {label}
        </div>
        <div style={{width: '5%'}} />
        <div style={{
          color: '#000',
          border: 'none',
          outline: 'none',
          width: '62%',
          cursor: 'inherit',
          fontSize: '16px',
          alignItems: 'center'
          // textAlign: alignment || 'left'
          }}>
          {value === 'true' && 'Yes'}
          {value === 'False' && 'No'}
          {value !== 'true' && value !== 'False' && value}
        </div>
      </div>
    </>
  )
}
