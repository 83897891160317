import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { InputField } from 'src/shared'
import { MembersRepository } from 'src/repository/admin/member/MembersRepository'
import { MEMBERS_MODEL } from 'src/services/routeConst'
interface PropsEditSubAccountCreditAmountModal {
  showModal?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}
const EditSubAccountCreditAmountModal = ({
  showModal = false,
  setShowModal,
  modalData,
  callBack = () => {},
}: PropsEditSubAccountCreditAmountModal) => {
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<any>()
  const { id, MembersId } = modalData
  const fields = ['MembersId', 'CardCreditAmount']

  const membersRepository = React.useMemo(() => new MembersRepository(), [])

  useEffect(() => {
    const getDataInEffect = async () => {
      try {
        const { mainData } = await membersRepository.getData({
          id,
          modelName: MEMBERS_MODEL,
          defaultMainData: { MembersId: 0 },
        })
        setData(mainData)
      } catch (error: any) {
        console.error(error)
      }
    }
    loadingData && getDataInEffect()
  }, [MembersId, id, loadingData, membersRepository])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    data && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    const dataToSave: any = data
    try {
      await membersRepository.saveData({
        modelName: MEMBERS_MODEL,
        idName: 'MembersId',
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
      })
    } catch (error: any) {
      console.error(error)
    }
    // setData({})
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setData((st: any) => ({ ...st, [name]: value }))
  }

  if (loadingData) {
    return null
  }
  // console.log(data)
  return (
    <CModal
      show={showModal}
      onClose={() => {
        // setData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>Edit Credit Amount</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CFormGroup row>
          <CCol xs='12' sm='12'>
            <InputField
              name='CardCreditAmount'
              label='Credit Amount'
              type='currency'
              alignment='right'
              value={data.CardCreditAmount}
              decimals={2}
              onChange={handleChange}
              showSeparator
              placeholder='$0.00'
            />
          </CCol>
        </CFormGroup>
        <CButton
          color='secondary'
          onClick={() => {
            // setData({})
            setShowModal(false)
          }}
          style={{ float: 'right' }}
        >
          Cancel
        </CButton>
        <CButton
          color={id ? 'info' : 'success'}
          onClick={saveData}
          style={{ float: 'right' }}
          className='mr-2'
        >
          Save
        </CButton>
      </CModalBody>
      {/* <CModalFooter> */}
      {/* </CModalFooter> */}
    </CModal>
  )
}

export default EditSubAccountCreditAmountModal
