// Generated with util/create-search-page.js
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import EditProcsTestModal from './EditProcsTestModal'
import { ProcsTestTableColumns } from './ProcsTestTableColumns'
import { BASE_API, PROCS_TEST_MODEL, MODELS, PROCS_TEST_ID } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'

const ProcsTestSearch: React.FC<any> = ({ modelName = PROCS_TEST_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)
  const [showEditModal, setShowEditModal] = useState<any>(false)
  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const showEditModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowEditModal(true)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    edit: showEditModalFunc,
    delete: showDeleteModalFunc,
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        {modelName} Search
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => showEditModalFunc({ id: '' })}
        >
          New ProcsTest
        </CButton>
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={ProcsTestTableColumns}
          theme='dark'
          noHeader
          endpoint={BASE_API + ':' + MODELS}
          modelName={modelName}
          idName={PROCS_TEST_ID}
          actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'ProcsTest', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />

      <EditProcsTestModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        modelName={modelName}
        modalData={{ id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CCard>
  )
}

export default ProcsTestSearch
