import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, LOGIN, USERS, SERVICES, DELETE } from 'src/services/routeConst'

interface Response {
  data: any
  status: string
  error: string
  message: string
}
interface Props {
  body: any
}
export const DeleteUser = async ({ body }: Props): Promise<Response> => {
  try {
    const { data } = await axios.delete(`${BASE_API}${SERVICES}/${LOGIN}/${USERS}/${DELETE}`, {
      ...addAuthToHeader('ADMIN'),
      data: body,
    })
    return {
      data: data.data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error saving ${body.Model}!`,
      error: error.response,
    })
  }
}
