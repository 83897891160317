import { CButton } from '@coreui/react'
import axios from 'axios'
import { useContext, useEffect, useState } from 'react'
import { addAuthAccessTokenToHeader, logoutVerifyErrors } from 'src/services/authHelper'
import {
  BASE_API,
  DATA,
  DELETE,
  EXECUTE,
  LIST,
  MODELS,
  SAVE,
  SERVICES,
} from 'src/services/routeConst'
import { Loader } from 'src/shared'
import { WizardContext } from '../Wizard'

function CustomerServicesInfo() {
  const [services, setServices] = useState([])
  const { Customer, activeStep, handleReset, steps } = useContext(WizardContext)
  const [customerServices, setCustomerServices] = useState([])
  const fetchServices = async () => {
    try{
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}${LIST}`,
        {
          Model: 'Services',
          Params: [],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      data.data && setServices(data.data)
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }
  const fetchCustomerServices = async () => {
    try{
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${DATA}/${EXECUTE}`,
        {
          DbName: 'DbOltp',
          QueryOrProcedureName: 'deal.CUSTOMERS_SERVICES_select',
          Params: [
            {
              Name: 'CustomersId',
              Value: Customer.Id,
            },
          ],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      console.log(data)
      data.data && setCustomerServices(data.data)
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }

  useEffect(() => {
    fetchServices()
    fetchCustomerServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteCustomerService = async (id) => {
    try{
      await axios.delete(`${BASE_API}${SERVICES}${MODELS}/${DELETE}`, {
        data: {
          Id: id,
          Model: 'CustomersServices',
          // Extensions: body.Extension ?? null,
          Params: [],
        },
        headers: {
          Authorization: addAuthAccessTokenToHeader().headers.Authorization,
        },
      })
      fetchServices()
      fetchCustomerServices()
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }
  const saveCustomerService = async (idCustomer, idService) => {
    try{
      await axios.patch(
        `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
        {
          Model: 'CustomersServices',
          Id: 0,
          Params: [
            {
              Name: 'CustomersId',
              Value: idCustomer,
            },
            {
              Name: 'ServicesId',
              Value: idService,
            },
          ],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      fetchServices()
      fetchCustomerServices()
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }
  const ServiceInput = (Service) => {
    const found = customerServices.find((element) => element['ServicesId'] === Service['Id']) || {
      id: 0,
    }
    return found['Id'] !== 0 ? (
      <div
        onClick={() => {
          deleteCustomerService(found['Id'])
        }}
        className='grid-container'
      >
        <img
          src='/admin/check-white.png'
          alt=''
          width={24}
          className='c-sidebar-brand-full item-check'
        />
        <div className='item-name'>{Service['Name']}</div>
        <div className='item-price'>
          <i>${Service['Price']?.toFixed(2)}</i>
        </div>
        <div className='item-description'>{Service['Description']}</div>
      </div>
    ) : (
      <div
        onClick={() => saveCustomerService(Customer.Id, Service['Id'])}
        className='grid-container'
      >
        <div
          style={{ padding: '12px', width: '12px', height: '12px', backgroundColor: '#ddd' }}
          className='c-sidebar-brand-full item-check'
        />
        <div className='item-name'>{Service['Name']}</div>
        <div className='item-price'>
          <i>${Service['Price']?.toFixed(2)}</i>
        </div>
        <div className='item-description'>{Service['Description']}</div>
      </div>
    )
  }

  const renderAllServices = services.map((Service) => {
    return <div key={Service['Id']}>{ServiceInput(Service)}</div>
  })
  if (services === null || services.length === 0) {
    return <Loader fullScreen height='75vh' />
  }
  return (
    <div>
      {renderAllServices}
      <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '2rem' }}>
        <div style={{ flex: '1 1 auto' }} />
        <CButton color='primary' onClick={handleReset}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </CButton>
      </div>
    </div>
  )
}

export default CustomerServicesInfo
