import React, { useContext } from 'react'
import { InputField as CustomInputField } from 'smart-inputs'
import { baseInputProps } from '../../services/routeConst'
import { DashboardContext } from 'src/containers/DashBoard'

const InputField: React.FC<any> = ({ name, ...rest }: any) => {
  const { upscaled } = useContext(DashboardContext)
  const props = { ...baseInputProps, id: name, name, ...rest }
  const finalProps = {
    ...props,
    rootClassName: `${props.rootClassName}${upscaled ? ' upscaled' : ''}${
      props.type === 'textarea' ? ' textarea' : ''
    }`,
  }
  return <CustomInputField {...finalProps} />
}

export default InputField
