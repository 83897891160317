import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const CampaignTableColumns: ColumnData[] = [
  {
    label: 'Name',
    name: 'Name',
    customWidth: 15,
  },
  {
    label: 'Message',
    name: 'Message',
    customWidth: 50,
  },
  {
    label: 'Config Name',
    name: 'ConfigName',
    customWidth: 15,
  },
  {
    label: 'Send On',
    name: 'SendOn',
    customWidth: 10,
  },
  {
    label: 'Is Sent',
    name: 'IsSent',
    customWidth: 10,
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    customWidth: 10,
    sortable: false,
  },
]
