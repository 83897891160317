// Generated with util/create-search-page.js

import { PdfViewer } from 'src/shared'

import LoadingButton from 'src/shared/components/LoadingButton'
interface PropsHeaderComponent {
  onCancelHandle?: any
  status: any
  saveData: any
}
const HeaderComponent = ({ status, saveData }: PropsHeaderComponent) => {
  const isLoading = status === 'saving'

  return (
    <div className='d-flex align-items-center '>
      <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton>

      <PdfViewer />
    </div>
  )
}

export default HeaderComponent
