import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, Prompt } from 'react-router-dom'
// https://blog.formpl.us/how-to-create-a-custom-route-blocking-modal-in-react-9306139ee3c7
interface PropsConfirmRedirectModal {
  data?: boolean
  content?: string
  title?: string
  isBlocked?: boolean
}
const ConfirmRedirectModal: React.FC<PropsConfirmRedirectModal> = ({
  data = true,
  content = 'You have unsaved changes. Are you sure you want to leave this page?',
  title = 'Confirm Navigation?',
  isBlocked = true,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const location = useLocation()
  const history = useHistory()
  const [lastLocation, setLastLocation] = useState(location)
  const [shouldUnload, setShouldUnload] = useState(false)
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  const closeModal = () => {
    setIsModalOpen(false)
    setShouldUnload(false)
  }

  const openModal = () => {
    setIsModalOpen(true)
  }

  const showModal = (nextLocation: any) => {
    openModal()
    setLastLocation(nextLocation)
  }

  const handleBlockedRoute = (nextLocation: any) => {
    if (!confirmedNavigation && isBlocked) {
      showModal(nextLocation)
      return false
    }

    return true
  }

  const handleConfirmNavigationClick = () => {
    closeModal()
    setConfirmedNavigation(true)
  }

  // Block react routes
  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      setShouldUnload(true)
      history.push(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation, history])

  // Block non-react routes
  useEffect(() => {
    const unload = (event: any) => {
      if (isBlocked && !shouldUnload) {
        event.returnValue = content
      }
      if (shouldUnload) {
        event.returnValue = ''
      }
    }
    window.addEventListener('beforeunload', unload)

    return () => window.removeEventListener('beforeunload', unload)
  }, [isBlocked, content, shouldUnload])

  return (
    <>
      <Prompt when={!!data} message={handleBlockedRoute} />
      <CModal show={isModalOpen} onClose={closeModal} color='danger'>
        <CModalHeader closeButton>
          <CModalTitle>{title}</CModalTitle>
        </CModalHeader>
        <CModalBody>{content}</CModalBody>
        <CModalFooter>
          <CButton color='secondary' onClick={closeModal}>
            Cancel
          </CButton>
          <CButton color='danger' onClick={handleConfirmNavigationClick}>
            Confirm
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default ConfirmRedirectModal
