// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import AddressInfo from './AddressComponent'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  handleAddressChange: any
  title: string
}

const InspectorInfo = ({
  modelData,
  handleChange,
  title = 'Model Name',
  handleAddressChange,
}: PropsInfoForm) => {
  const { FirstName, LastName, SourceName, SourceCode } = modelData

  const sourceData = () => {
    if(SourceName && SourceCode){
      return `${SourceName}: ${SourceCode}`
    }
    return ''
  }
  
  return (
    <FieldsSection header={title} text={sourceData()}>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
        <InputField
          name='FirstName'
          type='text'
          label='First Name'
          value={FirstName ?? ''}
          onChange={handleChange}
        />
      </CCol>
      <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
        <InputField
          name='LastName'
          type='text'
          label='Last Name'
          value={LastName ?? ''}
          onChange={handleChange}
        />
      </CCol>
      </CFormGroup>

      {/* <CFormGroup row>
        <CCol>
          <InputField
            name='CustomerLastName'
            type='phone'
            label='Phone'
            value={Phone}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup> */}
      {/* <CFormGroup row>
        <CCol>
          <InputField
            name='PhoneOther'
            type='phone'
            label='Other Phone'
            value={PhoneOther ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup> */}
      <AddressInfo
        modelData={modelData}
        handleChange={handleChange}
        handleAddressChange={handleAddressChange}
      />

      {/*   <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </FieldsSection>
  )
}

export default InspectorInfo
