import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from '../../../../../src/shared'
interface PropsMemberChangePassword {
  id?: any
  memberData?: any
  handleChange?: any
}
const MemberChangePassword: React.FC<PropsMemberChangePassword> = ({
  id,
  memberData,
  handleChange,
}) => {
  return (
    <FieldsSection header={`${id ? 'Change' : 'Create'} Password`}>
      <CFormGroup row>
        <CCol xs='12' lg='6' className='mb-2'>
          <InputField
            name='Password'
            label='Password'
            type='password'
            value={memberData.Password}
            onChange={handleChange}
            showButton
          />
        </CCol>
        <CCol xs='12' lg='6'>
          <InputField
            name='confirmPassword'
            label='Confirm Password'
            type='password'
            value={memberData.confirmPassword}
            onChange={handleChange}
            showButton
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default MemberChangePassword
