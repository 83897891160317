// Generated with util/create-search-page.js
export const QueriesTableColumns = [
  {
    label: 'TokensId',
    name: 'TokensId',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'Query',
    name: 'Query',
    dbType: 'varchar',
  },
  {
    label: 'StampStart',
    name: 'StampStart',
    dbType: 'datetime',
  },
  {
    label: 'StampEnd',
    name: 'StampEnd',
    dbType: 'datetime',
  },
  {
    label: 'ExecutionTime',
    name: 'ExecutionTime',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'IsError',
    name: 'IsError',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'ErrorsId',
    name: 'ErrorsId',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]
