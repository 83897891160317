import VehicleInfo from './VehicleInfo'
import AccidentHistoryInfo from './AccidentHistoryInfo'
import VehicleConditionsInfo from './VehicleConditionsInfo'
import MechanicalIssuesInfo from './MechanicalIssuesInfo'
import ModificationsInfo from './ModificationsInfo'
import ElectricalInfo from './ElectricalInfo'
import NotesInfo from './NotesInfo'
import ComplianceInfo from './ComplianceInfo'
import TitleInfo from './TitleInfo'
import HistoryInfo from './HistoryInfo'
import PaymentInfo from './PaymentInfo'
import DeductionInfo from './DeductionInfo'
import ApptPickupInfo from './ApptPickupInfo'
import ApptTimeSlotInfo from './ApptTimeSlotInfo'
import DnServiceInfo from './DnServiceInfo'
import CustomerInfo from './CustomerInfo'

export {
  VehicleInfo,
  AccidentHistoryInfo,
  VehicleConditionsInfo,
  MechanicalIssuesInfo,
  ModificationsInfo,
  ElectricalInfo,
  NotesInfo,
  ComplianceInfo,
  TitleInfo,
  HistoryInfo,
  PaymentInfo,
  DeductionInfo,
  ApptPickupInfo,
  ApptTimeSlotInfo,
  DnServiceInfo,
  CustomerInfo
}
