import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, SERVICES, WIDGETS } from 'src/services/routeConst'
interface PropsFileList {
  Id?: any
}
export const DeleteFiles = async ({ Id = 0 }: PropsFileList) => {
  try {
    const { data } = await axios.delete(`${BASE_API}${SERVICES}${WIDGETS}/Files/Delete`, {
      ...addAuthToHeader('ADMIN'),
      data: {
        Id,
      },
    })
    return {
      data: data.data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error saving File!`,
      error: error.response,
    })
  }
}
