// Generated with util/create-search-page.js
// import { CCol, CFormGroup } from '@coreui/react'
// import Avatar from 'src/shared/components/Avatar/Avatar'

import axios from "axios"
import { useEffect, useState } from "react"
import { addAuthAccessTokenToHeader } from "src/services/authHelper"
import { BASE_API, BREADCRUMBS, LIST_HISTORY, SERVICES, WIDGETS } from "src/services/routeConst"
import { Note } from "src/shared/components/Notes/Note"

// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  // modelData: any
  // title?: string
  MetasId: any
  // id?: any
}


const StatusHistoryInfo = ({ MetasId }: PropsInfoForm) => {
  const [listData, setListData] = useState([])
  const fetchData = async () => {
    try {
      const response = await axios.post(
        `${BASE_API}${SERVICES}${WIDGETS}${BREADCRUMBS}${LIST_HISTORY}`,
        {
          // Extension: "NeedFollowUp",
          MetasId: MetasId
          // [
          //   {
          //     "Name": "MetasId",
          //     "Value": MetasId
          //   },
            // {
            //   "Name": "OrderBy",
            //   "Value": "FollowUpDate"
            // },
            // {
            //   "Name": "OrderByDir",
            //   "Value": "DESC"
            // },
            // {
            //   "Name": "Page",
            //   "Value": null
            // },
            // {
            //   "Name": "PageSize",
            //   "Value": null
            // }
          
        },
        { ...addAuthAccessTokenToHeader() },
      )
      setListData(response.data.data.ListHistoryInfo)
      console.log('statushistiry',listData);
      
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])
  
  
  return (
    <>
      {/* asas {MetasId} */}
      <Note notes={listData} />

    </>
  )
}

export default StatusHistoryInfo
