// Generated with util/create-search-page.js
import { CCardBody,  CCol, CRow } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import  React, { useState, useRef, useCallback, useEffect } from 'react'
import { DeleteModal } from 'src/shared'
//import { routeToPage } from 'src/services/routingHelper'
import { PhoneTableColumns } from './PhoneTableColumns'
import { BASE_API, MODELS, SERVICES, LIST, PHONE_MODEL } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'
//import { RootRepository } from 'src/repository/admin/rootRepository'
import DragDropFiles from '../../../shared/components/DragDropFiles'
import readXlsxFile from 'read-excel-file'
import { Line } from 'rc-progress';
import Modal from 'react-modal';
import { SaveModel } from 'src/services/modelNew/Save/Save'
// import { toast } from 'react-toastify'
import { ListModel } from 'src/services/modelNew/List/List'
import { addAuthAccessTokenToHeader } from 'src/services/authHelper'
//import axios from 'axios'
const axios = require('axios').default;
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const PhoneSearch = ({ setExcelData, modelName = PHONE_MODEL, modelData, triggerSend, apiData }) => {
  const [phonesData, setPhonesData] = useState<any>({ modelData: null })
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)
  const [currentPhoneCount, setCurrentPhoneCount] = useState(0)
  const changesRef = useRef<any>()
  const [status, setStatus] = useState<any>('loading')
  const [totalPhones, setTotalPhones] = useState(0)
  const [modalIsOpen, setIsOpen] = React.useState(false);
  //const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function afterOpenModal() {
  }

  // const viewDetails = ({ id }) => {
  //   routeToPage(history, '/Admin/Phone/Detail/' + id)
  // }

  const resetPhoneStatus = async ({ id }) => {
    const { } = await SaveModel({
      body: {
        Model: modelName,
        Params: {Id: id, IsSent: false}
      },
    });
    tableRef.current.fetchNewData();
  }

  const showDeleteModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions = {
    delete: showDeleteModalFunc,
    reset: resetPhoneStatus,
  }

  const handleExcelChange = (selectorFiles: FileList) => {
    let r: any[] = []
    readXlsxFile(selectorFiles[0]).then((rows) => {
      const Phones = rows.slice(1).map(([Name, PhoneNo, IsSent = false]) => ({Name, PhoneNo, IsSent}));
      r = Phones;
      setExcelData(r);
    })
  }

  // const sendMessages = () => {
  //   openModal();
  //   saveData();
  // }

  useEffect(() => {
    if(triggerSend) {
      openModal();
      saveData();
    }
    
  }, [triggerSend]);

  const updateRefs = () => {
    changesRef?.current?.fetchNewData()
  }
  const getData = useCallback<any>(
    async (shouldUpdateRefs = true) => {
      try {
        // console.log("THE ID");
        // console.log(modelData);
        
        // busco dato y no me lo devuelve me devuelve el  defaultMainData
        const response = await axios.post(
          `${BASE_API}${SERVICES}${MODELS}${LIST}`,
          {
            "Id": modelData.Id,
            "Model": "Phones",
            "Params": [
              {
                "Name": "CampaignId",
                "Value": modelData.Id
            }
            ]
          },
          { ...addAuthAccessTokenToHeader() },
        )
        setTotalPhones(response.data.data.ListInfo.length)
        setPhonesData(response.data.data.ListInfo)
        shouldUpdateRefs && updateRefs()
      } catch (error) {
        setStatus('idle')
        console.error(error)
      }
    },
    [modelName],
  )

  

  useEffect(() => {
    getData(false)
  }, [])

  const saveData = async () => {
    try {
      axios.defaults.headers.common = {'Authorization': `bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9`}
      let i = 0;
      
      const fieldList = modelData.FieldList.split(',');
      let message = modelData.MessageWithFields;
      setStatus('saving')
      for (let pd of phonesData) {
        i++;
        if(pd != undefined && !pd.IsSent) {
          delete pd.RecordCount;
          delete pd.IsNoCall;
          let j = 1;
          let finalMessage = message;
          for (const field of fieldList) {
            finalMessage = finalMessage.replace("{"+field+"}", pd['Value'+j]);
            j++;
          }
          
            const { } = await SaveModel({
              body: {
                Model: modelName,
                Params: {...pd, ...{Message: finalMessage, IsSent: true}},
              },
            });
          if(pd.PhoneNo.length == 10) {
            await pushMessage(finalMessage, pd.PhoneNo); 
          }
        }
        setCurrentPhoneCount(i);
      }

      /**const { data: appsData } = **/await ListModel({
        body: {
          Model: "Phones",
          Extension: "Send",
      }})

      // console.log("appsData");
      // console.log(appsData);

      //toast.success(message)
      updateRefs()
      setStatus('idle')
      setTimeout(()=> {
        closeModal()
        tableRef.current.fetchNewData();
      }, 1000);
      //closeModal();
      //await goBack(250)
    } catch (error) {
      setStatus('idle')
      console.log(status);
      
      //toast.error('Ups! The action could not be executed')
      console.error(error)
    }
  }


  const pushMessage = async (message, phone) => {
    try {
		  await axios.post(`https://api.softsmartinc.com/Services/vOld/LetsConnect/Sms`,
            {
              "PhoneTo": phone,
              "SmsMessage": message
            }
        );
      } catch (error) {
        console.error(error)
      }   
	};

  return (
    <>
      {/* <CCardHeader
        className='d-flex align-items-center card-accent-secondary'
        style={{ backgroundColor: '#2A3446', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}
      >
        <span style={{ color: 'white' }}>{modelName}</span>
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => sendMessages()}
        >
          Send
        </CButton>
      </CCardHeader> */}
      <CCardBody className={'phones-card-body'}>
      
      {apiData.modelData == null || apiData.modelData.Id == 0 ? <DragDropFiles handleExcelChange={handleExcelChange} modelData={[]}></DragDropFiles> : ''}
        <DataTable
          columnData={apiData.modelData == null || apiData.modelData.Id == 0 ?  PhoneTableColumns : PhoneTableColumns.slice(0, -1)}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={modelName}
          queryParams={[{Name: "CampaignId", Value: modelData.Id }]}
          scopedSlots={{
            PhoneNo: ({ value }) => {
              return <div>{value}</div>
            },
            Message: ({ value }) => {
              return <div>{value}</div>
            },
            TimeStamp: ({ value }) => {
              return <div>{value}</div>
            }
          }}
          actionClick={(type, data) => {
            actionFunctions[type]({ 
              id: data
            })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          paginationPosition='bottom-right'
        />
      </CCardBody>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Icon Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        {/* <button onClick={closeModal}>close</button> */}
        <div className='smsProgressModal'>
        <CRow>
        <CCol>
          <Line percent={currentPhoneCount * 10} strokeWidth={4} trailWidth={4} trailColor="#D9D9D9" strokeColor="#22e081" />
          <p>Sending {currentPhoneCount} of {totalPhones}</p>
        </CCol>
      </CRow>
        </div>
        
      </Modal>

      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Phone', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </>
  )
}

export default PhoneSearch
