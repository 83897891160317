// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
const PushInfo = ({ modelData, handleChange }) => {
  const {
    Subject,
    EmailTo,
    EmailFrom,
    StampStart,
    StampEnd,
    ExecutionTime,
    Body,
    IsError,
    ErrorsId,
    ChangedOn,
    Notification,
  } = modelData

  return (
    <FieldsSection header='Push Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='Subject'
            type='text'
            label='Subject'
            value={Subject}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='EmailTo'
            type='text'
            label='EmailTo'
            value={EmailTo}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='EmailFrom'
            type='text'
            label='EmailFrom'
            value={EmailFrom}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='Notification'
            type='text'
            label='Notification'
            value={Notification}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='StampStart'
            type='text'
            label='StampStart'
            value={StampStart}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='StampEnd'
            type='text'
            label='StampEnd'
            value={StampEnd}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='Executiontime'
            type='text'
            label='Executiontime'
            value={ExecutionTime}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CustomCodeEditor
          value={Body}
          mode='text'
          height='20rem'
          readOnly={true}
          theme='dracula'
        ></CustomCodeEditor>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='ErrorsId'
            type='text'
            label='ErrorsId'
            value={ErrorsId}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='ChangedOn'
            type='text'
            label='Changed On'
            value={ChangedOn}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default PushInfo
