import { CCol, CFormGroup } from '@coreui/react'
// import { RadioGroup, Radio } from 'react-radio-input'

import { Info } from "./components";

// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange: any
  title?: string
}
// const RadioGroupTs: any = RadioGroup
const BuyerInfo = ({ modelData, handleChange }: PropsInfoForm) => {
  // const mapComboToData = () =>
  console.log(modelData);
  
  return (
    <>
    <CFormGroup row>
      <CCol>
        <Info title='General Info' modelData={modelData} handleChange={handleChange} />
      </CCol>
    </CFormGroup>
    </>
  )
}

export default BuyerInfo