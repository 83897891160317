import React from 'react'

//component - CoreUI / CWidgetDropdown

const WidgetDropdownCustomer: React.FC<any> = (props: any) => {
  const { children, className, icon, header, color, footerSlot, text, ...attributes } = props

  var currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  const classes = `text-white overflow-hidden ${color && `bg-${color}`} ${className}`

  return (
    <div className={classes} {...attributes}>
      {icon && icon}
      <div className='containerWidgetDropdownCustomer'>
        {/* <div style={{display: 'flex', justifyContent: 'space-between'}}> */}
          <div style={{display: 'flex', alignItems: 'start', height: '65px'}}>
            {!!text && <div style={{fontSize: '16px'}}>{text}</div>}
          </div>
          <div style={{display: 'flex', alignItems: 'end', height: '65px'}}>
            {!!header && <div className='text-value-xl'>{currencyFormat.format(header)}</div>}
          </div>
        {/* </div> */}
        {children}
      </div>
      {footerSlot}
    </div>
  )
}

export default WidgetDropdownCustomer
