import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, TOKENS } from 'src/services/routeConst'
interface PropsGenerateMember {
  params?: Record<string, string | number>
}
export const DeleteMember = async ({ params = {} }: PropsGenerateMember) => {
  //URL {api}/Token/Member/Generate/{type}

  try {
    const { data } = await axios.post(`${BASE_API}:${TOKENS}/Member/Delete`, params, {
      ...addAuthToHeader('ADMIN'),
    })
    return {
      data: data.data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error generating Member `,
      error: error.response,
    })
  }
}
