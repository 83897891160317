// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  handleAddressChange: any
  title: string
}

const VehicleCustomerInfo = ({ modelData,  title = 'Model Name' }: PropsInfoForm) => {
  const { Vehicle, VehicleVin, VehicleMileage, VehicleColor } = modelData

  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel label='Vehicle' value={Vehicle ?? ''} />
          <InputLabel label='Vin' value={VehicleVin ?? ''} />
          <InputLabel label='Mileage' value={VehicleMileage ?? ''} />
          <InputLabel label='Color' value={VehicleColor ?? ''} />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default VehicleCustomerInfo
