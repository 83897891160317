import { CCardBody, CCardHeader } from '@coreui/react'

import { Wizard } from './Wizard'

const ModelSearch: React.FC<any> = ({ modelName = 'New Customers', history }) => {
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g)!.join(' ')
  return (
    <>
      <CCardHeader
        className='d-flex align-items-center'
        style={{
          backgroundColor: '#2A3446',
          marginBottom: '1rem',
          fontSize: 16,
          fontWeight: 600,
          borderRadius: 0
        }}
      >
        <span style={{ color: 'white' }}>{title} Search</span>
      </CCardHeader>
      <CCardBody className={'custom-card-body'}>
        <Wizard history={history} />
      </CCardBody>
    </>
  )
}

export default ModelSearch
