import { CCol, CFormGroup } from '@coreui/react'
import React, { Fragment } from 'react'
import { InputField } from 'src/shared'
interface PropsLookupsTypeInformation {
  lookupsData?: any
  handleChange?: any
}
const LookupsTypeInformation: React.FC<PropsLookupsTypeInformation> = ({
  lookupsData,
  handleChange,
}) => {
  return (
    <Fragment>
      <>
        <CFormGroup row>
          {/* <CCol xs='12' sm='2'>
            <InputField
              name='LookupsId'
              label='Lookup ID'
              type='number'
              value={lookupsData.LookupsId}
              disabled
            />
          </CCol> */}
          <CCol xs='12' sm='12'>
            <InputField
              name='Name'
              type='text'
              label='Name'
              value={lookupsData.Name ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol xs='12' sm='12'>
            <InputField
              name='Description'
              type='textarea'
              label='Description'
              value={lookupsData.Description ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol xs='12' sm='12'>
            <InputField
              name='DatabaseName'
              type='text'
              label='Database Name'
              value={lookupsData.DatabaseName ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
      </>
    </Fragment>
  )
}

export default LookupsTypeInformation
