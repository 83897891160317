// Generated with util/create-search-page.js
export const ProceduresTableColumns = [
  {
    label: 'ProcedureName',
    name: 'ProcedureName',
    dbType: 'varchar',
  },
  {
    label: 'StampEnd',
    name: 'StampEnd',
    dbType: 'varchar',
  },
  {
    label: 'StampStart',
    name: 'StampStart',
    dbType: 'varchar',
  },

  /* {
    label: 'StampStart',
    name: 'StampStart',
    dbType: 'datetime',
  },
  {
    label: 'StampEnd',
    name: 'StampEnd',
    dbType: 'datetime',
  },
  {
    label: 'Executiontime',
    name: 'Executiontime',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'IsError',
    name: 'IsError',
    dbType: 'bit',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'ErrorsId',
    name: 'ErrorsId',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  }, */
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]
