import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'

const ChangePasswordModal = ({ showModal, setShowModal }: any) => {
  const [userData, setUserData] = React.useState<any>({})

  const saveData = async () => {
    setShowModal(false)
    //   console.log('Save Data!', userData)
    //  await propertiesRepository.saveData({
    //    modelName,
    //    idName: 'PropertiesId',
    //    dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: propertyData[field] }))),
    //  })
    setUserData({})
    //  callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setUserData((st: any) => ({ ...st, [name]: value }))
  }

  return (
    <CModal
      show={showModal}
      onClose={() => {
        setUserData({})
        setShowModal(false)
      }}
      color='info'
    >
      <CModalHeader closeButton>
        <CModalTitle>Change Password</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='Password'>
              <CFormGroup row>
                <CCol xs='12' className='mb-2'>
                  <InputField
                    name='Password'
                    label='Password'
                    type='password'
                    value={userData.Password}
                    onChange={handleChange}
                    showButton
                  />
                </CCol>
                <CCol xs='12'>
                  <InputField
                    name='confirmPassword'
                    label='Confirm Password'
                    type='password'
                    value={userData.confirmPassword}
                    onChange={handleChange}
                    showButton
                  />
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color='info' onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            setUserData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default ChangePasswordModal
