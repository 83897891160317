import { CCol, CFormGroup } from '@coreui/react'
// import { RadioGroup, Radio } from 'react-radio-input'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange?: any
  title?: string
}
// const RadioGroupTs: any = RadioGroup
const ElectricalInfo = ({ title, modelData }: PropsInfoForm) => {
  const { 
    // CondKeyQuantity,
    // CondInterior,
    // CondExterior,
    // CondTire,
    
    // CondAccidentHadAccident,
    // CondAccidentRepaired,
    // CondAccidentEstimate,
    // CondAccidentRepairedCost,
    // CondAccidentEstimatedCost,
    
    // CondModsEngine,
    // CondModsExhaustEmissions,
    // CondModsRaisedSuspension,
    // CondModsLoweredSuspension,
    
    CondElectricalRadio,
    CondElectricalDvdBluRay,
    CondElectricalNavigation,
    CondElectricalSunroofMoonroof,
    
    // CondMechEngine,
    // CondMechTransmission,
    // CondMechAircon,
    // CondMechHeater,
    // CondMechPowerLiftgate,
    // CondMechPowerSlidingdoors,
  } = modelData
  // const mapComboToData = () =>

  return (
    <>
      <FieldsSection header={title}>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputLabel label={'Radio'} value={CondElectricalRadio? CondElectricalRadio.toString() :'False'}/>
            <InputLabel label={'Dvd BluRay'} value={CondElectricalDvdBluRay? CondElectricalDvdBluRay.toString() :'False'}/>
            <InputLabel label={'Navigation'} value={CondElectricalNavigation? CondElectricalNavigation.toString() :'False'}/>
            <InputLabel label={'Sunroof Moonroof'} value={CondElectricalSunroofMoonroof? CondElectricalSunroofMoonroof.toString() :'False'}/>
          </CCol>
        </CFormGroup>

      </FieldsSection>
    </>
  )
}

export default ElectricalInfo