import React from 'react'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { RootRepository } from 'src/repository/admin/rootRepository'
// import { routeToPage } from 'src/services/routingHelper'
import { DeleteModal, Loader } from 'src/shared'
import { ScenariosHeader, ScenariosInfo, TestResultsDetailsModal } from './components'
import {
  SERVICES_MODEL,
  SCENARIOS_MODEL,
  ENDPOINTS_MODEL,
  TEST_RESULTS_MODEL,
} from 'src/services/routeConst'
import { ComboModel } from 'src/services/modelNew/Combo/Combo'
import { toast } from 'react-toastify'
import axios from 'axios'

const ScenarioDetails: React.FC<any> = ({ history, match, modelName = SCENARIOS_MODEL }) => {
  const [apiData, setApiData] = React.useState<any>({ scenarioData: null, endpointsData: [] })
  const [status, setStatus] = React.useState<any>('loading')
  const [showDetailsModal, setShowDetailsModal] = React.useState<any>(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState<any>(false)
  const [isJSONValid, setIsJSONValid] = React.useState<any>(true)
  const clickedIDRef = React.useRef<any>(-1)
  const tableRef = React.useRef<any>()
  const modalDataRef = React.useRef<any>({})
  const changesRef = React.useRef<any>()

  const { id = 0 } = match.params
  const fields = [
    'ScenariosId',
    'EndpointsId',
    'Name',
    'Description',
    'Token',
    'TokenType',
    'Payload',
    'ExpectedResultDescription',
    'ExpectedStatusCode',
    'ExpectedStatusMessage',
  ]

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(
    async (shouldUpdateRefs = true) => {
      try {
        const { data: endpointsData } = await ComboModel({ modelName: ENDPOINTS_MODEL })
        const { mainData }: any = await rootRepository.getData({
          id,
          modelName,
          defaultMainData: {
            ScenariosId: 0,
            ExpectedResultJson: JSON.stringify(
              { StatusCode: 200, StatusMessage: 'Success!' },
              null,
              '\t',
            ),
          },
        })
        setApiData({ scenarioData: mainData, endpointsData })
        setStatus('idle')
        id && shouldUpdateRefs && updateRefs()
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [id, modelName, rootRepository],
  )

  const updateRefs = () => {
    tableRef.current.fetchNewData()
    changesRef.current.fetchNewData()
  }

  React.useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ scenarioData: null, endpointsData: [] })
      history.goBack()
    }, delay)
  }

  const saveData = async () => {
    const dataToSave = apiData.scenarioData
    if (!isJSONValid) {
      toast.error(
        'Could not save Scenario because JSON is invalid! Please correct it and try again.',
      )
      return
    }
    setStatus('saving')
    try {
      await rootRepository.saveData({
        modelName,
        idName: 'ScenariosId',
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        getData,
        goBack,
      })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({ ...st, scenarioData: { ...st.scenarioData, [name]: value } }))
  }

  const handleComboChange = (e: any, newValue: any = {}, name: any) => {
    if (!e.target) return
    const CodeId = newValue ? newValue.CodeId : 0
    setApiData((st: any) => ({ ...st, scenarioData: { ...st.scenarioData, [name]: CodeId } }))
  }

  const scenarioHandler = async ({ action = 'test' }) => {
    if (action === 'reset') {
      getData(false)
      return
    }
    if (!isJSONValid) {
      toast.error(
        'Could not perform test because JSON is invalid! Please correct it and try again.',
      )
      return
    }
    setStatus('testing')
    let request = {}
    try {
      const { mainData: endpointData } = await rootRepository.getData({
        id: apiData.scenarioData.EndpointsId,
        modelName: ENDPOINTS_MODEL,
      })
      const { ServicesId, HttpMethod, Name: EndpointName, Enabled } = endpointData
      const { mainData: serviceData }: any = await rootRepository.getData({
        id: ServicesId,
        modelName: SERVICES_MODEL,
      })
      if (!Enabled) {
        toast.error(`${serviceData.Name}.${EndpointName} is Disabled!`)
        return
      }
      const Endpoint = `${serviceData.URL}/${EndpointName}`
      request = {
        url: Endpoint,
        method: HttpMethod,
        data: JSON.parse(apiData.scenarioData.Payload),
        headers: apiData.scenarioData.Token
          ? { Authorization: `Bearer ${apiData.scenarioData.Token}` }
          : {},
      }
      const response = await axios(request)
      const { status, data } = response
      saveTestResults({ request, response, status, message: data.info })
      toast.success(
        <>
          <strong>Status: {status}</strong>
          <div>{data.info}</div>
        </>,
      )
      setStatus('idle')
    } catch (error: any) {
      console.dir(error)
      const errorStatus = error?.response?.status ?? error?.status ?? '500'
      const errorMessage =
        error?.response?.data?.message ??
        error?.response?.data?.data?.message ??
        error?.message ??
        'SOMETHING WENT WRONG'
      saveTestResults({
        request,
        response: error.response,
        status: errorStatus,
        message: errorMessage,
      })
      toast.error(
        <>
          <strong>Status: {errorStatus}</strong>
          <div>{errorMessage}</div>
        </>,
      )
      setStatus('idle')
    }
  }

  const getExpectedResults = () => {
    const { ExpectedResultJson } = apiData.scenarioData
    return JSON.parse(ExpectedResultJson)
  }

  const saveTestResults = async ({ request, response, status, message }: any) => {
    const { StatusCode: StatusCodeExpected, StatusMessage: StatusMessageExpected } =
      getExpectedResults()
    const dataToSave = {
      TestsId: 0,
      ScenariosId: id,
      StatusCodeExpected,
      StatusMessageExpected,
      StatusCodeReceived: status,
      StatusMessageReceived: message,
      Success: status === StatusCodeExpected && message === StatusMessageExpected,
      RequestJson: JSON.stringify(request, null, '\t'),
      ResponseJson: JSON.stringify(response, null, '\t'),
    }
    setStatus('saving')
    try {
      await rootRepository.saveData({
        modelName: TEST_RESULTS_MODEL,
        idName: 'TestsId',
        dataToSave,
        noToaster: true,
      })
      setStatus('idle')
      updateRefs()
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  // const viewDetails = ({ id: viewID }) => {
  //   setApiData({ scenarioData: null })
  //   routeToPage(history, `/Micro/Endpoints/${viewID ? `Detail/${viewID}` : `New`}`, {
  //     ScenariosId: id,
  //   })
  // }

  /*  const showDetailsModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    modalDataRef.current = {
      modelName: TEST_RESULTS_MODEL,
      label: 'Test Results',
      id: id,
      scenarioData: apiData.scenarioData,
    }
    setShowDetailsModal(true)
  } */

  /*  const actionFunctions: any = {
    view: showDetailsModalFunc,
  } */

  if (!apiData.scenarioData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { scenarioData, endpointsData } = apiData
  return (
    <>
      <CCard>
        <ScenariosHeader
          id={id}
          modelName={modelName}
          status={status}
          saveData={saveData}
          scenarioHandler={scenarioHandler}
          onCancel={history.goBack}
        />
        <CCardBody>
          <CRow>
            <CCol xs='12' md='6'>
              <ScenariosInfo
                scenarioData={scenarioData}
                endpointsData={endpointsData}
                handleChange={handleChange}
                handleComboChange={handleComboChange}
                setIsJSONValid={setIsJSONValid}
              />
            </CCol>
            {/*  {id ? (
              <CCol xs='12' md='6'>
                <ScenariosDetailTabSection
                  id={id}
                  Guid={scenarioData.Guid}
                  modelName={modelName}
                  actionFunctions={actionFunctions}
                  tableRef={tableRef}
                  changesRef={changesRef}
                />
              </CCol>
            ) : null} */}
          </CRow>
        </CCardBody>
      </CCard>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName: ENDPOINTS_MODEL, label: 'Endpoint', id: clickedIDRef.current }}
        callBack={getData}
      />
      <TestResultsDetailsModal
        showModal={showDetailsModal}
        setShowModal={setShowDetailsModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
    </>
  )
}

export default ScenarioDetails
