import React, { useRef, useState } from 'react'
import { CCard, CCardBody, CCardText, CCol, CImg, CRow, CTabPane } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { CheckBoxGroup } from 'smart-inputs'
import { getAuthToken } from '../../../../../src/services/authHelper'
import {
  BASE_API,
  BANKS_MODEL,
  STATEMENTS_MODEL,
  TRANSACTIONS_MODEL,
  SETTINGS_MODEL,
  MODELS,
  STATS_MODEL,
  CARDS_MODEL,
  EXPERIAN_MODEL,
  SHARED_MODEL,
  CONTACT_MODEL,
  SOCIALS_MODEL,
  SOCIALS_ID,
  OTP_ID,
  OTP_MODEL,
  FILES_ID,
  // FILES_URL,
  // SIGNINS_MODEL,
  // SIGNINS_ID,
} from '../../../../../src/services/routeConst'
// import { FieldsSection } from 'src/shared'
import DetailTabSection from '../../../../../src/shared/components/DetailTabSection/DetailTabSection'
// import { loginHistoryTableColumns } from '../../Users/userTableColumns'
import {
  memberPaymentAccountsTableColumns,
  memberStatementsTableColumns,
  memberTransactionsTableColumns,
  memberSettingsTableColumns,
  memberStatsTableColumns,
  memberCardsTableColumns,
  memberExperianTableColumns,
  memberSharedTableColumns,
  memberContactsTableColumns,
  memberSocialsTableColumns,
  memberOTPTableColumns,
  // subAccountsTableColumns,
} from '../memberTableColumns'
// import MemberCardsSection from './MemberCardsSection'
import HeaderButton from '../../../../../src/shared/components/HeaderButton'
// import MemberAvatar from 'src/shared/components/MemberAvatar'

import { PdfViewer } from 'src/shared/components/pdfviewer'
import { FilesTableColumns } from './FilesTableColumns'

/* import { Viewer, Worker } from '@react-pdf-viewer/core' */
import ReactDOM from 'react-dom'

// import DocViewer, { DocViewerRenderers } from "react-doc-viewer";

const FilesId_Avatar = '/avatars/8.jpg'

const getTabSectionTabs = () => {
  let tabSectionTabs = [
    // { name: 'SettingsTable', label: 'Settings' },
    // { name: 'CardsTable', label: 'Cards' },
    // { name: 'PaymentAccountsTable', label: 'Banks' },
    // { name: 'StatsTable', label: 'Stats' },
    // { name: 'ExperianTable', label: 'Experian' },
    // { name: 'SharedSenderTable', label: 'Shared sender' },
    // { name: 'SharedReceiverTable', label: 'Shared receiver' },
    // { name: 'ContactTable', label: 'Contacts' },
    // { name: 'TransactionsTable', label: 'Transactions' },
    // { name: 'StatementsTable', label: 'Statements' },
    // { name: 'SocialsTable', label: 'Socials' },
    // { name: 'OtpTable', label: 'Otp' },
    { name: 'Services', label: 'Services' },
    { name: 'Players', label: 'Players' },
    { name: 'Documents', label: 'Documents' },
  ]

  // if (IsParent) {
  //   tabSectionTabs = [...tabSectionTabs, { name: 'SubAccountsTable', label: 'Sub Accounts' }]
  // }

  // return [...tabSectionTabs, { name: 'MemberCards', label: 'Cards' }]
  return [...tabSectionTabs]
}

const MembersDetailTabSection: React.FC<any> = ({
  id,
  modelName,

  actionFunctions,
  paymentAccountsTableRef,
  transactionsTableRef,
  statementsTableRef,
  settingsTableRef,
  socialsTableRef,
  statsTableRef,
  otpTableRef,
  cardsTableRef,
  experianTableRef,
  sharedSenderTableRef,
  sharedReceiverTableRef,
  contactTableRef,
  // subAccountsTableRef,
  changesRef,
  memberData,
  showEditModalFunc,
  viewDetails,
}) => {
  // console.log('MEMBER DATA', memberData)
  let memberTransactionsIsParentTableColumns = memberTransactionsTableColumns.slice()

  if (memberData.IsParent) {
    memberTransactionsIsParentTableColumns.splice(2, 0, {
      label: 'Member',
      name: 'MemberName',
    })
  }
  // const searchEmailOrPhone = () => {
  //   return memberData.Phone === null ? memberData.Email : memberData.Phone
  // }
  const tableRef = useRef<any>()

  const viewPdf = () => {
    // console.log(id);
    setShown(true)
  }
  const viewDocument: any = {
    view: viewPdf,
  }

  const [shown, setShown] = useState(false)
  let fileUrl = 'https://s2.q4cdn.com/498544986/files/doc_downloads/test.pdf'
  console.log(fileUrl)

  const modalBody = () => (
    <div
      style={{
        backgroundColor: '#fff',
        flexDirection: 'column',
        overflow: 'hidden',

        // paddingLeft: 15%,

        /* Fixed position */
        left: '25%',
        position: 'fixed',
        top: 0,

        /* Take full size */
        height: '100%',
        width: '50%',

        /* Displayed on top of other elements */
        zIndex: 9999,
      }}
    >
      <div
        style={{
          alignItems: 'center',
          backgroundColor: '#000',
          color: '#fff',
          display: 'flex',
          padding: '.5rem',
        }}
      >
        <div style={{ marginRight: 'auto' }}>sample-file-name.pdf</div>
        <button
          style={{
            backgroundColor: '#357edd',
            border: 'none',
            borderRadius: '4px',
            color: '#ffffff',
            cursor: 'pointer',
            padding: '8px',
          }}
          onClick={() => setShown(false)}
        >
          Close
        </button>
      </div>
      <div
        style={{
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        {/* <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js'>
          <Viewer fileUrl={fileUrl} />
        </Worker> */}
      </div>
    </div>
  )

  const [services, setServices] = React.useState([
    {
      id: 1,
      name: 'QuantumBasePackage',
      label: 'Quantum a Base Package',
      value: false,
    },
    {
      id: 2,
      name: 'MechanicalInspection',
      label: 'Mechanical Inspection',
      value: false,
    },
    {
      id: 3,
      name: 'ExpressServices',
      label: 'Express services',
      value: false,
    },
    {
      id: 4,
      name: 'SigningOvernight',
      label: 'Signing and Overnight',
      value: false,
    },
    {
      id: 5,
      name: 'Notary',
      label: 'Notary',
      value: false,
    },
  ])

  return (
    <DetailTabSection
      id={id}
      modelName={modelName}
      tabs={getTabSectionTabs()}
      Guid={memberData.Guid}
      headerStyle={{ padding: '0.65rem', paddingBottom: 0 }}
      changesRef={changesRef}
      headers={{
        PaymentAccountsTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: BANKS_MODEL,
                label: 'Bank',
              })
            }
            label='Add Bank'
          />
        ),
        TransactionsTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: TRANSACTIONS_MODEL,
                label: 'Transaction',
              })
            }
            label='Add Transaction'
          />
        ),
        StatementsTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: STATEMENTS_MODEL,
                label: 'Statement',
              })
            }
            label='Add Statement'
          />
        ),
        SettingsTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: SETTINGS_MODEL,
                label: 'Setting',
              })
            }
            label='Add Setting'
          />
        ),
        CardsTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: CARDS_MODEL,
                label: 'Card',
              })
            }
            label='Add Card'
          />
        ),
        StatsTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: STATS_MODEL,
                label: 'Stat',
              })
            }
            label='Add Stat'
          />
        ),
        ExperianTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: EXPERIAN_MODEL,
                label: 'Experian',
              })
            }
            label='Add Experian'
          />
        ),
        SharedSenderTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: SHARED_MODEL,
                label: 'Shared',
              })
            }
            label='Add Shared'
          />
        ),
        SharedReceiverTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: SHARED_MODEL,
                label: 'Shared',
              })
            }
            label='Add Shared'
          />
        ),
        ContactTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: CONTACT_MODEL,
                label: 'Contact',
              })
            }
            label='Add Contact'
          />
        ),
        SocialsTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: SOCIALS_MODEL,
                label: 'Social',
              })
            }
            label='Add Social'
          />
        ),
        OtpTable: (
          <HeaderButton
            id={id}
            onClick={() =>
              showEditModalFunc({
                id: 0,
                modelName: OTP_MODEL,
                label: 'OTP',
              })
            }
            label='Add OTP'
          />
        ),
      }}
    >
      <CTabPane data-tab='Services' className='detail-tab-section-pane service-tab'>
        <CheckBoxGroup
          name='OptionEx'
          label=''
          options={services}
          onChangeCheckBox={({ name, value }) => {
            console.log(services)
            setServices((st: any) => [
              ...st.map((option: any) => ({
                ...option,
                value: name === option.name ? value : option.value,
              })),
            ])
          }}
          customClassNames={{ root: 'input-field', input: 'input-field-input' }}
        />
      </CTabPane>
      <CTabPane data-tab='Players' className='detail-tab-section-pane'>
        <CRow>
          <CCol xs='12' md='6'>
            <select name='player' id='player' className='form-control mb-2'>
              <option value='Inspector'>Inspector</option>
            </select>
            <CCard style={{ backgroundColor: '#ccc', color: '#000', textAlign: 'center' }}>
              <CCardBody>
                <CImg
                  style={{ borderRadius: '100%', width: '100px', height: '100px' }}
                  src={FilesId_Avatar}
                  fluid
                />
                <CCardText className='pt-1'>John Smith</CCardText>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xs='12' md='6'>
            <select name='player' id='player' className='form-control mb-2'>
              <option value='player'>Dealer Representative</option>
            </select>
            <CCard style={{ backgroundColor: '#ccc', color: '#000', textAlign: 'center' }}>
              <CCardBody>
                <CImg
                  style={{ borderRadius: '100%', width: '100px', height: '100px' }}
                  src={FilesId_Avatar}
                  fluid
                />
                <CCardText className='pt-1'>Michael Joss</CCardText>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xs='12' md='6'>
            <select name='player' id='player' className='form-control mb-2'>
              <option value='player'>Account Representative</option>
            </select>
            <CCard style={{ backgroundColor: '#ccc', color: '#000', textAlign: 'center' }}>
              <CCardBody>
                <CImg
                  style={{ borderRadius: '100%', width: '100px', height: '100px' }}
                  src={FilesId_Avatar}
                  fluid
                />
                <CCardText className='pt-1'>Joseph Doe</CCardText>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xs='12' md='6'>
            <select name='player' id='player' className='form-control mb-2'>
              <option value='player'>DisPatch Now Rep.</option>
            </select>
            <CCard style={{ backgroundColor: '#ccc', color: '#000', textAlign: 'center' }}>
              <CCardBody>
                <CImg
                  style={{ borderRadius: '100%', width: '100px', height: '100px' }}
                  src={FilesId_Avatar}
                  fluid
                />
                <CCardText className='pt-1'>Michael Joss</CCardText>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CTabPane>

      {/* DOCUMENTS */}
      <CTabPane data-tab='Documents' className='detail-tab-section-pane'>
        <CCardBody>
          <PdfViewer maxSizeMib={10} label='Upload' />

          <DataTable
            columnData={FilesTableColumns}
            theme='dark'
            noHeader
            endpoint={`${BASE_API}/Models`}
            modelName={'Models'}
            idName={FILES_ID}
            actionClick={(type, data) => viewDocument[type]({ id: data })}
            ref={tableRef}
            apiKey={getAuthToken('ADMIN')}
          />
          {shown && ReactDOM.createPortal(modalBody(), document.body)}
        </CCardBody>
      </CTabPane>

      <CTabPane data-tab='OtpTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberOTPTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={OTP_MODEL}
          idName={OTP_ID}
          // queryParams={{ Extention: 'ByEmailPhone', EmailPhone: searchEmailOrPhone }}
          actionClick={(type: any, data: any) =>
            actionFunctions[type]({
              id: data,
              modelName: OTP_MODEL,
              label: 'Otp',
            })
          }
          scopedSlots={{
            Code: ({ value }) => {
              return <div style={{ color: '#F2AB26', fontFamily: 'Consolas' }}>{value}</div>
            },
            Status: ({ value }) => {
              return <div style={{ color: '#F2AB26', fontFamily: 'Consolas' }}>{value}</div>
            },
          }}
          ref={otpTableRef}
          apiKey={getAuthToken('ADMIN')}
        />
      </CTabPane>
      <CTabPane data-tab='SocialsTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberSocialsTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={SOCIALS_MODEL}
          idName={SOCIALS_ID}
          // queryParams={{ UsersId: id, Extention: 'ByUsersId' }}
          actionClick={(type: any, data: any) =>
            actionFunctions[type]({
              id: data,
              modelName: SOCIALS_MODEL,
              label: SOCIALS_MODEL,
            })
          }
          scopedSlots={{
            SocialType: ({ value }) => {
              let valueString = ''
              if (value) {
                valueString = ''
              }
              valueString = value?.charAt(0)?.toUpperCase() + value?.slice(1)
              return <div style={{ color: '#F2AB26', fontFamily: 'Consolas' }}>{valueString}</div>
            },
            ShareOn: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
          }}
          ref={socialsTableRef}
          parseBools
          apiKey={getAuthToken('ADMIN')}
        />
      </CTabPane>
      <CTabPane data-tab='PaymentAccountsTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberPaymentAccountsTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={BANKS_MODEL}
          idName='BanksId'
          // queryParams={{ UsersId: id }}
          actionClick={(type: any, data: any) =>
            actionFunctions[type]({
              id: data,
              modelName: BANKS_MODEL,
              label: 'Bank',
            })
          }
          scopedSlots={{
            BankName: ({ value }) => {
              return <div style={{ color: '#F2AB26', fontFamily: 'Consolas' }}>{value}</div>
            },
            AccountNumber: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            RoutingNumber: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            AccountType: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            BankLogoBlob: ({ value }) => {
              // const urlCreator = window.URL || window.webkitURL
              const imageUrl = 'data:image/png;base64,' + value
              return <img src={imageUrl} style={{ width: '35px' }} alt={'LogoBank'} />
            },
          }}
          ref={paymentAccountsTableRef}
          parseBools
          apiKey={getAuthToken('ADMIN')}
        />
      </CTabPane>
      <CTabPane data-tab='TransactionsTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberTransactionsIsParentTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={TRANSACTIONS_MODEL}
          idName='TransId'
          // queryParams={{ Extention: 'Custom', UsersId: id }}
          actionClick={(type: any, data: any) =>
            actionFunctions[type]({
              id: data,
              modelName: TRANSACTIONS_MODEL,
              label: 'Transaction',
            })
          }
          ref={transactionsTableRef}
          parseBools
          apiKey={getAuthToken('ADMIN')}
          scopedSlots={{
            /*  MemberName: ({ value, row }: any) => (
              <MemberAvatar
                name={value}
                avatar={row.original?.FilesId_Avatar}
                onClick={
                  row.original?.UsersId !== memberData.UsersId
                    ? (e: any) => {
                        e.preventDefault()
                        e.stopPropagation()
                        viewDetails({ id: row.original?.UsersId })
                      }
                    : null
                }
              />
            ), */
            MerchantName: ({ value }) => {
              return <div style={{ color: '#F2AB26', fontFamily: 'Consolas' }}>{value}</div>
            },
            MerchantCity: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            MerchantState: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            MerchantZipCode: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            MerchantPhone: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            Amount: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            Category: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
          }}
          /*  scopedSlots={{
            BankName: ({ value }) => {
              return <div style={{ color: '#F2AB26', fontFamily: 'Consolas' }}>{value}</div>
            },
            AccountNumber: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
      
          }} */
          paginationPosition='top-right'
        />
      </CTabPane>
      <CTabPane data-tab='StatementsTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberStatementsTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={STATEMENTS_MODEL}
          idName='StatementsId'
          // queryParams={{ UsersId: id }}
          actionClick={(type: any, data: any) =>
            actionFunctions[type]({
              id: data,
              modelName: STATEMENTS_MODEL,
              label: 'Statement',
            })
          }
          scopedSlots={{
            DatePaymentDue: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            BalanceTotal: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            MinimumDue: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
          }}
          ref={statementsTableRef}
          parseBools
          apiKey={getAuthToken('ADMIN')}
        />
      </CTabPane>
      <CTabPane data-tab='SettingsTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberSettingsTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={SETTINGS_MODEL}
          idName='SettingsId'
          // queryParams={{ Extention: 'ByUsersId', UsersId: id }}
          actionClick={(type: any, data: any) =>
            actionFunctions[type]({
              id: data,
              modelName: SETTINGS_MODEL,
              label: 'Setting',
            })
          }
          ref={settingsTableRef}
          parseBools
          apiKey={getAuthToken('ADMIN')}
        />
      </CTabPane>
      <CTabPane data-tab='StatsTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberStatsTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={STATS_MODEL}
          idName='StatsId'
          // queryParams={{ UsersId: id }}
          actionClick={(type: any, data: any) =>
            actionFunctions[type]({
              id: data,
              modelName: STATS_MODEL,
              label: 'Stat',
            })
          }
          scopedSlots={{
            VitalScore: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            CashbackBalance: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            VitalCashReceived: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            CreditScore: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            NumShares: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            NumSignups: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            YourRank: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
          }}
          ref={statsTableRef}
          parseBools
          apiKey={getAuthToken('ADMIN')}
        />
      </CTabPane>
      <CTabPane data-tab='CardsTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberCardsTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={CARDS_MODEL}
          idName='CardsId'
          // queryParams={{ UsersId: id }}
          actionClick={(type: any, data: any) =>
            actionFunctions[type]({
              id: data,
              modelName: CARDS_MODEL,
              label: 'Card',
            })
          }
          scopedSlots={{
            Style: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            Color: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            Name: ({ value }) => {
              return <div style={{ color: '#F2AB26', fontFamily: 'Consolas' }}>{value}</div>
            },
          }}
          ref={cardsTableRef}
          parseBools
          apiKey={getAuthToken('ADMIN')}
        />
      </CTabPane>
      <CTabPane data-tab='ExperianTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberExperianTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={EXPERIAN_MODEL}
          idName='ExperianId'
          // queryParams={{ UsersId: id }}
          actionClick={(type: any, data: any) =>
            actionFunctions[type]({
              id: data,
              modelName: EXPERIAN_MODEL,
              label: 'Experian',
            })
          }
          ref={experianTableRef}
          parseBools
          apiKey={getAuthToken('ADMIN')}
        />
      </CTabPane>
      <CTabPane data-tab='SharedSenderTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberSharedTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={SHARED_MODEL}
          idName='SharedId'
          // queryParams={{
          //   Extention: 'BySenderOrReceiver',
          //   UsersId_Receiver: null,
          //   UsersId_Sender: id,
          // }}
          actionClick={(type: any, data: any) => {
            console.log('hi')
            console.log(type, data)
            viewDetails({ id: data })
            /* actionFunctions[type]({
              id: data,
              modelName: SHARED_MODEL,
              label: 'Shared Sender',
            }) */
            // viewDetails({ id: data })
            /* actionFunctions[type]({
              id: data,
              modelName: SHARED_MODEL,
              label: 'Shared Sender',
            }) */
          }}
          ref={sharedSenderTableRef}
          parseBools
          apiKey={getAuthToken('ADMIN')}
          scopedSlots={{
            CodePrimary: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas', color: '#F2AB26' }}>{value}</div>
            },
            Email: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            Firstname: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            LastName: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            NickName: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            Date: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            /* MemberName_Sender: ({ value, row }: any) => (
              <MemberAvatar
                name={value}
                avatar={row.original?.FilesId_Avatar_Sender}
                onClick={
                  row.original?.UsersId !== memberData.UsersId
                    ? (e: any) => {
                        e.preventDefault()
                        e.stopPropagation()
                        viewDetails({ id: row.original?.UsersId })
                      }
                    : null
                }
              />
            ),
            MemberName_Receiver: ({ value, row }: any) => (
              <MemberAvatar
                name={value}
                avatar={row.original?.FilesId_Avatar_Receiver}
                onClick={
                  row.original?.UsersId !== memberData.UsersId
                    ? (e: any) => {
                        e.preventDefault()
                        e.stopPropagation()
                        viewDetails({ id: row.original?.UsersId })
                      }
                    : null
                }
              />
            ), */
          }}
        />
      </CTabPane>
      <CTabPane data-tab='SharedReceiverTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberSharedTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={SHARED_MODEL}
          idName='SharedId'
          // queryParams={{
          //   Extention: 'BySenderOrReceiver',
          //   UsersId_Receiver: id,
          //   UsersId_Sender: null,
          // }}
          actionClick={(type: any, data: any) => {
            console.log(type, data)
            viewDetails({ id: data })
            /* actionFunctions[type]({
              id: data,
              modelName: SHARED_MODEL,
              label: 'Shared Sender',
            }) */
          }}
          ref={sharedReceiverTableRef}
          parseBools
          apiKey={getAuthToken('ADMIN')}
          scopedSlots={{
            CodePrimary: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas', color: '#F2AB26' }}>{value}</div>
            },
            Email: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            Firstname: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            LastName: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            NickName: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            Date: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            /* MemberName_Sender: ({ value, row }: any) => (
              <MemberAvatar
                name={value}
                avatar={row.original?.FilesId_Avatar_Sender}
                onClick={
                  row.original?.UsersId !== memberData.UsersId
                    ? (e: any) => {
                        e.preventDefault()
                        e.stopPropagation()
                        viewDetails({ id: row.original?.UsersId })
                      }
                    : null
                }
              />
            ),
            MemberName_Receiver: ({ value, row }: any) => (
              <MemberAvatar
                name={value}
                avatar={row.original?.FilesId_Avatar_Receiver}
                onClick={
                  row.original?.UsersId !== memberData.UsersId
                    ? (e: any) => {
                        e.preventDefault()
                        e.stopPropagation()
                        viewDetails({ id: row.original?.UsersId })
                      }
                    : null
                }
              />
            ), */
          }}
        />
      </CTabPane>
      <CTabPane data-tab='ContactTable' className='detail-tab-section-pane'>
        <DataTable
          columnData={memberContactsTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={CONTACT_MODEL}
          idName='ContactsId'
          // queryParams={{ UsersId: id, Extention: 'ByUsersId' }}
          actionClick={(type: any, data: any) =>
            actionFunctions[type]({
              id: data,
              modelName: CONTACT_MODEL,
              label: 'Contacts',
            })
          }
          scopedSlots={{
            FirstName: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            LastName: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            Email: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas', color: '#F2AB26' }}>{value}</div>
            },
            Phone: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
          }}
          ref={contactTableRef}
          parseBools
          apiKey={getAuthToken('ADMIN')}
        />
      </CTabPane>

      {/* {memberData.IsParent ? (
        <CTabPane data-tab='SubAccountsTable' className='detail-tab-section-pane'>
          <DataTable
            columnData={subAccountsTableColumns}
            
            theme='dark'
            noHeader
            endpoint={`${BASE_API}:${MODELS}`}
            modelName={MEMBERS_MODEL}
            idName='UsersId'
            queryParams={{ UsersId_Parent: id }}
            actionClick={(type: any, data: any) =>
              type === 'edit'
                ? viewDetails({ id: data })
                : actionFunctions[type]({
                    id: data,
                    modelName: type === 'edit_credit_amount' ? 'CreditAmount' : MEMBERS_MODEL,
                    label: 'Member',
                  })
            }
            ref={subAccountsTableRef}
            filesEndpoint={FILES_URL}
            parseBools
            apiKey={getAuthToken('ADMIN')}
            scopedSlots={{
              AvatarUrl: ({ row }: any) => (
                <MemberAvatar
                  name={row.original?.FirstName + ' ' + row.original?.LastName}
                  avatar={row.original?.FilesId_Avatar}
                  noChip
                />
              ),
            }}
          />
        </CTabPane>
      ) : null} */}

      {/* <CTabPane
        data-tab='MemberCards'
        className='detail-tab-section-pane px-3 py-0 px-xl-4 px-xxl-5 py-lg-2'
      >
        <MemberCardsSection
          id={id}
          memberData={memberData}
          showEditModalFunc={showEditModalFunc}
          modelName={MEMBERS_MODEL}
        />
      </CTabPane> */}
    </DetailTabSection>
  )
}

export default MembersDetailTabSection
