// Generated with util/create-search-page.js
import { CCard, CCardBody, CCol, CRow, CTabPane } from '@coreui/react'
import { Loader } from 'src/shared'
import { HeaderComponent, Info, ProfilePicture } from './components'
import { USERS_MODEL } from 'src/services/routeConst'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import NotesInfo from './components/NotesInfo'

const ModelsDetails = ({ history, match, modelName = USERS_MODEL }) => {
  const { id = 0 } = match.params
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id,
    modelName,
    history,
  })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData, appsData, avatarFile } = apiData
  console.log("avatarFile", avatarFile);
  
  return (
    <CCard style={{ border: 'none' }}>
      <HeaderComponent
        modelName={modelName}
        onCancelHandle={history.goBack}
        status={status}
        saveData={saveData}
      />
      <CCardBody className={'custom-card-body'}>
        <CRow>
          <CCol>
            <DetailTabSection
              id={id}
              hideNotes={true}
              modelName={modelName}
              tabs={[
                { name: 'Details', label: 'Details' },
                { name: 'Profile-Picture', label: 'Profile Picture' },
              ]}
            >
              <CTabPane data-tab='Details' className='detail-tab-section-pane'>
                <CRow>
                  <CCol xs='12' md='12'>
                    <Info
                      title={'Details'}
                      modelData={modelData}
                      appsData={appsData}
                      handleChange={handleChange}
                    />
                  </CCol>
                </CRow>
              </CTabPane>
              <CTabPane data-tab='Profile-Picture' className='detail-tab-section-pane'>
                {modelData.Id !== 0 && (
                  <CRow>
                    <CCol>
                      <ProfilePicture userData={avatarFile} updateProfilePictureID={() => {}} />
                    </CCol>
                  </CRow>
                )}
              </CTabPane>
            </DetailTabSection>
          </CCol>
          <CCol xs='5'>
            <DetailTabSection
              id={id}
              hideNotes={true}
              modelName={modelName}
              tabs={[{ name: 'Notes', label: 'Notes' }]}
            >
              <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
                <NotesInfo MetasId={modelData.MetasId}></NotesInfo>
              </CTabPane>
            </DetailTabSection>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  )
}

export default ModelsDetails
