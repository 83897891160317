/* eslint-disable react-hooks/exhaustive-deps */
import {
  CButton,
  CCol,
  CFormGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React from 'react'
import { toast } from 'react-toastify'
import { sendOTP, verifyOTP } from 'src/services/modelNew/OTP'
import { FieldsSection, InputField, Loader } from 'src/shared'
interface PropsVerifyUserPhoneModal {
  showModal?: any
  setShowModal?: any
  phone?: any
}
const VerifyUserPhoneModal: React.FC<PropsVerifyUserPhoneModal> = ({
  showModal = false,
  setShowModal,
  phone = '+18059159181',
}) => {
  const [code, setCode] = React.useState('')
  const [status, setStatus] = React.useState('idle')

  const handleChange = (e: any) => {
    setCode(e.value)
  }

  React.useEffect(() => {
    code !== '' && status !== 'idle' && setStatus('idle')
  }, [code])

  React.useEffect(() => {
    if (!showModal) {
      setStatus('idle')
      setCode('')
    }
  }, [showModal])

  const createHiddenPhone = () => {
    const hiddenNum = '******' + phone.substr(-4)
    return hiddenNum
  }

  const handleSubmit = async () => {
    setStatus('verifying')
    const { data, info, error } = await verifyOTP({
      code,
      type: 'Phone',
      toCheck: phone,
      MemberId: 0,
    })
    // console.log(data)
    setCode('')
    setStatus(data && data.Verified ? 'success' : 'error')
    data && data.Verified
      ? toast.success(info) &&
        setTimeout(() => {
          setShowModal(false)
        }, 500)
      : toast.error(error.message)
  }

  const resendCode = () => {
    setStatus('resending_code')
    sendOTP({ type: 'Phone', sendTo: phone, MemberId: 0 })
    setTimeout(() => {
      setStatus('idle')
    }, 3000)
  }

  const handleKeypress = (e: any) => {
    //it triggers by pressing the enter key
    // console.log(canSubmit)
    if (canSubmit && e.key === 'Enter') {
      handleSubmit()
    }
  }

  const hiddenPhone = showModal ? createHiddenPhone() : ''
  const modalColor = status === 'error' ? 'danger' : status === 'success' ? 'success' : 'info'
  const canSubmit = code.length === 6 && status === 'idle'
  return (
    <CModal
      show={showModal}
      onClose={() => {
        setShowModal(false)
      }}
      color={modalColor}
      onKeyPress={handleKeypress}
    >
      <CModalHeader closeButton>
        <CModalTitle>Verify Phone Number</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection>
              <div className='text-center mb-2'>
                <div>Please enter the verification code sent to:</div>
                <small className='text-info'>{hiddenPhone}</small>
              </div>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <CLabel
                    className={`d-flex justify-content-center mb-1 ${
                      status === 'error'
                        ? 'text-danger'
                        : status === 'success'
                        ? 'text-success'
                        : ''
                    }`}
                    htmlFor='code'
                  >
                    <small>
                      {status === 'error'
                        ? 'Error verifying code. Please try again.'
                        : status === 'success'
                        ? 'Code Verified!'
                        : 'Enter Code:'}
                    </small>
                  </CLabel>

                  <InputField
                    name='code'
                    label={null}
                    type='number'
                    alignment='center'
                    value={code}
                    maxLength={6}
                    onChange={handleChange}
                    inputStyles={{ height: 54, fontSize: 32, fontWeight: 700, letterSpacing: 7 }}
                  />
                </CCol>
              </CFormGroup>

              <CButton
                disabled={!canSubmit}
                color={modalColor}
                className='w-75 d-flex mx-auto justify-content-center mb-1'
                onClick={handleSubmit}
              >
                {status === 'verifying' || status === 'success' ? (
                  <Loader size='sm' color='white' style={{ padding: '0.5rem' }} />
                ) : (
                  'Submit Code'
                )}
              </CButton>
              <p className='text-center'>
                Didn{"'"}t get a verification code?{' '}
                <button
                  disabled={status === 'resending_code'}
                  className={`text-${status === 'resending_code' ? 'muted' : modalColor} p-0`}
                  style={{
                    outline: 'none',
                    border: 'none',
                    background: 'none',
                    transition: 'color 150ms ease-in-out',
                  }}
                  onClick={resendCode}
                >
                  Send another one
                </button>
              </p>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
    </CModal>
  )
}

export default VerifyUserPhoneModal
