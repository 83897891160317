import { CButton, CCardHeader } from '@coreui/react'
import React from 'react'
import LoadingButton from 'src/shared/components/LoadingButton'
interface PropsMemberDetailsHeader {
  status?: any
  saveData?: any
  goBack?: any
}
const MemberDetailsHeader: React.FC<PropsMemberDetailsHeader> = ({ status, saveData, goBack }) => {
  const isLoading = status === 'saving'
  return (
    <CCardHeader className='d-flex align-items-center card-accent-secondary'>
      Member Details
      {/* <CButton
        className='mr-1 ml-auto'
        type='button'
        color='primary'
        style={{ float: 'right' }}
        disabled={savingDataRef.current}
        onClick={saveData}
      >
        Save
      </CButton> */}
      <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton>
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={goBack}
      >
        Cancel
      </CButton>
    </CCardHeader>
  )
}
export default React.memo(MemberDetailsHeader)
