import { createStore, applyMiddleware } from 'redux'
import logger from 'redux-logger'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'

const changeState = (_: any) => (next: any) => (action: any) => {
  next(action)
}
const composeWithDevToolsTS: any = composeWithDevTools
const composeEnhancers = composeWithDevToolsTS({
  name: 'Redux',
  realtime: true,
  trace: true,
  traceLimit: 20,
})

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(changeState, logger, thunk)),
)
export default store
