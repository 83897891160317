import React, { useContext } from 'react'
import { ComboBox as CustomComboBox } from 'smart-inputs'
import { DashboardContext } from 'src/containers/DashBoard'

const ComboBox: React.FC<any> = (props: any) => {
  const { upscaled } = useContext(DashboardContext)
  const finalProps = {
    ...props,
    customClassNames: {
      ...props.customClassNames,
      root: `${props.customClassNames?.root ?? ''}${upscaled ? ' ' : ''}`,
    },
  }
  return <CustomComboBox {...finalProps} />
}

export default ComboBox
