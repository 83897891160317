import VehicleInfo from './VehicleInfo'
import AccidentHistoryInfo from './AccidentHistoryInfo'
import VehicleConditionsInfo from './VehicleConditionsInfo'
import MechanicalIssuesInfo from './MechanicalIssuesInfo'
import ModificationsInfo from './ModificationsInfo'
import ElectricalInfo from './ElectricalInfo'

export {
  VehicleInfo,
  AccidentHistoryInfo,
  VehicleConditionsInfo,
  MechanicalIssuesInfo,
  ModificationsInfo,
  ElectricalInfo
}
