import React from 'react'
import { CCard, CCardBody, CCol, CRow, CTabPane } from '@coreui/react'
import { ModelsRepository } from 'src/repository/admin/models/ModelsRepository'
import { DeleteModal, Loader } from 'src/shared'
import { EditTableFieldModal, ModelsHeader, ModelsInfo, NotesInfo } from './components'
import { MODELS_MODEL, COLUMNS_MODEL } from 'src/services/routeConst'

import { ProcImportColumn } from 'src/services/modelNew/ProcGen/Imports/ProcColumn'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'

const ModelDetails: React.FC<any> = ({ history, match, modelName = MODELS_MODEL }) => {
  const [apiData, setApiData] = React.useState<{ modelData?: any; conectionData?: any }>({
    modelData: null,
    conectionData: null,
  })
  const [status, setStatus] = React.useState<any>('loading')
  const [showDeleteModal, setShowDeleteModal] = React.useState<any>(false)
  const [showEditModal, setShowEditModal] = React.useState<any>(false)
  const clickedIDRef = React.useRef<any>(-1)
  const tableRef = React.useRef<any>()
  const changesRef = React.useRef<any>()
  const { id = 0 } = match.params
  const fields = ['Name', 'DbName', 'DbObject', 'DbSchema']

  const modelsRepository = React.useMemo(() => new ModelsRepository(), [])
  //const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(
    async ({ initial = false, shouldUpdateRefs = true }) => {
      try {
        // const { data: conectionData } = await ComboModel({ modelName: CONNECTIONS_MODEL })
        const { mainData } = await modelsRepository.getData({
          id,
          modelName,
          defaultMainData: { ModelsId: 0 },
        })
        const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData

        setApiData((st: any) => ({ ...st, modelData: dataResponse, conectionData: [] }))
        /*  initial &&
          getComboData({
            initial,
            type: 'databaseNames',
            DbName: mainData.DbName,
            SchemaName: mainData.SchemaName,
          }) */
        setStatus('idle')
        !initial && setStatus('idle')
        id && shouldUpdateRefs && updateRefs()
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [id, modelName, modelsRepository],
  )

  const updateRefs = () => {
    tableRef?.current?.fetchNewData()
    changesRef?.current?.fetchNewData()
  }

  React.useEffect(() => {
    getData({ initial: true, shouldUpdateRefs: false })
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ modelData: null })
      history.goBack()
    }, delay)
  }

  const saveData = async () => {
    const dataToSave: any = apiData.modelData

    const IsChangeTracking =
      typeof dataToSave.IsChangeTracking === 'undefined' ? false : dataToSave.IsChangeTracking
    setStatus('saving')
    try {
      const { data } = await modelsRepository.saveData({
        modelName,
        idName: 'ModelsId',
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        getData: () => getData({ initial: true, shouldUpdateRefs: true }),
        goBack,
      })
      await ProcImportColumn({
        procType: 'ChangeTracking',
        params: {
          IsChangeTracking,
          ModelsId: id === 0 ? data.replace(/^\D+/g, '') : id,
        },
      })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setApiData((st: any) => ({ ...st, modelData: { ...st.modelData, [name]: value } }))
  }
  /* 
  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const showEditModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    // console.log({ id })
    setShowEditModal(true)
  } */

  /* const actionFunctions: any = {
    edit: showEditModalFunc,
    delete: showDeleteModalFunc,
  } */
  // console.log(apiData)
  if (!apiData.modelData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { modelData, conectionData } = apiData
  return (
    <>
      <CCard style={{ border: 'none' }}>
        <ModelsHeader
          modelName={modelName}
          status={status}
          saveData={saveData}
          onCancel={history.goBack}
        ></ModelsHeader>
        <CCardBody>
          <CRow>
            <CCol xs='12' md='6' style={{ flex: '0 0 65%', maxWidth: '65%' }}>
              <DetailTabSection
                id={id}
                hideNotes={true}
                modelName={modelName}
                tabs={[{ name: 'Details', label: 'Model Information' }]}
              >
                <CTabPane data-tab='Details' className='detail-tab-section-pane'>
                  <ModelsInfo
                    modelData={modelData}
                    conectionsData={conectionData}
                    handleChange={handleChange}
                  />
                </CTabPane>
              </DetailTabSection>
            </CCol>
            {id ? (
              <CCol xs={5} style={{ marginTop: '0',flex: '0 0 35%', maxWidth: '35%' }}>
                {modelData.Id !== 0 && modelData.MetasId !== null ? (
                  <DetailTabSection
                    id={0}
                    metasId={modelData.MetasId}
                    hideNotes
                    tabs={[{ name: 'Notes', label: 'Notes' }]}
                  >
                    <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
                      <NotesInfo MetasId={modelData.MetasId}></NotesInfo>
                    </CTabPane>
                  </DetailTabSection>
                ) : (
                  <></>
                )}
              </CCol>
            ) : null}
          </CRow>
        </CCardBody>
      </CCard>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName: COLUMNS_MODEL, label: 'Field', id: clickedIDRef.current }}
        callBack={getData}
      />
      <EditTableFieldModal
        showModal={showEditModal}
        setShowModal={setShowEditModal}
        modelName={COLUMNS_MODEL}
        modalData={{ id: clickedIDRef.current, ModelsId: id }}
        callBack={updateRefs}
      />
    </>
  )
}

export default ModelDetails
