import React, { isValidElement, useContext } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { CLink, CBadge } from '@coreui/react'
/* import CIcon from '@coreui/icons-react' */
import { DropdownContext } from '../CSidebarNavDropDownCustom/CSidebarNavDropDownCustom'
import { useLocation } from 'react-router-dom'
//
//component - CoreUI / CSidebarNavItem
const CSidebarNavItemCustom: React.FC<any> = (props) => {
  const {
    children,
    className,
    //
    innerRef,
    name,
    icon,
    badge,
    addLinkClass,
    label,
    color,
    ...rest
  } = props

  let path = ''
  // const value = useLocation()
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    path = useLocation().pathname
    // console.log('ACTUAL PATH', path, 'REST.TO', rest.to)
  } catch (e) {
    console.warn(e)
  }
  /* useEffect(() => {
    console.log('PATH ITEM', path)
  }, [path]) */
  const { isOpen } = useContext<any>(DropdownContext)
  const classes = classNames('c-sidebar-nav-item', className)

  const linkClasses = classNames(
    label ? 'c-sidebar-nav-label' : 'c-sidebar-nav-link',
    color && `c-sidebar-nav-link-${color}`,
    addLinkClass,
  )

  const countString = (value: any) => {
    const regexp = /\//g
    const strArray: any = [...value.matchAll(regexp)]
    return strArray.flat()
  }
  const removeString = (value: any, removeValue: any) => {
    return value.substring(0, value.lastIndexOf(removeValue))
  }
  const removeValue = (value: any) => {
    let lengthTotalArray = countString(value).length
    let valueCut = value
    while (lengthTotalArray > 2) {
      valueCut = removeString(valueCut, '/')
      lengthTotalArray = lengthTotalArray - 1
    }
    return valueCut
  }
  const routerLinkProps = rest.to && {
    exact: true,
    activeClassName: 'c-active',
    isActive: (match: any, _: any) => {
      // console.log('MATCH', match, 'Location', location.path, 'rest.to', rest.to)
      /* console.log('===========')
      console.log(' 1 PATH', path) */
      let actualPath = removeValue(path)
      /*  console.log('2 REMOVE PATH', path, actualPath)
      // console.log('INCLUDES', rest.to.includes(actualPath), 'ACTUALPATH', actualPath)
      console.log('3 INCLUDE PATH', path, actualPath, rest.to.includes(actualPath)) */
      if (rest.to === '/dashboard' || path === '/' || actualPath === '/') {
        /* console.log('4 RESTO HERE') */
        return false
      }
      if (rest.to.includes(actualPath)) {
        return true
      }

      if (!match) {
        // console.log('NOMATCH')
        return false
      }
      return false
    },
  }
  const addColor = () => {
    // onsole.log('COLOR path', path.includes('New'))
    let actualPath = removeValue(path)
    if (rest?.to?.includes(actualPath)) {
      if (path.includes('New')) {
        return { color: '#7267D3' }
      }
      if (path.includes('Detail')) {
        return { color: '#7267D3' }
      }
      return {}
    }
    return {}
  }
  const styleIcon = addColor()

  return (
    <li className={classes} ref={innerRef}>
      {children || (
        <CLink
          className={linkClasses}
          {...routerLinkProps}
          {...rest}
          tabIndex={isOpen === false ? -1 : 0}
        >
          {icon &&
            (isValidElement(icon) ? (
              icon
            ) : (
              <i style={{ ...styleIcon }} className={`${icon} c-sidebar-nav-icon`} />
            ))}
          {name}
          {badge && <CBadge {...{ ...badge, text: null }}>{badge.text}</CBadge>}
        </CLink>
      )}
    </li>
  )
}

CSidebarNavItemCustom.propTypes = {
  children: PropTypes.node,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  badge: PropTypes.object,
  addLinkClass: PropTypes.string,
  label: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  color: PropTypes.string,
}

export default CSidebarNavItemCustom
