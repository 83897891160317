import React from 'react'
import { FieldsSection } from '../../../../shared'
import AddressFinderAux from '../../../../shared/inputFields/AddressFinderAux'

interface PropsAccountAddress {
  id?: any
  allMembersData?: any
  memberData?: any
  handleChange?: any
  handleComboChange?: any
  handlePhoneChange?: any
  showVerifyOTPModalFunc?: any
  sendingOTP?: any
  showVerifyPhoneModal?: any
  showVerifyEmailModal?: any
  handleAddressChange?: any
}
const AccountAddress: React.FC<PropsAccountAddress> = ({
  /* id, */
  /* allMembersData, */
  memberData,
  handleChange,
  handleAddressChange,
  /* handleComboChange, */
  /* handlePhoneChange,
  showVerifyOTPModalFunc,
  sendingOTP,
  showVerifyPhoneModal, */
  // showVerifyEmailModal,
}) => {
  // const btnRef = React.useRef<any>()

  const { Address, Address2, State, City, ZipCode } = memberData
  const handleAddressDataChange = ({ value }: any) => {
    handleAddressChange(value)
  }

  return (
    <FieldsSection header='Trip Information'>
      <AddressFinderAux
        address={{ Address, Address2, City, State, ZipCode }}
        handleChange={handleChange}
        handleAddressDataChange={handleAddressDataChange}
      />
    </FieldsSection>
  )
}
export default React.memo(AccountAddress)
