// Generated with util/create-search-page.js
import { CButton, CCardHeader } from '@coreui/react'
import LoadingButton from 'src/shared/components/LoadingButton'

const CampaignHeader = ({ status, saveData, onCancel, IsSent, SendOn, sendMessages }) => {
  const isLoading = status === 'saving'
  return (
    <CCardHeader className='d-flex align-items-center' style={{ backgroundColor: '#2A3446', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}>
      <span style={{ color: 'white' }}>Campaign Details</span>
      
      {!IsSent ? <> {SendOn != null && SendOn != undefined ? <LoadingButton
        className=''
        style={{ float: 'right', marginLeft: '1280px' }}
        loading={isLoading}
        onClick={sendMessages}
      >
        Send
      </LoadingButton> : ''}
      <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton> </>: <CButton
        className='mr-1 ml-auto'
        type='button'
        color='success'
        style={{ float: 'right' }}
      >
        <b>Completed On {SendOn}</b>
      </CButton>
      }
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancel}
      >
        Close
      </CButton>
    </CCardHeader>
  )
}

export default CampaignHeader
