import { format, isToday, isThisWeek, isThisYear } from 'date-fns'
import { isYesterday } from 'date-fns/esm'
import { Notes } from 'smart-notes'
import React from 'react'
import { connect } from 'react-redux'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, LIST, MODELS, SAVE, SERVICES } from 'src/services/routeConst'
// import { FieldsSection } from 'src/shared'
import Note from 'src/shared/components/Note'

const groupNotesByDate = (notes: any[]) => {
  // this gives an object with dates as keys
  const groupedNotes = notes.reduce((groupedNotes: any, note: any) => {
    let d = new Date(note.ChangedOn)
    const date = d.toString()
    if (!groupedNotes[date]) {
      groupedNotes[date] = []
    }
    groupedNotes[date].push(note)
    return groupedNotes
  }, {})

  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groupedNotes).map((date) => {
    return {
      date,
      notes: groupedNotes[date],
    }
  })

  return groupArrays
}
const forHumansDate = (date: string) => {
  if (date) {
    const dateParse = new Date(date)
    if (isToday(dateParse)) {
      return `Today ${format(dateParse, "hh:mm aaaaa'm'")}`
    }
    if (isYesterday(dateParse)) {
      return `Yesterday ${format(dateParse, "hh:mm aaaaa'm'")}`
    }

    if (isThisWeek(dateParse)) {
      return format(dateParse, "E hh:mm aaaaa'm'")
    }
    if (isThisYear(dateParse)) {
      return format(dateParse, "E, LLL d hh:mm aaaaa'm'")
    }
    return `${format(dateParse, "E, LLL d, yyy hh:mm aaaaa'm'")}`
  }
  return ''
}
interface Props {
  metasId?: string
}
const NotesSection: React.FC<Props> = ({ metasId = 1 }) => {
  const Guid = '123'
  const renderNotes = (notes: any) => {
    const groupedNotes = groupNotesByDate(notes)
    return groupedNotes.map((noteGroup, idx) => (
      <React.Fragment key={idx}>
        <small
          className='d-block text-center mt-2'
          style={{ color: 'rgba(241,241,255,0.5)', fontSize: 12 }}
        >
          {/* {noteGroup.date} */}
          {/*  {'Tue Aug 31 2021 21:10:50 GMT-0400 (Bolivia Time)'} */}
          {forHumansDate(noteGroup?.date ?? null)}
        </small>
        {noteGroup?.notes.map((note: any) => (
          <Note note={note} Guid={Guid} key={note.Id} />
        ))}
      </React.Fragment>
    ))
  }

  return (
    <Notes
      metasId={`${metasId}`}
      classNames={{
        input: 'input-field',
        root: 'input-field-root',
      }}
      name='Notes'
      placeholder='Enter a note...'
      renderNotes={(notes: any) => renderNotes(notes)}
      authToken={getAuthToken('ADMIN')}
      Endpoints={{
        Fetch: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
        Save: `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
      }}
      data={{
        Fetch: {
          Model: 'Notes',
          Extension: null,
          Params: [
            {
              Name: 'SearchString',
              Value: null,
            },
            {
              Name: 'OrderBy',
              Value: null,
            },
            {
              Name: 'OrderByDir',
              Value: null,
            },
            {
              Name: 'Page',
              Value: 1,
            },
            {
              Name: 'PageSize',
              Value: 10000,
            },
          ],
        },
        Save: {
          Model: 'Notes',
          Extension: null,
          Id: 0,
          Params: [],
        },
      }}
    />
  )
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  return {
    loggedInUser: auth.loggedInUser,
  }
}

export default React.memo(connect(mapStateToProps, null)(NotesSection))

// <FieldsSection header='Notes'>
//   <CFormGroup row className='justify-content-center'>

//   </CFormGroup>
// </FieldsSection>
