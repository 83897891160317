import { CCol, CContainer, CFormGroup, CRow } from '@coreui/react'
import axios from 'axios'
// import { InputField } from 'smart-inputs'
import { useEffect, useState } from 'react'
import { APIError } from 'src/services/API/APIError'
// import { APIError } from 'src/services/API/APIError'
import { addAuthAccessTokenToHeader, logoutVerifyErrors } from 'src/services/authHelper'
import { BASE_API, DATA, DATA_EXEC, SERVICES } from 'src/services/routeConst'
// import { BASE_API, MODELS, SAVE, SERVICES } from 'src/services/routeConst'

interface PropsCalendarForm {
  modelData: any
  title: string
}
interface DateData {
  Friday: number
  Monday: number
  Saturday: number
  Sunday: number
  Thursday: number
  Timeslot: number
  Tuesday: number
  Wednesday: number
}

const Calendar = ({ modelData }: PropsCalendarForm) => {
  const [list, setList] = useState({ Execution: [] })
  const [timeslot, setTimeslot] = useState({ ListInfo: [] })

  const IDCUSTOMER = modelData.Id

  const fetchData = async () => {
    try {
      const response = await axios.post(
        `https://api.softsmartinc.com/Services/vOld/Data/Execute`,
        {
          QueryOrProcedureName: 'deal.INSPECTORS_TIMESLOTS_ListWeeklyCalendar',
          DbName: 'DbOltp',
          Params: [
            {
              Name: 'Id',
              Value: IDCUSTOMER,
            },
          ],
        },
        { ...addAuthAccessTokenToHeader() },
      )

      setList(response.data.data)
    } catch (error: any) {
      const status = error.response.status
      logoutVerifyErrors(status)
      console.log(error)
    }
  }

  const fetchDataList = async () => {
    try {
      const response = await axios.post(
        `https://api.softsmartinc.com/Services/vOld/Models/List`,
        {
          Model: 'InspectorsTimeslots',
          Extension: null,
          Params: [
            {
              Name: 'SearchString',
              Value: null,
            },
            {
              Name: 'OrderBy',
              Value: null,
            },
            {
              Name: 'OrderByDir',
              Value: null,
            },
          ],
        },
        { ...addAuthAccessTokenToHeader() },
      )

      setTimeslot(response.data.data)
    } catch (error: any) {
      const status = error.response.status
      logoutVerifyErrors(status)
      console.log(error)
    }
  }
  useEffect(() => {
    fetchData()
    fetchDataList()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setList, setTimeslot])

  let nine: DateData = list.Execution[0] || {
    Friday: 0,
    Monday: 0,
    Saturday: 0,
    Sunday: 0,
    Thursday: 0,
    Timeslot: 0,
    Tuesday: 0,
    Wednesday: 0,
  }
  let eleven: DateData = list.Execution[1] || {
    Friday: 0,
    Monday: 0,
    Saturday: 0,
    Sunday: 0,
    Thursday: 0,
    Timeslot: 0,
    Tuesday: 0,
    Wednesday: 0,
  }
  let thirteen: DateData = list.Execution[2] || {
    Friday: 0,
    Monday: 0,
    Saturday: 0,
    Sunday: 0,
    Thursday: 0,
    Timeslot: 0,
    Tuesday: 0,
    Wednesday: 0,
  }
  let fifteen: DateData = list.Execution[3] || {
    Friday: 0,
    Monday: 0,
    Saturday: 0,
    Sunday: 0,
    Thursday: 0,
    Timeslot: 0,
    Tuesday: 0,
    Wednesday: 0,
  }
  let seventeen: DateData = list.Execution[4] || {
    Friday: 0,
    Monday: 0,
    Saturday: 0,
    Sunday: 0,
    Thursday: 0,
    Timeslot: 0,
    Tuesday: 0,
    Wednesday: 0,
  }

  let idDate = [
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0],
  ]

  timeslot.ListInfo.forEach(function (v) {
    if (v['TimeslotTime'] === 9 && v['WeekdayDay'] === 'Sunday') idDate[0][0] = v['Id']
    if (v['TimeslotTime'] === 9 && v['WeekdayDay'] === 'Monday') idDate[0][1] = v['Id']
    if (v['TimeslotTime'] === 9 && v['WeekdayDay'] === 'Tuesday') idDate[0][2] = v['Id']
    if (v['TimeslotTime'] === 9 && v['WeekdayDay'] === 'Wednesday') idDate[0][3] = v['Id']
    if (v['TimeslotTime'] === 9 && v['WeekdayDay'] === 'Thursday') idDate[0][4] = v['Id']
    if (v['TimeslotTime'] === 9 && v['WeekdayDay'] === 'Friday') idDate[0][5] = v['Id']
    if (v['TimeslotTime'] === 9 && v['WeekdayDay'] === 'Saturday') idDate[0][6] = v['Id']

    if (v['TimeslotTime'] === 11 && v['WeekdayDay'] === 'Sunday') idDate[1][0] = v['Id']
    if (v['TimeslotTime'] === 11 && v['WeekdayDay'] === 'Monday') idDate[1][1] = v['Id']
    if (v['TimeslotTime'] === 11 && v['WeekdayDay'] === 'Tuesday') idDate[1][2] = v['Id']
    if (v['TimeslotTime'] === 11 && v['WeekdayDay'] === 'Wednesday') idDate[1][3] = v['Id']
    if (v['TimeslotTime'] === 11 && v['WeekdayDay'] === 'Thursday') idDate[1][4] = v['Id']
    if (v['TimeslotTime'] === 11 && v['WeekdayDay'] === 'Friday') idDate[1][5] = v['Id']
    if (v['TimeslotTime'] === 11 && v['WeekdayDay'] === 'Saturday') idDate[1][6] = v['Id']

    if (v['TimeslotTime'] === 13 && v['WeekdayDay'] === 'Sunday') idDate[2][0] = v['Id']
    if (v['TimeslotTime'] === 13 && v['WeekdayDay'] === 'Monday') idDate[2][1] = v['Id']
    if (v['TimeslotTime'] === 13 && v['WeekdayDay'] === 'Tuesday') idDate[2][2] = v['Id']
    if (v['TimeslotTime'] === 13 && v['WeekdayDay'] === 'Wednesday') idDate[2][3] = v['Id']
    if (v['TimeslotTime'] === 13 && v['WeekdayDay'] === 'Thursday') idDate[2][4] = v['Id']
    if (v['TimeslotTime'] === 13 && v['WeekdayDay'] === 'Friday') idDate[2][5] = v['Id']
    if (v['TimeslotTime'] === 13 && v['WeekdayDay'] === 'Saturday') idDate[2][6] = v['Id']

    if (v['TimeslotTime'] === 15 && v['WeekdayDay'] === 'Sunday') idDate[3][0] = v['Id']
    if (v['TimeslotTime'] === 15 && v['WeekdayDay'] === 'Monday') idDate[3][1] = v['Id']
    if (v['TimeslotTime'] === 15 && v['WeekdayDay'] === 'Tuesday') idDate[3][2] = v['Id']
    if (v['TimeslotTime'] === 15 && v['WeekdayDay'] === 'Wednesday') idDate[3][3] = v['Id']
    if (v['TimeslotTime'] === 15 && v['WeekdayDay'] === 'Thursday') idDate[3][4] = v['Id']
    if (v['TimeslotTime'] === 15 && v['WeekdayDay'] === 'Friday') idDate[3][5] = v['Id']
    if (v['TimeslotTime'] === 15 && v['WeekdayDay'] === 'Saturday') idDate[3][6] = v['Id']

    if (v['TimeslotTime'] === 17 && v['WeekdayDay'] === 'Sunday') idDate[4][0] = v['Id']
    if (v['TimeslotTime'] === 17 && v['WeekdayDay'] === 'Monday') idDate[4][1] = v['Id']
    if (v['TimeslotTime'] === 17 && v['WeekdayDay'] === 'Tuesday') idDate[4][2] = v['Id']
    if (v['TimeslotTime'] === 17 && v['WeekdayDay'] === 'Wednesday') idDate[4][3] = v['Id']
    if (v['TimeslotTime'] === 17 && v['WeekdayDay'] === 'Thursday') idDate[4][4] = v['Id']
    if (v['TimeslotTime'] === 17 && v['WeekdayDay'] === 'Friday') idDate[4][5] = v['Id']
    if (v['TimeslotTime'] === 17 && v['WeekdayDay'] === 'Saturday') idDate[4][6] = v['Id']
  })

  const deleteDate = async (day, timeslot) => {
    try {
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${DATA}/${DATA_EXEC}`,
        {
          DbName: 'DbOltp',
          QueryOrProcedureName: 'deal.INSPECTORS_TIMESLOTS_SaveOrDelete',
          Params: [
            {
              Name: 'Id',
              Value: 0,
            },
            {
              Name: 'InspectorsId',
              Value: IDCUSTOMER,
            },
            {
              Name: 'WeekdayDay',
              Value: day,
            },
            {
              Name: 'TimeslotTime',
              Value: timeslot,
            },
            {
              Name: 'IsOn',
              Value: 0,
            },
          ],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      fetchData()
      // fetchDataList()
      return {
        data: data.data,
        status: 'success',
        message: data.info,
      }
    } catch (error: any) {
      const status = error.response.status
      logoutVerifyErrors(status)
      throw new APIError({
        status: 'error',
        message: `Error deleting InspectorsTimeslots!`,
        error: error.response,
      })
    }
  }
  const saveDate = async (day, timeslot) => {
    try {
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${DATA}/${DATA_EXEC}`,
        {
          DbName: 'DbOltp',
          QueryOrProcedureName: 'deal.INSPECTORS_TIMESLOTS_SaveOrDelete',
          Params: [
            {
              Name: 'Id',
              Value: 0,
            },
            {
              Name: 'InspectorsId',
              Value: IDCUSTOMER,
            },
            {
              Name: 'WeekdayDay',
              Value: day,
            },
            {
              Name: 'TimeslotTime',
              Value: timeslot,
            },
            {
              Name: 'IsOn',
              Value: 1,
            },
          ],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      fetchData()
      fetchDataList()
      return {
        data: data.data,
        status: 'success',
        error: data.error,
        message: data.info,
      }
    } catch (error: any) {
      const status = error.response.status
      logoutVerifyErrors(status)
      throw new APIError({
        status: 'error',
        message: `Error saving InspectorsTimeslots!`,
        error: error.response,
      })
    }
  }
  // Call method like OFF
  const forDelete = (day, timeslot) => (
    <CCol
      onClick={async () => await deleteDate(day, timeslot)}
      style={{
        height: '30px',
        cursor: 'pointer',
        margin: '0.2em',
        backgroundColor: 'green',
        fontSize: '10px',
        paddingRight: '1px',
        paddingLeft: '1px',
      }}
    ></CCol>
  )
  // call method like ON
  const forSave = (day, timeslot) => (
    <CCol
      onClick={async () => await saveDate(day, timeslot)}
      style={{
        height: '30px',
        cursor: 'pointer',
        margin: '0.2em',
        backgroundColor: 'white',
        fontSize: '10px',
        paddingRight: '1px',
        paddingLeft: '1px',
      }}
    ></CCol>
  )

  const getHour = (hour) => {
    let hourText = ''
    if (hour === 9) hourText = '9 am'
    if (hour === 11) hourText = '11 am'
    if (hour === 13) hourText = '1 pm'
    if (hour === 15) hourText = '3 pm'
    if (hour === 17) hourText = '5 pm'
    return (
      <span
        style={{
          width: '4em',
          alignSelf: 'center',
          textAlign: 'end',
          fontSize: '10px',
          paddingRight: '5px',
          fontWeight: 'bold',
          color: '#111',
        }}
      >
        {hourText}
      </span>
    )
  }

  return (
    <CFormGroup row>
      <CContainer
        style={{
          padding: '1em 2em 1em 2em',
          backgroundColor: '#ddd',
          borderRadius: '10px',
        }}
      >
        <CRow>
          <span
            style={{
              width: '4em',
              alignSelf: 'center',
              textAlign: 'end',
              fontSize: '10px',
              paddingRight: '5px',
            }}
          ></span>
          <CCol
            style={{
              textAlign: 'center',
              fontSize: '12px',
              paddingRight: '1px',
              paddingLeft: '1px',
              paddingBottom: '0.8em',
              color: '#111',
              fontWeight: 'bold',
            }}
          >
            Sunday
          </CCol>
          <CCol
            style={{
              textAlign: 'center',
              fontSize: '12px',
              paddingRight: '1px',
              paddingLeft: '1px',
              paddingBottom: '0.8em',
              color: '#111',
              fontWeight: 'bold',
            }}
          >
            Monday
          </CCol>
          <CCol
            style={{
              textAlign: 'center',
              fontSize: '12px',
              paddingRight: '1px',
              paddingLeft: '1px',
              paddingBottom: '0.8em',
              color: '#111',
              fontWeight: 'bold',
            }}
          >
            Tuesday
          </CCol>
          <CCol
            style={{
              textAlign: 'center',
              fontSize: '12px',
              paddingRight: '1px',
              paddingLeft: '1px',
              paddingBottom: '0.8em',
              color: '#111',
              fontWeight: 'bold',
            }}
          >
            Wednesday
          </CCol>
          <CCol
            style={{
              textAlign: 'center',
              fontSize: '12px',
              paddingRight: '1px',
              paddingLeft: '1px',
              paddingBottom: '0.8em',
              color: '#111',
              fontWeight: 'bold',
            }}
          >
            Thursday
          </CCol>
          <CCol
            style={{
              textAlign: 'center',
              fontSize: '12px',
              paddingRight: '1px',
              paddingLeft: '1px',
              paddingBottom: '0.8em',
              color: '#111',
              fontWeight: 'bold',
            }}
          >
            Friday
          </CCol>
          <CCol
            style={{
              textAlign: 'center',
              fontSize: '12px',
              paddingRight: '1px',
              paddingLeft: '1px',
              paddingBottom: '0.8em',
              color: '#111',
              fontWeight: 'bold',
            }}
          >
            Saturday
          </CCol>
        </CRow>
        <CRow>
          {nine ? getHour(nine['Timeslot']) : ''}
          {nine['Sunday'] !== 0
            ? forDelete('Sunday', nine['Timeslot'])
            : forSave('Sunday', nine['Timeslot'])}
          {nine['Monday'] !== 0
            ? forDelete('Monday', nine['Timeslot'])
            : forSave('Monday', nine['Timeslot'])}
          {nine['Tuesday'] !== 0
            ? forDelete('Tuesday', nine['Timeslot'])
            : forSave('Tuesday', nine['Timeslot'])}
          {nine['Wednesday'] !== 0
            ? forDelete('Wednesday', nine['Timeslot'])
            : forSave('Wednesday', nine['Timeslot'])}
          {nine['Thursday'] !== 0
            ? forDelete('Thursday', nine['Timeslot'])
            : forSave('Thursday', nine['Timeslot'])}
          {nine['Friday'] !== 0
            ? forDelete('Friday', nine['Timeslot'])
            : forSave('Friday', nine['Timeslot'])}
          {nine['Saturday'] !== 0
            ? forDelete('Saturday', nine['Timeslot'])
            : forSave('Saturday', nine['Timeslot'])}
        </CRow>

        <CRow>
          {eleven ? getHour(eleven['Timeslot']) : ''}
          {eleven['Sunday'] !== 0
            ? forDelete('Sunday', eleven['Timeslot'])
            : forSave('Sunday', eleven['Timeslot'])}
          {eleven['Monday'] !== 0
            ? forDelete('Monday', eleven['Timeslot'])
            : forSave('Monday', eleven['Timeslot'])}
          {eleven['Tuesday'] !== 0
            ? forDelete('Tuesday', eleven['Timeslot'])
            : forSave('Tuesday', eleven['Timeslot'])}
          {eleven['Wednesday'] !== 0
            ? forDelete('Wednesday', eleven['Timeslot'])
            : forSave('Wednesday', eleven['Timeslot'])}
          {eleven['Thursday'] !== 0
            ? forDelete('Thursday', eleven['Timeslot'])
            : forSave('Thursday', eleven['Timeslot'])}
          {eleven['Friday'] !== 0
            ? forDelete('Friday', eleven['Timeslot'])
            : forSave('Friday', eleven['Timeslot'])}
          {eleven['Saturday'] !== 0
            ? forDelete('Saturday', eleven['Timeslot'])
            : forSave('Saturday', eleven['Timeslot'])}
        </CRow>

        <CRow>
          {thirteen ? getHour(thirteen['Timeslot']) : ''}
          {thirteen['Sunday'] !== 0
            ? forDelete('Sunday', thirteen['Timeslot'])
            : forSave('Sunday', thirteen['Timeslot'])}
          {thirteen['Monday'] !== 0
            ? forDelete('Monday', thirteen['Timeslot'])
            : forSave('Monday', thirteen['Timeslot'])}
          {thirteen['Tuesday'] !== 0
            ? forDelete('Tuesday', thirteen['Timeslot'])
            : forSave('Tuesday', thirteen['Timeslot'])}
          {thirteen['Wednesday'] !== 0
            ? forDelete('Wednesday', thirteen['Timeslot'])
            : forSave('Wednesday', thirteen['Timeslot'])}
          {thirteen['Thursday'] !== 0
            ? forDelete('Thursday', thirteen['Timeslot'])
            : forSave('Thursday', thirteen['Timeslot'])}
          {thirteen['Friday'] !== 0
            ? forDelete('Friday', thirteen['Timeslot'])
            : forSave('Friday', thirteen['Timeslot'])}
          {thirteen['Saturday'] !== 0
            ? forDelete('Saturday', thirteen['Timeslot'])
            : forSave('Saturday', thirteen['Timeslot'])}
        </CRow>

        <CRow>
          {fifteen ? getHour(fifteen['Timeslot']) : ''}
          {fifteen['Sunday'] !== 0
            ? forDelete('Sunday', fifteen['Timeslot'])
            : forSave('Sunday', fifteen['Timeslot'])}
          {fifteen['Monday'] !== 0
            ? forDelete('Monday', fifteen['Timeslot'])
            : forSave('Monday', fifteen['Timeslot'])}
          {fifteen['Tuesday'] !== 0
            ? forDelete('Tuesday', fifteen['Timeslot'])
            : forSave('Tuesday', fifteen['Timeslot'])}
          {fifteen['Wednesday'] !== 0
            ? forDelete('Wednesday', fifteen['Timeslot'])
            : forSave('Wednesday', fifteen['Timeslot'])}
          {fifteen['Thursday'] !== 0
            ? forDelete('Thursday', fifteen['Timeslot'])
            : forSave('Thursday', fifteen['Timeslot'])}
          {fifteen['Friday'] !== 0
            ? forDelete('Friday', fifteen['Timeslot'])
            : forSave('Friday', fifteen['Timeslot'])}
          {fifteen['Saturday'] !== 0
            ? forDelete('Saturday', fifteen['Timeslot'])
            : forSave('Saturday', fifteen['Timeslot'])}
        </CRow>

        <CRow>
          {seventeen ? getHour(seventeen['Timeslot']) : ''}
          {seventeen['Sunday'] !== 0
            ? forDelete('Sunday', seventeen['Timeslot'])
            : forSave('Sunday', seventeen['Timeslot'])}
          {seventeen['Monday'] !== 0
            ? forDelete('Monday', seventeen['Timeslot'])
            : forSave('Monday', seventeen['Timeslot'])}
          {seventeen['Tuesday'] !== 0
            ? forDelete('Tuesday', seventeen['Timeslot'])
            : forSave('Tuesday', seventeen['Timeslot'])}
          {seventeen['Wednesday'] !== 0
            ? forDelete('Wednesday', seventeen['Timeslot'])
            : forSave('Wednesday', seventeen['Timeslot'])}
          {seventeen['Thursday'] !== 0
            ? forDelete('Thursday', seventeen['Timeslot'])
            : forSave('Thursday', seventeen['Timeslot'])}
          {seventeen['Friday'] !== 0
            ? forDelete('Friday', seventeen['Timeslot'])
            : forSave('Friday', seventeen['Timeslot'])}
          {seventeen['Saturday'] !== 0
            ? forDelete('Saturday', seventeen['Timeslot'])
            : forSave('Saturday', seventeen['Timeslot'])}
        </CRow>
      </CContainer>
    </CFormGroup>
  )
}

export default Calendar
