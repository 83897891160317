// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
// import { toast } from 'react-toastify'
import { FieldsSection, InputField } from 'src/shared'
// import { RadioGroup, Radio } from 'react-radio-input'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const AppointmentInfo = ({
  modelData,
  handleChange,
  title = 'Model Name',
}: PropsInfoForm) => {

  const { 
    InspectionTimeslot,
    PhoneMobile
  } = modelData  
  let {InspectionDate} = modelData

  const formatDate = (date) => {
    if(date) {
      date = date.split('-')
      const day = String(date[0])
      const month = String(date[1])
      const year = String(date[2])
      if(day.length===2){
        return `${year}-${day}-${month}`
      }
      return `${day}-${month}-${year}`
    }
    return ''
  }
  InspectionDate = formatDate(InspectionDate)

  return (
    <>
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='PhoneMobile'
            type='phone'
            label='PhoneMobile'
            value={PhoneMobile ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol style={{marginTop: '2px'}} md='12' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='InspectionDate'
            type='date'
            label='Inspection Date'
            value={InspectionDate ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='InspectionTimeslot'
            type='mask'
            label='Timeslot'
            value={InspectionTimeslot ?? ''}
            // onChange={handleChange}
            mask={[
              /[0-9]/,
              /[0-9]/,
              ':',
              /[0-9]/,
              /[0-9]/,
            ]}
            onChange={({ name, value }) => {
              handleChange({ name, value: value.replaceAll('#', '').replaceAll(':', ':') })
            }}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
    </>
  )
}

export default AppointmentInfo
