import { ColumnData } from 'library-soft-datatable'

export const userTableColumns: ColumnData[] = [
  {
    label: 'Avatar',
    name: 'AvatarUrl',
    alignment: 'center',
    type: 'text',
    customWidth: 6,
  },
  {
    label: 'First Name',
    name: 'FirstName',
  },
  {
    label: 'Last Name',
    name: 'LastName',
  },
  {
    label: 'Phone',
    name: 'Phone',
    type: 'phone',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]

export const loginHistoryTableColumns = [
  {
    label: 'UserName',
    name: 'UserName',
  },
  {
    label: 'Access Token',
    name: 'AccessToken',
  },
  {
    label: 'Validity',
    name: 'Validity',
  },
  {
    label: 'User Type',
    name: 'UserType',
  },
]
