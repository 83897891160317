import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import React, { useContext, useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { modelsTableColumns } from './modelsTableColumns'
import { BASE_API, MODELS_MODEL } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'
import { DashboardContext } from 'src/containers/DashBoard'
import { toast } from 'react-toastify'
import { ProcGenerateImport } from 'src/services/modelNew/ProcGen/Imports/ProcImport'
import axios from 'axios'

const ModelsSearch: React.FC<any> = ({ history, modelName = MODELS_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const clickedIDRef = useRef()
  const tableRef = useRef<any>()
  const { setShowLinear, showLinear } = useContext(DashboardContext)
  const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Admin/DispatchNow/Detail/${id}`)
  }
  try {
    const response = axios.get('http://localhost:8008/models')
    console.log(response)
  } catch (error) {
    console.error(error)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }
  const procGenerate = async () => {
    try {
      setShowLinear(true)
      toast.info('Importing Procs this could take a few minutes.')
      const { message } = await ProcGenerateImport({
        procType: 'Models',
      })
      toast.success(message)
      setShowLinear(false)
    } catch (error: any) {
      setShowLinear(false)
      toast.error(`${error.message}`)
    }
  }
  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        Dispatch Now Search
        {/* {modelName} Search */}
        <CButton
          disabled={showLinear}
          size='sm'
          className='ml-1'
          type='button'
          color='primary'
          onClick={procGenerate}
        >
          Imports Models
        </CButton>
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, `/Database/Models/New`)}
        >
          New Model
        </CButton>
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={modelsTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}/Models`}
          modelName={modelName}
          idName={'Id'}
          actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Model', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CCard>
  )
}

export default ModelsSearch
