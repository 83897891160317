import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const ConnectionsTableColumns: ColumnData[] = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'DbServer',
    name: 'DbServer',
  },
  {
    label: 'DbName',
    name: 'DbName',
  },
  {
    label: 'DbUser',
    name: 'DbUser',
  },
  {
    label: 'DbPassword',
    name: 'DbPassword',
  },
  {
    label: 'ConnectionString',
    name: 'ConnectionString',
  },
  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
