import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React from 'react'
import { DeleteModel } from 'src/services/modelNew/Delete/Delete'
interface ModalData {
  modelName: any
  label: any
  id: any
}
interface PropsDeleteModal {
  showModal: boolean
  setShowModal: any
  modalData: ModalData
  callBack: any
}
const DeleteModal: React.FC<PropsDeleteModal> = ({
  showModal = false,
  setShowModal,
  modalData = { modelName: '', label: '', id: -1 },
  callBack = () => {},
}: PropsDeleteModal) => {
  const { modelName, label, id } = modalData

  const deleteData = async (modelName: any, id: any) => {
    try {
      await DeleteModel({ modelName, data: { id } })
    } catch (error: any) {
      console.error(error)
    }
    callBack()
  }

  const handleDelete = () => {
    deleteData(modelName, id)
    setShowModal(false)
  }

  return (
    <CModal show={showModal} onClose={setShowModal} color='danger'>
      <CModalHeader closeButton>
        <CModalTitle>
          Delete {label}?
        </CModalTitle>
      </CModalHeader>
      <CModalBody style={{ color: '#000' }}>
        Are you sure you want to delete{label !== '' ? ` this ${label}` : ''}?
      </CModalBody>
      <CModalFooter>
        <CButton color='danger' onClick={handleDelete}>
          Delete
        </CButton>
        <CButton color='secondary' onClick={() => setShowModal(false)}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default DeleteModal
