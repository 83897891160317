import { CButton } from '@coreui/react'

import { InputField } from 'src/shared'

export const PhoneVerification = () => {
  return (
    <>
      <h3>
        Enter the verification code sent to Your Pone <br /> Number ending in ### ###-####
      </h3>
      <InputField type='phone' />
      <p>PLease input the 6 digit Verification code</p>
      <CButton color='primary' size='lg'>
        Verify
      </CButton>
    </>
  )
}
