// Generated with util/create-search-page.js
import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react'
import { LOOKUP_MODEL } from 'src/services/routeConst'
import { InputField, Loader } from 'src/shared'
import { StatusInfo } from '../enum/Status'
import { useActionsModels } from '../hooks/ActionsModels'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  id: any
  title?: string
  show: boolean
  onClose: any
  typesId: any
}

const InspectorInfo = ({ id, show, onClose, typesId }: PropsInfoForm) => {
  const modelName = LOOKUP_MODEL
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id,
    typesId,
    modelName,
    history: null,
  })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData } = apiData

  return (
    <CModal show={show} onClose={onClose}>
      <CModalHeader>Lookup</CModalHeader>
      <CModalBody>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='Code'
              type='text'
              label='Code'
              value={modelData?.Code ?? ''}
              onChange={({ name, value }) => {
                console.log(name, value)
                handleChange({ name, value })
              }}
            />
          </CCol>
          <CCol md='12' className='pr-lg-2 pl-lg-2'>
            <InputField
              name='Description'
              type='text'
              label='Description'
              value={modelData?.Description ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
      </CModalBody>
      <CModalFooter>
        <CButton
          color='secondary'
          onClick={async () => {
            onClose()
          }}
        >
          Cancel
        </CButton>
        <CButton
          color='primary'
          onClick={async () => {
            await saveData()
          }}
        >
          {'Save & New'}
        </CButton>
        <CButton
          color='primary'
          onClick={async () => {
            await saveData()
            onClose()
          }}
        >
          Save{' '}
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default InspectorInfo
