export const TableColumns = [
  /* type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center', */
  {
    label: 'Email',
    name: 'Email',
  },
  {
    label: 'Nickname',
    name: 'Nickname',
  },
  {
    label: 'Phone',
    name: 'Phone',
    type: 'phone',
  },
  {
    label: 'Is Account Locked',
    name: 'IsAccountLocked',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'Is New',
    name: 'IsNew',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'IsLockedPolicy',
    name: 'Is Locked Policy',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'IsActive',
    name: 'Is Active',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
