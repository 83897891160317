import CIcon from '@coreui/icons-react'
import { CCol, CFormGroup } from '@coreui/react'
import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useGeolocation } from 'src/hooks/useGeolocation'
import Gmap from './Gmap'
import InputField from './InputField'
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

interface AddressData {
  Address: string
  Address2: string
  City: string
  State: string
  ZipCode: string
  Lat: number
  Lng: number
}
interface PropsAddressMap {
  address?: AddressData
  handleChange?: any
  handleAddressDataChange?: any
  name?: string
}
// const containerStyle = {
//   width: '400px',
//   height: '400px'
// };

const AddressMap: React.FC<PropsAddressMap> = ({
  address = { Address: '', Address2: '', City: '', State: '', ZipCode: '', Lat: 0, Lng: 0 },
  handleChange,
  handleAddressDataChange,
  name = '',
}) => {
  const btnRef = useRef<any>()
  const [locationData, getLocationData] = useGeolocation()

  // const [latitude, setLatitude] = React.useState(2)

  useEffect(() => {
    locationData && console.log(locationData)
  }, [locationData])

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      // console.log(place);
      handleContentChange(place)
      function viewMap() {
        let element = (
          <>
            <Gmap Lat={place.geometry?.location?.lat()} Lng={place.geometry?.location?.lng()} />
          </>
        )
        ReactDOM.render(element, document.getElementById('showmap'))
      }
      setTimeout(viewMap, 1000)
    },
    options: { types: ['geocode'] },
  })

  const handleContentChange = (data: any) => {
    const { address_components = [] } = data
    const components: any = {}
    address_components.forEach(({ types, short_name, long_name }: any) => {
      types.forEach((type: any) => {
        components[type] = ['route', 'administrative_area_level_1'].includes(type)
          ? short_name
          : long_name
      })
    })
    const {
      premise = '',
      street_number = '',
      route = '',
      postal_code = '',
      neighborhood = '',
      locality = '',
      administrative_area_level_1 = '',
    } = components
    const streetAddress = street_number
      ? `${street_number} ${route}`
      : route
      ? route
      : premise
      ? premise
      : ''
    handleAddressDataChange({
      name,
      value: {
        Address: streetAddress,
        City: neighborhood ? neighborhood : locality,
        State: administrative_area_level_1,
        ZipCode: postal_code,
        Lat: data.geometry.location.lat(),
        Lng: data.geometry.location.lng(),
      },
    })
    // handleAddressDataChange({ name, value: components })
  }

  const renderLocationButton = () => {
    return (
      <div>
        <small
          ref={btnRef}
          className={`input-field-button`}
          onClick={() => {
            getLocationData()
          }}
        >
          <CIcon name='cil-location-pin' />
        </small>
      </div>
    )
  }
  // console.log(address);

  const { Address, Address2, City, State, ZipCode } = address

  // const [modal, setModal] = React.useState(false);

  // const toggle = ()=>{
  //   setModal(!modal);
  // }

  // React.useEffect(()=> {
  //   console.log(modal);
  //   console.log(Lat);
  //   console.log(Lng);
  // })

  return (
    <>
      <CFormGroup row>
        <CCol xs='12'>
          <InputField
            innerRef={ref}
            name={`${name}Address`}
            label={name ? `${name} Address` : 'Address'}
            type='text'
            value={Address}
            onChange={handleChange}
            showButton
            ButtonComponent={renderLocationButton}
          />
        </CCol>
      </CFormGroup>
      <div id='showmap'></div>
      <br />
      <CFormGroup row>
        <CCol xs='12'>
          <InputField
            name={`${name}Address2`}
            type='text'
            label={name ? `${name} Address 2` : 'Address 2'}
            value={Address2}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol xs='12' lg='6'>
          <InputField
            name={`${name}City`}
            type='text'
            label={name ? `${name} City` : 'City'}
            value={City}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3' className='px-lg-0 my-3 my-lg-0'>
          <InputField
            name={`${name}State`}
            type='text'
            label={name ? `${name} State` : 'State'}
            value={State}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3'>
          <InputField
            name={`${name}ZipCode`}
            type='text'
            label={name ? `${name} Zipcode` : 'Zipcode'}
            value={ZipCode}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      {/*       
      <CButton
        onClick={toggle}
        className="mr-1"
      >Launch demo modal</CButton>
      <CModal
        show={modal}
        onClose={toggle}
      >
        <CModalHeader closeButton>Modal title</CModalHeader>
        <CModalBody>
          <Gmap modal={modal} Lat={Lat} Lng={Lng} />
        </CModalBody>
        <CModalFooter>
          <CButton color="primary">Do Something</CButton>{' '}
          <CButton
            color="secondary"
            onClick={toggle}
          >Cancel</CButton>
        </CModalFooter>
      </CModal> */}
    </>
  )
}

export default AddressMap
