import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CTabPane } from '@coreui/react'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { Loader } from 'src/shared'
import { CampaignHeader, CampaignInfo } from './components'
import { CAMPAIGN, PHONE_MODEL } from 'src/services/routeConst'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'
// import  MessageSearch  from '../Phones/Search'
import PhoneSearch from '../Phones/Search'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
// import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import DragDropFiles from '../../../shared/components/DragDropFiles'
import readXlsxFile from 'read-excel-file'
import LoadingButton from 'src/shared/components/LoadingButton'
import { Line } from 'rc-progress'
import { addAuthAccessTokenToHeader } from 'src/services/authHelper'
import Modal from 'react-modal'
const axios = require('axios').default
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}

const fields = [
  'Id',
  'Name',
  'Message',
  'SendOn',
  //'IsSent',
  //'MessageWithFields',
  //'FieldList',
  'ConfigId',
  // 'IsSent',
]

const CampaignDetails = ({ history, match, modelName = CAMPAIGN }) => {
  const [excelData, setExcelData] = useState([
    { Name: '', PhoneNo: '', IsSent: '', CampaignId: '' },
  ])
  const [columnName, setColumnName] = useState([])
  const [countData, setCountData] = useState(0)
  const [apiData, setApiData] = useState<any>({ modelData: null })
  const [status, setStatus] = useState<any>('loading')
  const [triggerSend, setTriggerSend] = useState(false)
  const [activeStep, setActiveStep] = useState(1)
  const changesRef = useRef<any>()
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [currentPhoneCount, setCurrentPhoneCount] = useState(0)

  const { id = 0 } = match.params

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function afterOpenModal() {}

  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback<any>(
    async (shouldUpdateRefs = true) => {
      try {
        // busco dato y no me lo devuelve me devuelve el  defaultMainData
        const { mainData } = await rootRepository.getData({
          id,
          modelName,
          defaultMainData: { Id: 0 },
        })
        const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
        setApiData({ modelData: dataResponse })
        setStatus('idle')
        id && shouldUpdateRefs && updateRefs()
      } catch (error) {
        setStatus('idle')
        console.error(error)
      }
    },
    [rootRepository, id, modelName],
  )

  const updateRefs = () => {
    changesRef?.current?.fetchNewData()
    //console.log(changesRef.current)
  }
  useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setApiData({ menuData: null })
        history.goBack()
        resolve(true)
      }, delay)
    })
  }

  const saveData = async () => {
    // try {
    //   const dataToSave: any = apiData.modelData
    //   var today = new Date();
    //   var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
    //   var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    //   var dateTime = date+' '+time;
    //   setStatus('saving')
    //   const { message, data } = await SaveModel({
    //     body: {
    //       Model: modelName,
    //       Params: Object.assign({}, ...fields.map((field) => ({ [field]: (field == "SendOn" ? dateTime : dataToSave[field]) }))),
    //     },
    //   })
    //   console.log("sdsdsd");
    //   console.log(data.SaveInfo.Id);
    //   for (let excelDataRow of excelData) {
    //     excelDataRow.CampaignId = data.SaveInfo.Id;
    //     savePhones(excelDataRow);
    //   }
    //   toast.success(message)
    //   updateRefs()
    //   setStatus('idle')
    //   await goBack(250)
    // } catch (error) {
    //   setStatus('idle')
    //   toast.error('Ups! The action could not be executed')
    //   console.error(error)
    // }
  }

  const saveFinalData = async () => {
    try {
      axios.defaults.headers.common = {
        Authorization: `bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9`,
      }
      var today = new Date()
      var date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
      var time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()
      var dateTime = date + ' ' + time
      let currPhoneCount = 0
      const dataToSave: any = apiData.modelData

      setStatus('saving')
      const { message, data } = await SaveModel({
        body: {
          Model: modelName,
          Params: Object.assign(
            {},
            ...fields.map((field) => ({
              [field]: field == 'SendOn' ? dateTime : dataToSave[field],
            })),
          ),
        },
      })
      //console.log(data.SaveInfo.Id);

      for (let excelDataRow of excelData) {
        // console.log("excelDataRow");
        // console.log(excelDataRow);
        let getNoCallResp = await checkIsNoCall(excelDataRow.PhoneNo)
        currPhoneCount++
        let saveItem = {}
        saveItem['CampaignId'] = data.SaveInfo.Id
       // saveItem['Name'] = excelDataRow.Name
        saveItem['PhoneNo'] = excelDataRow.PhoneNo
        saveItem['IsNoCall'] = getNoCallResp == 0 ? false : true
        saveItem['IsSent'] = getNoCallResp == 0 ? true : false

        let cMs = modelData.MessageWithFields
        let i = 1
        columnName.map((clm: any) => {
          if (clm !== 'Name' && clm !== 'PhoneNo') {
            //console.log(clm)
            let sts = 'Value' + i
            saveItem[sts] = excelDataRow[clm]
            cMs = cMs.replace('{' + clm + '}', excelDataRow[clm])
            i++
          }
        })
        saveItem['Message'] = modelData.Message
        let savedPhoneId = await savePhones(saveItem)
         console.log("savedPhoneId");
         console.log(savedPhoneId);
        // console.log();

        if (`${excelDataRow.PhoneNo}`.length == 10 && getNoCallResp == 0) {
          await pushMessage(savedPhoneId)
        }
        setCurrentPhoneCount(currPhoneCount)
      }
      toast.success(message)
      updateRefs()
      setStatus('idle')
      setTimeout(() => {
        closeModal()
      }, 1000)
      await goBack(250)
    } catch (error) {
      setStatus('idle')
      toast.error('Ups! The action could not be executed')
      console.error(error)
    }
  }

  const pushMessage = async (savedPhoneId) => {
    try {
      await axios.post(`https://api.softsmartinc.com/Services/vOld/Widget/Sms/Send`, {
        Id: savedPhoneId
      })
    } catch (error) {
      console.error(error)
    }
  }

  const checkIsNoCall = async (phone) => {
    try {
      const response = await axios.post(
        `https://api.softsmartinc.com/Services/vOld/Data/Execute`,
        {
          DbName: 'DbAdmin',
          QueryOrProcedureName: 'sms.NOCALL_Exists',
          Params: [
            {
              Name: 'PhoneNo',
              Value: phone,
            },
          ],
        },
        { ...addAuthAccessTokenToHeader() },
      )

      // console.log("response.data.Execution.Result");
      // console.log(response.data.data.Execution[0].Result);

      return response.data.data.Execution[0].Result ?? 0
    } catch (error) {
      console.error(error)
    }
  }

  const savePhones = async (excelDataRow) => {
    try {
      //const dataToSave: any = apiData.modelData

      setStatus('saving')

      const {data} = await SaveModel({
        body: {
          Model: PHONE_MODEL,
          Params: excelDataRow,
        },
      })

      console.log(data);
      return data.SaveInfo.Id;
      // toast.success(message)
      // updateRefs()
      // setStatus('idle')
      // await goBack(250)
    } catch (error) {
      setStatus('idle')
      toast.error('Ups! The action could not be executed')
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    //console.log(name)
    // if(name == 'MessageWithFields') {
    //   var found = [],          // an array to collect the strings that are found
    //   rxp = /{([^}]+)}/g,
    //   //str = "a {string} with {curly} braces",
    //   curMatch;

    //   while( curMatch = rxp.exec( value ) ) {
    //         found.push( curMatch[1] );
    //   }
    //   setApiData((st: any) => ({
    //     ...st,
    //     modelData: { ...st.modelData, ['FieldList']: found.toString() },
    //   }))
    // }

    //console.log( found );

    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }

  // const handleCheckBoxChange = (evt) => {

  //     setApiData((st: any) => ({
  //       ...st,
  //       modelData: { ...st.modelData, ['IsSent']: evt.target.checked },
  //     }))
  // }

  const sendMessages = () => {
    setTriggerSend((triggerSend) => !triggerSend)
  }

  if (!apiData.modelData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const nextPage = () => {
    if (activeStep === 1) {
      if (modelData.ConfigId && modelData.Name) {
        setActiveStep(2)
      }
    } else if (activeStep === 2) {
      if (countData > 0) {
        setActiveStep(3)
      }
    } else if (activeStep === 3) {
      setActiveStep(4)
    } else if (activeStep === 4) {
      openModal()
      saveFinalData()
    }
  }

  const prevPage = () => {
    if (activeStep === 1) {
      setActiveStep(1)
    } else if (activeStep === 2) {
      setActiveStep(1)
    } else if (activeStep === 3) {
      setActiveStep(2)
    } else if (activeStep === 4) {
      setActiveStep(3)
    }
  }

  const handleExcelChange = (selectorFiles: FileList) => {
    let r: any[] = []
    readXlsxFile(selectorFiles[0]).then((rows) => {
      // console.log(rows, modelData.FieldList)
      let arrayName = ['PhoneNo']
      //let arrayField = modelData.FieldList.split(',')
      const arrayData = arrayName //arrayName.concat(arrayField)
      const Phones = rows.slice(1).map((row: any) => {
        let item = {}
        row.map((r: any, k) => {
          if (arrayData[k]) {
            item[arrayData[k]] = r
          }
        })
        return item
      })
      r = Phones
      if (!Phones.length || !Phones[0]['PhoneNo']) {
        toast.error('Ups! You need to add PhoneNo column')
        return
      }
      setColumnName(arrayData)
      setCountData(r.length)
      setExcelData(r)
    })
  }

  const { modelData } = apiData
  return (
    <>
      {id !== 0 ? (
        <CCard style={{ border: 'none' }}>
          <CampaignHeader
            status={status}
            saveData={saveData}
            IsSent={apiData.modelData.IsSent}
            SendOn={apiData.modelData.SendOn}
            onCancel={history.goBack}
            sendMessages={sendMessages}
          />
          <CCardBody>
            <DetailTabSection
              id={id}
              hideNotes={true}
              modelName={modelName}
              tabs={[{ name: 'Details', label: 'Campaign Info' }]}
            >
              <CTabPane data-tab='Details' className='campaign-detail-tab-section-pane'>
                <CRow>
                  <CCol xs='12' md='4'>
                    <CampaignInfo
                      modelData={modelData}
                      handleChange={handleChange} /**handleCheckBoxChange={handleCheckBoxChange}*/
                    />
                  </CCol>
                  <CCol xs='12' md='8'>
                    {/* <MessageSearch /> */}
                    <PhoneSearch
                      setExcelData={setExcelData}
                      modelData={modelData}
                      triggerSend={triggerSend}
                      apiData={apiData}
                    />
                  </CCol>

                  {/* {id ? (
              <CCol xs='12' md='6'>
                <DetailTabSection
                  id={id}
                  Guid={modelData.Guid}
                  modelName={modelName}
                  changesRef={changesRef}
                />
              </CCol>
            ) : null} */}
                </CRow>
              </CTabPane>
            </DetailTabSection>
          </CCardBody>
        </CCard>
      ) : (
        <CCard style={{ border: 'none' }}>
          <CCardHeader
            className='d-flex align-items-center'
            style={{
              backgroundColor: '#2A3446',
              marginBottom: '1rem',
              fontSize: 16,
              fontWeight: 600,
              borderRadius: 0,
            }}
          >
            <span style={{ color: 'white' }}>Campaign Details</span>
            <CButton
              className='mr-2 ml-auto'
              type='button'
              color='secondary'
              style={{ float: 'right' }}
              onClick={history.goBack}
            >
              Close
            </CButton>
          </CCardHeader>
          <CCardBody>
            <div id='container'>
              <div className='step-wizard' role='navigation'>
                <div className='cprogress'>
                  <div className='progressbar empty'></div>
                  <div id='prog' className='progressbar'></div>
                </div>
                <ul>
                  <li className={activeStep === 1 ? 'active' : activeStep > 1 ? 'done' : ''}>
                    <button className='csButton' id='step1'>
                      <div className='step'>1</div>
                      <div className='title'>Campaign Information</div>
                    </button>
                  </li>
                  <li className={activeStep === 2 ? 'active' : activeStep > 2 ? 'done' : ''}>
                    <button className='csButton' id='step2'>
                      <div className='step'>2</div>
                      <div className='title'>Upload File</div>
                    </button>
                  </li>
                  <li className={activeStep === 3 ? 'active' : activeStep > 3 ? 'done' : ''}>
                    <button className='csButton' id='step3'>
                      <div className='step'>3</div>
                      <div className='title'>Proccessing Data</div>
                    </button>
                  </li>
                  <li className={activeStep === 4 ? 'active' : activeStep > 4 ? 'done' : ''}>
                    <button className='csButton' id='step4'>
                      <div className='step'>4</div>
                      <div className='title'>Campaing Summary</div>
                    </button>
                  </li>
                </ul>
              </div>
              <div className='wzContect'>
                {activeStep === 1 ? (
                  <CRow>
                    <CCol xs='12' md='12'>
                      <CampaignInfo
                        modelData={modelData}
                        handleChange={handleChange} /**handleCheckBoxChange={handleCheckBoxChange}*/
                      />
                    </CCol>
                  </CRow>
                ) : null}
                {activeStep === 2 ? (
                  <div>
                    <DragDropFiles
                      handleExcelChange={handleExcelChange}
                      modelData={[]}
                    ></DragDropFiles>
                    {countData > 0 ? (
                      <div style={{ fontSize: 20 }}>{countData} Records Uploaded</div>
                    ) : null}
                  </div>
                ) : null}
                {activeStep === 3 ? (
                  <table className='table table-bordered table-striped'>
                    <thead>
                      <tr>
                        {columnName.map((clm: any, k: any) => {
                          return (
                            <th className='py-1' key={k}>
                              {clm}
                            </th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {excelData.map((itm: any, kc: any) => {
                        return (
                          <tr key={kc}>
                            {columnName.map((clm: any, kkc: any) => {
                              return (
                                <td className='py-1' key={kkc}>
                                  {itm[clm]}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                ) : null}
                {activeStep === 4 ? (
                  <div style={{ textAlign: 'left', margin: '0 auto', maxWidth: '400px' }}>
                    <div>Name: {modelData.Name}</div>
                    <div>Message: {modelData.Message}</div>
                    {/* <div>FieldList: {modelData.FieldList}</div> */}
                    <div>You are going to display</div>
                    <div>{countData} Records to Be Sent.</div>
                  </div>
                ) : null}
              </div>
              <div className='buttons'>
                <button onClick={prevPage} className='btn btn-secondary btn-md mr-2' id='prev'>
                  Back
                </button>
                <LoadingButton
                  className='btn btn-primary btn-md'
                  loading={status === 'saving'}
                  onClick={nextPage}
                >
                  {activeStep == 4 ? 'Send Now' : 'Next'}
                </LoadingButton>
              </div>
            </div>

            <Modal
              isOpen={modalIsOpen}
              onAfterOpen={afterOpenModal}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel='Icon Modal'
            >
              {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
              {/* <button onClick={closeModal}>close</button> */}
              <div className='smsProgressModal'>
                <CRow>
                  <CCol>
                    <Line
                      percent={currentPhoneCount * 10}
                      strokeWidth={4}
                      trailWidth={4}
                      trailColor='#D9D9D9'
                      strokeColor='#22e081'
                    />
                    <p>
                      Sending {currentPhoneCount} of {countData}
                    </p>
                  </CCol>
                </CRow>
              </div>
            </Modal>
          </CCardBody>
        </CCard>
      )}{' '}
    </>
  )
}

export default CampaignDetails
