import React, { useContext } from 'react'
import { CButton, CCol, CFormGroup, CTabPane } from '@coreui/react'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import { CustomerContext } from '../Detail'
import {
  InspectorInfo,
  PickUpInfo,
  DropOffInfo,
  // TitleInfo,
  // VehicleInf,
  AppointmentInfo,
  VehicleCustomerInfo
} from '../components'
import {
  AccidentHistoryInfo,
  ElectricalInfo,
  MechanicalIssuesInfo,
  ModificationsInfo,
  VehicleConditionsInfo,
  VehicleInfo,
  CustomerInfo,
  ComplianceInfo,
  TitleInfo,
  HistoryInfo,
  PaymentInfo,
  DeductionInfo,
  ApptPickupInfo,
  ApptTimeSlotInfo,
  DnServiceInfo,
  NotesInfo,
} from '../components/CustomersDispatches'
// import {
//   AccidentHistoryInfo,
//   ElectricalInfo,
//   MechanicalIssuesInfo,
//   ModificationsInfo,
//   VehicleConditionsInfo,
//   VehicleInfo,
// } from '../components/VehiclesServices'
// import OwnershipInfo from './OwnershipInfo'
import CustomerServicesInfo from './CustomerServicesInfo'
import CustomerFiles from './Files/Search'
// import { InvoiceServicesInfo } from './InvoicesServices'
// import { CostServicesInfo } from './CostsServices'
// import CustomerServiceTable from './CustomerService/CustomerServiceTable'
// import WorkFlow from 'src/pages/Workflow/Workflow/Search'
// import Breadcrumb from 'src/pages/Workflow/Workflow/components/Bread'
interface PropsCustomersServices {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  modelData?: any
  modelDataVehicle?: any
  modelDataDispatch?: any
  Guid?: any
  handleChange?: any
  handleChangeMaxLength?: any
  handleAddressChange?: any
}
const CustomersServices: React.FC<PropsCustomersServices> = ({
  id,
  modelName,
  changesRef,
  Guid,
  modelData,
  // modelDataVehicle,
  modelDataDispatch,
  handleChange,
  handleChangeMaxLength,
  handleAddressChange,
}) => {
  const { MetasId } = useContext(CustomerContext)
  const { SourceName } = modelData
  // const actionClick = () => {}
  
  return (
    <>
      {/* <WorkFlow customerId={modelData.Id} /> */}
      <DetailTabSection
        id={id}
        metasId={MetasId}
        Guio={Guid}
        modelName={modelName}
        hideNotes
        tabs={[
          { name: 'CustomersDetails', label: 'Customers' },
          { name: 'DispatchesDetails', label: `${SourceName || ''} Details` },
          // { name: 'VehiclesDetails', label: 'Vehicles ' },
          { name: 'CustomersServices', label: 'Services' },
          // { name: 'CustomersFiles', label: 'Files' },
        ]}
        changesRef={changesRef}
      >
        <CTabPane data-tab='CustomersDetails' className='detail-tab-section-pane'>
          <CFormGroup row className={'m-0'}>
            <CCol md='8' className='pr-lg-1 p-0'>
              <InspectorInfo
                title={'Details'}
                modelData={modelData}
                handleChange={handleChange}
                handleAddressChange={handleAddressChange}
              />
            </CCol>
            <CCol md='4' className='pl-lg-1 p-0'>
              <AppointmentInfo
                title={'Appointment'}
                modelData={modelData}
                handleChange={handleChange}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row className={'m-0'}>
            <CCol md='6' className='pr-lg-1 p-0'>
              <PickUpInfo
                title={'Pick up'}
                modelData={modelData}
                handleChange={handleChange}
                handleAddressChange={handleAddressChange}
              />
            </CCol>
            <CCol md='6' className='pl-lg-1 p-0'>
              <DropOffInfo
                title={'Drop off'}
                modelData={modelData}
                handleChange={handleChange}
                handleAddressChange={handleAddressChange}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row className={'m-0'}>
            <CCol md='12' className='pr-lg-1 p-0'>
              <VehicleCustomerInfo
                title={'Vehicle'}
                modelData={modelData}
                handleChangeMaxLength={handleChangeMaxLength}
                handleChange={handleChange}
                handleAddressChange={handleAddressChange}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row className={'m-0'}>
            <CCol md='12' className='pr-lg-1 p-0'>
              <CButton
                style={{width: '100%'}}
                    color='primary'
                    width={'100%'}
                    onClick={() => {
                      window.open(`${modelData?.InspectorPhotoToolUrl ?? ''}`)
                    }}
                  >
                {' '}
                Inspector Photo Tool Url
              </CButton>
            </CCol>
          </CFormGroup>
          {/* <OwnershipInfo title={'Ownership Info'} modelData={modelData} handleChange={handleChange} /> */}
          {/* <TitleInfo title={'Title Info'} modelData={modelData} handleChange={handleChange} /> */}
        </CTabPane>
        {/* <CTabPane data-tab='VehiclesDetails' className='detail-tab-section-pane'>
      <CFormGroup row>
        <CCol>
          <VehicleInfo
            title={'Vehicle info'}
            modelData={modelDataVehicle}
            handleChange={handleChange}
          />
        </CCol>
        <CCol>
          <TitleInfo title={'Title Info'} modelData={modelData} handleChange={handleChange} />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <OwnershipInfo title={'Ownership Info'} modelData={modelData} handleChange={handleChange} />
        </CCol>
        <CCol>
          <AccidentHistoryInfo
            title={'Accident History'}
            modelData={modelDataVehicle}
            handleChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <VehicleConditionsInfo
            title={'General Vehicle Conditions'}
            modelData={modelDataVehicle}
            handleChange={handleChange}
          />
        </CCol>
        <CCol>
          <MechanicalIssuesInfo
            title={'Mechanical Issues'}
            modelData={modelDataVehicle}
            handleChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <ModificationsInfo title={'Modifications Info'} modelData={modelData} handleChange={handleChange} />
        </CCol>
        <CCol>
          <ElectricalInfo title={'Electrical Info'} modelData={modelData} handleChange={handleChange} />
        </CCol>
      </CFormGroup>
      </CTabPane> */}
        <CTabPane data-tab='DispatchesDetails' className='detail-tab-section-pane'>
        { modelData['SourceName'] === 'Quantum Manual'
            ? (<>
            <CFormGroup row>
              <CCol>
                {modelData['SourceDetails']}
              </CCol>
            </CFormGroup>
              </>)
            : (<><CFormGroup row>
              <CCol className='pr-lg-1'>
                <VehicleInfo
                  title={'Vehicle info'}
                  modelData={modelDataDispatch}
                  handleChange={handleChange} />
              </CCol>
              <CCol className='pl-lg-1'>
                <AccidentHistoryInfo
                  title={'Accident History'}
                  modelData={modelDataDispatch}
                  handleChange={handleChange} />
              </CCol>
            </CFormGroup><CFormGroup row>
                <CCol className='pr-lg-1'>
                  <VehicleConditionsInfo
                    title={'General Vehicle Conditions'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
                <CCol className='pl-lg-1'>
                  <MechanicalIssuesInfo
                    title={'Mechanical Issues'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
              </CFormGroup><CFormGroup row>
                <CCol className='pr-lg-1'>
                  <ModificationsInfo
                    title={'Modifications Info'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
                <CCol className='pl-lg-1'>
                  <ElectricalInfo
                    title={'Electrical Info'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
              </CFormGroup><CFormGroup row>
                <CCol className='pr-lg-1'>
                  <CustomerInfo
                    title={'Customer'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
                <CCol className='pl-lg-1'>
                  <ComplianceInfo
                    title={'Compliance Info'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
              </CFormGroup><CFormGroup row>
                <CCol className='pr-lg-1'>
                  <TitleInfo
                    title={'Title Info'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
                <CCol className='pl-lg-1'>
                  <HistoryInfo
                    title={'History Info'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
              </CFormGroup><CFormGroup row>
                <CCol className='pr-lg-1'>
                  <PaymentInfo
                    title={'Payment Info'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
                <CCol className='pl-lg-1'>
                  <DeductionInfo
                    title={'Deduction Info'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
              </CFormGroup><CFormGroup row>
                <CCol className='pr-lg-1'>
                  <ApptPickupInfo
                    title={'Appt Pickup Info'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
                <CCol className='pl-lg-1'>
                  <ApptTimeSlotInfo
                    title={'Appt Time Slot Info'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
              </CFormGroup><CFormGroup row>
                <CCol className='pr-lg-1'>
                  <DnServiceInfo
                    title={'Dn Service Info'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>
                <CCol className='pl-lg-1'>
                  <NotesInfo
                    title={'Notes Info'}
                    modelData={modelDataDispatch}
                    handleChange={handleChange} />
                </CCol>

              </CFormGroup></>)}
        </CTabPane>
        <CTabPane data-tab='CustomersServices' className='detail-tab-section-pane'>
          <CFormGroup row>
            <CCol md='12' className='pr-lg-1 pl-lg-0'>
              <CustomerServicesInfo modelData={modelData} />
            </CCol>
            {/* <CCol md='8' className='pr-lg-1 pl-lg-0'>
            <CustomerServiceTable modelData={modelData} refreshCustomerService={refreshCustomerService} setRefreshCustomerService={setRefreshCustomerService} />
          </CCol> */}
            {/* <CCol md='4' className='pr-lg-1 pl-lg-0'>
            <InvoiceServicesInfo title={'Invoice Transaction Items'} modelData={modelData} handleChange={handleChange} />
          </CCol>
          <CCol md='4' className='pr-lg-0 pl-lg-0'>
            <CostServicesInfo title={'Costs Associated with Deal'} modelData={modelData} handleChange={handleChange} />
          </CCol> */}
          </CFormGroup>
        </CTabPane>
        <CTabPane data-tab='CustomersFiles' className='detail-tab-section-pane'>
          <CustomerFiles metasId={MetasId} />
        </CTabPane>
      </DetailTabSection>
    </>
  )
}

export default CustomersServices
