// Generated with util/create-search-page.js
export const PushTableColumns = [
  {
    label: 'Subject',
    name: 'Subject',
  },
  {
    label: 'Email To',
    name: 'EmailTo',
  },
  {
    label: 'Email From',
    name: 'EmailFrom',
  },
  {
    label: 'Execution Time',
    name: 'ExecutionTime',
  },
  {
    label: 'IsError',
    name: 'IsError',
    type: 'boolCheckbox',
    alignment: 'center',
  },

  /*  {
    label: 'TokensIdRecipient',
    name: 'TokensIdRecipient',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'DeviceId',
    name: 'DeviceId',
    dbType: 'varchar',
  },
  {
    label: 'Notification',
    name: 'Notification',
    dbType: 'varchar',
  },
  {
    label: 'StampStart',
    name: 'StampStart',
    dbType: 'datetime',
  },
  {
    label: 'StampEnd',
    name: 'StampEnd',
    dbType: 'datetime',
  },
  {
    label: 'Executiontime',
    name: 'Executiontime',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'IsError',
    name: 'IsError',
    dbType: 'bit',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'ErrorsId',
    name: 'ErrorsId',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  }, */
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]
