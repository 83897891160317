import { combineReducers } from 'redux'
import sidebarReducer from './SidebarReducer'
import authReducer from './authReducer'
import IconReducer from './IconReducer'

export default combineReducers({
  sidebar: sidebarReducer,
  auth: authReducer,
  icon: IconReducer,
})
