// Generated with util/create-search-page.js
import { CButton } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { CustomerServicesTableColumns } from './CustomerServicesTableColumns'
import { BASE_API, CUSTOMERS_SERVICES_MODEL, MODELS, SERVICES, LIST } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'
import DetailModal from './DetailModal'

const ImagesTab = ({ modelName = CUSTOMERS_SERVICES_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)
  const [showModal, setShowModal] = useState<any>(false)
  const tableRef = useRef<any>()
  const [idReview, setId] = useState(0)
  const viewDetails = ({ id }) => {
    setId(id)
    setShowModal(true)
  }

  const newCustomerServices = () => {
    setId(0)
    setShowModal(true)
  }

  const showDeleteModalFunc = ({ id }) => {
    setId(id)
    setShowDeleteModal(true)
  }

  const actionFunctions = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <>
      <CButton
        className='mr-1 ml-auto'
        type='button'
        size='sm'
        color='primary'
        style={{ float: 'right' }}
        onClick={newCustomerServices}
      >
        New Customers Services
      </CButton>
      <br />
      <DataTable
        columnData={CustomerServicesTableColumns}
        theme='dark'
        noHeader
        endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
        modelName={modelName}
        actionClick={(type, data) => actionFunctions[type]({ id: data })}
        ref={tableRef}
        paginationPosition='bottom-right'
        apiKey={getAuthToken('ADMIN')}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Customer services detail', id: idReview }}
        callBack={() => tableRef.current.fetchNewData()}
      />
      <DetailModal
        id={idReview}
        showModal={showModal}
        setShowModal={setShowModal}
        refTable={tableRef}
      />
    </>
  )
}

export default ImagesTab
