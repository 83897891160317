import React from 'react'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { toast } from 'react-toastify'
import { MembersRepository } from '../../../../src/repository/admin/member/MembersRepository'
import { sendOTP } from '../../../../src/services/modelNew/OTP/Send'
import { DeleteModal, Loader } from '../../../../src/shared'
import {
  MemberDetailsHeader,
  MemberInfo,
  // MemberChangePassword,
  VerifyMemberEmailModal,
  VerifyMemberPhoneModal,
  MemberDetailTabSection,
  // MemberSettings,
  // MemberAddress,
  EditMemberPaymentAccountsModal,
  EditMemberTransactionsModal,
  EditMemberStatementsModal,
  // MemberStats,
  NewSubAccountModal,
  EditSubAccountCreditAmountModal,
  EditMemberSettingsModal,
  // UserProfilePicture,
  EditMemberStatsModal,
  EditMemberCardsModal,
  EditMemberExperianModal,
  EditMemberSharedModal,
  // MemberDetailsSection,
} from './components'
import {
  MEMBERS_MODEL,
  BANKS_MODEL,
  STATEMENTS_MODEL,
  TRANSACTIONS_MODEL,
  SETTINGS_MODEL,
  STATS_MODEL,
  CARDS_MODEL,
  EXPERIAN_MODEL,
  SHARED_MODEL,
  CONTACT_MODEL,
  SOCIALS_MODEL,
  OTP_MODEL,
  BASE_API,
  TOKENS,
} from '../../../../src/services/routeConst'
import { ComboModel } from '../../../../src/services/modelNew/Combo/Combo'
// import { routeToPage } from 'src/services/routingHelper'
import EditMemberContactsModal from './components/EditMeemberContactModal'
import EditMemberSocialsModal from './components/EditMemberSocials'
import EditMemberOtpModal from './components/EditMemberOtpModal'
import { CallApi } from '../../../../src/services/modelNew/Api/CallApi'
// import MemberLoginAccess from './components/MemberLoginAccess'
import CustomerVehicleInfo from './components/CustomerVehicleInfo'
import CustomerTripInfo from './components/CustomerTripInfo'
// import { ModelsDetailTabSection } from '../Services/components'
enum Status {
  loading = 'loading',
  idle = 'idle',
  saving = 'saving',
}
const MemberDetails: React.FC<any> = ({ history, match, modelName = MEMBERS_MODEL }) => {
  const [apiData, setApiData] = React.useState<any>({ memberData: null, allMembersData: [] })
  const [status, setStatus] = React.useState<Status>(Status.loading)
  const [showDeleteModal, setShowDeleteModal] = React.useState<any>(false)
  const [showEditModal, setShowEditModal] = React.useState<any>(false)
  const [showVerifyEmailModal, setShowVerifyEmailModal] = React.useState<any>(false)
  const [showVerifyPhoneModal, setShowVerifyPhoneModal] = React.useState<any>(false)
  const [sendingOTP, setSendingOTP] = React.useState<any>(null)
  const clickedIDRef = React.useRef<any>(-1)
  // const tableRef = React.useRef<any>()
  const modalDataRef = React.useRef<any>({})
  const loginHistoryTableRef = React.useRef<any>()
  const paymentAccountsTableRef = React.useRef<any>()
  const transactionsTableRef = React.useRef<any>()
  const statementsTableRef = React.useRef<any>()
  const settingsTableRef = React.useRef<any>()
  const socialsTableRef = React.useRef<any>()
  const otpTableRef = React.useRef<any>()
  const statsTableRef = React.useRef<any>()
  const cardsTableRef = React.useRef<any>()
  const experianTableRef = React.useRef<any>()
  const sharedSenderTableRef = React.useRef<any>()
  const sharedReceiverTableRef = React.useRef<any>()
  const contactTableRef = React.useRef<any>()
  const changesRef = React.useRef<any>()
  const subAccountsTableRef = React.useRef<any>()
  const { id = 0 } = match.params
  const fields = {
    base: ['UsersId', 'Email', 'Password', 'FirstName', 'LastName', 'Phone', 'IsActive'],
    extra: ['IsNew', 'Nickname', 'CodePrimary', 'AvatarUrl', 'DeviceType', 'DeviceId'],
    address: ['Address', 'Address2', 'City', 'State', 'ZipCode'],
    vehicles: ['ASP', 'VIN', 'Miles', 'Year', 'Make', 'Model'],
    trip: ['PickUpLocation', 'ScheduledTime', 'DropOffLocation', 'LatestDropOffTime']
  }

  const allFields = [...fields.base, ...fields.extra, ...fields.address]

  const membersRepository = React.useMemo(() => new MembersRepository(), [])

  const getData = React.useCallback(
    async (shouldUpdateRefs = true) => {
      try {
        const { data: allMembersData } = await ComboModel({ modelName: MEMBERS_MODEL })
        const { mainData } = await membersRepository.getData({
          id,
          modelName,
          defaultMainData: { UsersId: 0 },
        })
        const { data: passwordData = {} } = id
          ? await CallApi({
              path: `${BASE_API}:${TOKENS}/Member/PasswordRetrieve`,
              params: { MembersId: id },
            })
          : {}
        setApiData({
          memberData: {
            ...mainData,
            originalPassword: passwordData?.password ?? '',
            Password: passwordData?.password ?? '',
            confirmPassword: passwordData?.password ?? '',
            profile_picture: '/avatars/0.png',
          },
          allMembersData,
        })
        setStatus(Status.idle)
        id && shouldUpdateRefs && updateRefs()
      } catch (error: any) {
        setStatus(Status.idle)
        console.error(error)
      }
    },
    [id, membersRepository, modelName],
  )

  const updateRefs = () => {
    loginHistoryTableRef?.current?.fetchNewData()
    paymentAccountsTableRef?.current?.fetchNewData()
    transactionsTableRef?.current?.fetchNewData()
    statementsTableRef?.current?.fetchNewData()
    // changesRef?.current?.fetchNewData()
    subAccountsTableRef?.current?.fetchNewData()
    settingsTableRef?.current?.fetchNewData()
    socialsTableRef?.current?.fetchNewData()
    statsTableRef?.current?.fetchNewData()
    cardsTableRef?.current?.fetchNewData()
    experianTableRef?.current?.fetchNewData()
    sharedSenderTableRef?.current?.fetchNewData()
    sharedReceiverTableRef?.current?.fetchNewData()
    otpTableRef?.current?.fetchNewData()
    contactTableRef?.current?.fetchNewData()
  }

  React.useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ memberData: null })
      history.goBack()
    }, delay)
  }

  const saveData = async ({ recordId = id }) => {
    const dataToSave: any = apiData.memberData
    const saveFields = recordId === 0 ? fields.base : allFields
    if (recordId !== id) {
      dataToSave.UsersId = recordId
    }
    status !== Status.saving && setStatus(Status.saving)
    try {
      const { data, message } = await CallApi({
        path: `${BASE_API}:${TOKENS}/Member/${recordId === 0 ? 'Create' : 'Update'}`,
        tokenType: 'APPLICATION',
        params: {
          ...Object.assign(
            {},
            ...saveFields.map((field) => ({
              [field === 'UsersId' ? 'MembersId' : field]: dataToSave[field],
            })),
          ),
        },
      })
      !!data && toast.success(message)
      Number(dataToSave.UsersId) ? getData() : goBack(1000)
      setStatus(Status.idle)
    } catch (err: any) {
      toast.error(
        'Member failed to update. Reason: ' + (err.error?.data?.data?.message ?? err.message),
      )
      setStatus(Status.idle)
      console.error(err)
    }
  }

  const handleChange = ({ name = '', value = '' }: { name: any; value: any }) => {
    name && setApiData((st: any) => ({ ...st, memberData: { ...st.memberData, [name]: value } }))

    setTimeout(() => {
      console.log(apiData, name, value)
    }, 250)
  }

  /*  const handleAddressChange = ({
    Address = '',
    Address2 = '',
    City = '',
    State = '',
    ZipCode = '',
  }) => {
    // console.log({ Address, Address2, City, State, ZipCode })
    setApiData((st: any) => ({
      ...st,
      memberData: { ...st.memberData, Address, Address2, City, State, ZipCode },
    }))
  } */

  const handlePhoneChange = ({ name = '', value = '' }) => {
    setApiData((st: any) => ({
      ...st,
      memberData: { ...st.memberData, [name]: value.replace(/[^0-9]/g, '') },
    }))
  }

  // const handleSwitchChange = (name: any, value: any) => {
  //   setApiData((st: any) => ({
  //     ...st,
  //     memberData: {
  //       ...st.memberData,
  //       [name]: value,
  //     },
  //   }))
  // }

  const handleComboChange = (e: any, newValue: any = {}) => {
    if (!e.target) return
    const CodeId = newValue ? newValue.CodeId : null
    setApiData((st: any) => ({ ...st, memberData: { ...st.memberData, UsersId_Parent: CodeId } }))
  }

  const showVerifyOTPModalFunc = async ({ type = 'Email', sendTo = memberData.Email }) => {
    if (sendingOTP) return
    setSendingOTP(type)
    try {
      await sendOTP({ type, sendTo: sendTo, MemberId: id })
      type === 'Email' ? setShowVerifyEmailModal(true) : setShowVerifyPhoneModal(true)
    } catch (error: any) {
      toast.error(error.message)
    }
    setSendingOTP(null)
  }

  // const viewDetails = ({ id }) => {
  //   routeToPage(history, `/Admin/Users/Detail/${id}`)
  // }

  const showDeleteModalFunc = ({ id, modelName = '', label = '' }: any) => {
    clickedIDRef.current = id
    modalDataRef.current = {
      modelName: modelName,
      label: label,
      id: id,
    }
    setShowDeleteModal(true)
  }

  const showEditModalFunc = ({ id, modelName = '', label = '' }: any) => {
    clickedIDRef.current = id
    modalDataRef.current = {
      modelName: modelName,
      label: label,
      UsersId: memberData.UsersId,
      id: id,
    }
    setShowEditModal(modelName)
  }

  const viewDetails = ({ id: viewID }: any) => {
    console.log('VIEW DETAIL')
    setApiData({ memberData: null })

    // routeToPage(history, `/Admin/Members/${viewID ? `Detail/${viewID}` : `New`}`)
    window.open(`/Admin/Members/${viewID ? `Detail/${viewID}` : `New`}`, '_self')
  }

  const actionFunctions: any = {
    edit_credit_amount: showEditModalFunc,
    edit: showEditModalFunc,
    view: showEditModalFunc,
    delete: showDeleteModalFunc,
  }
  // console.log('API DATA', apiData)
  /*  const updateProfilePictureID = async (FilesId: any) => {
    // console.log('FILES ID', FilesId, 'MEMBERID', apiData.memberData.UsersId)
    const dataToSave = { UsersId: apiData.memberData.UsersId, FilesId_Avatar: FilesId }
    try {
      await membersRepository.saveData({
        modelName,
        idName: 'UsersId',
        dataToSave,
        getData,
        goBack,
      })
    } catch (error: any) {
      console.error(error)
    }
  } */
  // console.log(apiData.memberData, status, showEditModal)
  if (!apiData.memberData || status === Status.loading) {
    return <Loader fullScreen height='75vh' />
  }

  const { memberData, allMembersData } = apiData
  return (
    <>
      <CCard>
        <MemberDetailsHeader
          status={status}
          saveData={saveData}
          goBack={() => {
            setApiData({ memberData: null })
            history.goBack()
          }}
        />
        <CCardBody>
          <CRow>
            <CCol xs='12' md='6'>
              <MemberInfo
                id={id}
                memberData={memberData}
                allMembersData={allMembersData}
                handleChange={handleChange}
                handleComboChange={handleComboChange}
                handlePhoneChange={handlePhoneChange}
                showVerifyOTPModalFunc={showVerifyOTPModalFunc}
                sendingOTP={sendingOTP}
                showVerifyPhoneModal={showVerifyPhoneModal}
                showVerifyEmailModal={showVerifyEmailModal}
              />
              <CustomerVehicleInfo
                id={id}
                memberData={memberData}
                allMembersData={allMembersData}
                handleChange={handleChange}
                handleComboChange={handleComboChange}
                // handlePhoneChange={handlePhoneChange}
                showVerifyOTPModalFunc={showVerifyOTPModalFunc}
                sendingOTP={sendingOTP}
                showVerifyPhoneModal={showVerifyPhoneModal}
                // showVerifyEmailModal={showVerifyEmailModal}
              />
              <CustomerTripInfo
                id={id}
                memberData={memberData}
                allMembersData={allMembersData}
                handleChange={handleChange}
                handleComboChange={handleComboChange}
                // handlePhoneChange={handlePhoneChange}
                showVerifyOTPModalFunc={showVerifyOTPModalFunc}
                sendingOTP={sendingOTP}
                showVerifyPhoneModal={showVerifyPhoneModal}
                // showVerifyEmailModal={showVerifyEmailModal}
              />
            </CCol>
            {id ? (
              <CCol xs='12' md='6'>
                <MemberDetailTabSection
                  id={id}
                  modelName={modelName}
                  actionFunctions={actionFunctions}
                  loginHistoryTableRef={loginHistoryTableRef}
                  paymentAccountsTableRef={paymentAccountsTableRef}
                  transactionsTableRef={transactionsTableRef}
                  statementsTableRef={statementsTableRef}
                  settingsTableRef={settingsTableRef}
                  socialsTableRef={socialsTableRef}
                  statsTableRef={statsTableRef}
                  otpTableRef={otpTableRef}
                  cardsTableRef={cardsTableRef}
                  experianTableRef={experianTableRef}
                  sharedSenderTableRef={sharedSenderTableRef}
                  changesRef={changesRef}
                  subAccountsTableRef={subAccountsTableRef}
                  memberData={memberData}
                  sharedReceiverTableRef={sharedReceiverTableRef}
                  showEditModalFunc={showEditModalFunc}
                  viewDetails={viewDetails}
                  contactTableRef={contactTableRef}
                />
              </CCol>
            ) : null}
          </CRow>
        </CCardBody>
      </CCard>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditMemberOtpModal
        showModal={showEditModal === OTP_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditMemberSocialsModal
        showModal={showEditModal === SOCIALS_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditMemberPaymentAccountsModal
        showModal={showEditModal === BANKS_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditMemberTransactionsModal
        showModal={showEditModal === TRANSACTIONS_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditMemberStatementsModal
        showModal={showEditModal === STATEMENTS_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <NewSubAccountModal
        showModal={showEditModal === MEMBERS_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditSubAccountCreditAmountModal
        showModal={showEditModal === 'CreditAmount'}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditMemberSettingsModal
        showModal={showEditModal === SETTINGS_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditMemberStatsModal
        showModal={showEditModal === STATS_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditMemberCardsModal
        showModal={showEditModal === CARDS_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditMemberExperianModal
        showModal={showEditModal === EXPERIAN_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditMemberSharedModal
        showModal={showEditModal === SHARED_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <EditMemberContactsModal
        showModal={showEditModal === CONTACT_MODEL}
        setShowModal={setShowEditModal}
        modalData={modalDataRef.current}
        callBack={updateRefs}
      />
      <VerifyMemberEmailModal
        email={memberData.Email}
        showModal={showVerifyEmailModal}
        setShowModal={setShowVerifyEmailModal}
        MemberId={id}
      />
      <VerifyMemberPhoneModal
        phone={memberData.Phone}
        showModal={showVerifyPhoneModal}
        setShowModal={setShowVerifyPhoneModal}
        MemberId={id}
      />
    </>
  )
}

export default MemberDetails
