// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const ApptTimeSlotInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const { 
    ApptTimeSlotStartTime,
    ApptTimeSlotStartDate,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            label={'Start Time'}
            value={ApptTimeSlotStartTime ?? '⠀'}
          />
          <InputLabel 
            label={'Start Date'}
            value={ApptTimeSlotStartDate ?? '⠀'}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default ApptTimeSlotInfo
