import classes from './VitalLogo.module.css'

const VitalLogo = ({
  width = 50,
  height = 50,
}: {
  width?: string | number
  height?: string | number
}) => {
  return (
    <div style={{ width, height }} className={classes.ldsRoller}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}

export default VitalLogo
