// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { RadioGroup, Radio } from 'react-radio-input'
import { InputField } from 'src/shared'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange: any
  title?: string
}
const RadioGroupTs: any = RadioGroup
const InspectorInfo = ({ modelData, handleChange }: PropsInfoForm) => {
  const { FirstName, LastName, PhoneMobile, HourlyRate, EinSsn, Email } = modelData
  // const mapComboToData = () =>

  return (
    <>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='FirstName'
            type='text'
            label='First Name'
            value={FirstName ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='6' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='LastName'
            type='text'
            label='Last Name'
            value={LastName ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='PhoneMobile'
            type='phone'
            label='Phone Mobile'
            value={PhoneMobile ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='Email'
            type='email'
            label='Email'
            value={Email ?? ''}
            onChange={handleChange}
          />

          {/* <ComboBox
            showButton
            name='RegionsId'
            label='Regions'
            data={mapComboToData()}
            value={AllRegionsData.find((item: any) => item.Id === modelData.RegionsId)}
            handleChange={(...params) => {
              const [, newValue] = params
              handleChange({ name: 'RegionsId', value: newValue?.CodeId ?? null })
            }}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>
                <CIcon name={option.Icon} className='mr-2' /> {option.Description}
              </div>
            )}
          /> */}
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='HourlyRate'
            type='currency'
            decimals={2}
            showSeparator
            id='MaxRateNo'
            label='Hourly Rate'
            value={HourlyRate ?? ''}
            alignment='right'
            onChange={({ name, value }) => {
              handleChange({
                name,
                value: value.replaceAll('$', ''),
              })
              // const valueNumber = value
              //   .replaceAll('#', '')
              //   .replaceAll('-', '')
              //   .replaceAll('$', '')
              //   .replaceAll(',', '')
              // handleChange({
              //   name,
              //   value: valueNumber,
              // })
            }}
          />
        </CCol>
        <CCol md='6' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='EinSsn'
            type='mask'
            label='EIN/SSN'
            value={EinSsn ?? ''}
            mask={[
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              '-',
              /[0-9]/,
              /[0-9]/,
              '-',
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
              /[0-9]/,
            ]}
            onChange={({ name, value }) => {
              handleChange({ name, value: value.replaceAll('#', '').replaceAll('-', '') })
            }}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2' style={{ color: '#000' }}>
          {/*  <RadioGroup
            name='InspectorType'
            label='Inspector Type'
            color='#333'
            options={['Account Manager', 'Contractor']}
            onChangeRadio={({ name, value }: { name: string; value: string }) => {
              handleChange({ name, value: value })
            }}
            defaultSelected={modelData?.InspectorType ?? 'Account Manager'}
            customClassNames={{ root: 'input-field custom-radio', input: 'input-field-input' }}
          /> */}
          <div className='radio-group-label'>
              <b>Inspector Type</b>
              <RadioGroupTs
                // label='Cost'
                name='InspectorType'
                onChange={(value) => {
                  handleChange({ name: 'InspectorType', value })
                }}
                className='radio-group-ts custom-radio customer-custom-radio'
                selectedValue={String(modelData.InspectorType)}
              >
                <label className='radio-group-ts-label' htmlFor='AccountManager'>
                  <Radio id='AccountManager' value='Account Manager' />
                  <div className='radio-group-ts-value'>
                    Account Manager
                  </div>
                </label>
                <label className='radio-group-ts-label' htmlFor='Contractor'>
                  <Radio id='Contractor' value='Contractor' />
                  <div className='radio-group-ts-value'>
                    Contractor
                  </div>
                </label>
              </RadioGroupTs>
            </div>
        </CCol>
        <CCol md='6' className='pr-lg-2 pl-lg-2' style={{ color: '#000' }}>
          <div className='radio-group-label'>
              <b>Timezone</b>
              <RadioGroupTs
                // label='Cost'
                name='Timezone'
                onChange={(value) => {
                  handleChange({ name: 'Timezone', value })
                }}
                className='radio-group-ts custom-radio customer-custom-radio'
                selectedValue={String(modelData.Timezone)}
              >
                <label className='radio-group-ts-label' htmlFor='Pacific'>
                  <Radio id='Pacific' value='Per Hour' />
                  <div className='radio-group-ts-value'>
                    Pacific
                  </div>
                </label>
                <label className='radio-group-ts-label' htmlFor='Mountain'>
                  <Radio id='Mountain' value='Mountain' />
                  <div className='radio-group-ts-value'>
                     Mountain
                  </div>
                </label>
                <label className='radio-group-ts-label' htmlFor='Central'>
                  <Radio id='Central' value='Central' />
                  <div className='radio-group-ts-value'>
                     Central
                  </div>
                </label>
                <label className='radio-group-ts-label' htmlFor='Eastern'>
                  <Radio id='Eastern' value='Eastern' />
                  <div className='radio-group-ts-value'>
                     Eastern
                  </div>
                </label>
              </RadioGroupTs>
            </div>
        </CCol>
      </CFormGroup>

      {/* <CFormGroup row>
        <CCol>
          <InputField
            name='CustomerLastName'
            type='phone'
            label='Phone'
            value={Phone}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup> */}
      {/* <CFormGroup row>
        <CCol>
          <InputField
            name='PhoneOther'
            type='phone'
            label='Other Phone'
            value={PhoneOther ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup> */}

      {/*   <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </>
  )
}

export default InspectorInfo
