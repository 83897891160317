// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
const SigninsInfo = ({ modelData, handleChange }) => {
  const {
    TokenType,
    SourceIp,
    SourceBrowser,
    StampStart,
    StampEnd,
    ExecutionTime,
    UserName,
    Validity,
    IsError,
    ErrorsId,
    AccessToken,
  } = modelData

  return (
    <FieldsSection header='Signin Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='TokenType'
            type='text'
            disabled
            label='TokenType'
            value={TokenType}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='SourceIp'
            type='text'
            disabled
            label='SourceIp'
            value={SourceIp}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='SourceBrowser'
            type='text'
            disabled
            label='SourceBrowser'
            value={SourceBrowser}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='UserName'
            type='text'
            disabled
            label='UserName'
            value={UserName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Validity'
            type='text'
            disabled
            label='Validity'
            value={Validity}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='StampStart'
            type='text'
            disabled
            label='StampStart'
            value={StampStart}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='StampEnd'
            type='text'
            disabled
            label='StampEnd'
            value={StampEnd}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Executiontime'
            type='text'
            disabled
            label='Executiontime'
            value={ExecutionTime}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
            disabled
          >
            <CustomSwitch
              name='IsError'
              className='d-block'
              disabled={true}
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='ErrorsId'
            type='text'
            disabled
            label='ErrorsId'
            value={ErrorsId}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='AccessToken'
            type='text'
            disabled
            label='Access Token'
            value={AccessToken}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default SigninsInfo
