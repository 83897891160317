import { CCol, CFormGroup } from '@coreui/react'
import { ComboBox } from 'src/shared'
import React from 'react'
import { FieldsSection } from 'src/shared'
interface PropsUserChangeRole {
  rolesData?: any
  handleComboChange?: any
  userData?: any
}
const UserChangeRole: React.FC<PropsUserChangeRole> = ({
  rolesData,
  handleComboChange,
  userData,
}) => {
  /* console.log(
    rolesData,
    rolesData.find((role: any) => role.CodeId === userData.RolesId),
    userData,
  ) */
  return (
    <FieldsSection header='Change Role'>
      <CFormGroup row>
        <CCol xs='12'>
          <ComboBox
            showButton
            name='RolesId'
            label='Role'
            data={rolesData}
            value={rolesData.find((role: any) => role.CodeId === userData.RolesId)}
            handleChange={handleComboChange}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
            )}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}
export default UserChangeRole
