import {  CCol, CFormGroup } from '@coreui/react'
import React from 'react'
// import { routeToPage } from 'src/services/routingHelper'
// import { FieldsSection } from 'src/shared'

interface PropsApplicationsInfo {
  data?: any
  history?: any
  handleChange?: any
  handleChangeToken?: any
}
const ApplicationsTokensInfo: React.FC<PropsApplicationsInfo> = ({ data}) => {
  // const redirectToToken = () => {
  //   routeToPage(history, `/Admin/Tokens/Detail/${data.TokensId}`)
  // }
  // const { TokensId } = data
  return (
    <>
    {/* <FieldsSection
      header={
        <>
          Tokens Info
          {TokensId === 0 || (
            <CButton
              onClick={redirectToToken}
              color='secondary'
              className='mr-1 ml-auto'
              style={{ float: 'right' }}
            >
              View
            </CButton>
          )}
        </>
      }
    > */}
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <div style={{ color: '#000' }}>
            <b>TokenType</b> : {data.TokenType}
          </div>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <div style={{ color: '#000' }}>
            <b>User Name</b> : {data.UserName}
          </div>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <div style={{ color: '#000' }}>
            <b>Access Token</b> : {data.AccessToken}
          </div>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <div style={{ color: '#000' }}>
            <b>Full Name</b> : {data.FullName}
          </div>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <div style={{ color: '#000' }}>
            <b>LoginDateTime</b> : {data.LoginDateTime}
          </div>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <div style={{ color: '#000' }}>
            <b>IsDeleted</b> : {data.IsDeleted ? 'Yes' : 'No'}
          </div>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <div style={{ color: '#000' }}>
            <b>Is Expired</b> : {data.IsExpired ? 'Yes' : 'No'}
          </div>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <div style={{ color: '#000' }}>
            <b>Is Locked Out</b> : {data.IsLockedOut ? 'Yes' : 'No'}
          </div>
        </CCol>
      </CFormGroup>
    {/* </FieldsSection> */}
    </>
  )
}

export default ApplicationsTokensInfo
