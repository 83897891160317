// Generated with util/create-search-page.js
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { SigninsTableColumns } from './SigninsTableColumns'
import { BASE_API, SIGNINS_MODEL, MODELS, SIGNINS_ID } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'

const SigninsSearch = ({ history, modelName = SIGNINS_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)

  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }) => {
    routeToPage(history, '/Logs/Signins/Detail/' + id)
  }

  const showDeleteModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions = {
    view: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        {modelName} Search
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={SigninsTableColumns}
          theme='dark'
          noHeader
          endpoint={BASE_API + ':' + MODELS}
          modelName={modelName}
          idName={SIGNINS_ID}
          actionClick={(type, data) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          scopedSlots={{
            AccessToken: ({ value }) => {
              return (
                <div style={{ color: '#F2AB26', fontSize: '0.70rem', fontFamily: 'monospace' }}>
                  {value}
                </div>
              )
            },
            SourceIp: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            SourceBrowser: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
          }}
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Signin', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CCard>
  )
}

export default SigninsSearch
