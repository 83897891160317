import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, DELETE, MODELS, SERVICES } from 'src/services/routeConst'
interface PropsDeleteModel {
  modelName?: string
  data?: any
}
export const DeleteModel = async ({ modelName = '', data = {} }: PropsDeleteModel) => {
  //URL {api}/Models/Delete/{ModelName}/:id
  // const { id = '' }: any = data
  console.log(typeof data.id);
  console.log(modelName);
  const {id}:any = data

  try {
    const { data } = await axios.delete(
      `${BASE_API}${SERVICES}${MODELS}/${DELETE}`,
      {
        data:{
          Id: id,
          Model: modelName,
          // Extensions: body.Extension ?? null,
          Params: [],
        },
        headers: {
          Authorization: addAuthToHeader('ADMIN').headers.Authorization
        }
      },
    )
    return {
      data: data.data,
      status: 'success',
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error deleting ${modelName}!`,
      error: error.response,
    })
  }
}
