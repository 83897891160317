/* export const BASE_API = ['localhost', 'admindev.vitalcard.com'].includes(window.location.hostname)
  ? 'http://localhost'
  : 'https://services-staging.vitalcard.com' */
export const BASE_API = ['hidroponiaserver.web.app'].includes(window.location.hostname)
  ? 'https://api.softsmartinc.com'
  : 'https://api.softsmartinc.com'
// export const BASE_API = 'https://dispatch-now-mock-server.herokuapp.com'
// export const FILES_URL = 'https://betavitalcdnstorage.blob.core.windows.net'
export const FILES_URL = 'https://media.vitalcard.com'

export const LOGIN_PORT = '8100'
export const LOGIN_ENDPOINT = 'Login'
export const AUTH_VALIDATE = 'Validate'
export const AUTH_AUTHENTICATE = 'Authenticate'

// export const PROC_GENERATE = '8102/ProcGenerate'
export const PROC_GENERATE = '/ProcGenerate'
// export const MODELS = '8108/Models'
export const MODELS = '/Models'
export const LIST = '/List'
// export const WIDGETS = '8118/Widgets'
export const WIDGETS = '/Widget'
export const SERVICES = '/Services/vOld'
export const EXECUTE = 'Execute'
// export const DATA = '8126/Data'
export const DATA = '/Data'
// export const TOKENS = '8136/Token'
export const TOKENS = '/Token'

export const LETSCONNECT = '/LetsConnect'
export const SMS = '/Sms'

export const BREADCRUMBS = '/Breadcrumbs'
export const LIST_HISTORY = '/ListHistory'

// * Login
export const ADMIN_LOGIN = 'User'
export const MEMBER_LOGIN = 'Member'
export const APPLICATION_LOGIN = 'Application'

export const MODEL_SEARCH = 'Search'
export const MODEL_COMBO = 'Combo'
export const MODEL_LIST = 'List'
export const DATA_EXEC = 'Execute'
export const TRANSACT_SQL = 'Query'

export const RETRIEVE = 'Retrieve'
export const DELETE = 'Delete'
export const SAVE = 'Save'
export const USERS = 'Users'
export const GENERATE = 'Generate'
export const LOGIN = 'Login'

export const PROCGENERATE_LOOKUPVIEW = 'LookupView'

// * ADMIN Models
export const MENUS_MODEL = 'Menus'
export const SUB_MENUS_MODEL = 'SubMenus'
export const MENUS_ID = 'MenusId'
export const CLIENTS_MODEL = 'Clients'
export const CLIENTS_ID = 'ClientsId'
export const USERS_MODEL = 'AdminUsers'
export const NO_CALL_MODEL = 'NoCall'
export const PHONE_MODEL = 'Phones'
export const CAMPAIGN = 'Campaign'
export const USERS_ID = 'UsersId'
export const ROLES_MODEL = 'Roles'
export const ROLES_ID = 'RolesId'
export const TOKENS_MODEL = 'Tokens'
export const TOKENS_ID = 'TokensId'
export const MODELS_MODEL = 'Models'
export const MODELS_ID = 'ModelsId'
export const LOOKUP_MODEL = 'Lookups'
export const LOOKUP_TYPES_MODEL = 'LookupTypes'
export const LOOKUP_TYPES_ID = 'TypesId'
export const LOOKUPS_MODEL = 'Lookups'
export const LOOKUPS_ID = 'LookupsId'
export const PROPERTIES_MODEL = 'Properties'
export const PROPERTIES_ID = 'PropertiesId'
export const APPLICATIONS_MODEL = 'Applications'
export const APPS_MODEL = 'Apps'
export const APPLICATIONS_ID = 'ApplicationsId'
export const COLUMNS_MODEL = 'Columns'
export const COLUMNS_ID = 'ColumnsId'
export const FACILITIES_MODEL = 'Facilities'
export const PHARMA_MODEL = 'Pharma'

export const SERVICES_ID = 'ServicesId'
export const ENDPOINTS_MODEL = 'MicroEndpoints'
export const ENDPOINTS_ID = 'EndpointsId'
export const SCENARIOS_MODEL = 'MicroScenarios'
export const SCENARIOS_ID = 'ScenariosId'
export const TEST_RESULTS_MODEL = 'TestResults'
export const TEST_RESULTS_ID = 'TestsId'
export const DATASCHEMAS_MODEL = 'DataSchemas'
export const DATASCHEMAS_ID = 'SchemasId'
// * ALERTS Models
export const EMAILS_MODEL = 'AlertEmails'
export const EMAILS_ID = 'EmailsId'
export const ALERTS_FIELDS_MODEL = 'AlertFields'
export const ALERTS_FIELDS_ID = 'FieldsId'
export const TEMPLATES_MODEL = 'AlertTemplates'
export const TEMPLATES_ID = 'TemplatesId'
export const SMS_MODEL = 'AlertSms'
export const SMS_ID = 'SmsId'

// * MEMBER Models
export const MEMBERS_MODEL = 'MemberUsers'
export const MEMBERS_ID = 'UsersId'
export const TRANSACTIONS_MODEL = 'MemberTransactions'
export const TRANSACTIONS_ID = 'TransId'
export const STATEMENTS_MODEL = 'MemberStatements'
export const STATEMENTS_ID = 'StatementsId'
export const BANKS_MODEL = 'MemberBanks'
export const BANKS_ID = 'BanksId'
export const CATEGORIES_MODEL = 'Categories'
export const CATEGORIES_ID = 'CategoriesId'
export const SETTINGS_MODEL = 'MemberSettings'
export const SETTINGS_ID = 'SettingsId'
export const STATS_MODEL = 'MemberStats'
export const STATS_ID = 'StatsId'
export const CARDS_MODEL = 'MemberCards'
export const CARDS_ID = 'CardsId'
export const EXPERIAN_MODEL = 'MemberExperian'
export const EXPERIAN_ID = 'ExperianId'
export const SHARED_MODEL = 'MemberShares'
export const SHARED_ID = 'SharedId'
export const CONTACT_MODEL = 'MemberContacts'
export const CONTACT_ID = 'ContactsId'
export const SOCIALS_MODEL = 'MemberSocials'
export const SOCIALS_ID = 'SocialsId'
export const OTP_MODEL = 'MemberOtp'
export const OTP_ID = 'OtpId'
// * Operations
export const OPERATIONS_QUANTUM = 'QuantumDispatches'
export const INSPECTORS = 'Inspectors'
export const CUSTOMERS_MODEL = 'Customers'
export const VEHICLES_MODEL = 'Vehicles'
export const DISPATCHES_MODEL = 'Dispatches'
export const CUSTOMERS_SERVICES_MODEL = 'CustomersServices'
export const SERVICES_MODEL = 'Services'
export const REGIONS_MODELS = 'Regions'
export const LEADS_MODELS = 'Leads'
export const BUYERS_MODELS = 'Buyers'
export const JEMCARE_MODELS = 'JemcareLeads'
// * Temmplates
export const TEMPLATES_BANK_MODEL = 'TestingBanks'
export const TEMPLATES_BANK_MODEL_ID = 'BanksId'

export const TEMPLATES_MERCHANTS_MODELS = 'TestingMerchants'
export const TEMPLATES_MERCHANTS_ID = 'MerchantsId'

export const TEMPLATES_USERS_MODELS = 'TestingUsers'
export const TEMPLATES_USERS_ID = 'UsersId'

export const TEMPLATES_CARDS_MODELS = 'TestingCards'
export const TEMPLATES_CARDS_ID = 'CardsId'

export const TEMPLATES_SETTINGS_MODELS = 'TestingSettings'
export const TEMPLATES_SETTINGS_ID = 'SettingsId'

export const TEMPLATES_STATS_MODEL = 'TestingStats'
export const TEMPLATES_STATS_ID = 'StatsId'

export const TEMPLATES_EXPERIAN_MODEL = 'TestingExperian'
export const TEMPLATES_EXPERIAN_ID = 'ExperianId'

export const TEMPLATES_TRANS_MODEL = 'TestingTrans'
export const TEMPLATES_TRANS_ID = 'TransId'

export const TEMPLATES_STATEMENTS_MODELS = 'TestingStatements'
export const TEMPLATES_STATEMENTS_ID = 'TestingStatements'
//* Experian
export const EXPERIAN_FACTORS_MODELS = 'ExperianFactors'
export const EXPERIAN_FACTORS_ID = 'FactorsId'
export const ALERTIMAGEDOWNLOAD = `${BASE_API}:${WIDGETS}/Files/Email`
export const baseInputProps = {
  className: 'input-field ',
  rootClassName: 'input-field-root',
}

export const SIGNINS_MODEL = 'LogsSignins'
export const SIGNINS_ID = 'SigninsId'

export const APIS_MODEL = 'LogsApis'
export const APIS_ID = 'ApisId'

export const CONNECTIONS_MODEL = 'DataConnections'
export const CONNECTIONS_ID = 'ConnectionsId'

export const PROC_MODEL = 'Procs'
export const PROC_ID = 'ProcsId'

export const PROCS_MODEL = 'Procedures'
export const PROCS_ID = 'ProcsId'

export const PUSH_MODEL = 'LogsPush'
export const PUSH_ID = 'PushId'

export const FILES_MODEL = 'Files'
export const FILES_ID = 'FilesId'

export const QUERIES_MODEL = 'LogsQueries'
export const QUERIES_ID = 'QueriesId'

export const PROCEDURES_MODEL = 'LogsProcedures'
export const PROCEDURES_ID = 'ProceduresId'

export const PUSH_ALERTS_MODEL = 'AlertPush'
export const PUSH_ALERTS_ID = 'PushId'

export const APPLICANTS_MODEL = 'Applicants'
export const APPLICANTS_ID = 'ApplicantsId'
export const ALPHABETA_MODEL = 'WaitlistAlphaBeta'
export const ALPHABETA_ID = 'AlphabetaId'

export const PROCS_TEST_MODEL = 'DataProcsTest'
export const PROCS_TEST_ID = 'ProcsTestId'

export const ERROR_LOGS_MODEL = 'LogsErrors'
export const ERROR_LOGS_ID = 'ErrorsId'

export const ERRORLOGS_MODEL = 'LogsErrors'
export const ERRORLOGS_ID = 'ErrorsId'
export const ERROR_EMAILS_MODEL = 'LogsEmails'
export const ERROR_EMAILS_ID = 'EmailsId'
