import { CCol, CFormGroup } from '@coreui/react'
// import { RadioGroup, Radio } from 'react-radio-input'
import { FieldsSection, InputField } from 'src/shared'
// import Address from './Address'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange: any
  title?: string
}
// const RadioGroupTs: any = RadioGroup
const LeadInfo = ({ title, modelData, handleChange }: PropsInfoForm) => {
  const { 
    CustName,
    CustFirstName,
    CustLastName,
    CustPhoneMobile,
    CustPhoneOther,

    OfferAmount,
    Campaign,

    CustAddressZip
  } = modelData
  // const mapComboToData = () =>

  return (
    <>
      <FieldsSection header={title}>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='CustName'
              type='text'
              label='Name'
              value={CustName ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol md='6' className='pr-lg-2 pl-lg-2'>
            <InputField
              name='CustFirstName'
              type='text'
              label='First Name'
              value={CustFirstName ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='CustLastName'
              type='text'
              label='Last Name'
              value={CustLastName ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol md='3' className='pr-lg-2 pl-lg-2'>
            <InputField
              name='CustPhoneMobile'
              type='phone'
              label='Phone Mobile'
              value={CustPhoneMobile ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='CustPhoneOther'
              type='phone'
              label='Phone Other'
              value={CustPhoneOther ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='6' className='pr-lg-2 pl-lg-2'>
            <InputField
              name='Campaign'
              type='text'
              label='Campaign'
              value={Campaign ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
        <CFormGroup row>    
          <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='OfferAmount'
              type='number'
              label='Offer Amount'
              value={OfferAmount ?? ''}
              onChange={handleChange}
              alignment={'right'}
            />
          </CCol>
          <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='CustAddressZip'
              type='number'
              label='Zip'
              value={CustAddressZip ?? ''}
              onChange={handleChange}
              alignment={'right'}
            />
          </CCol>
        </CFormGroup>
        {/* <Address
          modelData={modelData}
          handleChange={handleChange}
        /> */}

      </FieldsSection>
    </>
  )
}

export default LeadInfo