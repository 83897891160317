import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { InputField } from 'src/shared'

interface PropsModelsInfo {
  modelData?: any
  conectionsData?: any
  handleChange?: any
  handleComboChange?: any
}
const ModelsInfo: React.FC<PropsModelsInfo> = ({ modelData, handleChange }) => {
  return (
    <>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={modelData.Name ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='DbName'
            type='text'
            label='DbName'
            value={modelData.DbName ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='DbObject'
            type='text'
            label='DbObject'
            value={modelData.DbObject ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='DbSchema'
            type='text'
            label='DbSchema'
            value={modelData.DbSchema ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      {/* <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='TableViewOrProcedure'
            type='text'
            label='Table View O rProcedure'
            value={modelData.TableViewOrProcedure ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='IsChangeTracking'
            type='fancyswitch'
            label='Is Change Tracking'
            labelStyles={{ marginBottom: 0 }}
          >
            <CustomSwitch
              name='IsChangeTracking'
              className='d-block'
              value={modelData.IsChangeTracking}
              updateData={(e, value) => {
                console.log(value)
                handleChange({
                  name: e,
                  value,
                })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </>
  )
}

export default ModelsInfo
