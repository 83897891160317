import React from 'react'
import { connect } from 'react-redux'
import { CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, JEMCARE_MODELS, MODELS, SERVICES, LIST } from 'src/services/routeConst'
import { routeToPage } from 'src/services/routingHelper'
import { DeleteModal } from 'src/shared'
import { TableColumns } from './JemcareTableColumns'
// import { ModalNewCustomer } from './components'

const ModelSearch: React.FC<any> = ({ history, modelName = JEMCARE_MODELS, loggedInUser = {} }) => {

  // "DbName": "DbOltp",
  //   "QueryOrProcedureName": "jemcare.LEADS_List",
  //   "Params": [{
  //     "Name": "StatusGroup",
  //     "Value": "Declined"
  //   }]

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  // const [showModal, setShowModal] = useState(false)
  const clickedIDRef = useRef()
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g)!.join(' ')
  const tableRef = useRef<any>()

  const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Jemcare/Detail/${id}`)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <>
      <CCardHeader
        className='d-flex align-items-center'
        style={{
          backgroundColor: '#2A3446',
          marginBottom: '1rem',
          fontSize: 16,
          fontWeight: 600,
          borderRadius: 0,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <span style={{ color: 'white' }}>{title} Received</span>
        {/* <CButton
          color='primary'
          onClick={() => {
            routeToPage(history, `/Leads/New`)
          }}
        >
          New Lead
        </CButton> */}
      </CCardHeader>
      <CCardBody className={'custom-card-body'}>
        <DataTable
          columnData={TableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          queryParams={[
            {
              Name: 'StatusGroup',
              Value: 'Received',
            },
            {Name: "UsersId", Value : loggedInUser.Auth.User.Id}
          ]}
          modelName={modelName}
          actionClick={(type: any, data: any) => {
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          paginationPosition='bottom-right'
        />
      </CCardBody>
      {/* <ModalNewCustomer
        history={history}
        show={showModal}
        onClose={() => {
          setShowModal(false)
        }}
      /> */}
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Lead', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </>
  )
}

const mapStateToProps = (state: any) => {
  const {  auth } = state
  return {
    loggedInUser : auth.loggedInUser
  }
}

export default React.memo(connect(mapStateToProps, null)(ModelSearch))
