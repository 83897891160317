// Generated with util/create-search-page.js
import { CButton, CCardHeader } from '@coreui/react'
interface PropsHeaderComponent {
  saveData: any
  modelName: any
}
const HeaderComponent = ({ saveData, modelName = 'Model Name' }: PropsHeaderComponent) => {
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g).join(' ')
  return (
    <CCardHeader
      className='d-flex align-items-center'
      style={{
        backgroundColor: '#2A3446',
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 16,
        fontWeight: 600,
        borderRadius: 0,
        marginBottom: '1rem',
      }}
    >
      <span style={{ color: 'white' }}>{title} Search</span>

      <CButton
        className='mr-2'
        type='button'
        color='primary'
        style={{ float: 'right' }}
        onClick={saveData}
      >
        New Lookup
      </CButton>
    </CCardHeader>
  )
}

export default HeaderComponent
