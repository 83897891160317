import { CModal, CModalBody, CModalFooter } from '@coreui/react'
import { Step, Stepper, StepLabel } from 'smart-inputs'
import { createContext, useState } from 'react'
import { CButton } from '@coreui/react'
import { routeToPage } from 'src/services/routingHelper'
import Details from './Details'
import { useActionsModels } from './hooks/ActionsModels'
import { CUSTOMERS_MODEL } from 'src/services/routeConst'
import PickUpInfo from './PickUpInfo'
import VehicleComponent from './Vehicle'
import SourceDetail from './SourceDetails'

interface WizardContextStore {
  activeStep: number
  setActiveStep?: any
  handleNext: VoidFunction
  handleBack: VoidFunction
  handleReset: VoidFunction
  setCustomer: any
  Customer: any
  Vehicle: any
  setVehicle: any
  steps: string[]
}
export const WizardContext = createContext<WizardContextStore>({
  handleNext: () => {},
  handleBack: () => {},
  handleReset: () => {},
  setCustomer: () => {},
  setVehicle: () => {},
  Customer: null,
  activeStep: 0,
  Vehicle: null,
  steps: [],
})
export const ModalNewCustomer = ({ history, show, onClose }) => {
  const modelName = CUSTOMERS_MODEL
  const [apiData, , saveData, handleChange, handleChangeMaxLength, handleAddressChange] = useActionsModels({
    id: 0,
    modelName,
    history,
  })
  const [Customer, setCustomer] = useState<any>(null)
  const [VehicleData, setVehicle] = useState(null)
  const steps = ['Detail', 'Pickup ', 'Vehicle Info', 'Quantum Details']
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }
  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleReset = async () => {
    setActiveStep(0)
    const SaveInfo = await saveData()
    // console.log('SaveINfo',SaveInfo);
    await routeToPage(history, `/Operations/Customers/Detail/${SaveInfo['Id'] ?? 0}`)
    // redirect to
  }
  const { modelData } = apiData
console.log('MOdelData', modelData);

  return (
    <WizardContext.Provider
      value={{
        activeStep,
        handleNext,
        handleBack,
        handleReset,
        setActiveStep,
        Customer,
        setCustomer,
        Vehicle: VehicleData,
        setVehicle,
        steps,
      }}
    >
      <CModal show={show} onClose={onClose} size='lg' style={{ height: '40rem' }}>
        <CModalBody>
          <div style={{ width: '100%' }}>
            <Stepper activeStep={activeStep} style={{ backgroundColor: '#fff', color: '#000' }}>
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {}

                if (isStepSkipped(index)) {
                  stepProps.completed = false
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel>
                      <span style={{ color: '#000' }}>{label}</span>
                    </StepLabel>
                  </Step>
                )
              })}
            </Stepper>

            {activeStep === steps.length && (
              <>
                <p>All steps completed - you&apos;re finished</p>
                <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '2rem' }}>
                  <div style={{ flex: '1 1 auto' }} />
                  <CButton color='primary' onClick={handleReset}>
                    Reset
                  </CButton>
                </div>
              </>
            )}
            {activeStep === 0 && (
              <div style={{ marginBottom: '1rem' }}>
                <Details
                  title='Details'
                  handleChange={handleChange}
                  modelData={modelData}
                  handleAddressChange={handleAddressChange}
                />
              </div>
            )}
            {activeStep === 1 && (
              <div style={{ marginBottom: '1rem' }}>
                <PickUpInfo
                  handleAddressChange={handleAddressChange}
                  handleChange={handleChange}
                  modelData={modelData}
                  title='Pick up Info'
                />
              </div>
            )}

            {activeStep === 2 && (
              <div style={{ marginBottom: '1rem' }}>
                <VehicleComponent
                  handleAddressChange={handleAddressChange}
                  handleChange={handleChange}
                  handleChangeMaxLength={handleChangeMaxLength}
                  modelData={modelData}
                  title='Vehicle info'
                ></VehicleComponent>
              </div>
            )}
            {activeStep === 3 && (
              <div style={{ marginBottom: '1rem' }}>
                <SourceDetail
                  handleAddressChange={handleAddressChange}
                  handleChange={handleChange}
                  modelData={modelData}
                  title='Quantum info'
                ></SourceDetail>
              </div>
            )}
            {/* <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '2rem' }}>
          {activeStep !== 0 && (
            <CButton color='primary' disabled={activeStep === 0} onClick={handleBack}>
              Back
            </CButton>
          )}
          <div style={{ flex: '1 1 auto' }} />
          <CButton color='primary' onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </CButton>
        </div> */}
          </div>
        </CModalBody>
        <CModalFooter>
          {activeStep !== 0 && (
            <CButton color='primary' onClick={handleBack} size='lg'>
              Previous
            </CButton>
          )}
          <CButton
            color='primary'
            onClick={activeStep === steps.length - 1 ? handleReset : handleNext}
            size='lg'
          >
            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
          </CButton>
        </CModalFooter>
      </CModal>
    </WizardContext.Provider>
  )
}
