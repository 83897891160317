import React from 'react'
import { CCard, CCardBody, CCol, CRow, CTabPane } from '@coreui/react'

import { RolesRepository } from 'src/repository/admin/roles/RolesRepository'
import { DeleteModal, Loader } from 'src/shared'
// import { RolesHeader, RolesInfo, UserInfo, Menus, SubMenus } from './components'
import { RolesHeader, RolesInfo, UserInfo, Menus } from './components'
import { MENUS_MODEL, ROLES_MODEL, USERS_MODEL } from 'src/services/routeConst'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import NotesInfo from './components/NotesInfo'
import { ListModel } from 'src/services/modelNew/List/List'
// import { SaveModel } from 'src/services/modelNew/Save/Save'
// import { toast } from 'react-toastify'

const fields = ['Id', 'Name', 'Description']
const RoleDetails: React.FC<any> = ({ history, match, modelName = ROLES_MODEL }) => {
  const [apiData, setApiData] = React.useState<any>({ roleData: null })
  const [propertyData, setPropertyData] = React.useState<any>([])
  const [roleMenuData, setRoleMenuData] = React.useState<any>([])
  const [status, setStatus] = React.useState<any>('loading')
  const [showDeleteModal, setShowDeleteModal] = React.useState<any>(false)
  const clickedIDRef = React.useRef<any>(-1)
  const tableRef = React.useRef<any>()
  const changesRef = React.useRef<any>()
  const { id = 0 } = match.params
  const rolesRepository = React.useMemo(() => new RolesRepository(), [])

  const getData = React.useCallback(
    async (shouldUpdateRefs = true) => {
      try {
        const { mainData } = await rolesRepository.getData({
          id,
          modelName,
          defaultMainData: { Id: 0 },
        })
        const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
        setApiData({ roleData: dataResponse })
        setStatus('idle')

        const { data } = await ListModel({
          body: {
            Model: USERS_MODEL,
            Params: {
              RolesId: dataResponse.Id
            }
          }
        })
        setPropertyData(data);
        
        const { data: result } = await ListModel({
          body: {
            Model: MENUS_MODEL,
            Params: {
              RolesId: dataResponse.Id
            }
          }
        })
        setRoleMenuData(result);

        id && shouldUpdateRefs && updateRefs()
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [id, modelName, rolesRepository],
  )

  const updateRefs = () => {
    tableRef.current.fetchNewData()
    changesRef.current.fetchNewData()
  }

  React.useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setApiData({ roleData: null })
        history.goBack()
        resolve(true)
      }, delay)
    })
  }

  const saveData = async () => {
    const dataToSave: any = apiData.roleData
    setStatus('saving')
    try {
      await rolesRepository.saveData({
        modelName,
        idName: 'Id',
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        getData,
        goBack,
      })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }
  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        roleData: { ...st.roleData, [name]: value },
      }))
  }

  /*   const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Admin/Users/Detail/${id}`)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  } */

  /* const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  } */

  if (!apiData.roleData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { roleData } = apiData
  return (
    <>
      <CCard>
        <RolesHeader
          modelName={modelName}
          status={status}
          saveData={saveData}
          onCancel={history.goBack}
        />
        <CCardBody>
          <CRow>
            <CCol>
              <DetailTabSection
                id={0}
                MetasId={roleData.MetasId}
                Guio={0}
                modelName={''}
                hideNotes
                tabs={[
                  { name: 'RolesInfo', label: 'Info' },
                  { name: 'UsersInfo', label: 'User Assigned this Role' },
                  { name: 'RoleMenu', label: 'Menus' },
                  // { name: 'RoleSubMenu', label: 'Sub Menus' },
                ]}
              >
                <CTabPane data-tab='RolesInfo' className='detail-tab-section-pane'>
                  <RolesInfo roleData={roleData} handleChange={handleChange}></RolesInfo>
                </CTabPane>
                <CTabPane data-tab='UsersInfo' className='detail-tab-section-pane'>
                  <UserInfo propertyData={propertyData}></UserInfo>
                </CTabPane>
                <CTabPane data-tab='RoleMenu' className='detail-tab-section-pane'>
                  <Menus roleMenuData={roleMenuData}></Menus>
                </CTabPane>
                {/* <CTabPane data-tab='RoleSubMenu' className='detail-tab-section-pane'>
                  <SubMenus id={id} history={history}></SubMenus>
                </CTabPane> */}
              </DetailTabSection>
            </CCol>

            <CCol xs={5} style={{ marginTop: '0' }}>
              {roleData.Id !== 0 && roleData.MetasId !== null ? (
                <DetailTabSection
                  id={0}
                  metasId={roleData.MetasId}
                  hideNotes
                  tabs={[{ name: 'Notes', label: 'Notes' }]}
                >
                  <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
                    <NotesInfo MetasId={roleData.MetasId}></NotesInfo>
                  </CTabPane>
                </DetailTabSection>
              ) : (
                <></>
              )}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName: USERS_MODEL, label: 'User', id: clickedIDRef.current }}
        callBack={getData}
      />
    </>
  )
}

export default RoleDetails
