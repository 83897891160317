import CIcon from '@coreui/icons-react'
import * as CoreUiIcons from '@coreui/icons'
import { CCol, CFormGroup, CModal, CModalBody, CModalHeader, CModalTitle } from '@coreui/react'
import React, { useContext } from 'react'
import { InputField } from 'src/shared'

import { DashboardContext } from 'src/containers/DashBoard'

interface PropsMenuInfo {
    subMenuData?: any
  handleChange?: any
  handleComboChange?: any
}
const SubMenuInfo: React.FC<PropsMenuInfo> = ({
    subMenuData,
  handleChange
}) => {
  const { zoomMode } = useContext(DashboardContext)
  
  return (
    <>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Name'
            type='text'
            label='Menu Name'
            value={subMenuData.Name}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Description'
            type='textarea'
            label='Menu Description'
            value={subMenuData.Description}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='6' sm='6'>
          <InputField
            name='Icon'
            type='iconpicker'
            label='Icon'
            coreUiIcon={CoreUiIcons}
            size={zoomMode === 'enabled' ? 'lg' : 'sm'}
            renderModal={({ show, onClose, classNames, IconList }: any) => (
              <CModal
                size='xl'
                show={show}
                onClosed={onClose}
                color='primary'
                addContentClass={`mw-100 ${classNames.modalContainer}`}
                scrollable
              >
                <CModalHeader closeButton>
                  <CModalTitle>Select an Icon</CModalTitle>
                </CModalHeader>
                <CModalBody>{IconList}</CModalBody>
              </CModal>
            )}
            value={subMenuData.Icon}
            onChange={handleChange}
            showButton
            IconComponent={CIcon}
            ButtonComponent={({ styles, togglePicker }: any) => (
              <CIcon style={styles} onClick={togglePicker} name='cil-apps' />
            )}
          />
        </CCol>
        <CCol xs='6' sm='6'>
          <InputField
            name='Color'
            type='colorpicker'
            label='Color'
            value={subMenuData.Color}
            onChange={handleChange}
            ButtonComponent={({ styles, togglePicker }: any) => (
              <CIcon style={styles} onClick={togglePicker} name='cil-color-palette' />
            )}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='6' sm='6'>
         
        </CCol>
      </CFormGroup>
        <CFormGroup row>
          <CCol xs='12' sm='12'>
            <InputField
              name='PagePath'
              type='text'
              label='Page Path'
              value={subMenuData?.PagePath ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
    </>
  )
}

export default SubMenuInfo
