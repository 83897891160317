import React from 'react'
import { CTabPane } from '@coreui/react'

// import { FieldsSection } from 'src/shared'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import { DataTable } from 'library-soft-datatable'
import { modelsTableColumns } from '../modelsTableColumns'
import { BASE_API } from 'src/services/routeConst'
interface PropsScenariosDetailTabSection {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  Guid?: any
}
const ScenariosDetailTabSection: React.FC<PropsScenariosDetailTabSection> = ({
  id,
  modelName,
  changesRef,
  Guid,
}) => {
  return (
    <DetailTabSection
      id={id}
      Guio={Guid}
      modelName={modelName}
      tabs={[{ name: 'DataTable', label: 'Clients' }]}
      changesRef={changesRef}
    >
      <CTabPane data-tab='DataTable' className='detail-tab-section-pane'>
        <CTabPane data-tab='DataTable' className='detail-tab-section-pane'>
          <DataTable
            columnData={modelsTableColumns}
            theme='dark'
            noHeader
            endpoint={`${BASE_API}/Models`}
            modelName={'Models'}
            idName={'Id'}
            apiKey={''}
          />
        </CTabPane>
      </CTabPane>
    </DetailTabSection>
  )
}

export default ScenariosDetailTabSection
