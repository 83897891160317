import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormGroup, CRow } from '@coreui/react'
import { CheckBoxGroup } from 'smart-inputs'
import { ComboBox } from 'src/shared'
import React from 'react'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { Loader } from 'src/shared'
import LoadingButton from 'src/shared/components/LoadingButton'
import ConfirmRedirectModal from 'src/shared/modals/ConfirmRedirectModal'

const defaultOptions = [
  {
    name: 'Create',
    label: 'Create',
    value: true,
    disabled: true,
  },
  {
    name: 'Retrieve',
    label: 'Retrieve',
    value: true,
    disabled: true,
  },
  {
    name: 'Update',
    label: 'Update',
    value: true,
    disabled: true,
  },
  {
    name: 'Delete',
    label: 'Delete',
    value: true,
    disabled: true,
  },
]

const procNames: any = {
  databaseNames: 'DatabaseNamesCombo',
  schemaNames: 'SchemaNamesCombo',
  tableNames: 'TableNamesCombo',
  fieldNames: 'TableColumnsCombo',
}

const CrudGenerator: React.FC<any> = ({ history }) => {
  const [selectionData, setSelectionData] = React.useState<any>({
    DatabaseName: null,
    SchemaName: null,
    TableName: null,
  })
  const [options, setOptions] = React.useState<any>(defaultOptions)

  const [status, setStatus] = React.useState('loading')
  const [apiData, setApiData] = React.useState({
    databaseNames: [],
    schemaNames: [],
    tableNames: [],
  })
  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(
    async ({ type = 'databaseNames', params }) => {
      try {
        const { data } = await rootRepository.exec({ procName: procNames[type], params })
        setApiData((st: any) => ({ ...st, [type]: data }))
        setStatus('idle')
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [rootRepository],
  )

  React.useEffect(() => {
    // getData({ type: 'databaseNames' })
  }, [getData])

  const handleComboChange = (
    e: any,
    newValue: any = {},
    type: string,
    nextType = '',
    params = {},
  ) => {
    if (!e.target) return
    const CodeId = newValue ? newValue?.CodeId : null
    let stateData: any
    // if (CodeId === null) {
    if (type === 'DatabaseName') {
      stateData = { SchemaName: null, TableName: null }
    } else if (type === 'SchemaName') {
      stateData = { TableName: null }
    } else if (type === 'TableName' && CodeId) {
      enableOptions({ useDefaults: true })
    } else {
      stateData = { TableName: null }
    }
    if (!!nextType) {
      getData({ type: nextType, params })
    }
    setSelectionData((st: any) => ({ ...st, [type]: CodeId, ...stateData }))
    stateData && stateData.TableName === null && setOptions(defaultOptions)
  }

  const enableOptions = ({ useDefaults = false }) => {
    setOptions((st: any) =>
      [...(useDefaults ? defaultOptions : st)].map((option) => ({ ...option, disabled: false })),
    )
  }

  const generateSP = async () => {
    setStatus('generating')
    try {
      const params = {
        DatabaseName: selectionData.DatabaseName,
        SchemaName: selectionData.SchemaName,
        TableName: selectionData.TableName,
      }
      const selectedProcs = options
        ?.filter((option: any) => option.value)
        ?.map((option: any) => option.name)
      for (let procType of selectedProcs) {
        await rootRepository.procGen({ procType, params })
      }
      setStatus('idle')
    } catch (error: any) {
      // console.log(error)
      setStatus('idle')
    }
  }

  const resetFields = () => {
    setSelectionData({ DatabaseName: '0', SchemaName: '0', TableName: '0' })
    setApiData((st: any) => ({ ...st, tableNames: [] }))
    setOptions(defaultOptions)
  }

  if (!apiData.databaseNames || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const generateDisabled =
    status === 'generating' ||
    options[0].disabled ||
    !options.filter((option: any) => option.value)?.length
  const { databaseNames, schemaNames = [], tableNames = [] } = apiData
  const isLoading = status === 'generating'
  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        Crud Generator
        <CButton className='ml-2' type='button' color='secondary' onClick={resetFields}>
          Reset
        </CButton>
        <LoadingButton
          className='mr-1 ml-auto'
          style={{ float: 'right' }}
          disabled={generateDisabled}
          loading={isLoading}
          onClick={generateSP}
        >
          Generate
        </LoadingButton>
        <CButton
          className='mr-2'
          type='button'
          color='secondary'
          style={{ float: 'right' }}
          onClick={history.goBack}
        >
          Cancel
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol xs='12' md='6'>
            <CFormGroup row>
              <CCol xs='12'>
                <ComboBox
                  showButton
                  name='DatabaseName'
                  label='Database Name'
                  data={databaseNames}
                  value={databaseNames.find(
                    (dbNameData: any) => dbNameData.CodeId === selectionData['DatabaseName'],
                  )}
                  handleChange={(e: any, newValue: any = {}) =>
                    handleComboChange(e, newValue, 'DatabaseName', 'schemaNames', {
                      DbName: newValue?.CodeId,
                    })
                  }
                  classNames={{ input: 'input-field' }}
                  customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                  renderOption={(option: any, { selected }: any) => (
                    <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
                  )}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs='12'>
                <ComboBox
                  showButton
                  name='SchemaName'
                  label='Schema Name'
                  data={schemaNames}
                  value={schemaNames.find(
                    (schemaNameData: any) => schemaNameData.CodeId === selectionData['SchemaName'],
                  )}
                  handleChange={(e: any, newValue: any = {}) =>
                    handleComboChange(e, newValue, 'SchemaName', 'tableNames', {
                      DbName: selectionData['DatabaseName'],
                      SchemaName: newValue?.CodeId,
                    })
                  }
                  classNames={{ input: 'input-field' }}
                  customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                  renderOption={(option: any, { selected }: any) => (
                    <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
                  )}
                  disabled={selectionData.DatabaseName === null}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs='12'>
                <ComboBox
                  showButton
                  name='TableName'
                  label='Table Name'
                  data={tableNames}
                  value={tableNames.find(
                    (tableNameData: any) => tableNameData.CodeId === selectionData['TableName'],
                  )}
                  handleChange={(e: any, newValue = {}) =>
                    handleComboChange(e, newValue, 'TableName')
                  }
                  classNames={{ input: 'input-field' }}
                  customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                  renderOption={(option: any, { selected }: any) => (
                    <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
                  )}
                  disabled={selectionData.SchemaName === null}
                />
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol>
            <CFormGroup row>
              <CCol xs='12'>
                <CheckBoxGroup
                  name='Procs'
                  label='Select Procedures'
                  options={options}
                  onChangeCheckBox={({ name, value }) => {
                    setOptions((st: any) => [
                      ...st.map((option: any) => ({
                        ...option,
                        value: name === option.name ? value : option.value,
                      })),
                    ])
                  }}
                  customClassNames={{
                    root: 'input-field-root CheckBoxGroup',
                    input: 'input-field mt-1',
                  }}
                />
              </CCol>
            </CFormGroup>
          </CCol>
        </CRow>
      </CCardBody>
      <ConfirmRedirectModal data={selectionData?.TableName} />
    </CCard>
  )
}

export default CrudGenerator

// Options Structure
// {
//   label: 'isDeleted',
//   name: 'isDeleted',
//   options: [
//     { name: 'isField', label: 'isField', value: false },
//     { name: 'isParam', label: 'isParam', value: false },
//   ],
// },
// {
//   label: 'changedOn',
//   name: 'changedOn',
//   options: [
//     { name: 'isField', label: 'isField', value: false },
//     { name: 'isParam', label: 'isParam', value: false },
//   ],
// },
// {
//   label: 'changedBy',
//   name: 'changedBy',
//   options: [
//     { name: 'isField', label: 'isField', value: false },
//     { name: 'isParam', label: 'isParam', value: false },
//   ],
// },
// {
//   label: 'changeType',
//   name: 'changeType',
//   options: [
//     { name: 'isField', label: 'isField', value: false },
//     { name: 'isParam', label: 'isParam', value: false },
//   ],
// },
