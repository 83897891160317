import { RootRepository } from '../rootRepository'

export class UsersRepository extends RootRepository {
  async getData({ id, modelName }: any) {
    try {
      return await super.getData({
        id,
        modelName,
        defaultMainData: { UsersId: 0, RolesId: 0 },
      })
    } catch (error: any) {
      throw new Error(error.message)
    }
  }
}
