import React, { useEffect } from 'react';
import SignaturePad from 'react-signature-canvas';

interface PropsInfoForm {
    modelData?: any
    handleChange?: any
}

const JemcareSign = ({ modelData, handleChange }: PropsInfoForm) => {
    // const [showSignPad, setshowSignPad] = React.useState<any>(true)
    // const [showMemSignPad, setshowMemSignPad] = React.useState<any>(true)
    // const [showStaffSignPad, setShowStaffSignPad] = React.useState<any>(true)
    // const [showStaffIniSignPad, setShowStaffIniSignPad] = React.useState<any>(true)
    let sigCanvas: any = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    let sigCanvas1: any = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    let sigCanvas2: any = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    let sigCanvas3: any = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    useEffect(() => {
        if (modelData.Signature) {
            sigCanvas.current.fromDataURL(modelData.Signature, 'image/png');
        }
        if (modelData.MemberInitials) {
            sigCanvas1.current.fromDataURL(modelData.MemberInitials, 'image/png');
        }
        if (modelData.StaffSignature) {
            sigCanvas2.current.fromDataURL(modelData.StaffSignature, 'image/png');
        }
        if (modelData.StaffInitials) {
            sigCanvas3.current.fromDataURL(modelData.StaffInitials, 'image/png');
        }
    }, [])

    return ( <div className='row'>
        <div className="col-md-8 mt-3 p-0" style={{maxWidth:480}}>
            <label className='d-block'>Signature</label>
            <div className='position-relative'>
                <span onClick={() => {
                    sigCanvas.current.clear();
                    modelData.Signature = "";
                }} className="btn-icon">x</span>
                <SignaturePad ref={sigCanvas} clearOnResize={false} onEnd={() => {
                    handleChange({name: 'Signature', value: sigCanvas.current.toDataURL('image/png') })
                }} canvasProps={{
                    className: 'signaturecanvas',
                    width: 480, height: 125
                }}
                />
            </div>
        </div>
        <div className="col-md-4 mt-3 py-0 pr-0" style={{maxWidth:200, paddingLeft: '20px'}}>
            <label className='d-block'>Member Initials Signature</label>
            <div className='sing-pad'>
                <span onClick={() => {
                    sigCanvas1.current.clear();
                    modelData.MemberInitials = "";
                }} className="btn-icon">x</span>
                <SignaturePad ref={sigCanvas1} clearOnResize={false} onEnd={() => {
                    handleChange({name: 'MemberInitials', value: sigCanvas1.current.toDataURL('image/png') })
                }} canvasProps={{
                    className: 'signaturecanvas',
                    width: 180, height: 125
                }}
                />
            </div>
        </div>
        <div className="col-md-8 mt-3 p-0" style={{maxWidth:480}}>
            <label className='d-block'>Staff Signature</label>
            <div className='position-relative'>
            <span onClick={() => {
                sigCanvas2.current.clear();
                modelData.StaffSignature = "";
            }} className="btn-icon">x</span>
            <SignaturePad ref={sigCanvas2} clearOnResize={false} onEnd={() => {
                handleChange({name: 'StaffSignature', value: sigCanvas2.current.toDataURL('image/png') })
            }} canvasProps={{
                className: 'signaturecanvas',
                width: 480, height: 125
            }}
            />
            </div>
        </div>
        <div className="col-md-4 mt-3 py-0 pr-0" style={{maxWidth:200, paddingLeft: '20px'}}>
            <label className='d-block'>Staff Initials Signature</label>
            <div className='sing-pad'>
            <span onClick={() => {
                sigCanvas3.current.clear();
                modelData.StaffInitials = "";
            }} className="btn-icon">x</span>
            <SignaturePad ref={sigCanvas3} clearOnResize={false} onEnd={() => {
                handleChange({name: 'StaffInitials', value: sigCanvas3.current.toDataURL('image/png') })
            }} canvasProps={{
                className: 'signaturecanvas',
                width: 180, height: 125
            }}
            />
            </div> 
        </div>
    </div> )
}

export default JemcareSign