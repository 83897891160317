import { CCol, CFormGroup, CRow } from '@coreui/react'
import { ComboBox, FieldsSection, InputField } from 'src/shared'

interface PropsProcProperties {
  procTypesOptions: any[]
  apiData?: any
  setApiData: Function
}
const ProcProperties = ({ procTypesOptions, apiData, setApiData }: PropsProcProperties) => {
  return (
    <>
      {procTypesOptions.map((item, index) => {
        if (item.value) {
          if (item.name === 'Search' || item.name === 'List') {
            return (
              <FieldsSection key={index} header={` ${item.name} properties (optional)`}>
                <CRow>
                  <CCol>
                    <CFormGroup row>
                      <CCol xs='12'>
                        <InputField
                          name='Extension'
                          type='text'
                          label='Extension'
                          value={apiData[item.name]?.Extension}
                          onChange={(e) => {
                            setApiData((state) => ({
                              ...state,
                              [item.name]: { ...state[item.name], Extension: e.value },
                            }))
                          }}
                        />
                      </CCol>
                    </CFormGroup>
                    <CFormGroup row>
                      <CCol xs='12'>
                        <ComboBox
                          data={[]}
                          showButton
                          multiple
                          freeSolo
                          name='FieldList'
                          label='FieldList'
                          value={apiData[item.name]?.FieldList ?? []}
                          handleChange={(e, value) => {
                            console.log(e)
                            setApiData((state) => ({
                              ...state,
                              [item.name]: { ...state[item.name], FieldList: value },
                            }))
                          }}
                          classNames={{ input: 'input-field' }}
                          customClassNames={{
                            button: 'comboBoxInput',
                            root: 'input-field-root pt-3',
                          }}
                          renderOption={(option: any, { selected }: any) => (
                            <div className={selected ? 'combo-selected' : ''}>{option.CodeId}</div>
                          )}
                          placeholder='Enter a FieldList'
                        />
                      </CCol>
                    </CFormGroup>
                    <CFormGroup row>
                      <CCol xs='12'>
                        <ComboBox
                          data={[]}
                          showButton
                          multiple
                          freeSolo
                          name='ParamList'
                          label='ParamList'
                          value={apiData[item.name]?.ParamList ?? []}
                          handleChange={(e, value) => {
                            console.log(e)
                            setApiData((state) => ({
                              ...state,
                              [item.name]: { ...state[item.name], ParamList: value },
                            }))
                          }}
                          classNames={{ input: 'input-field' }}
                          customClassNames={{
                            button: 'comboBoxInput',
                            root: 'input-field-root pt-3',
                          }}
                          renderOption={(option: any, { selected }: any) => (
                            <div className={selected ? 'combo-selected' : ''}>{option.CodeId}</div>
                          )}
                          placeholder='Enter a ParamList'
                        />
                      </CCol>
                    </CFormGroup>
                  </CCol>
                </CRow>
              </FieldsSection>
            )
          }
          if (item.name === 'Combo') {
            return (
              <FieldsSection key={index} header={` ${item.name} properties *`}>
                <CRow>
                  <CCol>
                    <CFormGroup row>
                      <CCol xs='12'>
                        <InputField
                          name='Extension'
                          type='text'
                          label='Extension'
                          value={apiData[item.name]?.Extension}
                          onChange={(e) => {
                            setApiData((state) => ({
                              ...state,
                              [item.name]: { ...state[item.name], Extension: e.value },
                            }))
                          }}
                        />
                      </CCol>
                    </CFormGroup>

                    <CFormGroup row>
                      <CCol xs='12'>
                        <InputField
                          name='CodeIdField'
                          type='text'
                          label='Code ID Field *'
                          value={apiData[item.name]?.CodeIdField}
                          onChange={(e) => {
                            setApiData((state) => ({
                              ...state,
                              [item.name]: { ...state[item.name], CodeIdField: e.value },
                            }))
                          }}
                        />
                      </CCol>
                    </CFormGroup>
                    <CFormGroup row>
                      <CCol xs='12'>
                        <InputField
                          name='DescriptionField'
                          type='text'
                          label='Description Field *'
                          value={apiData[item.name]?.DescriptionField}
                          onChange={(e) => {
                            setApiData((state) => ({
                              ...state,
                              [item.name]: { ...state[item.name], DescriptionField: e.value },
                            }))
                          }}
                        />
                      </CCol>
                    </CFormGroup>
                    <CFormGroup row>
                      <CCol xs='12'>
                        <ComboBox
                          data={[]}
                          showButton
                          multiple
                          freeSolo
                          name='ParamList'
                          label='ParamList'
                          value={apiData[item.name]?.ParamList ?? []}
                          handleChange={(e, value) => {
                            console.log(e)
                            setApiData((state) => ({
                              ...state,
                              [item.name]: { ...state[item.name], ParamList: value },
                            }))
                          }}
                          classNames={{ input: 'input-field' }}
                          customClassNames={{
                            button: 'comboBoxInput',
                            root: 'input-field-root pt-3',
                          }}
                          renderOption={(option: any, { selected }: any) => (
                            <div className={selected ? 'combo-selected' : ''}>{option.CodeId}</div>
                          )}
                          placeholder='Enter a ParamList'
                        />
                      </CCol>
                    </CFormGroup>
                  </CCol>
                </CRow>
              </FieldsSection>
            )
          }
        }
        return ''
      })}
    </>
  )
}

export default ProcProperties
