// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  modelName: string
}

const InfoForm = ({ modelData, handleChange, modelName = 'Model Name' }: PropsInfoForm) => {
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g)!.join(' ')
  console.log(modelData)
  const { AccountType, ChangedOn, Name, Phone } = modelData
  return (
    <FieldsSection header={`${title} Info`}>
      <CFormGroup row>
        <CCol xs='3' sm='3'>
          <InputField
            name='AccountType'
            type='text'
            label='Account Type'
            value={AccountType}
            onChange={handleChange}
          />
        </CCol>
        <CCol>
          <InputField name='Name' type='text' label='Name' value={Name} onChange={handleChange} />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <InputField
            name='Phone'
            type='phone'
            label='Phone'
            value={Phone}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <InputField
            disabled
            name='ChangedOn'
            type='text'
            label='ChangedOn'
            value={ChangedOn}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      {/*   <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </FieldsSection>
  )
}

export default InfoForm
