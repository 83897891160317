// Generated with util/create-search-page.js
import { useState, useMemo, useCallback, useEffect } from 'react'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { Loader } from 'src/shared'
import { ProcsHeader, ProcsInfo, ProcQuery } from './components'
import { PROCS_MODEL, PROCS_ID, CONNECTIONS_MODEL } from 'src/services/routeConst'
import { ComboModel } from 'src/services/modelNew/Combo/Combo'
interface ApiDataResponde {
  modelData?: any
  conectionData?: any
}
const ProcsDetails: React.FC<any> = ({ history, match, modelName = PROCS_MODEL }) => {
  const [apiData, setApiData] = useState<ApiDataResponde>({ modelData: null })
  const [status, setStatus] = useState<any>('loading')
  const { id = 0 } = match.params

  const fields = [
    'ProcsId',
    'Name',
    'ProcedureName',
    'SchemaName',
    'Script',
    'DatabaseName',
    'ConnectionsId',
    'ConnectionName',
  ]

  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback(async () => {
    try {
      const { data: conectionData } = await ComboModel({ modelName: CONNECTIONS_MODEL })
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [PROCS_ID]: 0 },
      })
      setApiData((st: any) => ({ ...st, modelData: mainData, conectionData: conectionData }))
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }, [id, modelName, rootRepository])

  /*  const updateRefs = () => {
    changesRef.current.fetchNewData()
  } */

  useEffect(() => {
    getData()
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ modelData: null })
      history.goBack()
    }, delay)
  }

  const saveData = async () => {
    const dataToSave: any = apiData.modelData
    setStatus('saving')
    try {
      await rootRepository.saveData({
        modelName,
        idName: PROCS_ID,
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        getData,
        goBack,
      })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
    // console.log(apiData)
  }

  if (!apiData.modelData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { modelData, conectionData } = apiData
  return (
    <CCard>
      <ProcsHeader status={status} saveData={saveData} onCancel={history.goBack} />
      <CCardBody>
        <CRow>
          <CCol xs='12' md='6'>
            <ProcsInfo
              modelData={modelData}
              conectionsData={conectionData}
              handleChange={handleChange}
            />
          </CCol>
          <CCol xs='12' md='6'>
            <ProcQuery />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  )
}

export default ProcsDetails
