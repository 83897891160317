import { Step, Stepper, StepLabel } from 'smart-inputs'
import { createContext, useState } from 'react'
import { CButton } from '@coreui/react'
import Customers from './Customers/Customers'

import Vehicle from './Vehicles/Vehicle'
import CustomerServices from './CustomersServices/CustomerServicesInfo'
import { routeToPage } from 'src/services/routingHelper'

interface WizardContextStore {
  activeStep: number
  setActiveStep?: any
  handleNext: VoidFunction
  handleBack: VoidFunction
  handleReset: VoidFunction
  setCustomer: any
  Customer: any
  Vehicle: any
  setVehicle: any
  steps: string[]
}
export const WizardContext = createContext<WizardContextStore>({
  handleNext: () => {},
  handleBack: () => {},
  handleReset: () => {},
  setCustomer: () => {},
  setVehicle: () => {},
  Customer: null,
  activeStep: 0,
  Vehicle: null,
  steps: [],
})
export const Wizard = ({ history }) => {
  const [Customer, setCustomer] = useState<any>(null)
  const [VehicleData, setVehicle] = useState(null)
  const steps = ['General information', 'Vehicle details', 'Customer Services']
  const [activeStep, setActiveStep] = useState(0)
  const [skipped, setSkipped] = useState(new Set<number>())
  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }
  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }
  const handleReset = () => {
    setActiveStep(0)
    routeToPage(history, `/Operations/Customers/Detail/${Customer?.Id ?? 0}`)
    // redirect to
  }
  return (
    <WizardContext.Provider
      value={{
        activeStep,
        handleNext,
        handleBack,
        handleReset,
        setActiveStep,
        Customer,
        setCustomer,
        Vehicle: VehicleData,
        setVehicle,
        steps,
      }}
    >
      <div style={{ width: '100%' }}>
        <Stepper
          activeStep={activeStep}
          style={{ backgroundColor: '#3C4B64', color: '#fff', marginBottom: '2rem' }}
        >
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {}

            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel>
                  <span style={{ color: 'white' }}>{label}</span>
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>

        {activeStep === steps.length && (
          <>
            <p>All steps completed - you&apos;re finished</p>
            <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '2rem' }}>
              <div style={{ flex: '1 1 auto' }} />
              <CButton color='primary' onClick={handleReset}>
                Reset
              </CButton>
            </div>
          </>
        )}
        {activeStep === 0 && (
          <div style={{ marginBottom: '1rem' }}>
            <Customers />
          </div>
        )}
        {activeStep === 1 && (
          <div style={{ marginBottom: '1rem' }}>
            <Vehicle />
          </div>
        )}

        {activeStep === 2 && (
          <div style={{ marginBottom: '1rem' }}>
            <CustomerServices />
          </div>
        )}
        {/* <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '2rem' }}>
          {activeStep !== 0 && (
            <CButton color='primary' disabled={activeStep === 0} onClick={handleBack}>
              Back
            </CButton>
          )}
          <div style={{ flex: '1 1 auto' }} />
          <CButton color='primary' onClick={handleNext}>
            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
          </CButton>
        </div> */}
      </div>
    </WizardContext.Provider>
  )
}
