// Generated with util/create-search-page.js
import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
import { PROCS_TEST_MODEL, PROCS_TEST_ID } from 'src/services/routeConst'
import { RootRepository } from 'src/repository/admin/rootRepository'

const fields = [
  'ProcsTestId',
  'ProcsId',
  'Params',
  'TimeStampStart',
  'TimeStampEnd',
  'Executiontime',
  'IsError',
  'ErrorMessage',
  'ErrorStackTrace',
]

const EditProcsTestModal: React.FC<any> = ({
  showModal = false,
  modelName = PROCS_TEST_MODEL,
  setShowModal,
  modalData,
  callBack = () => {},
}) => {
  const [loadingData, setLoadingData] = useState<any>(true)
  const [apiData, setApiData] = useState<any>()
  const { id = 0 } = modalData

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    const getDataInEffect = async () => {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [PROCS_TEST_ID]: 0 },
      })
      setApiData(mainData)
    }
    loadingData && getDataInEffect()
  }, [id, loadingData, modelName, rootRepository])

  useEffect(() => {
    apiData && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData])

  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    await rootRepository.saveData({
      modelName,
      idName: PROCS_TEST_ID,
      dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: apiData[field] }))),
    })
    setApiData({})
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setApiData((st: any) => ({ ...st, [name]: value }))
  }

  if (loadingData) {
    return null
  }
  const {
    ProcsId,
    Params,
    TimeStampStart,
    TimeStampEnd,
    Executiontime,
    IsError,
    ErrorMessage,
    ErrorStackTrace,
  } = apiData
  return (
    <CModal
      show={showModal}
      onClose={() => {
        setApiData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} ProcsTest</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header=''>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='ProcsId'
                    type='text'
                    label='ProcsId'
                    value={ProcsId}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Params'
                    type='text'
                    label='Params'
                    value={Params}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='TimeStampStart'
                    type='text'
                    label='TimeStampStart'
                    value={TimeStampStart}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='TimeStampEnd'
                    type='text'
                    label='TimeStampEnd'
                    value={TimeStampEnd}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Executiontime'
                    type='text'
                    label='Executiontime'
                    value={Executiontime}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='IsError'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='IsError'
                  >
                    <CustomSwitch
                      name='IsError'
                      className='d-block'
                      value={IsError}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='ErrorMessage'
                    type='text'
                    label='ErrorMessage'
                    value={ErrorMessage}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='ErrorStackTrace'
                    type='text'
                    label='ErrorStackTrace'
                    value={ErrorStackTrace}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            setApiData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default EditProcsTestModal
