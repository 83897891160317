import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import React, { useRef, useState } from 'react'
import { DeleteModal } from './DeleteModal'
import { routeToPage } from 'src/services/routingHelper'
import { memberTableColumns } from './memberTableColumns'
import { BASE_API, MEMBERS_ID, MEMBERS_MODEL, MODELS } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'
import { MemberGenerateForm } from './components'
import MemberAvatar from 'src/shared/components/MemberAvatar'
import { formatFullName } from 'src/services/util'

const MembersSearch: React.FC<any> = ({ history, modelName = MEMBERS_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const clickedIDRef = useRef()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Admin/Members/Detail/${id}`)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <>
      <MemberGenerateForm refreshData={() => tableRef.current.fetchNewData()} />
      <CCard>
        <CCardHeader className='d-flex align-items-center card-accent-secondary'>
          {modelName} Search
          <CButton
            className='mr-1 ml-auto'
            type='button'
            color='primary'
            style={{ float: 'right' }}
            onClick={() => routeToPage(history, `/Admin/Members/New`)}
          >
            New Member
          </CButton>
        </CCardHeader>
        <CCardBody>
          <DataTable
            columnData={memberTableColumns}
            theme='dark'
            noHeader
            endpoint={`${BASE_API}:${MODELS}`}
            modelName={modelName}
            idName={MEMBERS_ID}
            actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
            ref={tableRef}
            apiKey={getAuthToken('ADMIN')}
            scopedSlots={{
              Email: ({ value }) => {
                return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
              },
              AvatarUrl: ({ row }: any) => (
                <MemberAvatar
                  name={formatFullName(row.original?.FirstName, row.original?.LastName)}
                  avatar={row.original?.FilesId_Avatar}
                  noChip
                />
              ),
            }}
            paginationPosition='top-right'
          />
        </CCardBody>
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setShowDeleteModal}
          modalData={{ modelName, label: 'Member', id: clickedIDRef.current }}
          callBack={() => tableRef.current.fetchNewData()}
        />
      </CCard>
    </>
  )
}

export default MembersSearch
