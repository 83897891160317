// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const OwnershipInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const { IsTitleInHand, FinalOffer, DueCustomer, PaymentMethod } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            label='Is Title In Hand'
            value={IsTitleInHand? IsTitleInHand.toString() :'False'}
          />
          <InputLabel 
            label='Final Offer'
            value={FinalOffer ?? '⠀'}
          />
          <InputLabel 
            label='Due Customer'
            value={DueCustomer ?? '⠀'}
          />
          <InputLabel 
            label='Payment Method'
            value={PaymentMethod ?? '⠀'}
          />
          {/* <InputField type='fancyswitch' name='IsTitleInHand' label={`Is Title In Hand`}>
            <CustomSwitch
              name='IsTitleInHand'
              className='d-block'
              value={IsTitleInHand}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.IsTitleInHand })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField> */}
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default OwnershipInfo
