// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const HistoryInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const { 
    HistoryAutocheckVerification,
    HistoryAutocheckNotes,
    HistoryCoPartNotes,
    HistoryGoogleVerification,
    HistoryNmvtisVerification,
    HistoryCarfaxVerification,
    HistoryCarfaxNotes,
    HistoryCoPartVerification,
    HistoryKsrNotes,
    HistoryGoogleNotes,
    HistoryNmvtisNotes,
    HistoryKsrVerification,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel
            label='Autocheck Verification'
            value={HistoryAutocheckVerification ? HistoryAutocheckVerification.toString() : 'No'}
          />
          <InputLabel 
            label={'Autocheck Notes'}
            value={HistoryAutocheckNotes ?? '⠀'}
          />
          <InputLabel 
            label={'Co Part Notes'}
            value={HistoryCoPartNotes ?? '⠀'}
          />
          <InputLabel
            label='Google Verification'
            value={HistoryGoogleVerification ? HistoryGoogleVerification.toString() : 'No'}
          />
          <InputLabel
            label='Nmv tis Verification'
            value={HistoryNmvtisVerification ? HistoryNmvtisVerification.toString() : 'No'}
          />
          <InputLabel
            label='Car fax Verification'
            value={HistoryCarfaxVerification ? HistoryCarfaxVerification.toString() : 'No'}
          />
          <InputLabel
            label='Car fax Notes'
            value={HistoryCarfaxNotes ?? '⠀'}
          />
          <InputLabel
            label='Co Part Verification'
            value={HistoryCoPartVerification ? HistoryCoPartVerification.toString() : 'No'}
          />
          <InputLabel
            label='Ksr Notes'
            value={HistoryKsrNotes ?? '⠀'}
          />
          <InputLabel
            label='GoogleNotes'
            value={HistoryGoogleNotes ?? '⠀'}
          />
          <InputLabel
            label='Nmv tis Notes'
            value={HistoryNmvtisNotes ?? '⠀'}
          />
          <InputLabel
            label='Ksr Verification'
            value={HistoryKsrVerification ? HistoryKsrVerification.toString() : 'No'}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default HistoryInfo
