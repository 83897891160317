import React from 'react'
import { DataTable } from 'library-soft-datatable'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, COLUMNS_MODEL, COLUMNS_ID, MODELS } from 'src/services/routeConst'
// import { FieldsSection } from 'src/shared'
import { tableFieldsTableColumns } from '../modelsTableColumns'
interface PropsModelsDetailTabSection {
  id?: any
  modelName?: any
  showEditModalFunc?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  Guid?: any
}
const ModelsDetailTabSection: React.FC<PropsModelsDetailTabSection> = ({
  // id,
  actionFunctions,
  tableRef,
}) => {
  return (
    <DataTable
      columnData={tableFieldsTableColumns}
      theme='white'
      noHeader
      endpoint={`${BASE_API}:${MODELS}`}
      modelName={COLUMNS_MODEL}
      idName={COLUMNS_ID}
      // queryParams={{ ModelsId: id, Extention: 'ByModelsId' }}
      actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
      ref={tableRef}
      rootStyles={{ fontSize: '0.75rem' }}
      apiKey={getAuthToken('ADMIN')}
    />
  )
}

export default ModelsDetailTabSection
