// Generated with util/create-search-page.js
import { CButton, CCardHeader } from '@coreui/react'

const ErrorLogsHeader = ({ onCancel }) => {
  return (
    <CCardHeader className='d-flex align-items-center card-accent-secondary'>
      ErrorLog Details
      <CButton
        className='mr-2 ml-auto'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancel}
      >
        Cancel
      </CButton>
    </CCardHeader>
  )
}

export default ErrorLogsHeader
