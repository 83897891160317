import { DataTable } from 'library-soft-datatable'
import { BASE_API, LIST, MODELS, SERVICES, SUB_MENUS_MODEL } from 'src/services/routeConst'
// import { FieldsSection } from 'src/shared'
import CIcon from '@coreui/icons-react'
import { subMenuTableColumns } from '../menuTableColumns'
import { getAuthToken } from 'src/services/authHelper'
import SubMenuInfo from './SubMenuInfo'
import { DeleteModal } from 'src/shared'
import React from 'react'
// import React from 'react'
// import { toast } from 'react-toastify'
// import { SaveModel } from 'src/services/modelNew/Save/Save'
// import { CButton } from '@coreui/react'
interface PropsMenuSubMenus {
  id?: any
  actionFunctions?: any
  createSubMenuMode?: any;
  menuData?: any
  onClick?:any
  ref?:any
  handleSubChange?: any
  subMenuData?: any
}
const MenuSubMenus = ({ id, menuData, createSubMenuMode, handleSubChange, subMenuData }: PropsMenuSubMenus) => {

  const [showDeleteModal, setShowDeleteModal] = React.useState<any>(false)
  const clickedIDRef = React.useRef<any>(-1)
  const tableRef = React.useRef<any>()

  // const [subMenuData, setSubMenuData] = React.useState<any>({
  //   'Id': 0,
  //   'IsMain': false,
  //   'MenusId_Parent': menuData.Id,
  //   'Name': '',
  //   'Description': '',
  //   'Icon': '',
  //   'Color': '',
  //   'PagePath': ''
  // });

  // const handleSubChange = React.useCallback(({ name = '', value = '' }) => {
  //   console.log(name, value)
  //   name &&
  //   setSubMenuData(state => ({ ...state, [name]: value }));
  // }, [])

  // const saveData = async () => {
  //   if (subMenuData.Name) {
  //     try {
  //       const { message } = await SaveModel({
  //         body: {
  //           Model: "Menus",
  //           Params: subMenuData
  //         },
  //       })
  //       toast.success(message)
  //     } catch (error: any) {
  //       toast.error(error)
  //       // console.error(error)
  //     }
  //   }
  // }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    // edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  // SubMenuInfo
  return <>
    { menuData.Id !== 0 && menuData.MetasId !== null ? (createSubMenuMode) ? <> <SubMenuInfo
            subMenuData={subMenuData}
            handleChange={handleSubChange}
          ></SubMenuInfo> </> : <DataTable
          columnData={subMenuTableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName='Menus'
          idName='Id'
          ref={tableRef}
          extension='SubMenus'
          queryParams={[{ Name: 'MenusId', Value: id }]}
          actionClick={(type: any, data: any) => {actionFunctions[type]({ id: data })}}
          rootStyles={{ fontSize: '0.75rem' }}
          IconComponent={CIcon}
          apiKey={getAuthToken('ADMIN')}
          />
       : <>
      </> }
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName: SUB_MENUS_MODEL, label: 'Sub Menu', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </>
}

export default MenuSubMenus
