// Generated with util/create-search-page.js
import { CButton, CCardHeader } from '@coreui/react'
import LoadingButton from 'src/shared/components/LoadingButton'

const FacilitiesHeader = ({ status, saveData, onCancel }) => {
  const isLoading = status === 'saving'
  return (
    <CCardHeader
      className='d-flex align-items-center'
      style={{
        backgroundColor: '#2A3446',
        marginBottom: '1rem',
        fontSize: 16,
        fontWeight: 600,
        borderRadius: 0,
      }}
    >
      <span style={{ color: 'white' }}>Facilities Details</span>
      <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton>
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancel}
      >
        Cancel
      </CButton>
    </CCardHeader>
  )
}

export default FacilitiesHeader
