import React from 'react'
import { CCard, CCardBody, CCol, CFade, CRow, CTabPane } from '@coreui/react'
import { MenusRepository } from 'src/repository/admin/menus/MenusRepository'
import { routeToPage } from 'src/services/routingHelper'
import { DeleteModal, Loader } from 'src/shared'
import { MenuInfo, MenusHeader, NotesInfo } from './components'
import { MENUS_MODEL } from 'src/services/routeConst'
import { useContext } from 'react'
import { DashboardContext } from '../../../containers/DashBoard'
import { ListModel } from 'src/services/modelNew/List/List'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'
import HeaderButton from 'src/shared/components/HeaderButton'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import MenuSubMenus from './components/MenuSubMenus'

const fields = [
  'Id',
  'ApplicationsId',
  'Name',
  'Description',
  'Icon',
  'Color',
  'RolesList'
]

const MenuDetails: React.FC<any> = ({ history, location, match, modelName = MENUS_MODEL }) => {
  const { getMenuRoutes } = useContext(DashboardContext)
  const [apiData, setApiData] = React.useState<any>({ menuData: null, allMenusData: [], allRollData: [] })
  const [status, setStatus] = React.useState<any>('loading')
  const [createSubMenuMode, setCreateSubMenuMode] = React.useState<any>(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState<any>(false)
  const clickedIDRef = React.useRef<any>(-1)
  const tableRef = React.useRef<any>()
  const changesRef = React.useRef<any>()
  const [subMenuData, setSubMenuData] = React.useState<any>({
    'Id': 0,
    'IsMain': false,
    'MenusId_Parent': 0,
    'Name': '',
    'Description': '',
    'Icon': '',
    'Color': '',
    'PagePath': ''
  });

  const handleSubChange = React.useCallback(({ name = '', value = '' }) => {
    name &&
    setSubMenuData(state => ({ ...state, [name]: value }));
  }, [])

  const { id = 0 } = match.params
  const { MenusId_Parent } = location?.state

  const menusRepository = React.useMemo(() => new MenusRepository(), [])

  const getData = React.useCallback(
    async (shouldUpdateRefs = true) => {
      try {
        const { data: allMenusData } = await ListModel({
          body: {
            Model: "Apps",
            Extension: null,
            Params: {
              Page: 1,
              PageSize: 10000,
              SearchString: null,
              OrderBy: null,
              OrderByDir: null,
            },
          },
        })
        
        const { data: allRollData } = await ListModel({
          body: {
            Model: "Roles",
            Extension: null,
            Params: {
              Page: 1,
              PageSize: 10000,
              SearchString: null,
              OrderBy: null,
              OrderByDir: null,
            },
          },
        })
        const { mainData } = await menusRepository.getData({
          id,
          modelName,
          defaultMainData: { Id: 0, MenusId_Parent, IsMain: true },
        })
        const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
        setApiData({ menuData: dataResponse, allMenusData, allRollData })
        setStatus('idle')
        id && shouldUpdateRefs && updateRefs()
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [MenusId_Parent, id, menusRepository, modelName],
  )

  const updateRefs = () => {
    tableRef.current?.fetchNewData()
    changesRef.current?.fetchNewData()
  }

  React.useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ menuData: null })
      history.goBack()
    }, delay)
  }

  const saveData = async () => {
    const dataToSave: any = apiData.menuData
    if (typeof dataToSave.RolesList !== 'string') {
      dataToSave.RolesList = dataToSave.RolesList.map((r:any) => { return r.CodeId }).toString();
    }
    
    setStatus('saving')
    try {
      const { message } = await SaveModel({
        body: {
          Model: modelName,
          /*  Params: Object.assign(
            {},
            ...fields.map((field) => ({
              [field]: ['IsMain'].includes(field) ? !dataToSave.MENUS_ID_Parent : dataToSave[field],
            })),
          ), */
          Params: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        },
      })
      toast.success(message)
      /* getData() */
      goBack()
      getMenuRoutes()
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const handleChange = React.useCallback(({ name = '', value = '' }) => {
    // console.log({ name, value })
    name &&
      setApiData((st: any) => ({
        ...st,
        menuData: {
          ...st.menuData,
          [name]: value,
        },
      }))
  }, [])

  const handleComboChange = (e: any, newValue: any = {}) => {
    if (!e.target) return
    const CodeId = newValue ? newValue.CodeId : null
    setApiData((st: any) => ({ ...st, menuData: { ...st.menuData, ApplicationsId: CodeId } }))
  }

  const handleRollComboChange = (e: any, newValue: any = {}) => {
    if (!e.target) return
    setApiData((st: any) => ({ ...st, menuData: { ...st.menuData, RolesList: newValue } }))
  }

  const viewDetails = ({ id: viewID }: any) => {
    setApiData({ menuData: null })

    routeToPage(history, `/Admin/Menus/${viewID ? `Detail/${viewID}` : `New`}`, {
      MenusId_Parent: id,
    })
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  if (!apiData.menuData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { menuData, allMenusData = [], allRollData = [] } = apiData

  if (menuData.RolesList) {
    if (typeof menuData.RolesList === 'string') {
      let RolesList = (menuData.RolesList) ? menuData.RolesList.split(',') : []
      let menuDataRolesList = allRollData.filter((r:any) => { return (RolesList.indexOf(r.Id.toString()) !== -1) });
      menuData.RolesList = menuDataRolesList.map((k:any) => { return { CodeId: k.Id, Description: k.Name } });
    }
  } else {
    menuData.RolesList = [];
  }

  const saveSubMenuData = async () => {
    subMenuData.MenusId = menuData.Id;
    if (subMenuData.Name) {
      try {
        const { message } = await SaveModel({
          body: {
            Model: "SubMenus",
            Params: subMenuData
          },
        })
        toast.success(message)
        setCreateSubMenuMode(false)
      } catch (error: any) {
        toast.error(error)
        // console.error(error)
      }
    }
  }

  return (
    <CFade>
      <CCard style={{ border: 'none' }}>
        <MenusHeader
          modelName={modelName}
          status={status}
          saveData={async () => {
            await saveData()
          }}
          onCancel={() => {
            setApiData({ menuData: null })
            history.goBack()
          }}
        />
        <CCardBody className={'custom-card-body'}>
          <CRow>
            <CCol xs='12' md='8'>
              <DetailTabSection
                id={id}
                Guid={menuData.Guid}
                modelName={modelName}
                tabs={[
                  { name: 'MenuInfo', label: 'Menu Info' },
                  { name: 'SubMenus', label: 'Sub Menus' },
                ]}
                hideNotes
                changesRef={changesRef}
                headers={{
                  SubMenus: 
                    <>
                      { (createSubMenuMode) ? <> <HeaderButton
                      id={id}
                      onClick={() => saveSubMenuData()}
                      label='Save'
                    /> <HeaderButton
                      id={id}
                      className={'ml-2'}
                      onClick={() => setCreateSubMenuMode(false)}
                      label='Cancel'
                    /> </> : <HeaderButton
                    id={id}
                    onClick={() => setCreateSubMenuMode(true)}
                    label='Add Sub Menu'
                  /> }
                  </>
                }}
              >
                <CTabPane data-tab='MenuInfo' className='custom-menu-tab'>
                  <MenuInfo
                    menuData={menuData}
                    handleChange={handleChange}
                    handleRollComboChange={handleRollComboChange}
                    handleComboChange={handleComboChange}
                    allMenusData={allMenusData}
                    allRollData={allRollData}
                  ></MenuInfo>
                </CTabPane>
                <CTabPane data-tab='SubMenus' className='detail-tab-section-pane'>
                  <MenuSubMenus
                    id={id}
                    menuData={menuData}
                    handleSubChange={handleSubChange}
                    subMenuData={subMenuData}
                    createSubMenuMode={createSubMenuMode}
                    onClick={() => viewDetails({ id: 0 })}
                    actionFunctions={actionFunctions}
                    //ref={tableRef}
                  />
                </CTabPane>
              </DetailTabSection>
            </CCol>
            <CCol xs='12' md='4' style={{ marginTop: '0' }}>
              {menuData.Id !== 0 && menuData.MetasId !== null ? (
                <DetailTabSection
                  id={0}
                  metasId={menuData.MetasId}
                  hideNotes
                  tabs={[{ name: 'Notes', label: 'Notes' }]}
                >
                  <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
                    <NotesInfo MetasId={menuData.MetasId}></NotesInfo>
                  </CTabPane>
                </DetailTabSection>
              ) : (
                <></>
              )}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Menu', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CFade>
  )
}

export default MenuDetails
