import React from 'react'
import { DataTable } from 'library-soft-datatable'
import {
  BASE_API,
  // LOOKUP_TYPES_ID,
  LOOKUPS_ID,
  LOOKUPS_MODEL,
  MODELS,
} from 'src/services/routeConst'
import { lookupValueTableColumns } from '../lookupTableColumns'
import { getAuthToken } from 'src/services/authHelper'
interface PropsLookupValuesTable {
  id?: any
  actionFunctions?: any
  lookupsData?: any
}
const LookupValuesTable = React.forwardRef<any, PropsLookupValuesTable>(
  ({ actionFunctions, lookupsData }, ref) => {
    const otherFields = ['OtherField', 'OtherField', 'OtherField', 'OtherField', 'OtherField']
      .map((key, idx) => ({
        fieldIdx: idx + 1,
        key: lookupsData[`${key}${idx + 1}`],
        description: lookupsData[`${key}Desc${idx + 1}`],
      }))
      ?.filter((field) => field.key)
    // console.log({ id, lookupValueTableColumns, otherFields })
    const parsedOtherFields = otherFields.map((field) => ({
      name: `OtherValue${field.fieldIdx}`,
      label: field.description,
    }))
    const adjustedTableColumns = [
      ...lookupValueTableColumns.slice(0, -1),
      ...parsedOtherFields,
      ...lookupValueTableColumns.slice(-1),
    ]
    return (
      <div className='hide-pagination'>
        <DataTable
          columnData={adjustedTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={LOOKUPS_MODEL}
          idName={LOOKUPS_ID}
          // queryParams={{ [LOOKUP_TYPES_ID]: id }}
          actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
          ref={ref}
          rootStyles={{ fontSize: '0.75rem' }}
          apiKey={getAuthToken('ADMIN')}
        />
      </div>
    )
  },
)

export default LookupValuesTable
