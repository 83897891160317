// Generated with util/create-search-page.js
import { CCol, CRow, CTabPane } from '@coreui/react'
import { Loader } from 'src/shared'
import { Calendar, HeaderComponent, InspectorInfo } from './components'
import { INSPECTORS } from 'src/services/routeConst'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'
import Address from './components/Address'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import NotesInfo from './components/NotesInfo'
import InspectorProfilePicture from './components/InspectorProfilePicture'

const ModelsDetails = ({ history, match, modelName = INSPECTORS }) => {
  const { id = 0 } = match.params
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id,
    modelName,
    history,
  })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData } = apiData
  return (
    <>
      <HeaderComponent
        modelName={modelName}
        onCancelHandle={history.goBack}
        status={status}
        saveData={saveData}
      />
      <>
        <CRow>
          <CCol>
            <DetailTabSection
              id={0}
              MetasId={modelData.MetasId}
              Guio={0}
              modelName={''}
              hideNotes
              tabs={[
                { name: 'InspectorInfo', label: 'Inspector Info' },
                { name: 'Profile-Picture', label: 'Profile Picture' },
                { name: 'Address', label: 'Address' },
                { name: 'Calendar', label: 'Calendar' },
              ]}
            >
              <CTabPane data-tab='InspectorInfo' className='detail-tab-section-pane'>
                <InspectorInfo
                  title={'Details'}
                  modelData={modelData}
                  handleChange={handleChange}
                />
              </CTabPane>
              <CTabPane data-tab='Profile-Picture' className='detail-tab-section-pane'>
                {modelData.Id !== 0 && (
                  <CRow>
                    <CCol>
                      <InspectorProfilePicture modelData={modelData} />
                    </CCol>
                  </CRow>
                )}
              </CTabPane>
              <CTabPane data-tab='Address' className='detail-tab-section-pane'>
                <Address modelData={modelData} handleChange={handleChange} />
              </CTabPane>
              <CTabPane data-tab='Calendar' className='detail-tab-section-pane'>
                {modelData.Id !== 0 ? (
                  <Calendar title={'Timeslots'} modelData={modelData} />
                ) : (
                  <></>
                )}
              </CTabPane>
            </DetailTabSection>
          </CCol>
          <CCol xs={5} style={{ marginTop: '0' }}>
            {modelData.Id !== 0 ? (
              <DetailTabSection
                id={0}
                metasId={modelData.MetasId}
                hideNotes
                tabs={[{ name: 'Notes', label: 'Notes' }]}
              >
                <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
                  <NotesInfo MetasId={modelData.MetasId}></NotesInfo>
                </CTabPane>
              </DetailTabSection>
            ) : (
              <></>
            )}
          </CCol>
        </CRow>
      </>
    </>
  )
}

export default ModelsDetails
