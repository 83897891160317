import React from 'react'
import {
  CCard,
  CCardBody,
  CCardHeader,
  CNav,
  CNavItem,
  CNavLink,
  CTabContent,
  CTabPane,
  CTabs,
} from '@coreui/react'
import NotesSection from '../NotesSection'

const DetailTabSection: React.FC<any> = ({
  Guid: ForGuid = '61',
  metasId = 1,
  defaultTab = '',
  headerStyle = { padding: '1.1rem 0.65rem 0 0.65rem' },
  getHeaderStyle,
  tabs = [],
  children,
  hideNotes = false,
  headers = {},
  size = 'sm',
}) => {
  const allTabs = [
    ...tabs,
    !hideNotes ? { name: 'Notes', label: 'Notes' } : {},
    /* !hideChanges ? { name: 'Changelogs', label: 'Change Logs' } : {}, */
  ]
  const [activeTab, setActiveTab] = React.useState<any>(defaultTab ? defaultTab : allTabs[0].name)

  const NotesSectionTS: any = NotesSection
  return (
    <CTabs activeTab={activeTab} onActiveTabChange={(tab: any) => setActiveTab(tab)}>
      <CCard className='border-transparent mb-2'>
        <CCardHeader
          className=''
          style={{
            ...(getHeaderStyle ? getHeaderStyle(activeTab) : headerStyle),
            marginLeft: '1rem',
          }}
        >
          <CNav
            variant='tabs'
            className={`${size === 'md' ? 'align-items-end' : ''} border-transparent border-none`}
          >
            {allTabs.map(({ name, label }, idx) =>
              name ? (
                <CNavItem key={idx}>
                  <CNavLink data-tab={name} className={size === 'md' ? `nav-link-${size}` : ''}>
                    {label}
                  </CNavLink>
                </CNavItem>
              ) : null,
            )}
            {headers[activeTab]}
          </CNav>
        </CCardHeader>
        <CCardBody className='pt-3 px-0 ml-3'>
          <CTabContent>
            {children}
            {!hideNotes ? (
              <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
                <NotesSectionTS Guid={ForGuid} metasId={metasId} />
              </CTabPane>
            ) : null}
          </CTabContent>
        </CCardBody>
      </CCard>
    </CTabs>
  )
}

export default DetailTabSection
