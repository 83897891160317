// import { CCol, CFormGroup } from '@coreui/react'
// import { RadioGroup, Radio } from 'react-radio-input'
// import { AccidentInfo, ElectricalInfo, GeneralInfo, MechInfo, ModsInfo } from './components'

import { DnServicesInfo } from "./components";

// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange: any
  title?: string
}
// const RadioGroupTs: any = RadioGroup
const ServicesInfo = ({ modelData, handleChange }: PropsInfoForm) => {
  // const mapComboToData = () =>
  console.log(modelData);
  
  return (
    <>
      <DnServicesInfo title='Dn Services' modelData={modelData} handleChange={handleChange}/>
    {/* <CFormGroup row>
      <CCol>
        <GeneralInfo title='General Info' modelData={modelData} />
      </CCol>
      <CCol>
        <AccidentInfo title='Accident Info' modelData={modelData} />
      </CCol>
    </CFormGroup>
    <CFormGroup row>
      <CCol>
        <ModsInfo title='Mods Info' modelData={modelData} />
      </CCol>
      <CCol>
        <ElectricalInfo title='Electrical Info' modelData={modelData} />
      </CCol>
    </CFormGroup>
    <CFormGroup row>
      <CCol>
        <MechInfo title='Mech Info' modelData={modelData} />
      </CCol>
    </CFormGroup> */}
    </>
  )
}

export default ServicesInfo