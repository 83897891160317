import CIcon from '@coreui/icons-react'
import {
  CButton,
  CCard,
  CCardBody,
  // CCardFooter,
  CCardHeader,
  CCol,
  CFormGroup,
  CRow,
} from '@coreui/react'
import { ComboBox } from 'src/shared'
import React from 'react'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { findDuplicates, toTitleCase } from 'src/services/util'
import { Loader, InputField } from 'src/shared'
import LoadingButton from 'src/shared/components/LoadingButton'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'
import ConfirmRedirectModal from 'src/shared/modals/ConfirmRedirectModal'
import copy from 'copy-to-clipboard'

// const schemaNames = [
//   {
//     CodeId: 'dbo',
//     Description: 'dbo',
//   },
// ]

const joinTypes: any = [
  {
    CodeId: 'LEFT \tJOIN',
    Description: 'LEFT JOIN',
  },
  {
    CodeId: 'INNER \tJOIN',
    Description: 'INNER JOIN',
  },
]

const whereConditions: any = [
  { CodeId: '=', Description: '=' },
  { CodeId: '<', Description: '<' },
  { CodeId: '>', Description: '>' },
  { CodeId: '<=', Description: '<=' },
  { CodeId: '>=', Description: '>=' },
  { CodeId: 'IN', Description: 'IN' },
  { CodeId: 'IS NULL', Description: 'IS NULL' },
  { CodeId: 'IS NOT NULL', Description: 'IS NOT NULL' },
  { CodeId: 'LIKE', Description: 'LIKE' },
]

const getUsedSelections = (selectionData: any) =>
  Object.keys(selectionData).filter((selection) =>
    selection === 'Joins' || selection === 'Wheres' || selection === 'WhereValue'
      ? selectionData[selection].length
      : !isInvalid(selectionData[selection]),
  )

const procNames: any = {
  databaseNames: 'DatabaseNamesCombo',
  schemaNames: 'SchemaNamesCombo',
  tableNames: 'TableNamesCombo',
  fieldNames: 'TableColumnsCombo',
}

const isInvalid = (value: any) => ['0', 'Select an item', null].includes(value)

const formatFieldName = (fieldName: any) =>
  toTitleCase(fieldName)?.replaceAll('_', '').replaceAll(' ', '')

const parseSelectFields = (SelectFields: any) => {
  const usedFields = SelectFields.length
    ? SelectFields.map((field: any) => field.CodeId?.split('.')[1])
    : []
  const duplicateFields = usedFields.length ? findDuplicates(usedFields) : []
  return SelectFields.map((field: any) => {
    const fieldData = field.CodeId?.split('.')
    const TableNameLower = fieldData[0]?.toLowerCase()
    const FieldName = fieldData[1]
    const isIDField = FieldName.slice(-3) === '_ID'
    const parsedFieldName = isIDField ? 'Id' : FieldName
    const parsedTableName =
      isIDField || TableNameLower.slice(-1) !== 's' ? TableNameLower : TableNameLower.slice(0, -1)
    return duplicateFields.includes(FieldName) || isIDField
      ? `${formatFieldName(parsedTableName)}${formatFieldName(parsedFieldName)} = ${field.CodeId}`
      : field.CodeId
  }).join(', ')
}

const QueryBuilder: React.FC<any> = ({ history }) => {
  const [selectionData, setSelectionData] = React.useState<any>({
    DatabaseName: null,
    SchemaName: null,
    TableName: null,
    SelectFields: [],
    Joins: [],
    Wheres: [],
    // Joins: [{ JoinType: '0', JoinTable: '0', JoinCondition1: '0', JoinCondition2: '0' }],
  })
  const [apiData, setApiData] = React.useState<any>({
    databaseNames: [],
    schemaName: [],
    tableNames: [],
    procTypes: [],
    fieldNames: {},
  })
  const [status, setStatus] = React.useState('loading')
  const [query, setQuery] = React.useState('')
  const [testResults, setTestResults] = React.useState<any>('')

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(
    async ({ type = 'databaseNames', params }) => {
      try {
        const { data } = await rootRepository.exec({
          procName: procNames[type],
          params,
        })
        setApiData((st: any) => ({
          ...st,
          [type]:
            type === 'fieldNames'
              ? {
                  ...st.fieldNames,
                  [params.TableName]: [{ CodeId: '*', Description: '*' }, ...data],
                }
              : [...data],
        }))
        setStatus('idle')
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [rootRepository],
  )

  React.useEffect(() => {
    // getData({ type: 'databaseNames' })
  }, [getData])

  const handleQueryChange = (data: any) => {
    setQuery(data)
  }

  const handleComboChange = (e: any, newValue: any = {}, type: any, nextType: any, params: any) => {
    if (!e.target) return
    const CodeId = newValue ? newValue.CodeId : null
    let stateData: any
    // if (CodeId === '0') {
    if (type === 'DatabaseName') {
      stateData = {
        SchemaName: null,
        TableName: null,
        SelectFields: [],
        Joins: [],
        Wheres: [],
      }
    } else if (type === 'SchemaName') {
      stateData = {
        TableName: null,
        SelectFields: [],
        Joins: [],
        Wheres: [],
      }
    } else if (type === 'TableName') {
      stateData = { SelectFields: [], Joins: [], Wheres: [] }
    }
    // }
    // console.log({ CodeId, type, stateData })
    if (nextType) {
      if (nextType === 'fieldNames' && CodeId) {
        getData({
          type: nextType,
          params: {
            DbName: selectionData.DatabaseName,
            TableName: CodeId,
            SchemaName: selectionData.SchemaName,
          },
        })
      } else if (CodeId) {
        getData({ type: nextType, params })
      }
    }
    if (type.type) {
      if (type.type.includes('Join')) {
        let otherJoinData: any
        if (isInvalid(CodeId)) {
          if (type.type === 'JoinType') {
            otherJoinData = { JoinTable: null, JoinCondition1: null, JoinCondition2: null }
          } else if (type.type === 'JoinTable') {
            otherJoinData = { JoinCondition1: null, JoinCondition2: null }
          } else if (type.type === 'JoinCondition1') {
            otherJoinData = { JoinCondition2: null }
          }
        }
        setSelectionData((st: any) => ({
          ...st,
          Joins: [
            ...st.Joins.map((joinData: any, idx: number) =>
              idx === type.id
                ? { ...joinData, [type.type]: isInvalid(CodeId) ? null : CodeId, ...otherJoinData }
                : idx > type.id
                ? { JoinType: null, JoinTable: null, JoinCondition1: null, JoinCondition2: null }
                : joinData,
            ),
          ],
        }))
      } else if (type.type.includes('Where')) {
        let otherWhereData: any
        // if (CodeId === '0') {
        if (type.type === 'WhereTable') {
          otherWhereData = { WhereField: null, WhereCondition: null, WhereValue: '' }
        } else if (type.type === 'WhereField') {
          otherWhereData = { WhereCondition: null, WhereValue: '' }
        }
        // }
        setSelectionData((st: any) => ({
          ...st,
          Wheres: [
            ...st.Wheres.map((whereData: any, idx: number) =>
              idx === type.id || (type.type === 'WhereTable' && isInvalid(CodeId) && idx >= type.id)
                ? {
                    ...whereData,
                    [type.type]: isInvalid(CodeId) ? null : CodeId,
                    ...otherWhereData,
                  }
                : whereData,
            ),
          ],
        }))
      }
    } else {
      setSelectionData((st: any) => {
        return { ...st, [type]: isInvalid(CodeId) ? null : CodeId, ...stateData }
      })
    }
  }

  const mapSelectionsToValues = React.useCallback(
    (usedSelections, selectionData) =>
      usedSelections.map((selection: any) =>
        selection === 'Joins' || selection === 'Wheres'
          ? {
              [selection]: selectionData[selection].map((data: any) =>
                Object.assign({}, ...mapSelectionsToValues(getUsedSelections(data), data)),
              ),
            }
          : {
              [selection]: selectionData[selection],
            },
      ),
    [],
  )

  React.useEffect(() => {
    const {
      // DatabaseName,
      SchemaName,
      TableName,
      SelectFields = [],
      Joins = [],
      Wheres = [],
    } = Object.assign({}, ...mapSelectionsToValues(getUsedSelections(selectionData), selectionData))
    // console.log({ TableName, Joins })
    // console.table({ TableName, JoinType, JoinTable })
    let query = ''
    let dbAndSchema = ''
    if (TableName) {
      dbAndSchema = `${SchemaName}.`
      query = `SELECT \t${
        SelectFields.length
          ? parseSelectFields(SelectFields)
          : dbAndSchema
          ? `${TableName.toUpperCase()}.*`
          : '*'
      } 
FROM \t${dbAndSchema}${TableName.toUpperCase()}`
    }
    Joins.forEach((joinData: any) => {
      const { JoinType, JoinTable, JoinCondition1, JoinCondition2 } = joinData
      if (JoinType) {
        query = `${query}
${JoinType}`
      }
      if (JoinTable) {
        query = `${query} ${dbAndSchema}${JoinTable.toUpperCase()}`
      }
      if (JoinCondition1 && !isInvalid(JoinCondition1)) {
        query = `${query} ON ${JoinCondition1}`
      }
      if (JoinCondition2 && !isInvalid(JoinCondition2)) {
        query = `${query} = ${JoinCondition2}`
      }
    })
    Wheres.forEach((whereData: any, idx: number) => {
      const { WhereTable, WhereField, WhereCondition, WhereValue = '' } = whereData
      if (WhereTable) {
        query = `${query} 
${idx === 0 ? `WHERE` : `AND `} \t`
      }
      if (WhereField && !isInvalid(WhereField)) {
        query = `${query}${WhereField}`
      }
      if (WhereCondition && !isInvalid(WhereCondition)) {
        query = `${query} ${WhereCondition}`
      }
      if (WhereValue) {
        query = `${query} ${WhereValue}`
      }
      // if (idx > 0 && WhereTable) {
      //   query = `${query}`
      // }
    })

    setQuery(query)
  }, [mapSelectionsToValues, selectionData])

  const buildQuery = () => {
    setStatus('building')
    // console.log(selectionData)
    const data = {
      DatabaseName: selectionData.DatabaseName,
      SchemaName: selectionData.SchemaName,
      TableName: selectionData.TableName,
    }
    console.log(data, query)
    setTimeout(() => {
      // console.log('BUILT')
      setStatus('idle')
    }, 1000)
  }

  const testQuery = async () => {
    setStatus('testing')
    try {
      const { data } = await rootRepository.sql({ query })
      setTestResults(data)
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const addJoin = () => {
    setSelectionData((st: any) => ({
      ...st,
      Joins: [
        ...st.Joins,
        { JoinType: null, JoinTable: null, JoinCondition1: null, JoinCondition2: null },
      ],
    }))
  }

  const addWhere = () => {
    setSelectionData((st: any) => ({
      ...st,
      Wheres: [
        ...st.Wheres,
        { WhereTable: null, WhereField: null, WhereCondition: null, WhereValue: '' },
      ],
    }))
  }

  const removeJoin = (id: any) => {
    setSelectionData((st: any) => ({
      ...st,
      Joins: [...st.Joins.filter((_: any, idx: number) => idx !== id)],
    }))
  }

  const removeWhere = (id: any) => {
    setSelectionData((st: any) => ({
      ...st,
      Wheres: [...st.Wheres.filter((_: any, idx: number) => idx !== id)],
    }))
  }

  const resetFields = () => {
    setSelectionData({
      DatabaseName: null,
      SchemaName: null,
      TableName: null,
      SelectFields: [],
      Joins: [],
      Wheres: [],
    })
    setTestResults('')
    setApiData((st: any) => ({ ...st, tableNames: [], procTypes: [], fieldNames: [] }))
  }

  const handleWhereValueChange = ({ name, value, id }: any) =>
    setSelectionData((st: any) => ({
      ...st,
      Wheres: [
        ...st.Wheres.map((whereData: any, idx: number) =>
          idx === id ? { ...whereData, [name.split('-')[0]]: String(value) } : whereData,
        ),
      ],
    }))

  const handleSelectFieldsChange = (_: any, newValue: any) => {
    setSelectionData((st: any) => ({
      ...st,
      SelectFields: newValue.filter((value: any) => !isInvalid(value.CodeId)),
    }))
  }

  const getSelectFields = () => {
    const usedTables = tableNames.filter((tableNameData: any) =>
      [...JoinTables, selectionData.TableName].includes(tableNameData.CodeId),
    )
    const combinedFieldNames = usedTables
      .map((table: any) => {
        const fields = fieldNames[table.CodeId] || []
        return fields.map((field: any) => ({
          ...field,
          CodeId: isInvalid(field.CodeId) ? field.CodeId : `${table.CodeId}.${field.CodeId}`,
        }))
      })
      .reduce((a: any, b: any) => a.concat(b), [])
    return combinedFieldNames
  }

  if (!apiData.databaseNames || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { databaseNames, schemaNames = [], tableNames = [], fieldNames = {} } = apiData

  const buildDisabled =
    status === 'building' || status === 'testing' || isInvalid(selectionData.TableName)
  const isBuilding = status === 'building'
  const isTesting = status === 'testing'
  const JoinTables = selectionData.Joins.filter((Join: any) => !isInvalid(Join.JoinTable)).map(
    (Join: any) => Join.JoinTable,
  )
  const selectFields = getSelectFields()
  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        Query Builder
        <CButton className='ml-2' type='button' color='secondary' onClick={resetFields}>
          Reset
        </CButton>
        <CButton
          className='ml-2'
          type='button'
          color='secondary'
          onClick={() => {
            copy(query)
          }}
        >
          Copy
        </CButton>
        <LoadingButton
          className='mr-1 ml-auto'
          style={{ float: 'right' }}
          disabled={buildDisabled}
          loading={isTesting}
          onClick={testQuery}
          color='info'
        >
          Test
        </LoadingButton>
        <LoadingButton
          className='mr-1'
          style={{ float: 'right' }}
          disabled={buildDisabled}
          loading={isBuilding}
          onClick={buildQuery}
        >
          Save
        </LoadingButton>
        <CButton
          className='mr-2'
          type='button'
          color='secondary'
          style={{ float: 'right' }}
          onClick={history.goBack}
        >
          Cancel
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol xs='12' md='6'>
            <CFormGroup row>
              <CCol xs='12'>
                <ComboBox
                  showButton
                  name='DatabaseName'
                  label='Database Name'
                  data={databaseNames}
                  value={databaseNames.find(
                    (dbNameData: any) => dbNameData.CodeId === selectionData['DatabaseName'],
                  )}
                  handleChange={(e: any, newValue: any = {}) =>
                    handleComboChange(e, newValue, 'DatabaseName', 'schemaNames', {
                      DbName: newValue.CodeId,
                    })
                  }
                  classNames={{ input: 'input-field' }}
                  customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                  renderOption={(option: any, { selected }: any) => (
                    <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
                  )}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs='12'>
                <ComboBox
                  showButton
                  name='SchemaName'
                  label='Schema Name'
                  data={schemaNames}
                  value={schemaNames.find(
                    (schemaNameData: any) => schemaNameData.CodeId === selectionData['SchemaName'],
                  )}
                  handleChange={(e: any, newValue: any = {}) =>
                    handleComboChange(e, newValue, 'SchemaName', 'tableNames', {
                      DbName: selectionData['DatabaseName'],
                      SchemaName: newValue.CodeId,
                    })
                  }
                  classNames={{ input: 'input-field' }}
                  customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                  renderOption={(option: any, { selected }: any) => (
                    <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
                  )}
                  disabled={isInvalid(selectionData.DatabaseName)}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs='12'>
                <ComboBox
                  showButton
                  name='TableName'
                  label='Table Name'
                  data={tableNames}
                  value={tableNames.find(
                    (tableNameData: any) => tableNameData.CodeId === selectionData['TableName'],
                  )}
                  handleChange={(e: any, newValue = {}) =>
                    handleComboChange(e, newValue, 'TableName', 'fieldNames', null)
                  }
                  classNames={{ input: 'input-field' }}
                  customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                  renderOption={(option: any, { selected }: any) => (
                    <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
                  )}
                  disabled={isInvalid(selectionData.SchemaName)}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs='12'>
                <ComboBox
                  showButton
                  multiple
                  name='SelectFields'
                  label='Select Fields'
                  data={selectFields}
                  value={selectionData.SelectFields}
                  optionLabel={(option: any) =>
                    option.CodeId ? String(option.CodeId) : option.Description
                  }
                  handleChange={(e: any, newValue = {}) => handleSelectFieldsChange(e, newValue)}
                  classNames={{ input: 'input-field' }}
                  customClassNames={{ button: 'comboBoxInput', root: 'input-field-root pt-3' }}
                  renderOption={(option: any, { selected }: any) => (
                    <div className={selected ? 'combo-selected' : ''}>{option.CodeId}</div>
                  )}
                  disabled={isInvalid(selectionData.TableName)}
                />
              </CCol>
            </CFormGroup>
            {selectionData.Joins.length
              ? selectionData.Joins.map((Join: any, idx: number) => (
                  <React.Fragment key={idx}>
                    <div className='mb-1 d-flex align-items-center'>
                      <h6 className='mb-0'>Join {idx + 1} </h6>
                      <CIcon
                        className='ml-2 query-builder-trash-icon'
                        name='cil-trash'
                        onClick={() => removeJoin(idx)}
                      />
                    </div>
                    <CFormGroup row className='mb-1 mb-lg-2'>
                      <CCol xs='12' lg='6' className='pr-lg-1 mb-1 my-lg-0'>
                        <ComboBox
                          showButton
                          name={`JoinType-${idx}`}
                          label='Join Type'
                          data={joinTypes}
                          value={joinTypes.find(
                            (joinTypeData: any) => joinTypeData.CodeId === Join['JoinType'],
                          )}
                          handleChange={(e: any, newValue = {}) =>
                            handleComboChange(
                              e,
                              newValue,
                              { id: idx, type: 'JoinType' },
                              null,
                              null,
                            )
                          }
                          classNames={{ input: 'input-field' }}
                          customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                          renderOption={(option: any, { selected }: any) => (
                            <div className={selected ? 'combo-selected' : ''}>
                              {option.Description}
                            </div>
                          )}
                          disabled={
                            idx > 0
                              ? isInvalid(selectionData.Joins[idx - 1].JoinCondition2)
                              : isInvalid(selectionData.TableName)
                          }
                        />
                      </CCol>
                      <CCol xs='12' lg='6' className='pl-lg-1 my-1 my-lg-0'>
                        <ComboBox
                          showButton
                          name={`JoinTable-${idx}`}
                          label='Join To Table'
                          data={tableNames}
                          value={tableNames.find(
                            (tableNameData: any) => tableNameData.CodeId === Join['JoinTable'],
                          )}
                          handleChange={(e: any, newValue = {}) =>
                            handleComboChange(
                              e,
                              newValue,
                              { id: idx, type: 'JoinTable' },
                              'fieldNames',
                              null,
                            )
                          }
                          classNames={{ input: 'input-field' }}
                          customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                          renderOption={(option: any, { selected }: any) => (
                            <div className={selected ? 'combo-selected' : ''}>
                              {option.Description}
                            </div>
                          )}
                          disabled={isInvalid(Join.JoinType)}
                        />
                      </CCol>
                    </CFormGroup>
                    <CFormGroup row>
                      <CCol xs='12' lg='6' className='pr-lg-1 mb-1 my-lg-0'>
                        <ComboBox
                          showButton
                          name={`JoinCondition1-${idx}`}
                          label='Join On'
                          data={selectFields?.filter(
                            (fieldNameData: any) => fieldNameData.Description !== '*' ?? [],
                          )}
                          optionLabel={(option: any) =>
                            option.CodeId ? String(option.CodeId) : option.Description
                          }
                          value={selectFields?.find(
                            (fieldNameData: any) => fieldNameData.CodeId === Join['JoinCondition1'],
                          )}
                          handleChange={(e: any, newValue = {}) =>
                            handleComboChange(
                              e,
                              newValue,
                              { id: idx, type: 'JoinCondition1' },
                              null,
                              null,
                            )
                          }
                          classNames={{ input: 'input-field' }}
                          customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                          renderOption={(option: any, { selected }: any) => (
                            <div className={selected ? 'combo-selected' : ''}>
                              <strong>{option.CodeId}</strong> - [{option.Description}]
                            </div>
                          )}
                          disabled={isInvalid(Join.JoinTable)}
                        />
                      </CCol>
                      <CCol xs='12' lg='6' className='pl-lg-1 my-1 my-lg-0'>
                        <ComboBox
                          showButton
                          name={`JoinCondition2-${idx}`}
                          label='Join On'
                          data={selectFields?.filter(
                            (fieldNameData: any) => fieldNameData.Description !== '*' ?? [],
                          )}
                          optionLabel={(option: any) =>
                            option.CodeId ? String(option.CodeId) : option.Description
                          }
                          value={selectFields?.find(
                            (fieldNameData: any) => fieldNameData.CodeId === Join['JoinCondition2'],
                          )}
                          handleChange={(e: any, newValue = {}) =>
                            handleComboChange(
                              e,
                              newValue,
                              { id: idx, type: 'JoinCondition2' },
                              null,
                              null,
                            )
                          }
                          classNames={{ input: 'input-field' }}
                          customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                          renderOption={(option: any, { selected }: any) => (
                            <div className={selected ? 'combo-selected' : ''}>
                              <strong>{option.CodeId}</strong> - [{option.Description}]
                            </div>
                          )}
                          disabled={isInvalid(Join.JoinCondition1)}
                        />
                      </CCol>
                    </CFormGroup>
                  </React.Fragment>
                ))
              : null}
            <CFormGroup row>
              <CCol>
                <CButton
                  type='button'
                  color='success'
                  style={{ width: 100 }}
                  onClick={addJoin}
                  disabled={isInvalid(selectionData.TableName)}
                >
                  Add Join
                </CButton>
              </CCol>
            </CFormGroup>
            <hr className='my-2 mx-5' />
            {selectionData.Wheres.length
              ? selectionData.Wheres.map((Where: any, idx: number) => (
                  <React.Fragment key={idx}>
                    <div className='mb-1 d-flex align-items-center'>
                      <h6 className='mb-0'>Where {idx + 1} </h6>
                      <CIcon
                        className='ml-2 query-builder-trash-icon'
                        name='cil-trash'
                        onClick={() => removeWhere(idx)}
                      />
                    </div>
                    <CFormGroup row className='mb-1 mb-lg-2'>
                      <CCol xs='12' lg='6' className='pr-lg-1 mb-1 my-lg-0'>
                        <ComboBox
                          showButton
                          name={`WhereTable-${idx}`}
                          label='Table'
                          data={tableNames.filter((tableNameData: any) =>
                            [...JoinTables, selectionData.TableName].includes(tableNameData.CodeId),
                          )}
                          value={tableNames.find(
                            (tableNameData: any) => tableNameData.CodeId === Where['WhereTable'],
                          )}
                          handleChange={(e: any, newValue = {}) =>
                            handleComboChange(
                              e,
                              newValue,
                              { id: idx, type: 'WhereTable' },
                              'fieldNames',
                              null,
                            )
                          }
                          classNames={{ input: 'input-field' }}
                          customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                          renderOption={(option: any, { selected }: any) => (
                            <div className={selected ? 'combo-selected' : ''}>
                              {option.Description}
                            </div>
                          )}
                          disabled={
                            idx > 0
                              ? isInvalid(selectionData.Wheres[idx - 1].WhereTable)
                              : isInvalid(selectionData.TableName)
                          }
                        />
                      </CCol>
                      <CCol xs='12' lg='6' className='pl-lg-1 my-1 my-lg-0'>
                        <ComboBox
                          showButton
                          name={`WhereField-${idx}`}
                          label='Field'
                          data={selectFields?.filter(
                            (fieldNameData: any) => fieldNameData.Description !== '*' ?? [],
                          )}
                          optionLabel={(option: any) =>
                            option.CodeId ? String(option.CodeId) : option.Description
                          }
                          value={selectFields?.find(
                            (fieldNameData: any) => fieldNameData.CodeId === Where['WhereField'],
                          )}
                          handleChange={(e: any, newValue = {}) =>
                            handleComboChange(
                              e,
                              newValue,
                              { id: idx, type: 'WhereField' },
                              null,
                              null,
                            )
                          }
                          classNames={{ input: 'input-field' }}
                          customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                          renderOption={(option: any, { selected }: any) => (
                            <div className={selected ? 'combo-selected' : ''}>
                              <strong>{option.CodeId}</strong> - [{option.Description}]
                            </div>
                          )}
                          disabled={isInvalid(Where.WhereTable)}
                        />
                      </CCol>
                    </CFormGroup>
                    <CFormGroup row>
                      <CCol xs='12' lg='6' className='pr-lg-1 mb-1 my-lg-0'>
                        <ComboBox
                          showButton
                          name={`WhereCondition-${idx}`}
                          label='Condition'
                          data={whereConditions}
                          value={whereConditions.find(
                            (whereConditionData: any) =>
                              whereConditionData.CodeId === Where['WhereCondition'],
                          )}
                          handleChange={(e: any, newValue = {}) =>
                            handleComboChange(
                              e,
                              newValue,
                              { id: idx, type: 'WhereCondition' },
                              null,
                              null,
                            )
                          }
                          classNames={{ input: 'input-field' }}
                          customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                          renderOption={(option: any, { selected }: any) => (
                            <div className={selected ? 'combo-selected' : ''}>
                              {option.Description}
                            </div>
                          )}
                          disabled={isInvalid(Where.WhereField)}
                        />
                      </CCol>
                      <CCol xs='12' lg='6' className='pl-lg-1 my-1 my-lg-0'>
                        <InputField
                          name={`WhereValue-${idx}`}
                          type='text'
                          label='Value'
                          value={Where['WhereValue']}
                          onChange={({ name, value }: any) =>
                            handleWhereValueChange({ name, value, id: idx })
                          }
                          disabled={isInvalid(Where.WhereCondition)}
                        />
                      </CCol>
                    </CFormGroup>
                  </React.Fragment>
                ))
              : null}

            <CFormGroup row>
              <CCol>
                <CButton
                  type='button'
                  color='success'
                  style={{ width: 100 }}
                  onClick={addWhere}
                  disabled={isInvalid(selectionData.TableName)}
                >
                  Add Where
                </CButton>
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol>
            <CFormGroup row>
              <CCol xs='12'>
                <CustomCodeEditor
                  name='Query'
                  theme='sqlserver'
                  value={query}
                  onChange={handleQueryChange}
                />
              </CCol>
            </CFormGroup>
            {testResults?.length ? (
              <CRow>
                <CCol>
                  <h4>Query Results:</h4>
                  {/* <pre style={{ color: '#e0e0e0' }}>{JSON.stringify(testResults, null, 2)}</pre> */}
                  <pre style={{ color: '#e0e0e0' }}>
                    <code>
                      {'['}
                      {testResults.length ? (
                        testResults.map((result: any, idx: number) => {
                          const resultLength = Object.keys(result).length
                          return (
                            <span key={idx} className='ml-3 d-block'>
                              {'{'}
                              {Object.keys(result).map((key, idx2) => {
                                const value = result[key] === '' ? null : result[key]
                                const valueType = !value ? null : typeof value
                                return (
                                  <span key={idx2} className='d-block'>
                                    <span className='ml-3' style={{ color: '#89DDFF' }}>
                                      "{key}"<span style={{ color: '#e0e0e0' }}>:</span>
                                    </span>
                                    <span>
                                      {' '}
                                      <span
                                        style={
                                          [null, 'number', 'boolean'].includes(valueType)
                                            ? { color: '#F78C6C' }
                                            : { color: '#C3E88D' }
                                        }
                                      >
                                        {[null, 'number', 'boolean'].includes(valueType)
                                          ? String(value)
                                          : `"${value}"`}
                                      </span>
                                      {idx2 !== resultLength - 1 ? ',' : ''}
                                    </span>
                                  </span>
                                )
                              })}
                              {idx !== testResults.length - 1 ? '},' : '}'}
                            </span>
                          )
                        })
                      ) : (
                        <pre style={{ color: '#e0e0e0' }}>
                          {JSON.stringify(testResults, null, 2)}
                        </pre>
                      )}
                      {']'}
                    </code>
                  </pre>
                </CCol>
              </CRow>
            ) : testResults ? (
              <h4>No Results!</h4>
            ) : null}
          </CCol>
        </CRow>
      </CCardBody>
      {/* <CCardFooter>
        <CButton type='button' color='primary' block disabled={buildDisabled} onClick={buildQuery}>
          Build Query
        </CButton>
      </CCardFooter> */}
      <ConfirmRedirectModal data={selectionData?.TableName} />
    </CCard>
  )
}
// SELECT 	USERS.USERS_ID, USERS.UserName, USERS.FirstName, USERS.LastName, ROLES.Name, FILES.FileUrl
// FROM 	USERS
// LEFT 	JOIN ROLES ON ROLES.ROLES_ID = USERS.ROLES_ID
// LEFT 	JOIN FILES ON FILES.FILES_ID = USERS.FILES_ID_ProfilePicture
// WHERE 	USERS.IsDeleted = 0
export default QueryBuilder

// SELECT * FROM ${tableName}
// ${joinType} ${joinTable} ${joinCondition}
