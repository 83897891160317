import { CButton, CCardHeader } from '@coreui/react'
import React from 'react'
import LoadingButton from 'src/shared/components/LoadingButton'
interface PropsLookupsHeader {
  id?: any
  status?: any
  generateView?: any
  saveData?: any
  onClickCancel?: any
}
const LookupsHeader: React.FC<PropsLookupsHeader> = ({
  id,
  status,
  generateView,
  saveData,
  onClickCancel,
}) => {
  const isLoading = status === 'saving'
  const isGenerating = status === 'generating'
  return (
    <CCardHeader className='d-flex align-items-center' style={{ backgroundColor: '#2A3446', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}>
      <span style={{ color: 'white' }}>Lookup Type Details</span>
      
      {/* <CButton
        className='mr-1 ml-auto'
        type='button'
        color='primary'
        style={{ float: 'right' }}
        disabled={savingDataRef.current}
        onClick={saveData}
      >
        Save
      </CButton> */}
      {id ? (
        <LoadingButton
          color='info'
          className='mr-1 ml-auto'
          style={{ float: 'right' }}
          loading={isGenerating}
          onClick={generateView}
        >
          Generate View
        </LoadingButton>
      ) : null}
      <LoadingButton
        className={`mr-1 ${!id ? 'ml-auto' : ''}`}
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton>
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onClickCancel}
        /* () => {
          setApiData({ lookupData: null, lookupValues: [] })
          history.goBack()
        } */
      >
        Cancel
      </CButton>
    </CCardHeader>
  )
}

export default LookupsHeader
