import { CCol, CFormGroup } from '@coreui/react'
import { ComboBox } from 'src/shared'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsModelsInfo {
  modelData?: any
  conectionsData?: any
  handleChange?: any
  handleComboChange?: any
}
const ModelsInfo: React.FC<PropsModelsInfo> = ({ modelData, conectionsData, handleChange }) => {
  return (
    <FieldsSection header='Model Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={modelData.Name}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='ShortName'
            type='text'
            label='Short Name (For Table Unions)'
            value={modelData.ShortName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12'>
          <ComboBox
            showButton
            name='ConnectionsId'
            label='Conections'
            data={conectionsData}
            value={conectionsData.find((e: any) => e.CodeId === modelData['ConnectionsId'])}
            handleChange={(e: any, newValue: any = {}) => {
              handleChange({ name: 'ConnectionsId', value: newValue.CodeId, e: e })
            }}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
            )}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='DatabaseName'
            type='text'
            label='DatabaseName'
            value={modelData.DatabaseName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='SchemaName'
            type='text'
            label='Schema Name'
            value={modelData.SchemaName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='TableViewOrProcedure'
            type='text'
            label='Table View O rProcedure'
            value={modelData.TableViewOrProcedure}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='IsChangeTracking'
            type='fancyswitch'
            label='Is Change Tracking'
            labelStyles={{ marginBottom: 0 }}
          >
            <CustomSwitch
              name='IsChangeTracking'
              className='d-block'
              value={modelData.IsChangeTracking}
              updateData={(e, value) => {
                console.log(value)
                handleChange({
                  name: e,
                  value,
                })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default ModelsInfo
