import { FieldsSection } from 'src/shared'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'

interface PropsQuery {
  modelData?: any
  handleChange?: any
}
const ProcQuery = ({ modelData, handleChange }: PropsQuery) => {
  return (
    <FieldsSection header='Procedure Script'>
      <CustomCodeEditor
        value={modelData?.Script ?? ''}
        mode='sqlserver'
        onChange={(e) => {
          handleChange({ name: 'Script', value: e })
        }}
        theme='sqlserver'
      ></CustomCodeEditor>
    </FieldsSection>
  )
}

export default ProcQuery
