// Generated with util/create-search-page.js
import { CCardBody, CCol, CRow } from '@coreui/react'
import { Loader } from 'src/shared'
import { HeaderComponent, TabsLeadInformation } from './components'
import { LEADS_MODELS } from 'src/services/routeConst'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'
import { createContext, useState } from 'react'
import Breadcrumb from '../Workflow/Workflow/components/Bread'
import LeadAssignedCard from './components/LeadAssignedCard'
// import InspectorProfilePicture from './components/InspectorProfilePicture'

export const LeadContext = createContext<{
  LeadsId: number
  MetasId: number
}>({
  LeadsId: 0,
  MetasId: 0,
})

const ModelsDetails = ({ history, match, modelName = LEADS_MODELS }) => {
  const { id = 0 } = match.params
  const [reloadNote, setReloadNote] = useState(true);
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id,
    modelName,
    history,
  })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData } = apiData

  const actionClick = () => {
    setReloadNote(false);
    setTimeout(() => {
      setReloadNote(true);
    }, 500)
  }

  return (
    <LeadContext.Provider
      value={{
        LeadsId: id,
        MetasId: modelData?.MetasId ?? 0,
      }}
    >
      <>
        <HeaderComponent
          modelName={modelName}
          onCancelHandle={history.goBack}
          status={status}
          saveData={saveData}
          modelData={modelData}
          handleChange={handleChange}
        />
        <>
          <CCardBody className={'custom-card-body'}>
            <CRow>
              <CCol xs={8}>
                <Breadcrumb MetasId={modelData?.MetasId} isCalled={true} CustomersId={id} actionClick={actionClick} BreadcrumbUrl={'https://api.softsmartinc.com/Services/vOld/Widget/Breadcrumbs/Initialize'}></Breadcrumb>
              </CCol>
              <CCol xs={4}>
                <LeadAssignedCard
                  modelData={modelData}
                />
              </CCol>
            </CRow>
            <CRow>
              <CCol xs={12}>
                <TabsLeadInformation
                  modelData={modelData}
                  // modelDataVehicle={modelDataVehicle}
                  // modelDataDispatch={modelDataDispatch}
                  handleChange={handleChange}
                  reloadNote={reloadNote}
                // handleChangeMaxLength={handleChangeMaxLength}
                // handleAddressChange={handleAddressChange}
                />
              </CCol>
            </CRow>
          </CCardBody>
        </>
      </>
    </LeadContext.Provider>
  )
}

export default ModelsDetails
