import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import React, { useRef, useState } from 'react'
import { routeToPage } from 'src/services/routingHelper'
import { tableColumns } from './Columns'
import { BASE_API, APPS_MODEL, SERVICES, MODELS, LIST } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'
interface PropsApplicationsSearch {
  history?: any
  modelName?: any
}

const ApplicationsSearch: React.FC<PropsApplicationsSearch> = ({
  history,
  modelName = APPS_MODEL,
}) => {
  const [, setShowDeleteModal] = useState(false)
  const clickedIDRef = useRef()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Admin/Applications/Detail/${id}`)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        {modelName} Search
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, `/Admin/Applications/New`)}
        >
          New Application
        </CButton>
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={tableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={modelName}
          scopedSlots={{
            Name: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            UserName: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas', color: '#F2AB26' }}>{value}</div>
            },
            Password: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
          }}
          actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
        />
      </CCardBody>
    </CCard>
  )
}

export default ApplicationsSearch
