import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'

const MemberDetailsSection = ({
  allMembersData,
  memberData,
  handleChange,
  handleComboChange,
  handleSwitchChange,
}) => {
  const { IsSubAccount } = memberData
  return (
    <div>
      <FieldsSection header='Member Details'>
        <CFormGroup row>
          <CCol xs='12'>
            <InputField
              name='IsSubAccount'
              type='fancyswitch'
              labelStyles={{ marginBottom: 0 }}
              label='Is SubAccount'
            >
              <CustomSwitch
                name='IsSubAccount'
                className='d-block'
                value={IsSubAccount}
                updateData={handleSwitchChange}
              />
            </InputField>
          </CCol>
        </CFormGroup>
      </FieldsSection>
    </div>
  )
}

export default React.memo(MemberDetailsSection)
