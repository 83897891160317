import { DataTable } from 'library-soft-datatable'
import { BASE_API } from 'src/services/routeConst'
import { modelsTableColumns } from '../../modelsTableColumns'

const ContactsTab = () => {
  return (
    <DataTable
      columnData={modelsTableColumns}
      theme='dark'
      noHeader
      endpoint={`${BASE_API}/Models`}
      modelName={'Models'}
      idName={'Id'}
      apiKey={''}
    />
  )
}

export default ContactsTab
