import { ColumnData } from 'library-soft-datatable'

export const TableColumns: ColumnData[] = [
  /* "BanksId": 1,
            "Name": "test",
            "Address": "testing",
            "City": "New York",
            "State": "test",
            "ZipCode": "0000",
            "Phone": "000000000",
            "RoutingNumber": "testing123",
            "AccountType": "test", */
  {
    label: 'Schema Name',
    name: 'SchemaName',
  },
  {
    label: 'Database Name',
    name: 'DatabaseName',
  },

  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
