import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormGroup } from '@coreui/react'
import React, { useRef } from 'react'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { ALERTIMAGEDOWNLOAD, TEMPLATES_MODEL } from 'src/services/routeConst'
import { InputField, Loader } from 'src/shared'
// import { Loader } from 'src/shared'
import LoadingButton from 'src/shared/components/LoadingButton'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'
import ConfirmRedirectModal from 'src/shared/modals/ConfirmRedirectModal'
import ModalSelectImage from './Modal/ModalSelectImage'
import './TemplateEditor.css'
enum StatusCreate {
  isNew = 'isNew',
  isCreate = 'isCreate',
}
const TemplateEdit: React.FC<any> = ({ history, match, modelName = TEMPLATES_MODEL }) => {
  const [apiData, setApiData] = React.useState<any>({ templateData: {} })
  const [status, setStatus] = React.useState('loading')
  const [show, setShow] = React.useState(false)
  const editorRef = useRef<any>()
  const [statusCreate, setStatusCreate] = React.useState<StatusCreate>(StatusCreate.isNew)
  // const emailEditorRef = useRef(null)

  const { id = 0 } = match.params
  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(async () => {
    try {
      const { mainData }: any = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { TemplatesId: 0 },
      })
      setStatusCreate(StatusCreate.isCreate)
      if (mainData.TemplatesId === 0) {
        setStatusCreate(StatusCreate.isNew)
      }
      setApiData({ templateData: mainData })
      // mainData.Json &&
      //   emailEditorRef.current.editor.loadDesign(JSON.parse(mainData.Json))
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }, [id, modelName, rootRepository])

  React.useEffect(() => {
    getData()
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ templateData: null })
      history.goBack()
    }, delay)
  }

  const saveTemplate = async () => {
    setStatus('saving')
    try {
      // emailEditorRef.current.exportHtml(async (data) => {
      // const { html, design } = data
      const { data } = await rootRepository.saveData({
        modelName,
        idName: 'TemplatesId',
        dataToSave: {
          TemplatesId: id,
          Name: apiData.templateData.Name,
          // Json: JSON.stringify(design),
          Html: apiData.templateData.Html,
        },
        getData,
        goBack,
      })
      if (statusCreate === StatusCreate.isNew) {
        addMetasAndUpdateModel(Number(data.replace(/^\D+/g, '')))
      }
      if (statusCreate === StatusCreate.isCreate) {
        if (
          typeof apiData.templateData.MetasId === 'undefined' ||
          apiData.templateData.MetasId === null
        ) {
          addMetasAndUpdateModel(Number(data.replace(/^\D+/g, '')))
        }
      }
      // })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  // * Return MetasId
  const addMetasAndUpdateModel = async (idModel) => {
    //* create meta

    return Number(idModel.replace(/^\D+/g, ''))
  }

  const handleNameChange = ({ value = '' }) => {
    setApiData((st: any) => ({ templateData: { ...st.templateData, Name: value } }))
  }

  const handleHTMLChange = (data: any) => {
    setApiData((st: any) => ({ templateData: { ...st.templateData, Html: data } }))
  }

  // const onLoad = () => {
  //   emailEditorRef?.current?.editor?.loadDesign(JSON.parse(apiData.templateData.Json))
  // }

  // const selectImage = () => {}

  if (!apiData.templateData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        Template editor {statusCreate}
        <LoadingButton
          className='mr-1 ml-auto'
          style={{ float: 'right' }}
          loading={status === 'saving'}
          onClick={saveTemplate}
        >
          Save
        </LoadingButton>
        <CButton
          className='mr-2'
          type='button'
          color='secondary'
          style={{ float: 'right' }}
          onClick={history.goBack}
        >
          Cancel
        </CButton>
      </CCardHeader>
      <CCardBody>
        {/* <pre>{JSON.stringify(apiData, null, 2)}</pre> */}
        {show === true ? 'true' : 'false'}
        <ModalSelectImage
          show={show}
          metasId={apiData.templateData.MetasId}
          typeFile='any'
          onClose={() => {
            setShow(() => {
              return false
            })
          }}
          onSelectImage={({ FilesId }) => {
            console.log(FilesId, 'on select images')
            editorRef?.current?.insertInCurrentCursorPosition(`${ALERTIMAGEDOWNLOAD}/${FilesId}`)
          }}
        />
        <CFormGroup row>
          <CCol xs='12' sm='6'>
            <InputField
              name='Name'
              type='text'
              label='Template Name'
              value={apiData.templateData.Name}
              onChange={handleNameChange}
            />
          </CCol>
          {statusCreate === StatusCreate.isCreate && (
            <>
              <CCol>
                <CButton
                  className='mr-2'
                  type='button'
                  color='primary'
                  onClick={() => setShow((value) => !value)}
                >
                  Select image
                </CButton>
              </CCol>
            </>
          )}
        </CFormGroup>

        <CustomCodeEditor
          name='Html'
          mode='html'
          ref={editorRef}
          value={apiData.templateData.Html}
          onChange={handleHTMLChange}
        />
        {/* <EmailEditor
          style={{ minHeight: '600px', height: '75vh' }}
          ref={emailEditorRef}
          // onLoad={onLoad}
        /> */}
      </CCardBody>
      <ConfirmRedirectModal data={apiData?.templateData?.Html ?? ''} />
    </CCard>
  )
}

export default TemplateEdit
