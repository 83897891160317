import { CModal, CModalBody, CModalHeader, CButton, CRow, CCol } from '@coreui/react'
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { ValidateFile } from './helpers'
import './PdfViewer.css'
import { Document, Page, pdfjs } from 'react-pdf'
import { toast } from 'react-toastify'
import UploadIcon from 'src/assets/icons/UploadIcon'
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
// * maxSizeMib the maximum weight is in megabits
interface PropsPdfViewer {
  maxSizeMib?: number
  onSavePdf?: (value: any) => void
  label?: string
  showButton?: boolean
}
const PdfViewer = forwardRef<{ open: VoidFunction }, PropsPdfViewer>(
  ({ maxSizeMib = 5, onSavePdf = () => {}, label = 'Upload PDF', showButton = false }, ref) => {
    const refInputPdfUpload = useRef<any>()
    const pdfViewerRef = useRef<any>()
    const [showModal, setShowModal] = useState(false)
    const [fileModal, setFileModal] = useState<any>()
    const openModal = () => {
      const clickEvent = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: false,
      })
      refInputPdfUpload.current.dispatchEvent(clickEvent)
    }
    const [numPages, setNumPages] = useState<any>(null)
    const [pageNumber, setPageNumber] = useState(1)
    function changePage(offset: any) {
      setPageNumber((prevPageNumber) => prevPageNumber + offset)
    }

    const closeModal = () => {
      setShowModal((modal) => !modal)
    }

    React.useEffect(() => {
      !showModal && setFileModal(null)
    }, [showModal])

    function previousPage() {
      changePage(-1)
    }

    function nextPage() {
      changePage(1)
    }

    function onDocumentLoadSuccess({ numPages }: any) {
      setNumPages(numPages)
    }
    const handleChangeInputUpload = (e: any) => {
      try {
        const [file] = e.target.files
        const { file: pdfFile } = new ValidateFile({ file: file, maxSize: maxSizeMib })
        setFileModal(pdfFile)
        setShowModal((value) => !value)
      } catch (error: any) {
        toast.error(error.message)
      }
    }
    const existFile = (file: any) => {
      return typeof file !== 'undefined' || file !== null
    }
    useImperativeHandle(ref, () => ({
      open(): void {
        openModal()
      },
    }))
    return (
      <>
        {showButton && (
          <CButton
            color='primary'
            type='button'
            size='sm'
            onClick={openModal}
            className='d-flex align-items-center'
          >
            <UploadIcon />
            {label}
          </CButton>
        )}

        <input
          style={{
            visibility: 'hidden',
            width: 0,
          }}
          type='file'
          ref={refInputPdfUpload}
          onChange={handleChangeInputUpload}
          id='imageUpload'
          accept='.pdf'
        />
        <CModal show={showModal} onClose={closeModal} color='info'>
          <CModalHeader closeButton>PDF Preview</CModalHeader>
          <CModalBody>
            {existFile(fileModal) ? (
              <div>
                <Document ref={pdfViewerRef} file={fileModal} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page pageNumber={pageNumber} />
                </Document>
                <CRow>
                  <CCol xs='12'>
                    <CButton
                      type='button'
                      size='sm'
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                      className='mr-2'
                    >
                      Previous
                    </CButton>
                    <CButton
                      color='primary'
                      type='button'
                      size='sm'
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Next
                    </CButton>
                    <small className='ml-auto float-right'>
                      Page {pageNumber} of {numPages}
                    </small>
                  </CCol>
                  <CCol>
                    <CButton
                      onClick={() => {
                        onSavePdf(fileModal)
                        closeModal()
                      }}
                      style={{ float: 'right' }}
                      color='info'
                      type='button'
                      size='sm'
                    >
                      Save
                    </CButton>
                    <CButton
                      onClick={closeModal}
                      style={{ float: 'right' }}
                      color='secondary'
                      type='button'
                      size='sm'
                      className='mr-2'
                    >
                      Cancel
                    </CButton>
                  </CCol>
                </CRow>
              </div>
            ) : (
              <p>Loading...</p>
            )}
          </CModalBody>
        </CModal>
      </>
    )
  },
)
export default PdfViewer
