import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormGroup, CModal, CModalBody, CModalFooter, CModalHeader } from "@coreui/react"
import axios from "axios"
import { useEffect, useState } from "react"
import { addAuthAccessTokenToHeader, logoutVerifyErrors } from "src/services/authHelper"
import { BASE_API, LIST, MODELS, SERVICES } from "src/services/routeConst"
import { ComboBox, InputField, Loader } from 'src/shared'
import { StatusInfo } from "./enum/Status"
import { useActionsModels } from "./hooks/ActionsModels"


interface PropsInvoiceHeader {
  header?: React.ReactNode
  children: React.ReactNode
  customersId: React.ReactNode
  setStateAdd: any
  stateAdd: any
  totalAmount: any
}

const CostHeader: React.FC<PropsInvoiceHeader> = ({header = '', children, customersId, setStateAdd,stateAdd, totalAmount}) => {
  
  const modelName = 'CustomersCosts'
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setStateAdd(!stateAdd)
    setModal(!modal)
  }
  const [inspectorsListCombo, setInspectorsListCombo] = useState([])

    // INSPECTORS
    useEffect(() => {
      try{
        axios.post(
          `${BASE_API}${SERVICES}${MODELS}${LIST}`,
          {
            // DbName: "DbOltp",
            // QueryOrProcedureName: "deal.CUSTOMERS_TRANS_List",
            Model: "Inspectors",
            Extension: null,
            Params: [
            ]
          },
          { ...addAuthAccessTokenToHeader() }
        ).then(({data}) => {
            setInspectorsListCombo(mapComboToData(data.data))
          }
          // data.data && setInspectorsList(data.data)
        )
        // mapComboToData()
      } catch (error: any) {
        const status = error.response.status 
        logoutVerifyErrors(status)
      }
    }, [])
    
    const mapComboToData = (data) =>
      data.map((route) => {
        return {
          CodeId: route.Id,
          Description: `${route.FirstName} ${route.LastName}`,
        }
      })
      
  const id = 0
  const CustomersId = customersId

  const [apiData, status, saveData, handleChange, handleComboChange, handleNumberChange] =
  useActionsModels({
    id,
    CustomersId,
    modelName,
    setStateAdd: setStateAdd,
    stateAdd: stateAdd
  })
  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData } = apiData
  const { Date, Amount, Description } = modelData
    
  const today = () => {
    const date = new window.Date()
    let year = date.getFullYear()
    let month = date.getMonth()+1
    let day = date.getDate()

    return `${year}-${month<10?`0${month}`:`${month}`}-${day<10?`0${day}`:`${day}`}`
  }
  modelData.Date = today()

  return (
    <>
    <CCard className='border-transparent mb-2'>
        <CCardHeader
          className='d-flex align-items-center'
          style={{ backgroundColor: '#3C4B64', color: 'white', maxHeight:'48px' }}
        >
          <div style={{width: '98%'}}>
            <b>{header}</b>
          </div>
          <div style={{color: 'red', marginRight: '5px'}}>(${totalAmount})</div>
          <div style={{width: '2%', textAlign: 'end', fontWeight:'bold', fontSize: '25px'}}>
            <p onClick={()=>{setModal(!modal); setStateAdd(!stateAdd)}} style={{cursor: 'pointer', padding: '0px', margin:'0px'}}>+</p>
          </div>
        </CCardHeader>
        <CCardBody className='pb-0 pt-3 px-2 px-lg-3 '>{children}</CCardBody>
      </CCard>

      <CModal
        show={modal}
        onClose={toggle}
      >      
        <CModalHeader closeButton>Add Cost</CModalHeader>
        <CModalBody>
          <CFormGroup row>
            <CCol md='6' className='pr-lg-2 pl-lg-2'>
              <InputField
                name='Date'
                type='date'
                label='Date'
                value={Date ?? ''}
                onChange={handleChange}
              />
            </CCol>
            <CCol md='6' className='pr-lg-2 pl-lg-2'>
              <InputField
                name='Amount'
                type='text'
                label='Amount'
                value={Amount ?? ''}
                onChange={handleNumberChange}
                alignment='right'
                className='item-number-input'
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md='12' className='pr-lg-2 pl-lg-2'>
              <InputField
                name='Description'
                type='textarea'
                label='Description'
                value={Description ?? ''}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md='12' className='pr-lg-2 pl-lg-2'>
              <ComboBox
                showButton
                name='InspectorsId'
                label='Inspector'
                data={inspectorsListCombo}
                // data={[{codeId: 1, Description:'a'},{codeId: 2, Description:'b'}]}
                value={inspectorsListCombo.find((menu: any) => menu.CodeId === modelData.InspectorsId)}
                // handleChange={()=>handleChange({name:'InspectorsId', value: 2})}
                handleChange={handleComboChange}
                placeholder='Select an inspector'
                classNames={{ input: 'input-field' }}
                customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled' }}
                renderOption={(option: any, { selected }: any) => (
                  <div className={selected ? 'combo-selected' : ''}>
                    {` ${option.Description}`}
                  </div>
                )}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={()=>{ toggle(); saveData();}}>Save</CButton>{' '}
          <CButton
            color="secondary"
            onClick={toggle}
          >Cancel</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default CostHeader