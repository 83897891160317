import { ColumnData } from 'library-soft-datatable'

export const memberTableColumns: ColumnData[] = [
  {
    label: 'Avatar',
    name: 'AvatarUrl',
    alignment: 'center',
    type: 'text',
    customWidth: 6,
  },
  {
    label: 'Email',
    name: 'Email',
    type: 'text',
    customWidth: 15,
  },
  {
    label: 'First Name',
    name: 'FirstName',
  },
  {
    label: 'Last Name',
    name: 'LastName',
  },
  {
    label: 'Phone',
    name: 'Phone',
    type: 'phone',
    customWidth: 10,
  },
  {
    label: 'Is New',
    name: 'IsNew',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'Nickname',
    name: 'Nickname',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]

export const memberPaymentAccountsTableColumns: ColumnData[] = [
  {
    label: 'Bank',
    name: 'BankName',
    alignment: 'center',
    type: 'text',
    customWidth: 30,
  },
  {
    label: 'Bank Logo',
    alignment: 'center',
    name: 'BankLogoBlob',
  },
  {
    label: 'Account Number',
    name: 'AccountNumber',
  },
  {
    label: 'Routing Number',
    name: 'RoutingNumber',
  },
  {
    label: 'Nickname',
    name: 'Nickname',
  },
  {
    label: 'Account Type',
    name: 'AccountType',
  },
  {
    label: 'Autopay Account',
    name: 'IsAutopayAccount',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]
export const memberTransactionsTableColumns: ColumnData[] = [
  {
    label: 'Date',
    name: 'Date',
  },
  {
    label: 'Merchant',
    name: 'MerchantName',
  },
  {
    label: 'City',
    name: 'MerchantCity',
  },
  {
    label: 'State',
    name: 'MerchantState',
  },
  {
    label: 'Zip Code',
    name: 'MerchantZipCode',
  },
  {
    label: 'Phone',
    name: 'MerchantPhone',
  },
  {
    label: 'Amount',
    name: 'Amount',
    type: 'currency',
  },
  {
    label: 'Category',
    name: 'Category',
  },
  {
    label: 'Posted',
    name: 'IsPosted',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'Posted On',
    name: 'DatePosted',
  },
  {
    label: 'Reference #',
    name: 'ReferenceNo',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]

export const memberStatementsTableColumns: ColumnData[] = [
  {
    label: 'Statements Date',
    name: 'Date',
  },
  {
    label: 'Date Due',
    name: 'DatePaymentDue',
  },
  {
    label: 'Balance',
    name: 'BalanceTotal',
    type: 'currency',
  },
  {
    label: 'Minimum Due',
    name: 'MinimumDue',
    type: 'currency',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
export const memberSocialsTableColumns: ColumnData[] = [
  {
    label: 'Social Type',
    name: 'SocialType',
  },
  {
    label: 'Share On',
    name: 'SharedOn',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
export const memberOTPTableColumns: ColumnData[] = [
  {
    label: 'Code',
    name: 'Code',
  },
  {
    label: 'Status',
    name: 'Status',
  },
  {
    label: 'Generated On',
    name: 'GeneratedOn',
  },
  {
    label: 'IsConfirmed',
    name: 'IsConfirmed',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'ConfirmedOn',
    name: 'ConfirmedOn',
  },

  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
export const subAccountsTableColumns: ColumnData[] = [
  {
    label: 'Avatar',
    name: 'AvatarUrl',
    alignment: 'center',
    type: 'text',
    customWidth: 6,
  },
  {
    label: 'Email',
    name: 'Email',
    type: 'text',
  },
  {
    label: 'First Name',
    name: 'FirstName',
  },
  {
    label: 'Last Name',
    name: 'LastName',
  },
  {
    label: 'Phone',
    name: 'Phone',
    type: 'phone',
  },
  {
    label: 'Nickname',
    name: 'Nickname',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit_credit_amount', 'edit', 'delete'],
    rowClickFunc: 'edit',
    sortable: false,
  },
]

export const memberSettingsTableColumns: ColumnData[] = [
  {
    label: 'As Of',
    name: 'AsOfDate',
  },
  {
    label: 'Payment Day',
    name: 'PaymentDay',
  },
  {
    label: 'Frozen',
    name: 'IsFrozen',
    type: 'boolYesOrNo',
    alignment: 'center',
  },
  {
    label: 'Autopay',
    name: 'IsAutoPay',
    type: 'boolYesOrNo',
    alignment: 'center',
  },
  {
    label: 'Credit Alerts',
    name: 'IsCreditAlerts',
    type: 'boolYesOrNo',
    alignment: 'center',
  },
  {
    label: 'Transaction Alerts',
    name: 'IsTransactionAlerts',
    type: 'boolYesOrNo',
    alignment: 'center',
  },
  {
    label: 'Subscription Transaction Alerts',
    name: 'IsSubscriptionTransactionAlerts',
    type: 'boolYesOrNo',
    alignment: 'center',
  },
  {
    label: 'In Store Transaction Alerts',
    name: 'IsInStoreTransactionAlerts',
    type: 'boolYesOrNo',
    alignment: 'center',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]

export const memberStatsTableColumns: ColumnData[] = [
  {
    label: 'As Of Date',
    name: 'AsOfDate',
  },
  {
    label: 'Vital Score',
    name: 'VitalScore',
  },
  {
    label: 'Credit Rewards',
    name: 'CreditRewardsBalance',
    type: 'currency',
  },
  {
    label: 'Referral Rewards',
    name: 'ReferralRewardsBalance',
    type: 'currency',
  },
  {
    label: 'Cashback Balance',
    name: 'CashbackBalance',
    type: 'currency',
  },
  {
    label: 'Vital Cash Received',
    name: 'VitalCashReceived',
    type: 'currency',
  },
  {
    label: 'Shares',
    name: 'NumShares',
  },
  {
    label: 'Signups',
    name: 'NumSignups',
  },
  {
    label: 'Rank',
    name: 'YourRank',
  },
  {
    label: 'Cashback 2%',
    name: 'IsCashback2Pct',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'Cashback 3%',
    name: 'IsCashback3Pct',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'Rewards Tier 1',
    name: 'RewardsTier1',
  },
  {
    label: 'Rewards Tier 2',
    name: 'RewardsTier2',
  },
  {
    label: 'Rewards Tier 3',
    name: 'RewardsTier3',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]

export const memberCardsTableColumns: ColumnData[] = [
  {
    label: 'As Of',
    name: 'AsOfDate',
  },
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Style',
    name: 'Style',
  },
  {
    label: 'Color',
    name: 'Color',
  },
  {
    label: 'Expiration',
    name: 'Expiration',
  },
  {
    label: 'Is Secured',
    name: 'IsSecuredCard',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]

export const memberExperianTableColumns: ColumnData[] = [
  {
    label: 'As Of',
    name: 'AsOfDate',
  },
  {
    label: '# Late Pay',
    name: 'NoOfLatePay',
  },
  {
    label: 'Credit Util %',
    name: 'CreditUtilPercent',
  },
  {
    label: '# Inquiries',
    name: 'NoOfInquiries',
  },
  {
    label: 'Account Avg Age',
    name: 'AccountAvgAge',
  },
  {
    label: 'Credit Score',
    name: 'CreditScore',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]

export const memberSharedTableColumns: ColumnData[] = [
  {
    label: 'Date',
    name: 'Date',
  },

  {
    label: 'CodePrimary',
    name: 'CodePrimary',
  },
  {
    label: 'TotalAmount',
    name: 'TotalAmount',
    type: 'currency',
  },
  {
    label: 'Email',
    name: 'Email',
  },
  {
    label: 'FirstName',
    name: 'FirstName',
  },
  {
    label: 'LastName',
    name: 'LastName',
  },
  {
    label: 'Nickname',
    name: 'Nickname',
  },

  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]

export const memberContactsTableColumns: ColumnData[] = [
  {
    label: 'FirstName',
    name: 'FirstName',
  },
  {
    label: 'LastName',
    name: 'LastName',
  },
  {
    label: 'Email',
    name: 'Email',
  },
  {
    label: 'Phone',
    name: 'Phone',
  },
  {
    label: 'IsFound',
    name: 'IsFound',
    type: 'boolYesOrNo',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
