import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, MODELS, MODEL_SEARCH } from 'src/services/routeConst'

interface Params {
  Name: any
  Value: any
}
interface PropsSearchModel {
  modelName: string
  data: any
  params: Params[]
}
export const SearchModel = async ({
  modelName = '',
  data = {},
  params = [],
}: Partial<PropsSearchModel>) => {
  //URL {api}/Models/Search/{ModelName}
  const { Page = 1, PageSize = 50, SearchString = null, ...dataRequest }: any = data
  // console.log(MODELS, MODEL_SEARCH, modelName);
  try {
    console.log(params)
    const request = { Page, PageSize, SearchString, ...dataRequest }

    const { data } = await axios.post(
      `${BASE_API}/${MODELS}/${MODEL_SEARCH}/${modelName}`,
      request,
      { ...addAuthToHeader() },
    )
    return {
      data: data.data,
      status: 'success',
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error getting ${modelName} list!`,
      error: error.response,
    })
  }
}
