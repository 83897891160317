// Generated with util/create-search-page.js
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { DeleteModal, Loader } from 'src/shared'
import { AddresComponent, HeaderComponent, InfoComponent, DetailTabSection } from './components'
import {
  TEMPLATES_USERS_MODELS,
  TEMPLATES_USERS_ID,
  TEMPLATES_CARDS_MODELS,
  TEMPLATES_SETTINGS_MODELS,
  TEMPLATES_STATS_MODEL,
  TEMPLATES_EXPERIAN_MODEL,
  TEMPLATES_TRANS_MODEL,
  TEMPLATES_STATEMENTS_MODELS,
} from 'src/services/routeConst'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'
import { useRef, useState } from 'react'
import * as Modal from './modals'

const ModelsDetails = ({ history, match, modelName = TEMPLATES_USERS_MODELS }) => {
  const { id = 0 } = match.params
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id,
    modelName,
    modelsId: TEMPLATES_USERS_ID,
    history,
  })
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)
  const [showEditModal, setShowEditModal] = useState<any>(false)
  const clickedIDRef = useRef<any>(-1)
  const modalDataRef = useRef<any>({})
  // * models refs
  const cardsTableRef = useRef<any>()
  const settingsTableRef = useRef<any>()
  const statsTableRef = useRef<any>()
  const experianTableRef = useRef<any>()
  const transTableRef = useRef<any>()
  const statementsTableRef = useRef<any>()
  const showEditModalFunc = ({ id, modelName = '', label = '' }: any) => {
    clickedIDRef.current = id
    modalDataRef.current = {
      modelName: modelName,
      label: label,
      UsersId: apiData.modelData.UsersId,
      id: id,
    }
    setShowEditModal(modelName)
  }
  const showDeleteModalFunc = ({ id, modelName = '', label = '' }: any) => {
    clickedIDRef.current = id
    modalDataRef.current = {
      modelName: modelName,
      label: label,
      id: id,
    }
    setShowDeleteModal(true)
  }
  const actionFunctions: any = {
    edit: showEditModalFunc,
    delete: showDeleteModalFunc,
  }
  const updateRefs = () => {
    cardsTableRef?.current?.fetchNewData()
    settingsTableRef?.current?.fetchNewData()
    statsTableRef?.current?.fetchNewData()
    experianTableRef?.current?.fetchNewData()
    transTableRef?.current?.fetchNewData()
    statementsTableRef?.current?.fetchNewData()
  }
  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData } = apiData
  return (
    <CCard>
      <HeaderComponent
        modelName={modelName}
        onCancelHandle={history.goBack}
        status={status}
        saveData={saveData}
      />
      <CCardBody>
        <CRow>
          <CCol xs='12' md='6'>
            <InfoComponent
              modelName={modelName}
              modelData={modelData}
              handleChange={handleChange}
            />
          </CCol>
          <CCol xs='12' md='6'>
            <AddresComponent
              modelName={modelName}
              modelData={modelData}
              handleChange={handleChange}
            />
          </CCol>
        </CRow>
        {id ? (
          <CRow>
            <CCol xs='12'>
              <DetailTabSection
                id={id}
                modelName={modelName}
                actionFunctions={actionFunctions}
                loginHistoryTableRef={null}
                paymentAccountsTableRef={null}
                transactionsTableRef={transTableRef}
                statementsTableRef={statementsTableRef}
                settingsTableRef={settingsTableRef}
                socialsTableRef={null}
                statsTableRef={statsTableRef}
                otpTableRef={null}
                cardsTableRef={cardsTableRef}
                experianTableRef={experianTableRef}
                sharedSenderTableRef={null}
                changesRef={null}
                subAccountsTableRef={null}
                memberData={null}
                sharedReceiverTableRef={null}
                showEditModalFunc={showEditModalFunc}
                viewDetails={null}
                contactTableRef={null}
              />
            </CCol>
            <DeleteModal
              showModal={showDeleteModal}
              setShowModal={setShowDeleteModal}
              modalData={modalDataRef.current}
              callBack={updateRefs}
            />
            <Modal.CardsModal
              showModal={showEditModal === TEMPLATES_CARDS_MODELS}
              modalData={modalDataRef.current}
              callBack={updateRefs}
              setShowModal={setShowEditModal}
            />
            <Modal.SettingsModal
              showModal={showEditModal === TEMPLATES_SETTINGS_MODELS}
              modalData={modalDataRef.current}
              callBack={updateRefs}
              setShowModal={setShowEditModal}
            />
            <Modal.StatsModal
              showModal={showEditModal === TEMPLATES_STATS_MODEL}
              modalData={modalDataRef.current}
              callBack={updateRefs}
              setShowModal={setShowEditModal}
            />
            <Modal.ExperianModal
              showModal={showEditModal === TEMPLATES_EXPERIAN_MODEL}
              modalData={modalDataRef.current}
              callBack={updateRefs}
              setShowModal={setShowEditModal}
            />
            <Modal.TransModal
              showModal={showEditModal === TEMPLATES_TRANS_MODEL}
              modalData={modalDataRef.current}
              callBack={updateRefs}
              setShowModal={setShowEditModal}
            />
            <Modal.StatementsModal
              showModal={showEditModal === TEMPLATES_STATEMENTS_MODELS}
              modalData={modalDataRef.current}
              callBack={updateRefs}
              setShowModal={setShowEditModal}
            />
          </CRow>
        ) : null}
      </CCardBody>
    </CCard>
  )
}

export default ModelsDetails
