import axios from 'axios'
import { CCol, CFormGroup, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react'
import { useEffect, useState } from 'react'
import { BASE_API, DATA, DELETE, LIST, MODELS, RETRIEVE, SAVE, SERVICES, TRANSACT_SQL } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'
import { InputField, ComboBox } from 'src/shared'
import BreadcrumbsComponent from './components/Breadcrumb';
import ButtonGroupDrpComponent from './components/ButtonAction';

const WorkFlow = ({customerId = 50}) => {
  const [workflowId, setWorkflowId] = useState()
  const [workflowActId, setWorkflowActionId] = useState('')
  const [workflowTypeId, setWorkflowTypeId] = useState('')
  const [workflowName, setWorkflowName] = useState('')
  const [addWorkFlowModal, setAddWorkFlowModal] = useState(false)
  const [addActionModal, setAddActionModal] = useState(false)
  const [workflowData, setWorkFlowData] = useState([])
  const [keyTypeData, setKeyTypeData] = useState([])
  const [workflowListData, setWorkFlowListData] = useState([])
  const [taskListData, setTaskListData] = useState([])
  const [statusData, setStatusData] = useState([])
  const [showAction, setShowAction] = useState(false)
  const [showTasks, setShowTasks] = useState(false)
  const [showAddEditTaskModal, setShowAddEditTaskModal] = useState(false)
  const [buttonName, setbuttonName] = useState('')
  // const [buttonDescription, setbuttonDescription] = useState('');
  const [buttonStart, setbuttonStart] = useState('')
  const [buttonEnd, setbuttonEnd] = useState('')
  const [taskName, setTaskName] = useState('')
  const [workflowTemplateId, setWorkflowTemplateId] = useState('')
  const [breadcrumCalled, setBreadcrumCalled] = useState(false);
  const [drpText, setDrpText] = useState('Select Next Action');
  const [drpCalled, setDrpCalled] = useState(false);
  const [drpBtnData, setDrpBtnData] = useState<any>([]);
  const [WorkFlowActions, setWorkFlowActions] = useState<any>([]);
  const [Customers, setCustomer] = useState<any>();

  useEffect(() => {
    getWorkflows()
    getCustomer()
    getKeyTypeData()
  }, [])
  
  useEffect(() => {
    if (Customers) {
      setBreadcrumCalled(true);
      setDrpCalled(true);
    }
  }, [Customers]);

  // const mapComboToData = () =>
  //   workflowData.map((route: any) => {
  //     return {
  //       CodeId: route.Id,
  //       Description: route.Name,
  //       LookupTypesId: route.LookupTypesId,
  //     }
  //   })

  const getWorkflows = () => {
    let token = getAuthToken('ADMIN')
    axios({
      method: 'POST',
      url: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
      data: {
        Model: 'Workflows',
        Extension: null,
        Params: [
          {
            Name: 'SearchString',
            Value: null,
          },
          {
            Name: 'OrderBy',
            Value: null,
          },
          {
            Name: 'OrderByDir',
            Value: null,
          },
          {
            Name: 'Page',
            Value: null,
          },
          {
            Name: 'PageSize',
            Value: null,
          },
        ],
      },
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    })
      .then((res) => {
        if (res.data) {
          if (res.data.data) {
            let wData = res.data.data.map((route: any) => {
              return {
                Id: route.Id,
                CodeId: route.Id,
                Description: route.Name,
                LookupTypesId: route.LookupTypesId,
              }
            })
            setWorkFlowData(wData)
          }
        }
      })
      .catch(() => {})
  }
  
  const getCustomer = () => {
    let token = getAuthToken('ADMIN')
    axios({
      method: 'POST',
      url: `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
      data: {
        Model: 'Customers',
        Extension: null,
        Id: customerId,
        Params: []
      },
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    })
      .then((res) => {
        if (res.data) {
          if (res.data.data) {
            setCustomer(res.data.data[0])
          }
        }
      })
      .catch(() => {})
  }

  const getKeyTypeData = () => {
    let token = getAuthToken('ADMIN')
    axios({
      method: 'POST',
      url: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
      data: {
        Model: 'LookupTypes',
        Extension: null,
        Params: [
          {
            Name: 'SearchString',
            Value: null,
          },
          {
            Name: 'OrderBy',
            Value: null,
          },
          {
            Name: 'OrderByDir',
            Value: null,
          },
          {
            Name: 'Page',
            Value: null,
          },
          {
            Name: 'PageSize',
            Value: null,
          },
        ],
      },
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    })
      .then((res) => {
        if (res.data) {
          if (res.data.data) {
            let sData = res.data.data.map((item:any) => { return { CodeId: item.Id, Description: '('+item.Id +') '+ item.Description } });
            setKeyTypeData(sData)
          }
        }
      })
      .catch(() => {})
  }

  const getWorkFlowListData = (event: any) => {
    setShowAction(true)
    setWorkflowId(event.value)
    getActions(event.value)
    // let tId: any = workflowData.find((item: any) => {
    //   return item.Id === event.value
    // })
    // if (tId) {
    //   getKeys(tId.LookupTypesId)
    // }
    getKeys()

  }

  const getKeys = () => {
    let token = getAuthToken('ADMIN')
    axios({
      method: 'POST',
      url: `${BASE_API}${SERVICES}${DATA}/${TRANSACT_SQL}`,
      data: {
        DbName: 'DbAdmin',
        QueryOrProcedureName: "lookup.LOOKUPS_List",
        // TypesId: TypesId
      },
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    })
      .then((res) => {
        if (res.data) {
          if (res.data.data.length) {
            let sData = res.data.data.map((item:any) => { return { CodeId: item.Id, Description: '('+item.Id +') '+ item.Description } });
            setStatusData(sData)
          }
        }
      })
      .catch(() => {})
  }

  const getActions = (workflowId: any) => {
    let token = getAuthToken('ADMIN')
    axios({
      method: 'POST',
      url: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
      data: {
        Model: 'WorkflowsActions',
        Extension: null,
        Params: [
          {
            Name: 'SearchString',
            Value: null,
          },
          {
            Name: 'OrderBy',
            Value: null,
          },
          {
            Name: 'OrderByDir',
            Value: null,
          },
          {
            Name: 'Page',
            Value: null,
          },
          {
            Name: 'PageSize',
            Value: null,
          },
          {
            Name: 'WorkflowsId',
            Value: workflowId,
          },
        ],
      },
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    })
      .then((res) => {
        if (res.data) {
          if (res.data.data) {
            setWorkFlowListData(res.data.data)
            setTaskListData([])
            setShowAction(false)
          }
        }
      })
      .catch(() => {})
  }

  const editTaskList = (item: any) => {
    setWorkflowActionId(item.Id)
    setAddActionModal(true)
    setbuttonName(item.Name)
    setbuttonStart(item.LookupsId_Start)
    setbuttonEnd(item.LookupsId_End)
  }

  const getTaskListData = (WorkflowActionId: any) => {
    setShowTasks(true)
    setWorkflowActionId(WorkflowActionId)

    let token = getAuthToken('ADMIN')
    axios({
      method: 'POST',
      url: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
      data: {
        Model: 'WorkflowsTemplates',
        Extension: null,
        Params: [
          {
            Name: 'SearchString',
            Value: null,
          },
          {
            Name: 'OrderBy',
            Value: null,
          },
          {
            Name: 'OrderByDir',
            Value: null,
          },
          {
            Name: 'Page',
            Value: null,
          },
          {
            Name: 'PageSize',
            Value: null,
          },
          {
            Name: 'WorkflowsActionsId',
            Value: WorkflowActionId,
          },
        ],
      },
      headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
    })
      .then((res) => {
        if (res.data) {
          if (res.data.data) {
            setTaskListData(res.data.data)
          }
        }
      })
      .catch(() => {})
  }

  const deleteTaskListData = (WorkflowActionId: any) => {
    if (window.confirm('Are you sure you want delete this record?')) {
      setWorkFlowListData([])
      setShowAction(true)
      let token = getAuthToken('ADMIN')
      axios({
        method: 'DELETE',
        url: `${BASE_API}${SERVICES}${MODELS}/${DELETE}`,
        data: {
          Model: 'WorkflowsActions',
          Extension: null,
          Id: WorkflowActionId,
          Params: [],
        },
        headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
      })
        .then((res) => {
          if (res.data) {
            getActions(workflowId)
          }
        })
        .catch(() => {})
    }
  }

  const saveWorkflow = () => {
    if (workflowName) {
      let token = getAuthToken('ADMIN')
      axios.patch(`${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
        {
          Model: 'Workflows',
          Extension: null,
          Id: workflowId || 0,
          Params: [
            {
              Name: 'Name',
              Value: workflowName,
            },
            {
              Name: 'lookupTypesId',
              Value: workflowTypeId,
            },
          ],
        },
        { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
        ).then((res) => {
          if (res.data) {
            setAddWorkFlowModal(false)
          }
        })
        .catch(() => {})
    }
  }

  const saveNotes = (MetasId: any, ActionDescription: string) => {
    let token = getAuthToken('ADMIN');
    setBreadcrumCalled(false);
    setDrpCalled(false);
      axios.post(
        `${BASE_API}${SERVICES}/Widget/Notes/Insert`,
        {
          MetasId: MetasId,
          Notes: ActionDescription,
          IsWorkflow: true
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
        .then((res) => {
          if (res.data) {
            setBreadcrumCalled(true);
            setDrpCalled(false);
          }
        })
        .catch(() => {})
  }

  const saveWorkflowAction = async() => {
    if (buttonName && buttonStart && buttonEnd) {
      let token = getAuthToken('ADMIN');
      axios.patch(
        `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
        {
          Model: 'WorkflowsActions',
          Extension: null,
          Id: workflowActId || 0,
          Params: [
            {
              Name: 'Name',
              Value: buttonName,
            },
            {
              Name: 'WorkflowsId',
              Value: workflowId,
            },
            {
              Name: 'LookupsId_Start',
              Value: buttonStart,
            },
            {
              Name: 'LookupsId_End',
              Value: buttonEnd,
            },
          ],
        },
        { headers: { Authorization: `Bearer ${token}` } },
      )
        .then((res) => {
          if (res.data) {
            setWorkflowActionId('')
            setAddActionModal(false)
            setShowAction(true)
            getActions(workflowId)
          }
        })
        .catch(() => {})
    }
  }

  const saveTask = () => {
    let token = getAuthToken('ADMIN')
    axios.patch(
     `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
      {
        Model: 'WorkflowsTemplates',
        Extension: null,
        Id: workflowTemplateId || 0,
        Params: [
          {
            Name: 'Task',
            Value: taskName,
          },
          {
            Name: 'WorkflowsActionsId',
            Value: workflowActId,
          }
        ],
      },
      { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
      ).then((res) => {
        if (res.data) {
          setShowAddEditTaskModal(false)
          getTaskListData(workflowActId)
        }
      })
      .catch(() => {})
  }

  const showAddWorkFlowModal = () => {
      if (workflowId) {
          let tId:any = workflowData.find((item: any) => { return (item.Id === workflowId) });
          setWorkflowName(tId.Description);
          setWorkflowTypeId(tId.LookupTypesId);
      }
      setAddWorkFlowModal(true);
  }

  const showAddActionModal = () => {
    setWorkflowActionId('')
    setbuttonName('')
    // setbuttonDescription('');
    setbuttonStart('')
    setbuttonEnd('')
    setAddActionModal(true)
  }
  const addTask = () => {
    setTaskName('')
    setWorkflowTemplateId('')
    setShowAddEditTaskModal(true)
  }

  const editTask = (item: any) => {
    setTaskName(item.Task)
    setWorkflowTemplateId(item.Id)
    // WorkflowTemplateId
    setShowAddEditTaskModal(true)
  }

  const confirmModal = (
    <CModal show={addWorkFlowModal} onClose={() => setAddWorkFlowModal(false)}>
      <CModalHeader>{workflowId ? ' Edit' : 'Add'} Workflow</CModalHeader>
      <CModalBody>
        <div>
        <InputField
              name='workflowName'
              type='text'
              label='Workflow Name'
              value={workflowName ?? ''}
              onChange={(event) => setWorkflowName(event.value)}
            />
        </div>
        <div className='mt-3'>
        <ComboBox
            name='workflowTypeId'
            label='Type'
            data={keyTypeData}
            value={keyTypeData.find((w: any) => w.CodeId === workflowTypeId)}
            handleChange={(e: any, newValue: any = {}) => {
              if (e) {
                setWorkflowTypeId(newValue.CodeId)
              } else {
                setWorkflowTypeId('');
              }
            }}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
            )}
          />
        </div>
      </CModalBody>
      <CModalFooter>
        <button className='btn btn-sm btn-grey' onClick={() => setAddWorkFlowModal(false)}>
          Close
        </button>
        <button className='btn btn-sm btn-gc' onClick={saveWorkflow}>
          Save
        </button>
      </CModalFooter>
    </CModal>
  )

  const addEditTaskModal = (
    <CModal show={showAddEditTaskModal} onClose={() => setAddWorkFlowModal(false)}>
      <CModalHeader>{workflowTemplateId ? 'Edit' : 'Add'} Task</CModalHeader>
      <CModalBody>
        <div>
        <InputField
              name='taskName'
              type='text'
              label='Task'
              value={taskName ?? ''}
              onChange={(event) => setTaskName(event.value)}
            />
        </div>
      </CModalBody>
      <CModalFooter>
        <button className='btn btn-sm btn-grey' onClick={() => setShowAddEditTaskModal(false)}>
          Close
        </button>
        <button className='btn btn-sm btn-gc' onClick={saveTask}>
          Save
        </button>
      </CModalFooter>
    </CModal>
  )

  const addWorkFlowActionModal = (
    <CModal backdrop={true} show={addActionModal} onClose={() => setAddActionModal(false)}>
      <CModalHeader>{workflowActId ? 'Edit Actions' : 'Add New Actions'}</CModalHeader>
      <CModalBody>
        <CFormGroup row>
          <CCol xs='12' sm='12'>
            <InputField
              name='buttonName'
              type='text'
              label='Button Name'
              value={buttonName ?? ''}
              onChange={(event) => setbuttonName(event.value)}
            />
          </CCol>
        </CFormGroup>
        {/* <CFormGroup row>
                <CCol xs='12' sm='12'>
                    <InputField
                        name='buttonDescription'
                        type='text'
                        label='Button Description'
                        value={buttonDescription ?? ''}
                        onChange={(event) => setbuttonDescription(event.value) }
                    />
                </CCol>
                </CFormGroup> */}
        <CFormGroup row>
          <CCol xs='12' sm='12'>
            <ComboBox
              showButton
              name='buttonStart'
              label='Status Start'
              data={statusData}
              value={statusData.find((w: any) => w.CodeId === buttonStart)}
              handleChange={(e: any, newValue: any = {}) => {
                if (e) {
                  setbuttonStart(newValue.CodeId)
                }
              }}
              classNames={{ input: 'input-field' }}
              customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled' }}
              renderOption={(option: any, { selected }: any) => (
                <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
              )}
            />
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol xs='12' sm='12'>
            <ComboBox
              showButton
              name='buttonEnd'
              label='Status End'
              data={statusData}
              value={statusData.find((w: any) => w.CodeId === buttonEnd)}
              handleChange={(e: any, newValue: any = {}) => {
                if (e) {
                  setbuttonEnd(newValue.CodeId)
                }
              }}
              classNames={{ input: 'input-field' }}
              customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled' }}
              renderOption={(option: any, { selected }: any) => (
                <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
              )}
            />
          </CCol>
        </CFormGroup>
      </CModalBody>
      <CModalFooter>
        <button className='btn btn-sm btn-grey' onClick={() => setAddActionModal(false)}>
          Close
        </button>
        <button className='btn btn-sm btn-gc' onClick={saveWorkflowAction}>
          Save
        </button>
      </CModalFooter>
    </CModal>
  )

  const clickWorkFlowStatus = (e: any) => {
    let statusId = e.Id;
    console.log(statusId)
    // let workflowActionId: any = '0';
    // axios({
    //     method: "POST",
    //     url: `${process.env.REACT_APP_BASE_URL}Data/Exec/ABACUS/core/WORKFLOW_Action_ListPaginated?api_key=${apiKey}`,
    //     data: {
    //         WorkflowId: 5,
    //         KeysIdEnd: statusId
    //     },
    //     headers: { 'content-type': 'application/json' }
    // }).then((res) => {
    //     if (res.data) {
    //         workflowActionId = res.data[0].WorkflowActionId;
    //         setWorkflowActionIdNow(workflowActionId);
    //         if (taskTabData.length > 0 && taskTabData[0].IsCompleted == false) {
    //             cancelTasks(taskTabData[0]);
    //         }
    //         getWorkflowTemplate(e, workflowActionId);
    //     } else {
    //     }
    // }).catch(() => {
    // })
  }

  const clickWorkFlow = (event: any) => {
    let wAction = WorkFlowActions.find((d: any) => d.LookupsId_End === event);
    let token = getAuthToken('ADMIN')
    axios.patch(
     `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
      {
        Model: 'Customers',
        Extension: null,
        Id: Customers.Id,
        Params: [
          {
            Name: 'InspectionStatusId',
            Value: wAction.LookupsId_End,
          }
        ],
      },
      { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
      ).then((res) => {
        if (res.data) {
          setDrpText('Select Next Action');
          setDrpCalled(true);
          saveNotes(Customers.MetasId, wAction.ActionDescription);
        }
      })
      .catch(() => {})
    // setDrpText('Select Next Action');
  }
  

  return (
    <>        
      <div className='container workflow pt-4'>
        <div className='mt-4'>
        {Customers ? 
          <div className='row mt-4'>
            <div className='col-md-9 pr-0'>
              <BreadcrumbsComponent isCalled={breadcrumCalled} MetasId={Customers.MetasId} actionClick={clickWorkFlowStatus} ></BreadcrumbsComponent>
            </div>
            <div className='col-md-3 pl-0'>
                <ButtonGroupDrpComponent isCalled={drpCalled} drpText={drpText} setDrpBtnData={setDrpBtnData} setWorkFlowActions={setWorkFlowActions} LookupStartId={Customers.InspectionStatusId} clickWorkFlow={clickWorkFlow} drpBtnData={drpBtnData}></ButtonGroupDrpComponent>
            </div>
          </div>
          : null }
          <div className='row mt-4'>
            <div className='col-md-6'>  
              <ComboBox
                showButton
                name='workflowId'
                label='Workflow'
                data={workflowData}
                value={workflowData.find((w: any) => w.CodeId === workflowId)}
                handleChange={(e: any, newValue: any = {}) => {
                  getWorkFlowListData({ value: newValue.CodeId, e: e })
                }}
                classNames={{ input: 'input-field' }}
                customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled' }}
                renderOption={(option: any, { selected }: any) => (
                  <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
                )}
              />
            </div>
            <div className='col-md-3'> 
              <button className="btn btn-lg btn-gc" style={{height:50}} onClick={showAddWorkFlowModal}>{ (workflowId) ? 'Edit' : 'Add' } </button>
            </div>
          </div>
          {/* <select className="form-select" onChange={(event) => getWorkFlowListData(event) }>
                        <option value="">Choose a Workflow</option>
                        {workflowData.map((row:any, k) => {
                            return <option key={k} value={row.Id}>{row.Name}</option>
                        })}
                    </select> */}
        </div>
        {workflowId ? (
          <div className='row mt-4'>
            <div className='col-md-6'>
              <div className='w-100 d-flex align-items-center bgbr'>
                <div className='w-50'>
                  <strong className='clr-g'>Choose a Workflow Actions</strong>
                </div>
                <div className='w-50 text-end'>
                  <button className='btn btn-sm btn-gc' onClick={showAddActionModal}>
                    Add Action
                  </button>
                </div>
              </div>
              {showAction ? (
                <></>
              ) : (
                <div className='w-100 mt-3'>
                  <table className='table table-bordered'>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th className='text-center action-th'>Actions</th>
                      </tr>
                    </thead>
                    <tbody className='border-top-0'>
                      {workflowListData.map((item: any, ke) => {
                        return (
                          <tr key={ke} className={(item.Id === workflowActId) ? 'bg-white': ''}>
                            <td>{item.Name}</td>
                            <td className='text-center'>
                              <button
                                className='btn btn-sm action-btn me-1'
                                onClick={() => getTaskListData(item.Id)}
                              >
                                Select
                              </button>
                              <button
                                className='btn btn-sm action-btn me-1'
                                onClick={() => editTaskList(item)}
                              >
                                Edit
                              </button>
                              <button
                                className='btn btn-sm action-btn me-1'
                                onClick={() => deleteTaskListData(item.Id)}
                              >
                                Delete
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className='col-md-6'>
              {workflowActId ? (
                <div className='w-100 d-flex align-items-center bgbr'>
                  <div className='w-50'>
                    <strong className='clr-g'>Tasks List</strong>
                  </div>
                  <div className='w-50 text-end'>
                    <button className='btn btn-sm btn-gc' onClick={addTask}>
                      Add Task
                    </button>
                  </div>
                </div>
              ) : null}
              {showTasks ? <></> : null}
              {taskListData.length ? (
                <div className='w-100 mt-3'>
                  <table className='table table-bordered'>
                    <thead>
                      <tr>
                        <th>Action</th>
                        <th>Task Description</th>
                        <th className='text-center action-th' style={{ width: '100px' }}>
                          Actions
                        </th>
                      </tr>
                    </thead>
                    <tbody className='border-top-0'>
                      {taskListData.map((item: any, ke) => {
                        return (
                          <tr key={ke}>
                            <td>{item.WorkflowsActionsName}</td>
                            <td>{item.Task}</td>
                            <td className='text-center'>
                              <button
                                className='btn btn-sm action-btn me-1'
                                onClick={() => editTask(item)}
                              >
                                Edit
                              </button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      {confirmModal}
      {addWorkFlowActionModal}
      {addEditTaskModal}
    </>
  )
}
export default WorkFlow
