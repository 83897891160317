// Generated with util/create-search-page.js
export const FilesTableColumns = [
  /* {
    label: 'ForGuid',
    name: 'ForGuid',
    dbType: 'varchar',
  }, */
  {
    label: 'Repository',
    name: 'Repository',
    dbType: 'varchar',
  },
  {
    label: 'Folder',
    name: 'Folder',
    dbType: 'varchar',
  },
  {
    label: 'FileNameOriginal',
    name: 'FileNameOriginal',
    dbType: 'varchar',
  },
  {
    label: 'FileNameGuid',
    name: 'FileNameGuid',
    dbType: 'varchar',
  },
  {
    label: 'FileUrl',
    name: 'FileUrl',
    dbType: 'varchar',
  },
  {
    label: 'Description',
    name: 'Description',
    dbType: 'varchar',
  },
  /*{
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },*/
]
