import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, DATA, TRANSACT_SQL } from 'src/services/routeConst'
interface PropsDataSQL {
  query?: string
}
export const DataSQL = async ({ query = '' }: PropsDataSQL) => {
  //URL {api}/Data/Execute
  const request = { SQLQuery: query }
  try {
    const { data } = await axios.post(`${BASE_API}:${DATA}/${TRANSACT_SQL}`, request, {
      ...addAuthToHeader('ADMIN'),
    })
    return {
      data: data.data,
      status: 'success',
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error executing SQL Statement "${query.substr(0, 100)}..."!`,
      error: error.response,
    })
  }
}
