import React from 'react'

//component - CoreUI / CWidgetDropdown

const WidgetDropdown: React.FC<any> = (props: any) => {
  const { children, className, icon, header, color, footerSlot, text, ...attributes } = props

  const classes = `card text-white overflow-hidden ${color && `bg-${color}`} ${className}`

  return (
    <div className={classes} {...attributes}>
      {icon && icon}
      <div className='card-body pb-0 d-flex justify-content-between'>
        <div>
          {!!text && <div className='text-uppercase'>{text}</div>}
          {!!header && <div className='text-value-lg'>{header}</div>}
        </div>
        {children}
      </div>
      {footerSlot}
    </div>
  )
}

export default WidgetDropdown
