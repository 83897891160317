import React from 'react'
import { CTabPane } from '@coreui/react'

// import { FieldsSection } from 'src/shared'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'

interface PropsUserDetailTabSection {
  id?: any
  TokensId?: any
  Guid?: any
  modelName?: any
  tableRef?: any
  changesRef?: any
}
const UserDetailTabSection: React.FC<PropsUserDetailTabSection> = ({
  id,
  // TokensId,
  Guid,
  modelName,
  // tableRef,
  changesRef,
}) => {
  return (
    <DetailTabSection
      id={id}
      Guid={Guid}
      modelName={modelName}
      tabs={[{ name: 'DataTable', label: 'Login History' }]}
      changesRef={changesRef}
    >
      <CTabPane data-tab='DataTable' className='detail-tab-section-pane'>
        {/* <FieldsSection header='Login History'> */}
        {
          /*   <DataTable
          columnData={loginHistoryTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          // queryParams={{ TokensId }}
          modelName={SIGNINS_MODEL}
          idName={SIGNINS_ID}
          ref={tableRef}
          rootStyles={{ fontSize: '0.75rem' }}
          apiKey={getAuthToken('ADMIN')}
          /* defaultPageSize={10} */
          /> */
        }
        {/* </FieldsSection> */}
      </CTabPane>
    </DetailTabSection>
  )
}

export default UserDetailTabSection
