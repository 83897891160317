import React from 'react'
import { getInitialsFromName } from 'src/services/util'
// import { CCol, CRow } from '@coreui/react'
interface PropsNote {
  note: any
  Guid: any
}

const Note: React.FC<PropsNote> = ({ note, Guid }) => {
  const currentUserIsSender = note?.GuidWhoWrote === Guid

  return (
    <div className='d-flex'>
      <div
        className={`mini-card note ${currentUserIsSender ? 'ml-auto' : ''}`}
        style={{
          maxWidth: '55%',
          // minWidth: '25%',
          flexDirection: currentUserIsSender ? 'row-reverse' : 'row',
        }}
      >
        <div
          className={`avatar-root avatar-circular  avatar-colorDefault`}
          style={{ backgroundColor: 'white' }}
        >
          <span
            style={{
              // textShadow: '#000 1px 0 18px',
              backgroundImage: 'linear-gradient(#1080BF 0%, #33D1F9 100%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {getInitialsFromName('Andy Jesus')}
          </span>
        </div>

        <div
          style={{
            padding: '0 0.5rem 0.5rem',
            overflow: 'hidden',
          }}
        >
          {/* <div
            className='row'
            style={{
              marginBottom: '0.4rem',
              alignItems: 'center',
              paddingRight: '0.4rem',
            }}
          >
            <div className='col'>
              <small
                style={{ color: 'rgba(241,241,255,0.5)' }}
                className={currentUserIsSender ? 'd-block text-right' : ''}
              >
                {currentUserIsSender ? 'You' : note?.SenderName ?? note.GuidId} /{' '}
                {note?.ChangedOn?.split(' ')[1] ?? 'NO DATE'}
              </small>
            </div>
          </div> */}
          <p
            style={{ margin: 0, [currentUserIsSender ? 'paddingRight' : 'paddingLeft']: '0.5rem' }}
          >
            {note.Notes}
          </p>
        </div>
      </div>
    </div>
  )
}

export default Note
