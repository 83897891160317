import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import AceEditor from 'react-ace'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/mode-sqlserver'
import 'ace-builds/src-noconflict/theme-sqlserver'
import 'ace-builds/src-noconflict/mode-jsx'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/mode-json'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/theme-terminal'
import 'ace-builds/src-noconflict/ext-language_tools'
import Beautify from 'ace-builds/src-noconflict/ext-beautify'
// Modes and Themes
// https://github.com/securingsincity/react-ace/blob/master/docs/Modes.md
// * To add more support for the modes you must import these
interface PropsCustomCodeEditor {
  name?: string
  theme?: string
  mode?: string
  value?: any
  onChange?: any
  width?: string
  height?: string
  completers?: any[]
  isValid?: any
  readOnly?: boolean
}
interface PropsRefEditor {
  insertInCurrentCursorPosition: any
}
const CustomCodeEditor = forwardRef<PropsRefEditor, PropsCustomCodeEditor>(
  (
    {
      name,
      theme = 'monokai',
      mode = 'sqlserver',
      value,
      onChange,
      width = '100%',
      height = '500px',
      completers = [],
      isValid = () => {},
      readOnly = false,
    }: PropsCustomCodeEditor,
    ref,
  ) => {
    const editorRef = React.useRef<any | AceEditor>()
    const complete = () => {}
    /* useEffect(() => {
    console.log(Beautify)
    Beautify.beautify(editorRef.current?.editor.session)
  }, []) */
    useEffect(() => {
      editorRef?.current?.editor.completers.push({
        getCompletions: function (_: any, __: any, ___: any, ____: any, callback: any) {
          callback(null, completers)
        },
      })
    }, [completers])

    const onValidate = () => {
      isValid(
        editorRef?.current?.editor
          ?.getSession()
          ?.getAnnotations()
          ?.filter((annotation: any) => annotation.type === 'error')?.length <= 0,
      )
    }
    /* */
    useImperativeHandle(ref, () => ({
      insertInCurrentCursorPosition(value: any) {
        const cursorPosition = editorRef?.current?.editor.getCursorPosition()
        editorRef?.current?.editor.session.insert(cursorPosition, value)
      },
      beautifyCode() {
        Beautify.beautify(editorRef.current?.editor.session)
      },
    }))
    return (
      <AceEditor
        ref={editorRef}
        mode={mode}
        theme={theme}
        value={value}
        onChange={onChange}
        onValidate={onValidate}
        showPrintMargin={false}
        name={name}
        onLoad={complete}
        width={width}
        height={height}
        enableBasicAutocompletion
        enableLiveAutocompletion
        enableSnippets
        wrapEnabled
        tabSize={mode === 'sqlserver' ? 8 : 2}
        readOnly={readOnly}
      />
    )
  },
)

export default CustomCodeEditor
