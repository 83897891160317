import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API } from 'src/services/routeConst'
interface PropsVerifyOTP {
  code: any
  type?: string
  toCheck?: string
  MemberId
}
export const verifyOTP = async ({
  code,
  type = 'Email',
  toCheck = '',
  MemberId,
}: PropsVerifyOTP) => {
  const request: any = type === 'Email' ? { EmailTo: toCheck } : { Phone: `+1${toCheck}` }
  request.Code = code
  try {
    const { data } = await axios.post(
      `${BASE_API}:8116/Otp/Verify${type === 'Email' ? 'Email' : 'Sms'}`,
      { ...request, MemberId },
      { ...addAuthToHeader('APPLICATION') },
    )
    return data
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error verifying OTP!`,
      error: error.response,
    })
  }
}
