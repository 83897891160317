import HeaderComponent from './Header'
import InspectorInfo from './InspectorInfo'
import PickUpInfo from './PickupInfo'
import DropOffInfo from './DropOffInfo'
import OwnershipInfo from './OwnershipInfo'
import TitleInfo from './TitleInfo'

export { 
  HeaderComponent, 
  InspectorInfo, 
  PickUpInfo, 
  DropOffInfo,
  OwnershipInfo,
  TitleInfo
}
