import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, DATA, DATA_EXEC } from 'src/services/routeConst'
interface PropsDataExec {
  procName?: string
  params?: Object
}
export const DataExec = async ({ procName = '', params = {} }: PropsDataExec) => {
  //URL {api}/Data/Execute
  const request = { ProcedureName: procName, ...params }
  try {
    const { data } = await axios.post(`${BASE_API}:${DATA}/${DATA_EXEC}`, request, {
      ...addAuthToHeader('ADMIN'),
    })
    // console.log(data)
    return {
      data: data.data,
      status: 'success',
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error executing SP ${procName}!`,
      error: error.response,
    })
  }
}
