// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const MechanicalIssuesInfo = ({ modelData, handleChange, title = 'Model Name' }: PropsInfoForm) => {
  const {
    MechEngine,
    MechTransmission,
    MechAir,
    MechHeater,
    MechPowerLiftgate,
    MechPowerSlidingdoors,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField type='fancyswitch' name='MechEngine' label={`Mech Issues Engine`}>
            <CustomSwitch
              name='MechEngine'
              className='d-block'
              value={MechEngine}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechEngine })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField type='fancyswitch' name='MechTransmission' label={`Mech Issues Transmission`}>
            <CustomSwitch
              name='MechTransmission'
              className='d-block'
              value={MechTransmission}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechTransmission })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField type='fancyswitch' name='MechAircon' label={`Mech Issues Aircon`}>
            <CustomSwitch
              name='MechAir'
              className='d-block'
              value={MechAir}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechAir })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField type='fancyswitch' name='MechHeater' label={`Mech Issues Heater`}>
            <CustomSwitch
              name='MechHeater'
              className='d-block'
              value={MechHeater}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechHeater })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            type='fancyswitch'
            name='MechPowerLiftgate'
            label={`MechIssues Power Liftgate`}
          >
            <CustomSwitch
              name='MechPowerLiftgate'
              className='d-block'
              value={MechPowerLiftgate}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechPowerLiftgate })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            type='fancyswitch'
            name='MechPowerSlidingdoors'
            label={`MechIssues Power Slidingdoors`}
          >
            <CustomSwitch
              name='MechPowerSlidingdoors'
              className='d-block'
              value={MechPowerSlidingdoors}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.MechPowerSlidingdoors })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default MechanicalIssuesInfo
