// Generated with util/create-search-page.js
import { useState, useMemo, useCallback, useEffect } from 'react'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { Loader } from 'src/shared'
import { TokensAvatarPicture, TokensHeader, TokensInfo } from './components'
import { TOKENS_MODEL, TOKENS_ID } from 'src/services/routeConst'

const fields = [
  'TokensId',
  'UserName',
  'FilesId_Avatar',
  'Password',
  'ChangedType',
  'TokenType',
  'TokenCode',
  'AccessToken',
  'LoginDateTime',
  'StampStart',
  'StampEnd',
  'IsExpired',
  'InvalidLogins',
  'IsLockedOut',
  'LockedOutExpired',
]

const TokensDetails = ({ history, match, modelName = TOKENS_MODEL }) => {
  const [apiData, setApiData] = useState<any>({ modelData: null })
  const [status, setStatus] = useState<any>('loading')

  const { id = 0 } = match.params

  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback<any>(async () => {
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [TOKENS_ID]: 0 },
      })
      console.log(mainData)
      setApiData({ modelData: mainData.data.RetrieveInfo[0] ?? {} })
      setStatus('idle')
    } catch (error) {
      setStatus('idle')
      console.error(error)
    }
  }, [rootRepository, id, modelName])

  useEffect(() => {
    getData()
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ modelData: null })
      history.goBack()
    }, delay)
  }

  const saveData = async () => {
    const dataToSave: any = apiData.modelData
    setStatus('saving')
    try {
      await rootRepository.saveData({
        modelName,
        idName: TOKENS_ID,
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        getData,
        goBack,
      })
      setStatus('idle')
    } catch (error) {
      setStatus('idle')
      console.error(error)
    }
  }
  const updateProfilePictureID = async (FilesId: any) => {
    // console.log('FILES ID', FilesId, 'MEMBERID', apiData.memberData.UsersId)
    const dataToSave = {
      TokensId: apiData.modelData.TokensId,
      FilesId_Avatar: FilesId,
    }
    try {
      await rootRepository.saveData({
        modelName,
        idName: 'TokensId',
        dataToSave,
        getData,
        goBack,
      })
    } catch (error: any) {
      console.error(error)
    }
  }
  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
    console.log(apiData)
  }

  if (!apiData.modelData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { modelData } = apiData
  return (
    <CCard>
      <TokensHeader status={status} saveData={saveData} onCancel={history.goBack} />
      <CCardBody>
        <CRow>
          <CCol xs='12' md='6'>
            <TokensInfo modelData={modelData} handleChange={handleChange} />
          </CCol>
          <CCol xs='12' md='6'>
            <TokensAvatarPicture
              modelData={modelData}
              updateProfilePictureID={updateProfilePictureID}
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  )
}

export default TokensDetails
