// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const DnServiceInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const { 
    DnServiceBasePackage,
    DnServiceMechanicalInspection,
    DnServiceExpressService,
    DnServiceSigningOvernighting,
    DnServicenotary,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel
            label='Base Package'
            value={DnServiceBasePackage ? DnServiceBasePackage.toString() : 'No'}
          />
          <InputLabel
            label='Mechanical Inspection'
            value={DnServiceMechanicalInspection ? DnServiceMechanicalInspection.toString() : 'No'}
          />
          <InputLabel
            label='Express Service'
            value={DnServiceExpressService ? DnServiceExpressService.toString() : 'No'}
          />
          <InputLabel
            label='Signing Overnighting'
            value={DnServiceSigningOvernighting ? DnServiceSigningOvernighting.toString() : 'No'}
          />
          <InputLabel
            label='Notary'
            value={DnServicenotary ? DnServicenotary.toString() : 'No'}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default DnServiceInfo
