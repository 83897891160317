import Form from 'react-bootstrap/Form';
import InputMask from 'react-input-mask';

const Confirmation = (props: any) => {
    const { data, jsonChange } = props;

    return (
        <>
            
            <div>
                <div className="row">
                    <div className="col-5 fz-12">
                        <b>NEW YORK STATE DEPARTMENT OF HEALTH<br />Medicaid</b>
                    </div>
                    <div className="col-7 text-end fz-21">
                        <b>Health Home Patient Information Sharing Consent</b>
                    </div>
                </div>
                <div className="border-bottom-confirm-modal"></div>
                <div className="row mt-3">
                    <div className="col-7">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder=""
                                name="NameOfHealthHome"
                                value={data.NameOfHealthHome}
                                onChange={jsonChange}
                            />
                            <Form.Text className="text-muted">
                                Name of Health Home
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>
                <div className="row mt-1">
                    <div className="col-12">
                        <b className="d-inline-block"><small>By signing this form, you agree to be in the</small></b>&nbsp;
                        <Form.Control
                            className="d-inline-block"
                            style={{ 'width': '400px' }}
                            size="sm" type="email"
                            placeholder=""
                            name="NameOfHealthHome"
                            value={data.NameOfHealthHome}
                            onChange={jsonChange}
                        />
                        &nbsp;
                        <b className="d-inline-block"><small>Health Home.</small></b>
                    </div>
                    <div className="col-12">
                        <b><small>To be in a Health Home, health care providers and other people involved in your care need to be able to talk to each other about your care and share your health information with each other to give you better care. While being in a Health Home will help make sure you get the care you
                            need, you will still be able to get health care and health insurance even if you do not sign this form or do not want to be in the Health Home</small></b>
                    </div>
                    <div className="col-12 mt-2">
                        <b><small>
                            The Health Home may get your health information, including your health records, from partners listed at the end of this form and/or from others
                            through a computer system run by the &nbsp;<Form.Control
                                className="d-inline-block"
                                style={{ 'width': '250px' }}
                                size="sm"
                                type="text"
                                name="Cardmanager"
                                value={data.Cardmanager}
                                onChange={jsonChange}
                                placeholder="" />&nbsp;
                            a Regional Health Information Organization (RHIO) and/or a computer system called PSYCKES run by the New York State Office of Mental Health,
                            and/or a computer system called TABS/CHOICES. A RHIO uses a computer system to collect and store your health information, including medical
                            records, from your doctors and health care providers who are part of the RHIO. The RHIO can only share your health information with the people
                            who you say can see or get your health information. PSYCKES is a computer system to collect and store your health treatment from your doctors
                            and health care providers who are part of the Medicaid program. TABS/CHOICES is a computer system run by the New York State Office for People
                            With Developmental Disabilities, that collects and stores information about your developmental disabilities.
                        </small></b>
                    </div>
                    <div className="col-12 mt-2">
                        <b><small>If you agree and sign this form, the Health Home and the partners listed on this form are allowed to get, see, read and copy, and share with each
                            other, ALL of your health information (including all of your health information the Health Home obtains from the RHIO and/or from PSYCKES
                            and/or from TABS/CHOICES) that they need to give you care, manage your care or study your care to make health care better for patients. The
                            health information they may get, see, read, copy and share may be from before and after the date you sign this form. Your health records may have
                            information about illnesses or injuries you had or may have had before; test results, like X-rays or blood tests; and the medicines you are now
                            taking or have taken before. Your health records may also have information on:</small></b>
                    </div>
                    <div className="col-12 mt-2">
                        <ol>
                            <li><b><small>Alcohol or drug use programs which you are in now or were in before as a patient;</small></b></li>
                            <li><b><small>Family planning services like birth control and abortion;</small></b></li>
                            <li><b><small>Inherited diseases;</small></b></li>
                            <li><b><small>HIV/AIDS;</small></b></li>
                            <li><b><small>Mental health conditions;</small></b></li>
                            <li><b><small>Developmental disability diagnosis and services; and/or</small></b></li>
                            <li><b><small>Sexually-transmitted diseases (diseases you can get from having sex).</small></b></li>
                        </ol>
                    </div>
                    <div className="col-12">
                        <b><small>Your health information is private and cannot be given to other people without your permission under New York State and U.S. laws and rules.
                            The partners that can get and see your health information must obey all these laws. They cannot give your information to other people unless you
                            agree or the law says they can give the information to other people. This is true if your health information is on a computer system or on paper.
                            Some laws cover care for HIV/AIDS, mental health records, and drug and alcohol use. The partners that use your health information and the Health
                            Home must obey these laws and rules.</small></b>
                    </div>

                    <div className="col-12 mt-2">
                        <b className="fz-16">Please read all the information on this form before you sign it.</b>
                    </div>

                    <div className="col-12 fz-16 mt-3">
                        <Form.Check className="d-inline-block" type="checkbox" /> &nbsp;
                        <b className="d-inline-block"><small>I AGREE to be in the</small></b>&nbsp;
                        <Form.Control
                            className="d-inline-block"
                            style={{ 'width': '400px' }}
                            size="sm" type="text"
                            placeholder=""
                            name="NameOfHealthHome"
                            value={data.NameOfHealthHome}
                            onChange={jsonChange}
                        />
                        &nbsp;
                        <b className="d-inline-block"><small>Health Home and.</small></b>
                        <b>agree that the Health Home can get ALL of my health information from the partners listed at the end of this form and from others through</b>
                        &nbsp; &nbsp;<Form.Control
                            className="d-inline-block"
                            style={{ 'width': '320px' }} size="sm" type="text"
                            placeholder=""
                            name="Cardmanager"
                            value={data.Cardmanager}
                            onChange={jsonChange}
                        />
                        &nbsp; &nbsp;<b><small>RHIO and/or through PSYCKES and/or through TABS/CHOICES to give
                            me care or manage my care, to check if I am in a health plan and what it covers, and to study and make the care of all patients better. I also
                            AGREE that the Health Home and the partners listed at the end of this form may share my health information with each other. I understand this
                            Consent Form takes the place of other Health Home Patient Information Sharing Consent Forms I may have signed before to share my health
                            information. I can change my mind and take back my consent at any time by signing a Withdrawal of Consent Form (DOH-5058) and giving it to
                            one of the Health Home partners.</small></b>
                    </div>
                    <div className="col-8 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                value={data.NameOfPatient}
                                name="NameOfPatient"
                                readOnly
                                onChange={jsonChange}
                            />
                            <Form.Text
                                className="text-muted"
                            >
                                Print Name of Patient
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-4 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" value={data.DOBOfPatient}
                                name="DOBOfPatient"
                                readOnly
                                onChange={jsonChange}
                                type="text" placeholder="" />
                            <Form.Text className="text-muted">
                                Patient Date of Birth
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-8 mt-2">
                        <Form.Group>
                            {/* <Form.Control size="sm" type="text" placeholder="" /> */}
                            <div>
                                <img src={data.SignatureOfPatient} width={200} height={50} />
                            </div>
                            <Form.Text className="text-muted">
                                Signature of Patient or Patient’s Legal Representative
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-4 mt-2">
                        <Form.Group>
                            <Form.Control
                                name="FormFillDate"
                                readOnly
                                onChange={jsonChange}
                                value={data.FormFillDate} size="sm" type="text" placeholder="" />
                            <Form.Text className="text-muted">
                                Date
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-8 mt-2">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder=""
                                name="NameOfLegalRepresentativeRelationship"
                                value={data.NameOfLegalRepresentativeRelationship}
                                onChange={jsonChange}
                            />
                            <Form.Text className="text-muted">
                                Print Name of Legal Representative Relationship of Legal Representative to Patient
                                (If Applicable)
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-4 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="RelationshipOfLegalRepresentative"
                                value={data.RelationshipOfLegalRepresentative}
                                onChange={jsonChange}
                            />
                            <Form.Text className="text-muted">
                                Relationship of Legal Representative to Patient
                                (If Applicable) (If Applicable)
                            </Form.Text>
                        </Form.Group>
                    </div>

                    <div className="col-12 mt-3">
                        <u> <b className="fz-16">Details About Patient Information and the Consent Process</b></u>
                    </div>

                    <div className="col-12 mt-2">
                        <div className="fw-500"><b><small>1. How will partners use my information?</small></b><br /></div>
                        &nbsp;&nbsp;&nbsp;<b><small>If you agree, partners will use your health information to:</small></b>
                        <ul>
                            <li><small>Give you health care and manage your care;</small></li>
                            <li><small>Check if you have health insurance and what it pays for; and</small></li>
                            <li><small>Study and make health care for patients better</small></li>
                        </ul>
                        <div className="mt-2">
                            <b><small>The choice you make does NOT let health insurers see your information to decide whether to give you health insurance or pay your bills. You
                                can make that choice in a separate form that health insurers must use</small></b>
                        </div>
                    </div>

                    <div className="col-12 mt-2">
                        <div className="fw-500"><b><small>2. Where does my health information come from?</small></b><br /></div>
                        <div className="mt-2">
                            <b><small>Your health information comes from places and people that gave you health care or health insurance in the past. These may include hospitals,
                                doctors, drugstores, laboratories, developmental disability providers, health plans (insurance companies), the Medicaid program, and other
                                groups that share health information. You can get a list of all the places and people by calling
                                &nbsp;<InputMask
                                    mask={'999-999-9999'}
                                    maskChar={'#'}
                                    className="d-inline-block form-control form-control-sm"
                                    style={{ 'width': '200px' }}
                                    type="text"
                                    placeholder=""
                                    name="numberOfHealthHome"
                                    value={data.numberOfHealthHome}
                                    onChange={jsonChange}
                                ></InputMask>&nbsp;
                                or talking to your care manager.</small></b>
                        </div>
                    </div>

                    <div className="col-12 mt-2">
                        <div className="fw-500"><b><small>3. What laws and rules cover how my health information can be shared?</small></b><br /></div>
                        <div className="mt-2">
                            <b><small>These laws and regulations include New York Mental Hygiene Law Section 33.13, New York Public Health Law Article 27-F, and federal
                                confidentiality rules, including 42 CFR Part 2 and 45 CFR Parts 160 and 164 (which are the rules referred to as “HIPAA”).</small></b>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="fw-500"><b><small>4. If I agree, who can get and see my information?</small></b><br /></div>
                        <div className="mt-2">
                            <b><small>The only people who can see your health information are those who you agree can get and see it, like doctors and other people who work for a
                                Health Home partner and who are involved in your health care; health care providers who are working for a Health Home partner who is giving
                                you care; and people who work for a Health Home partner who is giving you care to help them check your health insurance or to study and
                                make health care better for all patients. When you get care from a person who is not your usual doctor or provider, like a new drugstore, new
                                hospital, or other provider, some information, like what your health plan pays for or the name of your Health Home provider, may be given to
                                them or seen by them.</small></b>
                        </div>
                    </div>

                    <div className="col-12 mt-2">
                        <div className="fw-500"><b><small>5. What if a person uses my information and I didn’t agree to let them use it?</small></b><br /></div>
                        &nbsp;&nbsp;&nbsp;<b><small>If this happens, you can:</small></b>
                        <ul>
                            <li><small>call the Medicaid Helpline at 1-800-541-2831, or</small></li>
                            <li><small>contact the US Department of Health and Human Services, Office for Civil Rights at 1-800-368-1019, or submit a written complaint at:
                                https://www.hhs.gov/hipaa/filing-a-complaint/index.html</small></li>
                        </ul>
                        <b><small>You may also want to:</small></b>
                        <ul>
                            <li><small>call one of the providers you have said can see your records</small></li>
                            <li><small>call your care manager or health home:&nbsp;<Form.Control
                                className="d-inline-block"
                                style={{ 'width': '200px' }}
                                size="sm" type="text"
                                placeholder="CCMP Health Home"
                                name="NameOfHealthHome"
                                value={data.NameOfHealthHome}
                                onChange={jsonChange}
                            />&nbsp;at&nbsp;
                                <InputMask
                                    mask={'999-999-9999'}
                                    maskChar={'#'}
                                    className="d-inline-block form-control form-control-sm"
                                    style={{ 'width': '200px' }}
                                    type="text"
                                    placeholder=""
                                    name="numberOfHealthHome"
                                    value={data.numberOfHealthHome}
                                    onChange={jsonChange}
                                ></InputMask>
                                &nbsp;, or</small></li>
                            <li><small>call your Managed Care Plan if you belong to a Managed Care Plan.</small></li>
                        </ul>
                    </div>

                    <div className="col-12 mt-2">
                        <div className="fw-500"><b><small>6. How long does my consent last?</small></b><br /></div>
                        <div className="mt-1">
                            <b><small>Your consent will last until the day you take back your consent, or if you leave the Health Home program, or if the Health Home stops working</small></b>
                        </div>
                    </div>

                    <div className="col-12 mt-2">
                        <div className="fw-500"><b><small>7. What if I change my mind later and want to take back my consent?</small></b><br /></div>
                        <div className="mt-1">
                            <b><small>You can take back your consent at any time by signing a Withdrawal of Consent Form (DOH-5058) and giving it to one of the Health Home
                                partners. If you agree to share your information, all Health Home partners listed at the end of this form will be able to get your health
                                information. If you do not wish the Health Home partners listed on this form to get your health information, you need to take away your consent
                                from the Health Home program. You can get this form by calling .&nbsp;
                                <InputMask
                                    mask={'999-999-9999'}
                                    maskChar={'#'}
                                    className="d-inline-block form-control form-control-sm"
                                    style={{ 'width': '200px' }}
                                    type="text"
                                    placeholder=""
                                    name="numberOfHealthHome"
                                    value={data.numberOfHealthHome}
                                    onChange={jsonChange}
                                ></InputMask>&nbsp;
                                Your care manager will help you fill out this form if you want. Note: Even if you later decide to take back your consent, providers who already
                                have your information do not have to give your information back to you or take it out of their records.</small></b>
                        </div>
                    </div>

                    <div className="col-12 mt-2">
                        <div className="fw-500"><b><small>8. How do I get a copy of this form?</small></b><br /></div>
                        <div className="mt-1">
                            <b><small>You can have a copy of this form after you sign it.</small></b>
                        </div>
                    </div>
                </div>
                <div className="border-bottom-confirm-modal mt-2"></div>
                <div className="row mt-3">
                    <div className="col-5">
                        <Form.Group>
                            <Form.Control
                                className="d-inline-block"
                                size="sm"
                                type="text"
                                placeholder=""
                                name="NameOfHealthHome"
                                value={data.NameOfHealthHome}
                                onChange={jsonChange}
                            />
                            <Form.Text>
                                <b><small>Name of Health Home</small></b>
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-7">
                        <b className="fw-500 fz-16">Copy this page as necessary to list all participating partners</b>
                    </div>

                    <div className="col-12 mt-3">
                        <b className="d-inline-block">Member Name:</b>
                        &nbsp;&nbsp;
                        <Form.Control
                            name="MemberName"
                            readOnly
                            onChange={jsonChange}
                            value={data.MemberName}
                            className="d-inline-block" style={{ 'width': '250px' }} size="sm" type="text" placeholder="" />
                        &nbsp;&nbsp;
                        <b className="d-inline-block">CIN:</b>
                        &nbsp;&nbsp;
                        <Form.Control
                            className="d-inline-block"
                            style={{ 'width': '250px' }}
                            size="sm"
                            type="text"
                            placeholder=""
                            name="Cin"
                            value={data.Cin}
                            onChange={jsonChange}
                        />
                    </div>

                    <div className="col-12 mt-4">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th className="fz-14 fw-500" style={{ 'width': '34%' }} >Name of Participating Partner</th>
                                    <th className="fz-14 fw-500" style={{ 'width': '20%' }}>MEMBER INITIALS</th>
                                    <th className="fz-14 fw-500" style={{ 'width': '13%' }}>DATE</th>
                                    <th className="fz-14 fw-500" style={{ 'width': '20%' }}>STAFF INITIALS</th>
                                    <th className="fz-14 fw-500" style={{ 'width': '13%' }}>DATE</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="NameofParticipatingPartner1"
                                        value={data.NameofParticipatingPartner1}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="MemberInitials1"
                                        value={data.MemberInitials1}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="MemberInitialsDate1"
                                        value={data.MemberInitialsDate1}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="StaffInitials1"
                                        value={data.StaffInitials1}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="StaffInitialsDate1"
                                        value={data.StaffInitialsDate1}
                                        onChange={jsonChange}
                                    /></td>
                                </tr>
                                <tr>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="NameofParticipatingPartner2"
                                        value={data.NameofParticipatingPartner2}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="MemberInitials2"
                                        value={data.MemberInitials2}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="MemberInitialsDate2"
                                        value={data.MemberInitialsDate2}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="StaffInitials2"
                                        value={data.StaffInitials2}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="StaffInitialsDate2"
                                        value={data.StaffInitialsDate2}
                                        onChange={jsonChange}
                                    /></td>
                                </tr>
                                <tr>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="NameofParticipatingPartner3"
                                        value={data.NameofParticipatingPartner3}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="MemberInitials3"
                                        value={data.MemberInitials3}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="MemberInitialsDate3"
                                        value={data.MemberInitialsDate3}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="StaffInitials3"
                                        value={data.StaffInitials3}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="StaffInitialsDate3"
                                        value={data.StaffInitialsDate3}
                                        onChange={jsonChange}
                                    /></td>
                                </tr>
                                <tr>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="NameofParticipatingPartner4"
                                        value={data.NameofParticipatingPartner4}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="MemberInitials4"
                                        value={data.MemberInitials4}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="MemberInitialsDate4"
                                        value={data.MemberInitialsDate4}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="StaffInitials4"
                                        value={data.StaffInitials4}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="StaffInitialsDate4"
                                        value={data.StaffInitialsDate4}
                                        onChange={jsonChange}
                                    /></td>
                                </tr>
                                <tr>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="NameofParticipatingPartner5"
                                        value={data.NameofParticipatingPartner5}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="MemberInitials5"
                                        value={data.MemberInitials5}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="MemberInitialsDate5"
                                        value={data.MemberInitialsDate5}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="StaffInitials5"
                                        value={data.StaffInitials5}
                                        onChange={jsonChange}
                                    /></td>
                                    <td><Form.Control size="sm" type="text" placeholder=""
                                        name="StaffInitialsDate5"
                                        value={data.StaffInitialsDate5}
                                        onChange={jsonChange}
                                    /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="border-bottom-confirm-modal"></div>
            </div>
            <div className="row mt-4">
                <div className="col-12 text-center">
                    <b className="page-popup-title">Bill of Rights</b>
                </div>
                <div className="col-12 mt-2">
                    <b>Jemcare informs all persons served of their rights and responsibilities, provides fair and equitable treatment, and
                        provides sufficient information to make an informed choice about using our services. Outlined below are our rights and
                        responsibilities so that you (and your family) have the information to make informed decisions about the services you
                        are receiving as well as information if you have any questions or concerns.</b>
                </div>
                <div className="col-12 mt-2">
                    <b>As a participant in the Care Management Program, members have the right, consistent with law, to:</b>
                </div>
                <div className="col-12 mt-2">
                    <ul>
                        <li>Understand and use these rights. If for any reason you do not understand these rights, or need help to exercise
                            them, Jemcare MUST provide assistance, including an interpreter;</li>
                        <li>All persons shall be given the respect and dignity that is extended to others regardless of race, religion, national
                            origin, creed, age, gender, ethnic background, sexual orientation, developmental disability or other handicap,
                            health condition, such as one tested for or diagnosed as having HIV infection. In addition, there shall be no
                            discrimination for these or any other reasons; </li>
                        <li>No person shall be denied:
                            <ul>
                                <li>A safe and sanitary environment</li>
                                <li>Freedom from physical and psychological abuse;</li>
                                <li>Freedom from corporal punishment</li>
                                <li>Freedom from unnecessary restraints</li>
                                <li>Protection from commercial or other exploitation</li>
                                <li>Confidentiality with regard to all information contained in the person’s record, and access to such
                                    information, subject to the provisions of article 33 of the Mental Hygiene Law. In addition,
                                    confidentiality with regard to HIV-related information shall be maintained in accordance with article
                                    27-F of the Public Health Law; </li>
                            </ul>
                        </li>
                        <li>Privacy and confidentiality with respect to all of the health care services members receive and records
                            documenting those services, and the assurance that the privacy and confidentiality of each individual’s
                            treatment/medical records will be maintained in accordance with the requirements of the Health Insurance
                            Portability and Accountability Act (HIPAA);</li>
                        <li>Approve or refuse the release or disclosure of the contents of your medical record to any health care practitioner
                            and/or health-care facility except as required by law or third-party payment contract in accordance with section
                            33.16 of the Mental Hygiene Law;</li>
                        <li>Be informed of the services available at Jemcare and the provisions for off-hour emergency coverage;</li>
                        <li>Receive emergency services, when required</li>
                        <li>Be informed of the name, position, and functions of the Jemcare staff in charge of the health care coordination
                            services and any other Jemcare staff involved in the member’s care;
                        </li>
                        <li>Receive information needed to give informed consent for all proposed care coordination services, health and
                            behavioral health treatment. This information shall include the possible risks and benefits of the services;
                        </li>
                        <li>Refuse care coordination/management and be told what effect this may have, if any, on the member’s health
                            care and/or Jemcare services</li>
                        <li>Refuse to take part in research. In deciding whether or not to participate, members have the right to a full
                            explanation of the nature of the research and any potential consequences, whether negative or positive;</li>
                        <li>Participate in all decisions about the care coordination plan developed by each member and the Care Manager;</li>
                    </ul>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 text-center">
                    <b className="page-popup-title">Bill of Rights (Cont.)</b>
                </div>
                <div className="col-12 mt-2">
                    <ul>
                        <li>The opportunity to object to any provision within the care coordination plan, and the opportunity to appeal any
                            decision with which you disagree, made in relation to his or her objection to the plan;</li>
                        <li>Obtain from the Care Manager, complete and current information concerning his/her care plan, diagnosis,
                            treatment, and prognosis in terms that s/he can be reasonably expected to understand</li>
                        <li>Review, copy, and/or provide a written response to review your individual record in accordance with the
                            provision of Mental Hygiene Law 33.16. Jemcare can charge a reasonable fee. Members cannot be denied a
                            copy solely because of their inability to pay;
                        </li>
                        <li>All care coordination services are provided without charge to the member;</li>
                        <li>You have the right to be informed of Jemcare’s grievance procedure and express complaints, without fear of
                            reprisal, about the care and services provided, and to have Jemcare investigate such complaints.</li>
                    </ul>
                </div>
                <div className="col-6 mt-3">
                    <Form.Group>
                        <Form.Control
                            name="MemberName"
                            readOnly
                            onChange={jsonChange}
                            value={data.MemberName} className="d-inline-block" size="sm" type="text" placeholder="" />
                        <Form.Text>
                            <b><small>Member’s Name (PRINT)</small></b>
                        </Form.Text>
                    </Form.Group>
                </div>
                <div className="col-6 mt-3">
                    <Form.Group>
                        <Form.Control
                            name="FormFillDate"
                            readOnly
                            onChange={jsonChange}
                            value={data.FormFillDate} className="d-inline-block" size="sm" type="text" placeholder="" />
                        <Form.Text>
                            <b><small>Date</small></b>
                        </Form.Text>
                    </Form.Group>
                </div>
                <div className="col-6 mt-2">
                    <Form.Group>
                        {/* <Form.Control className="d-inline-block" size="sm" type="text" placeholder="" /> */}
                        <div>
                            <img src={data.SinatureOfMember} width={200} height={50} />
                        </div>
                        <Form.Text>
                            <b><small>Member’s Signature</small></b>
                        </Form.Text>
                    </Form.Group>
                </div>
            </div>
            <div className="border-bottom-confirm-modal mt-3"></div>
            <div className="row mt-4">
                <div className="col-12 text-center">
                    <b className="page-popup-title">Notice of Privacy Practices</b>
                </div>
                <div className="col-12 mt-2 text-center">
                    <b>HIPAA: Health Insurance Portability and Accountability Act</b>
                </div>
                <div className="col-12 mt-2">
                    <b><small>This notice describes how medical and mental health care information about you may be used and disclosed and
                        how you can get access to this information</small></b>
                </div>
                <div className="col-12 mt-2">
                    <div className="fw-500"><b><small>OUR RESPONSIBILITIES:</small></b><br /></div>
                    <b><small>Jemcare is required to maintain the privacy of health information. Jemcare must provide you with this notice indicating
                        our legal duties and privacy practices regarding the information we collect and maintain. Jemcare must abide by the
                        terms of this notice, notify you if we are unable to agree to a requested restriction and accommodate reasonable requests
                        for communicating information to you via alternative means or at alternative locations. Jemcare reserves the right to
                        change practices and to make the new provisions effective for all protected health information (PHI) that we maintain.
                        Should our privacy practices change, we will provide you with a revised notice. Jemcare will use you or your child's
                        health information to provide services or treatment, obtain payment for services or treatment you have received to
                        collect data </small></b>
                </div>
                <div className="col-12 mt-2">
                    <div className="fw-500"><b><small>UNDERSTAND YOUR CASE RECORD INFORMATION:</small></b><br /></div>
                    <b><small>Each time you/your child enroll in a program for services, a record is made. Typically, the case record contains the
                        following information: (1) the reason(s) for admission to our program, (2) symptoms or presenting challenges, (3)
                        evaluations and test results, (4) medication information, (5) diagnoses, (6) the treatment or service plan, (7) notes that
                        address progress toward meeting service goals, (8) periodic treatment or service plan reviews, and (9) a plan for future
                        care or treatment in our program(s). At Jemcare, this information is documented in what is often referred to as the 'Case
                        Record." The Case Record serves as a basis for planning care and treatment. It is a means of communication among the
                        health professionals who may contribute to your or your child's care. Understanding what is in the record and how
                        health information is used is important, it helps you to: (1) ensure its accuracy; (2) better understand who, what, when,
                        where, and why others may access health information; and (3) make more informed decisions when authorizing
                        disclosure of information to others.</small></b>
                </div>
                <div className="col-12 mt-2">
                    <div className="fw-500"><b><small>YOUR HEALTH INFORMATION RIGHTS:</small></b><br /></div>
                    <b><small>Unless otherwise required by law. the case record is the physical property Jemcare’s Care Management program. The
                        information in the case record belongs to you. You have the right to request a restriction on certain uses and disclosures
                        of information, and to request changes or additions to the information in the case record. Your rights include: (1) the
                        right to obtain a paper copy of this Privacy Notice; (2) the right to inspect and obtain a copy of the case record; (3) the
                        right to obtain an accounting of disclosures of health information; and (4) you can request copies of health information
                        by alternative means (e.g., by fax, or e-mail) or delivery at other locations. You have the right to revoke your
                        authorization to use or disclose health information except to the extent that action has already been taken, or as required
                        by law. All requests regarding these rights must be in writing</small></b>
                </div>
                <div className="col-12 mt-2">
                    <div className="fw-500"><b><small>EXAMPLES OF USES AND DISCLOSURES PERMITTED FOR TREATMENT, PAYMENT, AND HEALTH
                        OPERATIONS:</small></b><br /></div>
                    <b><small>We will use your health information for treatment or services. For example: Information will be recorded in your case
                        record and used to determine the services or treatment that should work best for you. Staff will document in your record
                        their expectations of the members of your service or treatment team. Members of the team will then record the actions
                        that they took in response to this plan, and their observations. Jemcare will provide other clinical staff with copies of
                        various reports that will assist them in treating or working with you. We will use your health information for payment.
                        For example: A bill may be given to you or a third-party payer such as Medicaid. The information on or accompanying
                        the bill may include information that identifies you, as well as your diagnosis and services that were provided. We will
                        use your health information for regular health care operations. For example: Members of the Jemcare staff or the quality
                        assurance staff may use information in your case record to assess the care and outcomes in your case or to determine
                        how effective the program's services are. Emergency medical information may be provided as needed and we may
                        disclose information to the NYS Central Registry, as required by law. Business Associates: There are some services</small></b>
                </div>
            </div>
            <div className="row mt-4">
                <div className="col-12 text-center">
                    <b className="page-popup-title">Notice of Privacy Practices (Cont.)</b>
                </div>
                <div className="col-12 mt-2 text-center">
                    <b>HIPAA: Health Insurance Portability and Accountability Act</b>
                </div>
                <div className="col-12 mt-2">
                    <b><small>
                        provided at Jemcare through contracts with Business Associates. Examples include our Insurance Company whom we
                        use when making reports of accident/injuries on our property, and NYS Office of Mental Health (OMH) /NYS
                        Department of Health or NYC Administration for Children's Services (ACS). When these services are contracted, we
                        may disclose some or all your health information to our Business Associate so that they can perform the job that we've
                        asked them to do. To protect your health information, we require the Business Associate to appropriately safeguard
                        your information and we only provide them with the 'minimum necessary". Communication with Family: Jemcare
                        professionals may disclose health or payment information related to your care to a person you identify such as a family
                        member, other relatives, or close personal friends. Unless it is an emergency, your consent will be secured prior to these
                        disclosures. Food and Drug Administration (FDA): We may disclose to the FDA health information relative to adverse
                        events, as defined and required by law. Workers Compensation: We may disclose health information to the extent
                        required by law related to workers compensation or similar programs. Public Health: We may disclose your health
                        information to public health or legal authorities charged with preventing or controlling disease, injury, or disability, as
                        required by law. Correctional Institution: Should you become an inmate of a correctional institution, we may disclose
                        to the institution or agents thereof health information necessary for your health and the health and safety of other
                        individuals, as required by law. An inmate does not have the right to the Notice of Privacy Practices. Law Enforcement:
                        We may disclose health information for law enforcement purposes as required by law or in response to a valid subpoena.
                        Federal law makes provision for your health information to be released to an appropriate health oversight agency, public
                        health authority, or attorney, provided that an employer or business associate believes in good faith that we have engaged
                        in unlawful conduct or have otherwise violated professional or clinical standards and are potentially endangering one
                        or more patients, workers or the public. Notice of Privacy Practices Availability: All recipients of service receive this
                        notice in writing upon admission to the program
                    </small></b>
                </div>
                <div className="col-12 mt-2">
                    <div className="fw-500"><b><small>FOR MORE INFORMATION OR TO REPORT A PROBLEM:</small></b><br /></div>
                    <b><small>If you have questions and would like additional information, you may contact the Jemcare Privacy Officer, Martin
                        Itkowitz at (718) 506-0725. If you believe that your privacy rights have been violated, you can also file a complaint
                        with Quality Improvement staff. There will be no retaliation for filing a complaint.
                    </small></b>
                </div>
                <div className="col-6 mt-3">
                    <Form.Group>
                        <Form.Control
                            name="MemberName"
                            readOnly
                            onChange={jsonChange}
                            value={data.MemberName} className="d-inline-block" size="sm" type="text" placeholder="" />
                        <Form.Text>
                            <b><small>Member’s Name (PRINT)</small></b>
                        </Form.Text>
                    </Form.Group>
                </div>
                <div className="col-6 mt-3">
                    <Form.Group>
                        <Form.Control
                            name="FormFillDate"
                            readOnly
                            onChange={jsonChange}
                            value={data.FormFillDate} className="d-inline-block" size="sm" type="text" placeholder="" />
                        <Form.Text>
                            <b><small>Date</small></b>
                        </Form.Text>
                    </Form.Group>
                </div>
                <div className="col-6 mt-2">
                    <Form.Group>
                        {/* <Form.Control className="d-inline-block" size="sm" type="text" placeholder="" /> */}
                        <div>
                            <img src={data.SinatureOfMember} width={200} height={50} />
                        </div>
                        <Form.Text>
                            <b><small>Member’s Signature</small></b>
                        </Form.Text>
                    </Form.Group>
                </div>
            </div>
        </>
    )
}

export default Confirmation;