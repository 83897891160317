import { CButton } from '@coreui/react'
import React from 'react'
interface PropsLoadingButton {
  color?: string
  className?: string
  spinnerClassName?: string
  loading?: any
  onClick?: any
  children?: any
  style?: any
  disabled?: boolean
  size?: string
}
const LoadingButton: React.FC<PropsLoadingButton> = ({
  color = 'primary',
  className = '',
  spinnerClassName = '',
  loading,
  onClick,
  children,
  style = {},
  disabled = false,
  size = 'md',
}) => {
  return (
    <CButton
      disabled={disabled ? disabled : loading}
      color={color}
      type='button'
      className={`position-relative c-loading-button ${className} ${
        loading ? 'c-loading-button-loading' : ''
      }`}
      onClick={onClick}
      style={style}
      size={size}
    >
      <span
        className={`c-loading-button-spinner spinner-border spinner-border-sm ${spinnerClassName}`}
        role='status'
        aria-hidden='true'
      ></span>
      {children}
    </CButton>
  )
}

export default LoadingButton
