// Generated with util/create-search-page.js
import { CButton, CCol, CFormGroup, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react'
import axios from 'axios'
import { DataTable } from 'library-soft-datatable'
import { useEffect, useRef, useState } from 'react'
// import { toast } from 'react-toastify'
import { addAuthAccessTokenToHeader, getAuthToken, logoutVerifyErrors } from 'src/services/authHelper'
import { BASE_API, MODELS, RETRIEVE, SERVICES, LIST, SAVE, WIDGETS } from 'src/services/routeConst'
import { BuyersTableColumns, UsersTableColumns } from './LeadTableColumns'
// import { RadioGroup, Radio } from 'react-radio-input'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange?: any
  title?: string
}

const LeadAssignedCard = ({
  modelData,
}: PropsInfoForm) => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    // setStateAdd(!stateAdd)
    setModal(!modal)
  }
  const { 
    TokensId_AssignedTo,
  } = modelData  
  let {InspectionDate} = modelData
  
  const [tokenData, setTokenData] = useState([{
    Id: 0,
    FullName: ''
  }])
  const [assignData, setAssignData] = useState([{}])
  // const [inspectorList, setInspectorList] = useState([])
  // const RadioGroupTs: any = RadioGroup
  const tableRef = useRef<any>()
    
  const [tokenType, setTokenType] = useState('BuyersContacts')

  const fetchTokenData = async (TokensId) => {
    try {
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
        {
          Model: 'Tokens',
          Id: TokensId,
          Params: [],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      setTokenData(data.data.RetrieveInfo[0])

      if(data.data.RetrieveInfo[0].TokenType === 'users') {
        const userData = await axios.post(
          `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
          {
            Model: 'AdminUsers',
            Id: data.data.RetrieveInfo[0].TokenCode,
            Params: [
            ],
          },
          { ...addAuthAccessTokenToHeader() },
        )
        setAssignData(userData.data.data.RetrieveInfo[0])
      }
      else {
        const userData = await axios.post(
          `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
          {
            Model: 'BuyersContacts',
            Id: data.data.RetrieveInfo[0].TokenCode,
            Params: [
            ],
          },
          { ...addAuthAccessTokenToHeader() },
        )
        setAssignData(userData.data.data.RetrieveInfo[0])
      }

    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }

  const updateInspector = async ({ id }: any) => {
    console.log('Update', id);
    
    const {data} = await axios.post(
      `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
      {
        Model: tokenType,
        Id: id,
        Params: [
        ],
      },
      { ...addAuthAccessTokenToHeader() },
    )

    await axios.patch(
      `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
      {
        Model: 'Leads',
        Id: modelData.Id,
        Params: [
          {
            name: 'TokensId_AssignedTo',
            value: data.data.RetrieveInfo[0].TokensId
          }
        ],
      },
      { ...addAuthAccessTokenToHeader() },
    )
    

    modelData.TokensId_AssignedTo = data.data.RetrieveInfo[0].TokensId
    setModal(false)
  }

  useEffect(() => {
    if(TokensId_AssignedTo){
      fetchTokenData(TokensId_AssignedTo)
    }
  }, [TokensId_AssignedTo])
  
  const actionFunctions: any = {
    select: updateInspector
  }

  const formatDate = (date) => {
    if(date) {
      date = date.split('-')
      const day = String(date[0])
      const month = String(date[1])
      const year = String(date[2])
      if(day.length===2){
        return `${year}-${day}-${month}`
      }
      return `${day}-${month}-${year}`
    }
    return ''
  }
  InspectionDate = formatDate(InspectionDate)

  const card = <>
  <div style={{display: 'flex'}}>
    <div className='inspector-card' onClick={toggle}>
      <div className="item-avatar">
        {/* <span style={{ border: 'solid 1px #ddd', borderRadius: '100%' }}> */}
          {/* <Avatar name={`${tokenData['FullName'] || ''}`} FilesId_Avatar={0}></Avatar> */}
        {/* </span> */}
        <span style={{ border: 'solid 1px #ddd', borderRadius: '100%', width: '34px', height: '34px' }}>
                    {/* <Avatar name={n.UserName} FilesId_Avatar={0}></Avatar> */}
                    <img
                      src={`${BASE_API}${SERVICES}${WIDGETS}/Files/Download/${tokenData['FileGuid']}`}
                      style={{ borderRadius: '50%', width: '40px', height: '40px' }}
                      alt=''
                    />
                  </span>
      </div>
      <div className='item-name'>
        { tokenData['TokenType'] === 'users' 
          ? `${assignData['FirstName'] || ''} ${assignData['LastName'] || ''}`
          : `${assignData['BuyersName'] || ''}`
        }
      </div>
      {/* <div className='inspector-price'>
        <i>
          ${ Cost['Amount'] ? Cost['Amount'].toFixed(2) : `0.00`}
        </i>
      </div> */}
      <div className='item-description'>
        {/* {tokenData['Address'] || ''}
        <br />{tokenData['City'] || ''} {tokenData['State'] || ''} */}
        { tokenData['TokenType'] === 'users' 
          ? `${assignData['Phone'] || ''}` 
          : `${assignData['Name'] || ''}`
        }
        <br />
        { tokenData['TokenType'] === 'BuyersContacts' && `${assignData['Phone'] || ''}`}
      </div>
    </div>
    <div className='inspector-button'>
      <button className='item-btn btn btn-primary' /*onClick={()=>sendSms(tokenData)}*/>Send</button>
    </div>
  </div>
  </>
  const unassigned = <div className='inspector-card-unassigned' onClick={toggle}>
    Click Here to Assign 
    </div>

  const onChangeValue = (event) => {
    console.log(event.target.value);
    setTokenType(event.target.value)
  }
  return (
    <>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-4 mb-0'>
          {TokensId_AssignedTo ? card : unassigned}
        </CCol>
      </CFormGroup>

    <CModal
        size='xl'
        show={modal}
        onClose={toggle}
      >      
        <CModalHeader closeButton>Choose</CModalHeader>
        <CModalBody className={'appointment-body'}>
        <div>
        
        <div style={{display:'flex'}} onChange={onChangeValue}>
          <label style={{marginRight: '1em', display: 'flex', alignItems: 'center', marginBottom:0}}>
            <input style={{marginRight:'3px'}} defaultChecked type="radio" value="BuyersContacts" name="tokenType" />
              Buyers
          </label>
          <label style={{display: 'flex', alignItems: 'center', marginBottom:0}}>
            <input style={{marginRight:'3px'}} type="radio" value="AdminUsers" name="tokenType" />
              Users
          </label>
        </div>
        <hr />
        { tokenType === 'BuyersContacts' && 
          <DataTable
          columnData={BuyersTableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={'BuyersContacts'}
          actionClick={(type: any, data: any) => {
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          paginationPosition='bottom-right'
          defaultPageSize={10}
        />
        }
        {
          tokenType === 'AdminUsers' &&
          <DataTable
            columnData={UsersTableColumns}
            theme='white'
            noHeader
            endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
            modelName={'AdminUsers'}
            actionClick={(type: any, data: any) => {
              actionFunctions[type]({ id: data })
            }}
            ref={tableRef}
            apiKey={getAuthToken('ADMIN')}
            paginationPosition='bottom-right'
            defaultPageSize={10}
          />
        }
        </div>  
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={toggle}
          >Cancel</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default LeadAssignedCard
