import React, { useState } from 'react'
import { CButton, CCol, CForm, CRow } from '@coreui/react'
import { connect } from 'react-redux'
import { setUser } from 'src/redux/actions/authActions'
import { InputField, LinearProgressIndicator } from 'src/shared'
import { login } from '././../../services/authHelper'

const Login: React.FC<any> = () => {
  const [UserName, setUserName] = useState(localStorage.getItem('VCA_AUTH_UserName') || '')
  const [Password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  // const rememberMeRef = React.useRef(false)
  const auth = async () => {
    try {
      setIsLoading(true)
      await login({ accessToken: '', Password, UserName, setRedirectToReferrer: () => {} })
      localStorage.setItem('ADMIN_AUTH_UserName', UserName)
      localStorage.setItem('ADMIN_AUTH_Password', Password)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
    }
  }
  return (
    <div
      style={{
        backgroundColor: 'white',
      }}
      className='c-app c-default-layout flex-row align-items-center'
      onKeyUp={(e) => e.key === 'Enter' && auth()}
    >
      <div className='login login-with-news-feed'>
        <div className='news-feed'>
          <div className='news-image'></div>
        </div>
        <div className='login-container'>
          <div className='login-header mb-30px'>
            <div className='brand'>
              <div className='d-flex align-items-center'>
                <span className='logo'></span>
                <b>Admin</b> Panel
              </div>
              <small>Administrative Control Panel</small>
            </div>
          </div>
          <div className='login-content'>
            <CForm className='w-100'>
              <InputField
                style={{ width: '100% !important', height: '15rem !important' }}
                type='username'
                name='UserName'
                label='UserName'
                value={UserName}
                onChange={({ value }: any) => {
                  setUserName(value)
                }}
              />
              <br />

              <InputField
                type='password'
                name='Password'
                label='Password'
                value={Password}
                showButton
                onChange={({ value }: any) => setPassword(value)}
              />
              <br />

              {/* <CRow>
                  <CCol>
                    <CFormGroup
                      className='d-flex align-items-center'
                      variant='custom-checkbox'
                      inline
                    >
                      <CInputCheckbox
                        custom
                        id='inline-checkbox1'
                        name='inline-checkbox1'
                        value='option1'
                        onChange={() => (rememberMeRef.current = !rememberMeRef.current)}
                      />
                      <CLabel variant='custom-checkbox' htmlFor='inline-checkbox1'>
                        <small style={{ userSelect: 'none', color: 'red !important' }}>
                          Remember Me
                        </small>
                      </CLabel>
                    </CFormGroup>
                  </CCol>
                  <CCol xs='6' className='text-right'>
                    <p
                      className='text-primary font-weight-bold'
                      style={{ cursor: 'pointer', userSelect: 'none' }}
                    >
                      Forgot Password
                    </p>
                  </CCol>
                </CRow> */}
              <CRow>
                <CCol xs='12'>
                  <CButton
                    color='primary'
                    /* className={`w-100 position-relative c-loading-button ${
                        loggingIn ? 'c-loading-button-loading' : ''
                      }`} */
                    disabled={isLoading}
                    className={`w-100 position-relative c-loading-button`}
                    onClick={auth}
                  >
                    <span
                      className='c-loading-button-spinner spinner-border spinner-border-sm'
                      role='status'
                      aria-hidden='true'
                    ></span>
                    Login
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
            <div className='text-gray-600 action-copy'>© Admin Panel All Right Reserved 2022</div>
          </div>
        </div>
      </div>
      {isLoading && <LinearProgressIndicator></LinearProgressIndicator>}
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    loggedInUser: state.loggedInUser,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: (data: any) => dispatch(setUser(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
