// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { SmartNotes } from 'smart-notes'
import { getAccessToken, getAuthToken } from 'src/services/authHelper'
import { Note } from 'src/shared/components/Notes/Note'
// import Avatar from 'src/shared/components/Avatar/Avatar'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  title?: string
  MetasId: any
  id?: any
}

const NotesInfo = ({ MetasId }: PropsInfoForm) => {
  return (
    <>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <SmartNotes
            metasId={MetasId}
            auth={getAuthToken()}
            accessToken={getAccessToken()}
            placeholder='Enter a note...'
            classNames={{
              input: 'input-field',
              root: 'input-field-root',
            }}
            renderNotes={(notes) => {
              return <Note notes={notes} />
            }}
          />
        </CCol>
      </CFormGroup>
    </>
  )
}

export default NotesInfo
