// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const VehicleInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const { VehicleVin, VehicleMileage, VehicleYear, VehicleMake, VehicleModel, VehicleTrim, VehicleColor } = modelData
  return (
<FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            label={'Year'}
            value={VehicleYear ?? '⠀'}
          />
          {/* <InputField
            name='VehicleYear'
            type='number'
            label='Year'
            value={VehicleYear ?? ''}
            onChange={handleChange}
            alignment='right'
          /> */}
            <InputLabel
              // name='VehicleMake'
              // type='text'
              label='Make'
              value={VehicleMake ?? '⠀'}
              // onChange={handleChange}
            />
          <InputLabel
            // name='VehicleModel'
            // type='text'
            label='Model'
            value={VehicleModel ?? '⠀'}
            // onChange={handleChange}
          />
          <InputLabel
            // name='VehicleTrim'
            // type='text'
            label='Trim'
            value={VehicleTrim ?? '⠀'}
            // onChange={handleChange}
          />
          <InputLabel
            // name='Vin'
            // type='text'
            label='Vin'
            value={VehicleVin ?? '⠀'}
            // onChange={handleChange}
          />
          <InputLabel
            // name='Mileage'
            // type='number'
            label='Mile age'
            value={VehicleMileage ?? '⠀'}
            // onChange={handleChange}
            // alignment='right'
          />
          <InputLabel
            // name='VehicleColor'
            // type='text'
            label='Color'
            value={VehicleColor ?? '⠀'}
            // onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default VehicleInfo
