import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const PharmaTableColumns: ColumnData[] = [
  {
    label: 'First Name',
    name: 'FirstName',
  },
  {
    label: 'Last Name',
    name: 'LastName',
  },
  {
    label: 'City',
    name: 'City',
  },
  {
    label: 'Date Of Birth',
    name: 'DateOfBirth',
  },
  {
    label: 'User Name',
    name: 'UserName',
  },
  {
    label: 'Facility',
    name: 'Facility',
  },
  {
    label: 'Is Medicare',
    name: 'MedicareYesNo',
  },
  {
    label: 'Is Sent',
    name: 'IsSent',
  },
  {
    label: 'Is Closed',
    name: 'IsClosed',
  },
  {
    label: 'Status',
    name: 'PharmacyStatus',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    customWidth: 10,
    sortable: false,
  },
]
