import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { MembersRepository } from 'src/repository/admin/member/MembersRepository'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'

interface PropsEditMemberSettingsModal {
  showModal?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}

const fields = [
  'SettingsId',
  'UsersId',
  'AsOfDate',
  'IsFrozen',
  'PaymentDay',
  'IsAutopay',
  'AutopayType',
  'Autopay Bank',
  'AutopayAmount',
  'IsCreditAlerts',
  'IsPaymentReceivedAlerts',
  'IsPaymentReminderAlerts',
  'IsStatementReceivedAlerts',
  'IsSpendTrackingAlerts',
  'SpendTrackingAlertsAmount',
  'IsTransactionAlerts',
  'TransactionAlertsAmount',
  'IsECommerceTransactionAlerts',
  'IsSubscriptionTransactionAlerts',
  'IsInStoreTransactionAlerts',
]

const EditMemberSettingsModal: React.FC<PropsEditMemberSettingsModal> = ({
  showModal = false,
  setShowModal,
  modalData,
  callBack = () => {},
}: PropsEditMemberSettingsModal) => {
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<any>()
  const { id, UsersId, modelName } = modalData

  const membersRepository = React.useMemo(() => new MembersRepository(), [])

  useEffect(() => {
    const getDataInEffect = async () => {
      try {
        const { mainData } = await membersRepository.getData({
          id,
          modelName: modelName,
          defaultMainData: { UsersId, SettingsId: 0 },
        })
        setData(mainData)
      } catch (error: any) {
        console.error(error)
      }
    }
    loadingData && getDataInEffect()
  }, [UsersId, id, loadingData, membersRepository, modelName])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    data && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    const dataToSave: any = data
    try {
      await membersRepository.saveData({
        modelName: modelName,
        idName: 'SettingsId',
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
      })
    } catch (error: any) {
      console.error(error)
    }
    // setData({})
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setData((st: any) => ({ ...st, [name]: value }))
  }

  if (loadingData) {
    return null
  }
  // console.log(data)
  return (
    <CModal
      size={'xl'}
      show={showModal}
      onClose={() => {
        // setData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} Settings</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='Settings Info'>
              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='AsOfDate'
                    disabled={true}
                    type='text'
                    label='As Of Date'
                    value={data.AsOfDate}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsFrozen'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Frozen'
                  >
                    <CustomSwitch
                      name='IsFrozen'
                      className='d-block'
                      value={data.IsFrozen}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsAutopay'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Autopay'
                  >
                    <CustomSwitch
                      name='IsAutopay'
                      className='d-block'
                      value={data.IsAutopay}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='PaymentDay'
                    label='Payment Day'
                    inputStyles={{
                      textAlign: 'right',
                    }}
                    value={data.PaymentDay}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='AutopayType'
                    label='Autopay Type'
                    inputStyles={{
                      textAlign: 'right',
                    }}
                    value={data.AutopayType}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='Autopay Bank'
                    label='BanksId_Autopay'
                    value={data.BanksId_Autopay}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='AutopayAmount'
                    label='Autopay Amount'
                    type='currency'
                    alignment='right'
                    value={data.AutopayAmount}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsCreditAlerts'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Credit Alerts'
                  >
                    <CustomSwitch
                      name='IsCreditAlerts'
                      className='d-block'
                      value={data.IsCreditAlerts}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsPaymentReceivedAlerts'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Payment Received Alerts'
                  >
                    <CustomSwitch
                      name='IsPaymentReceivedAlerts'
                      className='d-block'
                      value={data.IsPaymentReceivedAlerts}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsPaymentReminderAlerts'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Payment Reminder Alerts'
                  >
                    <CustomSwitch
                      name='IsPaymentReminderAlerts'
                      className='d-block'
                      value={data.IsPaymentReminderAlerts}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsStatementReceivedAlerts'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Statement Received Alerts'
                  >
                    <CustomSwitch
                      name='IsStatementReceivedAlerts'
                      className='d-block'
                      value={data.IsStatementReceivedAlerts}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsSpendTrackingAlerts'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Spend Tracking Alerts'
                  >
                    <CustomSwitch
                      name='IsSpendTrackingAlerts'
                      className='d-block'
                      value={data.IsSpendTrackingAlerts}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsTransactionAlerts'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Transaction Alerts'
                  >
                    <CustomSwitch
                      name='IsTransactionAlerts'
                      className='d-block'
                      value={data.IsTransactionAlerts}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsSubscriptionTransactionAlerts'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Subscription Transaction Alerts'
                  >
                    <CustomSwitch
                      name='IsSubscriptionTransactionAlerts'
                      className='d-block'
                      value={data.IsSubscriptionTransactionAlerts}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsECommerceTransactionAlerts'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='ECommerce Transaction Alerts'
                  >
                    <CustomSwitch
                      name='IsECommerceTransactionAlerts'
                      className='d-block'
                      value={data.IsECommerceTransactionAlerts}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsInStoreTransactionAlerts'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='In Store Transaction Alerts'
                  >
                    <CustomSwitch
                      name='IsInStoreTransactionAlerts'
                      className='d-block'
                      value={data.IsInStoreTransactionAlerts}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
              </CFormGroup>

              <CFormGroup row>
                <CCol xs='6' sm='6'>
                  <InputField
                    name='SpendTrackingAlertsAmount'
                    label='Spend Tracking Alerts Amount'
                    type='currency'
                    alignment='right'
                    value={data.SpendTrackingAlertsAmount}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='6' sm='6'>
                  <InputField
                    name='TransactionAlertsAmount'
                    label='Transaction Alerts Amount'
                    type='currency'
                    alignment='right'
                    value={data.TransactionAlertsAmount}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='4'>
                  <InputField
                    name='TokenCardworks'
                    label='Cardworks Token'
                    value={data.TokenCardworks}
                    onChange={() => {}}
                    disabled
                  />
                </CCol>
                <CCol xs='4'>
                  <InputField
                    name='TokenCliq'
                    label='Cliq Token'
                    value={data.TokenCliq}
                    onChange={() => {}}
                    disabled
                  />
                </CCol>
                <CCol xs='4'>
                  <InputField
                    name='TokenExperian'
                    label='Experian Token'
                    value={data.TokenExperian}
                    onChange={() => {}}
                    disabled
                  />
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            // setData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default EditMemberSettingsModal
