import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const ApplicantsTableColumns: ColumnData[] = [
  /*  {
    label: 'PlaidAccessToken',
    name: 'PlaidAccessToken',
    dbType: 'varchar',
  }, */
  {
    label: 'FirstName',
    name: 'FirstName',
  },
  {
    label: 'LastName',
    name: 'LastName',
  },
  {
    label: 'Email',
    name: 'Email',
  },
  {
    label: 'Phone',
    name: 'Phone',
  },
  {
    label: 'DOB',
    name: 'DOB',
  },
  {
    label: 'Address',
    name: 'Address',
  },
  {
    label: 'Address2',
    name: 'Address2',
  },
  {
    label: 'City',
    name: 'City',
  },
  {
    label: 'State',
    name: 'State',
  },
  {
    label: 'ZipCode',
    name: 'ZipCode',
  },
  {
    label: 'SSN',
    name: 'SSN',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
