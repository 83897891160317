import React from 'react'
import { FieldsSection } from 'src/shared'
import LookupValuesTable from './LookupValuesTable'

interface PropsLookupsDetailTabSection {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  showEditModalFunc?: any
  Guid?: any
  lookupsData?: any
}
const LookupsDetailTabSection: React.FC<PropsLookupsDetailTabSection> = ({
  id,
  actionFunctions,
  tableRef,
  lookupsData,
}: PropsLookupsDetailTabSection) => {
  return (
    <FieldsSection header='Lookups'>
      <LookupValuesTable
        id={id}
        lookupsData={lookupsData}
        actionFunctions={actionFunctions}
        ref={tableRef}
      ></LookupValuesTable>
    </FieldsSection>
  )
}

export default LookupsDetailTabSection
