import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, PROC_GENERATE } from 'src/services/routeConst'
interface PropsProcGenerateImport {
  procType: 'Models' | 'Procs' | 'Schemas'
  params?: any
}
interface Response {
  data: any
  status: any
  message: any
}
export const ProcGenerateImport = async ({
  procType,
}: PropsProcGenerateImport): Promise<Response> => {
  //URL {api}/ProcGenerate/List/{ModelName}
  try {
    const { data } = await axios.get(`${BASE_API}:${PROC_GENERATE}/Imports/${procType}`, {
      ...addAuthToHeader('APPLICATION'),
    })
    // console.log(data)
    return {
      data: data?.data,
      status: 'success',
      message: data?.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error Generating SP ${procType}!`,
      error: error.response,
    })
  }
}
