import { CCard, CCardBody, CCardHeader, CCol, CContainer, CRow } from '@coreui/react'
import { CardMoney, ComboBox } from 'src/shared'
import React, { useState } from 'react'
import { FieldsSection } from 'src/shared'
import AddressFinderAux from 'src/shared/inputFields/AddressFinderAux'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'
import { PdfViewer } from 'src/shared'

import { RadioGroup } from 'smart-inputs'
const defaultValues: any = {
  codeEditors: {
    sqlserver: `SELECT \t*
FROM \tUSERS
WHERE \tUSERS.IsDeleted = 0`,
    jsx: `<!DOCTYPE html>
<html lang="en">

<body>
  <div class='example-div'>
    Hello World!
  </div>
</body>
</html>`,
    json: `{
  "ModelName": "Test",
  "IsValid": true
}`,
  },
  Completers: [
    {
      name: 'firstName',
      value: 'firstName',
      score: 100,
      meta: 'variable',
    },
    {
      name: 'lastName',
      value: 'lastName',
      score: 100,
      meta: 'variable',
    },
    {
      name: 'email',
      value: 'email',
      score: 100,
      meta: 'variable',
    },
  ],
  JsonValue: `{
  "ModelName": "Test",
  "IsValid": true
}`,
  Guid: '9832F797-4D9E-49CE-97B6-EA39FEA5A621',
  exampleData: {
    addressData: { Address: '', Address2: '', City: '', State: '', ZipCode: '' },
    customTags: [],
  },
}

const KitchenSink: React.FC<any> = () => {
  const [completers, setCompleters] = useState<any>(defaultValues.Completers)
  const [jsonValue, setJsonValue] = React.useState(defaultValues.JsonValue)
  const [exampleData, setExampleData] = React.useState(defaultValues.exampleData)

  const handleAddressChange = ({ name, value }: any) => {
    setExampleData((st: any) => ({ ...st, addressData: { ...st.addressData, [name]: value } }))
  }

  const handleComboChange = (_: any, newValue: any) => {
    setExampleData((st: any) => ({ ...st, customTags: newValue }))
  }

  const handleCompletersChange = (_: any, newValue: any) => {
    const usedCompleters = completers.map((c: any) => c.name)
    const newTags = newValue
      .filter((tag: any) => !usedCompleters?.includes(tag))
      .map((newTag: any) => ({ name: newTag, value: newTag, score: 100, meta: 'variable' }))
    setCompleters((st: any) => [...st, ...newTags])
  }

  const handleAddressDataChange = ({ value }: any) => {
    const { Address = '', Address2 = '', City = '', State = '', ZipCode = '' } = value
    // console.log({ Address, Address2, City, State, ZipCode })

    setExampleData((st: any) => ({
      ...st,
      addressData: { ...st.addressData, Address, Address2, City, State, ZipCode },
    }))
  }

  const { Address, Address2, City, State, ZipCode } = exampleData.addressData
  const colorCard = {
    rose: 'linear-gradient(360deg, #A56F70 -16.09%, #C3989A 33.43%, #F2CFCF 98.74%)',
    gold: 'linear-gradient(360deg, #926544 -16.09%, #FFDDAF 75.76%, #FFF9E9 100%)',
    black: 'linear-gradient(360deg, #282828 -14.33%, #333333 4.41%, #666666 69.71%, #B2B2B2 100%)',
    silver: ' linear-gradient(0deg, #666666 6.08%, #B2B2B2 100%)',
  }
  return (
    <CContainer fluid>
      <CRow>
        <CCol>
          <h4>HTML / JSX Code Editor test</h4>
          <CustomCodeEditor mode='html' value={defaultValues.codeEditors.jsx} />
        </CCol>
        <CCol>
          <h4>SQL Server Code Editor</h4>
          <CustomCodeEditor theme='sqlserver' value={defaultValues.codeEditors.sqlserver} />
        </CCol>
        <CCol>
          <h4>JSON Code Editor</h4>
          <CustomCodeEditor
            mode='json'
            completers={completers}
            value={jsonValue}
            onChange={(value: any) => {
              setJsonValue(value)
            }}
          />
          <ComboBox
            data={[]}
            showButton
            multiple
            freeSolo
            name='customCompleters'
            label='Custom Completers'
            value={completers.map((c: any) => c.name)}
            handleChange={handleCompletersChange}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root pt-3 mt-2' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{option.CodeId}</div>
            )}
            placeholder='Enter a tag'
          />
        </CCol>
      </CRow>
      <CRow className='mt-4'>
        <CCol xs='4'>
          <CCard>
            <CCardHeader>Tag Input</CCardHeader>
            <CCardBody>
              <ComboBox
                data={[]}
                showButton
                multiple
                freeSolo
                name='customTags'
                label='Custom Tags'
                value={exampleData.customTags}
                handleChange={handleComboChange}
                classNames={{ input: 'input-field' }}
                customClassNames={{ button: 'comboBoxInput', root: 'input-field-root pt-3' }}
                renderOption={(option: any, { selected }: any) => (
                  <div className={selected ? 'combo-selected' : ''}>{option.CodeId}</div>
                )}
                placeholder='Enter a tag'
              />
              <pre style={{ color: 'white' }}>
                {JSON.stringify(exampleData.customTags, null, 4)}
              </pre>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs='4'>
          <CCard>
            <CCardHeader>Address Finder</CCardHeader>
            <CCardBody>
              <FieldsSection header='Member Address'>
                <AddressFinderAux
                  address={{ Address, Address2, City, State, ZipCode }}
                  handleChange={handleAddressChange}
                  handleAddressDataChange={handleAddressDataChange}
                />
              </FieldsSection>
            </CCardBody>
          </CCard>
        </CCol>
        <CCol xs='4'>
          <CCard>
            <CCardHeader>PDF Upload</CCardHeader>
            <CCardBody>
              <PdfViewer onSavePdf={(data: any) => console.log(data)}></PdfViewer>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow className='mt-4'>
        <CCol xs='4'>
          <CCard>
            <CCardHeader>Guid Search</CCardHeader>
            <CCardBody>
              {/* <GuidSearchInput
                name='GuidValue'
                label='Guid Search'
                value={guidValue}
                onChange={handleGuidValueChange}
              /> */}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <CRow className='mt-4'>
        <CCol xs='1'>
          <RadioGroup
            name='OptionEx'
            label='Options'
            color='#4638A5'
            options={['Rose', 'Gold', 'Black', 'Silver']}
            onChangeRadio={({ name, value }) => {
              console.log({ name, value })
            }}
            defaultSelected={'Rose'}
            customClassNames={{ root: 'input-field', input: 'input-field-input' }}
          />
        </CCol>
        <CCol xs='3'>
          <CardMoney
            background={colorCard.black}
            style={{ width: '250px', height: '150px' }}
            select={true}
          />

          <CardMoney background={colorCard.gold} />
          <CardMoney background={colorCard.rose} />
          <CardMoney background={colorCard.silver} />
        </CCol>
        <br />
      </CRow>
    </CContainer>
  )
}

export default KitchenSink
