import { ColumnData } from 'library-soft-datatable'

export const TableColumns: ColumnData[] = [
  {
    label: 'First Name',
    name: 'FirstName',
  },
  {
    label: 'Last Name',
    name: 'LastName',
  },
  {
    label: 'Facility Name',
    name: 'FacilityName',
  },
  {
    label: 'Registered Date',
    name: 'CreatedDate',
  },
  {
    label: 'Gender',
    name: 'Gender',
  },
  {
    label: 'DateOfBirth',
    name: 'DateOfBirth',
  },
  {
    label: 'Status',
    name: 'Status',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    customWidth: 10,
    sortable: false,
  },
]
