import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const PhoneTableColumns: ColumnData[] = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Phone No',
    name: 'PhoneNo',
  },
  {
    label: 'Message',
    name: 'Message',
  },
  {
    label: 'Is NoCall',
    name: 'IsNoCall',
  },
  {
    label: 'Is Sent',
    name: 'IsSent',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['delete', 'reset'],
    customWidth: 10,
    sortable: false,
  },
]
