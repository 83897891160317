// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const NotesInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const { NotesDateTime, NotesSubject, NotesMessage } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            label={'Datetime'}
            value={NotesDateTime ?? '⠀'}
          />
          <InputLabel
            label='Subject'
            value={NotesSubject ?? '⠀'}
          />
          <InputLabel
            label='Message'
            value={NotesMessage ?? '⠀'}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default NotesInfo
