// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { ComboBox, FieldsSection, InputField } from 'src/shared'
interface PropsInfo {
  modelData?: any
  conectionsData?: any
  databaseNames?: any
  schemaNames?: any
  handleChange?: any
  handleComboChange?: any
}
const ProcsInfo: React.FC<PropsInfo> = ({ modelData, handleChange, conectionsData }) => {
  return (
    <FieldsSection header='Procedure Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='ConnectionName'
            type='text'
            label='Connection Name'
            value={modelData.ConnectionName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12'>
          <ComboBox
            showButton
            name='ConnectionsId'
            label='Conections'
            data={conectionsData}
            value={conectionsData.find((e: any) => e.CodeId === modelData.ConnectionsId)}
            handleChange={(e: any, newValue: any = {}) => {
              handleChange({ name: 'ConnectionsId', value: newValue.CodeId, e: e })
            }}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
            )}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='DatabaseName'
            type='text'
            label='Database Name'
            value={modelData.DatabaseName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={modelData.Name}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='ProcedureName'
            type='text'
            label='Procedure Name'
            value={modelData.ProcedureName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='SchemaName'
            type='text'
            label='SchemaName'
            value={modelData.SchemaName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default ProcsInfo
