import { CButton, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import React, { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { menuTableColumns } from './menuTableColumns'
import { BASE_API, LIST, MENUS_MODEL, MODELS, SERVICES } from 'src/services/routeConst'
import CIcon from '@coreui/icons-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as faIcons from '@fortawesome/free-solid-svg-icons'
import { getAuthToken } from 'src/services/authHelper'
// import { JkcInput } from 'smartjkc';

const MenusSearch: React.FC<any> = ({ history, modelName = MENUS_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const clickedIDRef = useRef()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Admin/Menus/Detail/${id}`)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  // const [settings, setSettings] = useState({
  //   label: "First Name",
  //   value: "Nishith"
  // })
  // const onChangeEvent = (event: any) => {
  //   setSettings(prev => ({ ...prev, value: event.target.value }))
  // }

  return (
    <>

      <CCardHeader
        className='d-flex align-items-center card-accent-secondary'
        style={{ backgroundColor: '#2A3446', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}
      >
        <span style={{ color: 'white' }}>{modelName} Search</span>
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, `/Admin/Menus/New`)}
        >
          New Menu
        </CButton>
      </CCardHeader>
      <CCardBody className={'custom-card-body'}>
        {/* <div className='row'>
            <div className='col-3'>
              <JkcInput type={'text'} name={'test'} settings={settings} onChangeEvent={onChangeEvent}></JkcInput>
            </div>
        </div> */}

        <DataTable
          columnData={menuTableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={modelName}
          idName={'Id'}
          scopedSlots={{
            Name: ({ value }) => {
              return <div>{value}</div>
            },
            Color: ({ value }) => {
              return <div className='custom-icon' style={{ backgroundColor: value, border: 'none' }}></div>
            },
            // IsMain: ({ value }) => {
            //   return <span>{(value) ? 'True' : 'False'}</span>
            // },
            Icon: ({ value }) => {
              if(faIcons[value] == undefined) {
                return (<></>)
              } 
              return (
                <div className='custom-icon'>
                 <FontAwesomeIcon icon={faIcons[value]} />
                </div>
              )
              
            },
            PagePath: ({ value }) => {
              return <div style={{ color: '#F2AB26' }}>{value}</div>
            },
          }}
          actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          IconComponent={CIcon}
          parseBools
          apiKey={getAuthToken('ADMIN')}
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Menu', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </>

  )
}

export default MenusSearch
