import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { StatusInfo } from '../enum/Status'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'
import { WizardContext } from '../../Wizard'

interface PropsActionsModels {
  id: any
  modelName: any
  modelsId?: string
}
type ApiDataState = { modelData: any }
const fields: string[] = [
  'Id',
  'Name',
  'FirstName',
  'LastName',
  'PhoneMobile',
  'PhoneOther',
  'Address',
  'City',
  'Zip',
  'PickupAddress',
  'PickupCity',
  'PickupState',
  'PickupZip',
  'DropoffAddress',
  'DropoffCity',
  'DropoffState',
  'DropoffZip',
  'IsTitleInHand',
  'FinalOffer',
  'DueCustomer',
  'PaymentMethod',
  'RegisteredOwner',
  'IsLienHolder', 
  'LienHolder', 
  'Payoff',
  'PayoffExpiresOn',
  'LastTitleIssuedOn',
  'TitleNumber',
  'LastOdometer',
]
export const useActionsModels = ({
  id,
  modelName,
  modelsId = 'Id',
}: PropsActionsModels): [ApiDataState, StatusInfo, () => Promise<void>, Function, Function] => {
  const { setCustomer, handleNext } = useContext(WizardContext)
  const [apiData, setApiData] = useState<ApiDataState>({ modelData: null })
  const [status, setStatus] = useState<StatusInfo>(StatusInfo.loading)
  const rootRepository = useMemo<any>(() => new RootRepository(), [])
  const getData = useCallback<any>(async () => {
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [modelsId]: 0 },
      })
      const dataResponse = mainData?.data ? mainData.data[0] : mainData
      setApiData({ modelData: dataResponse })
      setStatus(StatusInfo.idle)
    } catch (error) {
      setStatus(StatusInfo.idle)
    }
  }, [rootRepository, id, modelName, modelsId])

  useEffect(() => {
    getData()
  }, [getData])

  const mappingFields = (apiData) => {
    const dataToSave: any = apiData.modelData

    return {
      ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
      VehiclesId: null,
    }
  }
  const saveData = async () => {
    setStatus(StatusInfo.saving)
    try {
      const { message, data } = await SaveModel({
        body: {
          Model: modelName,
          Params: mappingFields(apiData),
        },
      })
      setCustomer(data[0])
      toast.success(message)
      getData()
      setStatus(StatusInfo.idle)
      handleNext()
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }
  const handleAddressChange = (...data) => {
    const addressValue = (data[0] ?? {}) as Object

    setApiData((st: any) => ({
      ...st,
      modelData: { ...st.modelData, ...addressValue },
    }))
  }
  return [apiData, status, saveData, handleChange, handleAddressChange]
}
