import React, { useEffect, useState } from 'react'
import { CChartLine } from '@coreui/react-chartjs'
import { getStyle, hexToRgba } from '@coreui/utils'
import { BASE_API, DATA, EXECUTE, SERVICES } from 'src/services/routeConst'
import axios from 'axios'
import { addAuthAccessTokenToHeader } from 'src/services/authHelper'
import { format } from 'date-fns'

const brandSuccess = getStyle('success') || '#4dbd74'
const brandInfo = getStyle('info') || '#20a8d8'

const MainChart: React.FC<any> = (attributes) => {

  const [dispatchesData, setDispatchesData] = useState([])
  const [inspectionsData, setInspectionsData] = useState([])
  const [calendarData, setCalendarData] = useState([])

  const fetchDailyChartData = async () => {
    const { data } = await axios.post(
      `${BASE_API}${SERVICES}${DATA}/${EXECUTE}`,
      {
        DbName: 'DbAdmin',
        QueryOrProcedureName: 'access.APPLICATIONS_DailyChart',
        Params: [],
      },
      { ...addAuthAccessTokenToHeader() },
    )
    
    const dispatches = await data.data.Execution.map((value)=> value.NoOfDispatches)
    const inspections = await data.data.Execution.map((value)=> value.NoOfInspections)
    const calendar = await data.data.Execution.map((value)=> {
      if(navigator.userAgent.indexOf("Firefox")!==-1){
        console.log('firefox', value.CalendarDate);
        // 05-27-2022 07:07:39 PM
        let day = String(value.CalendarDate)[3] + String(value.CalendarDate)[4]
        let months = ['January', 'February', 'March', 'April','May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        let month = months[parseInt(String(value.CalendarDate)[1] + String(value.CalendarDate)[2])-1]
        let year = String(value.CalendarDate)[6] + String(value.CalendarDate)[7] + String(value.CalendarDate)[8] + String(value.CalendarDate)[9]
        
        const newDate = new Date(`${day} ${month} ${year} 00:00 UTC`)
        return format(newDate,'LLL d')
      }
      const dateParse = new Date(value.CalendarDate)
      return format(dateParse,'LLL d')
    })
    await setDispatchesData(dispatches)
    await setInspectionsData(inspections)
    await setCalendarData(calendar)
  }

  useEffect(() => {
    fetchDailyChartData()
  }, [])
  
  const defaultDatasets = (() => {
    return [
      {
        label: 'No. of Dispatches',
        backgroundColor: hexToRgba(brandInfo, 10),
        borderColor: brandInfo,
        pointHoverBackgroundColor: brandInfo,
        borderWidth: 2,
        data: dispatchesData,
      },
      {
        label: 'No. of Inspections',
        backgroundColor: 'transparent',
        borderColor: brandSuccess,
        pointHoverBackgroundColor: brandSuccess,
        borderWidth: 2,
        data: inspectionsData,
      },
    ]
  })()

  const defaultOptions = (() => {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              drawOnChartArea: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              maxTicksLimit: 10,
              stepSize: Math.ceil(20/5),
              max: 20,
            },
            gridLines: {
              display: true,
            },
          },
        ],
      },
      elements: {
        point: {
          radius: 0,
          hitRadius: 10,
          hoverRadius: 4,
          hoverBorderWidth: 3,
        },
      },
    }
  })()
  
  // render
  return (
    <CChartLine
      {...attributes}
      datasets={defaultDatasets}
      options={defaultOptions}
      labels={calendarData}
    />
  )
}

export default MainChart
