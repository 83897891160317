import { Form, Row, Col } from 'react-bootstrap';

const Under18Form = (props: any) => {
    const { data, mData, handleChange, jsonChange } = props;


    return (
        <>
            <div>
                {/* Third Pdf Start */}
                <div className="row">
                    <div className="col-5 fz-12" style={{ paddingTop: '30px' }}>
                        <b>NEW YORK STATE DEPARTMENT OF HEALTH</b><br /><span>Office of Health Insurance Programs</span>
                    </div>
                    <div className="col-7 text-end fz-21">
                        <b>Health Home Enrollment and </b>
                        <br />
                        <b>Information Sharing Consent</b>
                        <br />
                        <b>For Use with Children Under 18 Years of Age</b>
                    </div>
                </div>
                <div className="border-bottom-confirm-modal"></div>
                <div className="row mt-3">
                    <div className="col-12  mt-2">
                        <p className="light-p">This form must be used for children less than 18 years of age for enrollment in a Health Home. This form also outlines what, and with whom, health information
                            can be shared.</p>
                    </div>
                    <div className="col-12  mt-2">
                        <b className="fz-12-b">*[Please note, children less than 18 years of age who are parents, pregnant, and/or married, and who are otherwise capable of consenting, should not use this
                            form. Rather, they must use the Health Home Patient Information Sharing Consent form (DOH 5055)].</b>
                    </div>
                    <div className="col-12  mt-2">
                        <b className="fz-12-b">Instructions for Parent/Guardian/Legally Authorized Representative:</b>
                        <p className="light-p">Section 1 of this form should be completed by the child’s Parent, Guardian, or Legally Authorized Representative. Legally Authorized Representative for the
                            purpose of sharing health information is defined as “a person or agency authorized by state, tribal, military or other applicable law, court order or consent to act on
                            behalf of a person for the release of medical information”. List all of the child’s health providers who can share the child’s health information. The health
                            information they share may be from before and after the date you sign this form. These providers can share this information with each other and with the child’s
                            care management agency listed below. They cannot give the child’s information to other people unless you agree, or the law says they can. The child can keep
                            private any information about services that the child consents for outlined in Section 2, including family planning and emergency contraception, abortion, sexually
                            transmitted infection testing and treatment, HIV testing and treatment, HIV prevention, prenatal care, labor and delivery services, drug and alcohol treatment, or
                            sexual assault services. If you consented for any of these services for the child, then you may have the authority to consent to the release of information regarding
                            these services and can list the providers in this Section. <b className="fz-12-b">Note:</b> the child may have to consent to the release of this information also</p>
                        <p className="light-p">
                            Section 2 of this form is completed separately by the child with the care manager. Children age 10 or older can consent to share or withhold information
                            regarding certain types of protected services. In addition, if the child or adolescent is specifically receiving services for mental health or developmental
                            disabilities and is over the age of 12, the mental health and/or developmental disabilities provider may ask the child or adolescent if they want their
                            information disclosed.
                        </p>
                        <b className="fz-12-b">Instructions for Care Manager:</b>
                        <p className="light-p">
                            Section 1 is completed by the child’s Parent, Guardian, or Legally Authorized Representative. It lists all health providers who can
                            share the child’s health information. List the child’s care management agency as a provider below. These providers can share all health information except for any
                            information about services the child can self-consent for, including family planning and emergency contraception, abortion, sexually transmitted infection testing
                            and treatment, HIV testing and treatment, HIV prevention, prenatal care, labor and delivery services, drug and alcohol treatment, or sexual assault services. If the
                            Parent, Guardian, or Legally Authorized Representative consented to abortion, sexually transmitted infection testing and treatment, HIV testing and treatment, HIV
                            prevention, or drug and alcohol treatment on behalf of the child, information can only be released if the child also consents to the release in Section 2. Copy the
                            page below as needed to be able to list all agreed to providers. If this list needs to be updated in the future (to either add or remove a name), please have the
                            Parent/Guardian/Legally Authorized Representative select either ADD or REMOVE, initial and date next to each new entry or omission. The HHCM must also
                            initial next to each change made.
                        </p>
                        <b className="fz-12-b">Instructions for Participating Provider:</b>
                        <p className="light-p">
                            If your name or agency is listed in Section 1, you may release the child’s health information except for any information
                            about services the child can self-consent for, including family planning and emergency contraception, abortion, sexually transmitted infection testing and
                            treatment, HIV testing and treatment, HIV prevention, prenatal care, labor and delivery services, drug and alcohol treatment, or sexual assault services. You may
                            only release this information if you are given permission to do so in Section 2 of this form. If the Parent, Guardian, or Legally Authorized Representative consented
                            to abortion, sexually transmitted infection testing and treatment, HIV testing and treatment, HIV prevention, or drug and alcohol treatment on behalf of the child,
                            information can only be released <b className="fz-12-b"> if the child also consents</b> to the release in Section 2. If you receive a copy of Section 2 of this consent form, please review it
                            carefully to identify permission provided by the child for the Health Home Care Manager to share specific information with you.<b className="fz-12-b">Note:</b> If Section 2 is NOT
                            provided, permission has not been granted by the child and therefore, this information may NOT be released or shared with you.
                        </p>

                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder=""
                                name="NameOfHealthHome"
                                value={data.NameOfHealthHome}
                                onChange={jsonChange}
                            />
                            <Form.Text className="text-muted fz-12">
                                PRINT NAME OF HEALTH HOME
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder=""
                                name="firtsName"
                                readOnly
                                value={data.NameOfPatient}
                                onChange={handleChange}
                            />
                            <Form.Text className="text-muted fz-12">
                                PRINT NAME OF CHILD
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2"></div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder=""
                                name="DOBOfPatient"
                                readOnly
                                value={data.DOBOfPatient}
                                onChange={jsonChange}
                            />
                            <Form.Text className="text-muted fz-12">
                                CHILD’S DATE OF BIRTH
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-12 mt-4">
                        <b className="fz-12-b">Section 1:</b><span className="light-p"> This section is completed by the child’s Parent, Guardian, or Legally Authorized Representative.</span>
                        <p className="light-p">By signing this form, I agree that:</p>
                        <ol>
                            <li className="light-p">It has been explained to me that the child named above is qualified to be in a Health Home.</li>
                            <li className="light-p">The child listed above is enrolled in the Health Home listed above.</li>
                            <li className="light-p">I have had the chance to review the Health Home FAQ sheet and have had my questions answered.</li>
                            <li className="light-p"> I understand what the Health Home Program is and how it can help the child. I understand what being enrolled in a Health Home means
                                and why this child’s health information will be shared. </li>
                            <li className="light-p">The Health Home and anyone I have named in the Section 1 below can share
                                &nbsp;<Form.Control
                                    className="d-inline-block"
                                    style={{ 'width': '200px' }}
                                    placeholder="Name of child"
                                    size="sm"
                                    type="text"
                                    name="NameOfPatient"
                                    readOnly
                                    value={data.NameOfPatient}
                                    onChange={jsonChange}
                                />&nbsp;
                                health information, as outlined in the Instructions above with each other for the purposes of care management in the Health Home Program. They may share information from before or after the date I sign this form.
                            </li>
                            <li className="light-p">
                                The Health Home may get the child’s health information, including health records, from partners listed at the end of this form and/or from
                                others through the following electronic systems:
                                <ul>
                                    <li>The Statewide Health Information Network for New York (SHIN-NY): The SHIN-NY is run by the New York State Department of
                                        Health. The SHIN-NY collects and stores health information, including medical records, from their doctors and health care
                                        providers;</li>
                                    <li>The Psychiatric Services and Clinical Knowledge Enhancement System (PSYCKES): PYSKCES is run by the New York State Office of
                                        Mental Health, collects and stores your health treatment from your doctors and health care providers who are part of the Medicaid
                                        program;</li>
                                    <li>TABS/CHOICES: TABS/CHOICES is run by the New York State Office for People With Developmental Disabilities (OPWDD).
                                        TABS/CHOICES collects and stores information for people with intellectual and/or developmental disabilities (I/DD) served
                                        through OPWDD’s service system.</li>
                                </ul>
                            </li>
                            <li className="light-p"> In addition, the Health Home may need to share the child’s information with the local Single Point of Access (SPOA) to help the Health Home
                                Care Manager coordinate access to needed mental health services. The SPOA is able to see data under Mental Hygiene Law Section 41.05
                                and pursuant to the authority of Sections 41.07 and 41.13. Permission to contact the SPOA can be provided on the Provider page below.</li>
                        </ol>
                    </div>
                    <div className="col-12 mt-2">
                        <p className="light-p">I understand that this consent form takes the place of other Health Home information sharing consent forms I may have signed before on behalf of
                            the child. This consent stays in place until:</p>
                        <ul>
                            <li className="light-p">I withdraw the child, or</li>
                            <li className="light-p">The child is no longer eligible for a Health Home, or</li>
                            <li className="light-p">The Health Home is no longer in business, or</li>
                            <li className="light-p">The child becomes the age or situation to self-consent (complete DOH-5055)
                            </li>
                        </ul>
                        <p className="light-p">I can change this form at any time. If I make changes, I have to initial and date as indicated (or within the designated box). I can always take back
                            this consent on behalf of the child by contacting the Care Manager, Care Management Agency, or Health Home.</p>
                    </div>
                    <div className="col-12 mt-2">
                        <b className="fz-12-b">If I do not sign this consent form, I understand that the child’s information will not be shared.</b>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder=""
                                name="NameOfLegalRepresentativeRelationship"
                                readOnly
                                value={data.NameOfLegalRepresentativeRelationship}
                                onChange={jsonChange}
                            />
                            <Form.Text className="text-muted fz-12">
                                PRINT NAME OF CHILD’S PARENT, GUARDIAN OR LEGALLY AUTHORIZED REPRESENTATIVE
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder=""
                                name="RelationshipOfLegalRepresentative"
                                readOnly
                                value={data.RelationshipOfLegalRepresentative}
                                onChange={jsonChange}
                            />
                            <Form.Text className="text-muted fz-12">
                                RELATIONSHIP OF PARENT, GUARDIAN OR LEGALLY AUTHORIZED REPRESENTATIVE TO CHILD
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            {/* <Form.Control size="sm" type="text" placeholder="" /> */}
                            <div>
                                <img src={data.Signature} width={200} height={50} />
                            </div>
                            <Form.Text className="text-muted fz-12">
                                SIGNATURE OF CHILD’S PARENT, GUARDIAN OR LEGALLY AUTHORIZED REPRESENTATIVE
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder=""
                                name="FormFillDate"
                                readOnly
                                value={data.FormFillDate}
                                onChange={jsonChange}
                            />
                            <Form.Text className="text-muted fz-12">
                                DATE
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder=""
                                name="NameOfHealthHome"
                                value={data.NameOfHealthHome}
                                onChange={jsonChange}
                            />
                            <Form.Text className="text-muted fz-12">
                                NAME OF HEALTH HOME
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control
                                size="sm"
                                type="text"
                                placeholder=""
                                name="MemberName"
                                value={data.MemberName}
                                onChange={jsonChange}
                            />
                            <Form.Text className="text-muted fz-12">
                                MEMBER NAME
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-12 mt-4">
                        <div style={{ border: '1px solid black' }}></div>
                    </div>
                    <div className="col-12 mt-2">
                        <b className="fz-12-b">Copy this page as necessary to list all participating partners and others approved by the member’s Parent, Guardian or Legally Authorized
                            Representative</b>
                    </div>
                    <div className="col-12 mt-2 under18Table">
                        <table className='table'>
                            <thead>
                            <tr>
                                <td rowSpan={2} style={{ width: "40%" }} className="light-p">List all of the child’s health providers who can share the
                                    child’s health information below. The health information
                                    they share may be from before or after the date you sign
                                    this form. These providers can share this information with
                                    each other and with the child’s care management agency
                                    listed below.</td>
                                <td colSpan={4} style={{ width: "60%" }} className="light-p">If at ANY time there are changes to this page, the Parent, Guardian, or Legally
                                    Authorized Representative MUST select whether the change is to ADD or
                                    REMOVE provider, and DATE and INITIAL next to the change(s) made in the box below.
                                    The Health Home Care Manager (HHCM) must also INITIAL all changes.
                                </td>
                            </tr>
                            <tr>
                                <td className="light-p" style={{ width: "15%", textAlign: 'center' }}>Add or Remove</td>
                                <td className="light-p" style={{ width: "15%", textAlign: 'center' }}>Date of Change</td>
                                <td className="light-p" style={{ width: "20%", textAlign: 'center' }}>Initials of Parent,
                                    Guardian, or Legally
                                    Authorized Representative</td>
                                <td className="light-p" style={{ width: "10%", textAlign: 'center' }}>Initials of
                                    HHCM</td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Health Home Care Management Agency:
                                        </Form.Text>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            placeholder=""
                                            name="HealthHomeCareManagementAgency"
                                            value={data.HealthHomeCareManagementAgency}
                                            onChange={jsonChange}
                                        />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check
                                        
                                            type="checkbox"
                                            name="HealthHomeCareManagementAgencyAdd"
                                            value={data.HealthHomeCareManagementAgencyAdd}
                                            checked={data.HealthHomeCareManagementAgencyAdd}
                                            onChange={jsonChange}
                                        /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox"
                                            name="HealthHomeCareManagementAgencyRemove"
                                            value={data.HealthHomeCareManagementAgencyRemove}
                                            checked={data.HealthHomeCareManagementAgencyRemove}
                                            onChange={jsonChange}
                                        /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control
                                    size="sm"
                                    type="text"
                                    name="HealthHomeCareManagementAgencyDateOfChange"
                                    value={data.HealthHomeCareManagementAgencyDateOfChange}
                                    onChange={jsonChange}
                                    placeholder="" /></td>
                                <td style={{ width: "20%" }}><Form.Control
                                    size="sm"
                                    type="text"
                                    name="HealthHomeCareManagementAgencyDateOfChangeInitialsOfParent"
                                    value={data.HealthHomeCareManagementAgencyDateOfChangeInitialsOfParent}
                                    onChange={jsonChange}
                                    placeholder="" /></td>
                                <td style={{ width: "10%" }}><Form.Control
                                    size="sm"
                                    type="text"
                                    name="HealthHomeCareManagementAgencyDateOfChangeInitialsOfHHCM"
                                    value={data.HealthHomeCareManagementAgencyDateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange}
                                    placeholder="" /></td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Medicaid Managed Care Plan:
                                        </Form.Text>
                                        <Form.Control
                                            size="sm"
                                            type="text"
                                            placeholder=""
                                            name="MedicaidManagedCarePlan"
                                            value={data.MedicaidManagedCarePlan}
                                            onChange={jsonChange}
                                        />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox"
                                            name="MedicaidManagedCarePlanAdd"
                                            value={data.MedicaidManagedCarePlanAdd}
                                            checked={data.MedicaidManagedCarePlanAdd}
                                            onChange={jsonChange}
                                        /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="MedicaidManagedCarePlanRemove"
                                            value={data.MedicaidManagedCarePlanRemove}
                                            checked={data.MedicaidManagedCarePlanRemove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="MedicaidManagedCarePlanDateOfChange"
                                    value={data.MedicaidManagedCarePlanDateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="MedicaidManagedCarePlanDateOfChangeInitialsOfParent"
                                    value={data.MedicaidManagedCarePlanDateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="MedicaidManagedCarePlanDateOfChangeInitialsOfHHCM"
                                    value={data.MedicaidManagedCarePlanDateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Name of Primary Care Physician:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="PrimaryCarePhysician"
                                            value={data.PrimaryCarePhysician}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox" name="PrimaryCarePhysicianAdd"
                                            value={data.PrimaryCarePhysicianAdd}
                                            checked={data.PrimaryCarePhysicianAdd}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="PrimaryCarePhysicianRemove"
                                            value={data.PrimaryCarePhysicianRemove}
                                            checked={data.PrimaryCarePhysicianRemove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="PrimaryCarePhysicianDateOfChange"
                                    value={data.PrimaryCarePhysicianDateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="PrimaryCarePhysicianDateOfChangeInitialsOfParent"
                                    value={data.PrimaryCarePhysicianDateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="PrimaryCarePhysicianDateOfChangeInitialsOfHHCM"
                                    value={data.PrimaryCarePhysicianDateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Name of Hospital:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="Hospital"
                                            value={data.Hospital}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox" name="HospitalAdd"
                                            value={data.HospitalAdd}
                                            checked={data.HospitalAdd}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="HospitalRemove"
                                            value={data.HospitalRemove}
                                            checked={data.HospitalRemove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="HospitalDateOfChange"
                                    value={data.HospitalDateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="HospitalDateOfChangeInitialsOfParent"
                                    value={data.HospitalDateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="HospitalDateOfChangeInitialsOfHHCM"
                                    value={data.HospitalDateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Name of Foster Care Agency:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="FosterCareAgency"
                                            value={data.FosterCareAgency}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox" name="FosterCareAgencyAdd"
                                            value={data.FosterCareAgencyAdd}
                                            checked={data.FosterCareAgencyAdd}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="FosterCareAgencyRemove"
                                            value={data.FosterCareAgencyRemove}
                                            checked={data.FosterCareAgencyRemove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="FosterCareAgencyDateOfChange"
                                    value={data.FosterCareAgencyDateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="FosterCareAgencyDateOfChangeInitialsOfParent"
                                    value={data.FosterCareAgencyDateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="FosterCareAgencyDateOfChangeInitialsOfHHCM"
                                    value={data.FosterCareAgencyDateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Name of Behavioral Health Provider:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="BehavioralHealthProvider"
                                            value={data.BehavioralHealthProvider}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox" name="BehavioralHealthProviderAdd"
                                            value={data.BehavioralHealthProviderAdd}
                                            checked={data.BehavioralHealthProviderAdd}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="BehavioralHealthProviderRemove"
                                            value={data.BehavioralHealthProviderRemove}
                                            checked={data.BehavioralHealthProviderRemove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="BehavioralHealthProviderDateOfChange"
                                    value={data.BehavioralHealthProviderDateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="BehavioralHealthProviderDateOfChangeInitialsOfParent"
                                    value={data.BehavioralHealthProviderDateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="BehavioralHealthProviderDateOfChangeInitialsOfHHCM"
                                    value={data.BehavioralHealthProviderDateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Name of SPOA/LGU:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="SPOA_LGU"
                                            value={data.SPOA_LGU}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox" name="SPOA_LGUAdd"
                                            value={data.SPOA_LGUAdd}
                                            checked={data.SPOA_LGUAdd}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="SPOA_LGURemove"
                                            value={data.SPOA_LGURemove}
                                            checked={data.SPOA_LGURemove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="SPOA_LGUDateOfChange"
                                    value={data.SPOA_LGUDateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="SPOA_LGUDateOfChangeInitialsOfParent"
                                    value={data.SPOA_LGUDateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="SPOA_LGUDateOfChangeInitialsOfHHCM"
                                    value={data.SPOA_LGUDateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Name of Provider:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="Provider1"
                                            value={data.Provider1}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider1Add"
                                            value={data.Provider1Add}
                                            checked={data.Provider1Add}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider1Remove"
                                            value={data.Provider1Remove}
                                            checked={data.Provider1Remove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider1DateOfChange"
                                    value={data.Provider1DateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider1DateOfChangeInitialsOfParent"
                                    value={data.Provider1DateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider1DateOfChangeInitialsOfHHCM"
                                    value={data.Provider1DateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>

                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Name of Provider:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="Provider2"
                                            value={data.Provider2}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider2Add"
                                            value={data.Provider2Add}
                                            checked={data.Provider2Add}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider2Remove"
                                            value={data.Provider2Remove}
                                            checked={data.Provider2Remove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider2DateOfChange"
                                    value={data.Provider2DateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider2DateOfChangeInitialsOfParent"
                                    value={data.Provider2DateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider2DateOfChangeInitialsOfHHCM"
                                    value={data.Provider2DateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>

                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Name of Provider:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="Provider3"
                                            value={data.Provider3}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider3Add"
                                            value={data.Provider3Add}
                                            checked={data.Provider3Add}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider3Remove"
                                            value={data.Provider3Remove}
                                            checked={data.Provider3Remove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider3DateOfChange"
                                    value={data.Provider3DateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider3DateOfChangeInitialsOfParent"
                                    value={data.Provider3DateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider3DateOfChangeInitialsOfHHCM"
                                    value={data.Provider3DateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>

                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Name of Provider:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="Provider4"
                                            value={data.Provider4}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider4Add"
                                            value={data.Provider4Add}
                                            checked={data.Provider4Add}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider4Remove"
                                            value={data.Provider4Remove}
                                            checked={data.Provider4Remove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider4DateOfChange"
                                    value={data.Provider4DateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider4DateOfChangeInitialsOfParent"
                                    value={data.Provider4DateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider4DateOfChangeInitialsOfHHCM"
                                    value={data.Provider4DateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>

                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Name of Provider:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="Provider5"
                                            value={data.Provider5}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider5Add"
                                            value={data.Provider5Add}
                                            checked={data.Provider5Add}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider5Remove"
                                            value={data.Provider5Remove}
                                            checked={data.Provider5Remove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider5DateOfChange"
                                    value={data.Provider5DateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider5DateOfChangeInitialsOfParent"
                                    value={data.Provider5DateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider5DateOfChangeInitialsOfHHCM"
                                    value={data.Provider5DateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>

                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Name of Provider:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="Provider6"
                                            value={data.Provider6}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td style={{ width: "15%" }}>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider6Add"
                                            value={data.Provider6Add}
                                            checked={data.Provider6Add}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Add</small></b>&nbsp;
                                    </div>
                                    <div>
                                        <Form.Check type="checkbox" name="Provider6Remove"
                                            value={data.Provider6Remove}
                                            checked={data.Provider6Remove}
                                            onChange={jsonChange} /> &nbsp;
                                        <b className="light-p"><small>Remove</small></b>&nbsp;
                                    </div>
                                </td>
                                <td style={{ width: "15%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider6DateOfChange"
                                    value={data.Provider6DateOfChange}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "20%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider6DateOfChangeInitialsOfParent"
                                    value={data.Provider6DateOfChangeInitialsOfParent}
                                    onChange={jsonChange} /></td>
                                <td style={{ width: "10%" }}><Form.Control size="sm" type="text" placeholder=""
                                    name="Provider6DateOfChangeInitialsOfHHCM"
                                    value={data.Provider6DateOfChangeInitialsOfHHCM}
                                    onChange={jsonChange} /></td>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="section-tilte">
                            <div className="section-inner-title">TO BE COMPLETED WITH CHILD ONLY</div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <b className="fz-12-b">Section 2:</b><span className="light-p"> The child/youth must be age 10 or older to review and complete this section. </span>
                        <br />
                        <b className="fz-12-b">Instructions:</b><span className="light-p">  Section 2 should be completed after Section 1 has been completed and signed by all necessary parties. To complete Section 2, the
                            child/youth must be age 10 or older. Section 2 of this form should be completed by the Health Home Care Manager with the child. Completion of
                            this form should be done in private, without the child’s Parent, Guardian, or Legally Authorized Representative, to allow for confidentiality of the
                            information.</span>
                        <div style={{ marginLeft: '15px' }}>
                            <b className="fz-12-b">Section 2 - Part A:</b><span className="light-p"> Children age 10 or older can consent to share or withhold information regarding certain types of protected services as
                                follows: Family Planning; Emergency Contraception; Abortion; HIV Testing and Treatment Provider(s); HIV Prevention Pre-exposure and
                                Post-exposure Prophylaxis (PrEP/PEP); Sexually Transmitted Infection Testing and Treatment: Prenatal Care, Labor/Delivery; Drug and Alcohol
                                Treatment; Sexual Assault Services.  </span>
                            <br />
                            <b className="fz-12-b">Section 2 - Part B:</b><span className="light-p">  Children age 12 or older can consent to share or withhold information regarding mental health or developmental
                                disabilities services they are receiving. The mental health and/or developmental disabilities provider may ask the child or adolescent if they
                                want their information disclosed </span>
                        </div>
                        <b className="fz-12-b">Section 2 - Parts A and B MUST be completed unless:</b><span className="light-p">: the child does not meet the specified age requirement (age 10 or age 12); the child is
                            unwilling or unable to complete this section; the child does not identify any protected services; the child does not identify any mental health or
                            developmental disabilities services; OR, permission is denied by the child’s Parent/Guardian/Legally Authorized Representative to have the HHCM
                            meet alone with the child for review of Section 2. If any of these reasons apply, the HHCM MUST clearly document the reason(s) in the child’s
                            record and complete the Health Home Care Management Tracker For Section 2, and continue with attempts to obtain this information at a later
                            date to assist the member with coordinating these services</span>
                    </div>
                    <div className="col-12 mt-2">
                        <div className='d-inline-block'>
                            <b className="fz-12-b">
                                SECTION 2 - PART A:
                                <br />
                                I,&nbsp;<Form.Control className="d-inline-block" style={{ 'width': '200px' }} placeholder="Name of child" size="sm" type="text"
                                    name="NameOfPatient"
                                    readOnly
                                    value={data.NameOfPatient}
                                    onChange={jsonChange} />&nbsp;
                                , understand that I can consent for certain types of health care services without my Parent, Guardian, or Legally Authorized Representative knowing.
                                <span className="light-p">These services are: Family Planning; Emergency Contraception; Abortion; HIV Testing
                                    and Treatment Provider(s); HIV Prevention Pre-exposure and Post-exposure Prophylaxis (PrEP/PEP); Sexually Transmitted Infection Testing and
                                    Treatment: Prenatal Care, Labor/Delivery; Drug and Alcohol Treatment; Sexual Assault Services.</span>I can also decide who is allowed to get and share
                                my information about these services.
                            </b>
                        </div>
                    </div>
                    <div className="col-12 mt-3 under18Table">
                        <table className='table'>
                            <thead>
                            <tr>
                                <td colSpan={3} style={{ width: "65%" }} className="fz-12-b">
                                    It is okay to share information about these services with my Parent, Guardian or
                                    Legally Authorized Representative named below.
                                </td>
                                <td colSpan={4} style={{ width: "35%" }} className="light-p">
                                    Any changes made in this section must be initialed
                                    and dated by the child and the Health Home Care
                                    Manager (HHCM) below.
                                    I take away my permission to share information
                                    about these services with my Parent, Guardian or
                                    Legally Authorized Representative, as follows:
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Types of Services and Name(s)
                                    of Provider and/or Agency</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Name of Parent, Guardian or
                                    Legally Authorized Representative</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Child’s Initials</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">HHCM Initials</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                            </tr>
                            <tr>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndName1"
                                    value={data.Section2PartATypeOfServiceAndName1}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndNameDate1"
                                    value={data.Section2PartATypeOfServiceAndNameDate1}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAProvider1"
                                    value={data.Section2PartAProvider1}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitial1"
                                    value={data.Section2PartAChildInitial1}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitialDate1"
                                    value={data.Section2PartAChildInitialDate1}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitial1"
                                    value={data.Section2PartAHHCMINitial1}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitialDate1"
                                    value={data.Section2PartAHHCMINitialDate1}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndName2"
                                    value={data.Section2PartATypeOfServiceAndName2}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndNameDate2"
                                    value={data.Section2PartATypeOfServiceAndNameDate2}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAProvider2"
                                    value={data.Section2PartAProvider2}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitial2"
                                    value={data.Section2PartAChildInitial2}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitialDate2"
                                    value={data.Section2PartAChildInitialDate2}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitial2"
                                    value={data.Section2PartAHHCMINitial2}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitialDate2"
                                    value={data.Section2PartAHHCMINitialDate2}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndName3"
                                    value={data.Section2PartATypeOfServiceAndName3}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndNameDate3"
                                    value={data.Section2PartATypeOfServiceAndNameDate3}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAProvider3"
                                    value={data.Section2PartAProvider3}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitial3"
                                    value={data.Section2PartAChildInitial3}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitialDate3"
                                    value={data.Section2PartAChildInitialDate3}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitial3"
                                    value={data.Section2PartAHHCMINitial3}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitialDate3"
                                    value={data.Section2PartAHHCMINitialDate3}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndName4"
                                    value={data.Section2PartATypeOfServiceAndName4}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndNameDate4"
                                    value={data.Section2PartATypeOfServiceAndNameDate4}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAProvider4"
                                    value={data.Section2PartAProvider4}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitial4"
                                    value={data.Section2PartAChildInitial4}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitialDate4"
                                    value={data.Section2PartAChildInitialDate4}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitial4"
                                    value={data.Section2PartAHHCMINitial4}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitialDate4"
                                    value={data.Section2PartAHHCMINitialDate4}
                                    onChange={jsonChange} /></td>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="col-12 mt-3 under18Table">
                        <table className='table'>
                            <thead>
                            <tr>
                                <td colSpan={3} style={{ width: "65%" }} className="fz-12-b">
                                    It is okay to share information about these services with my Provider(s) named below.
                                </td>
                                <td colSpan={4} style={{ width: "35%" }} className="light-p">
                                    Any changes made in this section must be initialed
                                    and dated by the child and the Health Home Care
                                    Manager (HHCM) below.
                                    I take away my permission to share information
                                    about these services with the Provider, as follows:
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Types of Services and Name(s)
                                    of Provider and/or Agency</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Name of Provider(s)</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Child’s Initials</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">HHCM Initials</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                            </tr>
                            <tr>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndName5"
                                    value={data.Section2PartATypeOfServiceAndName5}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndNameDate5"
                                    value={data.Section2PartATypeOfServiceAndNameDate5}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAProvider5"
                                    value={data.Section2PartAProvider5}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitial5"
                                    value={data.Section2PartAChildInitial5}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitialDate5"
                                    value={data.Section2PartAChildInitialDate5}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitial5"
                                    value={data.Section2PartAHHCMINitial5}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitialDate5"
                                    value={data.Section2PartAHHCMINitialDate5}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndName6"
                                    value={data.Section2PartATypeOfServiceAndName6}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndNameDate6"
                                    value={data.Section2PartATypeOfServiceAndNameDate6}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAProvider6"
                                    value={data.Section2PartAProvider6}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitial6"
                                    value={data.Section2PartAChildInitial6}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitialDate6"
                                    value={data.Section2PartAChildInitialDate6}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitial6"
                                    value={data.Section2PartAHHCMINitial6}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitialDate6"
                                    value={data.Section2PartAHHCMINitialDate6}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndName7"
                                    value={data.Section2PartATypeOfServiceAndName7}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndNameDate7"
                                    value={data.Section2PartATypeOfServiceAndNameDate7}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAProvider7"
                                    value={data.Section2PartAProvider7}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitial7"
                                    value={data.Section2PartAChildInitial7}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitialDate7"
                                    value={data.Section2PartAChildInitialDate7}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitial7"
                                    value={data.Section2PartAHHCMINitial7}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitialDate7"
                                    value={data.Section2PartAHHCMINitialDate7}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndName8"
                                    value={data.Section2PartATypeOfServiceAndName8}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartATypeOfServiceAndNameDate8"
                                    value={data.Section2PartATypeOfServiceAndNameDate8}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAProvider8"
                                    value={data.Section2PartAProvider8}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitial8"
                                    value={data.Section2PartAChildInitial8}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAChildInitialDate8"
                                    value={data.Section2PartAChildInitialDate8}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitial8"
                                    value={data.Section2PartAHHCMINitial8}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartAHHCMINitialDate8"
                                    value={data.Section2PartAHHCMINitialDate8}
                                    onChange={jsonChange} /></td>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="NameOfHealthHome"
                                value={data.NameOfHealthHome}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                NAME OF HEALTH HOME
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="MemberName"
                                value={data.MemberName}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                MEMBER NAME
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-12 mt-4">
                        <div className="d-inline-block">
                            <b className="fz-12-b">
                                SECTION 2 - PART B:
                                <span className="light-p">
                                    : If you are receiving mental health and/or developmental disabilities services, and are over the age of twelve, your
                                    provider may ask you if you want your information shared with others. If you object, your provider may: deny the request entirely, send only
                                    part of the record, or send a summary of your clinical record.
                                </span>
                            </b>
                        </div>
                    </div>
                    <div className="col-12 mt-3 under18Table">
                        <table className='table'>
                            <thead>
                            <tr>
                                <td colSpan={3} style={{ width: "65%" }} className="fz-12-b">
                                    It is okay to share information about these services with my Parent,
                                    Guardian or Legally Authorized Representative named below:
                                </td>
                                <td colSpan={4} style={{ width: "35%" }} className="light-p">
                                    Any changes made in this section must be initialed
                                    and dated by the child and the Health Home Care
                                    Manager (HHCM) below.
                                    I take away my permission to share information
                                    about these services with my Parent, Guardian or
                                    Legally Authorized Representative, as follows:
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Types of Services and Name(s)
                                    of Provider and/or Agency</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Name of Parent, Guardian or
                                    Legally Authorized Representative</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Child’s Initials</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">HHCM Initials</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Mental Health Services:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="Section2PartBMentalHealthService1"
                                            value={data.Section2PartBMentalHealthService1}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBMentalHealthServiceDate1"
                                    value={data.Section2PartBMentalHealthServiceDate1}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBNameOfPa"
                                    value={data.Section2PartBNameOfPa}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBChildInitial"
                                    value={data.Section2PartBChildInitial}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBChildInitialDate"
                                    value={data.Section2PartBChildInitialDate}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBHHCMINitial"
                                    value={data.Section2PartBHHCMINitial}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBHHCMINitialDate"
                                    value={data.Section2PartBHHCMINitialDate}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Developmental Disability Services:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="Section2PartBDevelopmentalDisabilityServices2"
                                            value={data.Section2PartBDevelopmentalDisabilityServices2}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBDevelopmentalDisabilityServicesDate2"
                                    value={data.Section2PartBDevelopmentalDisabilityServicesDate2}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBNameOfParent2"
                                    value={data.Section2PartBNameOfParent2}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBChildInitial2"
                                    value={data.Section2PartBChildInitial2}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBChildInitialDate2"
                                    value={data.Section2PartBChildInitialDate2}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBHHCMINitial2"
                                    value={data.Section2PartBHHCMINitial2}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBHHCMINitialDate2"
                                    value={data.Section2PartBHHCMINitialDate2}
                                    onChange={jsonChange} /></td>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="col-12 mt-3 under18Table">
                        <table className='table'>
                            <thead>
                            <tr>
                                <td colSpan={3} style={{ width: "65%" }} className="fz-12-b">
                                    It is okay to share information about these services with the Provider(s) named below:
                                </td>
                                <td colSpan={4} style={{ width: "35%" }} className="light-p">
                                    Any changes made in this section must be initialed
                                    and dated by the child and the Health Home Care
                                    Manager (HHCM) below.
                                    I take away my permission to share information
                                    about these services with the Provider, as follows:
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Types of Services and Name(s)
                                    of Provider and/or Agency</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Name of Provider(s)</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Child’s Initials</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">HHCM Initials</td>
                                <td style={{ textAlign: "center" }} className="fz-12-b">Date</td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Mental Health Services:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="Section2PartBMentalHealthService3"
                                            value={data.Section2PartBMentalHealthService3}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBMentalHealthServiceDate3"
                                    value={data.Section2PartBMentalHealthServiceDate3}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBNameOfParent3"
                                    value={data.Section2PartBNameOfParent3}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBChildInitial3"
                                    value={data.Section2PartBChildInitial3}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBChildInitialDate3"
                                    value={data.Section2PartBChildInitialDate3}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBHHCMINitial3"
                                    value={data.Section2PartBHHCMINitial3}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBHHCMINitialDate3"
                                    value={data.Section2PartBHHCMINitialDate3}
                                    onChange={jsonChange} /></td>
                            </tr>
                            <tr>
                                <td>
                                    <Form.Group>
                                        <Form.Text className="text-black fz-12">
                                            Developmental Disability Services:
                                        </Form.Text>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            name="Section2PartBDevelopmentalDisabilityServices4"
                                            value={data.Section2PartBDevelopmentalDisabilityServices4}
                                            onChange={jsonChange} />
                                    </Form.Group>
                                </td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBDevelopmentalDisabilityServicesDate4"
                                    value={data.Section2PartBDevelopmentalDisabilityServicesDate4}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBNameOfParent4"
                                    value={data.Section2PartBNameOfParent4}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBChildInitial4"
                                    value={data.Section2PartBChildInitial4}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBChildInitialDate4"
                                    value={data.Section2PartBChildInitialDate4}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBHHCMINitial4"
                                    value={data.Section2PartBHHCMINitial4}
                                    onChange={jsonChange} /></td>
                                <td><Form.Control size="sm" type="text" placeholder=""
                                    name="Section2PartBHHCMINitialDate4"
                                    value={data.Section2PartBHHCMINitialDate4}
                                    onChange={jsonChange} /></td>
                            </tr>
                            </thead>
                        </table>
                    </div>
                    <div className="col-12">
                        <p className="light-p">By signing Section 2 of this form, I agree that</p>
                        <ul>
                            <li>I have had the chance to review the Health Home FAQ sheet and have had my questions answered, and </li>
                            <li>The Health Home and anyone I have named in Section 2 of this form can share my health information as listed above. They may share
                                information from before and after the date I sign this form. </li>
                        </ul>
                        <p className="light-p">I can change Section 2 of this form at any time. If I make changes, I have to initial and date next to those changes (or within the designated box).
                            I understand that this consent form, Section 2, takes the place of other Health Home information sharing consent forms, Section 2, I may have
                            signed before. Section 2 stays in place until:</p>
                        <ul>
                            <li>I withdraw it, or</li>
                            <li>I am no longer eligible for a Health Home, or</li>
                            <li>The Health Home is no longer in business, or</li>
                            <li>My Parent, Guardian or Legally Authorized Representative removes me from the Health Home program, or</li>
                            <li>I become the age or situation to self-consent (complete DOH-5055).</li>
                        </ul>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                readOnly
                                name="NameOfPatient"
                                value={data.NameOfPatient}
                                onChange={handleChange} />
                            <Form.Text className="text-muted fz-12">
                                PRINT NAME OF CHILD
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                readOnly
                                name="DOBOfPatient"
                                value={data.DOBOfPatient}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                CHILD’S DATE OF BIRTH
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            {/* <Form.Control size="sm" type="text" placeholder="" /> */}
                            <div>
                                <img src={data.SignatueOfChild} width={200} height={50} />
                            </div>
                            <Form.Text className="text-muted fz-12">
                                SIGNATURE OF CHILD
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                readOnly
                                name="CreatedDate"
                                value={data.CreatedDate}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                DATE
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="Cardmanager"
                                value={data.Cardmanager}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                PRINT NAME OF HEALTH HOME CARE MANAGER
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <div>
                                <img src={data.SignatueOfHomeCareManager} width={200} height={50} />
                            </div>
                            <Form.Text className="text-muted fz-12">
                                SIGNATURE OF HEALTH HOME CARE MANAGER
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                readOnly
                                name="CreatedDate"
                                value={data.CreatedDate}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                DATE
                            </Form.Text>
                        </Form.Group>
                    </div>
                </div>
                <div className="border-bottom-confirm-modal mt-3"></div>

                {/* Second Pdf Start */}
                <div className="row mt-5">
                    <div className="col-5 fz-12" style={{ paddingTop: '10px' }}>
                        <b>NEW YORK STATE DEPARTMENT OF HEALTH</b><br /><span>Office of Health Insurance Programs</span>
                    </div>
                    <div className="col-7 text-end fz-21">
                        <b>Health Home Consent</b>
                        <br />
                        <b>Release of Educational Records</b>
                    </div>
                </div>
                <div className="border-bottom-confirm-modal"></div>
                <div className="row mt-3">
                    <div className="col-12 mt-2">
                        <b className="fz-16">Instructions: </b>
                        <b className="light-p">: This form is for consent to release educational records to a Health Home for children and adolescents who have been enrolled in a
                            Health Home. It includes information on what educational records and with whom educational records can be shared. Consent for release of
                            educational records for children and adolescents under age 18 must be provided by the parent (see definition of parent in Question 5 below).
                            Consent for release of educational records for those aged 18 and over must be provided by the individual.
                        </b>
                    </div>
                    <div className="col-4 mt-4">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="NameOfHealthHome"
                                value={data.NameOfHealthHome}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                PRINT NAME OF HEALTH HOME
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-4 mt-4">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                readOnly
                                name="NameOfPatient"
                                value={data.NameOfPatient}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                PRINT NAME OF CHILD/PATIENT/CLIENT
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-4 mt-4">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="dob"
                                value={mData.dob}
                                onChange={handleChange}
                            />
                            <Form.Text className="text-muted fz-12">
                                DATE OF BIRTH
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-12 mt-3">
                        <b className="fz-16">INFORMATION SHARING EDUCATIONAL RECORDS</b>
                    </div>
                    <div className="col-12 mt-3">
                        <ol>
                            <li>What are education records?
                                <p className="light-p">
                                    Consistent with the Family Educational Rights and Privacy Act (FERPA) [34 CFR Part 99], educational records are those that are directly related
                                    to an infant or toddler in the Early Intervention Program (EIP) or a student and maintained by an educational agency or institution, such as an
                                    EIP provider, local early intervention official, or school that the child attended or attends.
                                </p>
                            </li>
                            <li>How will the Health Home and its providers use the child’s educational records?
                                <p className="light-p">
                                    The Health Home and its providers will use the child’s educational records to assist with the coordination and management of the child’s care.
                                </p>
                            </li>
                            <li>What laws and rules cover the release and sharing of the child’s educational records?
                                <p className="light-p">
                                    These laws and regulations include the federal Family Education Rights and Privacy Act of 1974 (FERPA) [34 CFR Part 99], New York’s Personal
                                    Privacy Protection Law (PPPL) [Public Officer’s Law §§91­99], NY Public Health Law (PHL) and regulations governing the EIP [Title IIA of
                                    Article 25 and 10 NYCRR §69­4], the Individuals with Disabilities Education Act (IDEA) [20 U.S.C. 1417 et seq.] and its implementing
                                    regulations at 34 CFR §300.610 through 300.627.
                                </p>
                            </li>
                            <li>Where do the child’s educational records come from?
                                <p className="light-p">
                                    Educational records come from any public or private EIP provider or local early intervention official or school, including a preschool, which
                                    maintains educational records for a child enrolled in a Health Home.
                                </p>
                            </li>
                            <li>A parent must sign consent to share educational records for a child under the age of 18. Who qualifies as the parent?
                                <p className="light-p">
                                    A parent includes a natural parent, a guardian, or an individual acting as a parent in the absence of a parent or a guardian [34 CFR § 99.3].
                                    Parent means a birth or adoptive parent, a legally appointed guardian generally authorized to act as the child’s parent or authorized to make
                                    early intervention or educational decisions for the child or a person in parental relationship [8 NYCRR§200.1(ii)][§2541(14) of PHL, 10
                                    NYCCR§69­4.1 (ah),(ai)]. A person in parental relation to another individual shall include his father or mother, by birth or adoption, his
                                    step­father or step­mother, his legally appointed guardian, or his custodian. A person shall be regarded as the custodian of another
                                    individual if he has assumed the charge and care of such individual because the parents or legally appointed guardian of such individual
                                    have died, are imprisoned, are mentally ill, or have been committed to an institution, or because, they have abandoned or deserted such
                                    individual or are living outside the state or their whereabouts are unknown [Ed.L. § 3212].  A parent may designate another person as a
                                    person in parental relation to act in the place of the birth or adoptive parent (including a grandparent, stepparent, or other relative with
                                    whom the child resides) pursuant to title 15­A of the General Obligations Law. A parent also includes a surrogate parent who has been
                                    appointed by the early intervention official or school district to make early intervention or educational decisions on behalf of the
                                    infant/toddler or student [8 NYCRR §200.5(n)][10 NYCRR 69­4.16(d)]. A parent does not include the State if the infant/toddler or student is a
                                    ward of the State [8 NYCRR §200.1(ii)(1)][10 NYCRR 69­4.16].
                                </p>
                            </li>
                            <li>Can the Heath Home redisclose the child’s educational records?
                                <p className="light-p">
                                    No.  FERPA only allows for redisclosure upon the consent of the parent [34 CFR §99.33].  If the Health Home seeks to redisclose the child’s early
                                    intervention or educational records, it must first seek consent from the parent (if the child is under age 18) or the individual (if 18 and over).
                                </p>
                            </li>
                        </ol>
                    </div>
                    <div className="col-12 mt-3">
                        <b className="fz-16">CONSENT FOR RELEASE OF EDUCATIONAL RECORDS</b>
                        <p className="light-p mb-0">I understand that:</p>
                        <ul>
                            <li className="light-p">providing consent will not impact mine or my child’s (as appropriate) receipt of EIP or special education programs and services;</li>
                            <li className="light-p">regardless of my decision to provide consent, all required services in mine or my child’s (as appropriate) Individualized Family Service Plan
                                or Individualized Education Program (IEP) will be provided at no cost to me; and</li>
                            <li className="light-p">upon request, I may review copies of the records disclosed pursuant to this consent.
                                <br />
                                <p className="light-p">By signing this form, I agree that:</p>
                            </li>
                            <li className="light-p">
                                <div className="d-inline-block">
                                    <Form.Group>
                                        <Form.Control size="sm" type="text" placeholder=""
                                            readOnly
                                            name="NameOfPatient"
                                            value={data.NameOfPatient}
                                            onChange={jsonChange} />
                                        <Form.Text className="text-muted fz-12" >
                                            PRINT NAME OF CHILD/PATIENT/CLIENT NOTED ABOVE
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                                <div className="d-inline-block mt-2 light-p" style={{ verticalAlign: 'top' }}>&nbsp;&nbsp;is enrolled in the Health Home;&nbsp;</div>
                            </li>
                            <li className="light-p">I have  reviewed the above information on sharing educational records; and</li>
                            <li className="light-p">I have been fully informed of all information related to my consent for release of educational records to the Health Home</li>
                        </ul>
                    </div>
                    <div className="col-12 mt-3">
                        <div className="d-inline-block mt-2 light-p" style={{ verticalAlign: 'top' }}>I voluntarily give my consent for&nbsp;</div>
                        <div className="d-inline-block">
                            <Form.Group>
                                <Form.Control size="sm" type="text" placeholder=""
                                    name="EIP_Provider"
                                    value={data.EIP_Provider}
                                    onChange={jsonChange} />
                                <Form.Text className="text-muted fz-12" >
                                    PRINT NAME OF SPECIFIC LOCAL EARLY INTERVENTION OFFICIAL, EIP PROVIDER(S), OR SCHOOL
                                </Form.Text>
                            </Form.Group>
                        </div>
                        <p className="light-p mt-1">to release the following educational records/information about me or my child (as appropriate)</p>
                    </div>
                    <div className="col-12 mt-3">
                        <div>
                            <Form.Check className="d-inline-block" type="checkbox" name="IsIEPs"
                                value={data.IsIEPs}
                                checked={data.IsIEPs}
                                onChange={jsonChange} /> &nbsp;
                            <b className="light-p"><small>Individualized Education Programs (IEPs)</small></b>&nbsp;
                        </div>
                        <div>
                            <Form.Check className="d-inline-block" type="checkbox" name="IsIFSP"
                                value={data.IsIFSP}
                                checked={data.IsIFSP}
                                onChange={jsonChange} /> &nbsp;
                            <b className="light-p"><small>Individualized Family Service Plan (IFSP);
                            </small></b>&nbsp;
                        </div>
                        <div>
                            <Form.Check className="d-inline-block" type="checkbox" name="IsSpecialEducationEvaluationReports"
                                value={data.IsSpecialEducationEvaluationReports}
                                checked={data.IsSpecialEducationEvaluationReports}
                                onChange={jsonChange} /> &nbsp;
                            <div className="d-inline-block" style={{ width: '90%', verticalAlign: 'top' }}>
                                <b className="light-p"><small>Special Education Evaluation Reports (e.g. social history, psychological, classroom observation, other assessments that describe the physical,
                                    mental, behavioral and emotional factors that contribute to the disability);
                                </small></b>&nbsp;
                            </div>
                        </div>
                        <div>
                            <Form.Check className="d-inline-block" type="checkbox" name="IsEarlyInterventionMultidisciplinary"
                                value={data.IsEarlyInterventionMultidisciplinary}
                                checked={data.IsEarlyInterventionMultidisciplinary}
                                onChange={jsonChange} /> &nbsp;
                            <div className="d-inline-block" style={{ width: '90%', verticalAlign: 'top' }}>
                                <b className="light-p"><small>Early Intervention Multidisciplinary evaluations and assessments, including core and supplemental assessments and other assessments that
                                    describe the child’s physical, cognitive, communication, social emotional,  and adaptive development and/or diagnosed physical or mental
                                    condition with a high probability of resulting in developmental delay);
                                </small></b>&nbsp;
                            </div>
                        </div>
                        <div>
                            <Form.Check className="d-inline-block" type="checkbox" name="IsVoluntary"
                                value={data.IsVoluntary}
                                checked={data.IsVoluntary}
                                onChange={jsonChange} /> &nbsp;
                            <b className="light-p"><small>Voluntary Family Assessment if completed as part of the EIP multidisciplinary evaluation;
                            </small></b>&nbsp;
                        </div>
                        <div>
                            <Form.Check className="d-inline-block" type="checkbox" name="IsOtherSpecify"
                                value={data.IsOtherSpecify}
                                checked={data.IsOtherSpecify}
                                onChange={jsonChange} /> &nbsp;
                            <b className="light-p"><small>Other, specify:
                            </small></b>&nbsp;
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <Form.Control size="sm" type="text" placeholder=""
                            name="OtherSpecifyText1"
                            value={data.OtherSpecifyText1}
                            onChange={jsonChange} />
                    </div>
                    <div className="col-12 mt-2">
                        <Form.Control size="sm" type="text" placeholder=""
                            name="OtherSpecifyText2"
                            value={data.OtherSpecifyText2}
                            onChange={jsonChange} />
                    </div>
                    <div className="col-12 mt-3">
                        <p className="light-p">I voluntarily consent to releasing the records checked above to:
                        </p>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="NameOfHealthHome"
                                value={data.NameOfHealthHome}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                NAME OF HEALTH HOME
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="NameOfHealthCareManagenentAgency"
                                value={data.NameOfHealthCareManagenentAgency}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                NAME OF HEALTH HOME CARE MANAGEMENT AGENCY
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="NameOfPhysician"
                                value={data.NameOfPhysician}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                NAME OF PHYSICIAN
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="OtherPerson1"
                                value={data.OtherPerson1}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                OTHER
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2"></div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="OtherPerson2"
                                value={data.OtherPerson2}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                OTHER
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-12 mt-3">
                        <p className="light-p mb-0">I understand that:</p>
                        <ul>
                            <li className="light-p"> I may withdraw this consent at any time by signing the Health Home Consent/Withdrawal of Release of Educational Records form
                                (DOH­5204);</li>
                            <li className="light-p">if I revoke consent it does not negate an action that occurred after my consent was given and before my consent was revoked; and</li>
                            <li className="light-p">my consent shall remain in effect until it is revoked in writing
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 mt-2">
                        <b className="fz-14">For children under age 18:</b>
                    </div>
                    <div className="col-6 mt-2">
                        <b className="fz-14">For individuals aged 18 and over:</b>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="NameOfLegalRepresentativeRelationship"
                                readOnly
                                value={data.NameOfLegalRepresentativeRelationship}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                PRINT NAME OF CHILD’S PARENT
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                readOnly
                                name="NameOfPatient"
                                value={data.NameOfPatient}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                PRINT NAME OF CHILD/PATIENT/CLIENT
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <div>
                                <img src={data.Signature} width={200} height={50} />
                            </div>
                            <Form.Text className="text-muted fz-12">
                                SIGNATURE OF CHILD’S PARENT
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <div>
                                <img src={data.SignatueOfChild} width={200} height={50} />
                            </div>
                            <Form.Text className="text-muted fz-12">
                                SIGNATURE OF CHILD/PATIENT/CLIENT
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                readOnly
                                name="CreatedDate"
                                value={data.CreatedDate}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                DATE
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-6 mt-2">
                        <Form.Group>
                            <Form.Control size="sm" type="text" placeholder=""
                                name="CreatedDate"
                                value={data.CreatedDate}
                                onChange={jsonChange} />
                            <Form.Text className="text-muted fz-12">
                                DATE
                            </Form.Text>
                        </Form.Group>
                    </div>
                    <div className="col-12 mt-4">
                        <p className="light-p">Do you authorize the EIP local early intervention official and EIP provider(s) or school to transmit protected educational records via encrypted
                            (secure) e­mail to the Health Home? (Check box)</p>
                    </div>
                    <div className="col-12">
                        <div>
                            <Form.Check className="d-inline-block" type="radio" name="IsEncryptedSecureEmailToTheHealthHome"
                                value={'true'}
                                checked={data.IsEncryptedSecureEmailToTheHealthHome}
                                onChange={jsonChange} /> &nbsp;
                            <b className="light-p"><small>Yes</small></b>&nbsp;
                        </div>
                        <div>
                            <Form.Check className="d-inline-block" type="radio" name="IsEncryptedSecureEmailToTheHealthHome"
                                value={'false'}
                                checked={data.IsEncryptedSecureEmailToTheHealthHome}
                                onChange={jsonChange} /> &nbsp;
                            <b className="light-p"><small>No</small></b>&nbsp;
                        </div>
                    </div>
                </div>
                <div className="border-bottom-confirm-modal mt-3"></div>

                {/* First Pdf Start */}
                <div className="row mt-5">
                    <div className="col-5 fz-12" style={{ paddingTop: '30px' }}>
                        <b>NEW YORK STATE DEPARTMENT OF HEALTH</b><br /><span>Office of Health Insurance Programs</span>
                    </div>
                    <div className="col-7 text-end fz-21">
                        <b>Health Home Consent</b>
                        <br />
                        <b>Frequently Asked Questions (FAQ)</b>
                        <br />
                        <b>For Use with Children Under 18 Years of Age</b>
                    </div>
                </div>
                <div className="border-bottom-confirm-modal"></div>
                <div className="row mt-3">
                    <div className="col-12 mt-2">
                        <b className="fz-16">Instructions: </b>
                        <b className="light-p">: This document should be given to children who are less than 18 years of age and who are eligible to enroll in a Health Home, and to
                            their parents, guardians or legally authorized representatives. This document should be reviewed with them prior to completion of Health Home
                            Consent/Enrollment/For Use with Children Under 18 Years of Age form (DOH 5200) and Health Home Consent/Information Sharing/For Use with
                            Children Under 18 Years of Age form (DOH 5201). However, children who are parents, pregnant, and/or married, and who otherwise are capable of
                            consenting, should not be given this document. They should be given the Health Home Patient Information Sharing Consent form (DOH 5055) to
                            review and complete.
                        </b>
                    </div>
                    <div className="col-12 mt-3">
                        <ol>
                            <li>What is the Health Home Program?
                                <br />
                                <p className="light-p">The goal of the Health Home program is to make sure you get the care and services you need to be healthy. Maybe it’s been hard for you to find
                                    the right doctor. Or, maybe you have been in the hospital or emergency room recently. The Health Home program has people who can help.
                                    These people are called “care managers”. As a member of the Health Home program, you will have your own care manager.
                                </p>
                                <p className="light-p mb-0">Your care manager makes sure you have the services you need such as:</p>
                                <ul>
                                    <li className="light-p">appointments with healthcare providers (such as doctors, nurses, nutritionists, counselors, mental health providers, substance abuse
                                        providers, developmental disabilities providers),</li>
                                    <li className="light-p">medications</li>
                                    <li className="light-p">a safe place to live</li>
                                    <li className="light-p">insurance to pay for your care and services, and/or</li>
                                    <li className="light-p">transportation to your appointments.</li>
                                </ul>
                                <p className="light-p mt-2">Your care manager works with you to set up a care plan. A care plan lists your needs, your goals for each need, and what providers and
                                    services you need to reach your goals. The people and services involved in your care plan become your Health Home “team”. To get everything
                                    you need, your team must talk to each other and share your health information.
                                    Your care manager is there to help and answer your questions.</p>
                            </li>
                            <li>How do I qualify for a Health Home?
                                <p className="light-p mb-0">To be in a Health Home, you must</p>
                                <ul>
                                    <li className="light-p">have one single qualifying condition or two or more chronic conditions,</li>
                                    <li className="light-p">need a Care Manager to help you get your care and services, and</li>
                                    <li className="light-p">have Medicaid</li>
                                </ul>
                                <p></p>
                            </li>
                            <li>What are the advantages of being in a Health Home?
                                <ul>
                                    <li className="light-p">You have one care manager who is coordinating your care and services</li>
                                    <li className="light-p">Your care manager can help you understand how to manage your healthcare and social needs</li>
                                    <li className="light-p">You have a team of providers available to you for questions you may have</li>
                                    <li className="light-p">• Your care manager will make sure that your providers are talking to each other to help you stay healthy</li>
                                </ul>
                                <p></p>
                            </li>
                            <li>How do I enroll in a Health Home?
                                <p className="light-p">Your parent, guardian, or legally authorized representative enrolls you in a Health Home by signing Health Home Consent/Enrollment/For Use
                                    with Children Under 18 Years of Age form (DOH 5200).</p>
                                <p className="light-p">For the purpose of enrollment, a legally authorized representative is a person or agency authorized by state, tribal, military or other applicable
                                    law, court order or consent to act on your behalf in making health care decisions.</p>
                            </li>
                            <li>Once you are enrolled in a Health Home, the Health Home and your providers will need to share your health information. What is “health
                                information”?
                                <p className="light-p">Your health information includes general information about your health. It may include information regarding illnesses or injuries you have
                                    had, medical records, such as x-rays or tests results, or a list of prescription drugs you have taken. Your health information may relate to
                                    treatment for sensitive health conditions, including, but not limited to: Sexually Transmitted Infection (including HIV) Testing and Treatment;
                                    HIV Prevention (Pre-exposure and Post-exposure Prophylaxis (PrEP/PEP)); Mental Health Conditions; Developmental Disabilities; or Alcohol or
                                    Substance Abuse treatment</p>
                            </li>
                            <li>What do we mean by “sharing” your health information?
                                <p className="light-p mb-0">People who are able to share your health information can:
                                </p>
                                <ul>
                                    <li className="light-p">get</li>
                                    <li className="light-p">see</li>
                                    <li className="light-p">read</li>
                                    <li className="light-p">discuss, and/or</li>
                                    <li className="light-p">copy your health information as allowed by law.</li>
                                </ul>
                                <p></p>
                            </li>
                            <li>Why do the Health Home and providers need your health information?
                                <p className="light-p mb-0">Remember, it is important for all the people in your Health Home team to be able to talk to each other. This means they will need to share your
                                    health information. Your health information will be used to:
                                </p>
                                <ul>
                                    <li className="light-p">Help you get care,</li>
                                    <li className="light-p">Help you manage your care, and</li>
                                    <li className="light-p">Make sure your health insurance is working and see what it pays for</li>
                                </ul>
                                <p className="light-p">
                                    Health plans (insurance companies) cannot use this health information to decide if they will give you health insurance. They cannot use this
                                    information to decide to pay your medical bills. Health plans have their own forms to get thisinformation
                                </p>
                            </li>
                            <li>Where will my health information come from?
                                <p className="light-p">Your health information comes from places and people that gave, or currently give, you health care or health insurance. These may include
                                    hospitals, doctors, pharmacies, laboratories, health plans (insurance companies), the Medicaid program, dentists, and other groups that share
                                    information.
                                </p>
                            </li>
                            <li>Who can share my health information?
                                <p className="light-p mb-0">
                                    Your parent, guardian, or legally authorized representative will decide who shares your health information by completing Health Home
                                    Consent/Information Sharing/For Use with Children Under 18 Years of Age form (DOH 5201), section 1. For the purpose of sharing health
                                    information, a legally authorized representative is a person or agency authorized by state, tribal, military or other applicable law, court order
                                    or consent to act on behalf of a person for the release of medical information. If a local commissioner of social services is the person who
                                    consents to your health care, then he or she is also the person that will consent to share your health information.
                                </p>
                                <p className="light-p mb-0">
                                    In some situations, the law lets you decide who can share your health information. Here are some situations where you have control over your
                                    health information and who gets to share it, by completing Health Home Consent/Information Sharing/For Use with Children Under 18 Years
                                    of Age form (DOH 5201), section 2:
                                </p>
                                <ul>
                                    <li className="light-p">Family planning</li>
                                    <li className="light-p">Emergency contraception</li>
                                    <li className="light-p">Abortion</li>
                                    <li className="light-p">Sexually Transmitted Infection (including HIV) Testing and Treatment</li>
                                    <li className="light-p">HIV Prevention Pre-exposure and Post-Exposure Prophylaxis (PrEP/PEP)</li>
                                    <li className="light-p">Prenatal Care, Labor/Delivery</li>
                                    <li className="light-p">Drug and Alcohol Treatment</li>
                                    <li className="light-p">Sexual Assault Services</li>
                                </ul>
                                <p className="light-p">
                                    Only if you are over the age of twelve, your clinician may ask you before releasing information about your mental health and/or developmental
                                    disabilities services.
                                </p>
                                <p className="light-p">
                                    New York State also has access to your health information because it pays for your Medicaid services. New York State may use your health
                                    information to learn more about the Health Home program to make changes and improve it.
                                </p>
                            </li>
                            <li>What laws and rules cover how my health information can be shared?
                                <p className="light-p">There are several federal and state laws. These laws include New York Mental Hygiene Law Section 33.13 and 33.16, New York Public Health Law
                                    Article 27-F, and federal confidentiality rules, including 42 CFR Part 2 and 45 CFR Parts 160 and 164 (which are the rules referred to as the Health
                                    Information Portability and Accountability Act (HIPAA)). The Health Home and providers that share the child’s health information must obey
                                    these laws and rules.
                                </p>
                            </li>
                            <li>What if a person uses my health information and I didn’t agree to let them use it?
                                <p className="light-p mb-0">
                                    If this happens, you can:
                                </p>
                                <ul>
                                    <li className="light-p">call the Medicaid Helpline at 1-800-541-2831, or</li>
                                    <li className="light-p">contact the US Department of Health and Human Services, Office for Civil Rights at 1-800-368-1019, or submit a written complaint at:
                                        https://www.hhs.gov/hipaa/filing-a-complaint/index.html</li>
                                </ul>
                                <p className="light-p mb-0">
                                    You may also want to:
                                </p>
                                <ul>
                                    <li className="light-p">call one of the providers you have said can see your records</li>
                                    <li className="light-p">call your care manager or health home:
                                        &nbsp;<Form.Control className="d-inline-block" style={{ 'width': '200px' }}
                                            placeholder="" size="sm" type="text"
                                            name="NameOfHealthHome"
                                            value={data.NameOfHealthHome}
                                            onChange={jsonChange} />
                                        &nbsp;at&nbsp;<Form.Control className="d-inline-block" style={{ 'width': '180px' }}
                                            name="numberOfHealthHome"
                                            value={data.numberOfHealthHome}
                                            onChange={jsonChange} placeholder="" size="sm" type="text" />, or
                                    </li>
                                    <li className="light-p">call your Managed Care Plan if you belong to a Managed Care Plan. </li>
                                </ul>
                                <p className="light-p mt-2">
                                    If you experience discrimination because of the release or disclosure of a physical or psychiatric disability, substance or alcohol abuse
                                    treatment, or HIV/AIDS related information, you may contact the New York State Division of Human Rights at 1-888-392-3644. This agency is
                                    responsible for protecting your rights.
                                </p>
                            </li>
                            <li>Do I have to pay to be in a Health Home?
                                <p className="light-p">No. The Health Home program is provided by Medicaid</p>
                            </li>
                            <li>Am I required to be in a Health Home?
                                <p className="light-p">No. You are not required to be in a Health Home. Once you have been given information about the Health Home Program and how it can help
                                    you, you decide if you want to enroll.</p>
                            </li>
                            <li>If I don’t enroll in a Health Home, will I still be able to get health services?
                                <p className="light-p">Yes. You can still see your doctors and providers, get health services, and keep your health insurance.</p>
                            </li>
                            <li>If I enroll in a Health Home today, can I leave the Health Home at any time?
                                <p className="light-p">Yes. You can change your mind at any time and withdraw from the Health Home. However, your parent, guardian, or legally authorized
                                    representative will need to agree and sign the Health Home Consent/Withdrawal of Health Home Enrollment and Information Sharing
                                    Consent Form/ For Use with Children Under 18 Years form (DOH 5202). If you are withdrawn from the Health Home, you can still see your
                                    doctors and providers and get health services as long as you have Medicaid.
                                </p>
                            </li>
                            <li>How long does my consent last?
                                <p className="light-p">Your consent will last until the day you take back your consent, or if you leave the Health Home program, or if the Health Home is no longer
                                    in business.
                                </p>
                            </li>
                            <li>If I don’t enroll in a Health Home today, can I change my mind later?
                                <p className="light-p">Yes. You may be enrolled at any time, if you are still found eligible.
                                </p>
                            </li>
                            <li>If I have questions about the Health Home Program, I can contact:
                                <br />
                                <div className="d-inline-block mt-2">
                                    <Form.Group>
                                        <Form.Control style={{ 'width': '230px' }} size="sm" type="text" placeholder=""
                                            name="Cardmanager"
                                            value={data.Cardmanager}
                                            onChange={jsonChange} />
                                        <Form.Text className="text-muted fz-12">
                                            Name of Care Manager
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                                <div className="d-inline-block mt-2" style={{ verticalAlign: 'top' }}>&nbsp;From&nbsp;</div>
                                <div className="d-inline-block mt-2">
                                    <Form.Group>
                                        <Form.Control style={{ 'width': '230px' }} size="sm" type="text" placeholder=""
                                            name="NameOfHealthCareManagenentAgency"
                                            value={data.NameOfHealthCareManagenentAgency}
                                            onChange={jsonChange} />
                                        <Form.Text className="text-muted fz-12">
                                            Name of Care Management Agency
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                                <div className="d-inline-block mt-2" style={{ verticalAlign: 'top' }}>&nbsp;at&nbsp;</div>
                                <div className="d-inline-block mt-2">
                                    <Form.Group>
                                        <Form.Control style={{ 'width': '200px' }} size="sm" type="text" placeholder=""
                                            name="CardmanagerNumber"
                                            value={data.CardmanagerNumber}
                                            onChange={jsonChange} />
                                        <Form.Text className="text-muted fz-12">
                                            Phone Number
                                        </Form.Text>
                                    </Form.Group>
                                </div>
                                <div className="row mt-4">
                                    <div className="col-6">
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={5} className="text-muted fz-12">
                                                Name of Health Home:
                                            </Form.Label>
                                            <Col sm={7}>
                                                <Form.Control size="sm" type="text" placeholder=""
                                                    name="NameOfHealthHome"
                                                    value={data.NameOfHealthHome}
                                                    onChange={jsonChange} />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                    <div className="col-6">
                                        <Form.Group as={Row}>
                                            <Form.Label column sm={4} className="text-muted fz-12">
                                                Phone Number:
                                            </Form.Label>
                                            <Col sm={8}>
                                                <Form.Control size="sm" type="text" placeholder=""
                                                    name="numberOfHealthHome"
                                                    value={data.numberOfHealthHome}
                                                    onChange={jsonChange} />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                </div>
                            </li>
                        </ol>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Under18Form;