import GeneralInfo from './GeneralInfo'
import AccidentInfo from './AccidentInfo'
import ModsInfo from './ModsInfo'
import ElectricalInfo from './ElectricalInfo'
import MechInfo from './MechInfo'

export { 
  GeneralInfo,
  AccidentInfo,
  ModsInfo,
  ElectricalInfo,
  MechInfo
}