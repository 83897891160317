import { useCallback, useEffect, useMemo, useState } from 'react'
import { StatusInfo } from '../enum/Status'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'

interface PropsActionsModels {
  id: any
  CustomersId: any
  modelName: any
  setStateAdd: any
  stateAdd: any
  // history: any
  modelsId?: string
}
type ApiDataState = { modelData: any }
const fields: string[] = ['Id', 'CustomersId', 'Date', 'Description', 'Amount', 'InspectorsId']
export const useActionsModels = ({
  id,
  CustomersId,
  modelName,
  modelsId = 'Id',
  setStateAdd,
  stateAdd
  // history,
}: PropsActionsModels): [ApiDataState, StatusInfo, () => Promise<void>, Function, Function, Function] => {
  const [apiData, setApiData] = useState<ApiDataState>({ modelData: null })
  const [status, setStatus] = useState<StatusInfo>(StatusInfo.loading)
  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback<any>(async () => {
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [modelsId]: 0, 'CustomersId': CustomersId },
      })
      const dataResponse = mainData?.data ? mainData.data[0] : mainData
      setApiData({ modelData: dataResponse })
      setStatus(StatusInfo.idle)
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }, [rootRepository, id, CustomersId, modelName, modelsId])

  useEffect(() => {
    getData()
  }, [getData])

  const mappingFields = (apiData) => {
    const dataToSave: any = apiData.modelData

    return {
      ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
    }
  }
  const saveData = async () => {
    setStatus(StatusInfo.saving)
    try {
      const { message } = await SaveModel({
        body: {
          Model: modelName,
          Params: mappingFields(apiData),
        },
      })
      
      toast.success(message)
      getData()
      setStateAdd(stateAdd)
      // goBack()
      setStatus(StatusInfo.idle)
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }
  const handleNumberChange = ({ name = '', value = '' }) => {
    if(value[value.length-1]==='.' && value.substring(0, value.length - 1).indexOf('.')!==-1){
      value = value.substring(0, value.length - 1)
    }

    if(value[value.length-1]!=='.'){
      if(value[value.length-1]<'0' || value[value.length-1]>'9'){
        value = value.substring(0, value.length - 1)
      }
    }
    
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }
  const handleComboChange = (e: any, newValue: any = {}) => {
    if (!e.target) return
    const CodeId = newValue ? newValue.CodeId : null
    setApiData((st: any) => ({ ...st, modelData: { ...st.modelData, InspectorsId: CodeId } }))
  }

  return [apiData, status, saveData, handleChange, handleComboChange, handleNumberChange]
}
