// Generated with util/create-search-page.js
import { useState, useEffect } from 'react'
import { CCardBody, CCol, CRow, CTabPane } from '@coreui/react'
import { Loader } from 'src/shared'
import { HeaderComponent, TabsInformation } from './components'
import {
  JEMCARE_MODELS,
  BASE_API,
  SERVICES,
  MODELS,
  LIST,
} from 'src/services/routeConst'
import axios from 'axios'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'
import { addAuthAccessTokenToHeader } from 'src/services/authHelper'
import { createContext } from 'react'
import JemcareInfo from './components/JemcareInfo'
// import JemcareFormInfo from './components/JemcareFormInfo'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import JemcarePdfs from './components/JemcarePdfs'
import JemcareFiles from './components/JemcareFiles'

export const LeadContext = createContext<{
  LeadsId: number
  MetasId: number
}>({
  LeadsId: 0,
  MetasId: 0,
})

const ModelsDetails = ({ history, match, modelName = JEMCARE_MODELS }) => {
  const { id = 0 } = match.params
  const [facilitiesData, setFacilitiesData] = useState<any>({ modelData: null })
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id,
    modelName,
    history,
  })

  const fetchFacilities = async () => {
    try {
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}${LIST}`,
        {
          Model: 'Facilities',
          Params: [],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      setFacilitiesData(data.data.ListInfo)
      //  data.data && setServices(data.data.ListInfo)
    } catch (error: any) {
      // const status = error.response.status
      // logoutVerifyErrors(status)
    }
  }

  useEffect(() => {
    fetchFacilities()
  }, [])

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData, statusData } = apiData
  if (modelData.ExistingParticipatPartners) {
    if (typeof modelData.ExistingParticipatPartners === 'string') {
      modelData.ExistingParticipatPartners = modelData.ExistingParticipatPartners
        ? JSON.parse(modelData.ExistingParticipatPartners)
        : []
    }
  } else {
    modelData.ExistingParticipatPartners = []
  }
  if (modelData.Race) {
    if (typeof modelData.Race === 'string') {
      modelData.Race = modelData.Race ? JSON.parse(modelData.Race) : []
    }
  } else {
    modelData.Race = []
  }
  if (modelData.ManualParticipatPartners) {
    if (typeof modelData.ManualParticipatPartners === 'string') {
      modelData.ManualParticipatPartners = modelData.ManualParticipatPartners
        ? JSON.parse(modelData.ManualParticipatPartners)
        : []
    }
  } else {
    modelData.ManualParticipatPartners = []
  }
  if (modelData.IsAnyCondition) {
    if (typeof modelData.IsAnyCondition === 'string') {
      modelData.IsAnyCondition = modelData.IsAnyCondition
        ? JSON.parse(modelData.IsAnyCondition)
        : []
    }
  } else {
    modelData.IsAnyCondition = []
  }
  if (modelData.SeriousListCondition) {
    if (typeof modelData.SeriousListCondition === 'string') {
      modelData.SeriousListCondition = modelData.SeriousListCondition
        ? JSON.parse(modelData.SeriousListCondition)
        : []
    }
  } else {
    modelData.SeriousListCondition = []
  }

  const handleCheck = (event) => {
    let nm = event?.target.name
    console.log(nm, event.target.checked)
    if (
      nm === 'ConsentReferDate' ||
      nm === 'otherEnrolled' ||
      nm === 'IsSecondCondition' ||
      nm === 'SeriousListCondition' ||
      nm === 'OtherHousing' ||
      nm === 'RiskFactorHistory' ||
      nm === 'IsAnyCondition'
    ) {
      handleChange({ name: event?.target.name, value: event?.target.value })
    } else {
      handleChange({ name: event?.target.name, value: event?.target.checked })
    }
  }
  const jsonChange = (event) => {
    console.log(event)
    // let jsonData = JSON.parse(modelData.JsonForm);
    // let JsonForm = { ...jsonData, [event.target.name]: event.target.value };
    // handleChange({ name: 'JsonForm', value: JSON.stringify(JsonForm) })
  }

  return (
    <LeadContext.Provider
      value={{
        LeadsId: id,
        MetasId: modelData?.MetasId ?? 0,
      }}
    >
      <>
        <HeaderComponent
          modelName={modelName}
          onCancelHandle={history.goBack}
          status={status}
          saveData={saveData}
          modelData={modelData}
          statusData={statusData}
          handleChange={handleChange}
          jsonChange={jsonChange}
          facilitiesData={facilitiesData}
        />
        <>
          <CCardBody className={'custom-card-body'}>
            <CRow>
              <CCol xs={8} className={'p-0'}>
                <DetailTabSection
                  id={id}
                  hideNotes={true}
                  modelName={modelName}
                  tabs={[
                    { name: 'GeneralInformation', label: 'General Information' },
                    { name: 'PdfForm', label: 'Forms' },
                    { name: 'Files', label: 'Files' },
                  ]}
                >
                  <CTabPane data-tab='GeneralInformation' className='detail-tab-section-pane'>
                    <CRow>
                      <CCol xs='12' md='12'>
                        <JemcareInfo
                          modelData={modelData}
                          handleChange={handleChange}
                          handleCheck={handleCheck}
                        ></JemcareInfo>
                      </CCol>
                    </CRow>
                  </CTabPane>
                  <CTabPane data-tab='PdfForm' className='detail-tab-section-pane'>
                    <CRow>
                      <CCol xs='12' md='12'>
                        <JemcarePdfs modelData={modelData}></JemcarePdfs>
                      </CCol>
                    </CRow>
                  </CTabPane>
                  <CTabPane data-tab='Files' className='detail-tab-section-pane'>
                    <CRow>
                      <CCol xs='12' md='12'>
                        <JemcareFiles modelData={modelData}></JemcareFiles>
                      </CCol>
                    </CRow>
                  </CTabPane>
                </DetailTabSection>
              </CCol>
              <CCol xs={4}>
                <TabsInformation modelData={modelData} />
              </CCol>
            </CRow>
          </CCardBody>
        </>
      </>
    </LeadContext.Provider>
  )
}

export default ModelsDetails
