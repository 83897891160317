import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { LOOKUP_TYPES_ID, LOOKUPS_ID, LOOKUPS_MODEL } from 'src/services/routeConst'

const fields = [
  LOOKUPS_ID,
  LOOKUP_TYPES_ID,
  'Code',
  'Description',
  'OtherValue1',
  'OtherValue2',
  'OtherValue3',
  'OtherValue4',
  'OtherValue5',
]
interface PropsEditLookupValueModal {
  showModal?: any
  modelName?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}
const EditLookupValueModal = ({
  showModal = false,
  modelName = LOOKUPS_MODEL,
  setShowModal,
  modalData,
  callBack = () => {},
}: PropsEditLookupValueModal) => {
  const [status, setStatus] = useState('loading')
  const [apiData, setApiData] = useState({ lookupValueData: null })
  const { id, lookup_id, lookupTypeData } = modalData

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(async () => {
    // console.log('gettingData', lookup_id, id)
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [LOOKUP_TYPES_ID]: lookup_id, [LOOKUPS_ID]: 0 },
      })
      setApiData({ lookupValueData: mainData })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }, [lookup_id, id, modelName, rootRepository])
  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    const dataToSave: any = apiData.lookupValueData
    await rootRepository.saveData({
      modelName,
      idName: LOOKUPS_ID,
      dataToSave: Object.assign(
        {},
        ...fields.map((field) => ({
          [field]: dataToSave[field],
        })),
      ),
    })
    setApiData({ lookupValueData: null })
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        lookupValueData: { ...st.lookupValueData, [name]: value },
      }))
  }

  if (!apiData.lookupValueData || status === 'loading') {
    return null
  }

  const { lookupValueData }: any = apiData
  const { OtherField1, OtherField2, OtherField3, OtherField4, OtherField5 } = lookupTypeData
  const otherFields = [OtherField1, OtherField2, OtherField3, OtherField4, OtherField5]
  // console.log(lookupTypeData, otherFields)
  return (
    <CModal
      show={showModal}
      onClose={() => {
        // setApiData({ lookupValueData: null })
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} Lookup Value</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='Lookup Value info'>
              {/* <CFormGroup row>
                <CCol xs='12' sm='4'>
                  <InputField
                    name='LookupsId'
                    label='Lookup ID'
                    type='number'
                    value={lookupValueData.LookupsId}
                    disabled={id || lookup_id}
                  />
                </CCol>
              </CFormGroup> */}
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Code'
                    type='text'
                    label='Code'
                    value={lookupValueData.Code}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Description'
                    type='text'
                    label='Description'
                    value={lookupValueData.Description}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              {otherFields?.map((field, idx) =>
                field ? (
                  <CFormGroup row key={idx}>
                    <CCol xs='12' sm='12'>
                      <InputField
                        name={`OtherValue${idx + 1}`}
                        type='text'
                        label={lookupTypeData[`OtherFieldDesc${idx + 1}`] ?? `OtherValue${idx + 1}`}
                        value={lookupValueData[`OtherValue${idx + 1}`]}
                        onChange={handleChange}
                      />
                    </CCol>
                  </CFormGroup>
                ) : null,
              )}
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            // setApiData({ lookupValueData: null })
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default EditLookupValueModal
