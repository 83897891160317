import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { MembersRepository } from 'src/repository/admin/member/MembersRepository'
import { PdfViewer } from 'src/shared'
interface StatmentsModal {
  showModal?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}
const StatementsModal: React.FC<StatmentsModal> = ({
  showModal = false,
  setShowModal,
  modalData,
  callBack = () => {},
}: StatmentsModal) => {
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<any>()
  const { id, UsersId, modelName } = modalData
  const fields = [
    'StatementsId',
    'Date',
    'UsersId',
    'DatePaymentDue',
    'CreditAvailable',
    'CreditLimit',
    'AprPurchase',
    'AprCashAdvance',
    'AprSavingsYtd',
    'BalanceTotal',
    'MinimumDue',
    'AmountSpending',
    'AmountPaymentsCredits',
    // 'FilesId_StatementPdf',
  ]

  const membersRepository = React.useMemo(() => new MembersRepository(), [])

  useEffect(() => {
    const getDataInEffect = async () => {
      try {
        const { mainData } = await membersRepository.getData({
          id,
          modelName: modelName,
          defaultMainData: { UsersId, StatementsId: 0, FilesId_StatementPdf: 0 },
        })
        console.log('MAIN DATA', mainData)
        setData(mainData)
      } catch (error: any) {
        console.error(error)
      }
    }
    loadingData && getDataInEffect()
  }, [UsersId, id, loadingData, membersRepository, modelName])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    data && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    const dataToSave: any = data
    try {
      await membersRepository.saveData({
        modelName: modelName,
        idName: 'StatementsId',

        dataToSave: {
          ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
          UsersId,
        },
      })
    } catch (error: any) {
      console.error(error)
    }
    // setData({})
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setData((st: any) => ({ ...st, [name]: value }))
  }

  if (loadingData) {
    return null
  }
  // console.log(data)
  return (
    <CModal
      show={showModal}
      size={'xl'}
      onClose={() => {
        // setData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} Statement</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='Statement Info'>
              <CFormGroup row>
                <CCol xs='6' sm='6'>
                  <InputField
                    name='Date'
                    type='mask'
                    mask={[/[0-1]/, /\d/, '-', /[0-3]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    label='Date'
                    value={data.Date}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='6' sm='6'>
                  <InputField
                    name='DatePaymentDue'
                    type='mask'
                    mask={[/[0-1]/, /\d/, '-', /[0-3]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    label='Date Due'
                    value={data.DatePaymentDue}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>

              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='CreditAvailable'
                    label='Credit Available'
                    type='currency'
                    alignment='right'
                    value={data.CreditAvailable}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='CreditLimit'
                    label='Credit Limit'
                    type='currency'
                    alignment='right'
                    value={data.CreditLimit}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='AprPurchase'
                    label='Apr Purchase'
                    type='currency'
                    alignment='right'
                    value={data.AprPurchase}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='AprCashAdvance'
                    label='Apr CashAdvance'
                    type='currency'
                    alignment='right'
                    value={data.AprCashAdvance}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='AprSavingsYtd'
                    label='Apr Savings Ytd'
                    type='currency'
                    alignment='right'
                    value={data.AprSavingsYtd}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='BalanceTotal'
                    label='Total Balance'
                    type='currency'
                    alignment='right'
                    value={data.BalanceTotal}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='MinimumDue'
                    label='Minimum Due'
                    type='currency'
                    alignment='right'
                    value={data.MinimumDue}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='AmountSpending'
                    label='Amount Spending'
                    type='currency'
                    alignment='right'
                    value={data.AmountSpending}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='AmountPaymentsCredits'
                    label='Amount Payments Credits'
                    type='currency'
                    alignment='right'
                    value={data.AmountPaymentsCredits}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <PdfViewer onSavePdf={(data: any) => console.log(data)}></PdfViewer>
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            // setData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default StatementsModal
