export const assignActionFuncsToTableColumns = (columnData: any, actionFunctions: any) => {
  const columns = columnData.map((column: any) =>
    column.type === 'action'
      ? {
          ...column,
          actionFunctions,
        }
      : column,
  )

  return columns
}

export const adjustTableData = (data: any, idName = 'id') =>
  data.map((d: any) => ({ ...d, actionData: { id: d[idName] } }))
