import { CCol, CFormGroup } from '@coreui/react'
// import { RadioGroup, Radio } from 'react-radio-input'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange?: any
  title?: string
}
// const RadioGroupTs: any = RadioGroup
const ModsInfo = ({ title, modelData }: PropsInfoForm) => {
  const { 
    // CondKeyQuantity,
    // CondInterior,
    // CondExterior,
    // CondTire,
    
    // CondAccidentHadAccident,
    // CondAccidentRepaired,
    // CondAccidentEstimate,
    // CondAccidentRepairedCost,
    // CondAccidentEstimatedCost,
    
    CondModsEngine,
    CondModsExhaustEmissions,
    CondModsRaisedSuspension,
    CondModsLoweredSuspension,
    
    // CondElectricalRadio,
    // CondElectricalDvdBluRay,
    // CondElectricalNavigation,
    // CondElectricalSunroofMoonroof,
    
    // CondMechEngine,
    // CondMechTransmission,
    // CondMechAircon,
    // CondMechHeater,
    // CondMechPowerLiftgate,
    // CondMechPowerSlidingdoors,
  } = modelData
  // const mapComboToData = () =>

  return (
    <>
      <FieldsSection header={title}>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputLabel label={'Engine'} value={CondModsEngine? CondModsEngine.toString() :'False'}/>
            <InputLabel label={'Exhaust Emissions'} value={CondModsExhaustEmissions? CondModsExhaustEmissions.toString() :'False'}/>
            <InputLabel label={'Raised Suspension'} value={CondModsRaisedSuspension? CondModsRaisedSuspension.toString() :'False'}/>
            <InputLabel label={'Lowered Suspension'} value={CondModsLoweredSuspension? CondModsLoweredSuspension.toString() :'False'}/>
          </CCol>
        </CFormGroup>

      </FieldsSection>
    </>
  )
}

export default ModsInfo