
const CardCustomer = ({
  firstname = '', 
  lastname = '', 
  followupdate = '', 
  // city = '', 
  // state = '', 
  // zip = '', 
  vehicle = '',
  // status = '', 
  phonemobile = ''
}) => {
  const fullName = firstname + ' ' + lastname

  const reduceWord = (word, len, dep=0) => {
    if(dep>15) len-=5
    if(word.length > len){
      return word.slice(0,len) + '...'
    }
    return word
  }

  return (
    <div className="card-customer-container">
      <div className="card-customer-name">{reduceWord(fullName,15)}</div>
      <div className="card-customer-followupdate">{followupdate}</div>
      {/* <div className="card-customer-city">{city}{city && ','} {state} {zip}</div> */}
      <div className="card-customer-vehicle">{reduceWord(vehicle,20)}</div>
      {/* <div className="card-customer-status">{status}</div> */}
      <div className="card-customer-phonemobile">{phonemobile}</div>
    </div>
  )
}

export default CardCustomer