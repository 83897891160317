import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import PeopleIcon from 'src/assets/icons/PeopleIcon'
import ChartBarSimple from 'src/shared/components/charts/ChartBarSimple'
import ChartLineSimple from 'src/shared/components/charts/ChartLineSimple'
import WidgetDropdown from 'src/shared/components/widgets/WidgetDropdown'
import CardCustomer from './CardCustomer'
import MainChart from './MainChart'

export const DefaultDashboard = ({
  cardData, 
  currencyFormat,
  leadsData,
  routeChangeLead,
  customersData,
  routeChangeCustomer
}) => {
  return (

    <div style={{paddingBottom:'80px'}}>
      <CRow style={{padding:10}}>
        {/* <CCol sm='6' lg='3'>
          <KPIWidget
            data={{ membersToday }}
            color='gradient-primary'
            colorName='primary'
            header='9,823'
            text='Members online'
            label='Members'
            labels='months'
            dataText='Today'
            dataLabel={`${membersToday}%`}
          />
        </CCol> */}
        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-primary'
            header={cardData.Dispatches}
            text='DISPATCHES'
            footerSlot={
              <ChartLineSimple
                pointed
                className='c-chart-wrapper mt-3 mx-3'
                style={{ height: '70px' }}
                dataPoints={[65, 59, 84, 84, 51, 55, 40]}
                pointHoverBackgroundColor='primary'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/* <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-info'
            header={cardData.Inspectors}
            text='INSPECTORS'
            footerSlot={
              <ChartLineSimple
                pointed
                className='mt-3 mx-3'
                style={{ height: '70px' }}
                dataPoints={[1, 18, 9, 17, 34, 22, 11]}
                pointHoverBackgroundColor='info'
                options={{ elements: { line: { tension: 0.00001 } } }}
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/*  <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday2 >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday2}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday2 >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-warning'
            header={currencyFormat.format(cardData.Revenue)}
            text='REVENUE $'
            footerSlot={
              <ChartLineSimple
                className='mt-3'
                style={{ height: '70px' }}
                backgroundColor='rgba(255,255,255,.2)'
                dataPoints={[78, 81, 80, 45, 34, 12, 40]}
                options={{ elements: { line: { borderWidth: 2.5 } } }}
                pointHoverBackgroundColor='warning'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/*  <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday3 >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday3}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday3 >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-danger'
            header={cardData.Inspections}
            text='INSPECTIONS'
            footerSlot={
              <ChartBarSimple
                className='mt-3 mx-3'
                style={{ height: '70px' }}
                backgroundColor='rgb(250, 152, 152)'
                label='Members'
                labels='months'
              />
            }
            icon={<PeopleIcon />}
          >
            {/* <CRow>
              <CCol>
                <h5 className={`text-right text-${membersToday4 >= 0 ? 'success1' : 'danger1'}`}>
                  {membersToday4}%
                </h5>
                <h6
                  className={`text-right mb-0 text-${membersToday4 >= 0 ? 'success1' : 'danger1'}`}
                >
                  Today
                </h6>
              </CCol>
            </CRow> */}
          </WidgetDropdown>
        </CCol>
      </CRow>
      <CRow style={{marginTop: '-25px'}}>
        <CCol md='7' className='pr-lg-0 pl-lg-3'>
          <CCard style={{margin:10}}>
            <CCardBody>
              <CRow>
                <CCol sm='5'>
                  <h5 id='traffic' className='card-title mb-0 '>
                    No. of Dispatches vs. No. of Inspections
                  </h5>
                  {/* <div className='small text-muted'>{getDateLabel()}</div> */}
                </CCol>
              </CRow>
              <MainChart style={{ height: '300px', marginTop: '20px' }} />
              <CRow>
                <CCol sm='5'>
                  <h5 id='traffic' className='card-title mt-4 '>
                    No. of Dispatches vs. No. of Inspections
                  </h5>
                  {/* <div className='small text-muted'>{getDateLabel()}</div> */}
                </CCol>
              </CRow>
              <MainChart style={{ height: '300px', marginTop: '20px' }} />

            </CCardBody>
          </CCard>
        </CCol>
        <CCol className='pr-lg-0 pl-lg-0'>
          <CCard style={{margin:10}}>
            <CCardBody>
              <h5>Leads</h5>
              { leadsData.map( (value) =>
              <div key={value['Id']} onClick={()=>routeChangeLead(value['Id'])}>
                <CardCustomer 
                  key={value['id']}
                  firstname={value['CustFirstName']}
                  lastname={value['CustLastName']}
                  followupdate={value['FollowUpDate']} 
                  // zip={value['CustAddressZip']} 
                  vehicle={value['Vehicle']} 
                  // status={value['Status']} 
                  phonemobile={value['CustPhoneMobile']}
                />
              </div> 
              )}
            </CCardBody>
          </CCard>
        </CCol>
        <CCol className='pr-lg-3 pl-lg-0'>
          <CCard style={{margin:10}}>
            <CCardBody>
              <h5>Customers</h5>
              { customersData.map( (value) =>
              <div key={value['Id']} onClick={()=>routeChangeCustomer(value['Id'])}>
                <CardCustomer 
                  key={value['id']}
                  firstname={value['FirstName']}
                  lastname={value['LastName']}
                  followupdate={value['FollowUpDate']} 
                  // zip={value['Zip']} 
                  vehicle={value['Vehicle']} 
                  // status={value['Status']} 
                  phonemobile={value['PhoneMobile']}
                />
              </div> 
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      {/* <CRow>
        <CCol sm='6' md='2'>
          <CWidgetProgressIcon header='87,500' text='Visitors' color='gradient-info'>
            <CIcon name='cil-people' height='36' />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm='6' md='2'>
          <CWidgetProgressIcon header='385' text='New Clients' color='gradient-success'>
            <CIcon name='cil-userFollow' height='36' />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm='6' md='2'>
          <CWidgetProgressIcon header='1238' text='Products sold' color='gradient-warning'>
            <CIcon name='cil-basket' height='36' />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm='6' md='2'>
          <CWidgetProgressIcon header='28%' text='Returning Visitors' color='gradient-primary'>
            <CIcon name='cil-chartPie' height='36' />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm='6' md='2'>
          <CWidgetProgressIcon header='5:34:11' text='Avg. Time' color='gradient-danger'>
            <CIcon name='cil-speedometer' height='36' />
          </CWidgetProgressIcon>
        </CCol>
        <CCol sm='6' md='2'>
          <CWidgetProgressIcon header='972' text='comments' color='gradient-info'>
            <CIcon name='cil-speech' height='36' />
          </CWidgetProgressIcon>
        </CCol>
      </CRow> */}
      
    </div>  )
}
