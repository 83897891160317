// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const VehicleConditionsInfo = ({
  modelData,
  title = 'Model Name',
}: PropsInfoForm) => {
  const {
    KeyQuantity,
    InteriorCondition,
    ExteriorCondition,
    TireCondition,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            label='Key Quantity'
            value={KeyQuantity ?? '⠀'}
            // alignment='right'
          />
          <InputLabel 
            label='Interior Condition'
            value={InteriorCondition ?? '⠀'}
            // alignment='right'
          />
          <InputLabel 
            label='Exterior Condition'
            value={ExteriorCondition ?? '⠀'}
            // alignment='right'
          />
          <InputLabel 
            label='Tire Condition'
            value={TireCondition ?? '⠀'}
            // alignment='right'
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default VehicleConditionsInfo
