import { CCol, CFormGroup } from '@coreui/react'
import { RadioGroup, Radio } from 'react-radio-input'
import { ComboBox, FieldsSection, InputField } from 'src/shared'
import healthHomes from './Health.json';
import chedckedLists from './checked.json';
import partnerLists from './partner.json';
import reaceLists from './Race.json';
// import Address from './Address'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange: any
  handleCheck?: any,
  title?: string
}
const RadioGroupTs: any = RadioGroup
const JemcareInfo = ({ modelData, handleChange, handleCheck }: PropsInfoForm) => {
  const {
    FirstName,
    LastName,
    DateOfBirth,
    MedicAidNumber,
    Gender,
    LanguagePreference,
    HomePhone,
    CellPhone,
    Address,
    AptNo,
    City,
    State,
    ZipCode,
    IsMentalHealthCondition,
    IsHeartDiseaseCondition,
    IsPrivateResidence,
    IsHomeless,
    IsMHSupportive,
    IsEmergencyHousing,
    OtherHousing,
    IsOtherHousing,
    IsSeriousEmotional,
    IsHivAids,
    IsRecentRelease,
    IsLackofSocialSupports,
    IsNeedsAssistance,
    IsUnableToAccess,
    IsMedications,
    IsVictim,
    IsPCPOrSpecialist,
    IsDifficultyNavigating,
    IsDeficitsLifestyle,
    IsAsthmaCondition,
    IsSubstanceCondition,
    IsDiabetesCondition,
    IsBMICondition,
    IsOtherCondition,
    riskFactorHistory,
    Email,
    IsTreatmentsOrMedications,
    IsMembersParent,
    MedicaidEligibility,
    IsDeficitsWithDailyLiving,
    IsHarp,
    CreatedDate
  } = modelData

  const mapComboToData = () =>
    healthHomes.map((route) => {
      return {
        CodeId: route.value,
        Description: route.label,
      }
    })

  // const handleComboChange = (e: any, newValue: any = {}) => {
  //   if (!e.target) return
  //   handleChange({name: 'IsAnyCondition', value: newValue.CodeId});
  // }

  const mapComboCheckData = () =>
    chedckedLists.map((route) => {
      return {
        CodeId: route.value,
        Description: route.label,
      }
    })

  const handleComboChangeCheck = (e: any, newValue: any = {}) => {
    if (!e.target) return
    handleChange({ name: 'SeriousListCondition', value: JSON.stringify(newValue) });
  }

  const partnerData = () =>
    partnerLists.map((route) => {
      return {
        CodeId: route.value,
        Description: route.label,
      }
    })

  const raceData = () =>
    reaceLists.map((route) => {
      return {
        CodeId: route.value,
        Description: route.label,
      }
    })

  const handleAddMoreChange = (event: any, index: number, name: string, action: string) => {
    let copyData: any = modelData;
    if (action == 'add') {
      copyData.ManualParticipatPartners.push({ name: '' });
      handleChange({ name: name, value: JSON.stringify(copyData.ManualParticipatPartners) });
    } else if (action == 'remove') {
      copyData.ManualParticipatPartners.splice(index, 1);
      handleChange({ name: name, value: JSON.stringify(copyData.ManualParticipatPartners) });
    } else {
      copyData.ManualParticipatPartners[index].name = event.target.value;
      handleChange({ name: name, value: JSON.stringify(copyData.ManualParticipatPartners) });
    }
  }

  const handlePartnerComboChange = (e: any, newValue: any = {}) => {
    console.log(newValue)
    if (!e.target) return
    handleChange({ name: 'ExistingParticipatPartners', value: JSON.stringify(newValue) });
  }

  const handleRaceComboChange = (e: any, newValue: any = {}) => {
    if (!e.target) return
    handleChange({ name: 'Race', value: JSON.stringify(newValue) });
  }

  const handleAnyComboChange = (e: any, newValue: any = {}) => {
    if (!e.target) return
    handleChange({ name: 'IsAnyCondition', value: JSON.stringify(newValue) });
  }

  return (
    <>
      <FieldsSection header="General Information">
        <CFormGroup row>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='FirstName'
              type='text'
              label='First Name'
              value={FirstName ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='LastName'
              type='text'
              label='Last Name'
              value={LastName ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='DateOfBirth'
              type='text'
              label='DOB'
              value={DateOfBirth ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='CreatedDate'
              type='text'
              label='Registered Date'
              value={CreatedDate ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='MedicAidNumber'
              type='text'
              label='Medic Aid ID Number'
              value={MedicAidNumber ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='9' className='pr-lg-2 pl-lg-2 mb-3'>
            <div className='radio-group-label'>
              <b>Medicaid Eligibility</b>
              <RadioGroupTs
                // label='Cost'
                name='MedicaidEligibility'
                onChange={(value) => {
                  handleChange({ name: 'MedicaidEligibility', value })
                }}
                className='radio-group-ts custom-radio customer-custom-radio'
                selectedValue={MedicaidEligibility ?? ''}
              >
                <label className='radio-group-ts-label' htmlFor='MedicaidFFS'>
                  <Radio id='MedicaidFFS' value='Medicaid FFS' />
                  <div className='radio-group-ts-value'>
                    Medicaid FFS
                  </div>
                </label>
                <label className='radio-group-ts-label' htmlFor='MedicaidLTC'>
                  <Radio id='MedicaidLTC' value='Medicaid Managed Care / Managed LTC' />
                  <div className='radio-group-ts-value'>
                    Medicaid Managed Care / Managed LTC
                  </div>
                </label>
              </RadioGroupTs>
            </div>
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-3'>
            <div className='radio-group-label'>
              <b>Harp</b>
              <RadioGroupTs
                // label='Cost'
                name='IsHarp'
                onChange={(value) => {
                  handleChange({ name: 'IsHarp', value })
                }}
                className='radio-group-ts custom-radio customer-custom-radio'
                selectedValue={(IsHarp === true) ? 'true' : 'false'}
              >
                <label className='radio-group-ts-label' htmlFor='HarpYes'>
                  <Radio id='HarpYes' value='true' />
                  <div className='radio-group-ts-value'>
                    Yes
                  </div>
                </label>
                <label className='radio-group-ts-label' htmlFor='HarpNo'>
                  <Radio id='HarpNo' value='false' />
                  <div className='radio-group-ts-value'>
                    No
                  </div>
                </label>
              </RadioGroupTs>
            </div>
          </CCol>
          <CCol md='9' className='pr-lg-2 pl-lg-2 mb-3'>
            <ComboBox
              showButton
              multiple
              name='Race'
              label='Race'
              data={raceData()}
              value={modelData.Race ?? []}
              handleChange={handleRaceComboChange}
              classNames={{ input: 'input-field' }}
              customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled custom-multi' }}
              renderOption={(option: any, { selected }: any) => (
                <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
              )}
            />
          </CCol>
          <CCol md='6' className='pr-lg-2 pl-lg-2 mb-3'>
            <div className='radio-group-label'>
              <b>Are you enrolled in other Care Management Program?</b>
              <RadioGroupTs
                // label='Cost'
                name='IsMembersParent'
                onChange={(value) => {
                  handleChange({ name: 'IsMembersParent', value })
                }}
                className='radio-group-ts custom-radio customer-custom-radio'
                selectedValue={IsMembersParent ?? ''}
              >
                <label className='radio-group-ts-label' htmlFor='Yes'>
                  <Radio id='Yes' value='Yes' />
                  <div className='radio-group-ts-value'>
                    Yes
                  </div>
                </label>
                <label className='radio-group-ts-label' htmlFor='No'>
                  <Radio id='No' value='No' />
                  <div className='radio-group-ts-value'>
                    No
                  </div>
                </label>
                <label className='radio-group-ts-label' htmlFor='Unknown'>
                  <Radio id='Unknown' value='Unknown' />
                  <div className='radio-group-ts-value'>
                    Unknown
                  </div>
                </label>
              </RadioGroupTs>
            </div>
          </CCol>
          <CCol md='6' className='pr-lg-2 pl-lg-2 mb-3'>
            <div className='radio-group-label'>
              <b>Gender</b>
              <RadioGroupTs
                // label='Cost'
                name='Gender'
                onChange={(value) => {
                  handleChange({ name: 'Gender', value })
                }}
                className='radio-group-ts custom-radio customer-custom-radio'
                selectedValue={Gender ?? ''}
              >
                <label className='radio-group-ts-label' htmlFor='Male'>
                  <Radio id='Male' value='Male' />
                  <div className='radio-group-ts-value'>
                    Male
                  </div>
                </label>
                <label className='radio-group-ts-label' htmlFor='Female'>
                  <Radio id='Female' value='Female' />
                  <div className='radio-group-ts-value'>
                    Female
                  </div>
                </label>
                <label className='radio-group-ts-label' htmlFor='other'>
                  <Radio id='other' value='other' />
                  <div className='radio-group-ts-value'>
                    Other
                  </div>
                </label>
              </RadioGroupTs>
            </div>
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='Email'
              type='text'
              label='Email'
              value={Email ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='LanguagePreference'
              type='text'
              label='Language Preference'
              value={LanguagePreference ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='HomePhone'
              type='text'
              label='Home Phone'
              value={HomePhone ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='CellPhone'
              type='text'
              label='Cell Phone'
              value={CellPhone ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='8' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='Address'
              type='text'
              label='Address'
              value={Address ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='4' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='AptNo'
              type='text'
              label='AptNo'
              value={AptNo ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='4' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='City'
              type='text'
              label='City'
              value={City ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='4' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='State'
              type='text'
              label='State'
              value={State ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='4' className='pr-lg-2 pl-lg-2 mb-3'>
            <InputField
              name='ZipCode'
              type='text'
              label='ZipCode'
              value={ZipCode ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
      </FieldsSection>
      <FieldsSection header="Participate Partners">
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2'>
            <ComboBox
              showButton
              multiple
              name='ExistingParticipatPartners'
              label='Participate Partners'
              data={partnerData()}
              value={modelData.ExistingParticipatPartners ?? []}
              handleChange={handlePartnerComboChange}
              classNames={{ input: 'input-field' }}
              customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled custom-multi' }}
              renderOption={(option: any, { selected }: any) => (
                <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
              )}
            />
          </CCol>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-3'>
            {modelData && modelData.ManualParticipatPartners && modelData.ManualParticipatPartners.length > 0 &&
              <div className="row max-container mt-3">
                {
                  modelData.ManualParticipatPartners.map((element: any, key: any) => {
                    return (
                      <div className="col-md-6 mb-2 pos-rel" key={key}>
                        <input
                          type={'text'}
                          className='form-control'
                          name={'name' + key}
                          value={element.name}
                          placeholder={'Add CCMP participating partners'}
                          onChange={(e) => handleAddMoreChange(e, key, 'ManualParticipatPartners', 'update')} />
                        <span className="remove-icon"><i onClick={(e) => handleAddMoreChange(e, key, 'ManualParticipatPartners', 'remove')} className="fa fa-times"></i></span>
                      </div>
                    )
                  })
                }
              </div>
            }
            <div className="row max-container mt-1">
              <div className='col-md-12'>
                <a style={{ color: 'blue', cursor: 'pointer' }} onClick={(e) => handleAddMoreChange(e, 1, 'ManualParticipatPartners', 'add')}>Add more CCMP participating partners manually</a>
              </div>
            </div>
          </CCol>
        </CFormGroup>
      </FieldsSection>
      <FieldsSection header="Type of Residence">

        <div className="row max-container mt-3">
          <div className="col-md-12 mb-3">
            {IsPrivateResidence}
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsPrivateResidence" value={IsPrivateResidence} checked={IsPrivateResidence == true} onChange={handleCheck} id="r1" />
              <label className="form-check-label" htmlFor="r1">Private Residence (alone or with spouse/partner, parent, child, or other family)</label>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsHomeless" checked={(IsHomeless == true)} onChange={handleCheck} id="r2" />
              <label className="form-check-label" htmlFor="r2">Homeless (street, park, drop-in center, or other undomiciled)</label>
            </div>
          </div>
          <div className="col-md-12 mb-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsMHSupportive" checked={(IsMHSupportive == true)} onChange={handleCheck} id="r3" />
              <label className="form-check-label" htmlFor="r3">MH Supportive Housing</label>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsEmergencyHousing" checked={(IsEmergencyHousing == true)} onChange={handleCheck} id="r4" />
              <label className="form-check-label" htmlFor="r4">Homeless Shelter or Emergency Housing</label>
            </div>
          </div>
          <div className="col-md-2 mbs">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsOtherHousing" checked={(IsOtherHousing == true)} onChange={handleCheck} id="r5" />
              <label className="form-check-label" htmlFor="r5">Other</label>
            </div>
          </div>
          <div className="col-md-4 mbs">
            <InputField
              name='OtherHousing'
              type='text'
              label='Other Housing'
              value={OtherHousing ?? ''}
              onChange={handleChange}
            />
          </div>
        </div>

      </FieldsSection>
      <FieldsSection header="Eligibility">
        <CFormGroup row className='mb-0'>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
            Members must have two or more chronic conditions OR one of the single qualifying chronic conditions below. Please check appropriate boxes below.
          </CCol>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1 text-danger'>
            Two or more chronic conditions-Please specify
          </CCol>
        </CFormGroup>
        <div className="row">
          <div className="col-md-4 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsMentalHealthCondition" checked={(IsMentalHealthCondition == true)} onChange={handleCheck} id="IsMentalHealthCondition" />
              <label className="form-check-label" htmlFor="IsMentalHealthCondition">Mental Health Condition</label>
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsAsthmaCondition" checked={(IsAsthmaCondition == true)} onChange={handleCheck} id="inlineCheckbox2" />
              <label className="form-check-label" htmlFor="inlineCheckbox2">Asthma</label>
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" name="IsHeartDiseaseCondition" checked={(IsHeartDiseaseCondition == true)} onChange={handleCheck} type="checkbox" id="inlineCheckbox3" />
              <label className="form-check-label" htmlFor="inlineCheckbox3">Heart Disease</label>
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsSubstanceCondition" checked={(IsSubstanceCondition == true)} onChange={handleCheck} id="inlineCheckbox24" />
              <label className="form-check-label" htmlFor="inlineCheckbox24">Substance Use</label>
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsDiabetesCondition" checked={(IsDiabetesCondition == true)} onChange={handleCheck} id="inlineCheckbox25" />
              <label className="form-check-label" htmlFor="inlineCheckbox25">Diabetes</label>
            </div>
          </div>
          <div className="col-md-4 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsBMICondition" checked={(IsBMICondition == true)} onChange={handleCheck} id="inlineCheckbox26" />
              <label className="form-check-label" htmlFor="inlineCheckbox26">{'BMI > 25'}</label>
            </div>
          </div>
          <div className="col-md-2 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsOtherCondition" checked={(IsOtherCondition == true)} onChange={handleCheck} id="inlineCheckbox27" />
              <label className="form-check-label" htmlFor="inlineCheckbox27">Other</label>
            </div>
          </div>
          <div className="col-md-10 mt-3 mt0">
            <ComboBox
              showButton
              multiple
              name='IsAnyCondition'
              label="Other"
              value={modelData.IsAnyCondition ?? []}
              data={mapComboToData()}
              handleChange={handleAnyComboChange}
              classNames={{ input: 'input-field' }}
              customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled custom-multi' }}
              renderOption={(option: any, { selected }: any) => (
                <div className={selected ? 'combo-selected' : ''}>
                  {` ${option.Description}`}
                </div>
              )}
            />
          </div>
        </div>
        <div>
        </div>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1 mt-3 text-danger'>
            One chronic qualifying condition-Please check
          </CCol>
        </CFormGroup>
        <div className="row">
          <div className="col-md-6">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsHivAids" checked={(IsHivAids == true)} onChange={handleCheck} id="inlineCheckbox28" />
              <label className="form-check-label" htmlFor="inlineCheckbox28">HIV / AIDS</label>
            </div>
          </div>
          <div className="col-md-6">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsSeriousEmotional" checked={(IsSeriousEmotional == true)} onChange={handleCheck} id="inlineCheckbox29" />
              <label className="form-check-label" htmlFor="inlineCheckbox29">Serious Mental Illness</label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <ComboBox
              showButton
              multiple
              name='SeriousListCondition'
              label='Other Condition'
              data={mapComboCheckData()}
              value={modelData.SeriousListCondition ?? []}
              handleChange={handleComboChangeCheck}
              classNames={{ input: 'input-field' }}
              customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled custom-multi' }}
              renderOption={(option: any, { selected }: any) => (
                <div className={selected ? 'combo-selected' : ''}>
                  {` ${option.Description}`}
                </div>
              )}
            />
          </div>
        </div>
        <CFormGroup row className='mb-0'>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1 mt-3 text-danger'>
            Appropriateness/Functional Need
          </CCol>
        </CFormGroup>
        <div className="row max-container">
          <div className="col-md-12 mt-3">
            In addition to diagnositic criteria, members must be appropriate for Health Home services, by having one or more significant risk factors.
          </div>
          <div className="col-md-12 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsRecentRelease" checked={(IsRecentRelease == true)} onChange={handleCheck} id="inlineCheckb11" />
              <label className="form-check-label" htmlFor="inlineCheckb11">Recent release from medical, psych, or detox admission, or incarceration (Last 90 days)</label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsLackofSocialSupports" checked={(IsLackofSocialSupports == true)} onChange={handleCheck} id="IsLackofSocialSupports" />
              <label className="form-check-label" htmlFor="IsLackofSocialSupports">Lack of social supports (fewer than 2 people identified as a support by the member) </label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsNeedsAssistance" checked={(IsNeedsAssistance == true)} onChange={handleCheck} id="inlineCheckbo22" />
              <label className="form-check-label" htmlFor="inlineCheckbo22">Needs assistance applying for/accessing benefits such as SNAP, SSI, etc.</label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsUnableToAccess" checked={(IsUnableToAccess == true)} onChange={handleCheck} id="inlineCheckbo33" />
              <label className="form-check-label" htmlFor="inlineCheckbo33">Unable to access food due to financial limitations,inability to shop, dietary restrictions, etc.</label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsMedications" checked={(IsMedications == true)} onChange={handleCheck} id="inlineCheckbo4" />
              <label className="form-check-label" htmlFor="inlineCheckbo4">Does not adhere to treatments or has difficulty managing medications</label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsVictim" checked={(IsVictim == true)} onChange={handleCheck} id="inlineCheckbo45" />
              <label className="form-check-label" htmlFor="inlineCheckbo45">Victim of domestic violence</label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsPCPOrSpecialist" checked={(IsPCPOrSpecialist == true)} onChange={handleCheck} id="inlineCheckbo453" />
              <label className="form-check-label" htmlFor="inlineCheckbo453">Does not have a PCP or specialist or has not seen their provider in the last year</label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsDifficultyNavigating" checked={(IsDifficultyNavigating == true)} onChange={handleCheck} id="inlineCheckbo234" />
              <label className="form-check-label" htmlFor="inlineCheckbo234">Difficulty navigating system due to a physical or behavioral health condition</label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsTreatmentsOrMedications" checked={(IsTreatmentsOrMedications == true)} onChange={handleCheck} id="inlineCheckbo1234" />
              <label className="form-check-label" htmlFor="inlineCheckbo1234">Not adherent/difficulty managing treatments or medications</label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsDeficitsWithDailyLiving" checked={(IsDeficitsWithDailyLiving == true)} onChange={handleCheck} id="IsDeficitsWithDailyLiving" />
              <label className="form-check-label" htmlFor="IsDeficitsWithDailyLiving">Deficits with daily living (transportation, managing finances, hygiene, etc.). Explain:</label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <div className="checkbox-border">
              <input className="form-check-input" type="checkbox" name="IsDeficitsLifestyle" checked={(IsDeficitsLifestyle == true)} onChange={handleCheck} id="inlineCheckbox2324" />
              <label className="form-check-label" htmlFor="inlineCheckbox2324">Deficits related to lifestyle, illness, or treatment (medication side effects, home environment, isolation, cognitive or mental decline (e.g., dementia), aging, hospitalization, etc.) Explain:</label>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <InputField
              name='riskFactorHistory'
              type='text'
              label='Risk Factors/History'
              value={riskFactorHistory ?? ''}
              onChange={handleChange}
            />
          </div>
        </div>
      </FieldsSection>
    </>
  )
}

export default JemcareInfo