import React from 'react'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { RootRepository } from 'src/repository/admin/rootRepository'
// import { routeToPage } from 'src/services/routingHelper'
import { DeleteModal, Loader } from 'src/shared'
import { ServicesHeader, ServicesInfo } from './components'
import { SERVICES_MODEL, ENDPOINTS_MODEL } from 'src/services/routeConst'

const ServiceDetails: React.FC<any> = ({ history, match, modelName = SERVICES_MODEL }) => {
  const [apiData, setApiData] = React.useState<any>({ serviceData: null })
  const [status, setStatus] = React.useState<any>('loading')
  const [showDeleteModal, setShowDeleteModal] = React.useState<any>(false)
  const clickedIDRef = React.useRef<any>(-1)
  const tableRef = React.useRef<any>()
  const changesRef = React.useRef<any>()

  const { id = 0 } = match.params
  const fields = [
    'ServicesId',
    'Name',
    'Description',
    'URL',
    'Enabled',
    'Version',
    'Hits',
    'Errors',
  ]

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(
    async (shouldUpdateRefs = true) => {
      try {
        const { mainData }: any = await rootRepository.getData({
          id,
          modelName,
          defaultMainData: { ServicesId: 0 },
        })
        setApiData({ serviceData: mainData })
        setStatus('idle')
        id && shouldUpdateRefs && updateRefs()
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [id, modelName, rootRepository],
  )

  const updateRefs = () => {
    tableRef.current.fetchNewData()
    changesRef.current.fetchNewData()
  }

  React.useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ serviceData: null })
      history.goBack()
    }, delay)
  }

  const saveData = async () => {
    const dataToSave: any = apiData.serviceData
    setStatus('saving')
    try {
      await rootRepository.saveData({
        modelName,
        idName: 'ServicesId',
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        getData,
        goBack,
      })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setApiData((st: any) => ({ ...st, serviceData: { ...st.serviceData, [name]: value } }))
  }

  const handleSwitchChange = (name: any, value: any) => {
    setApiData((st: any) => ({
      ...st,
      serviceData: {
        ...st.serviceData,
        [name]: [1, true, 'True'].includes(value) ? 1 : 0,
      },
    }))
  }
  /* 
  const viewDetails = ({ id: viewID }: any) => {
    setApiData({ serviceData: null })
    routeToPage(history, `/Micro/Endpoints/${viewID ? `Detail/${viewID}` : `New`}`, {
      ServicesId: id,
    })
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  } */

  /* const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  } */

  if (!apiData.serviceData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { serviceData } = apiData
  return (
    <>
      <CCard>
        <ServicesHeader
          modelName={modelName}
          status={status}
          saveData={saveData}
          onCancel={history.goBack}
        />
        <CCardBody>
          <CRow>
            <CCol xs='12' md='6'>
              <ServicesInfo
                serviceData={serviceData}
                handleChange={handleChange}
                handleSwitchChange={handleSwitchChange}
              ></ServicesInfo>
            </CCol>
            {/* {id ? (
              <CCol xs='12' md='6'>
                <ServicesDetailTabSection
                  id={id}
                  Guid={serviceData.Guid}
                  modelName={modelName}
                  actionFunctions={actionFunctions}
                  tableRef={tableRef}
                  changesRef={changesRef}
                  viewDetails={viewDetails}
                />
              </CCol>
            ) : null} */}
          </CRow>
        </CCardBody>
      </CCard>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName: ENDPOINTS_MODEL, label: 'Endpoint', id: clickedIDRef.current }}
        callBack={getData}
      />
    </>
  )
}

export default ServiceDetails
