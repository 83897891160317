import { CCard, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react'
import React from 'react'

interface PropsMemberCard {
  background?: string
  style?: React.CSSProperties
  select?: boolean
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void
}
const CardMoney: React.FC<PropsMemberCard> = ({
  background = 'linear-gradient(360deg, #A56F70 -16.09%, #C3989A 33.43%, #F2CFCF 98.74%)',
  style = { width: '250px', height: '150px' },
  select = false,
  onClick,
}) => {
  const formatCardNumber = (cardNumber: any = '') => {
    return cardNumber.length
      ? cardNumber.match(new RegExp('.{1,4}', 'g')).join('-')
      : 'XXXX XXXX XXXX XXXX'
  }
  const selectStyle = select
    ? {
        border: 'solid 4.5px',
        borderRadius: '1rem',
        borderColor: '#4799EB',
      }
    : {}
  return (
    <div style={{ margin: '0.3rem', display: 'inline-block' }} onClick={onClick}>
      <CCard
        style={{
          padding: 0,
          boxShadow: `0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)`,
          marginBottom: 0,
          marginLeft: 0,
          ...selectStyle,
          ...style,
        }}
      >
        <CCardHeader
          style={{
            borderTopLeftRadius: '1em',
            borderTopRightRadius: '1em',
            background: background,
            padding: '0.5rem 1rem',
          }}
        >
          <CRow style={{ alignItems: 'center' }}>
            <CCol>
              {/* {card.CardId} */}
              <small style={{ marginLeft: '0.5rem' }}>{'N/A'}</small>
            </CCol>
          </CRow>
        </CCardHeader>

        <CCardBody
          style={{
            borderBottomLeftRadius: '1em',
            borderBottomRightRadius: '1em',
            background: background,
          }}
        >
          <CRow>
            <CCol xs='12'>
              <h4
                className='d-none d-lg-block text-center'
                style={{ letterSpacing: '0.2rem', fontSize: '0.7rem' }}
              >
                {formatCardNumber(1)}
              </h4>
              <h5 className='d-lg-none d-block text-center' style={{ letterSpacing: '0.4rem' }}>
                {formatCardNumber(1)}
              </h5>
            </CCol>
            <CCol xs='12'>
              <CRow
                style={{ justifyContent: 'space-between', padding: '0 1rem', marginTop: '20px' }}
              >
                <div>
                  <h6 style={{ marginBottom: 0, fontSize: '0.7rem' }}>XXXXXX</h6>
                  <h6>XX/XX</h6>
                </div>
                <h6 style={{ marginBottom: 0, fontSize: '0.7rem' }}>{'N/A'}</h6>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  )
}

export default CardMoney
