// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { ComboBox, FieldsSection, InputField } from 'src/shared'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  modelName: string
  conectionData: any
}

const InfoForm = ({
  modelData,
  handleChange,
  modelName = 'Model Name',
  conectionData,
}: PropsInfoForm) => {
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g)!.join(' ')
  const { SchemaName, DatabaseName, Description } = modelData
  return (
    <FieldsSection header={`${title} Info`}>
      <CFormGroup row>
        <CCol>
          <InputField
            name='SchemaName'
            label='Schema Name'
            value={SchemaName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <InputField
            name='DatabaseName'
            type='text'
            label='DatabaseName'
            value={DatabaseName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol xs='12'>
          <ComboBox
            showButton
            name='ConnectionsId'
            label='Conections'
            data={conectionData}
            value={conectionData.find((e: any) => e.CodeId === modelData['ConnectionsId'])}
            handleChange={(e: any, newValue: any = {}) => {
              handleChange({ name: 'ConnectionsId', value: newValue.CodeId, e: e })
            }}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
            )}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <InputField
            name='Description'
            type='textarea'
            label='Description'
            value={Description}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default InfoForm
