import { useCallback, useEffect, useMemo, useState } from 'react'
import { StatusInfo } from '../enum/Status'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'

interface PropsActionsModels {
  id: any
  modelName: any
  // history: any
  modelsId?: string
}
type ApiDataState = { modelData: any }
const fields: string[] = [
  'Id',
  'InspectorsId',
  'CustomersId',
  'ServicesId',
  'Date',
  'Name',
  'Description',
  'Cost',
  'Price',
]
export const useActionsEdit = ({
  id,
  modelName,
  modelsId = 'Id',
  // history,
}: PropsActionsModels): [ApiDataState, StatusInfo, () => Promise<void>, Function, Function, Function] => {
  const [apiDataEdit, setApiDataEdit] = useState<ApiDataState>({ modelData: null })
  const [statusEdit, setStatusEdit] = useState<StatusInfo>(StatusInfo.loading)
  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback<any>(async () => {
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [modelsId]: 0 },
      })
      const dataResponse = mainData?.data ? mainData.data[0] : mainData
      setApiDataEdit({ modelData: dataResponse })
      setStatusEdit(StatusInfo.idle)
    } catch (error) {
      setStatusEdit(StatusInfo.idle)
      console.error(error)
    }
  }, [rootRepository, id, modelName, modelsId])

  useEffect(() => {
    getData()
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiDataEdit({ modelData: null })
      // history.goBack()
    }, delay)
  }
  const mappingFields = (apiDataEdit) => {
    const dataToSave: any = apiDataEdit.modelData
    return {
      ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
    }
  }
  const saveDataEdit = async () => {
    setStatusEdit(StatusInfo.saving)
    try {
      const { message } = await SaveModel({
        body: {
          Model: modelName,
          Params: mappingFields(apiDataEdit),
        },
      })
      toast.success(message)
      getData()
      goBack()
      setStatusEdit(StatusInfo.idle)
    } catch (error) {
      setStatusEdit(StatusInfo.idle)
      console.error(error)
    }
  }

  const handleChangeEdit = ({ name = '', value = '' }) => {
    name &&
      setApiDataEdit((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }
  const handleReset = (data) => {
    console.log(data);
    
  }

  const handleAddressChange = ({
    Address = '',
    Address2 = '',
    City = '',
    State = '',
    ZipCode = '',
  }) => {
    // console.log({ Address, Address2, City, State, ZipCode })
    setApiDataEdit((st: any) => ({
      ...st,
      modelData: { ...st.modelData, Address, Address2, City, State, ZipCode },
    }))
  }
  return [apiDataEdit, statusEdit, saveDataEdit, handleChangeEdit, handleAddressChange, handleReset]
}
