// import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, SERVICES, WIDGETS } from 'src/services/routeConst'
const mapToBase64 = async (res) => {
  const blob = await res.blob()
  let result_base64 = await new Promise((resolve) => {
    let reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onload = () => resolve(reader.result)
  })
  return result_base64
}
// TODO: MODIFY THIS FUNCTION TO USE THE NEW API
export const DownloadFile = async ({ guid }: { guid: any }) => {
  //URL {api}/Widgets/Files/Download/:id
  try {
    const res = await fetch(`${BASE_API}${SERVICES}${WIDGETS}/Files/Download/${guid}`, {
      ...addAuthToHeader('ADMIN'),
      method: 'GET',
    })
    //console.log(res)
    if (!res.ok) {
      return {
        data: null,
        status: 'error',
        message: res.statusText,
      }
    }
    const resultBase64 = await mapToBase64(res)
    return {
      data: resultBase64,
      status: 'success',
      message: res.statusText,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error fetching Image with ID ${guid}!`,
      error: error.response,
    })
  }
}
