import { CCard, CCardBody } from '@coreui/react'

const Users = ({ propertyData = [] }) => {
  return (
    <>
      { propertyData.map((f:any, index: any) => {
        return <CCard key={index}>
          <CCardBody>
          <div style={{ color: 'dark !important' }}>
            <div>
              <b> Name:</b> {f.FirstName} {f.LastName}
            </div>
            <div>
              <b> Phone:</b> {f.Phone}
            </div>
          </div>
          </CCardBody>
        </CCard>
        })
      }
    </>
  )
}
export default Users
