import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, LIST, MODELS, SERVICES } from 'src/services/routeConst'
import { parseNulls } from 'src/services/util'
interface Params {
  Name?: any
  Value?: any
}
interface RequestBody {
  Model: string
  Extension: any
  Params: Object
}
interface Props {
  body: Partial<RequestBody>
}

const mapRequestData = (idName: string = 'Id', saveData: Object): Params[] => {
  let dataToSave: Params[] = []
  Object.keys(saveData).forEach((e) => {
    if (e === idName) {
      return
    }
    dataToSave.push({
      Name: e,
      Value: saveData[e],
    })
  })
  return dataToSave
}
export const ListModel = async ({ body }: Props) => {
  //URL {api}/Models/List/{ModelName}
  const { Model } = body
  const Params = mapRequestData('Id', body?.Params ?? {})
  try {
    const { data } = await axios.post(
      `${BASE_API}${SERVICES}${MODELS}${LIST}`,
      { ...body, Params: Params, Extension: body?.Extension ?? null },
      {
        headers: {
          Authorization: `Bearer ${getAuthToken('ADMIN')}`,
          'Content-Type': 'application/json',
        },
      },
    )
    // console.log(data)
    return {
      data: parseNulls([...data.data]),
      status: 'success',
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error getting ${Model} list!`,
      error: error.response,
    })
  }
}
