import React from 'react'
import { CTabPane } from '@coreui/react'

// import { FieldsSection } from 'src/shared'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
interface PropsScenariosDetailTabSection {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  Guid?: any
}
const ScenariosDetailTabSection: React.FC<PropsScenariosDetailTabSection> = ({
  id,
  modelName,
  changesRef,
  Guid,
}) => {
  return (
    <DetailTabSection
      id={id}
      Guio={Guid}
      modelName={modelName}
      /* tabs={[{ name: 'DataTable', label: 'Test Results' }]} */
      changesRef={changesRef}
    >
      <CTabPane data-tab='DataTable' className='detail-tab-section-pane' />
    </DetailTabSection>
  )
}

export default ScenariosDetailTabSection
