import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from '../../../../shared'
// import AddressFinderAux from '../../../../shared/inputFields/AddressFinderAux'

interface PropsCustomerVehicleInfo {
  id?: any
  allMembersData?: any
  memberData?: any
  handleChange?: any
  handleComboChange?: any
  handlePhoneChange?: any
  showVerifyOTPModalFunc?: any
  sendingOTP?: any
  showVerifyPhoneModal?: any
  showVerifyEmailModal?: any
  handleAddressChange?: any
}
const CustomerVehicleInfo: React.FC<PropsCustomerVehicleInfo> = ({
  id,
  /* allMembersData, */
  memberData,
  handleChange,
  // handleAddressChange,
  /* handleComboChange, */
  // handlePhoneChange,
  /* showVerifyOTPModalFunc,
  sendingOTP,
  showVerifyPhoneModal, */
  // showVerifyEmailModal,
}) => {
  // const btnRef = React.useRef<any>()
  const { ASP, VIN, Miles, Year, Make, Model } = memberData

  // const renderPhoneButton = () => {
  //   const unClickable = sendingOTP === 'Phone' || showVerifyPhoneModal
  //   return (
  //     <small
  //       ref={btnRef}
  //       className={`input-field-button ${unClickable ? 'text-muted' : ''}`}
  //       onClick={() => {
  //         !unClickable && showVerifyOTPModalFunc({ type: 'Phone', sendTo: Phone })
  //       }}
  //     >
  //       Verify
  //     </small>
  //   )
  // }

  // const renderEmailButton = () => {
  //   const unClickable = sendingOTP === 'Email' || showVerifyEmailModal
  //   return (
  //     <small
  //       ref={btnRef}
  //       className={`input-field-button ${unClickable ? 'text-muted' : ''}`}
  //       onClick={() => {
  //         !unClickable && showVerifyOTPModalFunc({ type: 'Email', sendTo: Email })
  //       }}
  //     >
  //       Verify
  //     </small>
  //   )
  // }

  // const { Address, Address2, State, City, ZipCode } = memberData
  // const handleAddressDataChange = ({ value }: any) => {
  //   handleAddressChange(value)
  // }

  return (
    <FieldsSection header='Vehicle Information'>
      {/* <CFormGroup row>
        <CCol xs='12' sm='4'>
          <InputField
            name='MembersId'
            label='Member ID'
            type='number'
            value={MembersId}
            disabled
          />
        </CCol>
      </CFormGroup> */}
      <CFormGroup row>
        <CCol xs='12' lg='6' className='pr-lg-0 mb-3 my-lg-0'>
          <InputField
            name='ASP'
            type='text'
            label='ASP C/R Scorecard'
            value={ASP}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='6'>
          <InputField name='VIN' type='text' label='VIN' value={VIN} onChange={handleChange} />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='3' className='pr-lg-0 mb-3 my-lg-0'>
          <InputField
            name='Miles'
            type='text'
            label='Miles'
            value={Miles}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3' className='pr-lg-0 mb-3 my-lg-0'>
          <InputField name='Year' type='text' label='Year' value={Year} onChange={handleChange} />
        </CCol>
        <CCol xs='12' lg='3' className='pr-lg-0 mb-3 my-lg-0'>
          <InputField name='Make' type='text' label='Make' value={Make} onChange={handleChange} />
        </CCol>
        <CCol xs='12' lg='3'>
          <InputField
            name='Model'
            type='text'
            label='Model'
            value={Model}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      {id ? (
        <>
          {/* <CFormGroup row>
            <CCol xs='12'>
              <ComboBox
                showButton
                name='MembersId_Parent'
                label='Parent Member'
                data={allMembersData}
                value={allMembersData.find(
                  (member: any) => member.CodeId === memberData.MembersId_Parent,
                )}
                handleChange={handleComboChange}
                classNames={{ input: 'input-field' }}
                customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                renderOption={(option: any, { selected }: any) => (
                  <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
                )}
              />
            </CCol>
          </CFormGroup> */}
          {/* <CFormGroup row>
            <CCol xs='12'>
              <InputField
                name='DeviceId'
                type='text'
                label='Device ID'
                value={DeviceId}
                onChange={handleChange}
                // disabled
              />
            </CCol>
          </CFormGroup> */}
        </>
      ) : null}
    </FieldsSection>
  )
}
export default React.memo(CustomerVehicleInfo)
