import GeneralInfo from './GeneralInfo'
import AccidentInfo from './AccidentInfo'
import ElectricalInfo from './ElectricalInfo'
import MechInfo from './MechInfo'

export { 
  GeneralInfo,
  AccidentInfo,
  ElectricalInfo,
  MechInfo,
}