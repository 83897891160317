import { useState } from 'react'
import ScheduleSelector from 'react-schedule-selector'
export const TimeSlots = () => {
  const [schedule, setSchedule] = useState({ schedule: [] })
  const handleChange = (newSchedule) => {
    setSchedule({ schedule: newSchedule })
  }
  return (
    <div>
      <ScheduleSelector
        selection={schedule.schedule}
        numDays={6}
        minTime={9}
        maxTime={18}
        startDate={new Date(2022, 5, 20)}
        dateFormat='dddd'
        hourlyChunks={1}
        onChange={handleChange}
      />
    </div>
  )
}
