import { CCol, CFormGroup } from '@coreui/react'
// import { RadioGroup, Radio } from 'react-radio-input'
import { FieldsSection, InputField } from 'src/shared'
import Address from './Address'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange: any
  title?: string
}
// const RadioGroupTs: any = RadioGroup
const Info = ({ title, modelData, handleChange }: PropsInfoForm) => {
  const { 
    Name
  } = modelData
  // const mapComboToData = () =>

  return (
    <>
      <FieldsSection header={title}>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='Name'
              type='text'
              label='Name'
              value={Name ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
        
        <Address
          modelData={modelData}
          handleChange={handleChange}
        />

      </FieldsSection>
    </>
  )
}

export default Info