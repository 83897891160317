import React, { useRef } from 'react'
import { CRow, CCol, CFormGroup, CButton, CCard, CCardBody, CCardHeader } from '@coreui/react'
import { ComboBox, SelectMultipleImage } from 'src/shared'
import { RootRepository } from 'src/repository/admin/rootRepository'
import {
  EMAILS_MODEL,
  ALERTS_FIELDS_MODEL,
  TEMPLATES_MODEL,
  PROCS_MODEL,
  ALERTIMAGEDOWNLOAD,
} from 'src/services/routeConst'
import { Loader, InputField } from 'src/shared'
// import LoadingButton from 'src/shared/components/LoadingButton'
// import parse from 'html-react-parser'
import { ComboModel } from 'src/services/modelNew/Combo/Combo'
import ConfirmRedirectModal from 'src/shared/modals/ConfirmRedirectModal'
import LoadingButton from 'src/shared/components/LoadingButton'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'

const fields = ['EmailsId', 'ProcsId', 'Name', 'Body', 'Fields']
enum StatusCreate {
  isNew = 'isNew',
  isCreate = 'isCreate',
}
const parseField = ({ field }: any) => field?.replaceAll('{{', '').replaceAll('}}', '')
interface ApiRespondeData {
  emailData?: any
  fieldsData?: any[]
  templatesData?: any[]
  procData?: any[]
}
const EmailEditor: React.FC<any> = ({ history, match, modelName = EMAILS_MODEL }) => {
  const [status, setStatus] = React.useState('loading')
  const [apiData, setApiData] = React.useState<ApiRespondeData>({
    emailData: null,
    fieldsData: [],
    templatesData: [],
    procData: [],
  })
  const [statusCreate, setStatusCreate] = React.useState<StatusCreate>(StatusCreate.isNew)
  const [show, setShow] = React.useState(false)
  const editorRef = useRef<any>()
  const changesRef = React.useRef<any>()

  const { id = 0 } = match.params

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(
    async (shouldUpdateRefs = true) => {
      try {
        const { data: templatesData } = await ComboModel({ modelName: TEMPLATES_MODEL })
        const { data: ProcData } = await ComboModel({ modelName: PROCS_MODEL })
        const { mainData, subData }: any = await rootRepository.getData({
          id,
          modelName,
          subModelName: ALERTS_FIELDS_MODEL,
          subModelIdName: 'FieldsId',
          defaultMainData: { EmailsId: 0 },
          defaultSubData: { FieldsId: 0 },
          forceSubData: true, //used when creating a new Email and id = 0 but we still need to fetch the subData (fields)
        })
        setStatusCreate(StatusCreate.isCreate)
        if (mainData.EmailsId === 0) {
          setStatusCreate(StatusCreate.isNew)
        }
        // console.log({ mainData, subData })
        setApiData({
          emailData: mainData,
          fieldsData: subData,
          procData: ProcData,
          templatesData,
        })
        setStatus('idle')
        id && shouldUpdateRefs && updateRefs()
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [id, modelName, rootRepository],
  )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateRefs = () => {
    changesRef.current.fetchNewData()
  }
  React.useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      // setApiData({
      //   emailData: null,
      //   fieldsData: [],
      //   templatesData: [],
      // })
      history.goBack()
    }, delay)
  }

  const saveEmail = async () => {
    setStatus('saving')
    try {
      await rootRepository.saveData({
        modelName,
        idName: 'EmailsId',
        dataToSave: {
          ...Object.assign({}, ...fields.map((field) => ({ [field]: apiData.emailData[field] }))),
          Fields: JSON.stringify(
            apiData.emailData.Body.match(/{{(\w+?)}}/g)?.map((field: any) => parseField({ field })),
          ),
        },
        getData,
        goBack,
      })
      if (statusCreate === StatusCreate.isNew) {
        addMetasAndUpdateModel('0')
      }
      if (statusCreate === StatusCreate.isCreate) {
        if (
          typeof apiData.emailData.MetasId === 'undefined' ||
          apiData.emailData.MetasId === null
        ) {
          addMetasAndUpdateModel('0')
        }
      }
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const handleChange = ({ name, value }: any) => {
    setApiData((st: any) => ({ ...st, emailData: { ...st.emailData, [name]: value } }))
  }

  const handleComboChange = (e: any, newValue: any = {}) => {
    if (!e.target) return
    const CodeId = newValue ? newValue.CodeId : null
    setApiData((st: any) => ({ ...st, emailData: { ...st.emailData, ProcsId: CodeId } }))
  }

  /* const autoCompleteMapFromAPi = (data: any) =>
    data.map((item: any) => ({
      id: item.FieldsId,
      name: item.Name,
      description: item.Description,
      value: item.Name,
    })) */

  const parseEmail = () => {
    let parsedEmail = apiData.emailData.Body
    const fieldsFromAPI = apiData?.fieldsData?.map((field: any) => field.Name)
    apiData.emailData.Body?.match(/{{(\w+?)}}/g)?.forEach((field: any) => {
      parsedEmail = parsedEmail.replaceAll(
        field,
        `<strong style="color: ${
          fieldsFromAPI?.includes(parseField({ field }))
            ? '#e1a82d'
            : addFieldToSuggestions({ field })
        };">${field}</strong>`,
      )
    })

    return parsedEmail ?? ''
  }
  // * Return MetasId
  const addMetasAndUpdateModel = async (idModel = '') => {
    //* create meta
    /* const { data: dataMeta } = await SaveModel({
      idName: 'MetasId',
      modelName: 'Metas',
      saveData: {
        MetasId: 0,
        MetaType: 'alertsTemplate',
        MetaCode: idModel,
      },
    })
    // * Update model  of the meta created
    await SaveModel({
      idName: 'EmailsId',
      modelName: 'AlertEmails',
      saveData: {
        EmailsId: idModel,
        MetasId: Number(dataMeta.replace(/^\D+/g, '')),
      },
    }) */
    return Number(idModel.replace(/^\D+/g, ''))
  }

  const addFieldToSuggestions = ({ field }: any) => {
    // console.log('Adding new field to list of Fields: ', field)
    setApiData((st: any) => ({
      ...st,
      fieldsData: [
        ...st.fieldsData,
        {
          FieldsId: st.fieldsData.length + 1,
          Name: parseField({ field }),
          Description: 'Custom Field',
        },
      ],
    }))
    return '#4799eb'
  }

  if (!apiData.emailData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  // console.log(apiData)
  // console.log(autoCompleteMapFromAPi(apiData.fieldsData))
  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        Email Editor
        <LoadingButton
          className='mr-1 ml-auto'
          style={{ float: 'right' }}
          loading={status === 'saving'}
          onClick={saveEmail}
        >
          Save
        </LoadingButton>
        <CButton
          className='mr-2'
          type='button'
          color='secondary'
          style={{ float: 'right' }}
          onClick={history.goBack}
        >
          Cancel
        </CButton>
      </CCardHeader>
      <CCardBody>
        <SelectMultipleImage
          show={show}
          metasId={apiData.emailData.MetasId}
          typeFile='any'
          onClose={() => {
            setShow(() => {
              return false
            })
          }}
          onSelectImage={({ FilesId }) => {
            console.log(FilesId, 'on select images')
            editorRef?.current?.insertInCurrentCursorPosition(`${ALERTIMAGEDOWNLOAD}/${FilesId}`)
          }}
        />
        <CFormGroup row>
          <CCol xs='12' sm='5'>
            <ComboBox
              showButton
              name='ProcsId'
              label='Procs'
              data={apiData.procData}
              value={apiData?.procData?.find(
                ({ CodeId }: any) => CodeId === apiData.emailData['ProcsId'],
              )}
              handleChange={(e: any, newValue = {}) => handleComboChange(e, newValue)}
              classNames={{ input: 'input-field' }}
              customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
              renderOption={(option: any, { selected }: any) => (
                <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
              )}
            />
          </CCol>
          <CCol xs='12' sm='5'>
            <InputField
              name='Name'
              type='text'
              label='Email Name'
              value={apiData.emailData.Name}
              onChange={handleChange}
            />
          </CCol>
          <CCol xs='12' sm='2'>
            {statusCreate === StatusCreate.isCreate && (
              <>
                <CCol>
                  <CButton
                    className='mr-2'
                    type='button'
                    color='primary'
                    onClick={() => setShow((value) => !value)}
                  >
                    Select image
                  </CButton>
                </CCol>
              </>
            )}
          </CCol>
        </CFormGroup>

        {/* <CustomTextEditor
          placeholders={autoCompleteMapFromAPi(apiData.fieldsData)}
          value={apiData.emailData.Body}
          onChange={({ data }: any) => handleChange({ name: 'Body', value: data })}
        /> */}
        <CustomCodeEditor
          name='Html'
          mode='html'
          ref={editorRef}
          value={apiData.emailData.Body}
          onChange={(e) => handleChange({ name: 'Body', value: e })}
        />
        <CRow className='justify-content-center mt-3'>
          <CCol xs='12' sm='6'>
            <div
              style={{ backgroundColor: 'white' }}
              dangerouslySetInnerHTML={{ __html: parseEmail() }}
            ></div>
          </CCol>
        </CRow>
        {/* </CCardBody> */}
        {/* </CCard> */}
        <ConfirmRedirectModal data={apiData?.emailData?.Body ?? ''} />
      </CCardBody>
    </CCard>
  )
}

export default EmailEditor

// SELECT * FROM ${tableName}
// ${joinType} ${joinTable} ${joinCondition}

// {
//   apiData.emailData.Fields ? (
//     <CCol md='4'>
//       <MapTable
//         variables={JSON.parse(apiData.emailData.Fields)?.map((field, idx) => ({
//           id: idx,
//           name: field,
//           value: '',
//         }))}
//         fields={['name', 'value']}
//         onChange={console.log}
//         onDelete={(e) => {
//           console.log(e)
//         }}
//       ></MapTable>
//       <CButton
//         className='mr-2'
//         type='button'
//         color='primary'
//         style={{ float: 'right' }}
//         size='sm'
//         onClick={() => {
//           /* setVariablesEditor((editor) => [
//                   ...editor,
//                   { id: `${Date.now()}`, value: '', name: '' },
//                 ]) */
//         }}
//       >
//         Add variable
//       </CButton>
//     </CCol>
//   ) : null
// }

// const PLACEHOLDERS = [
//   {
//     id: 1,
//     name: 'address',
//     title: 'Address',
//     description: 'Customer Support correspondence address.',
//   },
//   {
//     id: 2,
//     name: 'assignee',
//     title: 'Assignee Name',
//     description: 'Ticket assignee name.',
//   },
//   {
//     id: 3,
//     name: 'deadline',
//     title: 'Deadline Time',
//     description: 'Utmost time to which technician should handle the issue.',
//   },
//   {
//     id: 4,
//     name: 'department',
//     title: 'Department Name',
//     description: 'Department name responsible for servicing this ticket.',
//   },
//   {
//     id: 5,
//     name: 'caseid',
//     title: 'Case ID',
//     description: 'Unique case number used to distinguish tickets.',
//   },
//   {
//     id: 6,
//     name: 'casename',
//     title: 'Case Name',
//     description: 'Name of the ticket provided by the user.',
//   },
//   {
//     id: 7,
//     name: 'contact',
//     title: 'Contact E-mail',
//     description: 'Customer Support contact e-mail address.',
//   },
//   {
//     id: 8,
//     name: 'customer',
//     title: 'Customer Name',
//     description: 'Receipent of your response.',
//   },
//   {
//     id: 9,
//     name: 'hotline',
//     title: 'Hotline Number',
//     description: 'Customer Support Hotline number.',
//   },
//   {
//     id: 10,
//     name: 'technician',
//     title: 'Technician Name',
//     description: 'Technician which will handle this ticket.',
//   },
// ]
