import React, { useEffect } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { CFade } from '@coreui/react'
import { Loader, PrivateRoute } from './shared'
// import { Loader, PrivateRoute } from './shared'
import { DashBoard, Page404, Page500, Register, Logout, PostLogin, External } from './pages'
import 'react-toastify/dist/ReactToastify.min.css'

// MSAL imports
import { MsalProvider } from '@azure/msal-react'
import { connect } from 'react-redux'
import history from './history'
import { ToastContainer } from 'react-toastify'
import Login2 from './pages/login/Login2'
import axios from 'axios'
import Modal from 'react-modal';

interface PropsApp {
  pca?: any
  loggedInUser?: any,
  applicationRetrieve?: any
}
Modal.setAppElement('#root');
const App = ({ pca, loggedInUser = {}, applicationRetrieve = {} }: PropsApp) => {
  let titleFinal = applicationRetrieve.Title != undefined ? applicationRetrieve.Title : "Admin Dashboard";

  useEffect(() => {
    document.title = titleFinal
  }, [])
  // https://developers.google.com/web/updates/2019/02/scrolling-intervention
  // window.addEventListener('wheel', () => {}, { passive: true })
  // library.add(fas)

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      //debugger
      if (error.response.status === 401 || error.response.status === 403) {
        localStorage.clear()
        window.location.reload();
      }
      return error;
    }
  );

  return (
    <>
      <Router history={history}>
        <React.Suspense fallback={<Loader fullScreen />}>
          <MsalProvider instance={pca}>
            <Switch>
              <Route
                exact
                path='/External/Customers/Detail/:id'
                render={(props) => <External {...props} />}
              />
              <Route
                exact
                path='/login'
                render={(props) => {
                  if (
                    typeof loggedInUser.ADMIN_API_KEY === 'undefined' ||
                    loggedInUser.ADMIN_API_KEY === null
                  ) {
                    return (
                      <CFade>
                        <Login2 {...props} />
                      </CFade>
                    )
                  }
                  return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                }}
              />
              <Route
                exact
                path='/postlogin'
                render={(props) => <PostLogin {...props} loggedInUser={loggedInUser} />}
              />

              <Route exact path='/logout' render={(props) => <Logout {...props} />} />
              <Route exact path='/register' render={(props) => <Register {...props} />} />
              <Route exact path='/404' render={(props) => <Page404 {...props} />} />
              <Route exact path='/500' render={(props) => <Page500 {...props} />} />
              <PrivateRoute path='/' name='Home' loggedInUser={loggedInUser}>
                <DashBoard />
              </PrivateRoute>
            </Switch>

            <ToastContainer autoClose={3500} />
          </MsalProvider>
        </React.Suspense>
      </Router>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  return {
    loggedInUser: auth.loggedInUser,
    applicationRetrieve: auth.applicationRetrieve
  }
}

export default connect(mapStateToProps, null)(App)
