import CIcon from '@coreui/icons-react'
import { CDataTable, CInput } from '@coreui/react'
import { useState } from 'react'
interface PropsMapTable {
  variables: any[]
  fields: any[]
  onChange: (value: any) => {}
  onDelete: (value: any) => {}
}
const MapTable: React.FC<PropsMapTable> = ({
  variables = [],
  fields = [],
  onChange = () => {},
  onDelete = () => {},
}) => {
  const fieldsTable = [...fields, 'status']
  const [variablesEditor, setVariablesEditor] = useState(variables)
  const findValue = (id: any) => variablesEditor.find((e: any) => Number(e.id) === Number(id))
  const getValue: any = (id: any) => {
    const value = findValue(id)
    return value
  }
  const changeValue = (id: any, newValue: any) => {
    onChange(newValue)
    setVariablesEditor((state: any) => {
      return state.map((e: any) => {
        if (Number(e.id) === Number(id)) {
          return newValue
        }
        return e
      })
    })
  }

  return (
    <CDataTable
      items={variablesEditor}
      fields={fieldsTable}
      dark
      hover
      striped
      border
      size='sm'
      scopedSlots={{
        name: (item: any) => (
          <td>
            <CInput
              value={getValue(item.id).name}
              placeholder='Name'
              size='sm'
              onChange={(e: any) => changeValue(item.id, { ...item, name: e.target.value })}
            />
          </td>
        ),
        value: (item: any) => (
          <td>
            <CInput
              value={getValue(item.id).value}
              placeholder='Value'
              size='sm'
              onChange={(e: any) => changeValue(item.id, { ...item, value: e.target.value })}
            />
          </td>
        ),
        status: (item: any) => (
          <td>
            <CIcon
              onClick={() => onDelete(item)}
              name='cil-trash'
              height={18}
              className='mapTable-trash-icon'
            />
          </td>
        ),
      }}
    />
  )
}

export default MapTable
