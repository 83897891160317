import { ColumnData } from 'library-soft-datatable'

export const TableColumns: ColumnData[] = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Address',
    name: 'Address',
  },
  {
    label: 'City',
    name: 'City',
  },
  {
    label: 'State',
    name: 'State',
  },
  {
    label: 'Zip',
    name: 'Zip',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    customWidth: 10,
    sortable: false,
  },
]
