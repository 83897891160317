import { format, isThisWeek, isThisYear, isToday, isYesterday } from 'date-fns'
import { BASE_API, SERVICES, WIDGETS } from 'src/services/routeConst'

export const Note = ({ notes }) => {
  //console.log(notes)
//console.log(navigator.userAgent.indexOf("Firefox"));

  const forHumansDate = (date: string, showHour = false) => {
    if (date) {
      let dateParse
      if(navigator.userAgent.indexOf("Firefox")!==-1 || navigator.userAgent.indexOf("Safari")!==-1){
        // 05-27-2022 07:07:39 PM
        let dateArray = String(date).split(' ')
        let day = date[3] + date[4]
        let months = ['January', 'February', 'March', 'April','May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        let month = months[parseInt(date[1] + date[2])-1]
        let year = date[6] + date[7] + date[8] + date[9]
        let dateHour:any = dateArray[1].split(':')
        if(dateArray[2] === 'PM') {
          dateHour[0] = parseInt(dateHour[0]) + 12
        }
        let hour = dateHour.join(':')
        
        dateParse = new Date(`${day} ${month} ${year} ${hour} UTC`)
        if(dateParse == 'Invalid Date') {
          dateParse = new Date(date)
        }
        //console.log(dateParse);
        
      }
      else{
        // console.log("date");
        // console.log(date);
        dateParse = new Date(date)
      }

      if (showHour) {
        return `${format(dateParse, "hh:mm aaaaa'm'")}`
      }
      if (isToday(dateParse)) {
        return `Today`
        // return `Today ${format(dateParse, "hh:mm aaaaa'm'")}`
      }
      if (isYesterday(dateParse)) {
        return `Yesterday`
        // return `Yesterday ${format(dateParse, "hh:mm aaaaa'm'")}`
      }

      if (isThisWeek(dateParse)) {
        return format(dateParse, 'EEEE yyyy')
      }
      if (isThisYear(dateParse)) {
        return format(dateParse, 'EEEE, LLL d, yyyy')
      }
      console.log("dateParse");
      console.log(dateParse);
      
      return `${format(dateParse, 'EEEE, LLL d, yyyy')}`
    }
    return ''
  }

  return (
    <div
      style={{
        backgroundColor: '#0000',
        padding: '5px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '1px',
          backgroundColor: '#D9D9D9',
          marginLeft: '5px',
          marginTop: '10px',
          marginBottom: '0.25rem',
        }}
      ></div>
      {notes.map((n, i) => {
        return (
          <div
            key={n.Id}
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              backgroundColor: 'white',
            }}
          >
            {((i > 0 && n.ChangedOn.substring(0, 10) !== notes[i - 1].ChangedOn.substring(0, 10)) ||
              i === 0) && (
              <span
                style={{
                  color: '#111',
                  fontSize: '12px',
                  backgroundColor: '#FBFBFB',
                  padding: '5px',
                  margin: '0 auto',
                  marginBottom: '1rem',
                  borderRadius: '10px',
                }}
              >
                {forHumansDate(n.ChangedOn)}
              </span>
            )}
            <div
              key={n.Id}
              style={{
                width: '100%',
                border: 'solid #eee 1px',
                textAlign: 'center',
                marginBottom: '10px',
                marginTop: '2px',
                padding: '10px',
                borderRadius: '10px',
                backgroundColor: '#FBFBFB',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <span style={{ color: '#000', whiteSpace: 'nowrap' }}> {n.UserName}</span>
                <span style={{ color: '#000', whiteSpace: 'nowrap' }}>
                  {forHumansDate(n.ChangedOn, true)}
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <span style={{ border: 'solid 1px #ddd', borderRadius: '100%', width: '34px', height: '34px' }}>
                    {/* <Avatar name={n.UserName} FilesId_Avatar={0}></Avatar> */}
                    <img
                      src={`${BASE_API}${SERVICES}${WIDGETS}/Files/Download/${n.Guid}`}
                      style={{ borderRadius: '50%', width: '32px', height: '32px' }}
                      alt=''
                    />
                  </span>
                  <span
                    style={{
                      color: 'black',
                      marginLeft: '1rem',
                      textAlign: 'left',
                      fontSize: '1rem',
                    }}
                  >
                    {n.Notes || n.LookupStart}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
