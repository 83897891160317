// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const VehicleInfo = ({ modelData, handleChange, title = 'Model Name' }: PropsInfoForm) => {
  const { Vin, Mileage, VehicleYear, VehicleMake, VehicleModel, VehicleTrim } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='VehicleTrim'
            type='text'
            label='Vehicle Trim'
            value={VehicleTrim ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='Vin'
            type='text'
            label='Vehicle Vin'
            value={Vin ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='Mileage'
            type='number'
            label='Vehicle Mile age'
            value={Mileage ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='VehicleYear'
            type='number'
            label='Vehicle Year'
            value={VehicleYear ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='VehicleMake'
            type='text'
            label='Vehicle Make'
            value={VehicleMake ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='VehicleModel'
            type='text'
            label='Vehicle Model'
            value={VehicleModel ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default VehicleInfo
