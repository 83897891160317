import React, {useEffect} from 'react'
import { connect } from 'react-redux'
//import CIcon from '@coreui/icons-react'
//import { useHistory } from 'react-router-dom'
//import * as CoreUiIcons from '@coreui/icons'
import { CCol, CFormGroup } from '@coreui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import React, { useContext } from 'react'
import { InputField, ComboBox } from 'src/shared'
import * as faIcons from '@fortawesome/free-solid-svg-icons'
import { setIcon } from '../../../../redux/actions/IconActions'
import Modal from 'react-modal';
import SearchIcon from '../../../../shared/inputFields/SearchIcon'
import ColorPicker from '../../../../shared/inputFields/ColorPicker'

//import { DashboardContext } from 'src/containers/DashBoard'

interface PropsMenuInfo {
  menuData?: any
  allMenusData: any[]
  allRollData?: any[]
  handleChange?: any
  handleComboChange?: any
  handleRollComboChange?: any
  iconFontAwesome?: any
  setIcon?: any

}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const MenuInfo: React.FC<PropsMenuInfo> = ({
  menuData,
  allMenusData,
  allRollData = [],
  handleChange,
  handleComboChange,
  handleRollComboChange,
  iconFontAwesome = '',
  setIcon
}) => {

 // let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [colorModalIsOpen, setColorIsOpen] = React.useState(false);
  const [colorHex, setColor] = React.useState(menuData.Color);

  useEffect(() => {
    setColor(menuData.Color);
  });
  function openModal() {
    setIsOpen(true);
  }

  function openColorModal() {
    setColorIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  //  subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  function closeColorModal() {
    setColorIsOpen(false);
  }

  function selectColor(c : string) {
    setColor(c);
    closeColorModal();
  }
  
  //let history = useHistory();
  if(iconFontAwesome == '') {
    setIcon(menuData.Icon);
  }
  
  //const { zoomMode } = useContext(DashboardContext)
  const dVal = allMenusData.find((member: any) => member.Id == menuData.ApplicationsId)

  // var str = JSON.stringify(allRollData)
  // str = JSON.stringify(allRollData, null, 4)
  //console.log('baseInputProps' + str)

  const mapComboToData = () =>
    allMenusData.map((route) => {
      return {
        CodeId: route.Id,
        Description: route.Title,
      }
    })
  const mapRollComboToData = () =>
    allRollData.map((route) => {
      return {
        CodeId: route.Id,
        Description: route.Name,
      }
    })

  // const openIt = () => {
  //   history.push('/searchIcon')
  // }

 // const navigate = useNavigate();

  return (
    <>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Name'
            type='text'
            label='Menu Name'
            value={menuData.Name}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Description'
            type='textarea'
            label='Menu Description'
            value={menuData.Description}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Icon Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        {/* <button onClick={closeModal}>close</button> */}
        <div className='iconModalComponent'><SearchIcon closeModal={closeModal}/></div>
        
      </Modal>
        <CCol xs='6' sm='6'>
          <div className='searchIconInputDiv' onClick={openModal}>
            <span className='label'>Search Icon</span>
            <div className='iconAndName'>
              {faIcons[iconFontAwesome] != undefined && <FontAwesomeIcon icon={faIcons[iconFontAwesome]} />}
              <input
                id=''
                className='searchIconInput'
                type='hidden'
                name='Icon'
                onChange={handleChange}
                value={menuData.Icon = iconFontAwesome}
              />
              <div className='iconPDiv'>
              <span className='iconName'>{iconFontAwesome}</span>
              <div className='filler'></div>
              <div className='searchIcon'>
                <FontAwesomeIcon icon={faIcons['faSearch']} />
              </div>
              </div>
            </div>
          </div>

          {/* <InputField
            name='Icon'
            type='iconpicker'
            label='Icon'
            coreUiIcon={CoreUiIcons}
            size={zoomMode === 'enabled' ? 'lg' : 'sm'}
            renderModal={({ show, onClose, classNames, IconList }: any) => (
              <CModal
                size='xl'
                show={show}
                onClosed={onClose}
                color='primary'
                addContentClass={`mw-100 ${classNames.modalContainer}`}
                scrollable
              >
                <CModalHeader closeButton>
                  <CModalTitle>Select an Icon</CModalTitle>
                </CModalHeader>
                <CModalBody>{IconList}</CModalBody>
              </CModal>
            )}
            value={menuData.Icon}
            onChange={handleChange}
            showButton
            IconComponent={CIcon}
            ButtonComponent={({ styles, togglePicker }: any) => (
              <CIcon style={styles} onClick={togglePicker} name='cil-apps' />
            )}
          /> */}
        </CCol>
        <CCol xs='6' sm='6'>
        <Modal
        isOpen={colorModalIsOpen}
       // onAfterOpen={afterOpenModal}
        onRequestClose={closeColorModal}
        style={customStyles}
        contentLabel="Color Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        {/* <button onClick={closeModal}>close</button> */}
        <div className='iconModalComponent'><ColorPicker selectColor={selectColor}/></div>
        
      </Modal>

      <div className='searchIconInputDiv' onClick={openColorModal}>
            <span className='label'>Color</span>
            <div className='iconAndName'>
              <div className='circleColorDiv' style={{backgroundColor : colorHex}}></div>
              {colorHex}
              <input
                id=''
                className='searchIconInput'
                type='hidden'
                name='Color'
                onChange={handleChange}
                value={menuData.Color = colorHex}
              />
              
            </div>
          </div>
          {/* <InputField
            name='Color'
            type='colorpicker'
            label='Color'
            value={menuData.Color}
            onChange={handleChange}
            ButtonComponent={({ styles, togglePicker }: any) => (
              <CIcon style={styles} onClick={togglePicker} name='cil-color-palette' />
            )}
          /> */}
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='6'>
          <ComboBox
            showButton
            name='ApplicationsId'
            label='Application'
            data={mapComboToData()}
            value={dVal ? dVal.Title : ''}
            handleChange={handleComboChange}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
            )}
          />
        </CCol>
        <CCol xs='6'>
          <ComboBox
            showButton
            multiple
            name='RolesList'
            label='Role'
            data={mapRollComboToData()}
            value={menuData.RolesList ?? []}
            handleChange={handleRollComboChange}
            classNames={{ input: 'input-field' }}
            customClassNames={{
              button: 'comboBoxInput',
              root: 'input-field-root upscaled custom-multi',
            }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
            )}
          />
        </CCol>
      </CFormGroup>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { auth, icon } = state
  return {
    loggedInUser: auth.loggedInUser,
    iconFontAwesome: icon.iconFontAwesome,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setIcon: (data: any) => dispatch(setIcon(data)),
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(MenuInfo))