import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
interface PropsMemberInfo {
  id?: any
  allMembersData?: any
  memberData?: any
  handleChange?: any
  handleComboChange?: any
  handlePhoneChange?: any
  showVerifyOTPModalFunc?: any
  sendingOTP?: any
  showVerifyPhoneModal?: any
  showVerifyEmailModal?: any
}
const MemberInfo: React.FC<PropsMemberInfo> = ({
  id,
  /* allMembersData, */
  memberData,
  handleChange,
  /* handleComboChange, */
  handlePhoneChange,
  showVerifyOTPModalFunc,
  sendingOTP,
  showVerifyPhoneModal,
  showVerifyEmailModal,
}) => {
  const btnRef = React.useRef<any>()
  const { FirstName, LastName, Phone, Email, Nickname, DeviceId, CodePrimary } = memberData

  const renderPhoneButton = () => {
    const unClickable = sendingOTP === 'Phone' || showVerifyPhoneModal
    return (
      <small
        ref={btnRef}
        className={`input-field-button ${unClickable ? 'text-muted' : ''}`}
        onClick={() => {
          !unClickable && showVerifyOTPModalFunc({ type: 'Phone', sendTo: Phone })
        }}
      >
        Verify
      </small>
    )
  }

  const renderEmailButton = () => {
    const unClickable = sendingOTP === 'Email' || showVerifyEmailModal
    return (
      <small
        ref={btnRef}
        className={`input-field-button ${unClickable ? 'text-muted' : ''}`}
        onClick={() => {
          !unClickable && showVerifyOTPModalFunc({ type: 'Email', sendTo: Email })
        }}
      >
        Verify
      </small>
    )
  }
  return (
    <FieldsSection header='Member Info'>
      {/* <CFormGroup row>
        <CCol xs='12' sm='4'>
          <InputField
            name='MembersId'
            label='Member ID'
            type='number'
            value={MembersId}
            disabled
          />
        </CCol>
      </CFormGroup> */}
      <CFormGroup row>
        {/* <CCol xs='12' lg='4'>
          <InputField
            name='UserName'
            type='username'
            label='User Name'
            value={UserName}
            onChange={handleChange}
          />
        </CCol> */}
        <CCol xs='12' lg='3' className='pr-lg-0 mb-3 my-lg-0'>
          <InputField
            name='FirstName'
            type='text'
            label='First Name'
            value={FirstName}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3'>
          <InputField
            name='LastName'
            type='text'
            label='Last Name'
            value={LastName}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3' className='pr-lg-0'>
          <InputField
            name='Nickname'
            type='text'
            label='Nickname'
            value={Nickname}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3'>
          <InputField
            name='CodePrimary'
            type='text'
            label='Code Primary'
            value={CodePrimary}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='6'>
          <InputField
            name='Phone'
            type='phone'
            label='Phone Number'
            value={Phone}
            onChange={handlePhoneChange}
            verifyFunc={() => showVerifyOTPModalFunc({ type: 'Phone', sendTo: Phone })}
            showButton
            ButtonComponent={renderPhoneButton}
          />
        </CCol>
        <CCol xs='12' lg='6'>
          <InputField
            name='Email'
            type='email'
            label='Email'
            value={Email}
            onChange={handleChange}
            verifyFunc={() => showVerifyOTPModalFunc({ type: 'Email', sendTo: Email })}
            showButton
            ButtonComponent={renderEmailButton}
          />
        </CCol>
      </CFormGroup>

      {id ? (
        <>
          {/* <CFormGroup row>
            <CCol xs='12'>
              <ComboBox
                showButton
                name='MembersId_Parent'
                label='Parent Member'
                data={allMembersData}
                value={allMembersData.find(
                  (member: any) => member.CodeId === memberData.MembersId_Parent,
                )}
                handleChange={handleComboChange}
                classNames={{ input: 'input-field' }}
                customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                renderOption={(option: any, { selected }: any) => (
                  <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
                )}
              />
            </CCol>
          </CFormGroup> */}
          <CFormGroup row>
            <CCol xs='12'>
              <InputField
                name='DeviceId'
                type='text'
                label='Device ID'
                value={DeviceId}
                onChange={handleChange}
                // disabled
              />
            </CCol>
          </CFormGroup>
        </>
      ) : null}
    </FieldsSection>
  )
}
export default React.memo(MemberInfo)
