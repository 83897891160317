import { DataTable } from 'library-soft-datatable'
import { subMenuWithRoleTableColumns } from '../roleTableColumns'
import { BASE_API, LIST, MODELS, SERVICES, SUB_MENUS_MODEL } from 'src/services/routeConst'
import { routeToPage } from 'src/services/routingHelper'
import { getAuthToken } from 'src/services/authHelper'

const SubMenus = ({ history, id }) => {
  const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Admin/Menus/Detail/${id}`)
  }

  const actionFunctions: any = {
    view: viewDetails,
  }
  return (
    <DataTable
      columnData={subMenuWithRoleTableColumns}
      theme='white'
      noHeader
      endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
      scopedSlots={{
        Name: ({ value }) => {
          return <div>{value}</div>
        },
        Description: ({ value }) => {
          return <div>{value}</div>
        },
      }}
      queryParams={[
        {
          Name: 'RolesId',
          Value: id,
        },
      ]}
      modelName={SUB_MENUS_MODEL}
      actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
      ref={null}
      apiKey={getAuthToken('ADMIN')}
    />
  )
}
export default SubMenus
