// Generated with util/create-search-page.js
import { FieldsSection } from 'src/shared'
import AddressComponent from './AddressComponent'

// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsPickUpInfoForm {
  modelData: any
  handleChange: any
  title: string
  handleAddressChange: any
}

const PickUpInfo = ({
  modelData,
  handleChange,
  title = 'Model Name',
  handleAddressChange,
}: PropsPickUpInfoForm) => {
  return (
    <FieldsSection
      header={
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {title}
        </div>
      }
    >
      <AddressComponent
        modelName='Pickup'
        modelData={modelData}
        handleAddressChange={handleAddressChange}
        handleChange={handleChange}
      />

      {/*   <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </FieldsSection>
  )
}

export default PickUpInfo
