// Generated with util/create-search-page.js
import { CCardBody, CCol, CRow } from '@coreui/react'
import { Loader } from 'src/shared'
import { HeaderComponent, TabsCustomerInformation, TabsInformation } from './components'
import { CUSTOMERS_MODEL, VEHICLES_MODEL, OPERATIONS_QUANTUM } from 'src/services/routeConst'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'
import { createContext, useState } from 'react'
import Breadcrumb from 'src/pages/Workflow/Workflow/components/Bread'

export const CustomerContext = createContext<{
  CustomersId: number
  MetasId: number
  FilesId_Avatar: number
}>({
  CustomersId: 0,
  MetasId: 0,
  FilesId_Avatar: 0,
})
const ModelsDetails = ({
  history,
  match,
  modelName = CUSTOMERS_MODEL,
  modelNameVehicle = VEHICLES_MODEL,
  modelNameDispatch = OPERATIONS_QUANTUM,
}) => {
  const [reloadNote, setReloadNote] = useState(true);
  const { id = 0 } = match.params
  const [apiData, apiDataVehicle, apiDataDispatch, status, saveData, handleChange, handleChangeMaxLength, handleAddressChange] =
    useActionsModels({
      id,
      modelName,
      modelNameDispatch,
      modelNameVehicle,
      history,
    })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }

  const { modelData } = apiData
  const modelDataDispatch = apiDataDispatch.modelDataDispatch || {}
  const modelDataVehicle = apiDataVehicle.modelDataVehicle || {}

  const actionClick = () => {
    setReloadNote(false);
    setTimeout(() => {
      setReloadNote(true);
    }, 500)
  }

  console.log('modelDataCustoemr', modelData);


  return (
    <CustomerContext.Provider
      value={{
        CustomersId: id,
        MetasId: modelData?.MetasId ?? 0,
        FilesId_Avatar: modelData?.FilesId_Avatar ?? 0,
      }}
    >
      <>
        <HeaderComponent
          modelName={modelName}
          onCancelHandle={history.goBack}
          status={status}
          saveData={saveData}
          modelData={modelData}
          handleChange={handleChange}
        />
        <CCardBody className={'custom-card-body'}>
          <CRow>
            <CCol xs={8} className={'p-0'}>
              <Breadcrumb MetasId={modelData?.MetasId} isCalled={true} CustomersId={1} actionClick={actionClick} BreadcrumbUrl={'https://api.softsmartinc.com/Services/vOld/Widget/Breadcrumbs/Initialize'}></Breadcrumb>
              <TabsCustomerInformation
                modelData={modelData}
                modelDataVehicle={modelDataVehicle}
                modelDataDispatch={modelDataDispatch}
                handleChange={handleChange}
                handleChangeMaxLength={handleChangeMaxLength}
                handleAddressChange={handleAddressChange}
              />
            </CCol>
            <CCol xs={4}>
              {(reloadNote) ? <TabsInformation modelData={modelData} /> : <div className='mt-5 text-dark text-center font-bold'>Loading...</div>}
            </CCol>
          </CRow>
        </CCardBody>
      </>
    </CustomerContext.Provider>
  )
}

export default ModelsDetails
