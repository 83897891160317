import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { MembersRepository } from 'src/repository/admin/member/MembersRepository'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
// import imageCompression from 'browser-image-compression'
// import { toast } from 'react-toastify'
interface PropsEditMemberPaymentAccountsModal {
  showModal?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}
const EditMemberPaymentAccountsModal = ({
  showModal = false,
  setShowModal,
  modalData,
}: PropsEditMemberPaymentAccountsModal) => {
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<any>()
  const { id, MembersId, modelName } = modalData
  /* const fields = [
    'BanksId',
    'MembersId',
    'BankName',
    'AccountNumber',
    'RoutingNumber',
    'Nickname',
    'AccountType',
    'IconUrl',
    'IsAutopayAccount',
    'BankLogoBlob',
  ] */

  const membersRepository = React.useMemo(() => new MembersRepository(), [])

  useEffect(() => {
    const getDataInEffect = async () => {
      try {
        const { mainData } = await membersRepository.getData({
          id,
          modelName: modelName,
          defaultMainData: { MembersId, BanksId: 0 },
        })
        setData({ ...mainData, BankLogoBlob: `data:image/png;base64,${mainData.BankLogoBlob}` })
      } catch (error: any) {
        console.error(error)
      }
    }
    loadingData && getDataInEffect()
  }, [MembersId, id, loadingData, membersRepository, modelName])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])
  const useEffectTS: any = useEffect
  useEffect(() => {
    data && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  /* const saveData = async () => {
    setShowModal(false)
    const dataToSave: any = data
    dataToSave.IsAutopayAccount = [1, true, 'True'].includes(data.IsAutopayAccount)
      ? 'True'
      : 'False'
    try {
      const bankLogo64 = dataToSave['BankLogoBlob'].replace('data:', '').replace(/^.+,/, '')
      await membersRepository.saveData({
        modelName: modelName,
        idName: 'BanksId',
        dataToSave: {
          ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
          BankLogoBlob: bankLogo64,
        },
      })
    } catch (error: any) {
      console.error(error)
    }
    // setData({})
    callBack()
  } */

  const handleChange = ({ name = '', value = '' }) => {
    name && setData((st: any) => ({ ...st, [name]: value }))
  }

  const handleSwitchChange = (name: any, value: any) => {
    setData((st: any) => ({
      ...st,
      [name]: [1, true, 'True'].includes(value) ? 1 : 0,
    }))
  }
  const blobToImage = (value) => {
    //
    return <img src={value} style={{ width: '80px' }} alt={'LogoBank'} />
  }
  if (loadingData) {
    return null
  }

  return (
    <CModal
      show={showModal}
      size={'xl'}
      onClose={() => {
        // setData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} Bank</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='8'>
            <FieldsSection header='Info'>
              <CFormGroup row>
                <CCol xs='6' sm='6'>
                  <InputField
                    disabled
                    name='BankName'
                    type='text'
                    label='Bank Name'
                    value={data.BankName}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='6' sm='6'>
                  <InputField
                    disabled
                    name='AccountType'
                    type='text'
                    label='AccountType'
                    value={data.AccountType}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>

              <CFormGroup row>
                <CCol xs='6' sm='6'>
                  <InputField
                    disabled
                    name='AccountNumber'
                    type='text'
                    label='AccountNumber'
                    value={data.AccountNumber}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='6' sm='6'>
                  <InputField
                    disabled
                    name='RoutingNumber'
                    type='text'
                    label='Routing Number'
                    value={data.RoutingNumber}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='6' sm='6'>
                  <InputField
                    disabled
                    name='Nickname'
                    type='text'
                    label='Nickname'
                    value={data.Nickname}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='6' sm='6'>
                  <InputField
                    disabled
                    name='IsAutopayAccount'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Autopay Account'
                  >
                    <CustomSwitch
                      disabled
                      name='IsAutopayAccount'
                      className='d-block'
                      value={data.IsAutopayAccount}
                      updateData={handleSwitchChange}
                    />
                  </InputField>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='6' sm='6'>
                  <InputField
                    disabled
                    name='TokenCardworks'
                    type='text'
                    label='Cardworks Token'
                    value={data.TokenCardworks}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='6' sm='6'>
                  <InputField
                    disabled
                    name='PlaidAccessToken'
                    type='text'
                    label='Plaid Access Token'
                    value={data.PlaidAccessToken}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
          <CCol xs='4'>
            <FieldsSection header='Logo'>
              <CFormGroup row>
                <CCol
                  xs='12'
                  sm='12'
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {blobToImage(data.BankLogoBlob)}
                </CCol>
                <CCol xs='12' sm='12' style={{ marginTop: '1rem' }}>
                  {/* https://codepen.io/klekotinho77/pen/mdyOKaG */}

                  {/* <input type='file' /> */}
                  {/*  <UploadButton
                    onChange={async (e: any) => {
                      const [inputRead] = e.target.files
                      if (inputRead) {
                        try {
                          const compressedFile = await imageCompression(inputRead, {
                            maxSizeMB: 1,
                            maxWidthOrHeight: 1920,
                            useWebWorker: true,
                            maxIteration: 15,
                          })
                          const base64 = await imageCompression.getDataUrlFromFile(compressedFile)
                          setData((state) => ({ ...state, BankLogoBlob: base64 }))
                        } catch (error: any) {
                          toast.error(error.message)
                        }
                        
                      }
                    }}
                  /> */}
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        {/* <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton> */}
        <CButton
          color='secondary'
          onClick={() => {
            // setData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default EditMemberPaymentAccountsModal
