import React from 'react'

interface PropsChip {
  avatar?: string | React.ReactNode
  label?: string
  onClick: (value: any) => void
}

const Chip: React.FC<PropsChip> = ({ avatar = '', label = '', onClick = () => {} }) => {
  return (
    <div className='chip-root chip-clickable' tabIndex={0} role='button' onClick={onClick}>
      {avatar ?? null}
      <span className='chip-label'>{label}</span>
    </div>
  )
}

export default Chip
