// Generated with util/create-search-page.js
export const PushTableColumns = [
  {
    label: 'TokensId',
    name: 'TokensId',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'Notification',
    name: 'Notification',
  },
  {
    label: 'StampStart',
    name: 'StampStart',
  },
  {
    label: 'Stamp End',
    name: 'StampEnd',
  },
  {
    label: 'ChangedOn',
    name: 'ChangedOn',
  },
  /*  {
    label: 'TokensIdRecipient',
    name: 'TokensIdRecipient',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'DeviceId',
    name: 'DeviceId',
    dbType: 'varchar',
  },
  {
    label: 'Notification',
    name: 'Notification',
    dbType: 'varchar',
  },
  {
    label: 'StampStart',
    name: 'StampStart',
    dbType: 'datetime',
  },
  {
    label: 'StampEnd',
    name: 'StampEnd',
    dbType: 'datetime',
  },
  {
    label: 'Executiontime',
    name: 'Executiontime',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'IsError',
    name: 'IsError',
    dbType: 'bit',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'ErrorsId',
    name: 'ErrorsId',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  }, */
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]
