import React, { useState } from 'react'
import { connect } from 'react-redux'
//import { useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as faIcons from '@fortawesome/free-solid-svg-icons'
import { setIcon } from '../../redux/actions/IconActions'
// interface PropsSearchIcon { 
//   closeModal?: any,
//   setIcon?: any
// }
const SearchIcon: React.FC<any> = (props: any) => {
  //let history = useHistory();
  const [fIcons, setIcons] = useState(faIcons)
  const [searchValue, setSearchValue] = React.useState('')

  const onChangeHandler = (event) => {
    setSearchValue(event.target.value)
  }
  function handleSearch() {
    setIcons(faIcons)
  }
  const handleIconSelect = (key) => {
    props.setIcon(key)
    props.closeModal()
    
    //history.goBack();
  }

  return (
    <div>
      <div className='wrapperSearchIcon'>
        <div className='labelSearchIcon'>Search Icon</div>
        <div className='searchBarSearchIcon'>
          <input
            id='searchQueryInputSearchIcon'
            type='text'
            name='searchQueryInputSearchIcon'
            placeholder='Search'
            onChange={onChangeHandler}
            value={searchValue}
          />
          <button
            onClick={() => handleSearch()}
            id='searchQuerySubmitSearchIcon'
            type='submit'
            name='searchQuerySubmitSearchIcon'
          >
            <svg style={{ width: '24px', height: '24px' }} viewBox='0 0 24 24'>
              <path
                fill='#666666'
                d='M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z'
              />
            </svg>
          </button>
        </div>
      </div>
      <div className='flex-container-si'>
        {Object.keys(fIcons).map((key) => {
          if (key.includes(searchValue) && key !='fas' && key !='prefix') {
            return (
              <div key={key} onClick={()=>handleIconSelect(key)} className='cardSearchIcon'>
                <div className='centerSearchIcon'>
                  <FontAwesomeIcon icon={fIcons[key]} size='6x' transform='shrink-6' />
                </div>
                <div className='containerSearchIcon'>
                  <br></br>
                  <p className='centerSearchIcon'>{key}</p>
                </div>
              </div>
            )
          } else {
            return <></>
          }
        })}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  return {
    loggedInUser: auth.loggedInUser,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setIcon: (data: any) => dispatch(setIcon(data)),
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(SearchIcon))
