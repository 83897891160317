// import axios from 'axios'
import axios from 'axios'
import { toast } from 'react-toastify'
import { setUser, setApplicationRetrieve } from 'src/redux/actions/authActions'
import store from 'src/redux/store'
import { APIError } from './API/APIError'
import { AUTH_AUTHENTICATE, BASE_API, LOGIN, SERVICES, MODELS, RETRIEVE } from './routeConst'
/* import {
  ADMIN_LOGIN,
  APPLICATION_LOGIN,
  AUTH_AUTHENTICATE,
  AUTH_VALIDATE,
  BASE_API,
  LOGIN_ENDPOINT,
  LOGIN_PORT,
} from './routeConst' */

// const LOGIN_ENPOINT = BASE_API + ':' + LOGIN
/* const appCredentials = {
  UserName: process.env.REACT_APP_APPLICATIONS_ADMIN_USERNAME,
  Password: process.env.REACT_APP_APPLICATIONS_ADMIN_PASSWORD,
} */
type headerType = 'ADMIN' | 'APPLICATION' | 'MS'
export const addAuthToHeader = (type: headerType = 'ADMIN') => {
  const token = getAuthToken(type)
  return {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
  }
}

export const addAuthAccessTokenToHeader = () => {
  const token = getAccessToken()
  //console.log(token);
  
  return {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
  }
}

export const addAuthToHeaderFormData = (type: headerType = 'ADMIN') => {
  const token = getAuthToken(type)
  return {
    headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' },
  }
}

export const getAuthToken = (type: headerType = 'ADMIN') => {
  const { auth } = store.getState()
  if (type === 'APPLICATION') {
    const { applicationRetrieve = {} } = auth
    return applicationRetrieve['AccessToken']
  } else {
    const { loggedInUser = {} } = auth
    return loggedInUser[`${type}_API_KEY`]
  }
}

export const getAccessToken = () => {
  const { auth } = store.getState()
  const accessToken = auth.loggedInUser.Auth.User.AccessToken
  return accessToken
}

export const logoutVerifyErrors = (status) => {
  if (status === 400 || status === 401 || status === 403 || status === 404) {
    localStorage.clear()
    window.location.reload()
  }
}

export const logoutVerifyErrors500 = (error) => {
  if (String(error.error.status)[0] === '5') {
    toast.error(
      <div>
        {`Error: ${error.error.status}`}
        <br />
        {`Message: ${error.message}`}
        <br />
        {`StackTrace: ${error.error.data.data.Error.stackTrace}`}
      </div>,
    )
  }
}

// ! FAKE LOGIN
interface LoginProps {
  accessToken?: any
  setRedirectToReferrer?: any
  UserName?: any
  Password?: any
}
export const login = async ({
  accessToken,
  setRedirectToReferrer = null,
  UserName,
  Password,
}: LoginProps) => {
  try {
    return await loginAdminUser(
      { AccessToken: accessToken, UserName, Password },
      setRedirectToReferrer,
    )
  } catch (error: any) {
    throw loginError(error)
  }
}

/* const loginApplication = async () => {
  let response: any = { data: null, error: null }
  try {
    const { data } = await axios.post(
      `${BASE_API}:${LOGIN_PORT}/${LOGIN_ENDPOINT}/${APPLICATION_LOGIN}/${AUTH_AUTHENTICATE}`,
      appCredentials,
    )
    const { Authentication, Application } = data.data
    response.data = { appToken: Authentication.AccessToken, Application }
  } catch (error: any) {
    response.error = error
    throw error
  }
  return response
} */

/* const adminAuth = async ({ type = AUTH_VALIDATE, AccessToken, appToken }: any) => {
  let response: any = { data: null, error: null }
  try {
    const endpoint = `${BASE_API}:${LOGIN_PORT}/${LOGIN_ENDPOINT}/${ADMIN_LOGIN}/${
      type === AUTH_VALIDATE ? AUTH_VALIDATE : AUTH_AUTHENTICATE
    }`
    const { data } = await axios.post(
      endpoint,
      { AccessToken },
      { headers: { Authorization: `Bearer ${appToken}` } },
    )
    response.data = data
  } catch (error: any) {
    response.error = error
    throw error
  }
  return response
} */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* const authenticateAdmin = async (AccessToken: string, appToken: any) => {
  let response: any = { data: null, error: null }
  const { data = {} } = await adminAuth({ type: AUTH_VALIDATE, AccessToken, appToken })
  if (data?.info === 'Success!') {
    const { data: authenticateResponse } = await adminAuth({
      type: AUTH_AUTHENTICATE,
      AccessToken,
      appToken,
    })
    const { Authentication = {}, User } = authenticateResponse?.data
    const { AccessToken: token } = Authentication
    response.data = { token, User }
  }
  return response
} */
// ORIGINAL ADMIN USER
/* const loginAdminUser = async ({ AccessToken }: any, setRedirectToReferrer: any) => {
  let response: any = { data: null, error: null }
  const { data } = await loginApplication()
  const { appToken }: any = data
  if (appToken) {
    const { data: authResponse = {} } = await authenticateAdmin(AccessToken, appToken)
    const { token, User }: any = authResponse
    if (token) {
      store.dispatch(
        setUser({
          ...User,
          MS_API_KEY: AccessToken,
          ADMIN_API_KEY: token,
          APPLICATION_API_KEY: appToken,
        }),
      )
      setRedirectToReferrer && setRedirectToReferrer(true)
      response.data = authResponse
    }
  }
  return response
} */

const loginApplication = async ({ UserName, Password }) => {
  try {
    const { data } = await axios.post(
      `${BASE_API}${SERVICES}/${LOGIN}/${AUTH_AUTHENTICATE}`,
      {
        UserName,
        Password,
        TokenType: 'users',
      },
      {
        headers: {
          Authorization: `Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9`,
        },
      },
    )
    return data.data
  } catch (error: any) {
    throw new Error(error.message)
  }
}
// FAKE LOGIN
const loginAdminUser = async ({ UserName, Password }: any, setRedirectToReferrer: any) => {
  const data = await loginApplication({ UserName, Password })
  store.dispatch(
    setUser({
      Auth: { ...data },
      MS_API_KEY: 'AccessToken',
      ADMIN_API_KEY: data.Token.tokenString,
      APPLICATION_API_KEY: 'appToken',
    }),
  )
  setRedirectToReferrer && setRedirectToReferrer(true)
  fetchAppData()
}
let location = window.location.href
const fetchAppData = async () => {
  try {
    const execData = await axios.post(
      `${BASE_API}${SERVICES}/data/execute`,
      {
        // [idName]: Id,
        DbName: 'DbAdmin',
        QueryOrProcedureName: 'access.APPLICATIONS_Retrieve',
        // Id: id,
        // Extensions: body.Extension ?? null,
        Params: [
          {
            Name: 'UrlRoot',
            Value: location,
          },
        ],
      },
      { ...addAuthAccessTokenToHeader() },
    )
    console.log('DATA EXECUTErrrr', execData)

    const { data } = await axios.post(
      `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
      {
        // [idName]: Id,
        Model: 'Applications',
        Id: execData.data.data.Execution[0].Id,
        // Extensions: body.Extension ?? null,
        Params: [
          // Name: "FilesId_Avatar", Value: data.data?.FileInfo?.Id
        ],
      },
      { ...addAuthAccessTokenToHeader() },
    )

    store.dispatch(setApplicationRetrieve(data.data.RetrieveInfo[0]))
    // setAppData(data.data.RetrieveInfo[0])

    console.log('RETRIEVE EXECUTE', data)
  } catch (error: any) {
    const status = error.response.status
    logoutVerifyErrors(status)
  }
}

const loginError = (error: any) => {
  toast.warning('Failed  authentication')
  return new APIError({
    status: 'error',
    message: `Failed to communicate with authentication server`,
    error: error.response,
  })
}
