// Generated with util/create-search-page.js
import { CButton, CCol, CFormGroup, CModal, CModalBody, CModalFooter, CModalHeader } from '@coreui/react'
import axios from 'axios'
import { DataTable } from 'library-soft-datatable'
import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
// import { toast } from 'react-toastify'
import { addAuthAccessTokenToHeader, getAuthToken, logoutVerifyErrors } from 'src/services/authHelper'
import { BASE_API, MODELS, RETRIEVE, SERVICES, LIST, SAVE, LETSCONNECT, SMS } from 'src/services/routeConst'
import Avatar from 'src/shared/components/Avatar/Avatar'
import { TableColumns } from '../components/CustomerService/InspectorTableColumns'
// import { RadioGroup, Radio } from 'react-radio-input'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange?: any
  title?: string
}

const InspectorAssignedCard = ({
  modelData,
}: PropsInfoForm) => {
  const [modal, setModal] = useState(false);
  const toggle = () => {
    // setStateAdd(!stateAdd)
    setModal(!modal)
  }
  const { 
    InspectorsId,
  } = modelData  
  let {InspectionDate} = modelData
  
  const [inspectorData, setInspectorData] = useState([{
    Id: 0,
    ChangedOn: "",
    TokensId_ChangedBy: 0,
    FirstName: "",
    LastName: "",
    PhoneMobile: "",
    Email: "",
    Address: "",
    City: "",
    State: "",
    Zip: "",
    MetasId: 0,
    EinSsn: null,
    HourlyRate: null,
    Timezone: "",
    InspectorType: ""
  }])
  // const [inspectorList, setInspectorList] = useState([])
  // const RadioGroupTs: any = RadioGroup
  const tableRef = useRef<any>()


  const fetchInspectorData = async (InspectorsId) => {
    try {
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
        {
          Model: 'Inspectors',
          Id: InspectorsId,
          Params: [],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      setInspectorData(data.data.RetrieveInfo[0])
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }
  
  const sendSms = async (inspector) => {
    // console.log(inspector.AccessToken);
    // console.log(modelData.Id);
    console.log(`https://admin.thesource.ai/External/Customers/Detail/${modelData.Id}?Tokens=${inspector.AccessToken}`);
    console.log(`New Inspection: ${modelData.Vehicle || ''}`);
    
    try{
      const phoneNumber = inspector['PhoneMobile'].replaceAll(/[() -]/g,'')
      await axios.post(
        `${BASE_API}${SERVICES}${LETSCONNECT}${SMS}`,
        {
          PhoneTo: phoneNumber,
          SmsMessage: `New Inspection: ${modelData.Vehicle || ''}`,
          Params: [],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      await axios.post(
        `${BASE_API}${SERVICES}${LETSCONNECT}${SMS}`,
        {
          PhoneTo: phoneNumber,
          SmsMessage: `https://admin.thesource.ai/External/Customers/Detail/${modelData.Id}?Tokens=${inspector.AccessToken}`,
          Params: [],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      await toast.success('Message sent')

    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
      console.error(error)
    }
  }

  const updateInspector = async ({ id }: any) => {
    await axios.patch(
      `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
      {
        Model: 'Customers',
        Id: modelData.Id,
        Params: [
          {
            name: 'InspectorsId',
            value: id
          }
        ],
      },
      { ...addAuthAccessTokenToHeader() },
    )
    modelData.InspectorsId = id
    setModal(false)
  }

  useEffect(() => {
    if(InspectorsId){
      fetchInspectorData(InspectorsId)
    }
  }, [InspectorsId])
  
  const actionFunctions: any = {
    select: updateInspector
  }

  const formatDate = (date) => {
    if(date) {
      date = date.split('-')
      const day = String(date[0])
      const month = String(date[1])
      const year = String(date[2])
      if(day.length===2){
        return `${year}-${day}-${month}`
      }
      return `${day}-${month}-${year}`
    }
    return ''
  }
  InspectionDate = formatDate(InspectionDate)

  const card = <>
  <div style={{display: 'flex'}}>
    <div className='inspector-card' onClick={toggle}>
      <div className="item-avatar">
        {/* <span style={{ border: 'solid 1px #ddd', borderRadius: '100%' }}> */}
          <Avatar name={`${inspectorData['FirstName'] || ''} ${inspectorData['LastName']} || ''`} FilesId_Avatar={0}></Avatar>
        {/* </span> */}
      </div>
      <div className='item-name'>
        {`${inspectorData['FirstName'] || ''} ${inspectorData['LastName'] || ''}`}
      </div>
      {/* <div className='inspector-price'>
        <i>
          ${ Cost['Amount'] ? Cost['Amount'].toFixed(2) : `0.00`}
        </i>
      </div> */}
      <div className='item-description'>
        {/* {inspectorData['Address'] || ''}
        <br />{inspectorData['City'] || ''} {inspectorData['State'] || ''} */}
        {inspectorData['PhoneMobile'] || ''}
      </div>
    </div>
    <div className='inspector-button'>
      <button className='item-btn btn btn-primary' onClick={()=>sendSms(inspectorData)}>Send</button>
    </div>
  </div>
  </>
  const unassigned = <div className='inspector-card-unassigned' onClick={toggle}>
    Click Here to Assign Inspector
    </div>

  return (
    <>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-4 mb-0'>
          {InspectorsId ? card : unassigned}
        </CCol>
      </CFormGroup>

    <CModal
        size='xl'
        show={modal}
        onClose={toggle}
      >      
        <CModalHeader closeButton>Choose Inspector</CModalHeader>
        <CModalBody className={'appointment-body'}>
        <div>
        <DataTable
          columnData={TableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={'Inspectors'}
          actionClick={(type: any, data: any) => {
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          paginationPosition='bottom-right'
          defaultPageSize={10}
        />
          </div>  
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={toggle}
          >Cancel</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default InspectorAssignedCard
