import { CButton, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import React, { useRef, useState } from 'react'
import DeleteModal from './DeleteModal'
import { routeToPage } from 'src/services/routingHelper'
import { applicationsTableColumns } from './applicationsTableColumns'
import { BASE_API, APPS_MODEL, SERVICES, MODELS, LIST } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'
interface PropsApplicationsSearch {
  history?: any
  modelName?: any
}

const ApplicationsSearch: React.FC<PropsApplicationsSearch> = ({
  history,
  modelName = APPS_MODEL,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const clickedIDRef = useRef()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Admin/Applications/Detail/${id}`)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <>
      <CCardHeader
        className='d-flex align-items-center card-accent-secondary'
        style={{ backgroundColor: '#2A3446', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}
      >
        <span style={{ color: 'white' }}>{modelName} Search</span>
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, `/Admin/Applications/New`)}
        >
          New Application
        </CButton>
      </CCardHeader>
      <CCardBody className={'custom-card-body'}>
        <DataTable
          columnData={applicationsTableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={modelName}
          scopedSlots={{
            Name: ({ value }) => {
              return <div>{value}</div>
            },
            UserName: ({ value }) => {
              return <div style={{ color: '#F2AB26' }}>{value}</div>
            },
            Password: ({ value }) => {
              return <div>{value}</div>
            },
          }}
          actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Application', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </>
  )
}

export default ApplicationsSearch
