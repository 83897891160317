export const constructSidebarItems = (menus: any) => {
  return [
    {
      _tag: 'CSidebarNavTitle',
      _children: ['Navigation'],
    },
    {
      _tag: 'CSidebarNavItem',
      name: 'Home',
      to: '/dashboard',
      icon: 'cil-home',
      badge: {
        color: 'info',
        text: 'NEW',
      },
    },
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: 'SMS',
    //   to: '/Admin/Sms/Search',
    //   icon: 'cil-home',
    // },
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: 'Campaign',
    //   to: '/Admin/Campaign/Search',
    //   icon: 'cil-home',
    // },
    // {
    //   _tag: 'CSidebarNavItem',
    //   name: 'Import',
    //   to: '/Admin/Messages/Search',
    //   icon: 'cil-home',
    // },
    {
      _tag: 'CSidebarNavTitle',
      _children: ['Main'],
    },
    ...menus
      .filter(({ IsMain }: any) => IsMain)
      .map(({ name, path, IsMain, Icon, sub_menus = [] }: any) => ({
        _tag: 'CSidebarNavDropdown',
        name,
        icon: Icon,
        route: path,
        _children: IsMain
          ? sub_menus.map(({ Name, PagePath, Icon }: any) => {
              return {
                _tag: 'CSidebarNavItem',
                name: Name,
                to: PagePath,
                icon: Icon,
              }
            })
          : [],
      })),
  ]
}
