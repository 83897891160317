// Generated with util/create-search-page.js
import { useState, useMemo, useCallback, useEffect } from 'react'
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { Loader } from 'src/shared'
import { SigninsHeader, SigninsInfo } from './components'
import { SIGNINS_MODEL, SIGNINS_ID } from 'src/services/routeConst'

/* const fields = [
  'SigninsId',
  'TokenType',
  'SourceIp',
  'SourceBrowser',
  'Validity',
  'StampStart',
  'StampEnd',
  'Executiontime',
  'IsError',
  'ErrorsId',
] */

const SigninsDetails = ({ history, match, modelName = SIGNINS_MODEL }) => {
  const [apiData, setApiData] = useState<any>({ modelData: null })
  const [status, setStatus] = useState<any>('loading')

  const { id = 0 } = match.params

  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback<any>(async () => {
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [SIGNINS_ID]: 0 },
      })
      setApiData({ modelData: mainData })
      setStatus('idle')
    } catch (error) {
      setStatus('idle')
      console.error(error)
    }
  }, [rootRepository, id, modelName])

  useEffect(() => {
    getData()
  }, [getData])

  /*  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ modelData: null })
      history.goBack()
    }, delay)
  } */

  /* const saveData = async () => {
    const dataToSave: any = apiData.modelData
    setStatus('saving')
    try {
      await rootRepository.saveData({
        modelName,
        idName: SIGNINS_ID,
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        getData,
        goBack,
      })
      setStatus('idle')
    } catch (error) {
      setStatus('idle')
      console.error(error)
    }
  } */

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
    console.log(apiData)
  }

  if (!apiData.modelData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { modelData } = apiData
  return (
    <CCard>
      <SigninsHeader onCancel={history.goBack} />
      <CCardBody>
        <CRow>
          <CCol xs='12' md='6'>
            <SigninsInfo modelData={modelData} handleChange={handleChange} />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  )
}

export default SigninsDetails
