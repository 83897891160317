import {  CCardBody, CCardHeader, CDataTable } from '@coreui/react'
import React, {useState} from 'react'
import readXlsxFile from 'read-excel-file'
import DragDropFiles from '../../../shared/components/DragDropFiles'

const MessageSearch: React.FC<any> = () => {
  const [excelData, setExcelData] = useState([{id: "", name: '', phone: '', message: ''}]);

  const fields = [
    { key: 'name', _style: { width: '15%'} },
    { key: 'phone', _style: { width: '15%'} },
    { key: 'message', _style: { width: '60%'} },
  ]

  const handleExcelChange = (selectorFiles: FileList) => {
    console.log(selectorFiles);
    let r: any[] = []
    readXlsxFile(selectorFiles[0]).then((rows) => {
      const messages = rows.slice(1).map(([name, phone, message]) => ({name, phone, message}));
      r = messages;
      setExcelData(r);
      
    })
  }

  return (
    <>

      <CCardHeader
        className='d-flex align-items-center card-accent-secondary'
        style={{ backgroundColor: '#2A3446', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}
      >
        <span style={{ color: 'white' }}>Message Search</span> 
      </CCardHeader>
      <CCardBody className={'custom-card-body'}>
      <DragDropFiles handleExcelChange={handleExcelChange} modelData={[]}></DragDropFiles>
      {/* <input onChange={ (e?:any) => handleChange(e.target.files) } type="file" id="input" /> */}
      <CDataTable
      items={excelData}
      fields={fields}
      columnFilter
      tableFilter
      footer
      itemsPerPageSelect
      itemsPerPage={5}
      hover
      sorter
      pagination
      scopedSlots = {{
        'details':
            ()=>{
              return (
             <></>
            )
          }
      }}
    />
      </CCardBody>
      
    </>

  )
}

export default MessageSearch
