import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormGroup, CRow } from '@coreui/react'
import { CheckBoxGroup } from 'smart-inputs'
import { InputField } from 'src/shared'
import React from 'react'
import { Loader } from 'src/shared'
import LoadingButton from 'src/shared/components/LoadingButton'
import ConfirmRedirectModal from 'src/shared/modals/ConfirmRedirectModal'

import { toast } from 'react-toastify'
import { ProcGenerate } from 'src/services/modelNew/ProcGen/ProcGen'
import ProcProperties from './Components/ProcProperties'
// import { toast } from 'react-toastify'

const procTypes: any = [
  {
    name: 'Create',
    label: 'Create',
    value: false,
  },
  {
    name: 'Retrieve',
    label: 'Retrieve',
    value: false,
  },
  {
    name: 'Update',
    label: 'Update',
    value: false,
  },
  {
    name: 'Delete',
    label: 'Delete',
    value: false,
  },
  {
    name: 'Search',
    label: 'Search',
    value: false,
  },
  {
    name: 'List',
    label: 'List',
    value: false,
  },
  {
    name: 'Combo',
    label: 'Combo',
    value: false,
  },
]
interface ProcGenerateData {
  ParamList?: any[]
  FieldList?: any[]
  Extension?: string
}
interface ProcGenerateDataCombo {
  CodeIdField?: any
  DescriptionField?: any
  ParamList?: any[]
  Extension?: any
}
interface ApiDataRequest {
  data?: any
  Search?: ProcGenerateData
  List?: ProcGenerateData
  Combo?: ProcGenerateDataCombo
}
const Generator: React.FC<any> = ({ history }) => {
  const [procTypesOptions, setProcTypesOptions] = React.useState<any[]>(procTypes)
  const [status, setStatus] = React.useState('loading')
  const [apiData, setApiData] = React.useState<ApiDataRequest>({
    data: null,
  })
  // const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(async () => {
    try {
      setStatus('idle')
      setApiData((state) => {
        return { ...state, data: undefined }
      })
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }, [])

  React.useEffect(() => {
    getData()
  }, [getData])

  const generateSP = async () => {
    setStatus('generating')
    try {
      if (typeof apiData.data === 'undefined') {
        toast.error('Is required Database,Schema,Table')
        setStatus('idle')
        return
      }
      // console.log(apiData.data, procTypesOptions)
      for (let index = 0; index < procTypesOptions.length; index++) {
        const element = procTypesOptions[index]
        if (element.value) {
          const optionalProps =
            element.name === 'Search' || element.name === 'List' || element.name === 'Combo'
              ? apiData[element.name]
              : {}
          const requestProps = typeof optionalProps === 'undefined' ? {} : optionalProps
          await ProcGenerate({
            procType: element.name,
            params: {
              DatabaseName: apiData.data.DatabaseName,
              SchemaName: apiData.data.SchemaName,
              TableName: apiData.data.TableName,
              ...requestProps,
            },
          })
          toast.success(`Proc ${element.name} generate`)
        }
      }

      setStatus('idle')
    } catch (error: any) {
      console.log(error)
      toast.error(error.message)
      setStatus('idle')
    }
  }

  const resetFields = () => {
    setApiData({ data: undefined })
    setProcTypesOptions(procTypes)
  }
  const handleChange = ({ name = '', value = '' }) => {
    name && setApiData((state) => ({ ...state, data: { ...state.data, [name]: value } }))
  }
  if (apiData.data === null || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const generateDisabled = status === 'generating'
  const isLoading = status === 'generating'
  const { data } = apiData
  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        Procedure Generate
        <CButton className='ml-2' type='button' color='secondary' onClick={resetFields}>
          Reset
        </CButton>
        <LoadingButton
          className='mr-1 ml-auto'
          style={{ float: 'right' }}
          disabled={generateDisabled}
          loading={isLoading}
          onClick={generateSP}
        >
          Save
        </LoadingButton>
        <CButton
          className='mr-2'
          type='button'
          color='secondary'
          style={{ float: 'right' }}
          onClick={history.goBack}
        >
          Cancel
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CRow>
          <CCol xs='12' md='6'>
            <CFormGroup row>
              <CCol>
                <InputField
                  name='DatabaseName'
                  type='text'
                  label='Database Name'
                  value={data?.DatabaseName ?? ''}
                  onChange={handleChange}
                />
              </CCol>
              <CCol>
                <InputField
                  name='SchemaName'
                  type='text'
                  label='Schema Name'
                  value={data?.SchemaName ?? ''}
                  onChange={handleChange}
                />
              </CCol>
              <CCol>
                <InputField
                  name='TableName'
                  type='text'
                  label='Table Name'
                  value={data?.TableName ?? ''}
                  onChange={handleChange}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs='12'>
                <CheckBoxGroup
                  name='Procs'
                  label='Select Procedures'
                  options={procTypesOptions}
                  onChangeCheckBox={({ name, value }) => {
                    setProcTypesOptions((st: any) => [
                      ...st.map((option: any) => ({
                        ...option,
                        value: name === option.name ? value : option.value,
                      })),
                    ])
                  }}
                />
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol sm='6'>
            <ProcProperties
              procTypesOptions={procTypesOptions}
              setApiData={setApiData}
              apiData={apiData}
            />
          </CCol>
        </CRow>
      </CCardBody>
      <ConfirmRedirectModal data={false} />
    </CCard>
  )
}

export default Generator

// Options Structure
// {
//   label: 'isDeleted',
//   name: 'isDeleted',
//   options: [
//     { name: 'isField', label: 'isField', value: false },
//     { name: 'isParam', label: 'isParam', value: false },
//   ],
// },
// {
//   label: 'changedOn',
//   name: 'changedOn',
//   options: [
//     { name: 'isField', label: 'isField', value: false },
//     { name: 'isParam', label: 'isParam', value: false },
//   ],
// },
// {
//   label: 'changedBy',
//   name: 'changedBy',
//   options: [
//     { name: 'isField', label: 'isField', value: false },
//     { name: 'isParam', label: 'isParam', value: false },
//   ],
// },
// {
//   label: 'changeType',
//   name: 'changeType',
//   options: [
//     { name: 'isField', label: 'isField', value: false },
//     { name: 'isParam', label: 'isParam', value: false },
//   ],
// },
