// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import Address from '../components/Address'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const CustomerInfo = ({ modelData, handleChange, title = 'Model Name' }: PropsInfoForm) => {
  const { CustomerFirstName, CustomerLastName, CustomerMobile, CustomerOtherPhone } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='CustomerFirstName'
            type='text'
            label='First Name'
            value={CustomerFirstName ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='6' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='CustomerLastName'
            type='text'
            label='Last Name'
            value={CustomerLastName ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      {/* <CFormGroup row>
        <CCol>
          <InputField
            name='CustomerLastName'
            type='phone'
            label='Phone'
            value={Phone}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup> */}
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='CustomerMobile'
            type='phone'
            label='Customer Mobile'
            value={CustomerMobile ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='6' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='CustomerOtherPhone'
            type='phone'
            label='Other Phone'
            value={CustomerOtherPhone ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <Address
              modelData={modelData}
              handleChange={handleChange}
                // handlePhoneChange={handlePhoneChange}
                // showVerifyEmailModal={showVerifyEmailModal}
              />
      {/*   <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </FieldsSection>
  )
}

export default CustomerInfo
