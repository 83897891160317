import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsServiceInfo {
  serviceData?: any
  handleSwitchChange?: any
  handleChange?: any
}
const ServicesInfo: React.FC<PropsServiceInfo> = ({
  serviceData,
  handleSwitchChange,
  handleChange,
}) => {
  return (
    <FieldsSection header='Service Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={serviceData.Name}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Description'
            type='text'
            label='Description'
            value={serviceData.Description}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='URL'
            type='text'
            label='URL'
            value={serviceData.URL}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol xs='12'>
          <InputField
            name='Enabled'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Enabled'
          >
            <CustomSwitch
              name='Enabled'
              className='d-block'
              value={serviceData.Enabled}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Version'
            type='text'
            label='Version'
            value={serviceData.Version}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Hits'
            type='text'
            label='Hits'
            value={serviceData.Hits}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Errors'
            type='text'
            label='Errors'
            value={serviceData.Errors}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default ServicesInfo
