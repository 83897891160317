import React from 'react'
// import { FieldsSection } from 'src/shared'
import AddressFinder from 'src/shared/inputFields/AddressFinder'
interface PropsMemberAddress {
  modelData?: any
  handleChange?: any
  handleAddressChange?: any
  modelName?: string
}
const AddressComponent = ({
  modelData,
}: PropsMemberAddress) => {

  const [state, setState] = React.useState({
    Address: modelData.CustomerAddress || '',
    State: modelData.CustomerState || '',
    City: modelData.CustomerCity || '',
    Zip: modelData.CustomerZip || '',
  })

  modelData.CustomerAddress = state.Address
  modelData.CustomerState = state.State
  modelData.CustomerCity = state.City
  modelData.CustomerZip = state.Zip

  // const handleAddressDataChange = ({ value }: any) => {
  //   handleAddressChange(value)
  // }

  return (
    // <FieldsSection header={`${title} Address`}>
      <AddressFinder 
        address={state}
        handleAddressDataChange={(data: any) => {
          console.log(data)
          setState({ ...data.value })
        }}
        handleChange={(data: any) => {
          setState((state) => ({ ...state, Address: data.value }))
        }}
      />
    // </FieldsSection>
  )
}
export default React.memo(AddressComponent)
