import { CButton, CCol, CFormGroup, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CRow } from '@coreui/react';
import axios from 'axios'
import { useEffect, useState } from 'react'
import PeopleIcon from 'src/assets/icons/PeopleIcon';
import { addAuthAccessTokenToHeader, logoutVerifyErrors } from 'src/services/authHelper';
import { BASE_API, DELETE, LIST, MODELS, RETRIEVE, SAVE, SERVICES } from 'src/services/routeConst';
import { FieldsSection, Loader } from 'src/shared';
import WidgetDropdownCustomer from 'src/shared/components/widgets/WidgetDropdownCustomer';
import { CustomerService } from './CustomerService/CustomerService';
import { CustomerServiceEdit } from './CustomerService/CustomerServiceEdit';
import { useActionsEdit } from './CustomerService/hooks/ActionsEdit';
import { useActionsModels } from './CustomerService/hooks/ActionsModels';

interface PropsInfoForm {
  modelData: any,
}

function CustomerServicesInfo({
  modelData,
}: PropsInfoForm) {
  const id = 0 
  const modelName = 'CustomersServices'
  const [apiData, status, loadingData, setLoadingData, saveData, handleChange] = useActionsModels({
    id,
    modelName,
    // history,
  })
  const [apiDataEdit, statusEdit, saveDataEdit, handleChangeEdit] = useActionsEdit({
    id,
    modelName,
    // history,
  })


  const [services, setServices] = useState([])
  const [serviceData, setServiceData] = useState({})
  const [modal, setModal] = useState(false);
  const [modalNoInspector, setModalNoInspector] = useState(false);
  const [inspectorData, setInspectorData] = useState({});
  

  const [customerServiceList, setCustomerServiceList] = useState([])
  const [customerService, setCustomerService] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const handleCustomerService = (CustomerService) => {
    setCustomerService(CustomerService)
  }
  const toggleDelete = () => {
    setDeleteModal(!deleteModal)
  }
  const toggleEdit = async() => {
    await fetchCustomerServiceList()
    await setEditModal(!editModal)
    if(editModal===false) {
      apiDataEdit.modelData = {}
    }
  }


  const toggleNoInspector = () => {
    setModalNoInspector(!modalNoInspector)
  }

  const toggle = async () => {
    await fetchCustomerServiceList()
    setModal(!modal)
    if(modal===false) {
      apiData.modelData = {}
    }
  }
  // const element = <CustomerService serviceData={serviceData} customerData={modelData}  toggle={toggle} />
  
  const fetchServices = async () => {
    try {
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}${LIST}`,
        {
          Model: "Services",
          Params: []
        },
        { ...addAuthAccessTokenToHeader() }
      );
      data.data && setServices(data.data.ListInfo)
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }
   
  const fetchCustomerServiceList = async () => {
    setLoadingData(true)
    try{
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}${LIST}`,
        {
          Model: 'CustomersServices',
          // Id: InspectorsId,
          Params: [
            {
              Name: "CustomersId",
              Value: modelData.Id
            }
          ],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      await setCustomerServiceList(data.data.ListInfo)
      await setLoadingData(false)
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }
   
  useEffect(() => {
    fetchServices()
  },[])
  useEffect(() => {
    fetchCustomerServiceList()
  },[modal,editModal])

  useEffect(() => {
    fetchInspectorData(modelData['InspectorsId'])
  },[modelData['InspectorsId']])

  const fetchInspectorData = async (InspectorsId) => {
    try{
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
        {
          Model: 'Inspectors',
          Id: InspectorsId,
          Params: [],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      
      setInspectorData(data.data.RetrieveInfo[0])
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }
  
 
  const today = () => {
    const date = new window.Date()
    let year = date.getFullYear()
    let month = date.getMonth()+1
    let day = date.getDate()

    return `${year}-${month<10?`0${month}`:`${month}`}-${day<10?`0${day}`:`${day}`}`
  }
  const saveCustomerService = async (Service) => {
    const InspectorsId = modelData.InspectorsId
    const CustomersId = modelData.Id
    const ServicesId = Service.Id
    const Date = today()
    const Name = Service.Name
    const Description = Service.Description
    const Price = Service.Price
    const Cost = Service.CostMeasure === 'Per Hour' ? (inspectorData[0].HourlyRate || 1)*(Service.WorkHours || 1) : Service.Cost
    
    try {
      await axios.patch(
        `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
        {
          Model: "CustomersServices",
          Id: 0,
          Params: [
            {Name: "InspectorsId", Value: InspectorsId},
            {Name: "CustomersId", Value: CustomersId},
            {Name: "ServicesId", Value: ServicesId},
            {Name: "Date", Value: Date},
            {Name: "Name", Value: Name},
            {Name: "Description", Value: Description},
            {Name: "Cost", Value: Cost},
            {Name: "Price", Value: Price},
          ]
        },
        { ...addAuthAccessTokenToHeader() }
      );
      await fetchCustomerServiceList()
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }

  
  const ServiceInput = (Service) => {
    return (
        <div onClick={() => {
          if (modelData.InspectorsId) {
            if(!Service.IsModal) {
              saveCustomerService(Service)
            }else{
              setServiceData(Service)
              toggle();
              // saveCustomerService("found['Id']")
            }
          } else {
            toggleNoInspector()
          }
          }} className='customer-services-card' >
            <div className='customer-services-price'>
              <div className='item-name'>
                {Service['Name']}
              </div>
              <div className='item-price'>
                <i>
                  ${ Service['Price'] ? Service['Price'].toFixed(2) : `0.00`}
                </i>
              </div>
            </div>
          <div className='item-description'>
            {Service['Description']}
          </div>
        </div>
      )

  }


  // fetchCustomerServiceList()

  const deleteCustomerService = async (id) => {
    try{
      await axios.delete(
        `${BASE_API}${SERVICES}${MODELS}/${DELETE}`,
        {
          data:{
            Id: id,
            Model: 'CustomersServices',
            // Extensions: body.Extension ?? null,
            Params: [],
          },
          headers: {
            Authorization: addAuthAccessTokenToHeader().headers.Authorization
          }
        },
      )
      fetchCustomerServiceList()
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }

  const renderAllServices = services.map(( Service ) => {
    return (
      <div key={Service['Id']}>
        {ServiceInput(Service)}
      </div>
    )
  })

  const customerServiceTable = <>
  {/* <FieldsSection header={title}> */}
  <CRow style={{paddingLeft: '1em', paddingRight: '2px', paddingTop: '2px'}} className={'m-0'}>
      <CCol md='4' className={'widget-plr'}>
        <WidgetDropdownCustomer
          color='gradient-primary'
          header={`${customerServiceList[0] ? parseFloat(customerServiceList[0]['TotalAmount']).toFixed(2) : `0.00`}`}
          text='Amount'
          className="brd-rad"
          style={{height:'80px'}}
          icon={<PeopleIcon />}
        >
        </WidgetDropdownCustomer>
      </CCol>

      <CCol md='4' className={'widget-plr'}>
        <WidgetDropdownCustomer
          color='gradient-info'
          header={`${customerServiceList[0] ? parseFloat(customerServiceList[0]['TotalCost']).toFixed(2) : `0.00`}`}
          text='Cost'
          className="brd-rad"
          style={{height:'80px'}}
          icon={<PeopleIcon />}
        >
        </WidgetDropdownCustomer>
      </CCol>

      <CCol md='4' className={'widget-plr'}>
        <WidgetDropdownCustomer
          color='gradient-danger'
          header={`${customerServiceList[0] ? parseFloat(customerServiceList[0]['TotalProfit']).toFixed(2) : `0.00`}`}
          text='Profit'
          className="brd-rad"
          style={{height:'80px'}}
          icon={<PeopleIcon />}
        >
        </WidgetDropdownCustomer>
      </CCol>
    </CRow>
        <div style={{height: '1em'}} />
        <CFormGroup row className={'customer-service-table-formgroup'}>
          <CCol className='customer-service-table-header customer-service-table-ccol'>Date</CCol>
          <CCol md='5' className='customer-service-table-header customer-service-table-ccol'>Description</CCol>
          <CCol className='customer-service-table-header customer-service-table-ccol'>Cost</CCol>
          <CCol className='customer-service-table-header customer-service-table-ccol'>Amount</CCol>
          <CCol md='1' className='customer-service-table-header customer-service-table-ccol'></CCol>
        </CFormGroup>
        {customerServiceList && 
          customerServiceList.map((customerService) => {
            return (
              <CFormGroup row key={customerService['Id']}  className={'customer-service-table-formgroup customer-service-table-formgroup-cursor'}>
                <CCol
                  onClick={()=>{handleCustomerService(customerService);toggleEdit();}}
                  className='customer-service-table-body customer-service-table-ccol'>{customerService['Date']}</CCol>
                <CCol 
                  onClick={()=>{handleCustomerService(customerService);toggleEdit();}}
                  md='5' className='customer-service-table-body customer-service-table-ccol'><b>{customerService['Name']} - </b>{customerService['Description']}</CCol>
                <CCol 
                  onClick={()=>{handleCustomerService(customerService);toggleEdit();}}
                  style={{textAlign: 'right'}} className='customer-service-table-body customer-service-table-ccol'>
                  ${ customerService['Cost'] ? parseFloat(customerService['Cost']).toFixed(2) : `0.00`}
                  {/* ${customerService['Cost']} */}
                </CCol>
                <CCol 
                  onClick={()=>{handleCustomerService(customerService);toggleEdit();}}
                  style={{textAlign: 'right'}} className='customer-service-table-body customer-service-table-ccol'>
                  ${ customerService['Price'] ? parseFloat(customerService['Price']).toFixed(2) : `0.00`}
                  {/* ${customerService['Price']} */}
                </CCol>
                <CCol md='1' style={{textAlign: 'center', paddingRight: 0, paddingLeft: 0}} className='customer-service-table-body customer-service-table-ccol'>
                  <button className='btn btn-danger' onClick={()=>{handleCustomerService(customerService);toggleDelete();}}>
                    x
                  </button>
                  {/* <img
                    style={{cursor: 'pointer'}}
                    src='./delete-mark.png'
                    alt=''
                    width={12}
                    height={12}
                    onClick={()=>{handleCustomerService(customerService);toggleDelete();}}
                  /> */}
                </CCol>
              </CFormGroup>)
          })
        }
      {/* </FieldsSection> */}
  
  <CModal 
    show={deleteModal}
    onClose={toggleDelete} 
    color='danger'>
    <CModalHeader closeButton>
      <CModalTitle>
        Delete Customer Service
      </CModalTitle>
    </CModalHeader>
    <CModalBody>
      <p style={{color: '#000'}}>
        Are you sure you want to remove selected item?
      </p>
    </CModalBody>
    <CModalFooter>
      <CButton color='danger' onClick={()=>{deleteCustomerService(customerService['Id']);toggleDelete()}}>
        Delete
      </CButton>
      <CButton color='secondary' onClick={() => setDeleteModal(false)}>
        Cancel
      </CButton>
    </CModalFooter>
  </CModal>

  </>
  return (
    <>
      {
      loadingData
      ? <Loader height='75vh' />
      : <CFormGroup row>
          <CCol md='4' className='pr-0'>
            <FieldsSection header={'Service Packages'}>
              <CFormGroup row>
                <CCol md='12' className='p-0'>
                  {renderAllServices}
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>

          <CCol md='8' className='pl-0'>
            {customerServiceTable}
          </CCol>
        </CFormGroup>

      }
      
      <CustomerService  serviceData={serviceData} customerData={modelData} modal={modal}  toggle={toggle} apiData={apiData} status={status} saveData={saveData} handleChange={handleChange} inspectorData={inspectorData} fetchCustomerServicesList={fetchCustomerServiceList} />
      <CustomerServiceEdit editModal={editModal}  toggleEdit={toggleEdit} apiData={apiDataEdit} status={statusEdit} saveData={saveDataEdit} handleChange={handleChangeEdit} inspectorData={inspectorData} fetchCustomerServicesList={fetchCustomerServiceList} customerService={customerService} />
      
      <CModal
        show={modalNoInspector}
        onClose={toggleNoInspector}
      >      
      <CModalHeader>
        Unassigned inspector
      </CModalHeader>
        <CModalBody>
          <p style={{color: 'black'}}>
            Please, select Inspector
          </p>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            onClick={toggleNoInspector}
          >Back</CButton>
        </CModalFooter>
      </CModal>

    </>
  )
}

export default CustomerServicesInfo