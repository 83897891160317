import HeaderComponent from './Header'
import InspectorInfo from './InspectorInfo'
import PickUpInfo from './PickupInfo'
import DropOffInfo from './DropOffInfo'
import TabsInformation from './TabsInformation'
import TabsCustomerInformation from './TabsCustomerInformation'
import OwnershipInfo from './OwnershipInfo'
import TitleInfo from './TitleInfo'
// import VehicleInf from './VehicleInfo'
import AppointmentInfo from './AppointmentInfo'
import VehicleCustomerInfo from './VehicleCustomerInfo'
import { ModalNewCustomer } from './ModalNewCustomer/ModalNewCustomer'
import Header from './Header'
export {
  HeaderComponent,
  InspectorInfo,
  PickUpInfo,
  DropOffInfo,
  TabsInformation,
  TabsCustomerInformation,
  OwnershipInfo,
  TitleInfo,
  // VehicleInf,
  AppointmentInfo,
  ModalNewCustomer,
  Header,
  VehicleCustomerInfo
}
