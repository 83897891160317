import React, { useContext } from 'react'
import { CTabPane } from '@coreui/react'

// import { FieldsSection } from 'src/shared'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
// import SearchTab from './CustomersServices/Search'
import { CustomerContext } from '../Detail'

import NotesInfo from './NotesInfo'
import InspectorAssignedCard from './InspectorAssignedCard'
import StatusHistoryInfo from './StatusHistoryInfo'
interface PropsCustomersServices {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  modelData?: any
  Guid?: any
}
const CustomersServices: React.FC<PropsCustomersServices> = ({
  id,
  modelName,
  changesRef,
  Guid,
  modelData,
}) => {
  const { MetasId } = useContext(CustomerContext)

  return (
    <>
      <InspectorAssignedCard 
        modelData={modelData}
        // handleChange={handleChange}
      />
      <DetailTabSection
        id={id}
        metasId={MetasId}
        Guio={Guid}
        headerStyle= {{padding: '0px', marginTop: '-5px'}}
        modelName={modelName}
        hideNotes
        tabs={[
          { name: 'Notes', label: 'Notes' },
          { name: 'StatusHistory', label: 'Status History' }
        ]}
        changesRef={changesRef}
      >
        <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
          {modelData.Id !== 0 ? (
            <>
              <NotesInfo title={'Notes'} id={id} MetasId={modelData.MetasId} modelData={modelData} />
            </>
          ) : (
            <></>
          )}
        </CTabPane>
        <CTabPane data-tab='StatusHistory' className='detail-tab-section-pane'>
          {modelData.Id !== 0 ? (
            <>
              <StatusHistoryInfo MetasId={modelData.MetasId} />
            </>
          ) : (
            <></>
          )}
        </CTabPane>
      </DetailTabSection>
    </>
  )
}

export default CustomersServices
