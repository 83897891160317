import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import React from 'react'
import { DeleteMember } from 'src/services/modelNew/Tokens/Member/DeleteMember'
interface ModalData {
  modelName: any
  label: any
  id: any
}
interface PropsDeleteModal {
  showModal: boolean
  setShowModal: any
  modalData: ModalData
  callBack: any
}
export const DeleteModal: React.FC<PropsDeleteModal> = ({
  showModal = false,
  setShowModal,
  modalData = { modelName: '', label: '', id: -1 },
  callBack = () => {},
}: PropsDeleteModal) => {
  const { label, id } = modalData
  console.log(modalData)
  const deleteData = async (id: any) => {
    try {
      console.log(id)
      await DeleteMember({
        params: {
          MembersId: id,
        },
      })
    } catch (error: any) {
      console.error(error)
    }
    callBack()
  }

  const handleDelete = () => {
    deleteData(id)
    setShowModal(false)
  }

  return (
    <CModal show={showModal} onClose={setShowModal} color='danger'>
      <CModalHeader closeButton>
        <CModalTitle>
          Delete {label} {id}?
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        Are you sure you want to delete{label !== '' ? ` this ${label}` : ''}?
      </CModalBody>
      <CModalFooter>
        <CButton color='danger' onClick={handleDelete}>
          Delete
        </CButton>
        <CButton color='secondary' onClick={() => setShowModal(false)}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}
