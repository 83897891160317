import { useCallback, useEffect, useMemo, useState } from 'react'
import { StatusInfo } from '../enum/Status'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'
import { logoutVerifyErrors } from 'src/services/authHelper'

interface PropsActionsModels {
  id: any
  modelName: any
  history: any
  modelsId?: string
}
type ApiDataState = { modelData: any }
const fields: string[] = [
  'Id',
  'Name',
  'Address',
  'City',
  'State',
  'Zip',
  ]
export const useActionsModels = ({
  id,
  modelName,
  modelsId = 'Id',
  history,
}: PropsActionsModels): [ApiDataState, StatusInfo, () => Promise<void>, Function, Function] => {
  const [apiData, setApiData] = useState<ApiDataState>({ modelData: null })
  const [status, setStatus] = useState<StatusInfo>(StatusInfo.loading)
  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback<any>(async () => {
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [modelsId]: 0 },
      })
      
      const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
      setApiData({ modelData: dataResponse })
      setStatus(StatusInfo.idle)
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }, [rootRepository, id, modelName, modelsId])

  useEffect(() => {
    getData()
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ modelData: null })
      history.goBack()
    }, delay)
  }
  const mappingFields = (apiData) => {
    const dataToSave: any = apiData.modelData
    return {
      ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
    }
  }
  const saveData = async () => {
    setStatus(StatusInfo.saving)
    try {
      const { message } = await SaveModel({
        body: {
          Model: modelName,
          Params: mappingFields(apiData),
        },
      })
      toast.success(message)
      getData()
      goBack()
      setStatus(StatusInfo.idle)
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }
  const handleAddressChange = ({
    Address = '',
    Address2 = '',
    City = '',
    State = '',
    ZipCode = '',
  }) => {
    // console.log({ Address, Address2, City, State, ZipCode })
    setApiData((st: any) => ({
      ...st,
      modelData: { ...st.modelData, Address, Address2, City, State, ZipCode },
    }))
  }
  return [apiData, status, saveData, handleChange, handleAddressChange]
}
