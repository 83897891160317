import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import React from 'react'

interface PropsFieldsSection {
  header?: React.ReactNode
  children: React.ReactNode
  text?: React.ReactNode
}
const FieldsSection: React.FC<PropsFieldsSection> = ({ header = '', children, text }) => {
  return (
    <CCard className='border-transparent mb-2'>
      <CCardHeader
        className='d-flex align-items-center'
        style={{ backgroundColor: '#3C4B64', color: 'white' }}
      >
        <div style={{width: '70%'}}>
          <b>{header}</b>
        </div>
        <div style={{fontWeight: 'bold', width: '30%',textAlign:'right'}}>
          {text}
        </div>
      </CCardHeader>
      <CCardBody className='pb-0 pt-3 px-2 px-lg-3 '>{children}</CCardBody>
    </CCard>
  )
}

export default FieldsSection
