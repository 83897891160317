import React, { useEffect, useMemo, useRef, useState } from 'react'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import './Cropper.css'
import { CModal, CModalBody, CModalHeader, CButton } from '@coreui/react'
import CIcon from '@coreui/icons-react'
import defaultImage from 'src/assets/img/default-image.jpg'
import { toast } from 'react-toastify'
import { ConvertBlob, ValidateFile, getRoundedCanvas } from './helpers'
import { DownloadFile } from 'src/services/modelNew/Widget/File/Download'
import imageCompression from 'browser-image-compression'
interface PropsCropperComponent {
  cropShape?: string
  onChangeCropper: ({
    originalFile,
    fileCropped,
    fileCroppedBase64,
  }: {
    originalFile?: any
    fileCropped?: any
    fileCroppedBase64?: any
  }) => void
  maxSizeMib?: number
  guid: any
}
export const CropperComponent: React.FC<PropsCropperComponent> = ({
  cropShape = 'rect',
  onChangeCropper,
  maxSizeMib = 2,
  guid,
}) => {
  const [image, setImage] = useState<any>()
  const [cropData, setCropData] = useState<any>(defaultImage)
  const [cropper, setCropper] = useState<any>()
  const [showModal, setShowModal] = useState<any>(false)
  // const [zoom, setZoom] = useState(0)
  const refShape = useRef<any>()
  const fileRef = useRef()
  const refInputImageUpload = useRef<any>()
  const setStyle: any = useMemo(
    () => ({
      round: () => {
        refShape?.current?.style.setProperty('--border-radius-cropper-component', '50%')
      },
      rect: () => {
        refShape?.current?.style.setProperty('--border-radius-cropper-component', '0%')
      },
    }),
    [],
  )
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const { data } = await DownloadFile({ guid: guid })
        data && setCropData(data)
        data && setImage(data)
      } catch (error: any) {}
    }
    const putCrapperStyle = setStyle[cropShape]
    putCrapperStyle()
    fetchFiles()
  }, [cropShape, guid, setStyle])
  const onChangeSelectFile = async (e: any) => {
    // console.log(e)

    try {
      const [file] = e.target.files

      const compressedFile = await imageCompression(file, {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
        maxIteration: 20,
      })
      const { file: fileImg } = new ValidateFile({ file: compressedFile, maxSize: maxSizeMib })
      fileRef.current = fileImg
      const imageSource = new ConvertBlob({ file: fileImg })

      const objectUrl = await imageSource.getObjectUrl()

      setImage(objectUrl)
      setShowModal(true)

      setTimeout(() => {
        console.log(showModal)
      }, 2000)
    } catch (error: any) {
      toast.error(error.message)
      setShowModal(false)
    }
  }
  const b64toBlob = async (dataURI) => {
    const base64file = await fetch(dataURI)
    const blobFile = await base64file.blob()
    return blobFile
  }
  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      let cropperCanvas = cropper.getCroppedCanvas()
      // only rounded data
      if (cropShape === 'round') {
        cropperCanvas = getRoundedCanvas(cropperCanvas)
      }
      setCropData(cropperCanvas.toDataURL())
      const fileCropped = await b64toBlob(cropperCanvas.toDataURL())
      console.log(fileCropped, 'fileCropped')
      onChangeCropper({
        fileCropped,
        originalFile: fileRef.current,
        fileCroppedBase64: cropperCanvas.toDataURL(),
      })
      setShowModal(false)
    }
  }
  const openModal = () => {
    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    })
    refInputImageUpload.current.dispatchEvent(clickEvent)
  }

  return (
    <div ref={refShape}>
      <div className='container'>
        <div className='avatar-upload'>
          <div className='avatar-edit'>
            <input
              type='file'
              ref={refInputImageUpload}
              onChange={onChangeSelectFile}
              id='imageUpload'
              accept='.png, .jpg, .jpeg, .heic'
            />
            <label onClick={openModal}>
              <CIcon style={{ marginTop: '7px' }} name='cil-pencil' />
            </label>
          </div>
          <div className='avatar-preview' onClick={openModal}>
            <div id='imagePreview' style={{ backgroundImage: `url(${cropData})` }}></div>
          </div>
        </div>
      </div>
      <CModal show={showModal} onClose={() => setShowModal((modal: any) => !modal)} size='xl'>
        <CModalHeader closeButton>Modal title</CModalHeader>
        <CModalBody>
          <div style={{ width: '100%' }}>
            <Cropper
              zoomTo={-1}
              dragMode='crop'
              data={{
                width: 500,
                height: 500,
              }}
              background={false}
              initialAspectRatio={1}
              aspectRatio={1}
              zoomable={false}
              /* cropBoxMovable={false} */
              cropBoxResizable={true}
              minCropBoxHeight={50}
              minCropBoxWidth={50}
              src={image}
              responsive={true}
              onInitialized={(instance) => {
                setCropper(instance)
              }}
              // crossOrigin='anonymous'
              checkCrossOrigin={false}
            />
          </div>
          <div>
            <div className='box'>
              <CButton style={{ float: 'right' }} color='primary' onClick={getCropData}>
                Crop Image
              </CButton>
            </div>
          </div>
          <br style={{ clear: 'both' }} />
        </CModalBody>
      </CModal>
    </div>
  )
}

export default CropperComponent
