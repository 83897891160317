// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const MechanicalIssuesInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const {
    MechEngine,
    MechTransmission,
    MechAir,
    MechHeater,
    MechPowerLiftgate,
    MechPowerSlidingdoors,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            label='Issues Engine'
            value={MechEngine? MechEngine.toString() :'False'}
          />
          <InputLabel 
            label='Issues Transmission'
            value={MechTransmission? MechTransmission.toString() :'False'}
          />
          <InputLabel 
            label='Issues Aircon'
            value={MechAir? MechAir.toString() :'False'}
          />
          <InputLabel 
            label='Issues Heater'
            value={MechHeater? MechHeater.toString() :'False'}
          />
          <InputLabel 
            label='Issues Power Liftgate'
            value={MechPowerLiftgate? MechPowerLiftgate.toString() :'False'}
          />
          <InputLabel 
            label='Issues Power Slidingdoors'
            value={MechPowerSlidingdoors? MechPowerSlidingdoors.toString() :'False'}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default MechanicalIssuesInfo
