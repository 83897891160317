import { CSpinner } from '@coreui/react'
import React from 'react'
import VitalSpinner from './VitalSpinner'
interface PropsLoader {
  color?: string
  variant?: string
  rootStyles?: any
  style?: any
  size?: any
  fullScreen?: boolean
  height?: any
}
const Loader: React.FC<PropsLoader> = ({
  color = 'info',
  variant = '',
  rootStyles = {},
  style = {},
  size = 'md',
  fullScreen = false,
  height,
}: PropsLoader) => {
  const CSpinnerTs: any = CSpinner
  return fullScreen ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        height: height ? height : '100vh',
      }}
    >
      <VitalSpinner />
    </div>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...rootStyles,
      }}
    >
      <CSpinnerTs color={color} variant={variant} style={style} size={size} />
    </div>
  )
}

export default Loader
