// Generated with util/create-search-page.js
import { CButton, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import React, { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { SmsTableColumns } from './SmsTableColumns'
import { BASE_API, MODELS, SERVICES, LIST, NO_CALL_MODEL } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'
import SmsDetails from "./Detail";
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '600px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const SmsSearch = ({ history,match, modelName = NO_CALL_MODEL }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)

  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  function openModal() {
    setIsOpen(true);
  }
  
  function afterOpenModal() {
    // references are now sync'd and can be accessed.
  //  subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
    tableRef.current.fetchNewData();
  }

  const viewDetails = ({ id }) => {
    routeToPage(history, '/Admin/Sms/Detail/' + id)
  }

  const showDeleteModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <>
      <CCardHeader
        className='d-flex align-items-center card-accent-secondary'
        style={{ backgroundColor: '#2A3446', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}
      >
        <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Icon Modal"
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        {/* <button onClick={closeModal}>close</button> */}
        <SmsDetails match={match} closeModal={closeModal}/>
        
      </Modal>
        <span style={{ color: 'white' }}>{modelName} Search</span>
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          //onClick={() => routeToPage(history, '/Admin/Sms/New')}
          onClick={() => openModal()}
        >
          New Sms
        </CButton>
      </CCardHeader>
      <CCardBody className={'custom-card-body'}>
        <DataTable
          columnData={SmsTableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={modelName}
          scopedSlots={{
            PhoneNo: ({ value }) => {
              return <div>{value}</div>
            },
            TimeStamp: ({ value }) => {
              return <div>{value}</div>
            }
          }}
          actionClick={(type, data) => {
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          paginationPosition='bottom-right'
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Sms', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </>
  )
}

export default SmsSearch
