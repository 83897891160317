// Generated with util/create-search-page.js
import { CButton, CCardHeader } from '@coreui/react'
import { useState } from 'react'
import { PdfViewer } from 'src/shared'

import LoadingButton from 'src/shared/components/LoadingButton'
import CustomDateField from 'src/shared/inputFields/CustomDateField'
interface PropsHeaderComponent {
  onCancelHandle: any
  status: any
  saveData: any
  modelName: any
  modelData: any
  handleChange: any
}
const HeaderComponent = ({
  onCancelHandle,
  status,
  saveData,
  modelData,
  handleChange
}: // modelName = 'Model Name',
PropsHeaderComponent) => {
  const isLoading = status === 'saving'
  // const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g).join(' ')

  const [isFollow, setIsFollow] = useState(modelData.IsNeedFollowUp)

  let {FollowUpDate} = modelData

  const formatDate = (date) => {
    if(date) {
      date = date.split('-')
      const day = String(date[0])
      const month = String(date[1])
      const year = String(date[2])
      if(day.length===2){
        return `${year}-${day}-${month}`
      }
      return `${day}-${month}-${year}`
    }
    return ''
  }
  const today = () => {
    const date = new window.Date()
    let year = date.getFullYear()
    let month = date.getMonth()+1
    let day = date.getDate()

    return `${year}-${month<10?`0${month}`:`${month}`}-${day<10?`0${day}`:`${day}`}`
  }
  FollowUpDate = formatDate(FollowUpDate)

  const changeFollow = () => {
    if(modelData.IsNeedFollowUp){
      modelData.IsNeedFollowUp = false
      setIsFollow(false)
      FollowUpDate = formatDate(modelData.FollowUpDate)
    }else {
      modelData.IsNeedFollowUp = true
      FollowUpDate = today()
      modelData.FollowUpDate = today()
      setIsFollow(true)
    }
  }
  if(!FollowUpDate){
    FollowUpDate = today()
    modelData.FollowUpDate = today()
  }
  const checkIsFollow = <div style={{display: 'flex', alignItems:'center'}}>
    <div style={{color: '#fff', fontSize:'12px', marginRight:'10px'}}>Follow Up</div>
    <div onClick={()=>changeFollow()}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent:'center'}} className='header-input-check header-input-check-isFollow'>
        <img style={{width: '15px'}} src="./check-mark.png" alt="" />
      </div>
    </div>
      <CustomDateField
        className='header-input-date'
        name='FollowUpDate'
        type='date'
        value={FollowUpDate ?? ''}
        onChange={handleChange}
      />
    </div>
  const checkIsNotFollow = <div style={{display: 'flex', alignItems:'center'}}>
    <div style={{color: '#fff', fontSize:'12px', marginRight:'10px'}}>Follow Up</div>
    <div onClick={()=>changeFollow()}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent:'center'}} className='header-input-check header-input-check-noFollow'>
      </div>
    </div>
  </div>

  return (
    <CCardHeader
      className='d-flex align-items-center'
      style={{
        backgroundColor: '#2A3446',
        marginBottom: '0',
        fontSize: 16,
        fontWeight: 600,
        borderRadius: 0,
      }}
    >
      {/* {title} Details */}
      <span style={{ color: 'white' }}> Dispatch Request</span>



      
      {/* <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
        // onClick={saveDataVehicle}
      >
        Save
      </LoadingButton> */}
      <div
        className='mr-1 ml-auto'
      >
        {isFollow
        ? checkIsFollow
        : checkIsNotFollow
        }
      </div>
      <LoadingButton
        className='mr-1'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
        // onClick={saveDataVehicle}
      >
        Save
      </LoadingButton>
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancelHandle}
      >
        Close
      </CButton>
      <PdfViewer />
    </CCardHeader>
  )
}

export default HeaderComponent
