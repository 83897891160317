// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  modelName: string
}

const InfoForm = ({ modelData, handleChange, modelName = 'Model Name' }: PropsInfoForm) => {
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g)!.join(' ')
  console.log(modelData)
  const {
    ActivatedOn,
    FirstName,
    LastName,
    Nickname,
    Phone,
    Email,
    IsNew,
    IsAccountLocked,
    IsLockedPolicy,
    IsActive,
  } = modelData
  return (
    <FieldsSection header={`${title} Info`}>
      <CFormGroup row>
        <CCol>
          <InputField
            name='FirstName'
            type='text'
            label='First Name'
            value={FirstName}
            onChange={handleChange}
          />
        </CCol>
        <CCol>
          <InputField
            name='LastName'
            type='text'
            label='Last Name'
            value={LastName}
            onChange={handleChange}
          />
        </CCol>
        <CCol>
          <InputField
            name='Nickname'
            type='text'
            label='Nickname'
            value={Nickname}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol>
          <InputField
            name='Phone'
            type='phone'
            label='Phone'
            value={Phone}
            onChange={handleChange}
          />
        </CCol>
        <CCol>
          <InputField
            name='Email'
            type='email'
            label='Email'
            value={Email}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol>
          <InputField
            name='IsNew'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Is New'
          >
            <CustomSwitch
              name='IsNew'
              className='d-block'
              value={IsNew}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
        <CCol>
          <InputField
            name='IsAccountLocked'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Is Account Locked'
          >
            <CustomSwitch
              name='IsAccountLocked'
              className='d-block'
              value={IsAccountLocked}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
        <CCol>
          <InputField
            name='IsLockedPolicy'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Is Locked Policy'
          >
            <CustomSwitch
              name='IsLockedPolicy'
              className='d-block'
              value={IsLockedPolicy}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
        <CCol>
          <InputField
            name='IsActive'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Is Active'
          >
            <CustomSwitch
              name='IsActive'
              className='d-block'
              value={IsActive}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <InputField
            disabled
            name='ActivatedOn'
            type='text'
            label='Activated On'
            value={ActivatedOn}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default InfoForm
