import CIcon from '@coreui/icons-react'
import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
// import ReactDOM from 'react-dom'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useGeolocation } from 'src/hooks/useGeolocation'
// import Gmap from './Gmap'
import InputField from './InputField'

export interface AddressData {
  Address: string
  City: string
  State: string
  Zip: string
}
interface PropsAddressFinderAux {
  address?: AddressData
  modelData?: any
  handleChange?: any
  handleAddressDataChange?: any
  name?: string
  modelName?: string
}

const AddressFinderAux: React.FC<PropsAddressFinderAux> = ({
  address = { Address: '', City: '', State: '', Zip: '' },
  handleChange,
  handleAddressDataChange,
  name = '',
  // modelName = ''
}) => {
  const btnRef = React.useRef<any>()
  const [locationData, getLocationData] = useGeolocation()

  // const [latitude, setLatitude] = React.useState(2)

  React.useEffect(() => {
    locationData && console.log(locationData)
  }, [locationData])

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      handleContentChange(place)

      // function viewMap() {
      //   let element = (
      //     <>
      //       {modelName ? <Gmap modelName={modelName} Lat={place.geometry?.location?.lat()} Lng={place.geometry?.location?.lng()} /> : <></>}
      //     </>
      //   )
      //   if (modelName){
      //     ReactDOM.render(element, document.getElementById(modelName))
      //   }
      // }
      // setTimeout(viewMap, 1000)
    },
    options: { types: ['geocode'] },
  })

  const handleContentChange = (data: any) => {
    const { address_components = [] } = data
    const components: any = {}
    address_components.forEach(({ types, short_name, long_name }: any) => {
      types.forEach((type: any) => {
        components[type] = ['route', 'administrative_area_level_1'].includes(type)
          ? short_name
          : long_name
      })
    })
    const {
      premise = '',
      street_number = '',
      route = '',
      postal_code = '',
      neighborhood = '',
      locality = '',
      administrative_area_level_1 = '',
    } = components
    const streetAddress = street_number
      ? `${street_number} ${route}`
      : route
      ? route
      : premise
      ? premise
      : ''
    handleAddressDataChange({
      name,
      value: {
        Address: streetAddress.length === 0 ? Address : streetAddress,
        City: neighborhood ? neighborhood : locality,
        State: administrative_area_level_1,
        Zip: postal_code,
      },
    })
  }

  const renderLocationButton = () => {
    return (
      <div>
        <small
          ref={btnRef}
          className={`input-field-button`}
          onClick={() => {
            getLocationData()
          }}
        >
          <CIcon name='cil-location-pin' />
        </small>
      </div>
    )
  }
  const { Address, City, State, Zip } = address

  return (
    <>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2'>
          <InputField
            innerRef={ref}
            name={`Address`}
            label={name ? `${name} Address` : 'Address'}
            type='text'
            value={Address}
            onChange={handleChange}
            showButton
            ButtonComponent={renderLocationButton}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name={`City`}
            type='text'
            label={name ? `${name} City` : 'City'}
            value={City}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='3' className='pr-lg-0 pl-lg-0 mb-1'>
          <InputField
            name={`State`}
            type='text'
            label={name ? `${name} State` : 'State'}
            value={State}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='3' className='pr-lg-2 pl-lg-2'>
          <InputField
            name={`Zip`}
            type='text'
            label={name ? `${name} Zipcode` : 'Zipcode'}
            value={Zip}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      {/* <CFormGroup>
        <div id={modelName}></div>
      </CFormGroup> */}
    </>
  )
}

export default AddressFinderAux
