// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import ApplicantsAddress from './ApplicantsAddress'
interface PropsApplicantsInfo {
  modelData?: any
  handleChange?: any
  handleAddressChange?: any
}
const ApplicantsInfo: React.FC<PropsApplicantsInfo> = ({
  modelData,
  handleChange,
  handleAddressChange,
}) => {
  const { FirstName, LastName, Email, Phone, DOB, SSN } = modelData

  return (
    <>
      <CCol xs='12' md='6'>
        <FieldsSection header='Applicant Info'>
          {/* <CFormGroup row>
            <CCol xs='12' sm='12'>
              <InputField
                name='PlaidAccessToken'
                type='text'
                label='PlaidAccessToken'
                value={PlaidAccessToken}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup> */}
          <CFormGroup row>
            <CCol xs='12' md='6'>
              <InputField
                name='FirstName'
                type='text'
                label='FirstName'
                value={FirstName}
                onChange={handleChange}
              />
            </CCol>
            <CCol xs='12' md='6'>
              <InputField
                name='LastName'
                type='text'
                label='LastName'
                value={LastName}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup>

          <CFormGroup row>
            <CCol xs='12' sm='12'>
              <InputField
                name='Email'
                type='text'
                label='Email'
                value={Email}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs='12' sm='12'>
              <InputField
                name='Phone'
                type='text'
                label='Phone'
                value={Phone}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs='12' sm='12' md='6'>
              <InputField name='DOB' type='text' label='DOB' value={DOB} onChange={handleChange} />
            </CCol>
            <CCol xs='12' sm='12' md='6'>
              <InputField name='SSN' type='text' label='SSN' value={SSN} onChange={handleChange} />
            </CCol>
          </CFormGroup>
          {/* <CFormGroup row>
            <CCol xs='12' sm='12'>
          <InputField
            name='Address'
            type='text'
            label='Address'
            value={Address}
            onChange={handleChange}
          />
        </CCol>
          </CFormGroup> */}
          {/* <CFormGroup row>
            <CCol xs='12' sm='12'>
              <InputField
                name='Address2'
                type='text'
                label='Address2'
                value={Address2}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup> */}
          {/*  <CFormGroup row>
            <CCol xs='12' sm='12'>
              <InputField
                name='City'
                type='text'
                label='City'
                value={City}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs='12' sm='12'>
              <InputField
                name='State'
                type='text'
                label='State'
                value={State}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs='12' sm='12'>
              <InputField
                name='ZipCode'
                type='text'
                label='ZipCode'
                value={ZipCode}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup> */}
        </FieldsSection>
      </CCol>
      <CCol xs='12' md='6'>
        <ApplicantsAddress
          handleAddressChange={handleAddressChange}
          handleChange={handleAddressChange}
          memberData={modelData}
        ></ApplicantsAddress>
      </CCol>
    </>
  )
}

export default ApplicantsInfo
