import { CCol, CFormGroup } from '@coreui/react'
import { InputField } from 'src/shared'
interface PropsRolesInfo {
  roleData?: any
  handleChange?: any
}
const RolesInfo: React.FC<PropsRolesInfo> = ({ roleData, handleChange }) => {
  return (
    <>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={roleData.Name}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Description'
            type='textarea'
            label='Description'
            value={roleData.Description}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </>
  )
}

export default RolesInfo
