import React, { useEffect, useState } from 'react'

// import CIcon from '@coreui/icons-react';
import axios from 'axios'
import { connect } from 'react-redux'
import { addAuthAccessTokenToHeader, logoutVerifyErrors } from 'src/services/authHelper'
import { BASE_API, LIST, MODELS, SERVICES } from 'src/services/routeConst'
import { useHistory } from "react-router-dom";
import { QuantumDashboard } from './components/QuantumDashboard'
import { DispatchDashboard } from './components/DispatchDashboard';
//import { DefaultDashboard } from './components/DefaultDashboard';
import { JemDashboard } from './components/JemDashboard';
import { CampaignDashboard } from './components/CampaignDashboard';

// import { SearchModel } from 'src/services/modelNew/Search/Search'
// import { routeToPage } from 'src/services/routingHelper'
// import { APPLICANTS_MODEL, BANKS_MODEL, MEMBERS_MODEL } from 'src/services/routeConst'
// const useGetMenuLogs = () => {
//   const [state, setstate] = useState([])
//   const getMenusLogs = useCallback(async () => {
//     const { data } = await SearchModel({
//       modelName: 'Menus',
//       data: {
//         MenusId_Parent: '43',
//       },
//     })
//     setstate(data)
//   }, [])
//   useEffect(() => {
//     getMenusLogs()
//   }, [getMenusLogs])
//   return [state]
// }
// const useGetRecordCount = (models): any[] => {
//   const [state, setstate] = useState([])
//   const getMenusLogs = useCallback(async () => {
//     const { data } = await SearchModel({
//       modelName: models,
//       data: {
//         PageSize: '1',
//       },
//     })
//     setstate(data[0])
//   }, [models])
//   useEffect(() => {
//     getMenusLogs()
//   }, [getMenusLogs])
//   return [state]
// }
const Dashboard: React.FC<any> = (props: any) => {
  const { applicationRetrieve = {}, loggedInUser = {} } = props
  let titleFinal = applicationRetrieve.Title != undefined ? applicationRetrieve.Title : "Admin Dashboard";

  useEffect(() => {
    document.title = titleFinal
  }, [])

  const { Name, Id } = applicationRetrieve

  // const [menuLogs] = useGetMenuLogs()
  // const [membersCount] = useGetRecordCount(MEMBERS_MODEL)
  // const [banksCount] = useGetRecordCount(BANKS_MODEL)
  // const [applicantsCount] = useGetRecordCount(APPLICANTS_MODEL)

  /* let membersToday: any = -25
  membersToday = `${membersToday > 0 ? '+' : ''}${membersToday}`
  let membersToday2 = 25
  membersToday = `${membersToday > 0 ? '+' : ''}${membersToday}`
  let membersToday3 = 25
  membersToday = `${membersToday > 0 ? '+' : ''}${membersToday}`
  let membersToday4 = 100
  membersToday = `${membersToday > 0 ? '+' : ''}${membersToday}` */

  const [cardData, setCardData] = useState({ Dispatches: 0, Inspectors: 0, Revenue: 0, Inspections: 0 })
  const [leadsList, setLeadsList] = useState([])

  const [leadsData, setLeadsData] = useState([])
  const [customersData, setCustomersData] = useState([])

  const [appName, setAppName] = useState('')

  //let location = window.location.href;
  // let location = 'https://jemadmin.aaadev.info/';
  // let location = 'https://jemadmin.ss'
  // let location = 'https://quantumadmin.aaadev.info'
  // let location = 'dispatchadmin.aaadev.info'
  const fetchAppData = async () => {
    try {
      // const {data} = await axios.post(
      //   `${BASE_API}${SERVICES}/data/execute`,
      //   {
      //     // [idName]: Id,
      //     DbName: 'DbAdmin',
      //     QueryOrProcedureName: "access.APPLICATIONS_Retrieve",
      //     // Id: id,
      //     // Extensions: body.Extension ?? null,
      //     Params: [
      //       {
      //         Name: "UrlRoot", Value: location
      //       }
      //     ],
      //   },
      //   { ...addAuthAccessTokenToHeader() },
      // )
      // console.log("in dash");
      // console.log("DATA EXECUTE", data);
      await setAppName(Name)
      //console.log("Name");
      //console.log(Name);

      fetchCardData(Name, Id)
      appName == 'quantumauto' && fetchCustomersData(Name)
    } catch (error: any) {
      const status = error.response.status
      logoutVerifyErrors(status)
    }
  }
  useEffect(() => {
    fetchAppData()
  }, [])
  const fetchCardData = async (siteName: string, Id: number = 0) => {
    try {

      let params = siteName == 'jemadmin' || siteName == 'localhost' ? [{ Name: "UsersId", Value: loggedInUser.Auth.User.Id }] : [{}];
      var procedureName = "access.APPLICATIONS_Dashboard";
      if (siteName == 'jemadmin' || siteName == 'localhost') {
        procedureName = "access.APPLICATIONS_DashboardJemcare";
      } else if (siteName == 'smsadmin') {
        procedureName = "access.APPLICATIONS_DashboardSms";
      }
      console.log(Id);

      const response = await axios.post(
        `https://api.softsmartinc.com/Services/vOld/Data/Execute`,
        {
          "DbName": "DbAdmin",
          "QueryOrProcedureName": procedureName,//(siteName != 'jemadmin') ? (siteName == 'smsadmin') ? "access.APPLICATIONS_DashboardSms" : "access.APPLICATIONS_Dashboard" : "access.APPLICATIONS_DashboardJemcare",
          "Params": params
        },
        { ...addAuthAccessTokenToHeader() },
      )

      setCardData(response.data.data.Execution[0])

      if (siteName == 'jemadmin' || siteName == 'localhost') {
        const response = await axios.post(
          `https://api.softsmartinc.com/Services/vOld/Data/Execute`,
          {
            "DbName": "DbOltp",
            "QueryOrProcedureName": 'jemcare.LEADS_ListByGroup',
            "Params": params
          },
          { ...addAuthAccessTokenToHeader() },
        )

        setLeadsList(response.data.data.Execution)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const fetchCustomersData = async (siteName: string) => {
    try {
      const response = await axios.post(
        `https://api.softsmartinc.com/Services/vOld/Data/Execute`,
        // `${BASE_API}${SERVICES}${MODELS}${LIST}`,
        {
          DbName: "DbOltp",
          QueryOrProcedureName: "quantum.LIST_ListNeedFollowUp",
          Params: []
        },
        { ...addAuthAccessTokenToHeader() },
      )
      setLeadsData(response.data.data.Execution)
    } catch (error) {
      console.log(error)
    }

    try {
      const responseCustomer = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}${LIST}`,
        {
          Model: (siteName != 'jemadmin' && siteName != 'localhost') ? "customers" : "JemcareLeads",
          Extension: (siteName != 'jemadmin' && siteName != 'localhost') ? "NeedFollowUp" : "FollowUp",
          Params: (siteName != 'jemadmin' && siteName != 'localhost') ? [
            {
              "Name": "SearchString",
              "Value": null
            },
            {
              "Name": "OrderBy",
              "Value": "FollowUpDate"
            },
            {
              "Name": "OrderByDir",
              "Value": "DESC"
            },
            {
              "Name": "Page",
              "Value": null
            },
            {
              "Name": "PageSize",
              "Value": null
            }
          ] : [
            {
              "Name": "SearchString",
              "Value": null
            },
            {
              "Name": "OrderBy",
              "Value": "FollowUpDate"
            },
            {
              "Name": "OrderByDir",
              "Value": "DESC"
            },
            {
              "Name": "Page",
              "Value": null
            },
            {
              "Name": "PageSize",
              "Value": null
            },
            {
              "Name": "UsersId",
              "Value": loggedInUser.Auth.User.Id
            }
          ]
        },
        { ...addAuthAccessTokenToHeader() },
      )
      setCustomersData(responseCustomer.data.data.ListInfo)
    } catch (error) {
      console.log(error)
    }
  }

  // useEffect(() => {

  // }, [])

  const history = useHistory()
  const routeChangeLead = (id: any) => {
    let path = `/Leads/Detail/${id}`;
    history.push(path);
  }
  const routeChangeCustomer = (id: any) => {
    let path = `/Operations/Customers/Detail/${id}`;
    history.push(path);
  }

  const routeChangeJemcare = (id: any) => {
    let path = `/Jemcare/${id}`;
    history.push(path);
  }

  const currencyFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  // const todayMMDDAAAA = () => {
  //   const date = new window.Date()
  //   let year = date.getFullYear()
  //   let month = date.getMonth()+1
  //   let day = date.getDate()

  //   return `${month<10?`0${month}`:`${month}`}-${day<10?`0${day}`:`${day}`}-${year}`
  // }

  const Quantum = <QuantumDashboard
    cardData={cardData}
    currencyFormat={currencyFormat}
    leadsData={leadsData}
    routeChangeLead={routeChangeLead}
  // customersData = {customersData} 
  // routeChangeCustomer = {routeChangeCustomer} 
  />
  const Dispatch = <DispatchDashboard
    cardData={cardData}
    currencyFormat={currencyFormat}
    // leadsData={leadsData}
    // routeChangeLead={routeChangeLead}
    customersData={customersData}
    routeChangeCustomer={routeChangeCustomer}
  />


  const Campaign = <CampaignDashboard
    cardData={cardData}
    history={history}
  />


  const Jemcare = <JemDashboard
    cardData={cardData}
    customersData={customersData}
    routeChangeJemcare={routeChangeJemcare}
    loggedInUserId={loggedInUser.Auth.User.Id}
    leadsList={leadsList}
  />
  // const Default = <DefaultDashboard 
  // cardData={cardData}
  // currencyFormat={currencyFormat}
  // leadsData={leadsData}
  // routeChangeLead={routeChangeLead}
  // customersData = {customersData} 
  // routeChangeCustomer = {routeChangeCustomer}
  // />  
  return (
    <>
      {appName == 'quantumauto' && Quantum}
      {appName == 'dispatchnow' && Dispatch}
      {(appName == 'localhost' || appName == 'jemadmin') && Jemcare}
      {(appName == 'smsadmin') && Campaign}
      {/* { (appName !== 'dispatchnow' && appName !== 'quantumauto' && appName !== 'jemadmin' && Id !== 136 && appName !== 'localhost' ) && Default  } */}
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  return {
    applicationRetrieve: auth.applicationRetrieve,
    loggedInUser: auth.loggedInUser
  }
}

export default React.memo(connect(mapStateToProps, null)(Dashboard))
