// Generated with util/create-search-page.js
import { CButton, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { CampaignTableColumns } from './CampaignTableColumns'
import { BASE_API, MODELS, SERVICES, LIST, CAMPAIGN } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'

const CampaignSearch = ({ history, modelName = CAMPAIGN , showHeader = true }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)

  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }) => {
    routeToPage(history, '/Admin/Campaign/Detail/' + id)
  }

  const showDeleteModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <>
      {showHeader && <CCardHeader
        className='d-flex align-items-center card-accent-secondary'
        style={{ backgroundColor: '#2A3446', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}
      >
        <span style={{ color: 'white' }}>{modelName} Search</span>
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, '/Admin/Campaign/New')}
        >
          New Campaign
        </CButton>
      </CCardHeader>
      }
      <CCardBody className={'campaign-card-body'}>
        <DataTable
          columnData={!showHeader ? CampaignTableColumns.slice(0, -1) : CampaignTableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={modelName}
          scopedSlots={{
            PhoneNo: ({ value }) => {
              return <div>{value}</div>
            },
            TimeStamp: ({ value }) => {
              return <div>{value}</div>
            }
          }}
          actionClick={(type, data) => {
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          paginationPosition='bottom-right'
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Campaign', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </>
  )
}

export default CampaignSearch
