import React from 'react'

const GreenCheckmark = ({ width = '1.75rem', height = '1.75rem' }) => {
  return (
    // <svg
    //   version='1.1'
    //   id='Layer_1'
    //   xmlns='http://www.w3.org/2000/svg'
    //   x='0px'
    // y='0px'
    // width={width}
    // height={height}
    // viewBox='0 0 96 96'
    // enableBackground='new 0 0 96 96'
    // >
    //   <g>
    //     <path
    //       fillRule='evenodd'
    //       clipRule='evenodd'
    //       fill='#6BBE66'
    //       d='M48,0c26.51,0,48,21.49,48,48S74.51,96,48,96S0,74.51,0,48 S21.49,0,48,0L48,0z M26.764,49.277c0.644-3.734,4.906-5.813,8.269-3.79c0.305,0.182,0.596,0.398,0.867,0.646l0.026,0.025 c1.509,1.446,3.2,2.951,4.876,4.443l1.438,1.291l17.063-17.898c1.019-1.067,1.764-1.757,3.293-2.101 c5.235-1.155,8.916,5.244,5.206,9.155L46.536,63.366c-2.003,2.137-5.583,2.332-7.736,0.291c-1.234-1.146-2.576-2.312-3.933-3.489 c-2.35-2.042-4.747-4.125-6.701-6.187C26.993,52.809,26.487,50.89,26.764,49.277L26.764,49.277z'
    //     />
    //   </g>
    // </svg>
    <svg
      width={width}
      height={height}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 96 96'
      enableBackground='new 0 0 96 96'
    >
      <g>
        <title>background</title>
        <rect fill='none' id='canvas_background' height='514' width='514' y='-1' x='-1' />
      </g>
      <g>
        <title>Layer 1</title>
        <ellipse
          ry='28.366446'
          rx='29.580574'
          id='svg_3'
          cy='47.779249'
          cx='48.220751'
          strokeOpacity='null'
          strokeWidth='0.001'
          stroke='#6BBE66'
          fill='#ffffff'
        />
        <g id='svg_1'>
          <path
            id='svg_2'
            d='m48,0c26.51,0 48,21.49 48,48s-21.49,48 -48,48s-48,-21.49 -48,-48s21.49,-48 48,-48l0,0zm-21.236,49.277c0.644,-3.734 4.906,-5.813 8.269,-3.79c0.305,0.182 0.596,0.398 0.867,0.646l0.026,0.025c1.509,1.446 3.2,2.951 4.876,4.443l1.438,1.291l17.063,-17.898c1.019,-1.067 1.764,-1.757 3.293,-2.101c5.235,-1.155 8.916,5.244 5.206,9.155l-21.266,22.318c-2.003,2.137 -5.583,2.332 -7.736,0.291c-1.234,-1.146 -2.576,-2.312 -3.933,-3.489c-2.35,-2.042 -4.747,-4.125 -6.701,-6.187c-1.173,-1.172 -1.679,-3.091 -1.402,-4.704l0,0z'
            fill='#6BBE66'
            clipRule='evenodd'
            fillRule='evenodd'
          />
        </g>
      </g>
    </svg>
  )
}

export default GreenCheckmark
