// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const InspectorInfo = ({ modelData, handleChange, title = 'Model Name' }: PropsInfoForm) => {
  const { Name, Description, Timezone } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={Name ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <InputField
            name='Description'
            type='textarea'
            label='Description'
            value={Description ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol>
          <InputField
            name='Timezone'
            type='textarea'
            label='Timezone'
            value={Timezone ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      {/* <CFormGroup row>
        <CCol>
          <ComboBox
            showButton
            multiple
            freeSolo
            name='Timezone'
            label='Zip Code List'
            value={ZipCodeList?.split(',') ?? []}
            handleChange={(...rest) => {
              const [, value] = rest
              const zips = value.join()
              if (zips.length !== 0) {
                return handleChange({ name: 'ZipCodeList', value: zips })
              }
              handleChange({ name: 'ZipCodeList', value: null })
            }}
            classNames={{ input: 'input-field' }}
            customClassNames={{
              button: 'comboBoxInput',
              root: 'input-field-root input-field-root-multiple pt-3',
            }}
            renderOption={(option: any, { selected }: any) => (
              <span className={selected ? 'combo-selected' : ''}>{option.CodeId}</span>
            )}
            placeholder='Enter a tag'
          />
        </CCol>
      </CFormGroup> */}
      {/*   <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </FieldsSection>
  )
}

export default InspectorInfo
