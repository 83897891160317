// Generated with util/create-search-page.js
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { ErrorLogsTableColumns } from './ErrorLogsTableColumns'
import { BASE_API, ERROR_LOGS_MODEL, MODELS, ERROR_LOGS_ID } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'

const ErrorLogsSearch = ({ history, modelName = ERROR_LOGS_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)

  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }) => {
    routeToPage(history, '/Logs/ErrorLogs/Detail/' + id)
  }

  const showDeleteModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions = {
    view: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        {modelName} Search
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={ErrorLogsTableColumns}
          theme='dark'
          noHeader
          endpoint={BASE_API + ':' + MODELS}
          modelName={modelName}
          idName={ERROR_LOGS_ID}
          actionClick={(type, data) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          // queryParams={{ OrderBy: APIS_ID, OrderByDir: 'desc' }}
          scopedSlots={{
            Source: ({ value }) => {
              // console.log(data)
              return (
                <code style={{ color: '#F2AB26', fontSize: '0.75rem', fontFamily: 'Consolas' }}>
                  {value}
                </code>
              )
            },
            StampStart: ({ value }) => <div style={{ fontFamily: 'Consolas' }}>{value}</div>,
            StampEnd: ({ value }) => <div style={{ fontFamily: 'Consolas' }}>{value}</div>,
            StackTrace: ({ value }) => <div style={{ fontSize: '0.9rem' }}>{value}</div>,
          }}
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'ErrorLog', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CCard>
  )
}

export default ErrorLogsSearch
