import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { ComboBox } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface HttpsMethods {
  CodeId: string
  Description: string
}
const httpMethodsData: HttpsMethods[] = [
  {
    CodeId: 'GET',
    Description: 'GET',
  },
  {
    CodeId: 'POST',
    Description: 'POST',
  },
  {
    CodeId: 'PATCH',
    Description: 'PATCH',
  },
  {
    CodeId: 'PUT',
    Description: 'PUT',
  },
  {
    CodeId: 'DELETE',
    Description: 'DELETE',
  },
]
interface PropsEndPointsInfo {
  handleComboChange?: any
  handleSwitchChange?: any
  endpointData?: any
  handleChange?: any
}
const EndpointsInfo: React.FC<PropsEndPointsInfo> = ({
  handleComboChange,
  handleSwitchChange,
  endpointData,
  handleChange,
}) => {
  return (
    <FieldsSection header='Endpoint Info'>
      {/* <CFormGroup row>
        <CCol xs='12'>
          <ComboBox
            showButton
            name='ServicesId'
            label='Microservice'
            data={servicesData}
            value={servicesData.find((service: any) => service.CodeId === endpointData.ServicesId)}
            handleChange={(e: any, newValue = {}) => handleComboChange(e, newValue, 'ServicesId')}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
            )}
          />
        </CCol>
      </CFormGroup> */}
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={endpointData.Name}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Description'
            type='text'
            label='Description'
            value={endpointData.Description}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Header'
            type='textarea'
            label='Header'
            value={endpointData.Header}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12'>
          <ComboBox
            showButton
            name='HttpMethod'
            label='HTTP Method'
            data={httpMethodsData}
            value={httpMethodsData.find(
              (httpMethodData: any) => httpMethodData.CodeId === endpointData.HttpMethod,
            )}
            handleChange={(e: any, newValue = {}) => handleComboChange(e, newValue, 'HttpMethod')}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
            )}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12'>
          <InputField
            name='Enabled'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Enabled'
          >
            <CustomSwitch
              name='Enabled'
              className='d-block'
              value={endpointData.Enabled}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='4'>
          <InputField
            name='AuthApplication'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Auth Application'
          >
            <CustomSwitch
              name='AuthApplication'
              className='d-block'
              value={endpointData.AuthApplication}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
        <CCol xs='12' lg='4' className='px-lg-0 my-3 my-lg-0'>
          <InputField
            name='AuthMember'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Auth Member'
          >
            <CustomSwitch
              name='AuthMember'
              className='d-block'
              value={endpointData.AuthMember}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
        <CCol xs='12' lg='4'>
          <InputField
            name='AuthUser'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Auth User'
          >
            <CustomSwitch
              name='AuthUser'
              className='d-block'
              value={endpointData.AuthUser}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default EndpointsInfo
