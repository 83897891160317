import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import PeopleIcon from 'src/assets/icons/PeopleIcon'
import ChartBarSimple from 'src/shared/components/charts/ChartBarSimple'
import ChartLineSimple from 'src/shared/components/charts/ChartLineSimple'
import WidgetDropdown from 'src/shared/components/widgets/WidgetDropdown'
import CardCustomer from './CardCustomer'
import MainChart from './MainChart'

export function QuantumDashboard({
  cardData, currencyFormat, leadsData, routeChangeLead,
  // customersData,
  // routeChangeCustomer
}) {
  return (

    <div style={{ paddingBottom: '80px' }}>
      <CRow style={{ padding: 10 }}>
        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-primary'
            header={cardData.Dispatches}
            text='LEADS'
            footerSlot={<ChartLineSimple
              pointed
              className='c-chart-wrapper mt-3 mx-3'
              style={{ height: '70px' }}
              dataPoints={[65, 59, 84, 84, 51, 55, 40]}
              pointHoverBackgroundColor='primary'
              label='Members'
              labels='months' />}
            icon={<PeopleIcon />}
          >
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-info'
            header={cardData.Inspectors}
            text='PRICE OFFER'
            footerSlot={<ChartLineSimple
              pointed
              className='mt-3 mx-3'
              style={{ height: '70px' }}
              dataPoints={[1, 18, 9, 17, 34, 22, 11]}
              pointHoverBackgroundColor='info'
              options={{ elements: { line: { tension: 0.00001 } } }}
              label='Members'
              labels='months' />}
            icon={<PeopleIcon />}
          >
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-warning'
            header={currencyFormat.format(cardData.Revenue)}
            text='REVENUE $'
            footerSlot={<ChartLineSimple
              className='mt-3'
              style={{ height: '70px' }}
              backgroundColor='rgba(255,255,255,.2)'
              dataPoints={[78, 81, 80, 45, 34, 12, 40]}
              options={{ elements: { line: { borderWidth: 2.5 } } }}
              pointHoverBackgroundColor='warning'
              label='Members'
              labels='months' />}
            icon={<PeopleIcon />}
          >
          </WidgetDropdown>
        </CCol>

        <CCol sm='6' lg='3'>
          <WidgetDropdown
            color='gradient-danger'
            header={cardData.Inspections}
            text='PRICE ACCEPTED'
            footerSlot={<ChartBarSimple
              className='mt-3 mx-3'
              style={{ height: '70px' }}
              backgroundColor='rgb(250, 152, 152)'
              label='Members'
              labels='months' />}
            icon={<PeopleIcon />}
          >
          </WidgetDropdown>
        </CCol>
      </CRow>
      <CRow style={{ marginTop: '-25px' }}>
        <CCol md='8' className='pr-lg-0 pl-lg-3'>
          <CCard style={{ margin: 10 }}>
            <CCardBody>
              <CRow>
                <CCol sm='5'>
                  <h5 id='traffic' className='card-title mb-0 '>
                    No. of Leads vs. No. of Offers
                  </h5>
                  {/* <div className='small text-muted'>{getDateLabel()}</div> */}
                </CCol>
              </CRow>
              <MainChart style={{ height: '300px', marginTop: '20px' }} />


            </CCardBody>
          </CCard>
        </CCol>
        <CCol className='pr-lg-0 pl-lg-0'>
          <CCard style={{ margin: 10 }}>
            <CCardBody>
              <h5>Leads</h5>
              {leadsData.map((value) => <div key={value['Id']} onClick={() => routeChangeLead(value['Id'])}>
                <CardCustomer
                  key={value['id']}
                  firstname={value['CustFirstName']}
                  lastname={value['CustLastName']}
                  followupdate={value['FollowUpDate']}
                  // zip={value['CustAddressZip']} 
                  vehicle={value['Vehicle']}
                  // status={value['Status']} 
                  phonemobile={value['CustPhoneMobile']} />
              </div>
              )}
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

    </div>)
}
