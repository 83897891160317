// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const VehicleConditionsInfo = ({ modelData, handleChange, title = 'Model Name' }: PropsInfoForm) => {
  const { 
    KeyQuantity,
    InteriorCondition,
    ExteriorCondition,
    TireCondition,
    ModificationsEngine,
    ModificationsExhaustEmissions,
    ModificationsRaisedSuspension,
    ModificationsLoweredSuspension,
    ElectricalIssuesRadio,
    ElectricalIssuesDvdBluRay,
    ElectricalIssuesNavigation,
    ElectricalIssuesSunroofMoonroof,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='KeyQuantity'
            type='number'
            label='Key Quantity'
            value={KeyQuantity ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='InteriorCondition'
            type='number'
            label='Interior Condition'
            value={InteriorCondition ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='ExteriorCondition'
            type='number'
            label='Exterior Condition'
            value={ExteriorCondition ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='TireCondition'
            type='number'
            label='Tire Condition'
            value={TireCondition ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField 
            type='fancyswitch'
            name='ModificationsEngine'
            label={`Modifications Engine`}
          >
            <CustomSwitch
              name='ModificationsEngine'
              className='d-block'
              value={ModificationsEngine}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ModificationsEngine })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField 
            type='fancyswitch'
            name='ModificationsExhaustEmissions'
            label={`Modifications Exhaust Emissions`}
          >
            <CustomSwitch
              name='ModificationsExhaustEmissions'
              className='d-block'
              value={ModificationsExhaustEmissions}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ModificationsExhaustEmissions })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField 
            type='fancyswitch'
            name='ModificationsRaisedSuspension'
            label={`Modifications Raised Suspension`}
          >
            <CustomSwitch
              name='ModificationsRaisedSuspension'
              className='d-block'
              value={ModificationsRaisedSuspension}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ModificationsRaisedSuspension })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField 
            type='fancyswitch'
            name='ModificationsLoweredSuspension'
            label={`Modifications Lowered Suspension`}
          >
            <CustomSwitch
              name='ModificationsLoweredSuspension'
              className='d-block'
              value={ModificationsLoweredSuspension}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ModificationsLoweredSuspension })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField 
            type='fancyswitch'
            name='ElectricalIssuesRadio'
            label={`Electrical Issues Radio`}
          >
            <CustomSwitch
              name='ElectricalIssuesRadio'
              className='d-block'
              value={ElectricalIssuesRadio}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ElectricalIssuesRadio })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField 
            type='fancyswitch'
            name='ElectricalIssuesDvdBluRay'
            label={`Electrical Issues Dvd BluRay`}
          >
            <CustomSwitch
              name='ElectricalIssuesDvdBluRay'
              className='d-block'
              value={ElectricalIssuesDvdBluRay}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ElectricalIssuesDvdBluRay })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField 
            type='fancyswitch'
            name='ElectricalIssuesNavigation'
            label={`Electrical Issues Navigation`}
          >
            <CustomSwitch
              name='ElectricalIssuesNavigation'
              className='d-block'
              value={ElectricalIssuesNavigation}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ElectricalIssuesNavigation })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField 
            type='fancyswitch'
            name='ElectricalIssuesSunroofMoonroof'
            label={`Electrical Issues Sunroof Moonroof`}
          >
            <CustomSwitch
              name='ElectricalIssuesSunroofMoonroof'
              className='d-block'
              value={ElectricalIssuesSunroofMoonroof}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ElectricalIssuesSunroofMoonroof })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default VehicleConditionsInfo
