import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import Loader from '../../shared/components/Loader'
import store from 'src/redux/store'

async function loginUser(credentials: any) {
  const response = await fetch('http://localhost:8080/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(credentials),
  })
  return await response.json()
}
const Login: React.FC<any> = () => {
  const [username, setUserName] = useState('demo')
  const [password, setPassword] = useState('demo')
  const [loggingIn, setLoggingIn] = useState(false)
  const [loginError, setLoginError] = useState('')

  // const [redirectToReferrer, setRedirectToReferrer] = useState(false)

  // const { state } = useLocation()

  const login = async () => {
    setLoginError('')
    setLoggingIn(true)
    try {
      const response = await loginUser({
        username,
        password,
      })
      setLoggingIn(false)
      const { token, error, user } = response
      setUserName('')
      setPassword('')
      if (token) {
        store.dispatch({
          type: 'set',
          loggedInUser: user,
        })
        // console.log(store.getState());
        localStorage.setItem('VCA_AUTH', token)
        // setRedirectToReferrer(true)
      } else if (error) {
        setLoginError(error)
      }
    } catch (e) {
      setLoggingIn(false)
      setLoginError('Failed to communicate with authentication server')
    }
  }

  // if (redirectToReferrer === true) {
  //   // console.log('redirecting!', state?.from);
  //   return <Redirect to={state?.from || '/'} />
  // }

  return (
    <div
      className='c-app c-dark-theme c-default-layout flex-row align-items-center'
      onKeyUp={(e) => e.key === 'Enter' && login()}
    >
      <CContainer>
        <CRow className='justify-content-center'>
          <CCol md='8'>
            <CCardGroup>
              <CCard className='p-4'>
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    <p className='text-muted'>Sign In to your account</p>
                    <CInputGroup className='mb-3'>
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name='cil-user' />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type='text'
                        placeholder='Username'
                        autoComplete='username'
                        value={username}
                        onChange={(e: any) => setUserName(e.target.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className={`mb-${loginError !== '' ? 0 : 4}`}>
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name='cil-lock-locked' />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        type='password'
                        placeholder='Password'
                        autoComplete='current-password'
                        value={password}
                        onChange={(e: any) => setPassword(e.target.value)}
                      />
                    </CInputGroup>
                    {loginError !== '' && (
                      <div className='mb-1 text-danger text-center w-100'>{loginError}</div>
                    )}
                    <CRow alignHorizontal='center'>
                      <CCol xs='12' sm='6'>
                        <CButton
                          disabled={loggingIn}
                          color='primary'
                          className='px-4'
                          onClick={login}
                          style={{ width: '100%' }}
                        >
                          {loggingIn ? (
                            <Loader
                              style={{ width: '1rem', height: '1rem' }}
                              variant='grow'
                              color='muted'
                            />
                          ) : (
                            'Login'
                          )}
                        </CButton>
                      </CCol>
                      <CCol xs='12' sm='6' className='text-right'>
                        <CButton color='link' className='px-0' style={{ width: '100%' }}>
                          Forgot password?
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className='text-white bg-primary py-5 d-md-down-none' style={{ width: '44%' }}>
                <CCardBody className='text-center'>
                  <div>
                    <h2>Sign up</h2>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>
                    <Link to='/register'>
                      <CButton color='primary' className='mt-3' active tabIndex={-1}>
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
