// Generated with util/create-search-page.js
import { CCol, CRow } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
import AddressInfo from './AddressComponent'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  handleAddressChange: any
  title: string
}

const InspectorInfo = ({
  modelData,
  handleChange,
  title = 'Model Name',
  handleAddressChange,
}: PropsInfoForm) => {
  const { FirstName, LastName, PhoneMobile, SourceName, SourceCode } = modelData

  const sourceData = () => {
    if (SourceName && SourceCode) {
      return `${SourceName}: ${SourceCode}`
    }
    return ''
  }

  return (
    <FieldsSection header={title} text={sourceData()}>
      <CRow>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel label='First Name' value={FirstName ?? '⠀'} />
          <InputLabel label='LastName' value={LastName ?? '⠀'} />
          <InputLabel label='Phone Mobile' value={PhoneMobile ?? '⠀'} />
        </CCol>
        <CCol>
          <AddressInfo
            modelData={modelData}
            handleChange={handleChange}
            handleAddressChange={handleAddressChange}
          />
        </CCol>
      </CRow>
    </FieldsSection>
  )
}

export default InspectorInfo
