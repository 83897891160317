import React, { useEffect, useState } from 'react'
import { CButton, CCard, CCardHeader, CCol, CContainer, CRow } from '@coreui/react'
import { InputField as CustomInputField, DragAndDropFile } from 'smart-inputs'
import { ListFiles } from 'src/services/modelNew/File/ListFiles'
import { FileUpload } from 'src/services/modelNew/Widget/File/Upload'

import { LinearProgressIndicator } from 'src/shared'
import axios from 'axios'
import { getAuthToken } from 'src/services/authHelper'

export const ExampleDragAndDropFile = () => {
  const metasId = 11
  const [file, setFile] = useState<any>([])
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const getAllData = async () => {
    const { data } = await ListFiles({
      MetasId: metasId,
    })

    setFiles(data.FilesList)
  }
  const handleChange = (file) => {
    const filesToUpload = Array.from(file ?? [])
    setFile(filesToUpload)
  }

  const renderFilesToUpload = () => {
    console.log('file', file);
    console.log('files', files);

    return file.map((f: any, index) => (
      <CCard key={index}>
        <CCardHeader>
          <div style={{ color: 'dark !important' }}>
            <div>
              <b> OriginalName:</b> {f.name}
            </div>
            <div>
              <b> Type:</b> {f.type}
            </div>
          </div>
          <CButton
            disabled={loading}
            className='mr-2'
            type='button'
            color='primary'
            style={{ float: 'right' }}
            onClick={async () => {
              setLoading(true)
              await FileUpload({
                Id: 0,
                MetasId: metasId,
                Description: f.name,
                fileToUpload: f,
              })

              const newArray = file.filter((e) => e.name !== f.name)
              await getAllData()
              setFile(newArray)
              setLoading(false)
            }}
          >
            Upload
          </CButton>
        </CCardHeader>
      </CCard>
    ))
  }

  useEffect(() => {
    getAllData()
  }, [])

  const viewFile = async (url, originalName) => {
    axios({
      url,
      method: 'GET',
      headers: {
        authorization: `Bearer ${getAuthToken('ADMIN')}`,
      },
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', originalName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    })
  }

  return (
    <>
      {loading && <LinearProgressIndicator />}

      {renderFilesToUpload()}
      <DragAndDropFile
        handleChange={handleChange}
        multiple
        files={files}
        renderFile={(f) => (
          <CCard>
            <CCardHeader>
              <div style={{ color: 'dark !important' }}>
                <div>
                  <b> Id: </b>
                  {f.Id}
                </div>
                <div>
                  <b> OriginalName:</b> {f.OriginalName}
                </div>
                <div>
                  <b>FileRepo:</b> {f.FileRepo}
                </div>
                <div>
                  <b>FileUrl:</b> {f.FileUrl}
                </div>
                <div>
                  <b>Description:</b> {f.Description}
                </div>
                <div>
                  {' '}
                  <b>ChangedOn:</b> {f.ChangedOn}
                </div>
              </div>
              <CButton
                className='mr-2'
                type='button'
                color='danger'
                size='sm'
                style={{ float: 'right' }}
                onClick={() => { }}
              >
                Delete
              </CButton>
              <CButton
                className='mr-2'
                type='button'
                size='sm'
                color='primary'
                style={{ float: 'right' }}
                onClick={() => {
                  const nameUrl = f.FileUrl.split('/')
                  const url = nameUrl[1].split('.')
                  viewFile(
                    `https://api.softsmartinc.com/Services/vOld/Widget/Files/Download/${url[0]}`,
                    f.OriginalName,
                  )
                }}
              >
                Show
              </CButton>
            </CCardHeader>
          </CCard>
        )}
        name='File'
        types={['JPG', 'PNG', 'GIF', 'PDF']}
      />
    </>
  )
}
const KitchenInput: React.FC<any> = () => {
  const [color] = useState('#ffffff')
  return (
    <CContainer fluid>
      <CRow>
        <CCol>
          <h5>Input Test </h5>

          <CustomInputField value={color} type='colorpicker'></CustomInputField>
        </CCol>
        <CCol>
          <ExampleDragAndDropFile></ExampleDragAndDropFile>
        </CCol>
      </CRow>
    </CContainer>
  )
}
export default KitchenInput
