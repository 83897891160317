import { ColumnData } from 'library-soft-datatable'

export const fieldsTableColumns: ColumnData[] = [
  // {
  //   label: 'ID',
  //   name: 'FieldsId',
  //   customWidth: 8,
  // },
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: 'Select Query',
    name: 'SelectQuery',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
