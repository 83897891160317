// Generated with util/create-search-page.js
import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useContext, useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { ProcsTableColumns } from './ProcsTableColumns'
import { BASE_API, PROCS_MODEL, MODELS, PROCS_ID } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'
import { DashboardContext } from 'src/containers/DashBoard'
import { toast } from 'react-toastify'
import { ProcGenerateImport } from 'src/services/modelNew/ProcGen/Imports/ProcImport'

const ProcsSearch: React.FC<any> = ({ history, modelName = PROCS_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)
  const { setShowLinear, showLinear } = useContext(DashboardContext)
  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }: any) => {
    routeToPage(history, '/Database/Procs/Detail/' + id)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }
  const procGenerate = async () => {
    try {
      setShowLinear(true)
      toast.info('Importing Procs this could take a few minutes.')
      const { message } = await ProcGenerateImport({
        procType: 'Procs',
      })
      toast.success(message)
      setShowLinear(false)
    } catch (error: any) {
      setShowLinear(false)
      toast.error(`${error.message}`)
    }
  }
  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        Procedures Search
        <CButton
          disabled={showLinear}
          className='ml-1 '
          type='button'
          size='sm'
          color='primary'
          onClick={procGenerate}
        >
          Imports Procs
        </CButton>
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, '/Database/Procs/New')}
        >
          New Procedure
        </CButton>
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={ProcsTableColumns}
          theme='dark'
          noHeader
          endpoint={BASE_API + ':' + MODELS}
          modelName={modelName}
          idName={PROCS_ID}
          actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Proc', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CCard>
  )
}

export default ProcsSearch
