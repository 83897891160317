// import { useEffect } from 'react'
import { Redirect, Route } from 'react-router'
// import { login } from 'src/services/authHelper'

const PrivateRoute = ({ children, loggedInUser, ...rest }) => {
  // const location = useLocation()
  // const username = localStorage.getItem('ADMIN_AUTH_UserName')
  // const password = localStorage.getItem('ADMIN_AUTH_Password')
  // useEffect(() => {
  //   if (!/External/i.test(window.location.href)) {
  //     login({
  //       accessToken: '',
  //       Password: password,
  //       UserName: username,
  //       setRedirectToReferrer: () => {},
  //     })
  //   }
  // }, [password, username, location])
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (
          typeof loggedInUser.ADMIN_API_KEY === 'undefined' ||
          loggedInUser.ADMIN_API_KEY === null
        ) {
          return <Redirect to={{ pathname: '/login', state: { from: location } }} />
        }

        return children
      }}
    />
  )
}
export default PrivateRoute
