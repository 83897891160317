import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, WIDGETS } from 'src/services/routeConst'
interface PropsFileUpload {
  fileToUpload?: any
  FilesId?: any
  MetasId?: any
  id?: any
  modelName?: any
}
export const FileUpload = async ({
  fileToUpload,
  FilesId,
  MetasId = 0,
  id,
  modelName = 'Acc',
}: PropsFileUpload) => {
  //URL {api}/Widgets/Files/Upload
  const form = new FormData()
  form.append('File', fileToUpload)
  form.append('FilesId', FilesId)
  form.append('MetasId', MetasId)
  id && form.append(`${modelName === 'Acc' ? 'Users' : modelName}Id`, id)
  try {
    const { data } = await axios.post(
      // `${BASE_API}:${MODELS}/${MODEL_SAVE}/${modelName}`,
      `${BASE_API}:${WIDGETS}/Files/Upload`,
      form,
      { ...addAuthToHeader('ADMIN') },
    )
    return {
      data: data.data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error saving File!`,
      error: error.response,
    })
  }
}
