import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormGroup,
  CListGroup,
  CListGroupItem,
  CRow,
} from '@coreui/react'
import { RadioGroup, CheckBoxGroup } from 'smart-inputs'
import React from 'react'
import { connect } from 'react-redux'
import { Cropper } from 'src/shared'
const Profile: React.FC<any> = ({ loggedInUser }) => {
  const [options, setOptions] = React.useState([
    {
      name: 'SettingAllowPush',
      label: 'Allow Push Notifications',
      value: false,
    },
    {
      name: 'SettingPaperless',
      label: 'Allow Paperless',
      value: false,
    },
    {
      name: 'SettingAutopay',
      label: 'Enable Autopay',
      value: true,
    },
    {
      name: 'SettingFrozen',
      label: 'Card Frozen',
      value: true,
    },
  ])
  return (
    <CRow>
      <CCol xs='12' lg='4'>
        <CRow>
          <CCol xs='12'>
            <CCard>
              <CCardHeader className='d-flex align-items-center card-accent-secondary'>
                User Info
              </CCardHeader>
              <CCardBody>
                <CFormGroup row className='justify-content-center'>
                  {/*  src={userData.profile_picture}  */}
                  {/** cropShape={'rect' | 'round'} */}
                  <Cropper
                    cropShape={'round'}
                    maxSizeMib={3}
                    onChangeCropper={({ imageURL, file }: any) => {
                      console.log({ imageURL })
                      console.log({ file })
                    }}
                    guid={54}
                  />
                </CFormGroup>
                <CRow className='flex-column justify-content-center'>
                  <CCol className='text-center'>
                    {loggedInUser.FirstName} {loggedInUser.LastName}
                  </CCol>
                  <CCol className='text-center'>{loggedInUser.Email}</CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xs='12'>
            <CCard>
              {/* <CCardHeader className='card-accent-secondary'>User Info</CCardHeader> */}
              <CCardBody>
                <CListGroup>
                  <CListGroupItem>These are list items</CListGroupItem>
                  <CListGroupItem>Used for links and such</CListGroupItem>
                  <CListGroupItem>Or anything else you want to show</CListGroupItem>
                  <CListGroupItem>About the user</CListGroupItem>
                  <CListGroupItem>Or something</CListGroupItem>
                </CListGroup>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
      <CCol xs='12' lg='8'>
        <CRow>
          <CCol xs='12'>
            <CCard>
              <CCardHeader className='d-flex align-items-center card-accent-secondary'>
                User Settings
              </CCardHeader>
              <CCardBody>
                <CFormGroup row>
                  <CCol xs='12' lg='6'>
                    <RadioGroup
                      name='AccessLevels'
                      label='Access Level'
                      options={[
                        'Access Level - Full',
                        'Access Level - Medium',
                        'Access Level - High',
                      ]}
                      color='#4638c2'
                      onChangeRadio={({ name, value }) => {
                        console.log({ name, value })
                      }}
                      defaultSelected={'Access Level - Full'}
                      customClassNames={{
                        root: 'input-field-root RadioGroup',
                        input: 'input-field',
                      }}
                    />
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol xs='12' lg='6'>
                    <CheckBoxGroup
                      name='Settings'
                      label='Settings'
                      options={options}
                      onChangeCheckBox={({ name, value }) => {
                        // console.log(options)
                        setOptions((st: any) => [
                          ...st.map((option: any) => ({
                            ...option,
                            value: name === option.name ? value : option.value,
                          })),
                        ])
                      }}
                      customClassNames={{
                        root: 'input-field-root CheckBoxGroup',
                        input: 'input-field',
                      }}
                    />
                  </CCol>
                </CFormGroup>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xs='12' lg='6'>
            <CCard>
              {/* <CCardHeader className='card-accent-secondary'>User Info</CCardHeader> */}
              <CCardBody>
                <CListGroup>
                  <CListGroupItem>Lorem ipsum dolor sit amet consectetur.</CListGroupItem>
                  <CListGroupItem>Lorem ipsum dolor sit amet consectetur.</CListGroupItem>
                  <CListGroupItem>Lorem ipsum dolor sit amet consectetur.</CListGroupItem>
                  <CListGroupItem>Lorem ipsum dolor sit amet consectetur.</CListGroupItem>
                  <CListGroupItem>Lorem ipsum dolor sit amet consectetur.</CListGroupItem>
                </CListGroup>
              </CCardBody>
            </CCard>
          </CCol>
          <CCol xs='12' lg='6'>
            <CCard>
              {/* <CCardHeader className='card-accent-secondary'>User Info</CCardHeader> */}
              <CCardBody>
                <CListGroup>
                  <CListGroupItem>Lorem ipsum dolor sit amet consectetur.</CListGroupItem>
                  <CListGroupItem>Lorem ipsum dolor sit amet consectetur.</CListGroupItem>
                  <CListGroupItem>Lorem ipsum dolor sit amet consectetur.</CListGroupItem>
                  <CListGroupItem>Lorem ipsum dolor sit amet consectetur.</CListGroupItem>
                  <CListGroupItem>Lorem ipsum dolor sit amet consectetur.</CListGroupItem>
                </CListGroup>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  )
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  return {
    loggedInUser: auth.loggedInUser,
  }
}

export default React.memo(connect(mapStateToProps, null)(Profile))
