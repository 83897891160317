// Generated with util/create-search-page.js
import { CButton, CCardHeader } from '@coreui/react'

import LoadingButton from 'src/shared/components/LoadingButton'
interface PropsHeaderComponent {
  onCancelHandle: any
  status: any
  saveData: any
  modelName: any
}
const HeaderComponent = ({
  onCancelHandle,
  status,
  saveData,
  modelName = 'Model Name',
}: PropsHeaderComponent) => {
  const isLoading = status === 'saving'
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g).join(' ')
  return (
    <CCardHeader className='d-flex align-items-center card-accent-secondary' style={{ backgroundColor: '#2A3446', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}>
      <span style={{ color: 'white' }}>{title} Details</span>
      <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton>
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancelHandle}
      >
        Close
      </CButton>
    </CCardHeader>
  )
}

export default HeaderComponent
