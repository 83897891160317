import { CCol, CFormGroup } from '@coreui/react'
import axios from 'axios';
import { DataTable } from 'library-soft-datatable';
import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { addAuthAccessTokenToHeader, getAuthToken, logoutVerifyErrors } from 'src/services/authHelper';
import { BASE_API, LIST, MODELS, RETRIEVE, SAVE, SERVICES } from 'src/services/routeConst';
import { InputField } from 'src/shared';
import { DeleteModal } from 'src/shared';
import ContactsProfilePicture from './ContactsProfilePicture';
// import { RadioGroup, Radio } from 'react-radio-input'

// import { Info } from "./components";
import { TableColumns } from './ContactsTableColumns';

// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange: any
  title?: string
}

type ApiDataState = { modelDataContacts: any }

// const RadioGroupTs: any = RadioGroup
const ContactsInfo = ({ modelData }: PropsInfoForm) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  // const mapComboToData = () =>
  const tableRef = useRef<any>()
  const clickedIDRef = useRef()
  
  const [avatarData, setAvatarData] = useState({})
  const [status, setStatus] = useState('datatable')
  const [formState, setFormState] = useState<ApiDataState>({modelDataContacts:{BuyersId:modelData.Id}})
  const onInputChange = ({name, value}) => {
    name &&
    setFormState((st: any) => ({
        ...st,
        modelDataContacts: { ...st.modelDataContacts, [name]: value },
      }))
  }
  console.log(formState);
  // let {modelDataContacts} = formState
  
  const viewDetails = async ({id}) => {
    setStatus('Edit Contact')

    try{
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
        {
          Model: 'BuyersContacts',
          Id: id,
          Params: [],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      
      await setFormState({modelDataContacts: {
        Id: data.data.RetrieveInfo[0].Id,
        Name: data.data.RetrieveInfo[0].Name,
        Email: data.data.RetrieveInfo[0].Email,
        Phone: data.data.RetrieveInfo[0].Phone,
        Password: data.data.RetrieveInfo[0].Password,
        BuyersId: data.data.RetrieveInfo[0].BuyersId,
        TokensId: data.data.RetrieveInfo[0].TokensId,
      }})
      const contacts = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
        {
          Model: 'Tokens',
          Id: data.data.RetrieveInfo[0].TokensId,
          Params: [],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      await setAvatarData(contacts.data.data.RetrieveInfo[0])
      
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
    // routeToPage(history, `/Leads/Detail/${id}`)
  }
  const newContact = () => {
    setStatus('Add Contact')
    setFormState({modelDataContacts:{
      Id:0,
      BuyersId:modelData.Id
    }})
  }
  const viewDatatable = () => {
    setStatus('datatable')
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }
  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  const saveData = async ({modelDataContacts}) => {
    // status === 'edit' && id=
    try{
      await axios.patch(
        `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
        {
          Model: 'BuyersContacts',
          Id: modelDataContacts.Id,
          Params: [
            { name: 'Name', value: modelDataContacts.Name },
            { name: 'Email', value: modelDataContacts.Email },
            { name: 'Phone', value: modelDataContacts.Phone },
            { name: 'Password', value: modelDataContacts.Password },
            { name: 'BuyersId', value: modelDataContacts.BuyersId },
          ],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      toast.success('Contact saved')
      await viewDatatable()
    }catch(err){
      console.log(err);
    }
  }

  return (
    <>
    <CFormGroup row>
      <CCol>
        {
          status === 'datatable'
          ? <>
              <button onClick={()=>newContact()} className='btn btn-primary'>
                New Contact
              </button>
              <hr />
              <DataTable
                columnData={TableColumns}
                theme='white'
                noHeader
                endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
                modelName={'BuyersContacts'}
                actionClick={(type: any, data: any) => {
                  actionFunctions[type]({ id: data })
                }}
                ref={tableRef}
                apiKey={getAuthToken('ADMIN')}
                paginationPosition='bottom-right'
                queryParams={[{Name: 'BuyersId', Value: modelData.Id}]}
              />
            </>
          :<>
          <CFormGroup row>
            <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
              <InputField
                name='Name'
                type='text'
                label='Name'
                value={formState.modelDataContacts.Name || ''}
                onChange={onInputChange}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
              <InputField
                name='Email'
                type='text'
                label='Email'
                value={formState.modelDataContacts.Email || ''}
                onChange={onInputChange}
              />
            </CCol>
            <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
              <InputField
                name='Phone'
                type='phone'
                label='Phone'
                value={formState.modelDataContacts.Phone || ''}
                onChange={onInputChange}
              />
            </CCol>
            <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
              <InputField
                name='Password'
                type='password'
                label='Password'
                value={formState.modelDataContacts.Password || ''}
                onChange={onInputChange}
              />
            </CCol>
          </CFormGroup>
          {
            status === 'Edit Contact' &&
            <CFormGroup row>
              <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
                <ContactsProfilePicture modelData={modelData} avatarData={avatarData} />
              </CCol>
            </CFormGroup>
          }
          {/* <CFormGroup row>
            <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
              <InputField
                name='BuyersId'
                type='text'
                label='BuyersId'
                value={formState.modelDataContacts.BuyersId || ''}
                onChange={onInputChange}
              />
            </CCol>
          </CFormGroup> */}
          <CFormGroup row>
            <CCol md='6' style={{textAlign:'end'}}>
              <button onClick={()=>saveData(formState)} className='btn btn-primary'>
                Save
              </button>
            </CCol>
            <CCol md='6'>
              <button onClick={()=>viewDatatable()} className='btn btn-danger'>
                Close
              </button>
            </CCol>
          </CFormGroup>
          </>
        }
        </CCol>
    </CFormGroup>
    <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName:'BuyersContacts', label: 'contact', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </>
  )
}

export default ContactsInfo