import { CButton } from '@coreui/react'
import React from 'react'
import LoadingButton from './LoadingButton'
interface PropsHeaderButton {
  id: any
  onClick: (value: any) => void
  color?: string
  label?: string
  type?: string
  status?: string
  className?: string
  size?: string
}
const HeaderButton: React.FC<PropsHeaderButton> = ({
  id,
  onClick,
  color = 'primary',
  label = '',
  type = 'basic',
  status = '',
  className = 'ml-auto',
  size = 'sm',
}) => {
  const baseProps: any = {
    color,
    onClick,
    className: `mr-1 ${className}`,
    style: { float: 'right', marginBottom: '0.5rem' },
    size,
  }

  if (size === 'sm') {
    baseProps.style = { ...baseProps.style, padding: '0 0.5rem' }
  }

  return id ? (
    type === 'loader' ? (
      <LoadingButton {...baseProps} loading={status === 'saving'} size={size}>
        {label}
      </LoadingButton>
    ) : (
      <CButton {...baseProps} type='button' size={size}>
        {label}
      </CButton>
    )
  ) : null
}

export default HeaderButton
