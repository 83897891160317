export class ValidateFile {
  file: any
  constructor({ file, maxSize }: any) {
    this.isNull(file)
    this.isFile(file, new RegExp('\\.(pdf)$', 'i'))
    this.isWeightMax(file, maxSize)
    this.file = file
  }
  isNull(file: any) {
    if (typeof file === 'undefined' || file === null) {
      throw new Error('There is no archive')
    }
  }

  isFile(file: any, regex: any) {
    if (!regex.test(file.name)) {
      throw new Error('The file is not an pdf')
    }
  }
  isWeightMax(file: any, maxSizeMib: any) {
    const sizeMib = file.size / 1024 / 1024
    if (sizeMib >= maxSizeMib) {
      throw new Error(`File selected exceeds maximum upload size of ${sizeMib}MB`)
    }
  }
}
