import React from 'react'
import { FieldsSection } from 'src/shared'
import AddressFinderAux from 'src/shared/inputFields/AddressFinderAux'
interface PropsMemberAddress {
  modelData?: any
  handleChange?: any
  handleAddressChange?: any
  modelName?: string
}
const AddressComponent: React.FC<PropsMemberAddress> = ({
  modelData,
  handleChange,
  handleAddressChange,
  modelName = 'Model Name',
}) => {
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g)!.join(' ')
  const { Address, Address2, State, City, ZipCode } = modelData

  const handleAddressDataChange = ({ value }: any) => {
    handleAddressChange(value)
  }

  return (
    <FieldsSection header={`${title} Address`}>
      <AddressFinderAux
        address={{ Address, Address2, City, State, ZipCode }}
        handleChange={handleChange}
        handleAddressDataChange={handleAddressDataChange}
      />
    </FieldsSection>
  )
}
export default React.memo(AddressComponent)
