// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, MODELS, RETRIEVE, SERVICES, LIST } from 'src/services/routeConst'
import { FieldsSection } from 'src/shared'
import Avatar from 'src/shared/components/Avatar/Avatar'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import { RadioGroup, Radio } from 'react-radio-input'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const AppointmentInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const [modal, setModal] = useState(false)
  const toggle = () => {
    // setStateAdd(!stateAdd)
    setModal(!modal)
  }
  const { InspectorsId, InspectionTimeslot } = modelData
  let { InspectionDate } = modelData

  const [inspectorData, setInspectorData] = useState([
    {
      Id: 0,
      ChangedOn: '',
      TokensId_ChangedBy: 0,
      FirstName: '',
      LastName: '',
      PhoneMobile: '',
      Email: '',
      Address: '',
      City: '',
      State: '',
      Zip: '',
      MetasId: 0,
      EinSsn: null,
      HourlyRate: null,
      Timezone: '',
      InspectorType: '',
    },
  ])
  const [, setInspectorList] = useState([])
  // const RadioGroupTs: any = RadioGroup

  const fetchInspectorData = async (InspectorsId) => {
    const { data } = await axios.post(
      `${BASE_API}${SERVICES}${MODELS}/${RETRIEVE}`,
      {
        Model: 'Inspectors',
        Id: InspectorsId,
        Params: [],
      },
      {
        headers: {
          Authorization: `Bearer ${getAuthToken('ADMIN')}`,
          'Content-Type': 'application/json',
        },
      },
    )
    setInspectorData(data.data.RetrieveInfo)
  }
  const fetchInspectorList = async () => {
    const { data } = await axios.post(
      `${BASE_API}${SERVICES}${MODELS}${LIST}`,
      {
        Model: 'Inspectors',
        // Id: InspectorsId,
        Params: [],
      },
      {
        headers: {
          Authorization: `Bearer ${getAuthToken('ADMIN')}`,
          'Content-Type': 'application/json',
        },
      },
    )
    setInspectorList(data.data.ListInfo)
  }
  /*  const updateInspector = async (InspectorId) => {
    modelData.InspectorsId = InspectorId
  } */

  useEffect(() => {
    fetchInspectorList()
    if (InspectorsId) {
      fetchInspectorData(InspectorsId)
    }
  }, [InspectorsId])

  const formatDate = (date) => {
    if (date) {
      date = date.split('-')
      const day = String(date[0])
      const month = String(date[1])
      const year = String(date[2])
      if (day.length === 2) {
        return `${year}-${day}-${month}`
      }
      return `${day}-${month}-${year}`
    }
    return ''
  }
  InspectionDate = formatDate(InspectionDate)

  const card = (
    <div className='external-inspector-card' onClick={toggle} style={{width:'100% !important'}}>
      <div className='item-avatar'>
        {/* <span style={{ border: 'solid 1px #ddd', borderRadius: '100%' }}> */}
        <Avatar
          name={`${inspectorData[0]['FirstName'] || ''} ${inspectorData[0]['LastName'] || ''}`}
          FilesId_Avatar={0}
        ></Avatar>
        {/* </span> */}
      </div>
      <div className='item-name'>
        {inspectorData[0]['FirstName']} {inspectorData[0]['LastName']}
      </div>
      {/* <div className='inspector-price'>
                  <i>
                    ${ Cost['Amount'] ? Cost['Amount'].toFixed(2) : `0.00`}
                  </i>
                </div> */}
      <div className='item-description'>
        {inspectorData[0]['Address']}
        <br />
        {inspectorData[0]['City']} {inspectorData[0]['State']}
        <br />
        {inspectorData[0]['PhoneMobile']}
      </div>
    </div>
  )
  const unassigned = (
    <div className='inspector-card-unassigned' onClick={toggle}>
      Click Here to Assign Inspector
    </div>
  )

  return (
    <>
      <FieldsSection header={title}>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2'>
            <InputLabel label='Date' value={InspectionDate ?? '⠀'} />
          </CCol>
          <CCol md='12' className='pr-lg-2 pl-lg-2'>
            <InputLabel label='Timeslot' value={InspectionTimeslot ?? '⠀'} />
          </CCol>
          {/* <CCol md='8' className='pr-lg-2 pl-lg-2'>
            <div className='radio-group-label'>
              <b>Time Slot</b>
              <RadioGroupTs
                id='radio-appointment'
                name='InspectionTimeslot'
                onChange={(value) => {
                  handleChange({ name: 'InspectionTimeslot', value })
                }}
                className='radio-group-ts custom-radio customer-custom-radio'
                selectedValue={String(modelData.InspectionTimeslot)}
              >
                <label className='radio-group-ts-label' htmlFor='9'>
                  <Radio id='9' value='9' disabled />
                  <div className='radio-group-ts-value'>9</div>
                </label>
                <label className='radio-group-ts-label' htmlFor='11'>
                  <Radio id='11' value='11' disabled />
                  <div className='radio-group-ts-value'>11</div>
                </label>
                <label className='radio-group-ts-label' htmlFor='13'>
                  <Radio id='13' value='13' disabled />
                  <div className='radio-group-ts-value'>13</div>
                </label>
                <label className='radio-group-ts-label' htmlFor='15'>
                  <Radio id='15' value='15' disabled />
                  <div className='radio-group-ts-value'>15</div>
                </label>
                <label className='radio-group-ts-label' htmlFor='17'>
                  <Radio id='17' value='17' disabled />
                  <div className='radio-group-ts-value'>17</div>
                </label>
              </RadioGroupTs>
            </div>
          </CCol> */}
        </CFormGroup>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-0'>
            {InspectorsId ? card : unassigned}
          </CCol>
        </CFormGroup>
      </FieldsSection>

      {/*  <CModal size='xl' show={modal} onClose={toggle}>
        <CModalHeader closeButton>Choose Inspector</CModalHeader>
        <CModalBody className={'appointment-body'}>
         
          <CFormGroup row className={'appointment-modal-formgroup'}>
            <CCol md='2' className='appointment-modal-header appointment-modal-ccol'>
              First Name
            </CCol>
            <CCol md='2' className='appointment-modal-header appointment-modal-ccol'>
              Last Name
            </CCol>
            <CCol md='2' className='appointment-modal-header appointment-modal-ccol'>
              City
            </CCol>
            <CCol md='1' className='appointment-modal-header appointment-modal-ccol'>
              State
            </CCol>
            <CCol md='2' className='appointment-modal-header appointment-modal-ccol'>
              Timezone
            </CCol>
            <CCol md='2' className='appointment-modal-header appointment-modal-ccol'>
              Phone
            </CCol>
            <CCol md='1' className='appointment-modal-header appointment-modal-ccol'></CCol>
          </CFormGroup>
          {inspectorList &&
            inspectorList.map((inspector) => {
              return (
                <CFormGroup
                  row
                  key={inspector['Id']}
                  className={'appointment-modal-formgroup appointment-modal-formgroup-cursor'}
                >
                  <CCol
                    md='2'
                    className='appointment-modal-body appointment-modal-ccol'
                    onClick={() => {
                      updateInspector(inspector['Id'])
                      toggle()
                    }}
                  >
                    {inspector['FirstName']}
                  </CCol>
                  <CCol
                    md='2'
                    className='appointment-modal-body appointment-modal-ccol'
                    onClick={() => {
                      updateInspector(inspector['Id'])
                      toggle()
                    }}
                  >
                    {inspector['LastName']}
                  </CCol>
                  <CCol
                    md='2'
                    className='appointment-modal-body appointment-modal-ccol'
                    onClick={() => {
                      updateInspector(inspector['Id'])
                      toggle()
                    }}
                  >
                    {inspector['City']}
                  </CCol>
                  <CCol
                    md='1'
                    className='appointment-modal-body appointment-modal-ccol'
                    onClick={() => {
                      updateInspector(inspector['Id'])
                      toggle()
                    }}
                  >
                    {inspector['State']}
                  </CCol>
                  <CCol
                    md='2'
                    className='appointment-modal-body appointment-modal-ccol'
                    onClick={() => {
                      updateInspector(inspector['Id'])
                      toggle()
                    }}
                  >
                    {inspector['Timezone']}
                  </CCol>
                  <CCol
                    md='2'
                    className='appointment-modal-body appointment-modal-ccol'
                    onClick={() => {
                      updateInspector(inspector['Id'])
                      toggle()
                    }}
                  >
                    {inspector['PhoneMobile']}
                  </CCol>
                  <CCol
                    md='1'
                    className='appointment-modal-body appointment-modal-ccol appointment-modal-button'
                    onClick={() => {
                      updateInspector(inspector['Id'])
                      toggle()
                    }}
                  >
                    Select
                  </CCol>
                </CFormGroup>
              )
            })}

          <CFormGroup row>
            <CCol></CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton color='secondary' onClick={toggle}>
            Cancel
          </CButton>
        </CModalFooter>
      </CModal> */}
    </>
  )
}

export default AppointmentInfo
