// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const ComplianceInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const { 
    ComplianceSalesforceId,
    ComplianceGreenlight,
    ComplianceSalesforceName,
    ComplianceComplianceStatus,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            label={'Datetime'}
            value={ComplianceSalesforceId ?? '⠀'}
          />
          <InputLabel
            label='Green light'
            value={ComplianceGreenlight ? ComplianceGreenlight.toString() : 'No'}
          />
          <InputLabel
            label='Salesforce Name'
            value={ComplianceSalesforceName ?? '⠀'}
          />
          <InputLabel
            label='Status'
            value={ComplianceComplianceStatus ?? '⠀'}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default ComplianceInfo
