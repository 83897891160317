// Generated with util/create-search-page.js
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { Loader } from 'src/shared'
import { HeaderComponent, InfoComponent } from './components'
import { DATASCHEMAS_MODEL, DATASCHEMAS_ID } from 'src/services/routeConst'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'

const ModelsDetails = ({ history, match, modelName = DATASCHEMAS_MODEL }) => {
  const { id = 0 } = match.params
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id,
    modelName,
    modelsId: DATASCHEMAS_ID,
    history,
  })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData, conectionData } = apiData
  return (
    <CCard>
      <HeaderComponent
        modelName={modelName}
        onCancelHandle={history.goBack}
        status={status}
        saveData={saveData}
      />
      <CCardBody>
        <CRow>
          <CCol xs='12' md='6'>
            <InfoComponent
              modelName={modelName}
              modelData={modelData}
              conectionData={conectionData}
              handleChange={handleChange}
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  )
}

export default ModelsDetails
