import React from 'react'

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
}

export const useGeolocation = () => {
  const [locationData, setLocationData] = React.useState<any>()

  function success(pos: any) {
    var { latitude, longitude, accuracy } = pos.coords
    setLocationData({ latitude, longitude, accuracy })
    // console.log('Your current position is:')
    // console.log(`Latitude : ${latitude}`)
    // console.log(`Longitude: ${longitude}`)
    // console.log(`More or less ${accuracy} meters.`)
  }

  function errors(err: any) {
    console.error(`ERROR(${err.code}): ${err.message}`)
    setLocationData({})
  }

  const getLocationData = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
        if (result.state === 'granted') {
          //If granted then you can directly call your function here
          navigator.geolocation.getCurrentPosition(success)
        } else if (result.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(success, errors, options)
        } else if (result.state === 'denied') {
          //If denied then you have to show instructions to enable location
        }
        result.onchange = function () {}
      })
    } else {
      alert('Sorry Not available!')
    }
  }

  return [locationData, getLocationData]
}
