// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const TimeslotInfo = ({ modelData, handleChange, title = 'Model Name' }: PropsInfoForm) => {
  const { 
    TimeslotRequestId,
    TimeslotDate,
    TimeslotTime,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='TimeslotRequestId'
            type='text'
            label='Timeslot Request Id'
            value={TimeslotRequestId ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='TimeslotDate'
            type='mask'
            mask={[/[0-1]/, /\d/, '/', /[0-3]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
            label='Timeslot Date'
            value={TimeslotDate ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='TimeslotTime'
            label='Timeslot Time'
            type='number'
            value={TimeslotTime ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default TimeslotInfo
