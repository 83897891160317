const UploadIcon = ({ className = '' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 50 50'
      fill='#e0e0e0'
      className={`c-icon mr-2 ${className}`}
      style={{ marginTop: '0.1rem' }}
    >
      <path d='M 25 0.59375 L 24.28125 1.28125 L 16.28125 9.28125 C 15.882813 9.679688 15.882813 10.320313 16.28125 10.71875 C 16.679688 11.117188 17.320313 11.117188 17.71875 10.71875 L 24 4.4375 L 24 32 C 23.996094 32.359375 24.183594 32.695313 24.496094 32.878906 C 24.808594 33.058594 25.191406 33.058594 25.503906 32.878906 C 25.816406 32.695313 26.003906 32.359375 26 32 L 26 4.4375 L 32.28125 10.71875 C 32.679688 11.117188 33.320313 11.117188 33.71875 10.71875 C 34.117188 10.320313 34.117188 9.679688 33.71875 9.28125 L 25.71875 1.28125 Z M 7 16 L 7 50 L 43 50 L 43 16 L 33 16 C 32.640625 15.996094 32.304688 16.183594 32.121094 16.496094 C 31.941406 16.808594 31.941406 17.191406 32.121094 17.503906 C 32.304688 17.816406 32.640625 18.003906 33 18 L 41 18 L 41 48 L 9 48 L 9 18 L 17 18 C 17.359375 18.003906 17.695313 17.816406 17.878906 17.503906 C 18.058594 17.191406 18.058594 16.808594 17.878906 16.496094 C 17.695313 16.183594 17.359375 15.996094 17 16 Z'></path>
    </svg>
  )
}

export default UploadIcon
