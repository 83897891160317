import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const PropertiesTableColumns: ColumnData[] = [
  {
    label: 'GroupName',
    name: 'GroupName',
  },
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Value',
    name: 'Value',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    customWidth: 10,
    sortable: false,
  },
]
