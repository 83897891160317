// Generated with util/create-search-page.js
import { CButton, CCardBody, CCardHeader, CCol, CRow } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { PharmaTableColumns } from './PharmaTableColumns'
import { BASE_API, PHARMA_MODEL, MODELS, SERVICES, LIST, FACILITIES_MODEL } from 'src/services/routeConst'
import { ListModel } from 'src/services/modelNew/List/List'
import { addAuthAccessTokenToHeader, getAuthToken } from 'src/services/authHelper'
import NewPharmaModal from 'src/shared/modals/NewPharmaModal'
import React from 'react'
import { toast } from 'react-toastify'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import Modal from 'react-modal'
import axios from 'axios'
import { Line } from 'rc-progress'
import LoadingButton from 'src/shared/components/LoadingButton'

const fields = ['Id', 'City', 'State', 'ZipCode', 'FirstName', 'LastName', 'Address', 'FacilitiesId']
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
}
const PharmaSearch = ({ history, modelName = PHARMA_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)
  //  const [showNewPharmaModal, setNewPharmaModal] = useState<any>(false)
  const [apiData, setApiData] = useState<any>({
    modelData: {
      FirstName: "",
      LastName: "",
      DateOfBirth: "",
      "City": "",
      "Street": "",
      "State": "",
      "ZipCode": "",
      "PhoneCell": "",
      "MedicareYesNo": "",
      "Bin": "",
      "Pcn": "",
      "InsuranceGroup": "",
      "InsuranceName": "",
      "InsuranceId": "",
    }
  })
  const [modalToShow, setModalToShow] = React.useState<any>()
  const [status, setStatus] = useState<any>('loading')
  const [id, setId] = useState<any>(0)
  const [loggedInUserId, setLoggedInUserId] = useState<any>()
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const [currentApiCount, setApiCount] = useState(0)
  const [countData, setCountData] = useState(0)

  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const changesRef = useRef<any>()


  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function afterOpenModal() { }

  //  const id = 0;

  const viewDetails = ({ id }) => {
    routeToPage(history, '/Admin/Pharma/Detail/' + id)
  }

  const showDeleteModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const showNewPharmaModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setId(id);
    setModalToShow("createPharma")
  }


  const actionFunctions = {
    edit: showNewPharmaModalFunc,
    delete: showDeleteModalFunc,
  }

  const getPharmaProc = async (procName: string) => {
    try {

      const execData = await axios.post(
        `${BASE_API}${SERVICES}/data/execute`,
        {
          DbName: 'DbOltp',
          QueryOrProcedureName: procName,
          Params: [],
        },
        { ...addAuthAccessTokenToHeader() },
      );
      // debugger;

      if (procName == 'jemcare.PHARMA_ListNotSent') {
        let notSentList = execData.data.data.Execution;
        let cPhoneCount = 0
        setCountData(notSentList.length)
        //openModal();
        setStatus("list_not_sent")
        if (notSentList.length == 0) {
          //closeModal();
          setStatus("idle")
        }
        notSentList.forEach((item: any) => {
          //cPhoneCount++;
          if (item.PharmacyGuid != "") {
            fetchStatus(item.PharmacyGuid, item?.Id, cPhoneCount)
          } else {
            fetchIntake(item, cPhoneCount);
          }
        })
        setTimeout(() => {
          setStatus('idle');
        }, 2000);
      } else if (procName == 'jemcare.PHARMA_ListNotClosed') {
        let listNotClosed = execData.data.data.Execution;
        let cPhoneCount = 0
        setCountData(listNotClosed.length)
        setStatus("updating_status")
        //openModal();
        if (listNotClosed.length == 0) {
          setStatus("idle")
          //closeModal();
        }
        await listNotClosed.forEach((item: any) => {
          //cPhoneCount++;
          fetchStatus(item.PharmacyGuid, item?.Id, cPhoneCount);
        });
        //closeModal()


        //closeModal();
      }

      console.log('execData');
      console.log(execData);
      return execData;

      // Id = execData.data.data.Execution[0].Id
    } catch (error) {
      throw error
    }
  }

  useEffect(() => {
    // if (currentApiCount == countData) {
    //   setStatus('idle');
    //   //closeModal();
    // }
    setTimeout(() => {
      setStatus('idle');
    }, 2000);
  }, [currentApiCount]);

  const formatDate = (date) => {
    if (date) {
      date = date.split('-')
      const day = String(date[0])
      const month = String(date[1])
      const year = String(date[2])
      if (day.length === 2) {
        return `${year}-${day}-${month}`
      }
      return `${day}-${month}-${year}`
    }
    return ''
  }


  const fetchIntake = async (pharmaData: any, cPhoneCount: number) => {
    var data = JSON.stringify({
      "firstName": pharmaData?.FirstName,
      "lastName": pharmaData?.LastName,
      "DOB": formatDate(pharmaData?.DateOfBirth),
      "sex": "Male",
      "street": pharmaData?.City,
      "city": pharmaData?.City,
      "state": pharmaData?.State,
      "zip": pharmaData?.ZipCode,
      "phone": pharmaData?.PhoneCell,
      "MedicareYesNo": pharmaData?.MedicareYesNo,
      "BIN": pharmaData?.Bin,
      "PCN": pharmaData?.Pcn,
      "Grp": pharmaData?.InsuranceGroup,
      "insuranceName": pharmaData?.InsuranceName,
      "insuranceID": pharmaData?.InsuranceId
    });
    var config: any = {
      method: 'post',
      url: 'https://api.softsmartinc.com/Services/vOld/Jemcare/Pharma/Intake',
      headers: {
        'Authorization': 'Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9',
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios(config)
      .then(async function (response) {
        if (response?.data.data.IsError == 'No') {
          try {
            fetchStatus(response?.data.data.Id, pharmaData?.Id, cPhoneCount)

            updateRefs()
            setStatus('idle')

          } catch (error) {
            let status = "Server error";
            saveData("", status, pharmaData?.Id, cPhoneCount);
            setStatus('idle')
            //  toast.error('Ups! The action could not be executed')
            console.error(error)
          }
        }
      })
      .catch(function (error) {
        let status = "Server error";
        saveData("", status, pharmaData?.Id, cPhoneCount);
        console.log(error);
      });
  }


  const fetchStatus = async (guid: string, pharmaId: any, cPhoneCount: number) => {
    var data = JSON.stringify({
      "id": guid
    });

    var config: any = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'https://api.softsmartinc.com/Services/vOld/Jemcare/Pharma/Status',
      headers: {
        'Authorization': 'Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9',
        'Content-Type': 'application/json'
      },
      data: data
    };

    await axios(config)
      .then(function (response) {
        let status = response.data.data.Message;
        saveData(guid, status, pharmaId, cPhoneCount);
        //console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        let status = "Server Error";
        saveData(guid, status, pharmaId, cPhoneCount);
        console.log(error);
      });
  }

  const saveData = async (guid: string, status: string, pharmaId: any, cPhoneCount: number) => {
    try {
      //const dataToSave: any = apiData.modelData
      //setStatus('saving')
      const { message } = await SaveModel({

        body: {
          Model: PHARMA_MODEL,
          Params: {
            Id: pharmaId,
            PharmacyGuid: guid,
            IsSent: true,
            PharmacyStatus: status
          }
        },
      })
      cPhoneCount++;
      setApiCount(cPhoneCount);
      console.log("cPhoneCount", cPhoneCount);

      // if (cPhoneCount == 6) {
      //   setTimeout(() => {
      //     closeModal()
      //   }, 14000)
      // }

      //toast.success(message)
      tableRef.current.fetchNewData()
      //updateRefs()
      // setStatus('idle')
    } catch (error) {
      cPhoneCount++;
      setStatus('idle')
      //toast.error('Ups! The action could not be executed')
      console.error(error)
      setApiCount(cPhoneCount);
    }
  }


  const getData = useCallback<any>(
    async (shouldUpdateRefs = true) => {
      try {
        if (id == 0) {
          return;
        }
        // busco dato y no me lo devuelve me devuelve el  defaultMainData
        const { mainData } = await rootRepository.getData({
          id,
          modelName,
          defaultMainData: { Id: 0 },
        })
        const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
        setApiData({ modelData: dataResponse })

        //debugger;

        setStatus('idle')
        // fetchUsersList(dataResponse?.FacilitiesId)
        id && shouldUpdateRefs && updateRefs()
      } catch (error) {
        setStatus('idle')
        console.error(error)
      }
    },
    [rootRepository, id, modelName],
  )

  const updateRefs = () => {
    changesRef?.current?.fetchNewData()
    console.log(changesRef.current)
  }


  useEffect(() => {
    getData(false)
  }, [getData])


  useEffect(() => {
    const loggedInUser: any = localStorage.getItem('VCA_LoggedInUser');
    let loggedInUserRetrieveJson = JSON.parse(loggedInUser);
    setLoggedInUserId(loggedInUserRetrieveJson?.Auth?.User?.FirstName);
  }, []);

  async function sendAllPending(procName: string) {
    let allPendingPharma = await getPharmaProc(procName);
    console.log(allPendingPharma);

  }

  async function updateStatuses(procName: string) {
    let allPharma = await getPharmaProc(procName);
    console.log(allPharma);
  }

  return (
    <>
      <CCardHeader
        className='align-items-center card-accent-secondary'
        style={{
          backgroundColor: '#2A3446',
          marginBottom: '1rem',
          fontSize: 16,
          fontWeight: 600,
          borderRadius: 0,
        }}
      >
        <span style={{ color: 'white' }}>{modelName} Search</span>
        {/* <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => sendAllPending('jemcare.PHARMA_ListNotSent')}
        >
          Send All Pending
        </CButton>
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => updateStatuses('jemcare.PHARMA_ListNotClosed')}
        >
          Update Statuses {currentApiCount} of {countData}
        </CButton> */}

        <LoadingButton
          className='mr-1'
          style={{ float: 'right' }}
          loading={status === 'list_not_sent'}
          onClick={() => sendAllPending('jemcare.PHARMA_ListNotSent')}
        >
          {status === 'list_not_sent' ? "Sending all pending " + currentApiCount + " of " + countData : "Send all pending"}

        </LoadingButton>

        <LoadingButton
          className='mr-1'
          style={{ float: 'right' }}
          loading={status === 'updating_status'}
          onClick={() => updateStatuses('jemcare.PHARMA_ListNotClosed')}
        >
          {status === 'updating_status' ? "Updating Statuses " + currentApiCount + " of " + countData : "Update Statuses"}
        </LoadingButton>


        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => showNewPharmaModalFunc({ id: 0 })}
        >
          New Pharma
        </CButton>
      </CCardHeader>
      <CCardBody className={'custom-card-body'}>
        <DataTable
          columnData={PharmaTableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          queryParams={[{ Name: "UsersId", Value: JSON.parse(localStorage.getItem('VCA_LoggedInUser')).Auth?.User?.Id }]}
          modelName={modelName}
          actionClick={(type, data) => {
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          paginationPosition='bottom-right'
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Pharma', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />


      <NewPharmaModal
        showModal={modalToShow === 'createPharma'}
        //  modalData={{ modelName, label: 'Propertie', id: clickedIDRef.current }}
        //userData=""
        apiData={apiData}
        setApiData={setApiData}
        setShowModal={setModalToShow}
        // selectedFacility={selectedFacility}
        // theUsersData={theUsersData}
        // setSelectedFacility={setSelectedFacility}
        callBack={() => tableRef.current.fetchNewData()}
        pharmaId={id}
      />

      {/* <NewPharmaModal
        showModal={showNewPharmaModal}
        setShowModal={setNewPharmaModal}
        modalData={{ modelName, label: 'Propertie', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      /> */}

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Icon Modal'
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        {/* <button onClick={closeModal}>close</button> */}
        <div className='smsProgressModal'>
          <CRow>
            <CCol>
              <Line
                percent={currentApiCount * (100 / countData)}
                strokeWidth={4}
                trailWidth={4}
                trailColor='#D9D9D9'
                strokeColor='#22e081'
              />
              <p>
                processing {currentApiCount} of {countData}
              </p>
            </CCol>
          </CRow>
        </div>
      </Modal>




    </>
  )
}

export default PharmaSearch
