import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

interface PropsInfoForm {
    name?: any;
    value?: any;
    handleChange?: any;
}

const Editable = ({ name, value, handleChange }: PropsInfoForm) => {

    const [isEdit, setEdit] = useState(false);

    useEffect(() => {

    }, []);

    return (
        <div>
            {isEdit &&
                <Form.Group>
                    <Form.Control
                        type="text"
                        className='editable-input'
                        name={name}
                        value={value}
                        onChange={handleChange}
                    />
                    <button className='btn btn-primary editable-btn'><i className='fa fa-check'></i></button>
                    <button className='btn btn-danger editable-btn' onClick={() => setEdit(false)} ><i className='fa fa-times'></i></button>
                </Form.Group>
            }
            {
                !isEdit && <div>
                    {value != '' && <span className='editable-non-empty-value' onClick={() => setEdit(true)}  >{value}</span>}
                    {value == '' && <span className='editable-empty-value' onClick={() => setEdit(true)}>empty</span>}
                </div>
            }
        </div>
    )
}

export default Editable;