import {
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { RootRepository } from 'src/repository/admin/rootRepository'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'
import GreenCheckmark from 'src/assets/icons/GreenCheckmark'
import RedX from 'src/assets/icons/RedX'

const TestResultsDetailsModal: React.FC<any> = ({ showModal = false, setShowModal, modalData }) => {
  const [loadingData, setLoadingData] = useState<any>(true)
  const [data, setData] = useState<any>()
  const { id, modelName, scenarioData } = modalData

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  useEffect(() => {
    const getDataInEffect = async () => {
      try {
        const { mainData } = await rootRepository.getData({
          id,
          modelName: modelName,
        })
        setData(mainData)
      } catch (error: any) {
        console.error(error)
      }
    }
    loadingData && getDataInEffect()
  }, [id, loadingData, rootRepository, modelName])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    data && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const useEffectTs: any = useEffect
  useEffectTs(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  // const saveData = async () => {
  //   setShowModal(false)
  //   const dataToSave = data
  //   dataToSave.IsDefault = [1, true, 'True'].includes(data.IsDefault) ? 'True' : 'False'
  //   try {
  //     await rootRepository.saveData({
  //       modelName: modelName,
  //       idName: 'BanksId',
  //       dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
  //     })
  //   } catch (error: any) {
  //     console.error(error)
  //   }
  //   // setData({})
  //   callBack()
  // }

  // const handleChange = ({ name = '', value = '' }) => {
  //   name && setData((st: any) => ({ ...st, [name]: value }))
  // }

  // const handleSwitchChange = (name: any, value: any) => {
  //   setData((st: any) => ({
  //     ...st,
  //     [name]: [1, true, 'True'].includes(value) ? 1 : 0,
  //   }))
  // }

  if (loadingData) {
    return null
  }
  // console.log(scenarioData)
  return (
    <CModal
      size='xl'
      show={showModal}
      onClose={() => {
        // setData({})
        setShowModal(false)
      }}
      color={data.Success ? 'success' : 'danger'}
    >
      <CModalHeader closeButton>
        <CModalTitle>
          Test #{data.TestsId} Results - {data.Success ? 'PASSED' : 'FAILED'}
        </CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='Test Results'>
              <CFormGroup row className='align-items-center'>
                <CCol xs='10' sm='5' className='pr-lg-0 mb-3 my-lg-0'>
                  <InputField
                    name='ExpectedStatusCode'
                    type='text'
                    label='Status Code Expected'
                    value={scenarioData?.ExpectedStatusCode}
                    // onChange={handleChange}
                  />
                </CCol>
                <CCol xs='10' sm='5'>
                  <InputField
                    name='StatusCodeReceived'
                    type='text'
                    label='Status Code Received'
                    value={data.StatusCodeReceived}
                    // onChange={handleChange}
                  />
                </CCol>
                <CCol xs='1' className='pl-0'>
                  {scenarioData?.ExpectedStatusCode === data.StatusCodeReceived ? (
                    <GreenCheckmark />
                  ) : (
                    <RedX />
                  )}
                </CCol>
              </CFormGroup>
              <CFormGroup row className='align-items-center'>
                <CCol xs='10' sm='5' className='pr-lg-0 mb-3 my-lg-0'>
                  <InputField
                    name='ExpectedStatusMessage'
                    type='text'
                    label='Status Message Expected'
                    value={scenarioData?.ExpectedStatusMessage}
                    // onChange={handleChange}
                  />
                </CCol>
                <CCol xs='10' sm='5'>
                  <InputField
                    name='StatusMessageReceived'
                    type='text'
                    label='Status Message Received'
                    value={data.StatusMessageReceived}
                    // onChange={handleChange}
                  />
                </CCol>
                <CCol xs='1' className='pl-0'>
                  {scenarioData?.ExpectedStatusMessage === data.StatusMessageReceived ? (
                    <GreenCheckmark />
                  ) : (
                    <RedX />
                  )}
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='RequestJson'
                    labelStyles={{ marginBottom: '1rem' }}
                    rootStyles={{ padding: '20px 15px 5px' }}
                    label='Request Object'
                  >
                    <CustomCodeEditor
                      name='RequestJson'
                      mode='json'
                      value={data.RequestJson}
                      height='250px'
                      // onChange={(value) => handleChange({ name: 'RequestJson', value })}
                      // isValid={isValid}
                      readOnly
                    />
                  </InputField>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='ResponseJson'
                    labelStyles={{ marginBottom: '1rem' }}
                    rootStyles={{ padding: '20px 15px 5px' }}
                    label='Response Object'
                  >
                    <CustomCodeEditor
                      name='ResponseJson'
                      mode='json'
                      value={data.ResponseJson}
                      height='500px'
                      // onChange={(value) => handleChange({ name: 'ResponseJson', value })}
                      // isValid={isValid}
                      readOnly
                    />
                  </InputField>
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      {/* <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            // setData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter> */}
    </CModal>
  )
}

export default TestResultsDetailsModal
