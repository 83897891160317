// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { InputField } from 'src/shared'

const PropertiesInfo = ({ modelData, handleChange }) => {
  const { GroupName, Name, Value } = modelData

  return (
    <>
      <CFormGroup row>
        <CCol xs='6' sm='6'>
          <InputField
            name='GroupName'
            type='text'
            label='GroupName'
            value={GroupName ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='6' sm='6'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={Name ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Value'
            type='text'
            label='Value'
            value={Value ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </>
  )
}

export default PropertiesInfo
