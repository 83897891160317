// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const VehicleConditionsInfo = ({
  modelData,
  handleChange,
  title = 'Model Name',
}: PropsInfoForm) => {
  const {
    KeyQuantity,
    InteriorCondition,
    ExteriorCondition,
    TireCondition,
    ModsEngine,
    ModsExhaustEmissions,
    ModsRaisedSuspension,
    ElectricalRadio,
    ElectricalDvdBluRay,
    ElectricalNavigation,
    ElectricalSunMoonroof,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='KeyQuantity'
            type='number'
            label='Key Quantity'
            value={KeyQuantity ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='InteriorCondition'
            type='number'
            label='Interior Condition'
            value={InteriorCondition ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='ExteriorCondition'
            type='number'
            label='Exterior Condition'
            value={ExteriorCondition ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='TireCondition'
            type='number'
            label='Tire Condition'
            value={TireCondition ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField type='fancyswitch' name='ModsEngine' label={`Modifications Engine`}>
            <CustomSwitch
              name='ModsEngine'
              className='d-block'
              value={ModsEngine}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ModsEngine })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            type='fancyswitch'
            name='ModsExhaustEmissions'
            label={`Modifications Exhaust Emissions`}
          >
            <CustomSwitch
              name='ModsExhaustEmissions'
              className='d-block'
              value={ModsExhaustEmissions}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ModsExhaustEmissions })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            type='fancyswitch'
            name='ModsRaisedSuspension'
            label={`Modifications Raised Suspension`}
          >
            <CustomSwitch
              name='ModsRaisedSuspension'
              className='d-block'
              value={ModsRaisedSuspension}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ModsRaisedSuspension })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField type='fancyswitch' name='ElectricalRadio' label={`Electrical Issues Radio`}>
            <CustomSwitch
              name='ElectricalRadio'
              className='d-block'
              value={ElectricalRadio}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ElectricalRadio })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            type='fancyswitch'
            name='ElectricalDvdBluRay'
            label={`Electrical Issues Dvd BluRay`}
          >
            <CustomSwitch
              name='ElectricalDvdBluRay'
              className='d-block'
              value={ElectricalDvdBluRay}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ElectricalDvdBluRay })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            type='fancyswitch'
            name='ElectricalNavigation'
            label={`Electrical Issues Navigation`}
          >
            <CustomSwitch
              name='ElectricalNavigation'
              className='d-block'
              value={ElectricalNavigation}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ElectricalNavigation })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            type='fancyswitch'
            name='ElectricalSunMoonroof'
            label={`Electrical Issues Sunroof Moonroof`}
          >
            <CustomSwitch
              name='ElectricalSunMoonroof'
              className='d-block'
              value={ElectricalSunMoonroof}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.ElectricalSunMoonroof })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default VehicleConditionsInfo
