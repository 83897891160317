import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const TokensTableColumns: ColumnData[] = [
  {
    label: 'Token Type',
    name: 'TokenType',
  },
  {
    label: 'Token Code',
    name: 'TokenCode',
    type: 'number',
    sortable: false,
  },
  {
    label: 'User Name',
    name: 'UserName',
  },

  {
    label: 'Full Name',
    name: 'FullName',
  },
  {
    label: 'Invalid Logins',
    name: 'InvalidLogins',
    type: 'number',
    sortable: false
  },

  {
    label: 'Is Expired',
    name: 'IsExpired',
    type: 'boolCheckbox',
    sortable: false,
  },
  {
    label: 'Is Locked Out',
    name: 'IsLockedOut',
    type: 'boolCheckbox',
    sortable: false,
  },
  /* {
    label: 'AccessToken',
    name: 'AccessToken',
    dbType: 'varchar',
  }, */

  /*
  {
    label: 'Is Expired',
    name: 'IsExpired',
    dbType: 'bit',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'Invalid Logins',
    name: 'InvalidLogins',
    dbType: 'int',
    type: 'number',
    alignment: 'center',
  },/*
  /*  {
    label: 'IsLockedOut',
    name: 'IsLockedOut',
    dbType: 'bit',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'LockedOutExpired',
    name: 'LockedOutExpired',
    dbType: 'datetime',
  }, */
  /*{
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  }, */
]
