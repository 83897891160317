import React from 'react'
import { CTabPane } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, MODELS, TEST_RESULTS_MODEL } from 'src/services/routeConst'
// import { FieldsSection } from 'src/shared'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import { testResultsTableColumns } from '../scenariosTableColumns'
interface PropsScenariosDetailTabSection {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  Guid?: any
}
const ScenariosDetailTabSection: React.FC<PropsScenariosDetailTabSection> = ({
  id,
  modelName,
  actionFunctions,
  tableRef,
  changesRef,
  Guid,
}) => {
  return (
    <DetailTabSection
      id={id}
      Guio={Guid}
      modelName={modelName}
      tabs={[{ name: 'DataTable', label: 'Test Results' }]}
      changesRef={changesRef}
    >
      <CTabPane data-tab='DataTable' className='detail-tab-section-pane'>
        {/* <FieldsSection header='Test Results'> */}
        <DataTable
          columnData={testResultsTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={TEST_RESULTS_MODEL}
          idName='TestsId'
          actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          rootStyles={{ fontSize: '0.75rem' }}
          apiKey={getAuthToken('ADMIN')}
        />
        {/* </FieldsSection> */}
      </CTabPane>
    </DetailTabSection>
  )
}

export default ScenariosDetailTabSection
