// Generated with util/create-search-page.js
import { CButton } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { FilesTableColumns } from './FilesTableColumns'
import { BASE_API, FILES_MODEL, MODELS, FILES_ID } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'
import ImageModal from './ImageModal'

const ImagesTab = ({ modelName = FILES_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)
  const [showModal, setShowModal] = useState<any>(false)
  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const viewDetails = () => {
    setShowModal(true)
  }

  const showDeleteModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <>
      <CButton
        className='mr-1 ml-auto'
        type='button'
        size='sm'
        color='primary'
        style={{ float: 'right' }}
        onClick={() => {}}
      >
        New Image
      </CButton>
      <br />
      <DataTable
        columnData={FilesTableColumns}
        theme='dark'
        noHeader
        endpoint={BASE_API + '' + MODELS}
        modelName={modelName}
        idName={FILES_ID}
        actionClick={(type, data) => actionFunctions[type]({ id: data })}
        ref={tableRef}
        apiKey={getAuthToken('ADMIN')}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'File', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
      <ImageModal showModal={showModal} setShowModal={setShowModal} />
    </>
  )
}

export default ImagesTab
