import heic2any from 'heic2any'
/* export class isImageValid extends Image {
  
  onload(): void {
    console.log(this.width, this.height)
  }
} */

export class ValidateFile {
  file: any
  constructor({ file, maxSize }: any) {
    this.isNull(file)
    this.isAImage(file, new RegExp('\\.(jpe?g|tiff?|png|webp|heic|heif|jpg)$', 'i'))
    this.isWeightMax(file, maxSize)
    // this.isSizeValid(file, maxWidth, maxHeight)
    this.file = file
  }
  isNull(file: any) {
    if (typeof file === 'undefined' || file === null) {
      throw new Error('There is no archive')
    }
  }

  isAImage(file: any, regex: any) {
    if (!regex.test(file.name)) {
      throw new Error('The file is not an image')
    }
  }
  isWeightMax(file: any, maxSizeMib: any) {
    const sizeMib = file.size / 1024 / 1024
    if (sizeMib >= maxSizeMib) {
      throw new Error('The file size is very large')
    }
  }
}
export class ConvertBlob {
  file: any
  constructor({ file }: any) {
    this.file = file
  }
  async getObjectUrl() {
    const isHeicFormat = new RegExp(/\.(heic|heif)$/, 'i')
    let imageSource = URL.createObjectURL(this.file)
    if (isHeicFormat.test(this.file.name)) {
      const heichImage: any = await heic2any({
        blob: this.file,
        toType: 'image/jpeg',
        // cuts the quality and size by half
      })
      imageSource = URL.createObjectURL(heichImage)
    }
    return imageSource
  }
}

export const getRoundedCanvas = (sourceCanvas: any): HTMLCanvasElement => {
  // console.log(sourceCanvas)
  const canvas = document.createElement('canvas')
  const context: any = canvas.getContext('2d')
  const width = sourceCanvas.width
  const height = sourceCanvas.height
  canvas.width = width
  canvas.height = height
  context.imageSmoothingEnabled = true
  context.drawImage(sourceCanvas, 0, 0, width, height)
  context.globalCompositeOperation = 'destination-in'
  context.beginPath()
  // circle
  // context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI, true)
  // oval
  context.ellipse(width / 2, height / 2, width / 2, height / 2, 0, 0, 2 * Math.PI)
  context.fill()
  return canvas
}
