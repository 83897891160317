// Generated with util/create-search-page.js
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { ApisTableColumns } from './ApisTableColumns'
import { BASE_API, APIS_MODEL, MODELS, APIS_ID } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'
import MemberAvatar from 'src/shared/components/MemberAvatar'

const ApisSearch = ({ history, modelName = APIS_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)

  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }) => {
    routeToPage(history, '/Logs/Apis/Detail/' + id)
  }

  const showDeleteModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions = {
    edit: viewDetails,
    view: viewDetails,
    delete: showDeleteModalFunc,
  }
  console.log(APIS_ID)
  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        {modelName} Search
        {/* <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, '/Logs/Apis/New')}
        >
          New Api
        </CButton> */}
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={ApisTableColumns}
          theme='dark'
          noHeader
          endpoint={BASE_API + ':' + MODELS}
          modelName={modelName}
          idName={APIS_ID}
          actionClick={(type, data) => actionFunctions[type]({ id: data })}
          // queryParams={{ OrderBy: APIS_ID, OrderByDir: 'desc' }}
          scopedSlots={{
            Payload: ({ value }) => {
              // console.log(data)
              return (
                <code style={{ color: '#F2AB26', fontSize: '0.75rem', fontFamily: 'monospace' }}>
                  {value}
                </code>
              )
            },
            Endpoint: ({ value }) => <div style={{ fontFamily: 'Consolas' }}>{value}</div>,
            SouceIp: ({ value }) => <pre style={{ fontFamily: 'Consolas' }}>{value}</pre>,
            AvatarUrl: ({ row }: any) => (
              <div className='d-flex align-items-center'>
                <MemberAvatar
                  name={row.original?.CallerName}
                  // avatar={row.original?.FilesId_Avatar}
                  avatar={null}
                  noChip
                />
                <span className='ml-2'>{row.original?.CallerName ?? ' - '}</span>
              </div>
            ),
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Api', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CCard>
  )
}

export default ApisSearch
