import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import {InputField } from 'src/shared'
interface PropsApplicationsInfo {
  applicationData?: any
  handleChange?: any
  statusAction?: string
}
const ApplicationsInfo: React.FC<PropsApplicationsInfo> = ({
  applicationData,
  handleChange,
  // statusAction,
}) => {
  return (
    <>
    {/* <FieldsSection header='Application Info'> */}
      {/* {statusAction === 'isNew' && (
        <>
          <CFormGroup row>
            <CCol xs='12' sm='12'>
              <InputField
                name='UserName'
                type='text'
                label='UserName'
                value={applicationData?.UserName ?? ''}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol xs='12' sm='12'>
              <InputField
                name='Password'
                type='text'
                label='Password'
                value={applicationData?.Password ?? ''}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup>
        </>
      )} */}
      <CFormGroup row>
        <CCol xs='6' sm='6'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={applicationData?.Name ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='6' sm='6'>
          <InputField
            name='Title'
            type='text'
            label='Title'
            value={applicationData?.Title ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <InputField
            name='FileRepo'
            type='text'
            label='File Repo'
            value={applicationData?.FileRepo ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol>
          <InputField
            name='UserName'
            type='text'
            label='User Name'
            value={applicationData?.Name ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol>
          <InputField
            name='Password'
            type='password'
            showButton
            label='Password'
            value={applicationData?.Password ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <InputField
            name='RootUrl'
            type='text'
            label='Root Url'
            value={applicationData?.RootUrl ?? ''}
            onChange={handleChange}
          />
        </CCol>
        
      </CFormGroup>
      {/* <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='ChangedOn'
            type='text'
            label='Changed On'
            value={applicationData.ChangedOn ?? ''}
          />
        </CCol>
      </CFormGroup> */}
    {/* </FieldsSection> */}
    </>
  )
}

export default ApplicationsInfo
