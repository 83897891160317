// Generated with util/create-search-page.js
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { ProceduresTableColumns } from './ProceduresTableColumns'
import { BASE_API, PROCEDURES_MODEL, MODELS, PROCEDURES_ID } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'

const ProceduresSearch = ({ history, modelName = PROCEDURES_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)

  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }) => {
    routeToPage(history, '/Logs/Procedures/Detail/' + id)
  }

  const showDeleteModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions = {
    view: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        {modelName} Search
        {/* <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, '/Logs/Procedures/New')}
        >
          New Procedure
        </CButton> */}
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={ProceduresTableColumns}
          theme='dark'
          noHeader
          endpoint={BASE_API + ':' + MODELS}
          modelName={modelName}
          idName={PROCEDURES_ID}
          // queryParams={{ OrderBy: APIS_ID, OrderByDir: 'desc' }}
          actionClick={(type, data) => {
            console.log(data)
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          scopedSlots={{
            ProcedureName: ({ value }) => {
              // console.log(data)
              return (
                <code style={{ color: '#F2AB26', fontSize: '0.75rem', fontFamily: 'Consolas' }}>
                  {value}
                </code>
              )
            },
          }}
          apiKey={getAuthToken('ADMIN')}
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Procedure', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CCard>
  )
}

export default ProceduresSearch
