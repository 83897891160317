import ReactDOM from 'react-dom'
// import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
const GoogleMapTs: any = GoogleMap
export default function Gmap(props) {
  function tick() {
    const MapWithAMarker = withScriptjs(
      withGoogleMap(() => (
        <GoogleMapTs defaultZoom={18} defaultCenter={{ lat: props.Lat, lng: props.Lng }}>
          <Marker position={{ lat: props.Lat, lng: props.Lng }} />
        </GoogleMapTs>
      )),
    )

    // let element = (
    //   <h1>.</h1>
    // )

    // if(props.modal === true) {
    let element = (
      <MapWithAMarker
        googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyAQgP--Vn1szfFfrd65OTtudr17d4cxZhE&v=3.exp&libraries=geometry,drawing,places'
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    )
    // }
    ReactDOM.render(element, document.getElementById(props.modelName))
  }

  setTimeout(tick, 1000)

  return <div id={props.modelName}>Loading...</div>
}
