// Generated with util/create-search-page.js
import { CCardBody, CCol, CRow } from '@coreui/react'
import { Loader } from 'src/shared'
import { HeaderComponent, BuyersTabInformation } from './components'
import { BUYERS_MODELS } from 'src/services/routeConst'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'
import { createContext } from 'react'
import NotesInfo from './components/NotesInfo'
// import InspectorProfilePicture from './components/InspectorProfilePicture'

export const LeadContext = createContext<{
  LeadsId: number
  MetasId: number
}>({
  LeadsId: 0,
  MetasId: 0,
})

const ModelsDetails = ({ history, match, modelName = BUYERS_MODELS }) => {
  const { id = 0 } = match.params
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id,
    modelName,
    history,
  })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData } = apiData
  console.log('Buyers', modelData);
  
  return (
    <LeadContext.Provider
      value={{
        LeadsId: id,
        MetasId: modelData?.MetasId ?? 0,
      }}
    >
    <>
      <HeaderComponent
        modelName={modelName}
        onCancelHandle={history.goBack}
        status={status}
        saveData={saveData}
        />
      <>
        <CCardBody className={'custom-card-body'}>
          <CRow>
            <CCol xs={8} className={'p-0'}>
              <BuyersTabInformation
                modelData={modelData}
                // modelDataVehicle={modelDataVehicle}
                // modelDataDispatch={modelDataDispatch}
                handleChange={handleChange}
                // handleChangeMaxLength={handleChangeMaxLength}
                // handleAddressChange={handleAddressChange}
              />
            </CCol>
            <CCol xs={4}>
              {modelData.Id !== 0 ? (
                  <NotesInfo title={'Notes'} id={id} MetasId={modelData.MetasId} modelData={modelData} />
                ) : (
                  <></>
                )}            
            </CCol>
          </CRow>
        </CCardBody>
      </>
    </>
    </LeadContext.Provider>
  )
}

export default ModelsDetails
