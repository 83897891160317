// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { InputField } from 'src/shared'
import { RadioGroup, Radio } from 'react-radio-input'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const InspectorInfo = ({ modelData, handleChange }: PropsInfoForm) => {
  const { Name, Price, Cost, Description, WorkHours, IsModal } = modelData
  const RadioGroupTs: any = RadioGroup
  
  return (
    <>
      <CFormGroup row>
        <CCol md='4'>
          <InputField
            name='Name'
            type='text'
            label='Name'
            value={Name ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol>
          <InputField
            name='Price'
            type='currency'
            label='Price'
            value={ Price ?? ''}
            decimals={2}
            showSeparator
            alignment='right'
            onChange={({ name, value }) => {
              handleChange({
                name,
                value: value.replaceAll('$', ''),
              })
            }}
          />
        </CCol>
        <CCol>
          <InputField
            name='WorkHours'
            type='number'
            label='Work Hours'
            value={WorkHours ?? ''}
            onChange={handleChange}
            alignment='right'
          />
        </CCol>
        <CCol>
          <InputField
            name='Cost'
            type='currency'
            label='Cost'
            value={ Cost ?? ''}
            decimals={2}
            showSeparator
            onChange={({ name, value }) => {
              handleChange({
                name,
                value: value.replaceAll('$', ''),
              })
            }}
            alignment='right'
          />
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol md='8'>
            <div className='radio-group-label'>
              <b>Cost Measure</b>
              <RadioGroupTs
                // label='Cost'
                name='CostMeasure'
                onChange={(value) => {
                  handleChange({ name: 'CostMeasure', value })
                }}
                className='radio-group-ts custom-radio customer-custom-radio'
                selectedValue={String(modelData.CostMeasure)}
              >
                <label className='radio-group-ts-label' htmlFor='PerHour'>
                  <Radio id='PerHour' value='Per Hour' />
                  <div className='radio-group-ts-value'>
                    Per Hour
                  </div>
                </label>
                <label className='radio-group-ts-label' htmlFor='PerJob'>
                  <Radio id='PerJob' value='Per Job' />
                  <div className='radio-group-ts-value'>
                    Per Job
                  </div>
                </label>
              </RadioGroupTs>
            </div>
        </CCol>
        <CCol md='4'>
          <InputField rootClassName='d-block input-field-root custom-styles' type='fancyswitch' name='IsModal' label={`Is Modal Interface`}>
            <CustomSwitch
              name='IsModal'
              className='d-block'
              value={IsModal}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.IsModal })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='12'>
          <InputField
            name='Description'
            type='textarea'
            label='Description'
            value={Description ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      {/*   <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </>
  )
}

export default InspectorInfo
