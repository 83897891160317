import { CFormGroup } from '@coreui/react'
import React from 'react'
import { FileUpload } from 'src/services/modelNew/File/Upload'
import { FieldsSection, Cropper } from 'src/shared'
interface PropsUserProfilePicture {
  userData?: any
  updateProfilePictureID?: any
}
const UserProfilePicture: React.FC<PropsUserProfilePicture> = ({
  userData,
  updateProfilePictureID,
}) => {
  const uploadFile = async (file: any) => {
    // console.log('UPLOAD FLIE', file)
    const { data, error } = await FileUpload({
      modelName: 'Members',
      id: userData.MembersId,
      FilesId: userData.FilesId_Avatar,
      fileToUpload: file,
    })
    !error && data && data.FilesId && updateProfilePictureID(data.FilesId)
  }

  return (
    <FieldsSection header='Profile Picture'>
      <CFormGroup row className='justify-content-center'>
        {/*  src={userData.profile_picture}  */}
        {/** cropShape={'rect' | 'round'} */}
        <Cropper
          cropShape={'round'}
          maxSizeMib={3}
          guid={userData.FilesId_Avatar}
          onChangeCropper={({ originalFile }) => {
            uploadFile(originalFile)
          }}
        />
      </CFormGroup>
    </FieldsSection>
  )
}

export default React.memo(UserProfilePicture)
