// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const DnServicesInfo = ({ title, modelData, handleChange }: PropsInfoForm) => {
  const { 
    DnServiceBasePackage,
    DnServiceMechanicalInspection,
    DnServiceExpressService,
    DnServiceSigningOvernighting,
    DnServicenotary,
  } = modelData
  
  return (
    <>
      <FieldsSection header={title}>
        <CFormGroup row>
          <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField rootClassName='custom-switch d-block input-field-root custom-styles' type='fancyswitch' name='DnServiceBasePackage' label={`Base Package`}>
              <CustomSwitch
                name='DnServiceBasePackage'
                className='d-block'
                value={DnServiceBasePackage}
                updateData={(name: any) => {
                  handleChange({ name, value: !modelData.DnServiceBasePackage })
                  handleChange({ name: 'PagePath', value: '' })
                }}
              />
            </InputField>
          </CCol>
          <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField rootClassName='custom-switch d-block input-field-root custom-styles' type='fancyswitch' name='DnServiceMechanicalInspection' label={`Mechanical Inspection`}>
              <CustomSwitch
                name='DnServiceMechanicalInspection'
                className='d-block'
                value={DnServiceMechanicalInspection}
                updateData={(name: any) => {
                  handleChange({ name, value: !modelData.DnServiceMechanicalInspection })
                  handleChange({ name: 'PagePath', value: '' })
                }}
              />
            </InputField>
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField rootClassName='custom-switch d-block input-field-root custom-styles' type='fancyswitch' name='DnServiceExpressService' label={`Express Service`}>
              <CustomSwitch
                name='DnServiceExpressService'
                className='d-block'
                value={DnServiceExpressService}
                updateData={(name: any) => {
                  handleChange({ name, value: !modelData.DnServiceExpressService })
                  handleChange({ name: 'PagePath', value: '' })
                }}
              />
            </InputField>
          </CCol>
          <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField rootClassName='custom-switch d-block input-field-root custom-styles' type='fancyswitch' name='DnServiceSigningOvernighting' label={`Signing Overnighting`}>
              <CustomSwitch
                name='DnServiceSigningOvernighting'
                className='d-block'
                value={DnServiceSigningOvernighting}
                updateData={(name: any) => {
                  handleChange({ name, value: !modelData.DnServiceSigningOvernighting })
                  handleChange({ name: 'PagePath', value: '' })
                }}
              />
            </InputField>
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField rootClassName='custom-switch d-block input-field-root custom-styles' type='fancyswitch' name='DnServicenotary' label={`Notary`}>
              <CustomSwitch
                name='DnServicenotary'
                className='d-block'
                value={DnServicenotary}
                updateData={(name: any) => {
                  handleChange({ name, value: !modelData.DnServicenotary })
                  handleChange({ name: 'PagePath', value: '' })
                }}
              />
            </InputField>
          </CCol>
        </CFormGroup>
      </FieldsSection>
    </>
  )
}

export default DnServicesInfo
