import { ColumnData } from 'library-soft-datatable'

export const menuTableColumns: ColumnData[] = [
  {
    label: 'Name',
    name: 'Name',
    customWidth: 31,
  },
  {
    label: 'Icon',
    name: 'Icon',
    customWidth: 8,
  },
  {
    label: 'Color',
    name: 'Color',
    type: 'color',
    customWidth: 8,
  },
  {
    label: 'Page Path',
    name: 'PagePath',
    customWidth: 31,
  },
  {
    label: 'Applications',
    name: 'AppName',
    customWidth: 31,
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
    customWidth: 10,
  },
]

export const subMenuTableColumns: ColumnData[] = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Icon',
    name: 'Icon',
    type: 'icon',
  },
  {
    label: 'Color',
    name: 'Color',
    type: 'color',
  },
  {
    label: 'Page Path',
    name: 'PagePath',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: [/**'edit',**/ 'delete'],
    sortable: false,
  },
]
