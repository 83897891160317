import CIcon from '@coreui/icons-react'
import { CButton, CCard, CCardBody, CCardHeader, CCol, CCollapse, CFormGroup } from '@coreui/react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { GenerateMember } from 'src/services/modelNew/Tokens/Tokens'
import { InputField } from 'src/shared'
import LoadingButton from 'src/shared/components/LoadingButton'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'

const getDefaultParams = () => {
  return {
    Email:
      Math.random().toString(36).substring(2, 11) +
      Date.now().toString(36).substring(2, 11) +
      '@vitalcard.xyz',
    Password: '12345678',
    Phone: '7405202906',
  }
}

const MemberGenerateForm = ({ refreshData = () => {} }) => {
  const [generateParams, setGenerateParams] = useState(getDefaultParams())
  const [generateShown, setGenerateShown] = useState(false)
  const [status, setStatus] = useState('idle')

  const resetGenerateForm = () => {
    setGenerateParams(getDefaultParams())
  }

  const handleChange = ({ name, value }) => {
    setGenerateParams((st) => ({ ...st, [name]: value }))
  }

  const handlePhoneChange = ({ name = '', value = '' }) => {
    setGenerateParams((st) => ({
      ...st,
      [name]: value.replace(/[^0-9]/g, ''),
    }))
  }

  const generateMember = async ({ type = 'New' }) => {
    setStatus(`generating_${type.toLowerCase()}`)
    try {
      const { data = {} } = await GenerateMember({ type, params: generateParams })
      const { usersId } = data
      usersId
        ? toast.success(() => (
            <div>
              {type} Member Generated with ID:{' '}
              <Link style={{ color: 'white' }} to={`/Admin/Members/Detail/${usersId}`}>
                {usersId}
              </Link>
            </div>
          ))
        : toast.error(`There was an error creating the ${type} Member`)
      setStatus('idle')
      refreshData()
      resetGenerateForm()
    } catch (error) {
      console.dir(error)
      setStatus('idle')
    }
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        Member Generate
        <CButton className='ml-3' type='button' color='info' onClick={resetGenerateForm}>
          Reset
        </CButton>
        <div className='card-header-actions ml-auto' style={{ float: 'right' }}>
          <LoadingButton
            className='mr-2'
            type='button'
            color='primary'
            loading={status === 'generating_new'}
            onClick={() => generateMember({ type: 'New' })}
          >
            Generate As New
          </LoadingButton>
          <LoadingButton
            className='mr-2'
            type='button'
            color='primary'
            loading={status === 'generating_existing'}
            onClick={() => generateMember({ type: 'Existing' })}
          >
            Generate As Existing
          </LoadingButton>
          <CButton type='button' color='secondary' onClick={() => setGenerateShown((st) => !st)}>
            <div className='d-flex align-items-center'>
              Show Fields
              <CIcon
                name={generateShown ? 'cil-chevron-bottom' : 'cil-chevron-top'}
                className='ml-2'
              />
            </div>
          </CButton>
        </div>
      </CCardHeader>
      <CCollapse show={generateShown}>
        <CCardBody>
          <CFormGroup row>
            <CCol xs='12' lg='4'>
              <InputField
                name='Phone'
                type='phone'
                label='Phone Number'
                value={generateParams.Phone}
                onChange={handlePhoneChange}
              />
            </CCol>
            <CCol xs='12' lg='4' className='px-lg-0 my-3 my-lg-0'>
              <InputField
                name='Email'
                type='email'
                label='Email'
                value={generateParams.Email}
                onChange={handleChange}
              />
            </CCol>
            <CCol xs='12' lg='4'>
              <InputField
                name='Password'
                label='Password'
                type='password'
                value={generateParams.Password}
                onChange={handleChange}
                showButton
              />
            </CCol>
          </CFormGroup>
          {/* <CFormGroup row>
        <CCol xs='4'>
          <InputField
            name='IsNew'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Is New'
          >
            <CustomSwitch
              name='IsNew'
              className='d-block'
              value={IsNew}
              color='success'
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
        </CCardBody>
      </CCollapse>
    </CCard>
  )
}

export default MemberGenerateForm
