import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const ApisTableColumns: ColumnData[] = [
  {
    label: 'Caller',
    name: 'AvatarUrl',
    alignment: 'center',
    type: 'text',
  },
  {
    label: 'SourceIp',
    name: 'SourceIp',
  },
  {
    label: 'SourceBrowser',
    name: 'SourceBrowser',
  },
  {
    label: 'Endpoint',
    name: 'Endpoint',
  },
  {
    label: 'Payload',
    name: 'Payload',
  },
  /* {
    label: 'ResponseInfo',
    name: 'ResponseInfo',
    dbType: 'varchar',
  },
  {
    label: 'Response',
    name: 'Response',
    dbType: 'varchar',
  },
  {
    label: 'StampStart',
    name: 'StampStart',
    dbType: 'datetime',
  },
  {
    label: 'StampEnd',
    name: 'StampEnd',
    dbType: 'datetime',
  },
  {
    label: 'Executiontime',
    name: 'Executiontime',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'IsError',
    name: 'IsError',
    dbType: 'bit',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'ErrorsId',
    name: 'ErrorsId',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  }, */
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]
