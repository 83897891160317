import { CButton, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, REGIONS_MODELS, MODELS, SERVICES, LIST } from 'src/services/routeConst'
import { routeToPage } from 'src/services/routingHelper'
import { DeleteModal } from 'src/shared'
import { TableColumns } from './RegionsTableColumns'

const ModelSearch: React.FC<any> = ({ history, modelName = REGIONS_MODELS }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const clickedIDRef = useRef()
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g)!.join(' ')
  const tableRef = useRef<any>()

  const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Operations/Regions/Detail/${id}`)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <>
      <CCardHeader
        className='d-flex align-items-center'
        style={{
          backgroundColor: '#2A3446',
          marginBottom: '1rem',

          fontSize: 16,
          fontWeight: 600,
        }}
      >
        <span style={{ color: 'white' }}>{title} Search</span>
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, `/Operations/Regions/New`)}
        >
          New Regions
        </CButton>
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={TableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={modelName}
          actionClick={(type: any, data: any) => {
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          paginationPosition='bottom-right'
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'User', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </>
  )
}

export default ModelSearch
