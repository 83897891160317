import React from 'react'
import { CCard, CCardBody, CCol, CRow, CTabPane } from '@coreui/react'
// import { routeToPage } from 'src/services/routingHelper'
import { ApplicationsRepository } from 'src/repository/admin/applications/ApplicationsRepository'
import { Loader } from 'src/shared'
import { ApplicationsHeader, ApplicationsInfo } from './components'
import { APPS_MODEL, TOKENS_MODEL } from 'src/services/routeConst'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import { CreateApplication } from 'src/services/modelNew/Tokens/Application/CreateApplication'
import { toast } from 'react-toastify'
import ApplicationsTokensInfo from './components/AplicationsTokensInfo'
import ApplicationAvatar from './components/AplicationsAvatar'
import ApplicationLogo from './components/ApplicationsLogo'
import NotesInfo from './components/NotesInfo'
import { PROPERTIES_MODEL } from 'src/services/routeConst'
// import { UpdateApplication } from 'src/services/modelNew/Tokens/Application/UpdateApplication'
import { ColumnData } from 'library-soft-datatable'
import { ListModel } from 'src/services/modelNew/List/List'

// Generated with util/create-search-page.js
export const PropertiesTableColumns: ColumnData[] = [
  {
    label: 'GroupName',
    name: 'GroupName',
  },
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Value',
    name: 'Value',
  }
]

interface PropsApplicationDetails {
  history: any
  match?: any
  modelName?: any
  location?: any
}
enum StatusAction {
  isNew = 'isNew',
  isUpdate = 'isUpdate',
}

const ApplicationDetails = ({
  history,
  match,
  modelName = APPS_MODEL,
}: PropsApplicationDetails) => {
  const [apiData, setApiData] = React.useState<any>({ applicationData: null })
  const [propertyData, setPropertyData] = React.useState<any>([])
  const [tokensData, setTokensData] = React.useState<any>({ data: null })
  const [status, setStatus] = React.useState<any>('loading')
  const [statusAction, setStatusActions] = React.useState<StatusAction>(StatusAction.isNew)
  const changesRef = React.useRef<any>()
  const { id = 0 } = match.params
  const fields = ['Id', 'Name', 'Title', 'TokensId', 'RootUrl', 'UserName', 'Password', 'FileRepo']
  const getIdModel = (id: any) => {
    return id === 0 ? 0 : id?.replace(/\D/g, '') ?? 0
  }
  const applicationsRepository = React.useMemo(() => new ApplicationsRepository(), [])
  const getTokenData = React.useCallback(
    async (tokensId) => {
      const { mainData: tokenData } = await applicationsRepository.getData({
        id: tokensId,
        modelName: TOKENS_MODEL,
        defaultMainData: { TokensId: 0 },
      })

      const dataResponseToken = tokenData?.data ? tokenData.data.RetrieveInfo[0] : tokenData
      setTokensData({
        data: dataResponseToken,
      })
    },
    [applicationsRepository],
  )
  const getData = React.useCallback(
    async (shouldUpdateRefs = true) => {
      try {
        const { mainData } = await applicationsRepository.getData({
          id: getIdModel(id),
          modelName,
          defaultMainData: { Id: 0 },
        })
        setStatusActions(StatusAction.isUpdate)
        if (mainData.Id === 0) {
          setStatusActions(StatusAction.isNew)
        }
        const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
        await getTokenData(dataResponse.TokensId)
        setApiData({ applicationData: dataResponse })
        setStatus('idle')

        const { data } = await ListModel({
          body: {
            Model: PROPERTIES_MODEL,
            Params: {
              // ApplicationsId: dataResponse.Id
            }
          }
        })
        setPropertyData(data);

        id && shouldUpdateRefs && updateRefs()
      } catch (error: any) {
        setStatus('idle')
        console.error(error)
      }
    },
    [applicationsRepository, getTokenData, id, modelName],
  )
  const updateRefs = () => {
    // tableRef.current.fetchNewData()
    changesRef.current.fetchNewData()
  }
  React.useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ applicationData: null })
      history.goBack()
    }, delay)
  }
  const saveData = async () => {
    const dataToSave: any = apiData.applicationData
    setStatus('saving')
    try {
      // if (statusAction === StatusAction.isNew) {
      const { message } = await CreateApplication({
        body: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
      })
      toast.success(message)
      // }

      setStatus('idle')
      goBack()
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        applicationData: { ...st.applicationData, [name]: value },
      }))
    // console.log(apiData)
  }
  if (!apiData.applicationData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { applicationData } = apiData
  const { data } = tokensData

  return (
    <>
      <CCard style={{ border: 'none' }}>
        <ApplicationsHeader
          modelName={modelName}
          status={status}
          saveData={saveData}
          onCancel={history.goBack}
        ></ApplicationsHeader>
        <CCardBody>
          <CRow>
            <CCol>
              <DetailTabSection
                id={id}
                Guid={applicationData.Guid}
                modelName={modelName}
                hideNotes
                tabs={[
                  { name: 'Details', label: 'Info' },
                  { name: 'Profile', label: 'Avatar' },
                  { name: 'Logo', label: 'Logo' },
                  { name: 'TokensInfo', label: 'Token' },
                  { name: 'Properties', label: 'Properties' },
                ]}
              >
                <CTabPane data-tab='Details' className='detail-tab-section-pane'>
                  <ApplicationsInfo
                    applicationData={applicationData}
                    handleChange={handleChange}
                    statusAction={statusAction}
                  ></ApplicationsInfo>
                </CTabPane>

                <CTabPane data-tab='Profile' className='detail-tab-section-pane'>
                  {statusAction === StatusAction.isUpdate && (
                    <ApplicationAvatar
                      modelData={applicationData}
                      updateProfilePictureID={() => {}}
                    />
                  )}
                </CTabPane>
                <CTabPane data-tab='Logo' className='detail-tab-section-pane'>
                  {statusAction === StatusAction.isUpdate && (
                    <ApplicationLogo
                      modelData={applicationData}
                      updateProfilePictureID={() => {}}
                    />
                  )}
                </CTabPane>
                <CTabPane data-tab='TokensInfo' className='detail-tab-section-pane'>
                  {statusAction === StatusAction.isUpdate && (
                    <>
                      {Object.keys(data).length ? (
                        <ApplicationsTokensInfo
                          handleChange={handleChange}
                          handleChangeToken={getTokenData}
                          data={data}
                          history={history}
                        />
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </CTabPane>
                <CTabPane data-tab='Properties' className='detail-tab-section-pane'>
                  {statusAction === StatusAction.isUpdate && (
                    <>
                      { propertyData.map((f:any, index: any) => {
                        return <CCard key={index}>
                          <CCardBody>
                          <div style={{ color: 'dark !important' }}>
                            <div>
                              <b> Group Name:</b> {f.GroupName}
                            </div>
                            <div>
                              <b> Property Name:</b> {f.PropertyName}
                            </div>
                            <div>
                              <b> Value:</b> {f.Value}
                            </div>
                          </div>
                          </CCardBody>
                        </CCard>
                        })
                      }
                    </>
                  )}
                </CTabPane>
              </DetailTabSection>
            </CCol>
            <CCol xs={5} style={{ marginTop: '0' }}>
              {applicationData.Id !== 0 && applicationData.MetasId !== null ? (
                <DetailTabSection
                  id={0}
                  metasId={applicationData.MetasId}
                  hideNotes
                  tabs={[{ name: 'Notes', label: 'Notes' }]}
                >
                  <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
                    <NotesInfo MetasId={applicationData.MetasId}></NotesInfo>
                  </CTabPane>
                </DetailTabSection>
              ) : (
                <></>
              )}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      {/* <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName: 'Applications', label: 'Application', id: clickedIDRef.current }}
        callBack={getData}
      /> */}
    </>
  )
}

export default ApplicationDetails
