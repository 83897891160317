import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const ProcsTableColumns: ColumnData[] = [
  {
    label: 'Procedure Name',
    name: 'ProcedureName',
  },
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Database Name',
    name: 'DatabaseName',
  },
  {
    label: 'SchemaName',
    name: 'SchemaName',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
