// Generated with util/create-search-page.js
import { CButton, CCardHeader } from '@coreui/react'
// import LoadingButton from 'src/shared/components/LoadingButton'

const ApisHeader = ({ onCancel }) => {
  // const isLoading = status === 'saving'
  return (
    <CCardHeader className='d-flex align-items-center card-accent-secondary'>
      Api Details
      {/* <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton> */}
      <CButton
        className='mr-1 ml-auto mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancel}
      >
        Cancel
      </CButton>
    </CCardHeader>
  )
}

export default ApisHeader
