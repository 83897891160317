import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react'
import { CCard, CCardBody, CCol, CRow, CTabPane } from '@coreui/react'
import { connect } from 'react-redux'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { Loader } from 'src/shared'
import { FacilitiesHeader, FacilitiesInfo } from './components'
import { FACILITIES_MODEL } from 'src/services/routeConst'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { ListModel } from 'src/services/modelNew/List/List'
import { toast } from 'react-toastify'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import NotesInfo from './components/NotesInfo'
// import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'

const fields = ['Id', 'Code', 'Name', 'City', 'StateCode', 'ZipCode', 'Phone', 'UsersList']

const FacilitiesDetails: React.FC<any> = ({ history, match, modelName = FACILITIES_MODEL, applicationRetrieve = {} }) => {
  const [apiData, setApiData] = useState<any>({ modelData: null })
  const [status, setStatus] = useState<any>('loading')
  const [usersData, setUsersData] = useState<any>({ modelData: null })
  const changesRef = useRef<any>()
  console.log('applicationRetrieve');
  console.log(applicationRetrieve);
  
  const { id = 0 } = match.params

  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const fetchUsersList = useCallback<any>(async () => {
    const { data } = await ListModel({
      body: {
        Model: 'AdminUsers',
        Extension: null,
        Params: {
          ApplicationsId: 131  
        },
      },
    })
    setUsersData(data);
  }, [])

  useEffect(() => {
    fetchUsersList()
  }, [])


  const getData = useCallback<any>(
    async (shouldUpdateRefs = true) => {
      try {
        // busco dato y no me lo devuelve me devuelve el  defaultMainData
        const { mainData } = await rootRepository.getData({
          id,
          modelName,
          defaultMainData: { Id: 0 },
        })
        const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
        setApiData({ modelData: dataResponse })
        setStatus('idle')
        id && shouldUpdateRefs && updateRefs()
      } catch (error) {
        setStatus('idle')
        console.error(error)
      }
    },
    [rootRepository, id, modelName],
  )

  const updateRefs = () => {
    changesRef?.current?.fetchNewData()
    console.log(changesRef.current)
  }
  useEffect(() => {
    getData(false)
  }, [getData])

  const goBack = (delay = 0) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        setApiData({ menuData: null })
        history.goBack()
        resolve(true)
      }, delay)
    })
  }

  const saveData = async () => {
    try {
      const dataToSave: any = apiData.modelData
      setStatus('saving')
      const { message } = await SaveModel({
        body: {
          Model: modelName,
          Params: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
        },
      })
      toast.success(message)
      updateRefs()
      setStatus('idle')
      await goBack(250)
    } catch (error) {
      setStatus('idle')
      toast.error('Ups! The action could not be executed')
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    console.log('value');
    console.log(value);
    
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }

  if (!apiData.modelData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  const { modelData } = apiData
  return (
    <CCard style={{ border: 'none' }}>
      <FacilitiesHeader status={status} saveData={saveData} onCancel={history.goBack} />
      
      <CCardBody className={'custom-card-body'}>
        <CRow>
          <CCol>
            <DetailTabSection
              id={id}
              hideNotes={true}
              modelName={modelName}
              tabs={[
                { name: 'Details', label: 'Details' },
              ]}
            >
              <CTabPane data-tab='Details' className='detail-tab-section-pane'>
                <CRow>
                  <CCol xs='12' md='12'>
                  <FacilitiesInfo modelData={modelData} handleChange={handleChange} usersData={usersData} />
                  </CCol>
                </CRow>
              </CTabPane>
             
            </DetailTabSection>
          </CCol>
          <CCol xs='5'>
            <DetailTabSection
              id={id}
              hideNotes={true}
              modelName={modelName}
              tabs={[{ name: 'Notes', label: 'Notes' }]}
            >
              <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
                <NotesInfo MetasId={modelData.MetasId}></NotesInfo>
              </CTabPane>
            </DetailTabSection>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  )
}

const mapStateToProps = (state: any) => {
  const {  auth } = state
  return {
    applicationRetrieve : auth.applicationRetrieve
  }
}

export default React.memo(connect(mapStateToProps, null)(FacilitiesDetails))
//export default FacilitiesDetails
