import { useCallback, useEffect, useMemo, useState } from 'react'
import { StatusInfo } from '../enum/Status'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'
import { addAuthAccessTokenToHeader } from 'src/services/authHelper'
import axios from 'axios'
// import axios from 'axios'
// import { addAuthToHeader } from 'src/services/authHelper'

interface PropsActionsModels {
  id: any
  modelName: any
  modelNameDispatch: any
  modelNameVehicle: any
  history: any
  modelsId?: string
}
type ApiDataState = { modelData: any }
type ApiDataStateVehicle = { modelDataVehicle: any }
type ApiDataStateDispatch = { modelDataDispatch: any }
const fields: string[] = [
  'Id',
  'FirstName',
  'LastName',
  'PhoneMobile',
  'PhoneOther',
  'Address',
  'City',
  'Zip',
  'PickupAddress',
  'PickupCity',
  'PickupState',
  'PickupZip',
  'DropoffAddress',
  'DropoffCity',
  'DropoffState',
  'DropoffZip',

  // 'VehiclesId',
  // 'IsTitleInHand',
  // 'FinalOffer',
  // 'DueCustomer',
  // 'PaymentMethod',
  // 'RegisteredOwner',
  // 'IsLienholder',
  // 'Lienholder',
  // 'Payoff',
  // 'PayoffExpiresOn',
  // 'LastTitleIssuedOn',
  // 'TitleNumber',
  // 'LastOdometer',

  'Vehicle',
  'VehicleVin',
  'VehicleMileage',
  'VehicleColor',
  'SourceName',
  'SourceCode',

  'InspectorsId',
  'InspectionDate',
  'InspectionTimeslot',

  'SourceCode',
  'SourceName',

  'IsNeedFollowUp',
  'FollowUpDate',

]
/*const fieldsDispatchs: string[] = [
  'Id',

  'NotesDateTime',
  'NotesSubject',
  'NotesMessage',

  'ComplianceSalesforceId',
  'ComplianceGreenlight',
  'ComplianceSalesforceName',
  'ComplianceComplianceStatus',

  'TitleNumber',
  'TitleDualOwnershipStatus',
  'TitleRegisteredOwner1',
  'TitleRegisteredOwner2',
  'TitleRegistrationValidThrough',
  'TitleLienInfo',
  'TitleLienHolder',
  'TitleTitleType',
  'TitleTitleStatus',

  'HistoryAutocheckVerification',
  'HistoryAutocheckNotes',
  'HistoryCoPartNotes',
  'HistoryGoogleVerification',
  'HistoryNmvtisVerification',
  'HistoryCarfaxVerification',
  'HistoryCarfaxNotes',
  'HistoryCoPartVerification',
  'HistoryKsrNotes',
  'HistoryGoogleNotes',
  'HistoryNmvtisNotes',
  'HistoryKsrVerification',

  'PaymentVehiclePurchasePrice',
  'PaymentPayoffExpiration',
  'PaymentPayoffOvernightAddress',
  'PaymentPayoffAmount',
  'PaymentPayoffDailyPerDiem',
  'PaymentTotalAmountToCustomer',
  
  'DeductionAmount',
  'DeductionNotes',
  
  'Guid',

  // 'ApptPickupAddress',
  'ApptPickupAddressCity',
  'ApptPickupAddressState',
  'ApptPickupAddressStreet',
  'ApptPickupAddressZip',
  'ApptPickupAddressCity',
  
  // 'ApptTimeSlotRequestId',
  'ApptTimeSlotStartTime',
  'ApptTimeSlotStartDate',

  'DnServiceBasePackage',
  'DnServiceMechanicalInspection',
  'DnServiceExpressService',
  'DnServiceSigningOvernighting',
  'DnServicenotary',
  
  'CustomerName',
  'CustomerFirstName',
  'CustomerLastName',
  'CustomerMobile',
  'CustomerOtherPhone',
  'CustomerAddressCity',
  'CustomerAddressState',
  'CustomerAddressZip',
  'CustomerAddressStreet',

  'VehicleVin',
  'VehicleMileage',
  'VehicleYear',
  'VehicleMake',
  'VehicleModel',
  'VehicleTrim',
  'VehicleColor',
  'AccidentHadAccident',
  'AccidentRepaired',
  'AccidentEstimate',
  'AccidentRepairedCost',
  'AccidentEstimatedCost',
  'ConditionKeyQuantity',
  'ConditionInteriorCondition',
  'ConditionExteriorCondition',
  'ConditionTireCondition',
  'ModificationsEngine',
  'ModificationsExhaustEmissions',
  'ModificationsRaisedSuspension',
  'ModificationsLoweredSuspension',
  'ElectricalIssuesRadio',
  'ElectricalIssuesDvdBluRay',
  'ElectricalIssuesNavigation',
  'ElectricalIssuesSunroofMoonroof',
  'MechIssuesEngine',
  'MechIssuesTransmission',
  'MechIssuesAircon',
  'MechIssuesHeater',
  'MechIssuesPowerLiftgate',
  'MechIssuesPowerSlidingdoors',
  'InspectionCustomerPhotoToolUrl',
  'InspectionInspectorPhotoToolUrl',

]*/
// const fieldsVehicles: string[] = [
//   'Id',
//   'Vin',
//   'Mileage',
//   'VehicleYear',
//   'VehicleMake',
//   'VehicleModel',
//   'VehicleTrim',
//   'HadAccident',
//   'AccidentRepaired',
//   'AccidentEstimate',
//   'AccidentRepairedCost',
//   'AccidentEstimatedCost',
//   'KeyQuantity',
//   'InteriorCondition',
//   'ExteriorCondition',
//   'TireCondition',
//   'ModsEngine',
//   'ModsExhaustEmissions',
//   'ModsRaisedSuspension',
//   'ElectricalRadio',
//   'ElectricalDvdBluRay',
//   'ElectricalNavigation',
//   'ElectricalSunMoonroof',
//   'MechEngine',
//   'MechTransmission',
//   'MechAir',
//   'MechHeater',
//   'MechPowerLiftgate',
//   'MechPowerSlidingdoors',
//   'VehicleColor',
//   'VehicleBody',
//   'VehicleTransmission',
// ]

export const useActionsModels = ({
  id,
  modelName,
  modelNameDispatch,
  // modelNameVehicle,
  modelsId = 'Id',
  history,
}: PropsActionsModels): [
    ApiDataState,
    ApiDataStateVehicle,
    ApiDataStateDispatch,
    StatusInfo,
    () => Promise<void>,
    Function,
    Function,
    Function,
  ] => {
  const [apiData, setApiData] = useState<ApiDataState>({ modelData: null })
  const [apiDataDispatch, setApiDataDispatch] = useState<ApiDataStateDispatch>({
    modelDataDispatch: null,
  })
  const [apiDataVehicle/*, setApiDataVehicle*/] = useState<ApiDataStateVehicle>({
    modelDataVehicle: null,
  })
  const [status, setStatus] = useState<StatusInfo>(StatusInfo.loading)
  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const [idDispatch, setIdDispatch] = useState(0)
  // const [idVehicle, setIdVehicle] = useState(0)

  const getData = useCallback<any>(async () => {
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [modelsId]: 0 },
      })
      const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
      console.log('Dataresponse', dataResponse);

      setApiData({ modelData: dataResponse })
      setStatus(StatusInfo.idle)
      await setIdDispatch(dataResponse.SourceCode)
      // await setIdVehicle(dataResponse.VehiclesId)
    } catch (error) {
      setStatus(StatusInfo.idle)
    }
  }, [rootRepository, id, modelName, modelsId])

  useEffect(() => {
    // POST request using axios inside useEffect React hook
    if (idDispatch !== 0 || !idDispatch) {
      axios
        .post(
          `https://api.softsmartinc.com/Services/vOld/Models/Retrieve`,
          {
            Model: modelNameDispatch,
            Id: idDispatch,
            Params: [],
          },
          { ...addAuthAccessTokenToHeader() },
        )
        .then((response) => {

          setApiDataDispatch({ modelDataDispatch: response.data.data.RetrieveInfo[0] })
          console.log('response', response.data);
        })
    }
  }, [idDispatch, modelNameDispatch])
  // useEffect(() => {
  //   // POST request using axios inside useEffect React hook
  //   axios
  //     .post(
  //       `https://api.softsmartinc.com/Services/vOld/Models/Retrieve`,
  //       {
  //         Model: modelNameVehicle,
  //         Id: idVehicle,
  //         Params: [],
  //       },
  //       { ...addAuthAccessTokenToHeader() },
  //     )
  //     .then((response) => setApiDataVehicle({ modelDataVehicle: response.data.data[0] }))
  // }, [idVehicle, modelNameVehicle])

  useEffect(() => {
    getData()
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ modelData: null })
      history.goBack()
    }, delay)
  }
  const mappingFields = (apiData) => {
    const dataToSave: any = apiData.modelData
    return {
      ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
    }
  }

  // const mappingFieldsDispatchs = (apiDataDispatch) => {
  //   const dataToSave: any = apiDataDispatch.modelDataDispatch
  //   return {
  //     ...Object.assign({}, ...fieldsDispatchs.map((field) => ({ [field]: dataToSave[field] }))),
  //   }
  // }

  // const mappingFieldsVehicles = (apiDataVehicle) => {
  //   const dataToSave: any = apiDataVehicle.modelDataVehicle
  //   return {
  //     ...Object.assign({}, ...fieldsVehicles.map((field) => ({ [field]: dataToSave[field] }))),
  //   }
  // }
  const saveData = async () => {
    setStatus(StatusInfo.saving)

    try {
      const { message } = await SaveModel({
        body: {
          Model: modelName,
          Params: mappingFields(apiData),
        },
      })

      // if(apiData.modelData.SourceName !== 'Quantum Manual'){
      //   await SaveModel({
      //     body: {
      //       Model: modelNameDispatch,
      //       Params: mappingFieldsDispatchs(apiDataDispatch),
      //     },
      //   })
      // }

      // await SaveModel({
      //   body: {
      //     Model: modelNameVehicle,
      //     Params: mappingFieldsVehicles(apiDataVehicle),
      //   },
      // })

      toast.success(message)
      getData()
      goBack()
      setStatus(StatusInfo.idle)
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))

    name &&
      setApiDataDispatch((st: any) => ({
        ...st,
        modelDataDispatch: { ...st.modelDataDispatch, [name]: value },
      }))
    // name &&
    //   setApiDataVehicle((st: any) => ({
    //     ...st,
    //     modelDataVehicle: { ...st.modelDataVehicle, [name]: value },
    //   }))
  }
  const handleChangeMaxLength = ({ name = '', value = '' }) => {
    if (value.length <= 17) {
      name &&
        setApiData((st: any) => ({
          ...st,
          modelData: { ...st.modelData, [name]: value },
        }))

      name &&
        setApiDataDispatch((st: any) => ({
          ...st,
          modelDataDispatch: { ...st.modelDataDispatch, [name]: value },
        }))
    }
  }
  const handleAddressChange = (...data) => {
    const addressValue = (data[0] ?? {}) as Object

    setApiData((st: any) => ({
      ...st,
      modelData: { ...st.modelData, ...addressValue },
    }))
  }
  return [apiData, apiDataVehicle, apiDataDispatch, status, saveData, handleChange, handleChangeMaxLength, handleAddressChange]
}
