// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const AccidentHistoryInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const {
    HadAccident,
    AccidentRepaired,
    AccidentEstimate,
    AccidentRepairedCost,
    AccidentEstimatedCost
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            // type='fancyswitch'
            // name='HadAccident'
            label={`Had Accident`}
            value={HadAccident ? HadAccident.toString() : '⠀'}
          />
          {/* <InputField 
            type='fancyswitch'
            name='HadAccident'
            label={`Had Accident`}
          >
            <CustomSwitch
              name='HadAccident'
              className='d-block'
              value={HadAccident}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.HadAccident })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField> */}
          <InputLabel 
            label={`Repaired`}
            value={AccidentRepaired ? AccidentRepaired.toString() : '⠀'}
          />
          <InputLabel 
            label={`Estimate`}
            value={AccidentEstimate ? AccidentEstimate.toString() : '⠀'}
          />
          <InputLabel 
            label={`Repaired Cost`}
            value={AccidentRepairedCost ? ('$' + AccidentRepairedCost) : '⠀'}
            // alignment='right'
            />
          <InputLabel
            label='Estimated Cost'
            value={AccidentEstimatedCost ? ('$'+AccidentEstimatedCost) : '⠀'}
            // alignment='right'
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default AccidentHistoryInfo
