import React from 'react'
import { CTabPane } from '@coreui/react'

// import { FieldsSection } from 'src/shared'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import ImagesTab from './ImagesTab'
import ClientsTab from './ClientsTab'
interface PropsScenariosDetailTabSection {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  Guid?: any
}
const ScenariosDetailTabSection: React.FC<PropsScenariosDetailTabSection> = ({
  id,
  modelName,
  changesRef,
  Guid,
}) => {
  return (
    <DetailTabSection
      id={id}
      Guio={Guid}
      modelName={modelName}
      tabs={[
        { name: 'Clients', label: 'Clients' },
        { name: 'Images', label: 'Images' },
      ]}
      changesRef={changesRef}
    >
      <CTabPane data-tab='Images' className='detail-tab-section-pane'>
        <ImagesTab />
      </CTabPane>
      <CTabPane data-tab='Clients' className='detail-tab-section-pane'>
        <ClientsTab />
      </CTabPane>
    </DetailTabSection>
  )
}

export default ScenariosDetailTabSection
