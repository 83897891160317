// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'

import { FieldsSection, InputField } from 'src/shared'

interface PropsInfoForm {
  modelData: any
  handleChange: any
  handleAddressChange: any
  title: string
}

const SourceDetail = ({ modelData, handleChange, title = 'Model Name' }: PropsInfoForm) => {
  const { SourceDetails } = modelData
  modelData.SourceName = 'Quantum Manual'
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='SourceDetails'
            type='textarea'
            inputStyles={{ height: '335px', marginBottom: '0px' }}
            label='Source Details'
            value={SourceDetails ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default SourceDetail
