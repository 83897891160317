// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
const TokensInfo = ({ modelData, handleChange }) => {
  const {
    TokenType,
    TokenCode,
    AccessToken,
    LoginDateTime,
    StampStart,
    StampEnd,
    IsExpired,
    InvalidLogins,
    IsLockedOut,
    LockedOutExpired,
    FullName,
    UserName,
    Password,
  } = modelData

  return (
    <FieldsSection header='Token Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='UserName'
            type='text'
            label='UserName'
            value={UserName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Password'
            type='text'
            label='Password'
            value={Password}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='FullName'
            type='text'
            label='Full Name'
            value={FullName}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='TokenType'
            type='text'
            label='TokenType'
            value={TokenType}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='TokenCode'
            type='text'
            label='TokenCode'
            value={TokenCode}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='AccessToken'
            type='text'
            label='AccessToken'
            value={AccessToken}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='LoginDateTime'
            type='text'
            label='LoginDateTime'
            value={LoginDateTime}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='StampStart'
            type='text'
            label='StampStart'
            value={StampStart}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='StampEnd'
            type='text'
            label='StampEnd'
            value={StampEnd}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='IsExpired'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsExpired'
          >
            <CustomSwitch
              name='IsExpired'
              className='d-block'
              value={IsExpired}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='InvalidLogins'
            type='text'
            label='InvalidLogins'
            value={InvalidLogins}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='IsLockedOut'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsLockedOut'
          >
            <CustomSwitch
              name='IsLockedOut'
              className='d-block'
              value={IsLockedOut}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='LockedOutExpired'
            type='text'
            label='LockedOutExpired'
            value={LockedOutExpired}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default TokensInfo
