import { ColumnData } from 'library-soft-datatable'

export const roleTableColumns: ColumnData[] = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: 'Application Name',
    name: 'ApplicationName',
  },
  {
    label: 'Total of Users',
    name: 'NoOfUsers',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    customWidth: 10,
    sortable: false,
  },
]

export const usersWithRoleTableColumns: ColumnData[] = [
  {
    label: 'First Name',
    name: 'FirstName',
  },
  {
    label: 'Last Name',
    name: 'LastName',
  },
  {
    label: 'Phone',
    name: 'Phone',
  },

  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]

export const menuWithRoleTableColumns: ColumnData[] = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]
export const subMenuWithRoleTableColumns: ColumnData[] = [
  {
    label: 'Main Menu',
    name: 'MainMenuName',
  },
  {
    label: 'Sub Menu',
    name: 'Name',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]
