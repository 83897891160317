// Generated with util/create-search-page.js
import { CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef } from 'react'
import { TokensTableColumns } from './TokensTableColumns'
import { BASE_API, TOKENS_MODEL, MODELS, SERVICES, LIST } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'

const TokensSearch = ({ modelName = TOKENS_MODEL }) => {
  const tableRef = useRef<any>()

  return (
    <>
      <CCardHeader
        className='d-flex align-items-center card-accent-secondary'
        style={{ backgroundColor: '#2A3446', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}
      >
        <span style={{ color: 'white' }}>{modelName} Search</span>
      </CCardHeader>
      <CCardBody className={'custom-card-body'}>
        <DataTable
          columnData={TokensTableColumns}
          theme='white'
          noHeader
          endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
          modelName={modelName}
          scopedSlots={{
            TokenType: ({ value }) => {
              return <div style={{ color: '#F2AB26' }}>{value}</div>
            },
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
        />
      </CCardBody>
    </>
  )
}

export default TokensSearch
