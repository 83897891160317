import {
  CButton,
  CCardFooter,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalHeader,
} from '@coreui/react'
import { ComboBox, InputField, Loader } from 'src/shared'
import { StatusInfo, useActionsModels } from './hooks/ActionsModels'
import { CUSTOMERS_SERVICES_MODEL } from 'src/services/routeConst'
import CIcon from '@coreui/icons-react'

type Props = {
  id: any
  showModal: boolean
  setShowModal: Function | VoidFunction | any
  refTable: any
}

const DetailModal = ({ showModal, setShowModal, id, refTable }: Props) => {
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id: id,
    modelName: CUSTOMERS_SERVICES_MODEL,
  })
  const mapComboToData = () =>
    apiData.allServicesData.map((route) => {
      return {
        CodeId: route.Id,
        Description: route.Name,
      }
    })
  const save = async () => {
    try {
      await saveData()
      refTable.current.fetchNewData()
      setShowModal(false)
    } catch (error) {
      throw new Error()
    }
  }
  const { modelData, allServicesData } = apiData
  return (
    <CModal
      show={showModal}
      onClose={() => {
        setShowModal((modal: any) => !modal)
      }}
    >
      <CModalHeader closeButton>Customer Service</CModalHeader>
      {!apiData.modelData || status === StatusInfo.loading ? (
        <Loader fullScreen height='100%' />
      ) : (
        <CModalBody>
          <div style={{ width: '100%' }}>
            <CFormGroup row>
              <CCol>
                <InputField
                  name='Name'
                  type='text'
                  label='Name'
                  value={modelData?.Name ?? ''}
                  onChange={(value) => {
                    console.log(value)
                    handleChange(value)
                  }}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol>
                <InputField
                  name='Cost'
                  type='number'
                  label='Cost'
                  value={modelData?.Cost ?? ''}
                  onChange={handleChange}
                />
              </CCol>
              <CCol>
                <InputField
                  name='Price'
                  type='number'
                  label='Price'
                  value={modelData?.Price ?? ''}
                  onChange={handleChange}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol xs='12'>
                <ComboBox
                  showButton
                  name='ServicesId'
                  label='Parent Menu'
                  data={mapComboToData()}
                  value={allServicesData.find(
                    (item: any) => item.Id === apiData.modelData.ServicesId,
                  )}
                  handleChange={(...params) => {
                    const [, newValue] = params
                    handleChange({ name: 'ServicesId', value: newValue?.CodeId ?? null })
                  }}
                  classNames={{ input: 'input-field' }}
                  customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                  renderOption={(option: any, { selected }: any) => (
                    <div className={selected ? 'combo-selected' : ''}>
                      <CIcon name={option.Icon} className='mr-2' /> {option.Description}
                    </div>
                  )}
                />
              </CCol>
            </CFormGroup>
            <CFormGroup row>
              <CCol>
                <InputField
                  name='Description'
                  type='textarea'
                  label='Description'
                  value={modelData?.Description ?? ''}
                  onChange={handleChange}
                />
              </CCol>
            </CFormGroup>
          </div>
        </CModalBody>
      )}
      <CCardFooter>
        <CButton color='primary' onClick={save}>
          Save
        </CButton>
      </CCardFooter>
    </CModal>
  )
}
export default DetailModal
