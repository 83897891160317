import { SETSIDEBARSHOW } from '../actions/SidebarActions'

const initialState = { sidebarShow: 'responsive' }

function sidebarReducer(state = initialState, action: any) {
  switch (action.type) {
    case SETSIDEBARSHOW:
      return { ...state, sidebarShow: action.payload }
    default:
      return state
  }
}

export default sidebarReducer
