import { CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, Cropper } from 'src/shared'
const MemberProfilePicture = () => {
  return (
    <FieldsSection header='Profile Picture'>
      <CFormGroup row className='justify-content-center'>
        {/*  src={memberData.profile_picture}  */}
        {/** cropShape={'rect' | 'round'} */}
        <Cropper
          cropShape={'rect'}
          maxSizeMib={3}
          guid={54}
          onChangeCropper={(e: any) => {
            console.log('file:', e)
          }}
        />
      </CFormGroup>
    </FieldsSection>
  )
}

export default React.memo(MemberProfilePicture)
