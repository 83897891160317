// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { useEffect, useRef } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
const QueriesInfo = ({ modelData, handleChange }) => {
  console.log(modelData)
  const { StampEnd, StampStart, Executiontime, IsError, ErrorsId, Query } = modelData
  const editorRef = useRef<any>()
  useEffect(() => {
    editorRef.current.beautifyCode()
  }, [])
  return (
    <FieldsSection header='Querie Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='StampEnd'
            type='text'
            disabled
            label='StampEnd'
            value={StampEnd}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='StampStart'
            type='text'
            disabled
            label='StampStart'
            value={StampStart}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Executiontime'
            type='text'
            disabled
            label='Executiontime'
            value={Executiontime}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='IsError'
            type='fancyswitch'
            disabled
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              name='IsError'
              className='d-block'
              value={IsError}
              disabled={true}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='ErrorsId'
            type='text'
            disabled
            label='ErrorsId'
            value={ErrorsId}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          Query:
          <CustomCodeEditor
            value={Query}
            ref={editorRef}
            theme='dracula'
            mode='sqlserver'
            height='200px'
            readOnly={true}
          ></CustomCodeEditor>
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default QueriesInfo
