import CIcon from '@coreui/icons-react'
import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { usePlacesWidget } from 'react-google-autocomplete'
import { useGeolocation } from 'src/hooks/useGeolocation'
import InputField from './InputField'

interface AddressData {
  Address: string
  Address2: string
  City: string
  State: string
  ZipCode: string
}
interface PropsAddressFinderAux {
  address?: AddressData
  modelData?: any
  handleChange?: any
  handleAddressDataChange?: any
  name?: string
}

const AddressFinderAuxAux: React.FC<PropsAddressFinderAux> = ({
  address = { Address: '', Address2: '',City: '', State: '', ZipCode: '' },
  modelData,
  handleChange,
  handleAddressDataChange,
  name = '',
}) => {
  const btnRef = React.useRef<any>()
  const [locationData, getLocationData] = useGeolocation()

  // const [latitude, setLatitude] = React.useState(2)
  console.log("modelData",modelData);
  

  React.useEffect(() => {
    locationData && console.log(locationData)
  }, [locationData])

  const { ref } = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    onPlaceSelected: (place) => {
      handleContentChange(place)
    },
    options: { types: ['geocode'] },
  })

  const handleContentChange = (data: any) => {
    console.log({ Address })
    const { address_components = [] } = data
    const components: any = {}
    address_components.forEach(({ types, short_name, long_name }: any) => {
      types.forEach((type: any) => {
        components[type] = ['route', 'administrative_area_level_1'].includes(type)
          ? short_name
          : long_name
      })
    })
    const {
      premise = '',
      street_number = '',
      route = '',
      postal_code = '',
      neighborhood = '',
      locality = '',
      administrative_area_level_1 = '',
    } = components
    const streetAddress = street_number
      ? `${street_number} ${route}`
      : route
      ? route
      : premise
      ? premise
      : ''
    console.log({ streetAddress })
    console.log(streetAddress.length === 0 ? Address : streetAddress)
    handleAddressDataChange({
      name,
      value: {
        Address: streetAddress.length === 0 ? Address : streetAddress,
        City: neighborhood ? neighborhood : locality,
        State: administrative_area_level_1,
        Zip: postal_code,
      },
    })
  }

  const renderLocationButton = () => {
    return (
      <div>
        <small
          ref={btnRef}
          className={`input-field-button`}
          onClick={() => {
            getLocationData()
          }}
        >
          <CIcon name='cil-location-pin' />
        </small>
      </div>
    )
  }
  const { Address, City, State, ZipCode } = address
  console.log(address);
  
  return (
    <>
      <CFormGroup row>
        <CCol xs='12'>
          <InputField
            innerRef={ref}
            name={`Address`}
            label={name ? `${name} Address` : 'Address'}
            type='text'
            value={Address}
            onChange={handleChange}
            showButton
            ButtonComponent={renderLocationButton}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='6'>
          <InputField
            name={`City`}
            type='text'
            label={name ? `${name} City` : 'City'}
            value={City}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3' className='px-lg-0 my-3 my-lg-0'>
          <InputField
            name={`State`}
            type='text'
            label={name ? `${name} State` : 'State'}
            value={State}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3'>
          <InputField
            name={`Zip`}
            type='text'
            label={name ? `${name} Zipcode` : 'Zipcode'}
            value={ZipCode}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </>
  )
}

export default AddressFinderAuxAux