import axios from 'axios'
import React from 'react'
import { toast } from 'react-toastify'
import { addAuthAccessTokenToHeader } from 'src/services/authHelper'
import { constructSidebarItems } from 'src/services/menu/menuService'
import { MenusWidgetsList } from 'src/services/modelNew/List/List'
import { BASE_API, SERVICES } from 'src/services/routeConst'
import routes from '../routes'

const getSubRoutesFor = ({ name, Component, path, subRoute = 'Detail' }: any) => {
  require(`../pages${path?.replace('/Search', `/${subRoute}`)}`)
  const DetailComponent = React.lazy(() =>
    import(`../pages${path?.replace('/Search', `/${subRoute}`)}`).catch((err) =>
      console.error(err),
    ),
  )
  return [
    { name, Component, path },
    {
      name: name + ' Detail',
      Component: DetailComponent,
      path: path.replace('/Search', `/${subRoute}/:id`),
    },
    {
      name: 'New ' + name,
      Component: DetailComponent,
      path: path.replace('/Search', '/New'),
    },
  ]
}

const parseRoutes = (routes: any) => {
  return [].concat(
    ...routes
      .map((route: any) => {
        const { path, name, component } = route
        const Component =
          path &&
          React.lazy(() =>
            import(`../pages${component || path}`).catch((err) => console.error(err)),
          )

        if (path && (component || path)?.includes('/Search')) {
          try {
            return getSubRoutesFor({ name, Component, path: component || path, subRoute: 'Detail' })
          } catch (err: any) {
            try {
              return getSubRoutesFor({
                name,
                Component,
                path: component || path,
                subRoute: 'Edit',
              })
            } catch (error: any) { }
          }
        }
        try {
          require(`../pages${component || path}`)
          return { name, Component, path }
        } catch (err: any) {
          return null
        }
      })
      .filter(Boolean),
  )
}

export const useGetMenuRoute = (Id) => {
  // const [appId, setAppId] = useState(0);
  //setAppId(Id);
  const getSubMenus = async (menus) => {
    let subMenusList = []
    for (let i = 0; i < menus.length; i++) {
      const { data } = await MenusWidgetsList({
        body: {
          MenusId: menus[i].Id,
          MenusPath: 'ListSubMenus',
          MenusType: 'SubMenus'
        },
      },
      )
      for (let j = 0; j < data.length; j++) {
        data[j] = { ...data[j], MENUS_ID_Parent: menus[i].Id }
      }

      subMenusList = await subMenusList.concat(data).sort((a: any, b: any) => a.Id > b.Id ? 1 : -1)
    }
    return subMenusList
  }
  //console.log(Id);
  // let appId = Id;

  let location = window.location.href;
  // let location = 'https://jemadmin.aaadev.info/';
  // let location = 'https://jemadmin.ss'
  // let location = 'https://quantumadmin.aaadev.info'

  const [navData, setNavData] = React.useState<any>({ routes: [], nav: [] })
  const getMenuRoutes = React.useCallback(async (Id) => {
    try {
      if (Id == undefined) {
        const execData = await axios.post(
          `${BASE_API}${SERVICES}/data/execute`,
          {
            // [idName]: Id,
            DbName: 'DbAdmin',
            QueryOrProcedureName: "access.APPLICATIONS_Retrieve",
            // Id: id,
            // Extensions: body.Extension ?? null,
            Params: [
              {
                Name: "UrlRoot", Value: location
              }
            ],
          },
          { ...addAuthAccessTokenToHeader() },
        )

        Id = execData.data.data.Execution[0].Id
      }

      // console.log("in uge");
      // const applicationRetrieve: any = localStorage.getItem('VCA_ApplicationRetrieve');
      // let applicationRetrieveJson = JSON.parse(applicationRetrieve);
      // let appId = 
      //console.log('appIdsss'+Id);
      const { data } = await MenusWidgetsList({
        body: {
          ApplicationsId: Id,//(appId != undefined ? appId : 86 ),
          MenusPath: 'List',
          MenusType: 'Menus'
        },
      })
      for (let i = 0; i < data.length; i++) {
        data[i] = { ...data[i], IsMain: true }
      }

      let menusData = data.concat(await getSubMenus(data))
      const menuRoutes = await getRoutes(menusData)
      // console.log('GET ROUTES', menuRoutes)
      let navItems = await constructSidebarItems(menuRoutes)
      // console.log("navItems");
      // if (Id == 86 || Id == 131) {
      //   navItems = [...navItems, {
      //     _tag: 'CSidebarNavItem',
      //     name: 'Pharma',
      //     to: '/Admin/Pharma/Search',
      //     icon: '',
      //   }];
      // }
      await setNavData({
        routes: parseRoutes([...routes, ...menuRoutes]), nav: navItems
      })
    } catch (error: any) {
      toast.error((error as any)?.message || error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    //console.log("asdasda"+Id);

    getMenuRoutes(Id)
  }, [getMenuRoutes])

  const getRoutes = (data: any) =>
    data.map(({ Id, IsMain, MainMenuName, Name, Description, Icon, Color, PagePath = 'Leads/Details/1' }: any) => ({
      Description,
      Icon,
      Color,
      IsMain: IsMain,
      MainMenuName,
      sub_menus: IsMain ? data.filter(({ MENUS_ID_Parent }: any) => MENUS_ID_Parent === Id) : [],
      name: Name,
      path: PagePath,
    }))

  return [navData, getMenuRoutes]
}
