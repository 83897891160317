import { CCol, CFormGroup } from '@coreui/react'
import { ROLES_MODEL } from 'src/services/routeConst'
import { ComboBox, InputField } from 'src/shared'
import { ListModel } from 'src/services/modelNew/List/List'
import { useCallback, useEffect, useState } from 'react'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  appsData: any
  handleChange: any
  title: string
}

const InspectorInfo = ({
  modelData,
  appsData,
  handleChange,
}: PropsInfoForm) => {
  const [rolesData, setRolesData] = useState([]);
  const { FirstName, LastName, Phone, RolesId, Email, Password, ApplicationsId } = modelData
  const mapComboToData = () =>
    appsData.map((route) => {
      return {
        CodeId: route.Id,
        Description: route.Name,
      }
  })

  const mapRoleComboToData = () =>
    rolesData.map((route: any) => {
      return {
        CodeId: route.Id,
        Description: route.Name,
      }
  })

  const getData = useCallback<any>(async () => {
    const { data } = await ListModel({
      body: {
        Model: ROLES_MODEL,
        Extension: null,
        Params: {
          Page: 1,
          PageSize: 1000,
          SearchString: null,
          OrderBy: null,
          OrderByDir: null,
          ApplicationsId: ApplicationsId  
        },
      },
    })
    setRolesData(data);
    console.log(data);
  }, [ApplicationsId])

  useEffect(() => {
    console.log(ApplicationsId)
    getData()
  }, [ApplicationsId])

  return (
    <>
      <CFormGroup row>
        <CCol>
          <InputField
            name='FirstName'
            type='text'
            label='First Name'
            value={FirstName ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol>
          <InputField
            name='LastName'
            type='text'
            label='Last Name'
            value={LastName ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol>
          <InputField
            name='Phone'
            type='phone'
            label='Phone'
            value={Phone ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol>
          <InputField
            name='Email'
            type='email'
            label='Email'
            value={Email ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol>
          <InputField
            name='Password'
            type='password'
            label='Password'
            value={Password ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
      <CCol xs='6'>
          <ComboBox
            showButton
            name='ApplicationsId'
            label='Application'
            data={mapComboToData()}
            value={mapComboToData().find((menu: any) => menu.CodeId === ApplicationsId)}
            handleChange={(...e) => {
              const [, value] = e
              handleChange({
                name: 'ApplicationsId',
                value: value.CodeId,
              })
            }}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled' }}
            renderOption={(option: any, { selected }: any) => {
              return (
                <div className={selected ? 'combo-selected' : ''}>
                  <i className='cil-group' /> {option.Description}
                </div>
              )
            }}
          />
        </CCol>
        <CCol xs='6'>
          <ComboBox
            showButton
            name='RolesId'
            label='Roles'
            data={mapRoleComboToData()}
            value={mapRoleComboToData().find((menu: any) => menu.CodeId === RolesId)}
            handleChange={(...e) => {
              const [, value] = e
              handleChange({
                name: 'RolesId',
                value: value.CodeId,
              })
            }}
            classNames={{ input: 'input-field' }}
            customClassNames={{ button: 'comboBoxInput', root: 'input-field-root upscaled' }}
            renderOption={(option: any, { selected }: any) => {
              return (
                <div className={selected ? 'combo-selected' : ''}>
                  <i className='cil-group' /> {option.Description}
                </div>
              )
            }}
          />
        </CCol>
      </CFormGroup>
      {/*   <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </>
  )
}

export default InspectorInfo
