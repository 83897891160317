// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const CustomerInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const { 
    CustomerName,
    CustomerFirstName,
    CustomerLastName,
    CustomerMobile,
    CustomerOtherPhone,
    CustomerAddressCity,
    CustomerAddressState,
    CustomerAddressZip,
    CustomerAddressStreet,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            label={'Name'}
            value={CustomerName ?? '⠀'}
          />
          <InputLabel 
            label={'First Name'}
            value={CustomerFirstName ?? '⠀'}
          />
          <InputLabel 
            label={'Last Name'}
            value={CustomerLastName ?? '⠀'}
          />
          <InputLabel 
            label={'Mobile'}
            value={CustomerMobile ?? '⠀'}
          />
          <InputLabel 
            label={'Other Phone'}
            value={CustomerOtherPhone ?? '⠀'}
          />
          <InputLabel 
            label={'City'}
            value={CustomerAddressCity ?? '⠀'}
          />
          <InputLabel 
            label={'State'}
            value={CustomerAddressState ?? '⠀'}
          />
          <InputLabel 
            label={'Zip'}
            value={CustomerAddressZip ?? '⠀'}
          />
          <InputLabel 
            label={'Street'}
            value={CustomerAddressStreet ?? '⠀'}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default CustomerInfo
