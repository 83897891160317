import axios from 'axios';
import { useState } from 'react';
import { toast } from 'react-toastify'
import { Loader } from 'src/shared';

interface PropsInfoForm {
    modelData?: any;
    handleChange?: any;
    handleCheck?: any;
}

const JemcarePdfs = ({ modelData }: PropsInfoForm) => {
    const [isLoading1, setLoading1] = useState(false);
    const baseURL = "https://api.softsmartinc.com/Services/vOld/Pdf/";
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer 14FE34B2-9547-43F5-A57C-F0DC7DE81AA9'
    }

    const getExistingCCMPParticipation = () => {
        let array: any = modelData.ExistingParticipatPartners.map((row: any) => { return { value: row.CodeId, label: row.Description } });
        let result: any = [];
        array.push({ value: '63', label: 'Jemcare' },)
        array.sort((a: any, b: any) => a.value > b.value ? 1 : -1);
        array.map((res: any) => {
            result.push({ name: res.value });
        })
        console.log(result)
        return result;
    }

    const getRace = () => {
        let Races = modelData.Race;
        if (modelData.Race) {
            if (typeof modelData.Race === 'string') {
                Races = (modelData.Race) ? JSON.parse(modelData.Race) : []
            }
        }

        let result: any = Races.map((row: any) => { return row.CodeId });
        console.log(result)
        return result.toString();
    }

    const getManualCCMPParticipation = () => {
        let pData = JSON.parse(JSON.stringify(modelData.ManualParticipatPartners));
        let fIndex = pData.findIndex((e: any) => { return (e.name === 'Dr. Larry Neuman') });
        if (fIndex === -1) {
            pData.unshift({ name: 'Dr. Larry Neuman' });
        }
        return pData;
    }

    const downloadPdf = (type: number) => {
        let url = baseURL;
        let body = {}, filename: string = '';
        // let day = '00';
        // let month = '00';
        // let year = '0000';
        // if (modelData.DateOfBirth) {
        //     let array = modelData.DateOfBirth.split("-");
        //     if (array.length == 3) {
        //         month = array[0];
        //         day = array[1];
        //         // year = array[2];
        //     }
        // }
        let shortDay = '00';
        let shortMonth = '00';
        let shortYear = '0000';
        if (modelData.CreatedDate) {
            let array = modelData.CreatedDate.split("-");
            if (array.length == 3) {
                shortMonth = array[0];
                shortDay = array[1];
                shortYear = array[2];
            }
        }
        setLoading1(true);
        if (type === 1) {

            url = url + 'JemcarePrivacy';
            body = {
                "PrintName": modelData.FirstName + " " + modelData.LastName,
                "DateMonth": shortMonth,
                "DateDay": shortDay,
                "DateYear": shortYear,
                "Sign": modelData.Signature.replace("data:image/png;base64,", "")
            }
            filename = "Privacy";

        } else if (type === 2) {
            url = url + 'JemcareReferral';
            body = {
                FirstName: modelData.FirstName,
                LastName: modelData.LastName,
                dob: modelData.DateOfBirth,
                HARP: modelData.IsHarp ? "Yes" : "No",
                cin: modelData.MedicAidNumber,
                Gender: modelData.Gender,
                PrimaryPhone: modelData.HomePhone,
                chkHome: modelData.HomePhone ? "Yes" : "No",
                chkOther: modelData.CellPhone ? "Yes" : "No",
                chkCell: "No",
                PhoneOther: modelData.CellPhone,
                PrefferedLanguage: modelData.LanguagePreference,
                Race: getRace(),
                Religion: modelData.State ? modelData.State : "",
                Address: modelData.Address,
                chkPrivateResidence: modelData.IsPrivateResidence ? "Yes" : "No",
                chkHomeless1: modelData.IsHomeless ? "Yes" : "No",
                chkHomeless2: modelData.IsEmergencyHousing ? "Yes" : "No",
                chkHomeOther: modelData.IsOtherHousing ? "Yes" : "No",
                chkMH: modelData.IsMHSupportive ? "Yes" : "No",
                Other: modelData.OtherHousing,

                chkTwo: (modelData.IsMentalHealthCondition && modelData.IsAsthmaCondition && modelData.IsDiabetesCondition && modelData.IsOtherCondition && modelData.IsDiabetesCondition && modelData.IsBMICondition) ? "Yes" : "No",
                chkHyper: modelData.IsMentalHealthCondition ? 'Yes' : 'No',
                chkAsthma: modelData.IsAsthmaCondition ? 'Yes' : 'No',
                chkHeart: modelData.IsDiabetesCondition ? 'Yes' : 'No',
                chkOtherTwo: modelData.IsOtherCondition ? 'Yes' : 'No',
                chkDia: modelData.IsDiabetesCondition ? 'Yes' : 'No',
                chkBmi: modelData.IsBMICondition ? 'Yes' : 'No',

                chkOne: (modelData.IsSeriousEmotional && modelData.IsHivAids) ? "Yes" : "No",
                chkSerious: modelData.IsSeriousEmotional ? 'Yes' : 'No',
                chkHiv: modelData.IsHivAids ? 'Yes' : 'No',

                chk1: modelData.IsRecentRelease ? "Yes" : "No",
                chk2: modelData.IsLackofSocialSupports ? "Yes" : "No",
                chk3: modelData.IsNeedsAssistance ? "Yes" : "No",
                chk4: modelData.IsUnableToAccess ? "Yes" : "No",
                chk5: modelData.IsVictim ? "Yes" : "No",
                chk6: modelData.IsPCPOrSpecialist ? "Yes" : "No",
                chk7: modelData.IsDifficultyNavigating ? "Yes" : "No",
                chk8: modelData.IsTreatmentsOrMedications ? "Yes" : "No",
                chk9: modelData.IsDeficitsWithDailyLiving ? "Yes" : "No",
                chk10: modelData.IsDeficitsLifestyle ? "Yes" : "No",
                chk11: "No",
                Risk: modelData.riskFactorHistory,

                chkFamily: "No",
                chkMco: "No",
                chkSelf: "No",
                chkOtherSource: "Yes",
                ReffAgency: "Jemcare",
                ReffWorkerName: "N/A",
                ReffPhone: "201-839-7117 or 347-638-1665",
                chkMedi: "No",
                chkMediManage: "No",
                ReferralSource: "",
                chkHospital: "No",
                chkChronic2: "No",
                HospitalReferral: "",
                OtherReferral: "Michael and Betel Group"
            }
            filename = "Referral";

        } else if (type === 3) {

            url = url + 'JemcareBOR';
            body = {
                "PrintName": modelData.FirstName + " " + modelData.LastName,
                "DateMonth": shortMonth,
                "DateDay": shortDay,
                "DateYear": shortYear,
                "Sign": modelData.Signature.replace("data:image/png;base64,", "")
            }
            filename = "BOR";

        } else if (type === 4) {
            url = url + 'DOH5055';
            body = {
                "NameOfHealthHome": "Community Care Management Partners",
                "NameOfHealthHome2": "Community Care Management Partners",
                "BlankText3": "Healthix RHIO or Bronx RHIO",
                "BlankText4": "Community Care Management Partners",
                "BlankText5": "Healthix RHIO or Bronx RHIO",
                "PrintName": modelData.FirstName + " " + modelData.LastName,
                "PrintNameOfLegalRep": "",
                "PrintDob": modelData.DateOfBirth,
                "RelationShip": modelData.ConsentRelation,
                "Date": modelData.CreatedDate,
                "HealthHomeName": "CCMP Health Home",
                "HealthHomePhone": "(888) 682-1377",
                "HealthHomePhone2": "(888) 682-1377",
                "ShortFirstName": modelData.FirstName.charAt(0) + " " + modelData.LastName.charAt(0),
                "ShortDate": shortMonth + '/' + shortDay,
                "NameOfMemberOfLegalRep": "",
                "NameOfStaff": "",
                "DateLastPage": modelData.CreatedDate,
                "DateLastPage2": modelData.CreatedDate,
                "ManualParticipatPartners": getManualCCMPParticipation(),
                "ExistingParticipatPartners": getExistingCCMPParticipation(),
                "MemberName": modelData.FirstName + " " + modelData.LastName,
                "CIN": modelData.MedicAidNumber,
                "Sign": modelData.Signature.replace("data:image/png;base64,", ""),
                "healthHomeNamePage6": "Community Care Management Partners",
                "MemberInitials": (modelData.MemberInitials) ? modelData.MemberInitials.replace("data:image/png;base64,", "") : "",
                "StaffSignature": (modelData.StaffSignature) ? modelData.StaffSignature.replace("data:image/png;base64,", "") : "",
                "StaffInitials": (modelData.StaffInitials) ? modelData.StaffInitials.replace("data:image/png;base64,", "") : "",
                "eppddt": shortMonth + '/' + shortDay,
                "mppddt": shortMonth + '/' + shortDay

            }
            filename = "DOH5055";
        }
        axios.post(`${url}`, body, {
            responseType: 'blob',
            headers: headers
        }).then((response) => {

            generatePDF(response.data, filename)
        }).catch((error) => {
            console.log(error)
            toast.error("something went wrong please try again.")
        });
    };

    const generatePDF = (res: any, filename: string) => {
        // let blob = new Blob([res], { type: 'application/pdf' });
        var downloadURL = window.URL.createObjectURL(res);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = filename + ".pdf";
        link.click();
        setLoading1(false);
    }

    return (
        <div className='' style={{ minHeight: '200px' }}>
            <i style={{ display: 'none' }}>{modelData.MetasId}</i>
            <div className="d-inline-block mr-3" style={{ cursor: 'pointer' }} onClick={() => downloadPdf(1)}>
                <i className="fa fa-download"></i> Privacy
            </div>
            <div className="d-inline-block mr-3" style={{ cursor: 'pointer' }} onClick={() => downloadPdf(2)}>
                <i className="fa fa-download"></i> Referral
            </div>
            <div className="d-inline-block mr-3" style={{ cursor: 'pointer' }} onClick={() => downloadPdf(4)}>
                <i className="fa fa-download"></i> DOH5055
            </div>
            <div className="d-inline-block mr-3" style={{ cursor: 'pointer' }} onClick={() => downloadPdf(3)}>
                <i className="fa fa-download"></i> BOR
            </div>
            <div className='d-block mt-3'>
                {(isLoading1) ? <Loader size='sm' color='black' style={{ padding: '0.5rem' }} /> : null}
            </div>
        </div>
    )
}

export default JemcarePdfs;