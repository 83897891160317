// Generated with util/create-search-page.js
import { CCard, CCardBody, CCol, CRow } from '@coreui/react'
import { Loader } from 'src/shared'
import { HeaderComponent, Info } from './components'
import { REGIONS_MODELS } from 'src/services/routeConst'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'

const ModelsDetails = ({ history, match, modelName = REGIONS_MODELS }) => {
  const { id = 0 } = match.params
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id,
    modelName,
    history,
  })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData } = apiData

  return (
    <CCard>
      <HeaderComponent
        modelName={'Files'}
        onCancelHandle={history.goBack}
        status={status}
        saveData={saveData}
      />
      <CCardBody>
        <CRow>
          <CCol xs='12' md='6'>
            <Info title={'Files'} modelData={modelData} handleChange={handleChange} />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  )
}

export default ModelsDetails
