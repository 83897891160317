import React from 'react'

export const DashBoard = React.lazy(() => import('./containers/DashBoard'))
export const Login = React.lazy(() => import('./pages/login/Login'))
export const Logout = React.lazy(() => import('./pages/Logout/Logout'))
export const Register = React.lazy(() => import('./pages/register/Register'))
export const Page404 = React.lazy(() => import('./pages/page404/Page404'))
export const Page500 = React.lazy(() => import('./pages/page500/Page500'))
export const PostLogin = React.lazy(() => import('./pages/PostLogin/PostLogin'))
export const External = React.lazy(() => import('./pages/External/Search'))
