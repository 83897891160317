import { useEffect, useState } from 'react'
import { DownloadFile } from 'src/services/modelNew/File/Download'
import { getInitialsFromName, validate } from 'src/services/util'
interface PropsAvatar {
  FilesId_Avatar: string | number
  name: string
  className?: string
}

const DEFAULT_AVATAR = '/avatars/0.png'

const Avatar = ({ FilesId_Avatar, name, className = 'chip-avatar' }: PropsAvatar) => {
  const [avatarData, setAvatarData] = useState<any>({ src: '', status: 'loading' })
  const exists = (condition) => {
    return !!condition
  }
  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await DownloadFile({ FilesId: FilesId_Avatar })
        validate((data as any)?.split(';')?.[0].includes('/pdf'), 'Invalid avatar file type')
        setAvatarData({ src: data, status: 'idle' })
      } catch (error: any) {
        setAvatarData({ src: !name ? DEFAULT_AVATAR : null, status: 'idle' })
      }
    }
    if (exists(FilesId_Avatar)) {
      getData()
    } else {
      setAvatarData({ src: !name ? DEFAULT_AVATAR : null, status: 'idle' })
    }
  }, [FilesId_Avatar, name])

  const Initials = (
    <span
      style={{
        display: 'inline',
        // textShadow: '#000 1px 0 18px',
        backgroundImage: 'linear-gradient(#1080BF 0%, #33D1F9 100%)',
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
      }}
    >
      {getInitialsFromName(name)}
    </span>
  )

  return (
    <div
      className={`avatar-root avatar-circular ${className} avatar-colorDefault`}
      style={{ backgroundColor: 'white' }}
    >
      {exists(avatarData?.src) || avatarData?.status === 'loading' ? (
        avatarData?.status === 'loading' ? (
          Initials
        ) : (
          <img
            alt='Avatar'
            src={avatarData?.status === 'loading' ? DEFAULT_AVATAR : avatarData?.src}
            className='avatar-img'
            loading='lazy'
          />
        )
      ) : (
        Initials
      )}
    </div>
  )
}

export default Avatar
