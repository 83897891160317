// import { CRow } from '@coreui/react'
// import React from 'react'

export default function DayTimeslot() {
  // const day = [];
  return (
    <div>
      ... in progress
      {/* <CRow>
            { day ? getHour(nine["Timeslot"]) : ''}
            { day["Sunday"] !== 0 ? forDelete(idDate[0][0]) : forSave("Sunday", nine["Timeslot"])}
            { day["Monday"] !== 0 ? forDelete(idDate[0][1]) : forSave("Monday", nine["Timeslot"])}
            { day["Tuesday"] !== 0 ? forDelete(idDate[0][2]) : forSave("Tuesday", nine["Timeslot"])}
            { day["Wednesday"] !== 0 ? forDelete(idDate[0][3]) : forSave("Wednesday", nine["Timeslot"])}
            { day["Thursday"] !== 0 ? forDelete(idDate[0][4]) : forSave("Thursday", nine["Timeslot"])}
            { day["Friday"] !== 0 ? forDelete(idDate[0][5]) : forSave("Friday", nine["Timeslot"])}
            { day["Saturday"] !== 0 ? forDelete(idDate[0][6]) : forSave("Saturday", nine["Timeslot"])}
          </CRow> */}

    </div>
  )
}
