import React from 'react'
import { InputField as CustomInputField } from 'smart-inputs'
import { baseInputProps } from '../../services/routeConst'

const CustomDateField: React.FC<any> = ({ name, ...rest }: any) => {
  const props = { ...baseInputProps, id: name, name, ...rest }
  const finalProps = {
    ...props,
    rootClassName: `${props.rootClassName}}${
      props.type === 'textarea' ? ' textarea' : ''
    }`,
  }
  return <CustomInputField {...finalProps} />
}

export default CustomDateField
