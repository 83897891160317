export const toTitleCase = (str: any) => {
  return str.length
    ? str
        .split(/\s+|_+/g)
        .map((word: any) =>
          word
            .split('')
            .reduce(
              (final: any, next: any, idx: any) => (idx === 1 ? final.toUpperCase() : final) + next,
            ),
        )
        .join(' ')
    : str
}

export const parseBooleans = (arr: any) => {
  let finalArr = arr
  arr.map((data: any, idx: any) => {
    Object.keys(data).map((d) => {
      if (data[d] === 'True') {
        finalArr[idx][d] = true
      } else if (data[d] === 'False') {
        finalArr[idx][d] = false
      }
      return d
    })
    return data
  })
  return finalArr
}

export const parseNulls = (arr: any) => {
  let finalArr = arr
  arr.map((data: any, idx: any) => {
    Object.keys(data).map((d) => {
      if (data[d] === null) {
        finalArr[idx][d] = undefined
      }
      return d
    })
    return data
  })
  return finalArr
}

export const findDuplicates = (arr: any) => {
  const distinct = new Set(arr) // to improve performance
  // eslint-disable-next-line array-callback-return
  const filtered = arr.filter((item: any) => {
    // remove the element from the set on very first encounter
    if (distinct.has(item)) {
      distinct.delete(item)
    }
    // return the element on subsequent encounters
    else {
      return item
    }
  })
  const value: any = new Set(filtered)
  return [...value]
}

export const getDayOfWeek = (date: any) => {
  const dayOfWeek = new Date(date).getDay()
  return isNaN(dayOfWeek)
    ? null
    : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][dayOfWeek]
}
export const validate = (condition, messageError) => {
  if (condition) {
    throw new Error(messageError)
  }
}
export const getInitialsFromName = (string: string) => {
  if (string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase()

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  }
  return ''
}

export const stringToColor = (string: string) => {
  let hash = 0

  for (let i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.substr(-2)
  }

  return color
}

export const stringToHslColor = (str, s, l) => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  const h = hash % 360
  // return `hsl(${h},${s}%,${l}%)`
  return HSLToHex(Math.abs(h), s, l)
}

const HSLToHex = (h, s, l) => {
  s /= 100
  l /= 100

  let c = (1 - Math.abs(2 * l - 1)) * s,
    x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
    m = l - c / 2,
    r: any = 0,
    g: any = 0,
    b: any = 0

  if (0 <= h && h < 60) {
    r = c
    g = x
    b = 0
  } else if (60 <= h && h < 120) {
    r = x
    g = c
    b = 0
  } else if (120 <= h && h < 180) {
    r = 0
    g = c
    b = x
  } else if (180 <= h && h < 240) {
    r = 0
    g = x
    b = c
  } else if (240 <= h && h < 300) {
    r = x
    g = 0
    b = c
  } else if (300 <= h && h < 360) {
    r = c
    g = 0
    b = x
  }
  // Having obtained RGB, convert channels to hex
  r = Math.round((r + m) * 255).toString(16)
  g = Math.round((g + m) * 255).toString(16)
  b = Math.round((b + m) * 255).toString(16)

  // Prepend 0s, if necessary
  if (r.length === 1) r = '0' + r
  if (g.length === 1) g = '0' + g
  if (b.length === 1) b = '0' + b

  return '#' + r + g + b
}

export const textColorForBackground = (hexcolor: string) => {
  hexcolor = hexcolor.replace('#', '')
  var r = parseInt(hexcolor.substr(0, 2), 16)
  var g = parseInt(hexcolor.substr(2, 2), 16)
  var b = parseInt(hexcolor.substr(4, 2), 16)
  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'black' : 'white'
}

export const formatFullName = (firstName: string, lastName: string) => {
  if (!!firstName && !!lastName) return firstName + ' ' + lastName
  else if (!firstName && !lastName) return null
  else if (!firstName) return lastName
  else if (!lastName) return firstName
  else return null
}
