const routes = [
  { path: '/', exact: true, name: 'Home', component: '/Dashboard/Dashboard' },
  { path: '/dashboard', name: 'Dashboard', component: '/Dashboard/Dashboard' },
  {
    path: '/campaigns-dashboard',
    name: 'Dashboard',
    component: '/Dashboard/CampaignMainDashboard',
  },
  { path: '/Profile', name: 'Your Profile', component: '/Profile/Profile' },
  { path: '/Admin/Messages/Search', name: 'Messages', component: '/Admin/Messages/Search' },
  { path: '/Admin/Sms/Search', name: 'Sms', component: '/Admin/Sms/Search' },
  { path: '/Admin/Campaign/Search', name: 'Sms', component: '/Admin/Campaign/Search' },
  { path: '/Admin/Facilities/Search', name: 'Facilities', component: '/Admin/Facilities/Search' },
  { path: '/Admin/Pharma/Search', name: 'Register', component: '/Admin/Pharma/Search' },
  { path: '/Jemcare/Search', name: 'Jemcare', component: '/Jemcare/Search' },
]
// routes
export default routes
