import { toast } from 'react-toastify'
import { adjustTableData } from 'src/services/dataTable/dataTableService'
import { ListModel } from './List'
import { RetrieveModel } from './Retrieve'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { DataExec } from 'src/services/modelNew/Data/Exec'
import { ProcGenerate } from 'src/services/modelNew/ProcGen/ProcGen'
import { DataSQL } from 'src/services/modelNew/Data/SQL'
import { logoutVerifyErrors500 } from 'src/services/authHelper'
interface PropsSave {
  modelName?: any
  idName?: any
  dataToSave?: any
  getData?: any
  goBack?: any
  noToaster?: any
}
interface PropsGet {
  modelName?: any
  id?: any
  subModelName?: any
  subModelIdName?: any
  defaultMainData?: any
  defaultSubData?: any
  forceSubData?: any
}
export class RootRepository {
  async getData({
    modelName,
    id,
    subModelName = '',
    subModelIdName = '',
    defaultMainData = {},
    defaultSubData = [],
    forceSubData = false,
  }: PropsGet): Promise<any> {
    if (!id && !forceSubData) {
      return { mainData: defaultMainData, subData: defaultSubData }
    }
    try {
      let error,
        data = defaultMainData
      if (id) {
        const { error: apiError, data: apiData }: any = await RetrieveModel({
          data: {
            Id: id,
            Model: modelName,
          },
        })
        error = apiError
        data = apiData
      }
      if (!subModelName) {
        return { mainData: data, subData: defaultSubData }
      }
      const subData: any = await ListModel({
        body: {
          Model: subModelName,
        },
      })
      if (!error && data && !subData.error && subData.data) {
        const adjustedTableData = adjustTableData(subData.data, subModelIdName)
        return {
          mainData: data,
          subData: adjustedTableData,
        }
      }
    } catch (error: any) {
      console.log(error);
      
      // toast.error(error.message)
      logoutVerifyErrors500(error)
      // throw new Error(error.message)
    }
  }

  async saveData({ modelName, idName, dataToSave, getData, goBack, noToaster = false }: PropsSave) {
    try {
      const { data: saveData, message: saveMessage } = await SaveModel({
        body: {
          Model: modelName,
          Params: dataToSave,
        },
        idName,
      })
      if (!noToaster) {
        toast.success(saveMessage)
        saveData && getData && goBack && (Number(dataToSave[idName]) ? getData() : goBack(1000))
      }
      return { data: saveData, message: saveMessage }
    } catch (err: any) {
      !noToaster && toast.error(err.message)
      throw new Error(err.message)
    }
  }

  async exec({ procName, params }: any) {
    try {
      return await DataExec({ procName, params })
    } catch (error: any) {
      toast.error(error.message)
      throw new Error(error.message)
    }
  }

  async sql({ query }: any) {
    try {
      return await DataSQL({ query })
    } catch (error: any) {
      toast.error(error.message)
      throw new Error(error.message)
    }
  }

  async procGen({ procType, params }: { procType: any; params: any }) {
    try {
      const { data, message } = await ProcGenerate({ procType, params })
      toast.success(message)
      return data
    } catch (error: any) {
      toast.error(error.message)
      throw new Error(error.message)
    }
  }
}
