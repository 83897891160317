// Generated with util/create-search-page.js
import { CButton, CCardHeader } from '@coreui/react'
import axios from 'axios'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { addAuthAccessTokenToHeader } from 'src/services/authHelper'
import { BASE_API, MODELS, SAVE, SERVICES } from 'src/services/routeConst'

import LoadingButton from 'src/shared/components/LoadingButton'
import CustomDateField from 'src/shared/inputFields/CustomDateField'
interface PropsHeaderComponent {
  onCancelHandle: any
  status: any
  saveData: any
  modelName: any
  modelData: any
  handleChange: any
}
const HeaderComponent = ({
  onCancelHandle,
  status,
  saveData,
  modelData,
  handleChange,
  modelName = 'Model Name',
}: PropsHeaderComponent) => {
  const isLoading = status === 'saving'
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g).join(' ')
  const [isCustomer, setIsCustomer] = useState(modelData.CustomersId)

  const [isFollow, setIsFollow] = useState(modelData.IsNeedFollowUp)
  let {FollowUpDate} = modelData
  
  const formatDate = (date) => {
    if(date) {
      date = date.split('-')
      const day = String(date[0])
      const month = String(date[1])
      const year = String(date[2])
      if(day.length===2){
        return `${year}-${day}-${month}`
      }
      return `${day}-${month}-${year}`
    }
    
    return ''
  }

  const today = () => {
    const date = new window.Date()
    let year = date.getFullYear()
    let month = date.getMonth()+1
    let day = date.getDate()

    console.log(`${year}-${month<10?`0${month}`:`${month}`}-${day<10?`0${day}`:`${day}`}`);
    return `${year}-${month<10?`0${month}`:`${month}`}-${day<10?`0${day}`:`${day}`}`
  }
  FollowUpDate = formatDate(FollowUpDate)

  const changeFollow = () => {
    if(modelData.IsNeedFollowUp){
      modelData.IsNeedFollowUp = false
      setIsFollow(false)
      console.log(FollowUpDate);
      FollowUpDate = formatDate(modelData.FollowUpDate)
    }else {
      modelData.IsNeedFollowUp = true
      FollowUpDate = today()
      modelData.FollowUpDate = today()
      setIsFollow(true)
    }
  }

  const sendDispatch = async () => {
    try{
      const { data } = await axios.patch(
        `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
        {
          Model: 'Customers',
          Id: 0,
          Params: [
            { name: 'FirstName', value: modelData.CustFirstName },
            { name: 'LastName', value: modelData.CustLastName },
            { name: 'PhoneMobile', value: modelData.CustPhoneMobile },
            { name: 'PhoneOther', value: modelData.CustPhoneOther },
            { name: 'Address', value: modelData.CustAddressStreet },
            { name: 'City', value: modelData.CustAddressCity },
            { name: 'State', value: modelData.CustAddressState },
            { name: 'Zip', value: modelData.CustAddressZip },

            { name: 'Vehicle', value: `${modelData.VehicleYear || ''} ${modelData.VehicleMake || ''} ${modelData.VehicleModel || ''} ${modelData.VehicleTrim || ''}`},
            { name: 'VehicleVin', value: modelData.VehicleVin },
            { name: 'VehicleMileage', value: modelData.VehicleMileage },
            { name: 'VehicleColor', value: modelData.VehicleColor },

            { name: 'FollowUpDate', value: today() },
            { name: 'IsNeedFollowUp', value: true },
          ],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      console.log('cust',data.data.SaveInfo.Id);
      
      await axios.patch(
        `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
        {
          Model: 'Leads',
          Id: modelData.Id,
          Params: [
            // { name: 'IsCustomer', value: 1 },
            { name: 'CustomersId', value: data.data.SaveInfo.Id },
          ],
        },
        { ...addAuthAccessTokenToHeader() },
      )
      await setIsCustomer(data.data.SaveInfo.Id)
      modelData.CustomersId = data.data.SaveInfo.Id
      toast.success('Sent to Dispacth')

    }catch(error:any) {
      console.log(error);
      
      // const status = error.response.status 
      // logoutVerifyErrors(status)
    }
      
      // data.data && setIsCustomer(1)
      // console.log(data,dataLead.data);
      
      // data.data && {modelData.IsCustomer=1}

     
  }
  

  const history = useHistory()

  const viewCustomer = () => {
    let path = `/Operations/Customers/Detail/${modelData.CustomersId}`; 
    history.push(path);
  }

  if(!FollowUpDate){
    FollowUpDate = today()
    modelData.FollowUpDate = today()
  }
  const checkIsFollow = <div style={{display: 'flex', alignItems:'center'}}>
    <div style={{color: '#fff', fontSize:'12px', marginRight:'10px'}}>Follow Up</div>
    <div onClick={()=>changeFollow()}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent:'center'}} className='header-input-check header-input-check-isFollow'>
        <img style={{width: '15px'}} src="./check-mark.png" alt="" />
      </div>
    </div>
      <CustomDateField
        className='header-input-date'
        name='FollowUpDate'
        type='date'
        value={FollowUpDate ?? ''}
        onChange={handleChange}
      />
    </div>
  const checkIsNotFollow = <div style={{display: 'flex', alignItems:'center'}}>
    <div style={{color: '#fff', fontSize:'12px', marginRight:'10px'}}>Follow Up</div>
    <div onClick={()=>changeFollow()}>
      <div style={{display: 'flex', alignItems: 'center', justifyContent:'center'}} className='header-input-check header-input-check-noFollow'>
      </div>
    </div>
  </div>


  return (
    <CCardHeader
      className='d-flex align-items-center'
      style={{
        backgroundColor: '#2A3446',
        marginBottom: '0',
        fontSize: 16,
        fontWeight: 600,
        borderRadius: 0,
      }}
    >
      <span style={{ color: 'white' }}>{title} </span>
      
      <div
        className='mr-1 ml-auto'
      >
        {isFollow
        ? checkIsFollow
        : checkIsNotFollow
        }
      </div>

      {
        isCustomer === 0 
        ? 
          <LoadingButton
            className='mr-1'
            style={{ float: 'right' }}
            loading={isLoading}
            onClick={sendDispatch}
          >
            Send to Dispatch
          </LoadingButton>
        : 
          <LoadingButton
            className='mr-1'
            style={{ float: 'right' }}
            loading={isLoading}
            onClick={viewCustomer}
          >
            View Customer
          </LoadingButton>
      }
      <LoadingButton
        className='mr-1'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton>
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancelHandle}
      >
        Close
      </CButton>
    </CCardHeader>
  )
}

export default HeaderComponent
