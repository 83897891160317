// Generated with util/create-search-page.js
import { CButton } from '@coreui/react'
import { useContext } from 'react'
import { FieldsSection } from 'src/shared'
import AddressComponent from '../AddressComponent'
import { WizardContext } from './ModalNewCustomer'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsDropOffInfoForm {
  modelData: any
  handleChange: any
  handleAddressChange: any
  title: string
}

const DropOffInfo = ({
  modelData,
  handleChange,
  title = 'Model Name',
  handleAddressChange,
}: PropsDropOffInfoForm) => {
  const { handleNext, activeStep, steps } = useContext(WizardContext)
  return (
    <FieldsSection
      header={
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {title}
        </div>
      }
    >
      <AddressComponent
        modelName='Dropoff'
        modelData={modelData ?? {}}
        handleChange={handleChange}
        handleAddressChange={handleAddressChange}
      />
      <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '2rem' }}>
        <div style={{ flex: '1 1 auto' }} />
        <CButton color='primary' onClick={handleNext}>
          {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
        </CButton>
      </div>
      {/*   <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </FieldsSection>
  )
}

export default DropOffInfo
