import React from 'react'
import { CTabPane } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, MODELS, ENDPOINTS_MODEL } from 'src/services/routeConst'
// import { FieldsSection } from 'src/shared'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import { endpointsTableColumns } from '../servicesTableColumns'
import HeaderButton from 'src/shared/components/HeaderButton'
interface PropsServiceDetailTabSection {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  viewDetails?: any
  Guid?: any
}
const ServicesDetailTabSection: React.FC<PropsServiceDetailTabSection> = ({
  id,
  modelName,
  actionFunctions,
  tableRef,
  changesRef,
  viewDetails,
  Guid,
}) => {
  return (
    <DetailTabSection
      id={id}
      Guid={Guid}
      modelName={modelName}
      tabs={[{ name: 'DataTable', label: 'Endpoints' }]}
      changesRef={changesRef}
      headers={{
        DataTable: (
          <HeaderButton id={id} onClick={() => viewDetails({ id: 0 })} label='Add Endpoint' />
        ),
      }}
    >
      <CTabPane data-tab='DataTable' className='detail-tab-section-pane'>
        {/* <FieldsSection
          header={
            <>
              <p className='mb-0'>Endpoints</p>
              {id ? (
                <CButton
                  className='mr-1 ml-auto'
                  type='button'
                  color='primary'
                  size='sm'
                  style={{ float: 'right', padding: '0 0.5rem' }}
                  onClick={() => viewDetails({ id: 0 })}
                >
                  Add Endpoint
                </CButton>
              ) : null}
            </>
          }
        > */}
        <DataTable
          columnData={endpointsTableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={ENDPOINTS_MODEL}
          idName='EndpointsId'
          actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          rootStyles={{ fontSize: '0.75rem' }}
          apiKey={getAuthToken('ADMIN')}
        />
        {/* </FieldsSection> */}
      </CTabPane>
    </DetailTabSection>
  )
}

export default ServicesDetailTabSection
