import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, LIST, LOOKUP_MODEL, MODELS, SERVICES } from 'src/services/routeConst'
import { DeleteModal } from 'src/shared'
import { lookupValueTableColumns } from '../../lookupTableColumns'
import { LookupInfoModal } from './components'
import { Header } from './components'

const LookupInfo = ({ /* history */ id }) => {
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modelId, setModelId] = useState(0)
  const tableRef = useRef<any>()
  const viewDetails = ({ id }: any) => {
    setShowModal((value) => !value)
    setModelId(id)
  }
  const newData = () => {
    setShowModal((value) => !value)
    setModelId(0)
  }
  const deleteDetails = ({ id }: any) => {
    setShowDeleteModal((value) => !value)
    setModelId(id)
  }

  const actionFunctions: any = {
    edit: viewDetails,
    delete: deleteDetails,
  }

  return (
    <div>
      <Header
        modelName={'Lookups'}
        saveData={() => {
          newData()
        }}
      />
      <DataTable
        columnData={lookupValueTableColumns}
        theme='white'
        noHeader
        endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
        modelName={LOOKUP_MODEL}
        scopedSlots={{
          Name: ({ value }) => {
            return <div>{value}</div>
          },
        }}
        queryParams={[
          {
            Name: 'TypesId',
            Value: id,
          },
        ]}
        actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
        ref={tableRef}
        apiKey={getAuthToken('ADMIN')}
      />
      <LookupInfoModal
        show={showModal}
        onClose={() => {
          setShowModal(false)
          tableRef.current.fetchNewData()
        }}
        id={modelId}
        typesId={id}
      />
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName: LOOKUP_MODEL, label: 'Lookup', id: modelId }}
        callBack={() => {
          tableRef.current.fetchNewData()
        }}
      />
    </div>
  )
}
export default LookupInfo
