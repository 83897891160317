// Generated with util/create-search-page.js
export const SigninsTableColumns = [
  {
    label: 'TokensId',
    name: 'TokensId',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'UserName',
    name: 'UserName',
    dbType: 'varchar',
  },
  {
    label: 'Password',
    name: 'Password',
    dbType: 'varchar',
  },
  {
    label: 'AccessToken',
    name: 'AccessToken',
    dbType: 'varchar',
  },
  {
    label: 'SourceIp',
    name: 'SourceIp',
    dbType: 'varchar',
  },
  {
    label: 'SourceBrowser',
    name: 'SourceBrowser',
    dbType: 'varchar',
  },
  /* {
    label: 'TokenType',
    name: 'TokenType',
    dbType: 'varchar',
  },
  {
    label: 'SourceIp',
    name: 'SourceIp',
    dbType: 'varchar',
  },
  {
    label: 'SourceBrowser',
    name: 'SourceBrowser',
    dbType: 'varchar',
  }, */
  /* {
    label: 'Validity',
    name: 'Validity',
    dbType: 'varchar',
  },
  {
    label: 'StampStart',
    name: 'StampStart',
    dbType: 'datetime',
  },
  {
    label: 'StampEnd',
    name: 'StampEnd',
    dbType: 'datetime',
  },
  {
    label: 'Executiontime',
    name: 'Executiontime',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'IsError',
    name: 'IsError',
    dbType: 'bit',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'ErrorsId',
    name: 'ErrorsId',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  }, */
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]
