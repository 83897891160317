// Generated with util/create-search-page.js
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { DeleteModal } from './DeleteModal'
import { routeToPage } from 'src/services/routingHelper'
import { ApplicantsTableColumns } from './ApplicantsTableColumns'
import { BASE_API, APPLICANTS_MODEL, MODELS, APPLICANTS_ID } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'

const ApplicantsSearch: React.FC<any> = ({ history, modelName = APPLICANTS_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)

  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }: any) => {
    routeToPage(history, '/Dashboard/Applicants/Detail/' + id)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        {modelName} Search
        {/* <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, '/Dashboard/Applicants/New')}
        >
          New Applicant
        </CButton> */}
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={ApplicantsTableColumns}
          theme='dark'
          noHeader
          endpoint={BASE_API + ':' + MODELS}
          modelName={modelName}
          idName={APPLICANTS_ID}
          actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          paginationPosition='top-right'
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Applicant', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CCard>
  )
}

export default ApplicantsSearch
