import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { CCol, CFormGroup } from '@coreui/react'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface Props {
  memberData: any
  handleChange: any
}

const MemberLoginAccess: React.FC<Props> = ({ memberData, handleChange }: Props) => {
  return (
    /* Login Access - Application (On), Website (Off))  */
    <FieldsSection
      header={`Login Access  ${memberData.IsActive ? 'Application (On)' : ' Website (Off)'}`}
    >
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='IsActive'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label={`Access Setting`}
          >
            <CustomSwitch
              name='IsActive'
              className='d-block'
              value={memberData.IsActive}
              updateData={(name: any) => {
                handleChange({ name, value: !memberData.IsActive })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}
export default MemberLoginAccess
