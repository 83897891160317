import React from 'react'
import { CCard, CCardBody, CCardHeader, CRow, CCol, CButton, CFormGroup } from '@coreui/react'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { ALERTS_FIELDS_MODEL, SMS_MODEL } from 'src/services/routeConst'
import { Loader, InputField } from 'src/shared'
import LoadingButton from 'src/shared/components/LoadingButton'
import CustomTextEditor from 'src/shared/inputFields/CustomTextEditor'
import parse from 'html-react-parser'
import ConfirmRedirectModal from 'src/shared/modals/ConfirmRedirectModal'

const fields = ['SmsId', 'Name', 'Body', 'Fields']

const parseField = ({ field }: any) => field.replaceAll('{{', '').replaceAll('}}', '')

const SMSEditor: React.FC<any> = ({ history, match, modelName = SMS_MODEL }) => {
  const [status, setStatus] = React.useState('loading')
  /*  const [placeholders, setPlaceholders] = React.useState([
    {
      id: 1,
      name: 'item.Name',
      description: 'item.Description',
      value: 'item.Name',
    },
  ]) */
  const [apiData, setApiData] = React.useState<any>({
    SMSData: null,
    fieldsData: [],
  })
  const { id = 0 } = match.params

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = React.useCallback(async () => {
    try {
      const { mainData, subData }: any = await rootRepository.getData({
        id,
        modelName,
        subModelName: ALERTS_FIELDS_MODEL,
        subModelIdName: 'FieldsId',
        defaultMainData: { SmsId: 0 },
        defaultSubData: { FieldsId: 0 },
        forceSubData: true, //used when creating a new SMS and id = 0 but we still need to fetch the subData (fields)
      })
      setApiData({ SMSData: mainData, fieldsData: subData })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }, [id, modelName, rootRepository])

  React.useEffect(() => {
    getData()
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({
        SMSData: null,
        fieldsData: [],
      })
      history.goBack()
    }, delay)
  }

  const saveSMS = async () => {
    setStatus('saving')
    // console.log(apiData)
    try {
      await rootRepository.saveData({
        modelName,
        idName: 'SmsId',
        dataToSave: {
          ...Object.assign({}, ...fields.map((field) => ({ [field]: apiData.SMSData[field] }))),
          Fields: JSON.stringify(
            apiData.SMSData.Body.match(/{{(\w+?)}}/g)?.map((field: any) => parseField({ field })),
          ),
        },
        getData,
        goBack,
      })
      setStatus('idle')
    } catch (error: any) {
      setStatus('idle')
      console.error(error)
    }
  }

  const handleChange = ({ name, value }: any) => {
    setApiData((st: any) => ({ ...st, SMSData: { ...st.SMSData, [name]: value } }))
  }

  const autoCompleteMapFromAPi = (data: any) =>
    data.map((item: any) => ({
      id: item.FieldsId,
      name: item.Name,
      description: item.Description,
      value: item.Name,
    }))

  const parseSMS = () => {
    let parsedSMS = apiData.SMSData.Body
    const fieldsFromAPI = apiData.fieldsData.map((field: any) => field.Name)
    apiData.SMSData.Body?.match(/{{(\w+?)}}/g)?.forEach((field: any) => {
      parsedSMS = parsedSMS.replaceAll(
        field,
        `<strong style="color: ${
          fieldsFromAPI.includes(parseField({ field }))
            ? '#e1a82d'
            : addFieldToSuggestions({ field })
        };">${field}</strong>`,
      )
    })

    return parse(parsedSMS ?? '')
  }

  const addFieldToSuggestions = ({ field }: any) => {
    // console.log('Adding new field to list of Fields: ', field)
    setApiData((st: any) => ({
      ...st,
      fieldsData: [
        ...st.fieldsData,
        {
          FieldsId: st.fieldsData.length + 1,
          Name: parseField({ field }),
          Description: 'Custom Field',
        },
      ],
    }))
    return '#4799eb'
  }

  if (!apiData.SMSData || status === 'loading') {
    return <Loader fullScreen height='75vh' />
  }

  // console.log(apiData)
  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        SMS Editor
        <LoadingButton
          className='mr-1 ml-auto'
          style={{ float: 'right' }}
          loading={status === 'saving'}
          onClick={saveSMS}
        >
          Save
        </LoadingButton>
        <CButton
          className='mr-2'
          type='button'
          color='secondary'
          style={{ float: 'right' }}
          onClick={history.goBack}
        >
          Cancel
        </CButton>
      </CCardHeader>
      <CCardBody>
        <CFormGroup row>
          <CCol xs='12' sm='6'>
            <InputField
              name='Name'
              type='text'
              label='SMS Name'
              value={apiData.SMSData.Name}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
        {/* <button
          onClick={() => {
            setPlaceholders((placeholder) => {
              return [
                ...placeholder,
                {
                  id: placeholder[placeholders.length - 1].id + 1,
                  name: 'item.Name' + (Number(placeholder[placeholders.length - 1].id) + 1),
                  description: 'item.Description',
                  value: 'item.Name' + (Number(placeholder[placeholders.length - 1].id) + 1),
                },
              ]
            })
          }}
        >
          ++++
        </button>
        <code>{JSON.stringify(placeholders, false, 2)}</code> */}
        <CustomTextEditor
          placeholders={autoCompleteMapFromAPi(apiData.fieldsData)}
          /* placeholders={placeholders} */
          value={apiData.SMSData.Body}
          onChange={({ data }: any) => handleChange({ name: 'Body', value: data })}
        />
        <CRow className='justify-content-center mt-3'>
          <CCol xs='12' sm='6'>
            {parseSMS()}
          </CCol>
        </CRow>
      </CCardBody>
      <ConfirmRedirectModal data={apiData?.SMSData?.Body ?? ''} />
    </CCard>
  )
}

export default SMSEditor

// SELECT * FROM ${tableName}
// ${joinType} ${joinTable} ${joinCondition}
