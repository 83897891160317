import React, {useState} from 'react'
import { connect } from 'react-redux'
//import { useHistory } from 'react-router-dom'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import * as faIcons from '@fortawesome/free-solid-svg-icons'
import { setIcon } from '../../redux/actions/IconActions'
import { ChromePicker, CirclePicker} from 'react-color';

// interface PropsSearchIcon { 
//   closeModal?: any,
//   setIcon?: any
// }
const ColorPicker: React.FC<any> = (props: any) => {
  const [sketchPickerColor, setSketchPickerColor] = useState("#37d67a");
  const [blockPickerColor, setBlockPickerColor] = useState("#37d67a");

  const selectColor = () => {
    props.selectColor(sketchPickerColor);
  }
  return (
    <>
    <div className='colorPickerDiv'>
      <CirclePicker color={blockPickerColor}
          onChange={(color) => {
            setBlockPickerColor(color.hex);
            setSketchPickerColor(color.hex);
          }} 
          
          colors={['#4D4D4D', '#999999', '#FFFFFF', '#F44E3B', '#FE9200', '#FCDC00', '#DBDF00', '#A4DD00', '#68CCCA', '#73D8FF', '#AEA1FF', '#FDA1FF', '#333333', '#808080', '#cccccc', '#D33115', '#E27300', '#FCC400', '#B0BC00', '#68BC00', '#16A5A5', '#009CE0', '#7B64FF', '#FA28FF', '#000000', '#666666', '#B3B3B3', '#9F0500', '#C45100', '#FB9E00', '#808900', '#194D33', '#0C797D', '#0062B1', '#653294', '#AB149E']}/>
      <ChromePicker onChange={(color) => {
            setSketchPickerColor(color.hex);
          }}
          color={sketchPickerColor} />
          
    </div>
    <div className='colorPickerSelect'>
    <button onClick={selectColor}>Select</button>
    </div>
    </>
  )
}

const mapStateToProps = (state: any) => {
  const { auth } = state
  return {
    loggedInUser: auth.loggedInUser,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setIcon: (data: any) => dispatch(setIcon(data)),
  }
}

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(ColorPicker))
