import React from 'react'
import { CTabPane } from '@coreui/react'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import MenuSubMenus from './MenuSubMenus'
import { MenuInfo } from '../components';
import HeaderButton from 'src/shared/components/HeaderButton'
interface PropsMenusDetailTabSection {
  id?: any
  modelName?: any
  menuData?: any
  actionFunctions?: any
  tableRef?: any
  viewDetails?: any
  changesRef?: any
  Guid?: any
}
const MenusDetailTabSection: React.FC<PropsMenusDetailTabSection> = ({
  id,
  modelName,
  menuData,
  actionFunctions,
  tableRef,
  viewDetails,
  changesRef,
  Guid,
}) => {
  const [apiData, setApiData] = React.useState<any>({ menuData: null, allMenusData: [] })
  const handleChange = React.useCallback(({ name = '', value = '' }) => {
    // console.log({ name, value })
    name &&
      setApiData((st: any) => ({
        ...st,
        menuData: {
          ...st.menuData,
          [name]: value,
        },
      }))
  }, [])

  const handleComboChange = (e: any, newValue: any = {}) => {
    if (!e.target) return
    const CodeId = newValue ? newValue.CodeId : null
    setApiData((st: any) => ({ ...st, menuData: { ...st.menuData, MenusId_Parent: CodeId } }))
  }
  const { allMenusData = [] } = apiData
  return (
    <DetailTabSection
      id={id}
      Guid={Guid}
      modelName={modelName}
      tabs={[
        { name: 'MenuInfo', label: 'Menu Info' },
        { name: 'DataTable', label: 'Sub Menus' }
      ]}
      changesRef={changesRef}
      headers={{
        DataTable: menuData.IsMain ? (
          <HeaderButton id={id} onClick={() => viewDetails({ id: 0 })} label='Add Sub Menu' />
        ) : null,
      }}
    >
    <CTabPane data-tab='MenuInfo' className='detail-tab-section-pane'>
        <MenuInfo
          menuData={menuData}
          handleChange={handleChange}
          handleComboChange={handleComboChange}
          allMenusData={allMenusData}
        ></MenuInfo>
    </CTabPane>
    <CTabPane data-tab='DataTable' className='detail-tab-section-pane'>
      <MenuSubMenus
        id={id}
        menuData={menuData}
        onClick={() => viewDetails({ id: 0 })}
        actionFunctions={actionFunctions}
        ref={tableRef}
      />
    </CTabPane>
    </DetailTabSection>
  )
}

export default MenusDetailTabSection
