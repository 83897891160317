// Generated with util/create-search-page.js
import { CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { DeleteModal } from 'src/shared'
import { routeToPage } from 'src/services/routingHelper'
import { QueriesTableColumns } from './QueriesTableColumns'
import { BASE_API, QUERIES_MODEL, MODELS, QUERIES_ID } from 'src/services/routeConst'
import { getAuthToken } from 'src/services/authHelper'

const QueriesSearch = ({ history, modelName = QUERIES_MODEL }) => {
  const [showDeleteModal, setShowDeleteModal] = useState<any>(false)

  const clickedIDRef = useRef<any>()
  const tableRef = useRef<any>()

  const viewDetails = ({ id }) => {
    routeToPage(history, '/Logs/Queries/Detail/' + id)
  }

  const showDeleteModalFunc = ({ id }) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions = {
    view: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        {modelName} Search
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={QueriesTableColumns}
          theme='dark'
          noHeader
          endpoint={BASE_API + ':' + MODELS}
          modelName={modelName}
          idName={QUERIES_ID}
          actionClick={(type, data) => actionFunctions[type]({ id: data })}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          scopedSlots={{
            Query: ({ value }) => {
              return (
                <code style={{ color: '#F2AB26', fontSize: '0.75rem', fontFamily: 'Consolas' }}>
                  {value}
                </code>
              )
            },
            StampStart: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
            StampEnd: ({ value }) => {
              return <div style={{ fontFamily: 'Consolas' }}>{value}</div>
            },
          }}
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'Querie', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CCard>
  )
}

export default QueriesSearch
