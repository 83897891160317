// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { InputField, ComboBox } from 'src/shared'
import { useState, useEffect, useCallback } from 'react'
import { ListModel } from 'src/services/modelNew/List/List'
import { FACILITIES_MODEL } from 'src/services/routeConst'
// @ts-ignore
const PharmaInfo = ({ modelData, handleChange, usersData = [] }) => {
  const { City, State, ZipCode, FirstName, LastName, Address, FacilitiesId } = modelData
  const [selectedUsers, setSelectedUsers] = useState<any>("")
  const [theUsersData, setTheUsersData] = useState<any>({ CodeId: 0, Description: '' })
  const [selectedFacility, setSelectedFacility] = useState<any>([])

  const fetchUsersList = useCallback<any>(async () => {
    const { data } = await ListModel({
      body: {
        Model: FACILITIES_MODEL,
        Extension: null,
        Params: {},
      },
    })
    let add = data.map((route) => {
      if (route.Id == FacilitiesId) {
        setSelectedFacility({
          FacilitiesId: route.Id,
          Description: route.Name,
        })
      }
      return {
        FacilitiesId: route.Id,
        Description: route.Name,
      }
    })
    setTheUsersData(add)
  }, [])

  // const mapComboCheckData = () =>
  // usersData.map((route) => {
  //     return {
  //       CodeId: route.Id,
  //       Description: route.FirstName,
  //     }
  // })

  // const handleComboChangeCheck = (e: any, newValue: any = {}) => {
  //   if (!e.target) return
  //   setSelectedUsers(newValue)
  //   // var commaSeparated = newValue
  //   //   .map((item) => {
  //   //     return item.CodeId
  //   //   })
  //   //   .join(',')
  //   console.log(e.target.value);

  //   handleChange({ name: 'FacilitiesId', value: e })
  // }


  const handleComboChangeCheck = (e: any, newValue: any = {}) => {
    console.log(newValue)

    if (!e.target) return
    setSelectedFacility(newValue)
    // var commaSeparated = newValue
    //   .map((item) => {
    //     return item.CodeId
    //   })
    //   .join(',')
    handleChange({ name: 'FacilitiesId', value: newValue.FacilitiesId })
  }

  useEffect(() => {
    fetchUsersList()
    if (modelData.Id != 0) {
      console.log(usersData);

      // const splitUsers = UsersList.split(',')
      const filterUsersArray = async () => {
        // var t = theUsersData.find((menu: any) => menu.CodeId === FacilitiesId);
        setSelectedUsers("1");
        console.log(selectedUsers);
        console.log(FacilitiesId);

        // const myArrayFiltered = await usersData.filter((el) => {
        //   // return splitUsers.some((f) => {
        //   //   return el.Id == f
        //   // })
        //   return el.Id == modelData.FacilitiesId
        // })

        // const myF = myArrayFiltered.map((route) => {
        //   return {
        //     CodeId: route.Id,
        //     Description: route.FirstName,
        //   }
        // })
        // setSelectedUsers(myF)
      }
      filterUsersArray()
    }
  }, [])

  return (
    <>
      <CFormGroup row>
        <CCol xs='6' sm='6'>
          <InputField
            name='FirstName'
            type='text'
            label='First Name'
            value={FirstName ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='6' sm='6'>
          <InputField
            name='LastName'
            type='text'
            label='Last Name'
            value={LastName ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='6' sm='6'>
          <InputField
            name='ZipCode'
            type='text'
            label='Zip Code'
            value={ZipCode ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='6' sm='6'>
          <InputField
            name='Address'
            type='text'
            label='Address'
            value={Address ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='6' sm='6'>
          <InputField
            name='City'
            type='text'
            label='City'
            value={City ?? ''}
            onChange={handleChange}
          />
        </CCol>

        <CCol xs='6' sm='6'>
          <InputField
            name='State'
            type='text'
            label='State'
            value={State ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>



      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <ComboBox
            showButton
            multiple={false}
            name='UsersList'
            label='Facilities'
            data={theUsersData}
            value={selectedFacility ?? []}
            //value=""
            handleChange={handleComboChangeCheck}
            classNames={{ input: 'input-field' }}
            customClassNames={{
              button: 'comboBoxInput',
              root: 'input-field-root upscaled custom-multi',
            }}
            renderOption={(option: any, { selected }: any) => (
              <div className={selected ? 'combo-selected' : ''}>{` ${option.Description}`}</div>
            )}
          />
        </CCol>
      </CFormGroup>
    </>
  )
}

export default PharmaInfo
