import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { MembersRepository } from 'src/repository/admin/member/MembersRepository'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'

interface PropsEditMemberStatsModal {
  showModal?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}

/* const fields = [
  'StatsId',
  'UsersId',
  'AsOfDate',
  'VitalScore',
  'CreditRewardsBalance',
  'ReferralRewardsBalance',
  'CashbackBalance',
  'VitalCashReceived',
  'VitalCashSent',
  'VitalCashSpent',
  'VitalCashTotal',
  'NumShares',
  'NumSignups',
  'YourRank',
  'IsCashback2Pct',
  'IsCashback3Pct',
  'RewardsTier1',
  'RewardsTier2',
  'RewardsTier3',
] */

const StatsModal: React.FC<PropsEditMemberStatsModal> = ({
  showModal = false,
  setShowModal,
  modalData,
  callBack = () => {},
}: PropsEditMemberStatsModal) => {
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<any>()
  const { id, UsersId, modelName } = modalData

  const membersRepository = React.useMemo(() => new MembersRepository(), [])

  useEffect(() => {
    const getDataInEffect = async () => {
      try {
        const { mainData } = await membersRepository.getData({
          id,
          modelName: modelName,
          defaultMainData: { UsersId, StatsId: 0 },
        })
        setData(mainData)
      } catch (error: any) {
        console.error(error)
      }
    }
    loadingData && getDataInEffect()
  }, [UsersId, id, loadingData, membersRepository, modelName])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    data && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    // const dataToSave: any = data
    try {
      /*  await membersRepository.saveData({
        modelName: modelName,
        idName: STATS_ID,
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
      }) */
    } catch (error: any) {
      console.error(error)
    }
    // setData({})
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setData((st: any) => ({ ...st, [name]: value }))
  }

  if (loadingData) {
    return null
  }
  // console.log(data)
  return (
    <CModal
      size={'xl'}
      show={showModal}
      onClose={() => {
        // setData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} Stats</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='Stats Info'>
              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='AsOfDate'
                    disabled={true}
                    type='text'
                    label='As Of Date'
                    value={data.AsOfDate}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='VitalScore'
                    disabled={true}
                    label='Vital Score'
                    inputStyles={{ textAlign: 'right' }}
                    value={data.VitalScore}
                  />
                </CCol>

                <CCol xs='3' sm='3'>
                  <InputField
                    name='CreditRewardsBalance'
                    disabled={true}
                    label='Credit Rewards Balance'
                    type='currency'
                    alignment='right'
                    value={data.CreditRewardsBalance}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='ReferralRewardsBalance'
                    disabled={true}
                    label='Referral Rewards Balance'
                    type='currency'
                    alignment='right'
                    value={data.ReferralRewardsBalance}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
              </CFormGroup>

              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='CashbackBalance'
                    label='Cashback Balance'
                    disabled={true}
                    type='currency'
                    alignment='right'
                    value={data.CashbackBalance}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='VitalScore'
                    label='Vital Score'
                    inputStyles={{ textAlign: 'right' }}
                    value={data.VitalScore}
                    disabled={true}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='CreditRewardsBalance'
                    label='Credit Rewards Balance'
                    type='currency'
                    alignment='right'
                    value={data.CreditRewardsBalance}
                    decimals={2}
                    disabled={true}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='ReferralRewardsBalance'
                    label='Referral Rewards Balance'
                    type='currency'
                    alignment='right'
                    value={data.ReferralRewardsBalance}
                    decimals={2}
                    disabled={true}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
              </CFormGroup>

              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='NumShares'
                    disabled={true}
                    label='Number of Shares'
                    inputStyles={{ textAlign: 'right' }}
                    value={data.NumShares}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='NumSignups'
                    label='Number of Signups'
                    disabled={true}
                    inputStyles={{ textAlign: 'right' }}
                    value={data.NumSignups}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='YourRank'
                    label='Rank'
                    disabled={true}
                    inputStyles={{ textAlign: 'right' }}
                    value={data.YourRank}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='RewardsTier1'
                    label='Rewards Tier 1'
                    inputStyles={{ textAlign: 'right' }}
                    value={data.RewardsTier1}
                    disabled={true}
                  />
                </CCol>
              </CFormGroup>

              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='RewardsTier2'
                    label='Rewards Tier 2'
                    inputStyles={{ textAlign: 'right' }}
                    value={data.RewardsTier2}
                    disabled={true}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='RewardsTier3'
                    label='Rewards Tier 3'
                    inputStyles={{ textAlign: 'right' }}
                    value={data.RewardsTier3}
                    disabled={true}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsCashback2Pct'
                    type='fancyswitch'
                    disabled={true}
                    labelStyles={{ marginBottom: 0 }}
                    label='Cashback 2%'
                  >
                    <CustomSwitch
                      name='IsCashback2Pct'
                      className='d-block'
                      value={data.IsCashback2Pct}
                      disabled={true}
                      updateData={() => {}}
                    />
                  </InputField>
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsCashback3Pct'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Cashback 3%'
                    disabled={true}
                  >
                    <CustomSwitch
                      name='IsCashback3Pct'
                      className='d-block'
                      value={data.IsCashback3Pct}
                      disabled={true}
                      updateData={() => {}}
                    />
                  </InputField>
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='VitalCashSent'
                    disabled={true}
                    label='Vital Cash Send'
                    inputStyles={{ textAlign: 'right' }}
                    value={data.VitalCashSent}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='VitalCashReceived'
                    disabled={true}
                    label='Vital Cash Received'
                    inputStyles={{ textAlign: 'right' }}
                    value={data.VitalCashReceived}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='VitalCashSpent'
                    disabled={true}
                    label='Vital Cash Spent'
                    inputStyles={{ textAlign: 'right' }}
                    value={data.VitalCashSpent}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='VitalCashTotal'
                    disabled={true}
                    label='Vital Cash Total'
                    inputStyles={{ textAlign: 'right' }}
                    value={data.VitalCashTotal}
                  />
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Done
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            // setData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default StatsModal
