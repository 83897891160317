import { CFormGroup } from '@coreui/react'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import { DashboardContext } from 'src/containers/DashBoard'
import { FileUpload } from 'src/services/modelNew/Widget/File/UploadBuyersContacts'
import {  Cropper } from 'src/shared'
interface PropsInspectorProfilePicture {
  modelData?: any
  avatarData?: any
}
const ContactsProfilePicture: React.FC<PropsInspectorProfilePicture> = ({
  modelData,
  avatarData
}) => {
  console.log(modelData);
  console.log('avatarData', avatarData);
  
  const { setUpdateAvatarProfile } = useContext(DashboardContext)
  const uploadFile = async (file: any) => {
    const { data, error } = await FileUpload({
      Id: avatarData.FilesId_Avatar,
      MetasId: modelData.MetasId,
      fileToUpload: file,
      Description: 'File',
      TokensId: avatarData.Id
    })
    console.log(data, error);
    
    // !error && data && data.FilesId && updateProfilePictureID(data.FilesId)
    toast.success('Profile Picture Updated!')
  }

  return (
    <>
     {/* <FieldsSection header='Profile Picture'> */}
      <CFormGroup row className='justify-content-center'>
        <Cropper
          cropShape='round'
          maxSizeMib={3}
          guid={avatarData?.FileGuid ?? ''}
          onChangeCropper={async ({ fileCropped }: any) => {
            setUpdateAvatarProfile(true)
            await uploadFile(fileCropped)
            setUpdateAvatarProfile(false)
          }}
        />
      </CFormGroup>
     {/* </FieldsSection> */}
    </>
  )
}

export default React.memo(ContactsProfilePicture)
