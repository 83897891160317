import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API } from 'src/services/routeConst'
interface PropsSentOTP {
  type?: string
  sendTo?: string
  MemberId: any
}
export const sendOTP = async ({ type = 'Email', sendTo = '', MemberId }: PropsSentOTP) => {
  const request = type === 'Email' ? { EmailTo: sendTo } : { Phone: `+1${sendTo}` }
  try {
    await axios.post(
      `${BASE_API}:8116/Otp/Send${type === 'Email' ? 'Email' : 'Sms'}`,
      { ...request, MemberId },
      {
        ...addAuthToHeader('APPLICATION'),
      },
    )
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error sending OTP!`,
      error: error.response,
    })
  }
}
