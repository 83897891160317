// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { addAuthAccessTokenToHeader, logoutVerifyErrors } from 'src/services/authHelper'
import { BASE_API, DELETE, LIST, MODELS, SERVICES } from 'src/services/routeConst'
import { InvoiceHeader } from '.'
// import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const InvoiceServicesInfo = ({
  modelData,
  title = 'Model Name',
}: PropsInfoForm) => {
  const [customerInvoices, setCustomerInvoices] = useState([])
  
  const [stateAdd, setStateAdd] = useState(false)

  const fetchCustomerInvoices = async () => {
    try{
      const { data } = await axios.post(
        `${BASE_API}${SERVICES}${MODELS}${LIST}`,
        {
          // DbName: "DbOltp",
          // QueryOrProcedureName: "deal.CUSTOMERS_TRANS_List",
          Model: "CustomersTrans",
          Extension: null,
          Params: [
              {
                  "Name": "CustomersId",
                  "Value": modelData['Id']
              }
          ]
        },
        { ...addAuthAccessTokenToHeader() }
      );
      data.data && setCustomerInvoices(data.data)
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }
  const deleteInvoice = async (id) => {
    try{
      await axios.delete(
        `${BASE_API}${SERVICES}${MODELS}/${DELETE}`,
        {
          data:{
            Id: id,
            Model: 'CustomersTrans',
            // Extensions: body.Extension ?? null,
            Params: [],
          },
          headers: {
            Authorization: addAuthAccessTokenToHeader().headers.Authorization
          }
        },
      )
      fetchCustomerInvoices()
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
    }
  }
  useEffect(() => {
    // fetchServices()
    fetchCustomerInvoices()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateAdd, setStateAdd])

  const InvoiceInput = (Invoice) => {
    // const found = customerServices.find(element => element['ServicesId'] === Service['Id'] ) || {id:0};

    return (
        <div className='customer-invoices-card'>
          <div className='item-name'>
            {Invoice['TransactionDate']}
          </div>
          <div className='item-price'>
            <i>
              ${ Invoice['Amount'] ? Invoice['Amount'].toFixed(2) : `0.00`}
            </i>
          </div>
          <div className='item-description'>
            {Invoice['Description']}
          </div>
          <div className='c-sidebar-brand-full item-button'>
            <img
              src='/admin/delete-mark.png'
              alt=''
              width={10}
              height={10}
              onClick={()=>deleteInvoice(Invoice['Id'])}
            />
          </div>
        </div>
      )
    }
  const renderAllInvoices = customerInvoices.map(( Invoice ) => {
    return (
      <div key={Invoice['Id']}>
        {InvoiceInput(Invoice)}
      </div>
    )
  })

  const AmountTotal = () => {
    if (customerInvoices[0]) return (customerInvoices[0]['TotalAmount'])
    return 0;    
  }
  // const amountTotal = AmountTotal()
  
  // console.log(AmountTotal());
  

  return (
    <InvoiceHeader header={title} customersId={modelData['Id']} setStateAdd={setStateAdd} stateAdd={stateAdd} totalAmount={AmountTotal()} >
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
         {renderAllInvoices}
        </CCol>
      </CFormGroup>
    </InvoiceHeader>
    )
  }
  
export default InvoiceServicesInfo
