import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'
import { ListModel } from 'src/services/modelNew/List/List'
import { SERVICES_MODEL } from 'src/services/routeConst'
import { CustomerContext } from '../../../Detail'
export enum StatusInfo {
  loading = 'loading',
  idle = 'idle',
  saving = 'saving',
}

interface PropsActionsModels {
  id: any
  modelName: any
  modelsId?: string
}
type ApiDataState = { modelData: any; allServicesData: any }
const fields: string[] = ['Id', 'Name', 'CustomersId', 'Description', 'Price', 'ServicesId', 'Cost']
export const useActionsModels = ({
  id,
  modelName,
  modelsId = 'Id',
}: PropsActionsModels): [ApiDataState, StatusInfo, () => Promise<void>, Function] => {
  const { CustomersId } = useContext(CustomerContext)

  const [apiData, setApiData] = useState<ApiDataState>({ modelData: null, allServicesData: null })
  const [status, setStatus] = useState<StatusInfo>(StatusInfo.loading)
  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback<any>(async () => {
    try {
      const { data: allServicesData } = await ListModel({
        body: {
          Model: SERVICES_MODEL,
          Extension: null,
          Params: {
            Page: 1,
            PageSize: 1000,
            SearchString: null,
            OrderBy: null,
            OrderByDir: null,
          },
        },
      })
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [modelsId]: 0 },
      })
      const dataResponse = mainData?.data ? mainData.data[0] : mainData
      setApiData({ modelData: dataResponse, allServicesData })
      setStatus(StatusInfo.idle)
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }, [rootRepository, id, modelName, modelsId])

  useEffect(() => {
    getData()
  }, [getData])

  const mappingFields = (apiData) => {
    const dataToSave: any = apiData.modelData
    return {
      ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
      CustomersId,
    }
  }
  const saveData = async () => {
    setStatus(StatusInfo.saving)
    try {
      const { message } = await SaveModel({
        body: {
          Model: modelName,
          Params: mappingFields(apiData),
        },
      })
      toast.success(message)
      getData()
      setStatus(StatusInfo.idle)
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }

  return [apiData, status, saveData, handleChange]
}
