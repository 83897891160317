import React from 'react'
import Avatar from './Avatar/Avatar'
import Chip from './Chip/Chip'
interface PropsMemberAvatar {
  name: any
  avatar: any
  onClick?: any
  noChip?: boolean
}
const MemberAvatar: React.FC<PropsMemberAvatar> = ({
  name,
  avatar,
  onClick = () => {},
  noChip = false,
}) => {
  return noChip ? (
    <Avatar FilesId_Avatar={avatar} name={name} />
  ) : (
    <Chip avatar={<Avatar FilesId_Avatar={avatar} name={name} />} onClick={onClick} label={name} />
  )
}

export default MemberAvatar
