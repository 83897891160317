import { ColumnData } from 'library-soft-datatable'

export const lookupTableColumns: ColumnData[] = [
  // {
  //   label: 'ID',
  //   name: 'LookupsId',
  //   editable: false,
  //   customWidth: 10,
  // },
  {
    label: 'Name',
    name: 'Name',
    customWidth: 10,
  },
  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: 'DatabaseName',
    name: 'DatabaseName',
  },

  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    customWidth: 10,
    sortable: false,
  },
]

export const lookupValueTableColumns: ColumnData[] = [
  {
    label: 'Code',
    name: 'Code',
    customWidth: 14,
  },
  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    customWidth: 10,
    sortable: false,
  },
]
