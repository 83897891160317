import React from 'react'
import { CTabPane } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { getAuthToken } from 'src/services/authHelper'
import { BASE_API, USERS_MODEL, SERVICES, MODELS, LIST } from 'src/services/routeConst'
// import { FieldsSection } from 'src/shared'
// import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'
import { usersWithRoleTableColumns } from '../roleTableColumns'
interface PropsRolesDetailTabSection {
  id?: any
  modelName?: any
  actionFunctions?: any
  tableRef?: any
  changesRef?: any
  Guid?: any
}
const RolesDetailTabSection: React.FC<PropsRolesDetailTabSection> = ({
  // id,
  actionFunctions,
  tableRef,
}) => {
  return (
    <CTabPane data-tab='DataTable' className='detail-tab-section-pane'>
      {/* <FieldsSection header='Role Users'> */}
      <DataTable
        columnData={usersWithRoleTableColumns}
        theme='dark'
        noHeader
        endpoint={`${BASE_API}${SERVICES}${MODELS}${LIST}`}
        modelName={USERS_MODEL}
        idName='UsersId'
        // queryParams={{ RolesId: id, Extention: 'ByRolesId' }}
        actionClick={(type: any, data: any) => actionFunctions[type]({ id: data })}
        ref={tableRef}
        rootStyles={{ fontSize: '0.75rem' }}
        apiKey={getAuthToken('ADMIN')}
      />
      {/* </FieldsSection> */}
    </CTabPane>
  )
}

export default RolesDetailTabSection
