// Generated with util/create-search-page.js
import { CCardBody, CCol, CRow, CTabPane } from '@coreui/react'
import { Loader } from 'src/shared'
import { HeaderComponent, Info, NotesInfo } from './components'
import { SERVICES_MODEL } from 'src/services/routeConst'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'
import DetailTabSection from 'src/shared/components/DetailTabSection/DetailTabSection'

const ModelsDetails = ({ history, match, modelName = SERVICES_MODEL }) => {
  const { id = 0 } = match.params
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id,
    modelName,
    history,
  })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData } = apiData
  console.log(modelData)

  return (
    <>
      <HeaderComponent
        modelName={modelName}
        onCancelHandle={history.goBack}
        status={status}
        saveData={saveData}
      />
      <CCardBody>
        <CRow>
          <CCol>
            <DetailTabSection
              id={0}
              MetasId={modelData.MetasId}
              Guio={0}
              modelName={''}
              hideNotes
              tabs={[{ name: 'Details', label: 'Details' }]}
            >
              <CTabPane data-tab='Details' className='detail-tab-section-pane'>
                <Info title={'Details'} modelData={modelData} handleChange={handleChange} />
              </CTabPane>
            </DetailTabSection>
          </CCol>
          <CCol xs={5} style={{ marginTop: '0' }}>
            {modelData.Id !== 0 && modelData.MetasId !== null ? (
              <DetailTabSection
                id={0}
                metasId={modelData.MetasId}
                hideNotes
                tabs={[{ name: 'Notes', label: 'Notes' }]}
              >
                <CTabPane data-tab='Notes' className='detail-tab-section-pane'>
                  <NotesInfo MetasId={modelData.MetasId}></NotesInfo>
                </CTabPane>
              </DetailTabSection>
            ) : (
              <></>
            )}
          </CCol>
        </CRow>
      </CCardBody>
    </>
  )
}

export default ModelsDetails
