import React, { useEffect, useState } from 'react'
import { CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react'
import ImageGallery from 'react-image-gallery'
import CIcon from '@coreui/icons-react'
import { toast } from 'react-toastify'
import { LinearProgressIndicator, UploadButton } from '../../index'
import { useGetImagesFiles } from './hook/useGetImagesFiles'
import { Image, AnyFile, UploadFileServer } from './helpers/uploadFile'

interface PropsModalSelectImage {
  show: boolean
  onClose: Function
  onSelectImage: Function
  metasId: any
  typeFile?: 'image' | 'any'
}
export interface WidgetFile {
  FilesId?: number
  MetasId?: number
  Repository?: null
  Folder?: string
  FileNameOriginal?: string
  FileNameGuid?: null
  FileUrl?: string
  Description?: string
  RecordCount?: number
  url?: string
}
const TypeOfFile = {
  image: Image,
  any: AnyFile,
}
const ModalSelectImage: React.FC<PropsModalSelectImage> = ({
  show = false,
  onClose,
  metasId,
  onSelectImage,
  typeFile = 'image',
}: PropsModalSelectImage) => {
  const [files, isLoading, downloadFiles] = useGetImagesFiles(metasId)
  const [index, setIndex] = useState(0)
  const [showModal, setShowModal] = useState(show)
  const mapFilesToImagesGallery = (files: WidgetFile[]): any => {
    return files.map((params) => {
      return {
        original: params.url,
        thumbnail: params.url,
        originalHeight: '250px',
      }
    })
  }
  const currentPositionImages = (currentIndex) => {
    setIndex(currentIndex)
  }
  const uploadFileImage = async (e) => {
    try {
      const [image] = e.target.files
      const TypeFile = image.type.includes('image') ? TypeOfFile['image'] : TypeOfFile[typeFile]
      await UploadFileServer.uploadServer(new TypeFile(image, metasId))
      toast.success('Success upload image')
      await downloadFiles()
    } catch (error: any) {
      toast.error(error.message)
    }
  }
  useEffect(() => {
    setShowModal(show)
  }, [show])
  return (
    <CModal size='lg' show={showModal} onClose={onClose} color={'info'}>
      <CModalHeader>
        <CModalTitle>Modal title</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {isLoading && <LinearProgressIndicator />}
        <div style={{ width: '100%' }}>
          <UploadButton
            disabled={isLoading}
            accept='*'
            style={{ float: 'right' }}
            title='Upload Image'
            onChange={uploadFileImage}
          />
        </div>
        {isLoading ? (
          'Loading...'
        ) : files.length ? (
          <>
            <div>
              <ul style={{ listStyle: 'none', fontFamily: 'Consolas' }}>
                <li>FieldsId: {files[index].FilesId}</li>
                <li>Repository: {files[index].Repository}</li>
                <li>FileNameOriginal: {files[index].FileNameOriginal}</li>
                <li>FileNameGuid: {files[index].FileNameGuid}</li>
                <li>FileUrl: {files[index].FileUrl}</li>
                <li>Description: {files[index].Description}</li>
              </ul>
            </div>
            <ImageGallery
              lazyLoad={true}
              onSlide={currentPositionImages}
              items={mapFilesToImagesGallery(files)}
              showFullscreenButton={false}
              renderPlayPauseButton={() => {
                return (
                  <div className='image-gallery-icon image-gallery-play-button'>
                    <CButton
                      color='primary'
                      onClick={() => {
                        onSelectImage(files[index])
                        setShowModal(false)
                        onClose()
                      }}
                    >
                      <CIcon name='cil-user-follow' className='mfe-2' />
                      Select
                    </CButton>
                    <CButton
                      color='primary'
                      style={{ marginLeft: '0.5rem' }}
                      onClick={() => {
                        console.log(files[index], 'DELETE image')
                      }}
                    >
                      <CIcon name='cil-trash' className='mfe-2' />
                      Delete
                    </CButton>
                  </div>
                )
              }}
            />
          </>
        ) : (
          <>Upload Image please</>
        )}
      </CModalBody>
      <CModalFooter>
        <CButton color='secondary' onClick={onClose}>
          Close
        </CButton>
      </CModalFooter>
    </CModal>
  )
}
export default ModalSelectImage
