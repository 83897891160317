import React from 'react'
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
interface PropsUserChangePassword {
  userData?: any
  handleChange?: any
}
const UserChangePassword: React.FC<PropsUserChangePassword> = ({ userData, handleChange }) => {
  return (
    <FieldsSection header='Change Password'>
      <CFormGroup row>
        <CCol xs='12' className='mb-2'>
          <InputField
            name='Password'
            label='Password'
            type='password'
            value={userData.Password}
            onChange={handleChange}
            showButton
          />
        </CCol>
        <CCol xs='12'>
          <InputField
            name='confirmPassword'
            label='Confirm Password'
            type='password'
            value={userData.confirmPassword}
            onChange={handleChange}
            showButton
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default UserChangePassword
