import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
interface PropsCallApi {
  path?: string
  params?: Object
  tokenType?: 'ADMIN' | 'APPLICATION' | 'MS'
}
export const CallApi = async ({ path = '', params = {}, tokenType = 'ADMIN' }: PropsCallApi) => {
  try {
    const { data } = await axios.post(path, params, {
      ...addAuthToHeader(tokenType),
    })
    return {
      data: data.data,
      status: 'success',
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error executing API! Endpoint: ${path}`,
      error: error.response,
    })
  }
}
