export const scenariosTableColumns = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: 'Expected Status Code',
    name: 'ExpectedStatusCode',
  },
  {
    label: 'Expected Status Message',
    name: 'ExpectedStatusMessage',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]

export const testResultsTableColumns = [
  // {
  //   label: 'Expected Code',
  //   name: 'StatusCodeExpected',
  //   customWidth: '15',
  // },
  // {
  //   label: 'Expected Message',
  //   name: 'StatusMessageExpected',
  // },
  {
    label: 'Received Code',
    name: 'StatusCodeReceived',
    customWidth: '20',
  },
  {
    label: 'Received Message',
    name: 'StatusMessageReceived',
  },
  {
    label: 'Success',
    name: 'Success',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]
