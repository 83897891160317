/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useContext, useEffect, createRef, isValidElement } from 'react'
import classNames from 'classnames'
import CIcon from '@coreui/icons-react'
import { useLocation } from 'react-router-dom'

import { Context } from '../CSidebarCustom/CSidebarCustom'
export const DropdownContext = React.createContext({})

export const iconProps = (icon: any) => {
  if (typeof icon === 'object') {
    const key = icon.size ? 'className' : 'customClasses'
    return {
      ...icon,
      [`${key}`]: icon.customClasses || `c-sidebar-nav-icon ${icon.className}`,
    }
  } else {
    return {
      customClasses: 'c-sidebar-nav-icon',
      name: icon,
    }
  }
}

//component - CoreUI / CSidebarNavDropdown
const CSidebarNavDropdownCustom: React.FC<any> = (props) => {
  const {
    children,
    className,
    //
    innerRef,
    icon,
    fontIcon,
    name,
    show,
    route,
    ...attributes
  } = props

  const ref = createRef<any>()
  innerRef && innerRef(ref)

  const { dropdownMode, openDropdown, setOpenDropdown } = useContext(Context)
  // const locationPath = useLocation().pathname
  const [isOpen, setIsOpen] = useState(show)
  useEffect(() => {
    setIsOpen(show)
  }, [show])

  useEffect(() => {
    !dropdownMode && (!openDropdown || !ref.current.contains(openDropdown)) && setIsOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDropdown])

  const toggle = () => {
    // console.log('TOGLLE', isOpen, ref.current)
    setOpenDropdown(true)
    !dropdownMode &&
      setOpenDropdown(
        isOpen ? ref.current.parentNode.closest('.c-sidebar-nav-dropdown') : ref.current,
      )
    setIsOpen(!isOpen)
  }

  let path = ''
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    path = useLocation().pathname
  } catch (e) {
    console.warn(e)
  }

  useEffect(() => {
    if (dropdownMode === 'close') {
      setIsOpen(false)
    } else if (dropdownMode === 'closeInactive' && route) {
      setIsOpen(path.includes(route))
    } else if ((!dropdownMode || dropdownMode !== 'noAction') && !isOpen && route) {
      setIsOpen(path.includes(route))
    } else if (path === '/' || path === '/dashboard') {
      setIsOpen(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  //render
  const classes = classNames('c-sidebar-nav-dropdown', isOpen && 'c-show', className)

  const iconClasses = classNames('c-sidebar-nav-icon', fontIcon)

  return (
    <li className={classes} {...attributes} ref={ref}>
      <a
        className='c-sidebar-nav-dropdown-toggle'
        tabIndex={0}
        onClick={toggle}
        aria-label='menu dropdown'
      >
        {icon && (isValidElement(icon) ? icon : <CIcon {...iconProps(icon)} />)}
        {fontIcon && <i className={iconClasses} />}
        {name}
      </a>
      <ul className='c-sidebar-nav-dropdown-items'>
        <DropdownContext.Provider value={{ isOpen }}>{children}</DropdownContext.Provider>
      </ul>
    </li>
  )
}

export default CSidebarNavDropdownCustom
