import { CButton, CCard, CCardBody, CCardHeader, CCol, CFormGroup, CModal, CModalBody, CModalFooter, CModalHeader } from "@coreui/react"
import { useState } from "react"
import { InputField, Loader } from 'src/shared'
import { StatusInfo } from "./enum/Status"
import { useActionsModels } from "./hooks/ActionsModels"


interface PropsInvoiceHeader {
  header?: React.ReactNode
  children: React.ReactNode
  customersId: React.ReactNode
  setStateAdd: any
  stateAdd: any
  totalAmount: any
}

const InvoiceModal: React.FC<PropsInvoiceHeader> = ({header = '', children, customersId, setStateAdd,stateAdd, totalAmount}) => {
  
  const modelName = 'CustomersTrans'
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setStateAdd(!stateAdd)
    setModal(!modal)
  }
  
  const id = 0
  const CustomersId = customersId

  const [apiData, status, saveData, handleChange, handleNumberChange] =
  useActionsModels({
    id,
    CustomersId,
    modelName,
    setStateAdd: setStateAdd,
    stateAdd: stateAdd
  })
  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData } = apiData
  const { TransactionDate, Amount, Description } = modelData
  
  const today = () => {
    const date = new window.Date()
    let year = date.getFullYear()
    let month = date.getMonth()+1
    let day = date.getDate()

    return `${year}-${month<10?`0${month}`:`${month}`}-${day<10?`0${day}`:`${day}`}`
  }
  modelData.TransactionDate = today()

  return (
    <>
    <CCard className='border-transparent mb-2'>
        <CCardHeader
          className='d-flex align-items-center'
          style={{ backgroundColor: '#3C4B64', color: 'white', maxHeight:'48px' }}
        >
          <div style={{width: '98%'}}>
            <b>{header}</b>
          </div>
          <div style={{color: '#1FFD00', marginRight: '5px'}}>(${totalAmount})</div>
          <div style={{width: '2%', textAlign: 'end', fontWeight:'bold', fontSize: '25px'}}>
            <p onClick={()=>{setModal(!modal); setStateAdd(!stateAdd)}} style={{cursor: 'pointer', padding: '0px', margin:'0px'}}>+</p>
          </div>
        </CCardHeader>
        <CCardBody className='pb-0 pt-3 px-2 px-lg-3 '>{children}</CCardBody>
      </CCard>

      <CModal
        show={modal}
        onClose={toggle}
      >      
        <CModalHeader closeButton>Add Invoice Transaction Item</CModalHeader>
        <CModalBody>
          <CFormGroup row>
            <CCol md='6' className='pr-lg-2 pl-lg-2'>
              <InputField
                name='TransactionDate'
                type='date'
                label='Transaction Date'
                value={TransactionDate ?? ''}
                onChange={handleChange}
              />
            </CCol>
            <CCol md='6' className='pr-lg-2 pl-lg-2'>
              <InputField
                name='Amount'
                type='text'
                label='Amount'
                value={Amount ?? ''}
                onChange={handleNumberChange}
                alignment='right'
                className='item-number-input'
              />
            </CCol>
          </CFormGroup>
          <CFormGroup row>
            <CCol md='12' className='pr-lg-2 pl-lg-2'>
              <InputField
                name='Description'
                type='textarea'
                label='Description'
                value={Description ?? ''}
                onChange={handleChange}
              />
            </CCol>
          </CFormGroup>
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={()=>{ toggle(); saveData();}}>Save</CButton>{' '}
          <CButton
            color="secondary"
            onClick={toggle}
          >Cancel</CButton>
        </CModalFooter>
      </CModal>
    </>
  )
}

export default InvoiceModal