import { ColumnData } from 'library-soft-datatable'

export const modelsTableColumns: ColumnData[] = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Last Name',
    name: 'LastName',
  },
  {
    label: 'Phone',
    name: 'Phone',
  },
  {
    label: 'Address',
    name: 'Address',
  },
  {
    label: 'City',
    name: 'City',
  },
  {
    label: 'State',
    name: 'State',
  },
  {
    label: 'Zip',
    name: 'Zip',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]

export const tableFieldsTableColumns = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Type',
    name: 'Type',
  },
  {
    label: 'Size',
    name: 'Size',
  },
  {
    label: 'Primary Key',
    name: 'IsPrimary',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'Identity',
    name: 'IsIdentity',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'Is Encrypted',
    name: 'IsEncrypted',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
