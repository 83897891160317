import { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import Editable from './Editable';

interface PropsInfoForm {
    modelData?: any;
    handleChange?: any;
    handleCheck?: any;
}

const JemcareFormInfo = ({ modelData }: PropsInfoForm) => {

    const [jsonForm, setJsonForm] = useState([]);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(false);
        let json = JSON.parse(modelData.JsonForm);
        let temp: any = [];
        Object.keys(json).map((key) => {
            temp.push({ key: key, value: json[key], label: key })
        });
        setJsonForm(temp);
        setLoading(true);
    }, [modelData]);

    const handleChange = (event: any) => {
        console.log(event);
    }


    return (
        <div className='jemcareFormInfoTable'>
            {isLoading ?
                <>
                    {(modelData.AdultType === 'Adult') ?
                        <Table striped bordered size="sm">
                            <thead>
                                <tr>
                                    <th>Field Name</th>
                                    <th>Field Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {modelData && jsonForm.length > 0 &&
                                    <>
                                        {
                                            jsonForm.map((element: any, key: any) => {
                                                return (
                                                    <tr key={key}>
                                                        <td style={{ width: "50%" }}>{element.label}</td>
                                                        <td style={{ width: "50%" }}>
                                                            <Editable
                                                                name={element.key}
                                                                value={element.value}
                                                                handleChange={handleChange}
                                                            ></Editable>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </tbody>
                        </Table>
                        :
                        <>
                            <Table striped bordered size="sm">
                                <thead>
                                    <tr>
                                        <th>Field Name</th>
                                        <th>Field Value</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {modelData && jsonForm.length > 0 &&
                                        <>
                                            {
                                                jsonForm.map((element: any, key: any) => {
                                                    return (
                                                        <tr key={key}>
                                                            <td style={{ width: "50%" }}>{element.label}</td>
                                                            <td style={{ width: "50%" }}>
                                                                <Editable
                                                                    name={element.key}
                                                                    value={element.value}
                                                                    handleChange={handleChange}
                                                                ></Editable>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </>
                                    }
                                </tbody>
                            </Table>
                        </>
                    }
                </>
                : <p>Loading...</p>
            }
        </div>
    )
}

export default JemcareFormInfo;