import { CCol, CFormGroup } from '@coreui/react'
// import { RadioGroup, Radio } from 'react-radio-input'
import { FieldsSection, InputField } from 'src/shared'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange: any
  title?: string
}
// const RadioGroupTs: any = RadioGroup
const LeadInfo = ({ title, modelData, handleChange }: PropsInfoForm) => {
  const { 
    VehicleModel,
    VehicleMake,
    VehicleYear,
    VehicleTrim,
    VehicleVin,
    VehicleMileage,
    VehicleColor,
  } = modelData
  // const mapComboToData = () =>

  return (
    <>
      <FieldsSection header={title}>
        <CFormGroup row>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='VehicleModel'
              type='text'
              label='Model'
              value={VehicleModel ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='VehicleMake'
              type='text'
              label='Make'
              value={VehicleMake ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='VehicleYear'
              type='number'
              label='Year'
              value={VehicleYear ?? ''}
              onChange={handleChange}
              alignment={'right'}
            />
          </CCol>
          <CCol md='3' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='VehicleTrim'
              type='text'
              label='Trim'
              value={VehicleTrim ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
        <CFormGroup row>
          <CCol md='4' className='pr-lg-2 pl-lg-2'>
            <InputField
              name='VehicleVin'
              type='text'
              label='Vin'
              value={VehicleVin ?? ''}
              onChange={handleChange}
            />
          </CCol>
          <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='VehicleMileage'
              type='number'
              label='Mileage'
              value={VehicleMileage ?? ''}
              onChange={handleChange}
              alignment={'right'}
            />
          </CCol>
          <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputField
              name='VehicleColor'
              type='text'
              label='Color'
              value={VehicleColor ?? ''}
              onChange={handleChange}
            />
          </CCol>
        </CFormGroup>
      </FieldsSection>
    </>
  )
}

export default LeadInfo