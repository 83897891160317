import { useCallback, useEffect, useMemo, useState } from 'react'
import { StatusInfo } from '../enum/Status'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'
import { addAuthAccessTokenToHeader, logoutVerifyErrors } from 'src/services/authHelper'
import axios from 'axios'
import { BASE_API, MODELS, SAVE, SERVICES } from 'src/services/routeConst'

interface PropsActionsModels {
  id: any
  modelName: any
  history: any
  modelsId?: string
}
type ApiDataState = { modelData: any }
const fields: string[] = [
  'Id',
  'LeadsStatusId',
  'RecordId',

  'Campaign',

  'CustName',
  'CustFirstName',
  'CustLastName',
  'CustPhoneMobile',
  'CustPhoneOther',
  'CustAddressStreet',
  'CustAddressCity',
  'CustAddressState',
  'CustAddressZip',

  'DnServiceBasePackage',
  'DnServiceMechanicalInspection',
  'DnServiceExpressService',
  'DnServiceSigningOvernighting',
  'DnServicenotary',
  
  'VehicleYear',
  'VehicleMake',
  'VehicleModel',
  'VehicleTrim',
  'VehicleColor',
  'VehicleVin',
  'VehicleMileage',

  'CondKeyQuantity',
  'CondInterior',
  'CondExterior',
  'CondTire',
  'CondAccidentHadAccident',
  'CondAccidentRepaired',
  'CondAccidentEstimate',
  'CondAccidentRepairedCost',
  'CondAccidentEstimatedCost',
  'CondModsEngine',
  'CondModsExhaustEmissions',
  'CondModsRaisedSuspension',
  'CondModsLoweredSuspension',
  'CondElectricalRadio',
  'CondElectricalDvdBluRay',
  'CondElectricalNavigation',
  'CondElectricalSunroofMoonroof',
  'CondMechEngine',
  'CondMechTransmission',
  'CondMechAircon',
  'CondMechHeater',
  'CondMechPowerLiftgate',
  'CondMechPowerSlidingdoors',

  'OfferAmount',

  'ExceptMods',
  'ExceptTrim',
  'ExceptAccidents',
  'ExceptAccidentsRepairCost',
  'ExceptAccidentsLimit',
  'ExceptElectrical',
  'ExceptElectricalRadio',
  'ExceptElectricalNavigation',
  'ExceptElectricalSunroof',
  'ExceptElectricalDvdBlueRay',
  'ExceptElectricalTotal',
  'ExceptVinAuto',
  'ExceptMech',
  'ExceptMechPowerSlidingDoors',
  'ExceptMechPowerLiftgate',
  'ExceptMechTrans',
  'ExceptMechEngine',
  'ExceptMechHeater',
  'ExceptMechAircon',
  'ExceptMechTotal',
  'ExceptHistory',
  'ExceptRepairs',
  'ExceptOptions',
  'ExceptMcResponse',
  'ExceptAccidentPercent',
  
  'IsException',

  'IsNeedFollowUp',
  'FollowUpDate',
  'CustomersId',
  'TokensId_AssignedTo',
]
export const useActionsModels = ({
  id,
  modelName,
  modelsId = 'Id',
  history,
}: PropsActionsModels): [ApiDataState, StatusInfo, () => Promise<void>, Function, Function] => {
  const [apiData, setApiData] = useState<ApiDataState>({ modelData: null })
  const [status, setStatus] = useState<StatusInfo>(StatusInfo.loading)
  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback<any>(async () => {
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [modelsId]: 0 },
      })
      
      const dataResponse = mainData?.data ? mainData.data.RetrieveInfo[0] : mainData
      setApiData({ modelData: dataResponse })
      setStatus(StatusInfo.idle)
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }, [rootRepository, id, modelName, modelsId])

  useEffect(() => {
    getData()
  }, [getData])

  const today = () => {
    const date = new window.Date()
    let year = date.getFullYear()
    let month = date.getMonth()+1
    let day = date.getDate()

    console.log(`${year}-${month<10?`0${month}`:`${month}`}-${day<10?`0${day}`:`${day}`}`);
    return `${year}-${month<10?`0${month}`:`${month}`}-${day<10?`0${day}`:`${day}`}`
  }

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ modelData: null })
      history.goBack()
    }, delay)
  }
  const mappingFields = (apiData) => {
    const dataToSave: any = apiData.modelData
    return {
      ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
    }
  }
  const saveData = async () => {
    setStatus(StatusInfo.saving)
    try {
      const { message } = await SaveModel({
        body: {
          Model: modelName,
          Params: mappingFields(apiData),
        },
      })
      
      if(apiData.modelData.CustomersId !== 0 && apiData.modelData.Id !== 0){
        axios.patch(
          `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
          {
            Model: 'Customers',
            Id: apiData.modelData.CustomersId,
            Params: [
              { name: 'FollowUpDate', value: today() },
              { name: 'IsNeedFollowUp', value: true },
              { name: 'FirstName', value: apiData.modelData.CustFirstName },
              { name: 'LastName', value: apiData.modelData.CustLastName },
              { name: 'PhoneMobile', value: apiData.modelData.CustPhoneMobile },
              { name: 'PhoneOther', value: apiData.modelData.CustPhoneOther },
              { name: 'Address', value: apiData.modelData.CustAddressStreet },
              { name: 'City', value: apiData.modelData.CustAddressCity },
              { name: 'State', value: apiData.modelData.CustAddressState },
              { name: 'Zip', value: apiData.modelData.CustAddressZip },
  
              { name: 'Vehicle', value: `${apiData.modelData.VehicleYear || ''} ${apiData.modelData.VehicleMake || ''} ${apiData.modelData.VehicleModel || ''} ${apiData.modelData.VehicleTrim || ''}`},
              { name: 'VehicleVin', value: apiData.modelData.VehicleVin },
              { name: 'VehicleMileage', value: apiData.modelData.VehicleMileage },
              { name: 'VehicleColor', value: apiData.modelData.VehicleColor },
              
  
            ],
          },
          { ...addAuthAccessTokenToHeader() },
        )
      }
      toast.success(message)
      getData()
      goBack()
      setStatus(StatusInfo.idle)
    } catch (error: any) {
      const status = error.response.status 
      logoutVerifyErrors(status)
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }
  const handleAddressChange = ({
    Address = '',
    Address2 = '',
    City = '',
    State = '',
    ZipCode = '',
  }) => {
    // console.log({ Address, Address2, City, State, ZipCode })
    setApiData((st: any) => ({
      ...st,
      modelData: { ...st.modelData, Address, Address2, City, State, ZipCode },
    }))
  }
  return [apiData, status, saveData, handleChange, handleAddressChange]
}
