import { CCol, CFormGroup } from '@coreui/react'
// import { RadioGroup, Radio } from 'react-radio-input'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange?: any
  title?: string
}
// const RadioGroupTs: any = RadioGroup
const ElectricalInfo = ({ title, modelData }: PropsInfoForm) => {
  const { 
    // ExceptMods,
    // ExceptTrim,
    // ExceptVinAuto,
    // ExceptHistory,
    // ExceptRepairs,
    // ExceptOptions,
    // ExceptMcResponse,
    
    // ExceptAccidents,
    // ExceptAccidentPercent,
    // ExceptAccidentsRepairCost,
    // ExceptAccidentsLimit,
    
    ExceptElectrical,
    ExceptElectricalRadio,
    ExceptElectricalNavigation,
    ExceptElectricalSunroof,
    ExceptElectricalDvdBlueRay,
    ExceptElectricalTotal,
    
    
    // ExceptMech,
    // ExceptMechPowerSlidingDoors,
    // ExceptMechPowerLiftgate,
    // ExceptMechTrans,
    // ExceptMechEngine,
    // ExceptMechHeater,
    // ExceptMechAircon,
    // ExceptMechTotal,
  } = modelData
  // const mapComboToData = () =>

  return (
    <>
      {/* <FieldsSection header={title} text={'sourceData'}> */}
      <FieldsSection header={title}>
        <CFormGroup row>
          <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
            <InputLabel label={'Electrical'} value={ExceptElectrical? ExceptElectrical.toString() :'False'}/>
            <InputLabel label={'Radio'} value={ExceptElectricalRadio ?? '⠀'}/>
            <InputLabel label={'Navigation'} value={ExceptElectricalNavigation ?? '⠀'}/>
            <InputLabel label={'Sunroof'} value={ExceptElectricalSunroof ?? '⠀'}/>
            <InputLabel label={'DvdBlueRay'} value={ExceptElectricalDvdBlueRay ?? '⠀'}/>
            <InputLabel label={'Total'} value={ExceptElectricalTotal ?? '⠀'}/>
          </CCol>
        </CFormGroup>

      </FieldsSection>
    </>
  )
}

export default ElectricalInfo