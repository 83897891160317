// import { msalInstance } from 'src'
// import { setUser } from 'src/redux/actions/authActions'
// import store from '../../redux/store'

import history from 'src/history'
import { routeToPage } from '../routingHelper'

export class APIError {
  status: any
  alert: any
  message: string
  error: any
  name: string
  constructor({ status, message, error }: any) {
    const apiError = error?.data?.message || error?.data?.data?.message
    this.status = status
    this.alert = message
    this.message = `${message}${apiError ? ` - ${apiError}` : ''}`
    this.error = error
    this.name = 'APIError'
    if (
      apiError?.includes('Token Expired') ||
      apiError?.includes('Invalid Token') ||
      apiError?.includes('Token Invalid')
    ) {
      this.message = apiError
      // const msalInstanceTS: any = msalInstance
      // const currentAccount = msalInstanceTS.getActiveAccount()

      setTimeout(() => {
        routeToPage(history, '/logout', { method: 'auto' })
        // store.dispatch(setUser({}))
        // msalInstanceTS.logoutRedirect({
        //   account: currentAccount,
        //   postLogoutRedirectUri:
        //     window.location.hostname === 'localhost'
        //       ? 'https://localhost:3003/logout'
        //       : 'https://admin.vitalcard.com/logout',
        //   mainWindowRedirectUri:
        //     window.location.hostname === 'localhost'
        //       ? 'https://localhost:3003/logout'
        //       : 'https://admin.vitalcard.com/logout',
        // })
      }, 1000)
    }
  }
}
