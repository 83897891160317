import { useState, useEffect } from 'react';
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MdCheckCircle } from "react-icons/md";
import { getAuthToken } from 'src/services/authHelper';
import { CDropdown, CDropdownItem, CDropdownMenu, CDropdownToggle } from '@coreui/react';
import SwiperCore, {
    Navigation
} from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

type BreadcrumbsProps = {
    isCalled: boolean;
    actionClick: any;
    MetasId: any;
    CustomersId: any;
    BreadcrumbUrl: any

}
SwiperCore.use([Navigation]);
const Breadcrumb = ({ isCalled, actionClick, MetasId, BreadcrumbUrl }: BreadcrumbsProps) => {

    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [drpBtnData, setDrpBtnData] = useState<any>([]);

    useEffect(() => {
        if (isCalled) {
            setLoading(true);
            getBreadCrumbData();
        }
    }, [isCalled]);

    const getBreadCrumbData = () => {
        let token = getAuthToken('ADMIN');
        axios({
            method: 'POST',
            url: BreadcrumbUrl,
            data: {
                MetasId: MetasId
            },
            headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        }).then((res) => {
            if (res.data.data) {
                setData(res.data.data.StatusHistory);
                let wData = res.data.data.ActionsList.map((route: any) => {
                    return {
                        action: route.Id,
                        title: route.Name
                    }
                })
                setDrpBtnData(wData)
                setLoading(false);
            } else {
            }
        }).catch(() => {

        })
    }

    const clickWorkFlow = (event: any) => {
        //   let wAction = drpBtnData.find((d: any) => d.action === event);
        let token = getAuthToken('ADMIN');
        axios.post(
            'https://api.softsmartinc.com/Services/vOld/Widget/Breadcrumbs/UpdateStatus',
            {
                ModelName: "Customers",
                MetasId: MetasId,
                WorkflowsActionsId: event
            },
            { headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' } }
        ).then((res) => {
            if (res.data) {
                actionClick(true);
                getBreadCrumbData();
            }
        })
            .catch(() => { })
    }

    const activeSlide = {
        'background': '#5c636a',
        'color': '#fff',
        'height': '70px',
        'alignItems': 'center',
        'justifyContent': 'center',
        'padding': '2px 10px',
        'borderRadius': '0.3rem',
        'minWidth': '190px'
    }

    const nonActiveSlide = {
        'background': '#CFE2F3',
        'color': '#000',
        'height': '70px',
        'alignItems': 'center',
        'justifyContent': 'center',
        'padding': '2px 10px',
        'borderRadius': '0.3rem',
        'minWidth': '190px'
    }

    const renderSlides = data.map((element: any, key: any) => {
        return (
            <SwiperSlide key={key} style={(key === data.length - 1) ? activeSlide : nonActiveSlide}>
                {(key === data.length - 1) ? <MdCheckCircle size={50} style={(key === data.length - 1) ? { 'color': 'white' } : {}} className='swipper-icon'></MdCheckCircle> : null}
                {element.Breadcrumb}
                {/* <div className='swiper-icon-arrow-right'><MdKeyboardArrowRight size={30} ></MdKeyboardArrowRight></div> */}
            </SwiperSlide>
        );
    })

    return (

        <div className='breadcrumb-container' style={{ maxWidth: '100%', display: 'flex', paddingLeft: 15 }}>
            <div className='swiper-breadcrumb' style={{ maxWidth: 'calc(100% - 240px)' }}>
                {loading ? <SwiperSlide>Loading...</SwiperSlide> :
                    <Swiper
                        // navigation={true}
                        spaceBetween={20}
                        mousewheel={true}
                        grabCursor={true}
                        initialSlide={(data.length)}
                        keyboard={{
                            enabled: true,
                        }}
                        slidesPerView={'auto'}
                        breakpointsBase={'container'}
                        breakpoints={{
                            640: {
                                spaceBetween: 20,
                            },
                            768: {
                                spaceBetween: 20,
                            },
                            1024: {
                                spaceBetween: 20,
                            }
                        }}
                    >
                        {renderSlides}
                    </Swiper>
                }
            </div>
            <div className="action-breadcrumb" style={{ width: '220px', marginLeft: '20px' }}>
                <CDropdown>
                    <CDropdownToggle id="dropdown-basic" style={{ width: '100%', border: 'none', height: '70px', background: '#5c636a', fontSize: '20px', fontWeight: 'bold' }}>
                        Select Next Action
                    </CDropdownToggle>
                    <CDropdownMenu style={{ width: '100%' }}>
                        {drpBtnData.map((res: any, key: any) => {
                            return (
                                <CDropdownItem key={key} onClick={() => { clickWorkFlow(res.action) }} as="button"><span style={{ whiteSpace: 'break-spaces' }}>{res.title}</span></CDropdownItem>
                            )
                        })
                        }
                    </CDropdownMenu>
                </CDropdown>
            </div>
        </div>
    )
}
export default Breadcrumb;