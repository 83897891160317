// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
// import AddressComponent from '../AddressComponent'

// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsPickUpInfoForm {
  modelData: any
  handleChange: any
  title: string
  handleAddressChange: any
}

const PickUpInfo = ({
  modelData,
  handleChange,
  title = 'Model Name',
  // handleAddressChange,
}: PropsPickUpInfoForm) => {
  return (
    <FieldsSection
      header={
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          {title}
        </div>
      }
    >
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='PickupAddress'
            type='text'
            label='Address '
            value={modelData?.PickupAddress ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='PickupCity'
            type='text'
            label='City'
            value={modelData?.PickupCity ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='3' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='PickupState'
            type='text'
            label='State'
            value={modelData?.PickupState ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='3' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='PickupZip'
            type='text'
            label='Zip'
            value={modelData?.PickupZip ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      {/* <AddressComponent
        modelName='Pickup'
        modelData={modelData ?? {}}
        handleAddressChange={handleAddressChange}
        handleChange={handleChange}
      /> */}
    </FieldsSection>
  )
}

export default PickUpInfo
