import React from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setUser } from 'src/redux/actions/authActions'
import { Loader } from 'src/shared'
interface PropsLogout {
  setUser?: any
}
const Logout: React.FC<PropsLogout> = ({ setUser }) => {
  const history = useHistory()

  React.useEffect(() => {
    setUser({})
    window.history?.state?.state?.method !== 'auto'
      ? window.location.replace('https://thesource.ai/site/')
      : history.push('/login')
  }, [setUser, history])

  return <Loader fullScreen />
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: (data: any) => dispatch(setUser(data)),
  }
}

export default React.memo(connect(null, mapDispatchToProps)(Logout))
