import React, { useRef } from 'react'
import './CustomTextEditor.css'
declare global {
  interface Window {
    CKEDITOR: any
    ckeditorPlaceholdersAdminVitalCard: any
  }
}
const CKEDITOR4 = window.CKEDITOR

interface PropsCustomTextEditor {
  value?: string
  onChange?: any
  config?: any
  placeholders?: any[]
}
const CustomTextEditor: React.FC<PropsCustomTextEditor> = ({
  value = '',
  onChange = () => {},
  config,
  placeholders,
}: PropsCustomTextEditor) => {
  const itemTemplate =
      '<li data-id="{id}">' +
      '<div><strong class="item-title" style="color:black">{name}</strong></div>' +
      '<div><i style="color:black">{description}</i></div>' +
      '</li>',
    outputTemplate = '&#123&#123{value}&#125&#125<span>&nbsp;</span>'
  const configEditor = (evt: any) => {
    const autocomplete = new CKEDITOR4.plugins.autocomplete(evt.editor, {
      textTestCallback: (range: any) => {
        if (!range.collapsed) {
          return null
        }

        return CKEDITOR4.plugins.textMatch.match(range, (text: any, offset: any) => {
          const pattern = /\{{2}([A-Za-z]|)*$/,
            match = text.slice(0, offset).match(pattern)

          if (!match) {
            return null
          }

          return {
            start: match.index,
            end: offset,
          }
        })
      },
      dataCallback: (matchInfo: any, callback: any) => {
        // console.log(window.ckeditorPlaceholdersAdminVitalCard)
        const data = window.ckeditorPlaceholdersAdminVitalCard.filter(function (item: any) {
          const itemName = '{{' + item.name.toLowerCase() + '}}'
          return itemName.indexOf(matchInfo.query.toLowerCase()) === 0
        })

        callback(data)
      },
      itemTemplate: itemTemplate,
      outputTemplate: outputTemplate,
    })

    // Override default getHtmlToInsert to enable rich content output.
    autocomplete.getHtmlToInsert = function (item: any) {
      return this.outputTemplate.output(item)
    }
  }
  const configCKeditor = () =>
    CKEDITOR4.replace(editorRef.current, {
      ...config,
      height: '52vh',
      // autoGrow_maxHeight: 450,
      // autoGrow_minHeight: 550,
      plugins:
        'autocomplete,docprops,textmatch,wysiwygarea,basicstyles,link,undo,placeholder,emoji,list,liststyle,widget,removeformat,font,balloonpanel,balloontoolbar,toolbar,justify,floatpanel',
      on: {
        change: (e: any) => {
          // console.log('ONCHANGE', e.editor.getData())
          const value = e.editor.getData()
          // setPlaceholders(placeholders)

          onChange({ data: value })
        },
        instanceReady: function (evt: any) {
          window.ckeditorPlaceholdersAdminVitalCard = placeholders ?? []
          configEditor(evt)
        },
      },
    })
  const editorRef = useRef<any>()
  React.useEffect(() => {
    window.ckeditorPlaceholdersAdminVitalCard = placeholders ?? []
  }, [placeholders])
  React.useEffect(() => {
    const editor = CKEDITOR4.instances['CKEDITOR-ADMIN']
    if (!editor) {
      if (editorRef.current) {
        configCKeditor()
      }
    }
    return () => {
      console.log(editor)
      CKEDITOR4.instances['CKEDITOR-ADMIN'].destroy()
      delete window.ckeditorPlaceholdersAdminVitalCard
      // editor.destroy(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <textarea
        ref={editorRef}
        //cols={80}
        rows={100}
        id='CKEDITOR-ADMIN'
        data-sample-short
        defaultValue={`${value}`}
      ></textarea>
    </>
  )
}

export default CustomTextEditor
