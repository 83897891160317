// Generated with util/create-search-page.js
import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { CONNECTIONS_MODEL, CONNECTIONS_ID } from 'src/services/routeConst'
import { RootRepository } from 'src/repository/admin/rootRepository'

const fields = [
  'ConnectionsId',
  'Name',
  'DbServer',
  'DbName',
  'DbUser',
  'DbPassword',
  'ConnectionString',
  'Description',
]
interface PropsEditConnectionsModal {
  showModal?: any
  modelName?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}
const EditConnectionsModal: React.FC<PropsEditConnectionsModal> = ({
  showModal = false,
  modelName = CONNECTIONS_MODEL,
  setShowModal,
  modalData,
  callBack = () => {},
}) => {
  const [loadingData, setLoadingData] = useState<any>(true)
  const [apiData, setApiData] = useState<any>()
  const { id = 0 } = modalData

  const rootRepository = React.useMemo(() => new RootRepository(), [])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    const getDataInEffect = async () => {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [CONNECTIONS_ID]: 0 },
      })
      setApiData(mainData)
    }
    loadingData && getDataInEffect()
  }, [id, loadingData, modelName, rootRepository])

  useEffect(() => {
    apiData && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiData])

  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    await rootRepository.saveData({
      modelName,
      idName: CONNECTIONS_ID,
      dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: apiData[field] }))),
    })
    setApiData({})
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setApiData((st: any) => ({ ...st, [name]: value }))
  }

  if (loadingData) {
    return null
  }
  const { Name, DbServer, DbName, DbUser, DbPassword, ConnectionString, Description } = apiData
  return (
    <CModal
      show={showModal}
      onClose={() => {
        setApiData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} Connection</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header=''>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Name'
                    type='text'
                    label='Name'
                    value={Name}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='DbServer'
                    type='text'
                    label='DbServer'
                    value={DbServer}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='DbName'
                    type='text'
                    label='DbName'
                    value={DbName}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='DbUser'
                    type='text'
                    label='DbUser'
                    value={DbUser}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='DbPassword'
                    type='text'
                    label='DbPassword'
                    value={DbPassword}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='ConnectionString'
                    type='text'
                    label='ConnectionString'
                    value={ConnectionString}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Description'
                    type='text'
                    label='Description'
                    value={Description}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            setApiData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default EditConnectionsModal
