import HeaderComponent from './Header'
import CustomerInfo from './CustomerInfo'
import VehicleInfo from './VehicleInfo'
import AccidentHistoryInfo from './AccidentHistoryInfo'
import VehicleConditionsInfo from './VehicleConditionsInfo'
import MechanicalIssuesInfo from './MechanicalIssuesInfo'
import TimeslotInfo from './TimeslotInfo'
import ServiceInfo from './ServiceInfo'
import AddressComponent from './Address'

export { 
  HeaderComponent, 
  CustomerInfo, 
  VehicleInfo, 
  AccidentHistoryInfo,
  VehicleConditionsInfo,
  MechanicalIssuesInfo,
  AddressComponent,
  TimeslotInfo,
  ServiceInfo
}
