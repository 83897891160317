import { CButton, CCardHeader } from '@coreui/react'
import React from 'react'
import LoadingButton from 'src/shared/components/LoadingButton'
interface PropsEndPointsHeader {
  status?: any
  saveData?: any
  onCancel?: any
  modelName?: any
}
const EndpointsHeader: React.FC<PropsEndPointsHeader> = ({ status, saveData, onCancel }: any) => {
  const isLoading = status === 'saving'
  return (
    <CCardHeader className='d-flex align-items-center card-accent-secondary'>
      Endpoint Details
      {/* <CButton
        className='mr-1 ml-auto'
        type='button'
        color='primary'
        style={{ float: 'right' }}
        disabled={savingDataRef.current}
        onClick={saveData}
      >
        Save
      </CButton> */}
      <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton>
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancel}
      >
        Cancel
      </CButton>
    </CCardHeader>
  )
}

export default EndpointsHeader
