// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const OwnershipInfo = ({ modelData, handleChange }: PropsInfoForm) => {
  const {
    RegisteredOwner,
    IsLienHolder,
    LienHolder,
    Payoff,
    PayoffExpiresOn,
    LastTitleIssuedOn,
    TitleNumber,
    LastOdometer,
  } = modelData
  return (
    <FieldsSection header={'Title Info'}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='RegisteredOwner'
            type='text'
            label='Registered Owner'
            value={RegisteredOwner ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField type='fancyswitch' name='Lien' label={`Lien`}>
            <CustomSwitch
              name='IsLienHolder'
              className='d-block'
              value={IsLienHolder}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.IsLienHolder })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='LienHolder'
            type='text'
            label='Lien Bank'
            value={LienHolder ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='Payoff'
            type='currency'
            label='Payoff'
            decimals={2}
            showSeparator
            alignment='right'
            value={Payoff ?? ''}
            onChange={({ name, value }) => {
              handleChange({
                name,
                value: value.replaceAll('$', ''),
              })
            }}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='PayoffExpiresOn'
            type='date'
            label='Payoff Expires On'
            value={PayoffExpiresOn ?? ''}
            onChange={handleChange}
            alignment='right'
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='LastTitleIssuedOn'
            type='date'
            label='Last Title Issued Date'
            value={LastTitleIssuedOn ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='TitleNumber'
            type='text'
            label='Title Number'
            value={TitleNumber ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='LastOdometer'
            type='number'
            label='Last Odometer'
            value={LastOdometer ?? ''}
            onChange={handleChange}
            alignment='right'
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default OwnershipInfo
