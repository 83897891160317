// Generated with util/create-search-page.js
import { CFormGroup } from '@coreui/react'
import { useEffect, useRef } from 'react'
import { FieldsSection } from 'src/shared'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'

const EditorInfo = ({ modelData }: any) => {
  const { ProcedureCommand } = modelData
  const editorRef = useRef<any>()
  useEffect(() => {
    editorRef.current.beautifyCode()
  }, [])
  return (
    <>
      <FieldsSection header='Procedure Command'>
        <CFormGroup row>
          <CustomCodeEditor
            ref={editorRef}
            value={ProcedureCommand}
            mode='sqlserver'
            height='23rem'
            readOnly={true}
            theme='dracula'
          ></CustomCodeEditor>
        </CFormGroup>
      </FieldsSection>
    </>
  )
}
export default EditorInfo
