import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { MembersRepository } from 'src/repository/admin/member/MembersRepository'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
import { EXPERIAN_ID } from 'src/services/routeConst'

interface PropsEditMemberExperianModal {
  showModal?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}

const fields = [
  'ExperianId',
  'UsersId',
  'AsOfDate',
  'NoOfLatePay',
  'CreditUtilPercent',
  'NoOfInquiries',
  'AccountAvgAge',
  'CreditScore',
  'IsThinFile',
  'IsCreditDataRetrievalError',
  'IsCreditDataLoading',
  'CreditScoreGain',
  'NextRewardCreditScore',
  'TotalBalances',
  'TotalCredit',
  'NumberAccounts',
]

const EditMemberExperianModal: React.FC<PropsEditMemberExperianModal> = ({
  showModal = false,
  setShowModal,
  modalData,
  callBack = () => {},
}: PropsEditMemberExperianModal) => {
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<any>()
  const { id, UsersId, modelName } = modalData

  const membersRepository = React.useMemo(() => new MembersRepository(), [])

  useEffect(() => {
    const getDataInEffect = async () => {
      try {
        const { mainData } = await membersRepository.getData({
          id,
          modelName: modelName,
          defaultMainData: { UsersId, ExperianId: 0 },
        })
        setData(mainData)
      } catch (error: any) {
        console.error(error)
      }
    }
    loadingData && getDataInEffect()
  }, [UsersId, id, loadingData, membersRepository, modelName])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    data && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    const dataToSave: any = data
    try {
      await membersRepository.saveData({
        modelName: modelName,
        idName: EXPERIAN_ID,
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
      })
    } catch (error: any) {
      console.error(error)
    }
    // setData({})
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setData((st: any) => ({ ...st, [name]: value }))
  }

  if (loadingData) {
    return null
  }
  // console.log(data)
  return (
    <CModal
      show={showModal}
      size={'xl'}
      onClose={() => {
        // setData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} Experian Data</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='Experian Info'>
              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='AsOfDate'
                    disabled={true}
                    type='text'
                    label='As Of Date'
                    value={data.AsOfDate}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='NoOfLatePay'
                    label='# of Late Pay'
                    inputStyles={{
                      textAlign: 'right',
                    }}
                    value={data.NoOfLatePay}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='CreditUtilPercent'
                    label='Credit Util %'
                    inputStyles={{
                      textAlign: 'right',
                    }}
                    value={data.CreditUtilPercent}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='NoOfInquiries'
                    label='# of Inquiries'
                    inputStyles={{
                      textAlign: 'right',
                    }}
                    value={data.NoOfInquiries}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>

              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='AccountAvgAge'
                    label='Account Average Age'
                    inputStyles={{
                      textAlign: 'right',
                    }}
                    value={data.AccountAvgAge}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='CreditScore'
                    label='Credit Score'
                    inputStyles={{
                      textAlign: 'right',
                    }}
                    value={data.CreditScore}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsThinFile'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Thin File'
                  >
                    <CustomSwitch
                      name='IsThinFile'
                      className='d-block'
                      value={data.IsThinFile}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsCreditDataRetrievalError'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Credit Retrieval Error'
                  >
                    <CustomSwitch
                      name='IsCreditDataRetrievalError'
                      className='d-block'
                      value={data.IsCreditDataRetrievalError}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
              </CFormGroup>

              <CFormGroup row>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='IsCreditDataLoading'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Credit Data Loading'
                  >
                    <CustomSwitch
                      name='IsCreditDataLoading'
                      className='d-block'
                      value={data.IsCreditDataLoading}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='CreditScoreGain'
                    label='Credit Score Gain'
                    value={data.CreditScoreGain}
                    onChange={handleChange}
                    inputStyles={{
                      textAlign: 'right',
                    }}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='NumberAccounts'
                    label='# of Accounts'
                    value={data.NumberAccounts}
                    onChange={handleChange}
                    inputStyles={{
                      textAlign: 'right',
                    }}
                  />
                </CCol>
                <CCol xs='3' sm='3'>
                  <InputField
                    name='NextRewardCreditScore'
                    label='Next Reward Credit Score'
                    inputStyles={{
                      textAlign: 'right',
                    }}
                    value={data.NextRewardCreditScore}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>

              <CFormGroup row>
                <CCol xs='6' sm='6'>
                  <InputField
                    name='TotalBalances'
                    label='Total Balances'
                    type='currency'
                    value={data.TotalBalances}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    alignment='right'
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='6' sm='6'>
                  <InputField
                    name='TotalCredit'
                    label='Total Credit'
                    type='currency'
                    value={data.TotalCredit}
                    decimals={2}
                    alignment='right'
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            // setData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default EditMemberExperianModal
