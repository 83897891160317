// Generated with util/create-search-page.js
export const ErrorLogsTableColumns = [
  /* {
    label: 'TokensId',
    name: 'TokensId',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  }, */
  {
    label: 'Source',
    name: 'Source',
    dbType: 'varchar',
  },
  {
    label: 'StampStart',
    name: 'StampStart',
    dbType: 'datetime',
  },
  {
    label: 'StampEnd',
    name: 'StampEnd',
    dbType: 'datetime',
  },
  {
    label: 'StackTrace',
    name: 'StackTrace',
    dbType: 'varchar',
  },
  {
    label: 'Executiontime',
    name: 'Executiontime',
    dbType: 'bigint',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'HttpErrorNo',
    name: 'HttpErrorNo',
    dbType: 'int',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'Message',
    name: 'Message',
    dbType: 'varchar',
  },

  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view'],
    sortable: false,
  },
]
