// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  handleAddressChange: any
  title: string
}

const VehicleInfo = ({
  modelData,
  handleChange,
  title = 'Model Name',
}: PropsInfoForm) => {
  const { Vehicle, VehicleVin, VehicleMileage, VehicleColor } = modelData  

  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='Vehicle'
            type='text'
            label='Name'
            value={Vehicle ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='VehicleVin'
            type='text'
            label='Vin'
            value={VehicleVin ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='VehicleMileage'
            type='number'
            label='Mileage'
            value={VehicleMileage ?? ''}
            onChange={handleChange}
            alignment='right'
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='VehicleColor'
            type='text'
            label='Color'
            value={VehicleColor ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      {/* <CFormGroup row>
        <CCol>
          <InputField
            name='CustomerLastName'
            type='phone'
            label='Phone'
            value={Phone}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup> */}
      {/* <CFormGroup row>
        <CCol>
          <InputField
            name='PhoneOther'
            type='phone'
            label='Other Phone'
            value={PhoneOther ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup> */}

      {/*   <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            disabled
            name='IsError'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              disabled
              name='IsError'
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup> */}
    </FieldsSection>
  )
}

export default VehicleInfo
