import { useState } from "react";
import { JkcInput } from "smartjkc"

const SmartJkcInput = () => {
  const [settings, setSettings] = useState<any>({
    label: "Phone Number",
    value: "(123)-456-7890",
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" },
    mask: "(999)-999-9999",
    maskPlaceholder: "#",
    alwaysShowMask: true,
  });
  const [settings1, setSettings1] = useState<any>({
    label: "Salary",
    value: '25000',
    prefix: '$',
    decimalSeparator: '.',
    decimalsLimit: 2,
    decimalScale: 2,
    step: 1,
    disableGroupSeparators: true,
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" }
  });
  const [settings2, setSettings2] = useState<any>({
    label: "Percentages",
    value: "40",
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" },
    min: 40,
    max: 100,
    step: 0.5,
    precision: 2
  });
  const [settings4, setSettings4] = useState<any>({
    label: "Color Favourite",
    value: "#000",
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" }
  });
  const [settings5, setSettings5] = useState<any>({
    label: "Policy Date",
    value: new Date(),
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" }
  });
  const [settings6, setSettings6] = useState<any>({
    label: "Metting Time",
    value: new Date(),
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" },
    dateFormat: "h:mm aa",
    showTimeSelect: true,
    showTimeSelectOnly: true,
    timeCaption: "Time",
    timeIntervals: 10,
    popperPlacement: 'bottom'
  });
  const [settings7, setSettings7] = useState<any>({
    label: "Gender",
    value: "M",
    inline: true,
    json: [
      {
        name: 'Male',
        value: 'M'
      },
      {
        name: 'Female',
        value: 'F'
      },
      {
        name: 'Other',
        value: 'O'
      }
    ],
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" },
    radioCheckedBorderColor: "#000",
    // radioLabelStyle: {padding: '5px 10px 5px 10px',verticalAlign: 'top'},
    pointerColor: "#000",
    // pointerHeight: '20px',
    // pointerWidth: '20px'
  });
  const [settings8, setSettings8] = useState<any>({
    label: "Hobbies",
    value: "cricket",
    inline: true,
    json: [
      {
        name: 'Circket',
        value: 'cricket',
        checked: true,
      },
      {
        name: 'Football',
        value: 'football',
        checked: false,
      },
      {
        name: 'Chess',
        value: 'chess',
        checked: false
      }
    ],
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" },
    checkboxCheckedBorderColor: "#000",
    pointerColor: "#000"
  });
  const [settings11, setSettings11] = useState<any>({
    label: "Country",
    value: "India",
    json: [
      {
        value: 'India',
        label: 'India'
      },
      {
        value: 'USA',
        label: 'USA'
      },
      {
        value: 'UK',
        label: 'UK'
      }
    ],
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" },
    placeholder: "Select Country"
  });
  const [settings12, setSettings12] = useState<any>({
    label: "Full Name",
    value: "Smart Name",
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" }
  });
  const [settings13, setSettings13] = useState<any>({
    label: "User Name",
    value: "Smartjkc",
    iconShow: true,
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" }
  });
  const [settings14, setSettings14] = useState<any>({
    label: "Password",
    value: "Smartjkc",
    iconShow: false,
    maxLength: 16,
    iconShowHideToggle: true,
    isShowPassword: false,
    iconToggleStyle: {
      position: 'absolute',
      right: '24px',
      top: '11px',
      zIndex: '1'
    },
    className: "",
    inputStyle: { borderColor: "#000" },
    labelStyle: { color: "#000" }
  });

  const onChange = (event: any) => {
    setSettings((prevState: any) => {
      return { ...prevState, value: event.target.value };
    });
  };
  const onChange1 = (event: any) => {
    setSettings1((prevState: any) => {
      return { ...prevState, value: event };
    });
  };
  const onChange2 = (event: any) => {
    setSettings2((prevState: any) => {
      return { ...prevState, value: event };
    });
  };
  const onChange4 = (event: any) => {
    setSettings4((prevState: any) => {
      return { ...prevState, value: event.target.value };
    });
  };
  const onChange5 = (event: any) => {
    setSettings5((prevState: any) => {
      return { ...prevState, value: event };
    });
  };
  const onChange6 = (event: any) => {
    setSettings6((prevState: any) => {
      return { ...prevState, value: event };
    });
  };
  const onChange7 = (event: any) => {
    setSettings7((prevState: any) => {
      return { ...prevState, value: event.target.value };
    });
  };
  const onChange8 = (event: any) => {
    let json = [...settings8.json];
    json.map(res => {
      if (res.value == event.target.value)
        res.checked = event.target.checked;
    })
    setSettings8((prevState: any) => {
      return { ...prevState, json: json };
    });
  };
  const onChange11 = (event: any) => {
    setSettings11((prevState: any) => {
      return { ...prevState, value: event.target.value };
    });
  };
  const onChange12 = (event: any) => {
    setSettings12((prevState: any) => {
      return { ...prevState, value: event.target.value };
    });
  };
  const onChange13 = (event: any) => {
    setSettings13((prevState: any) => {
      return { ...prevState, value: event.target.value };
    });
  };
  const onChange14 = (event: any) => {
    setSettings14((prevState: any) => {
      return { ...prevState, value: event.target.value };
    });
  };
  const onClick = (event: any) => {
    setSettings14((prevState: any) => {
      return { ...prevState, isShowPassword: event };
    });
  }
  return (
    <div className="App smart-jkc-input">
      <div className="row m-5">
        <div className="col-12">
          <div className="row">
            <div className="col-3 mt-2">
              <JkcInput type={'text'} name={"firstName"} settings={settings12} onChangeEvent={onChange12}></JkcInput>
            </div>
            <div className="col-3 mt-2">
              <JkcInput type={'mask'} name={"dob"} settings={settings} onChangeEvent={onChange}></JkcInput>
            </div>
            <div className="col-3 mt-2">
              <JkcInput type={'number'} name={"salary"} settings={settings1} onChangeEvent={onChange1}></JkcInput>
            </div>
            <div className="col-3 mt-2 up-down">
              <JkcInput type={'updown'} name={"Age"} settings={settings2} onChangeEvent={onChange2}></JkcInput>
            </div>
            <div className="col-3 mt-2">
              <JkcInput type={'color'} name={"Color"} settings={settings4} onChangeEvent={onChange4}></JkcInput>
            </div>
            <div className="col-3 mt-2">
              <JkcInput type={'date'} name={"PolicyDate"} settings={settings5} onChangeEvent={onChange5}></JkcInput>
            </div>
            <div className="col-3 mt-2">
              <JkcInput type={'date'} name={"Mettings Time"} settings={settings6} onChangeEvent={onChange6}></JkcInput>
            </div>
            <div className="col-3 mt-2">
              <JkcInput type={'radio'} name={"gender"} settings={settings7} onChangeEvent={onChange7}></JkcInput>
            </div>
            <div className="col-3 mt-2">
              <JkcInput type={'checkbox'} name={"gender"} settings={settings8} onChangeEvent={onChange8}></JkcInput>
            </div>
            <div className="col-3 mt-2">
              <JkcInput type={'select'} name={"country"} settings={settings11} onChangeEvent={onChange11}></JkcInput>
            </div>
            <div className="col-3 mt-2">
              <JkcInput type={'username'} name={"username"} settings={settings13} onChangeEvent={onChange13}></JkcInput>
            </div>
            <div className="col-3 mt-2">
              <JkcInput type={'password'} name={"password"} settings={settings14} onClickEvent={onClick} onChangeEvent={onChange14}></JkcInput>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SmartJkcInput;