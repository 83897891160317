// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'

const FilesInfo = ({ modelData, handleChange }) => {
  const { Repository, Folder, FileNameOriginal, FileNameGuid, FileUrl, Description } = modelData

  return (
    <FieldsSection header='File Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Repository'
            type='text'
            label='Repository'
            value={Repository}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Folder'
            type='text'
            label='Folder'
            value={Folder}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='FileNameOriginal'
            type='text'
            label='FileNameOriginal'
            value={FileNameOriginal}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='FileNameGuid'
            type='text'
            label='FileNameGuid'
            value={FileNameGuid}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='FileUrl'
            type='text'
            label='FileUrl'
            value={FileUrl}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Description'
            type='text'
            label='Description'
            value={Description}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default FilesInfo
