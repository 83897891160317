import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import { ComboBox } from 'src/shared'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { MembersRepository } from 'src/repository/admin/member/MembersRepository'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
import { ComboModel } from 'src/services/modelNew/Combo/Combo'
import AddressFinderAux from 'src/shared/inputFields/AddressFinderAux'
import { CATEGORIES_MODEL, STATEMENTS_MODEL } from '../../../../services/routeConst'

const fields = [
  'TransId',
  'Amount',
  'CategoriesId',
  'Date',
  'DatePosted',
  'IsPosted',
  'UsersId',
  'MerchantAddress',
  'MerchantAddress2',
  'MerchantCity',
  'MerchantImageUrl',
  'MerchantName',
  'MerchantPhone',
  'MerchantState',
  'MerchantZipCode',
  'ReferenceNo',
  'StatementsId',
]
interface PropsEditMemberTransactionsModal {
  showModal?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}
const EditMemberTransactionsModal = ({
  showModal = false,
  setShowModal,
  modalData,
  callBack = () => {},
}: PropsEditMemberTransactionsModal) => {
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<any>()
  const { id, modelName, UsersId } = modalData

  const membersRepository = React.useMemo(() => new MembersRepository(), [])

  useEffect(() => {
    const getDataInEffect = async () => {
      try {
        const { data: statementsData } = await ComboModel({
          modelName: STATEMENTS_MODEL,
          postData: { UsersId },
        })
        const { data: categoriesData } = await ComboModel({
          modelName: CATEGORIES_MODEL,
        })
        const { mainData } = await membersRepository.getData({
          id,
          modelName,
          defaultMainData: { UsersId, TransId: 0, StatementsId: 0 },
        })
        setData({
          transactionData: mainData,
          statementsData: statementsData.filter((statement: any) => statement.UsersId === UsersId),
          categoriesData,
        })
      } catch (error: any) {
        console.error(error)
      }
    }
    loadingData && getDataInEffect()
  }, [UsersId, id, loadingData, membersRepository, modelName])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    data && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])
  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    const dataToSave = data.transactionData
    try {
      // dataToSave.IsPosted = [1, true, 'True'].includes(data.transactionData.IsPosted)
      //   ? 'True'
      //   : 'False'
      await membersRepository.saveData({
        modelName,
        idName: 'TransId',
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
      })
    } catch (error: any) {
      console.error(error)
    }
    // setData({})
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setData((st: any) => ({ ...st, transactionData: { ...st.transactionData, [name]: value } }))
  }

  const handleComboChange = (e: any, newValue: any = {}, name: string) => {
    if (!e.target) return
    const CodeId = newValue ? newValue.CodeId : 0
    //console.log({ CodeId })
    setData((st: any) => ({ ...st, transactionData: { ...st.transactionData, [name]: CodeId } }))
  }

  const handleAddressChange = ({ value }: any) => {
    const { Address = '', Address2 = '', City = '', State = '', ZipCode = '' } = value
    setData((st: any) => ({
      ...st,
      transactionData: {
        ...st.transactionData,
        MerchantAddress: Address,
        MerchantAddress2: Address2,
        MerchantCity: City,
        MerchantState: State,
        MerchantZipCode: ZipCode,
      },
    }))
  }

  const handlePhoneChange = ({ name = '', value = '' }) => {
    setData((st: any) => ({
      ...st,
      transactionData: { ...st.transactionData, [name]: value.replace(/[^0-9]/g, '') },
    }))
  }

  if (loadingData) {
    return null
  }
  // console.log(data)
  //Date, MerchantName, MerchantAddress, MerchantAddress2, MerchantCity, MerchantState, MerchantZipCode, MerchantImageUrl, Amount, CategoriesId, IsPosted, DatePosted, ReferenceNo
  const { transactionData = {}, statementsData = [], categoriesData = [] } = data
  // console.log(categoriesData)
  const { MerchantAddress, MerchantAddress2, MerchantCity, MerchantState, MerchantZipCode } =
    transactionData
  return (
    <CModal
      show={showModal}
      size={'xl'}
      onClose={() => {
        // setData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} Transaction</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='6'>
            <FieldsSection header='Transaction Info'>
              <CFormGroup row>
                <CCol xs='12'>
                  <ComboBox
                    showButton
                    name='StatementsId'
                    label='Statement'
                    data={statementsData}
                    value={statementsData.find(
                      (statement: any) => statement.CodeId === transactionData.StatementsId,
                    )}
                    handleChange={(e: any, newValue: any) =>
                      handleComboChange(e, newValue, 'StatementsId')
                    }
                    classNames={{ input: 'input-field' }}
                    customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                    renderOption={(option: any, { selected }: any) => (
                      <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
                    )}
                  />
                </CCol>
              </CFormGroup>

              <CFormGroup row>
                <CCol xs='4' sm='4'>
                  <InputField
                    name='Date'
                    type='mask'
                    mask={[/[0-1]/, /\d/, '-', /[0-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    label='Date'
                    value={transactionData.Date}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='4' sm='4'>
                  <InputField
                    name='Amount'
                    label='Amount'
                    type='currency'
                    alignment='right'
                    value={transactionData.Amount}
                    decimals={2}
                    onChange={handleChange}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
                <CCol xs='4'>
                  <ComboBox
                    showButton
                    name='CategoriesId'
                    label='Category'
                    data={categoriesData}
                    value={categoriesData.find(
                      (category: any) => category.CodeId === transactionData.CategoriesId,
                    )}
                    handleChange={(e: any, newValue: any) =>
                      handleComboChange(e, newValue, 'CategoriesId')
                    }
                    classNames={{ input: 'input-field' }}
                    customClassNames={{ button: 'comboBoxInput', root: 'input-field-root' }}
                    renderOption={(option: any, { selected }: any) => (
                      <div className={selected ? 'combo-selected' : ''}>{option.Description}</div>
                    )}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='4' sm='4'>
                  <InputField
                    name='IsPosted'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Posted'
                  >
                    <CustomSwitch
                      name='IsPosted'
                      className='d-block'
                      value={transactionData.IsPosted}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
                <CCol xs='4' sm='4'>
                  <InputField
                    name='Date Posted'
                    type='mask'
                    mask={[/[0-1]/, /\d/, '-', /[0-9]/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                    label='Date Posted'
                    value={transactionData.DatePosted}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol xs='4' sm='4'>
                  <InputField
                    name='Reference No'
                    type='text'
                    label='Reference #'
                    value={transactionData.ReferenceNo}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Cashback'
                    disabled={true}
                    type='currency'
                    alignment='right'
                    label='Cashback'
                    decimals={2}
                    value={transactionData.Cashback}
                    showSeparator
                    placeholder='$0.00'
                  />
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
          <CCol xs='6'>
            <FieldsSection header='Merchant Info'>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Name'
                    type='text'
                    label='Merchant Name'
                    value={transactionData.MerchantName}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Image Url'
                    type='text'
                    label='Merchant Image Url'
                    value={transactionData.MerchantImageUrl}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='MerchantPhone'
                    type='phone'
                    label='Merchant Phone Number'
                    value={transactionData.MerchantPhone}
                    onChange={handlePhoneChange}
                  />
                </CCol>
              </CFormGroup>
              <AddressFinderAux
                name=''
                address={{
                  Address: MerchantAddress,
                  Address2: MerchantAddress2,
                  City: MerchantCity,
                  State: MerchantState,
                  ZipCode: MerchantZipCode,
                }}
                handleChange={handleChange}
                handleAddressDataChange={handleAddressChange}
              />
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            // setData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default EditMemberTransactionsModal
