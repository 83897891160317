import { CButton, CCard, CCardBody, CCardHeader } from '@coreui/react'
import { DataTable } from 'library-soft-datatable'
import { useRef, useState } from 'react'
import { getAuthToken } from 'src/services/authHelper'
import {
  BASE_API,
  TEMPLATES_MERCHANTS_MODELS,
  TEMPLATES_MERCHANTS_ID,
  MODELS,
} from 'src/services/routeConst'
import { routeToPage } from 'src/services/routingHelper'
import { DeleteModal } from 'src/shared'
import { TableColumns } from './MerchantTableColumns'

const ModelSearch: React.FC<any> = ({ history, modelName = TEMPLATES_MERCHANTS_MODELS }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const clickedIDRef = useRef()
  const title = modelName.match(/[A-Z][a-z]+|[0-9]+/g)!.join(' ')
  const tableRef = useRef<any>()

  const viewDetails = ({ id }: any) => {
    routeToPage(history, `/Template/Merchant/Detail/${id}`)
  }

  const showDeleteModalFunc = ({ id }: any) => {
    clickedIDRef.current = id
    setShowDeleteModal(true)
  }

  const actionFunctions: any = {
    edit: viewDetails,
    delete: showDeleteModalFunc,
  }

  return (
    <CCard>
      <CCardHeader className='d-flex align-items-center card-accent-secondary'>
        {title} Search
        <CButton
          className='mr-1 ml-auto'
          type='button'
          color='primary'
          style={{ float: 'right' }}
          onClick={() => routeToPage(history, `/Template/Merchant/New`)}
        >
          New Data
        </CButton>
      </CCardHeader>
      <CCardBody>
        <DataTable
          columnData={TableColumns}
          theme='dark'
          noHeader
          endpoint={`${BASE_API}:${MODELS}`}
          modelName={modelName}
          idName={TEMPLATES_MERCHANTS_ID}
          actionClick={(type: any, data: any) => {
            actionFunctions[type]({ id: data })
          }}
          ref={tableRef}
          apiKey={getAuthToken('ADMIN')}
          scopedSlots={
            {
              /* AvatarUrl: ({ row }: any) => (
              <MemberAvatar
                name={row.original?.FirstName + ' ' + row.original?.LastName}
                avatar={row.original?.FilesId_Avatar}
                noChip
              />
            ), */
            }
          }
          paginationPosition='top-right'
        />
      </CCardBody>
      <DeleteModal
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        modalData={{ modelName, label: 'User', id: clickedIDRef.current }}
        callBack={() => tableRef.current.fetchNewData()}
      />
    </CCard>
  )
}

export default ModelSearch
