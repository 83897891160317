// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { useEffect, useRef } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'

const ErrorLogsInfo = ({ modelData, handleChange }) => {
  const { HttpErrorNo, Message, Source, StackTrace, ExecutionTime } = modelData
  const editorRef = useRef<any>()
  useEffect(() => {
    editorRef.current.beautifyCode()
  }, [])
  return (
    <FieldsSection header='ErrorLog Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='HttpErrorNo'
            type='text'
            disabled
            label='HttpErrorNo'
            value={HttpErrorNo}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='ExecutionTime'
            type='text'
            disabled
            label='ExecutionTime'
            value={ExecutionTime}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Message'
            type='text'
            label='Message'
            value={Message}
            disabled
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Source'
            type='text'
            label='Source'
            disabled
            value={Source}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol xs='12' sm='12'>
          Query:
          <CustomCodeEditor
            value={StackTrace}
            ref={editorRef}
            theme='dracula'
            mode='sqlserver'
            height='200px'
            readOnly={true}
          ></CustomCodeEditor>
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default ErrorLogsInfo
