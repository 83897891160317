import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField } from 'src/shared'
import { MembersRepository } from 'src/repository/admin/member/MembersRepository'
import { MEMBERS_ID } from 'src/services/routeConst'
// import AddressFinderAux from 'src/shared/inputFields/AddressFinderAux'
interface PropsNewSubAccountModal {
  showModal?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}
const NewSubAccountModal: React.FC<PropsNewSubAccountModal> = ({
  showModal = false,
  setShowModal,
  modalData,
  callBack = () => {},
}) => {
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<any>()
  const { id, MembersId, modelName } = modalData
  const fields = [
    'MembersId',
    'MembersId_Parent',
    'FirstName',
    'LastName',
    'Email',
    'Phone',
    'Address',
    'Address2',
    'City',
    'State',
    'ZipCode',
  ]

  const membersRepository = React.useMemo(() => new MembersRepository(), [])

  useEffect(() => {
    const getDataInEffect = async () => {
      try {
        const { mainData } = await membersRepository.getData({
          id,
          modelName: modelName,
          defaultMainData: { MembersId_Parent: MembersId, MembersId: 0 },
        })
        setData(mainData)
      } catch (error: any) {
        console.error(error)
      }
    }
    loadingData && getDataInEffect()
  }, [MembersId, id, loadingData, membersRepository, modelName])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    data && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    const dataToSave: any = data
    try {
      await membersRepository.saveData({
        modelName: modelName,
        idName: MEMBERS_ID,
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
      })
    } catch (error: any) {
      console.error(error)
    }
    // setData({})
    callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setData((st: any) => ({ ...st, [name]: value }))
  }

  const handlePhoneChange = ({ name = '', value = '' }) => {
    setData((st: any) => ({
      ...st,
      [name]: value.replace(/[^0-9]/g, ''),
    }))
  }

  // const handleAddressChange = ({
  //   Address = '',
  //   Address2 = '',
  //   City = '',
  //   State = '',
  //   ZipCode = '',
  // }) => {
  //   console.log({ Address, Address2, City, State, ZipCode })
  //   setData((st: any) => ({
  //     ...st,
  //     Address,
  //     Address2,
  //     City,
  //     State,
  //     ZipCode,
  //   }))
  // }

  if (loadingData) {
    return null
  }
  // console.log(data)
  return (
    <CModal
      show={showModal}
      onClose={() => {
        // setData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} Member</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='Member Info'>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='FirstName'
                    type='text'
                    label='First Name'
                    value={data.FirstName}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='LastName'
                    type='text'
                    label='Last Name'
                    value={data.LastName}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12'>
                  <InputField
                    name='Phone'
                    type='phone'
                    label='Phone Number'
                    value={data.Phone}
                    onChange={handlePhoneChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Email'
                    type='text'
                    label='Email'
                    value={data.Email}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              {/* <AddressFinderAux
                address={{
                  Address: data.Address,
                  Address2: data.Address2,
                  City: data.City,
                  State: data.State,
                  ZipCode: data.ZipCode,
                }}
                handleChange={handleChange}
                handleAddressDataChange={({ value }) => handleAddressChange(value)}
              /> */}
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            // setData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default NewSubAccountModal
