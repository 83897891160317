import { useState, useEffect } from 'react';
import { BASE_API, LIST, MODELS, SERVICES} from 'src/services/routeConst'
// import { Keyboard, Mousewheel } from "swiper";
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MdCheckCircle } from "react-icons/md";
import { getAuthToken } from 'src/services/authHelper'
import SwiperCore, {
  Navigation
} from 'swiper';
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
type BreadcrumbsProps = {
    isCalled: boolean;
    actionClick: any;
    MetasId: any;
}
SwiperCore.use([Navigation]);
const BreadcrumbsComponent = ({ isCalled, actionClick, MetasId }: BreadcrumbsProps) => {

    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (isCalled) {
            setLoading(true);
            getBreadCrumbData();
        }
    }, [isCalled]);

    const getBreadCrumbData = () => {
        let token = getAuthToken('ADMIN')
        axios({
          method: 'POST',
          url: `${BASE_API}${SERVICES}${MODELS}${LIST}`,
          data: {
            Model: 'Notes',
            Extension: "Breadcrumbs",
            Params: [
              {
                Name: 'SearchString',
                Value: null,
              },
              {
                Name: 'OrderBy',
                Value: null,
              },
              {
                Name: 'OrderByDir',
                Value: null,
              },
              {
                Name: 'Page',
                Value: null,
              },
              {
                Name: 'PageSize',
                Value: null,
              },
              {
                Name: 'MetasId',
                Value: MetasId,
              },
            ],
          },
          headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
        }).then((res) => {
            if (res.data) {
                setLoading(false);
                setData(res.data.data);
            } else {
            }
        }).catch(() => {
            
        })
      }

    const renderSlides = data.map((element: any, key: any) => {
        return (
            <SwiperSlide onClick={() => { actionClick(element) }} key={key} style={(key === data.length - 1) ? { 'background': '#5c636a', 'color': 'white' } : {}}>
                {(key === data.length - 1) ? <MdCheckCircle size={25} style={(key === data.length - 1) ? { 'color': 'white' } : {}} className='swipper-icon'></MdCheckCircle> : null }
                {element.Notes}
                {/* <div className='swiper-icon-arrow-right'><MdKeyboardArrowRight size={30} ></MdKeyboardArrowRight></div> */}
            </SwiperSlide>
        );
    })

    return (

        <div className='breadcrumbes-container'>
            {loading ? <SwiperSlide>Loading...</SwiperSlide> :
                <Swiper
                    // navigation={true}
                    spaceBetween={20}
                    mousewheel={true}
                    grabCursor={true}
                    initialSlide={(data.length - 1)}
                    keyboard={{
                        enabled: true,
                    }}
                    slidesPerView={4}
                    breakpoints={{
                        640: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                    }}
                >
                    {renderSlides}
                </Swiper>
            }
        </div>
    )
}
export default BreadcrumbsComponent;