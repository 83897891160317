export const endpointsTableColumns = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: 'Method',
    name: 'HttpMethod',
  },
  {
    label: 'Is Enabled',
    name: 'Enabled',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'Auth Application',
    name: 'AuthApplication',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'Auth Member',
    name: 'AuthMember',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'Auth User',
    name: 'AuthUser',
    type: 'boolCheckbox',
    editType: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]

export const scenariosTableColumns = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
