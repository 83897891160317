// Generated with util/create-search-page.js
import { CCol, CRow } from '@coreui/react'
import { Loader } from 'src/shared'
import { HeaderComponent, InspectorInfo, PickUpInfo, DropOffInfo, OwnershipInfo, TitleInfo } from './components'
import { CUSTOMERS_MODEL } from 'src/services/routeConst'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'

const ModelsDetails = ({ modelName = CUSTOMERS_MODEL }) => {
  const id = 0
  const [apiData, status, saveData, handleChange, handleAddressChange] = useActionsModels({
    id,
    modelName,
  })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData } = apiData
  return (
    <>
      <CRow>
        <CCol>
          <InspectorInfo
            title={'Details'}
            modelData={modelData}
            handleChange={handleChange}
            handleAddressChange={handleAddressChange}
          />
          <PickUpInfo
            title={'Pick up'}
            modelData={modelData}
            handleChange={handleChange}
            handleAddressChange={handleAddressChange}
          />
          <DropOffInfo
            title={'Drop off'}
            modelData={modelData}
            handleChange={handleChange}
            handleAddressChange={handleAddressChange}
          />
        </CCol>
        <CCol>
          <OwnershipInfo 
            title={'OwnershipInfo'}
            modelData={modelData}
            handleChange={handleChange}
          />
          <TitleInfo 
            title={'TitleInfo'}
            modelData={modelData}
            handleChange={handleChange}
          />
        </CCol>
      </CRow>

      <HeaderComponent status={status} saveData={saveData} />
    </>
  )
}

export default ModelsDetails
