export const TableColumns = [
  /* "BanksId": 1,
            "Name": "test",
            "Address": "testing",
            "City": "New York",
            "State": "test",
            "ZipCode": "0000",
            "Phone": "000000000",
            "RoutingNumber": "testing123",
            "AccountType": "test", */

  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Address',
    name: 'Address',
  },
  {
    label: 'City',
    name: 'City',
  },
  {
    label: 'State',
    name: 'State',
  },
  {
    label: 'ZipCode',
    name: 'ZipCode',
  },
  {
    label: 'Phone',
    name: 'Phone',
  },
  {
    label: 'Routing Number',
    name: 'RoutingNumber',
  },
  {
    label: 'AccountType',
    name: 'AccountType',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
