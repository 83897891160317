import { useCallback, useEffect, useMemo, useState } from 'react'
import { StatusInfo } from '../enum/Status'
import { RootRepository } from 'src/repository/admin/rootRepository'
import { SaveModel } from 'src/services/modelNew/Save/Save'
import { toast } from 'react-toastify'

interface PropsActionsModels {
  id: any
  modelName: any
  history: any
  modelsId?: string
}
type ApiDataState = { modelData: any }
const fields: string[] = [
  'Id',
  'FirstName',
  'LastName',
  'PhoneMobile',
  'PhoneOther',
  'Address',
  'City',
  'State',
  'Zip',
  'PickupAddress',
  'PickupCity',
  'PickupState',
  'PickupZip',
  'DropoffAddress',
  'DropoffCity',
  'DropoffState',
  'DropoffZip	',
  'Vehicle',
  'VehicleVin',
  'VehicleMileage',
  'VehicleColor',
  'SourceName',
  'SourceCode',
  'SourceDetails',

  'InspectorsId',
  'InspectionDate',
  'InspectionTimeslot',
  
  'IsNeedFollowUp',
  'FollowUpDate',
]
export const useActionsModels = ({
  id,
  modelName,
  modelsId = 'Id',
}: PropsActionsModels): [ApiDataState, StatusInfo, () => Promise<void>, Function, Function, Function] => {
  const [apiData, setApiData] = useState<ApiDataState>({ modelData: null })
  const [status, setStatus] = useState<StatusInfo>(StatusInfo.loading)
  const rootRepository = useMemo<any>(() => new RootRepository(), [])

  const getData = useCallback<any>(async () => {
    try {
      const { mainData } = await rootRepository.getData({
        id,
        modelName,
        defaultMainData: { [modelsId]: 0 },
      })
      const dataResponse = mainData?.data ? mainData.data[0] : mainData
      setApiData({ modelData: dataResponse })
      setStatus(StatusInfo.idle)
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }, [rootRepository, id, modelName, modelsId])

  useEffect(() => {
    getData()
  }, [getData])

  const goBack = (delay = 0) => {
    setTimeout(() => {
      setApiData({ modelData: null })
      // history.goBack()
    }, delay)
  }
  const mappingFields = (apiData) => {
    const dataToSave: any = apiData.modelData
    return {
      ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
    }
  }
  const saveData = async (): Promise<any> => {
    setStatus(StatusInfo.saving)
    try {
      const { message, data } = await SaveModel({
        body: {
          Model: modelName,
          Params: mappingFields(apiData),
        },
      })
      toast.success(message)
      getData()
      goBack()
      setStatus(StatusInfo.idle)
      console.log('dataNewCustoemr',data.SaveInfo);
      
      return data.SaveInfo
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }
  const handleChangeMaxLength = ({ name = '', value = '' }) => {
    if(value.length<=17){
      name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
    }
  }
  const handleAddressChange = ({
    Address = '',
    Address2 = '',
    City = '',
    State = '',
    ZipCode = '',
  }) => {
    // console.log({ Address, Address2, City, State, ZipCode })
    setApiData((st: any) => ({
      ...st,
      modelData: { ...st.modelData, Address, Address2, City, State, ZipCode },
    }))
  }
  return [apiData, status, saveData, handleChange, handleChangeMaxLength, handleAddressChange]
}
