import { CFade } from '@coreui/react'
import { useEffect, useRef } from 'react'
import { Redirect, useHistory } from 'react-router'
// import { toast } from 'react-toastify'
// import { routeToPage } from 'src/services/routingHelper'
import { Loader } from 'src/shared'

const PostLogin = (props: any) => {
  const { loggedInUser } = props
  const history = useHistory()
  const loggRef = useRef<any>(loggedInUser)

  useEffect(() => {
    loggRef.current = loggedInUser
    // setTimeout(() => {
    //   if (!loggRef.current?.ADMIN_API_KEY) {
    //     routeToPage(history, '/login')
    //     toast.error(
    //       'There was an error logging you in. Please try again and if the issue persists, contact an Admin.',
    //     )
    //   }
    // }, 5000)
  }, [history, loggRef.current.ADMIN_API_KEY, loggedInUser])
  console.log(loggedInUser)
  if (typeof loggedInUser.ADMIN_API_KEY === 'undefined' || loggedInUser.ADMIN_API_KEY === null) {
    return (
      <CFade>
        <Loader fullScreen />
      </CFade>
    )
  }
  return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
}

export default PostLogin
