import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { APPLICATIONS_MODEL, BASE_API, DELETE, LOGIN, SERVICES } from 'src/services/routeConst'
interface PropsCreateApplication {
  body: any
}
export const DeleteApplication = async ({ body = {} }: PropsCreateApplication) => {
  //URL {api}/Token/Member/Generate/{type}

  try {
    const { data } = await axios.delete(
      `${BASE_API}${SERVICES}/${LOGIN}/${APPLICATIONS_MODEL}/${DELETE}`,
      {
        ...addAuthToHeader('ADMIN'),
        data: body,
      },
    )
    return {
      data: data.data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error generating Member `,
      error: error.response,
    })
  }
}
