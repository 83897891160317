import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, LOGIN, USERS, SERVICES, RETRIEVE } from 'src/services/routeConst'

interface Response {
  data?: any
  status?: string
  error?: string
  message?: string
}
interface Props {
  body: any
}

export const RetrieveUser = async ({ body }: Props): Promise<Response> => {
  try {
    const { Id } = body
    if (Id === 0 || Id === null || typeof Id === 'undefined') {
      return {
        data: {
          User: {
            Id: 0,
          },
        },
      }
    }
    const { data } = await axios.post(
      `${BASE_API}${SERVICES}/${LOGIN}/${USERS}/${RETRIEVE}`,
      {
        ...body,
      },
      { ...addAuthToHeader('ADMIN') },
    )

    return {
      data: data.data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error saving ${body.Model}!`,
      error: error.response,
    })
  }
}
