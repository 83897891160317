// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const AccidentHistoryInfo = ({ modelData, handleChange, title = 'Model Name' }: PropsInfoForm) => {
  const {
    HadAccident,
    AccidentRepaired,
    AccidentEstimate,
    AccidentRepairedCost,
    AccidentEstimatedCost,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField type='fancyswitch' name='HadAccident' label={`Had Accident`}>
            <CustomSwitch
              name='HadAccident'
              className='d-block'
              value={HadAccident}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.HadAccident })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField type='fancyswitch' name='AccidentRepaired' label={`Repaired`}>
            <CustomSwitch
              name='AccidentRepaired'
              className='d-block'
              value={AccidentRepaired}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.AccidentRepaired })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField type='fancyswitch' name='AccidentEstimate' label={`Estimate`}>
            <CustomSwitch
              name='AccidentEstimate'
              className='d-block'
              value={AccidentEstimate}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.AccidentEstimate })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='AccidentRepairedCost'
            type='currency'
            label='Repaired Cost'
            value={AccidentRepairedCost ?? ''}
            onChange={({ name, value }) => {
              handleChange({
                name,
                value: value.replaceAll('$', ''),
              })
            }}
            alignment='right'
          />
        </CCol>
        <CCol className='pr-lg-2 pl-lg-2'>
          <InputField
            name='AccidentEstimatedCost'
            type='currency'
            label='Estimated Cost'
            value={AccidentEstimatedCost ?? ''}
            onChange={({ name, value }) => {
              handleChange({
                name,
                value: value.replaceAll('$', ''),
              })
            }}
            alignment='right'
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default AccidentHistoryInfo
