import { useCallback, useEffect, useState } from 'react'
import { StatusInfo } from '../enum/Status'

import { toast } from 'react-toastify'
// import { SaveUser } from 'src/services/modelNew/User/SaveUser'
import { ListModel } from 'src/services/modelNew/List/List'
import { APPLICATIONS_MODEL, USERS_MODEL } from 'src/services/routeConst'
import { RetrieveUser } from 'src/services/modelNew/User/RetrieveUser'
import { SaveModel } from 'src/services/modelNew/Save/Save'

interface PropsActionsModels {
  id: any
  modelName?: any
  history?: any
  modelsId?: string
}
type ApiDataState = { modelData: any; appsData: any; avatarFile: any }
const fields: string[] = ['Id', 'FirstName', 'LastName', 'Phone', 'Email', 'RolesId', 'ApplicationsId']
export const useActionsModels = ({
  id,
  history,
}: PropsActionsModels): [ApiDataState, StatusInfo, () => Promise<void>, Function, Function] => {
  const [apiData, setApiData] = useState<ApiDataState>({
    modelData: null,
    appsData: null,
    avatarFile: null,
  })
  const [status, setStatus] = useState<StatusInfo>(StatusInfo.loading)
  // const rootRepository = useMemo<any>(() => new RootRepository(), [])
  const goBack = useCallback(
    (delay = 0) => {
      setTimeout(() => {
        setApiData({ modelData: null, appsData: null, avatarFile: null })
        history.goBack()
      }, delay)
    },
    [history],
  )
  const getData = useCallback<any>(async () => {
    try {
      const { data: appsData } = await ListModel({
        body: {
          Model: APPLICATIONS_MODEL,
          Extension: null,
          Params: {
            Page: 1,
            PageSize: 1000,
            SearchString: null,
            OrderBy: null,
            OrderByDir: null,
          },
        },
      })
      const { data } = await RetrieveUser({
        body: {
          Id: id,
        },
      })

      const dataResponse = data.User
      setApiData({ modelData: dataResponse, appsData, avatarFile: data.AvatarFile })
      setStatus(StatusInfo.idle)
    } catch (error) {
      goBack()
      toast.error('Error loading data')
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }, [goBack, id])

  useEffect(() => {
    getData()
  }, [getData])

  const mappingFields = (apiData) => {
    const dataToSave: any = apiData.modelData
    return {
      ...Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
    }
  }
  const saveData = async () => {
    setStatus(StatusInfo.saving)
    try {
      const { data } = await SaveModel({
        body: {
          Model: USERS_MODEL,
          Params: mappingFields(apiData),
        }
      })
      const { message } = await SaveModel({
        body: {
          Model: "Tokens",
          Params: {
            Id: data.SaveInfo.TokensId,
            Password: apiData.modelData.Password
          }
        },
      })
      // console.log(result);
      toast.success(message)
      // getData()
      // goBack()
      setStatus(StatusInfo.idle)
    } catch (error) {
      setStatus(StatusInfo.idle)
      console.error(error)
    }
  }

  const handleChange = ({ name = '', value = '' }) => {
    name &&
      setApiData((st: any) => ({
        ...st,
        modelData: { ...st.modelData, [name]: value },
      }))
  }
  const handleAddressChange = ({
    Address = '',
    Address2 = '',
    City = '',
    State = '',
    ZipCode = '',
  }) => {
    // console.log({ Address, Address2, City, State, ZipCode })
    setApiData((st: any) => ({
      ...st,
      modelData: { ...st.modelData, Address, Address2, City, State, ZipCode },
    }))
  }
  return [apiData, status, saveData, handleChange, handleAddressChange]
}
