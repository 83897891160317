import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const FilesTableColumns: ColumnData[] = [
  /* {
    label: 'ForGuid',
    name: 'ForGuid',
    dbType: 'varchar',
  }, */
  {
    label: 'Repository',
    name: 'Name',
  },
  {
    label: 'Folder',
    name: 'Address',
  },
  {
    label: 'FileNameOriginal',
    name: 'LastName',
  },
  {
    label: 'FileUrl',
    name: 'Nickname',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['view', 'delete'],
    sortable: false,
  },
]
