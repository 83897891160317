// Generated with util/create-search-page.js
import { CCol, CRow } from '@coreui/react'
import { Loader } from 'src/shared'
import {
  HeaderComponent,
  VehicleInfo,
  AccidentHistoryInfo,
  VehicleConditionsInfo,
  MechanicalIssuesInfo,
} from './components'
import { VEHICLES_MODEL } from 'src/services/routeConst'
import { StatusInfo } from './enum/Status'
import { useActionsModels } from './hooks/ActionsModels'

const ModelsDetails = ({ modelName = VEHICLES_MODEL }) => {
  const id = 0
  const [apiData, status, saveData, handleChange] = useActionsModels({
    id,
    modelName,
  })

  if (!apiData.modelData || status === StatusInfo.loading) {
    return <Loader fullScreen height='75vh' />
  }
  const { modelData } = apiData

  return (
    <>
      <CRow>
        <CCol xs='12' md='6'>
          <VehicleInfo title={'Vehicle info'} modelData={modelData} handleChange={handleChange} />
          <AccidentHistoryInfo
            title={'Accident History'}
            modelData={modelData}
            handleChange={handleChange}
          />
        </CCol>
        <CCol xs='12' md='6'>
          <VehicleConditionsInfo
            title={'General Vehicle Conditions'}
            modelData={modelData}
            handleChange={handleChange}
          />
          <MechanicalIssuesInfo
            title={'Mechanical Issues'}
            modelData={modelData}
            handleChange={handleChange}
          />
        </CCol>
      </CRow>

      <HeaderComponent
        modelName={modelName}
        onCancelHandle={() => {}}
        status={status}
        saveData={saveData}
      />
    </>
  )
}

export default ModelsDetails
