import { ColumnData } from 'library-soft-datatable'

export const TableColumns: ColumnData[] = [
  {
    label: 'Name',
    name: 'Name',
  },
  {
    label: 'Description',
    name: 'Description',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
