// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomCodeEditor from 'src/shared/inputFields/CustomCodeEditor'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
//
const ApisInfo = ({ modelData, handleChange }) => {
  const {
    Payload,
    ResponseInfo,
    Response,
    StampStart,
    StampEnd,
    ExecutionTime,
    IsError,
    SourceBrowser,
    SourceIp,
  } = modelData
  // console.log(beautify.beautify(Response, { format: 'json' }))

  return (
    <FieldsSection header='Api Info'>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='ResponseInfo'
            type='text'
            disabled
            label='ResponseInfo'
            value={ResponseInfo}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='StampStart'
            type='text'
            disabled
            label='StampStart'
            value={StampStart}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='StampEnd'
            type='text'
            disabled
            label='StampEnd'
            value={StampEnd}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='Executiontime'
            type='text'
            disabled
            label='Executiontime'
            value={ExecutionTime}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='IsError'
            disabled
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='IsError'
          >
            <CustomSwitch
              name='IsError'
              disabled={true}
              className='d-block'
              value={IsError}
              updateData={(name, value) => handleChange({ name, value })}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='SourceBrowser'
            type='text'
            label='Source Browser'
            disabled
            value={SourceBrowser}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          <InputField
            name='SourceIp'
            type='text'
            label='Source Ip'
            disabled
            value={SourceIp}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' sm='12'>
          {/* <InputField
            name='Payload'
            type='text'
            label='Payload'
            value={Payload}
            onChange={handleChange}
          /> */}
          Payload:
          <CustomCodeEditor
            value={Payload}
            mode='json'
            theme='dracula'
            height='200px'
            readOnly={true}
          ></CustomCodeEditor>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        Response
        <CCol xs='12' sm='12'>
          <CustomCodeEditor
            value={JSON.stringify(JSON.parse(Response), null, 2)}
            mode='json'
            theme='dracula'
            height='200px'
            readOnly={true}
          ></CustomCodeEditor>
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default ApisInfo
