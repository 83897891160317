import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { FieldsSection, InputField, CardMoney } from 'src/shared'
import { MembersRepository } from 'src/repository/admin/member/MembersRepository'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
import { TEMPLATES_CARDS_ID } from 'src/services/routeConst'
import { RadioGroup } from 'smart-inputs'

interface PropsEditMemberCardsModal {
  showModal?: any
  setShowModal?: any
  modalData?: any
  callBack?: any
}

const fields = [
  'CardsId',
  'UsersId',
  'AsOfDate',
  'Number',
  'Cvv',
  'Style',
  'Color',
  'Name',
  'Expiration',
  'IsSecuredCard',
]

const CardsModal: React.FC<PropsEditMemberCardsModal> = ({
  showModal = false,
  setShowModal,
  modalData,
  callBack = () => {},
}: PropsEditMemberCardsModal) => {
  const [loadingData, setLoadingData] = useState(true)
  const [data, setData] = useState<any>()
  const { id, UsersId, modelName } = modalData

  const membersRepository = React.useMemo(() => new MembersRepository(), [])

  useEffect(() => {
    const getDataInEffect = async () => {
      try {
        const { mainData } = await membersRepository.getData({
          id,
          modelName: modelName,
          defaultMainData: { UsersId, CardsId: 0 },
        })
        setData(mainData)
      } catch (error: any) {
        console.error(error)
      }
    }
    loadingData && getDataInEffect()
  }, [UsersId, id, loadingData, membersRepository, modelName])

  const getData = useCallback(async () => {
    setLoadingData(true)
  }, [])

  useEffect(() => {
    data && loadingData && setLoadingData(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const useEffectTS: any = useEffect
  useEffectTS(() => {
    let mounted = true
    mounted && showModal && getData()
    return () => (mounted = false)
  }, [showModal, getData])

  const saveData = async () => {
    setShowModal(false)
    const dataToSave: any = data
    try {
      await membersRepository.saveData({
        modelName: modelName,
        idName: TEMPLATES_CARDS_ID,
        dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: dataToSave[field] }))),
      })
    } catch (error: any) {
      console.error(error)
    }
    // setData({})
    callBack()
  }
  const toFirstUpperCase = (str = 'Black') => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  const handleChange = ({ name = '', value = '' }) => {
    name && setData((st: any) => ({ ...st, [name]: value }))
  }
  const colorCard = {
    rose: 'linear-gradient(360deg, #A56F70 -16.09%, #C3989A 33.43%, #F2CFCF 98.74%)',
    gold: 'linear-gradient(360deg, #926544 -16.09%, #FFDDAF 75.76%, #FFF9E9 100%)',
    black: 'linear-gradient(360deg, #282828 -14.33%, #333333 4.41%, #666666 69.71%, #B2B2B2 100%)',
    silver: ' linear-gradient(0deg, #666666 6.08%, #B2B2B2 100%)',
  }
  if (loadingData) {
    return null
  }
  // console.log(data)
  return (
    <CModal
      show={showModal}
      size='xl'
      onClose={() => {
        // setData({})
        setShowModal(false)
      }}
      color={id ? 'info' : 'success'}
    >
      <CModalHeader closeButton>
        <CModalTitle>{id ? 'Edit' : 'New'} Card</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='6'>
            <FieldsSection header='Card Info'>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='AsOfDate'
                    type='text'
                    disabled={true}
                    label='As Of Date'
                    value={data.AsOfDate}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField name='Name' label='Name' value={data.Name} onChange={handleChange} />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Number'
                    label='Number'
                    type='mask'
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ' ',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ' ',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      ' ',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    maskCharacter='#'
                    value={data.Number}
                    onChange={({ name, value }) => {
                      handleChange({ name, value: value.replaceAll(' ', '') })
                    }}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Cvv'
                    type='mask'
                    label='Cvv'
                    mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
                    maskCharacter=' '
                    value={data.Cvv}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>

              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Expiration'
                    label='Expiration'
                    type='mask'
                    mask={[
                      /[0-9]/,
                      /[0-9]/,
                      '-',
                      /[0-9]/,
                      /[0-9]/,
                      '-',
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                      /[0-9]/,
                    ]}
                    value={data.Expiration}
                    onChange={({ name, value }: { name: string; value: string }) => {
                      // console.log(params)
                      /* const currentDay = new Date()
                      const currentExpiration = value.split('/')
                      const day = currentDay.getDate()
                      const month = currentExpiration[0]
                      const year = currentExpiration[1] */
                      handleChange({ name, value })
                    }}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='IsSecuredCard'
                    type='fancyswitch'
                    labelStyles={{ marginBottom: 0 }}
                    label='Secured'
                  >
                    <CustomSwitch
                      name='IsSecuredCard'
                      className='d-block'
                      value={data.IsSecuredCard}
                      updateData={(name: any, value: any) => handleChange({ name, value })}
                    />
                  </InputField>
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
          <CCol xs='6'>
            <FieldsSection header='Card detail'>
              <CFormGroup row>
                <CCol xs='12' sm='12'>
                  <InputField
                    name='Style'
                    label='Style'
                    value={data.Style}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='2' sm='2'>
                  {/* <InputField
                    name='Color'
                    label='Color'
                    value={data.Color}
                    onChange={handleChange}
                  /> */}
                  <RadioGroup
                    name='Color'
                    label='Color'
                    color='#4638A5'
                    options={['Rose', 'Gold', 'Black', 'Silver']}
                    onChangeRadio={({ name, value }: { name: string; value: string }) => {
                      handleChange({ name, value: value.toLowerCase() })
                    }}
                    defaultSelected={toFirstUpperCase(data.Color)}
                    customClassNames={{ root: 'input-field', input: 'input-field-input' }}
                  />
                </CCol>
                <CCol xs='10' sm='10'>
                  <CardMoney background={colorCard[data.Color]} />
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color={id ? 'info' : 'success'} onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            // setData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default CardsModal
