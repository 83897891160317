import { CButton, CCardHeader } from '@coreui/react'
import React from 'react'
import LoadingButton from 'src/shared/components/LoadingButton'
interface PropsModelHeader {
  status?: any
  modelName?: any
  saveData?: any
  onCancel?: any
}
const ModelsHeader: React.FC<PropsModelHeader> = ({ status, modelName, saveData, onCancel }) => {
  const isLoading = status === 'saving'
  return (
    <CCardHeader className='d-flex align-items-center' style={{ backgroundColor: '#2A3446', marginBottom: '1rem', fontSize: 16, fontWeight: 600, borderRadius: 0 }}>
      <span style={{ color: 'white' }}>{modelName} Details</span>
      {/* <CButton
        className='mr-1 ml-auto'
        type='button'
        color='primary'
        style={{ float: 'right' }}
        disabled={savingDataRef.current}
        onClick={saveData}
      >
        Save
      </CButton> */}
      <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton>
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancel}
      >
        Cancel
      </CButton>
    </CCardHeader>
  )
}

export default ModelsHeader
