// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const ApptPickupInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const { 
    ApptPickupAddressCity,
    ApptPickupAddressState,
    ApptPickupAddressStreet,
    ApptPickupAddressZip,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel
            label='City'
            value={ApptPickupAddressCity ?? '⠀'}
          />
          <InputLabel
            label='State'
            value={ApptPickupAddressState ?? '⠀'}
          />
          <InputLabel
            label='Street'
            value={ApptPickupAddressStreet ?? '⠀'}
          />
          <InputLabel
            label='Zip'
            value={ApptPickupAddressZip ?? '⠀'}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default ApptPickupInfo
