// Generated with util/create-search-page.js
import { CCol } from '@coreui/react'
import { SmartNotes } from 'smart-notes'
import { getAccessToken, getAuthToken } from 'src/services/authHelper'
import { Note } from 'src/shared/components/Notes/Note'

// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  title?: string
  MetasId: any
  id?: any
}

const NotesInfo = ({ MetasId }: PropsInfoForm) => {
  return (
    <div
      style={{
        backgroundColor: '#0000',
        padding: '10px',
      }}
    >
      <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
      <SmartNotes
          metasId={MetasId}
          auth={getAuthToken()}
          accessToken={getAccessToken()}
          placeholder='Enter a note...'
          classNames={{
            input: 'input-field',
            root: 'input-field-root',
          }}
          renderNotes={(notes) => {
            return <Note notes={notes} />
          }}
        />
      </CCol>
    </div>
  )
}

export default NotesInfo
