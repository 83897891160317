// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const OwnershipInfo = ({ modelData, handleChange }: PropsInfoForm) => {
  const { IsTitleInHand, FinalOffer, DueCustomer, PaymentMethod } = modelData
  return (
    <FieldsSection header={'Owner ship Info'}>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField type='fancyswitch' name='IsTitleInHand' label={`Is Title In Hand`}>
            <CustomSwitch
              name='IsTitleInHand'
              className='d-block'
              value={IsTitleInHand}
              updateData={(name: any) => {
                handleChange({ name, value: !modelData.IsTitleInHand })
                handleChange({ name: 'PagePath', value: '' })
              }}
            />
          </InputField>
        </CCol>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='FinalOffer'
            type='number'
            label='Final Offer'
            value={FinalOffer ?? ''}
            onChange={handleChange}
            alignment='right'
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='DueCustomer'
            type='currency'
            label='Due Customer'
            value={DueCustomer ?? ''}
            onChange={({ name, value }) => {
              handleChange({
                name,
                value: value.replaceAll('$', ''),
              })
            }}
            alignment='right'
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='PaymentMethod'
            type='text'
            label='Payment Method'
            value={PaymentMethod ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default OwnershipInfo
