import { CFormGroup } from '@coreui/react'
import React from 'react'
import { FileUpload } from 'src/services/modelNew/File/Upload'
import { FieldsSection, Cropper } from 'src/shared'
interface PropsUserProfilePicture {
  modelData?: any
  updateProfilePictureID?: any
}
const TokensAvatarPicture: React.FC<PropsUserProfilePicture> = ({
  modelData,
  updateProfilePictureID,
}) => {
  const uploadFile = async (file: any) => {
    // console.log('UPLOAD FLIE', file)
    const { data, error } = await FileUpload({
      modelName: 'Tokens',
      id: modelData.TokensId,
      FilesId: 0,
      fileToUpload: file,
    })
    !error && data && data.FilesId && updateProfilePictureID(data.FilesId)
  }

  return (
    <>
      {modelData.TokensId !== 0 && (
        <FieldsSection header='Token Picture'>
          <CFormGroup row className='justify-content-center'>
            {/*  src={userData.profile_picture}  */}
            {/** cropShape={'rect' | 'round'} */}
            <Cropper
              cropShape={'round'}
              maxSizeMib={3}
              guid={modelData.FileGuid}
              onChangeCropper={({ originalFile }) => {
                uploadFile(originalFile)
              }}
            />
          </CFormGroup>
        </FieldsSection>
      )}
    </>
  )
}

export default React.memo(TokensAvatarPicture)
