import { ColumnData } from 'library-soft-datatable'

export const FilesTableColumns: ColumnData[] = [
  /* {
    label: 'ForGuid',
    name: 'ForGuid',
    dbType: 'varchar',
  }, */
  {
    label: 'Repository',
    name: 'Repository',
  },
  {
    label: 'Folder',
    name: 'Folder',
  },
  {
    label: 'FileNameGuid',
    name: 'FileNameGuid',
  },

  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
