import { ColumnData } from 'library-soft-datatable'

export const TableColumns: ColumnData[] = [
  {
    label: 'First Name',
    name: 'CustFirstName',
  },
  {
    label: 'Last Name',
    name: 'CustLastName',
  },
  {
    label: 'Phone',
    name: 'CustPhoneMobile',
  },
  {
    label: 'Vehicle',
    name: 'Vehicle',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    customWidth: 10,
    sortable: false,
  },
]
