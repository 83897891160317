import {
  CButton,
  CCol,
  CFormGroup,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from '@coreui/react'
import React from 'react'
import { UsersRepository } from 'src/repository/admin/user/UsersRepository'
import { USERS_MODEL } from 'src/services/routeConst'
import { FieldsSection, InputField } from 'src/shared'

const SmsDetailModal = ({ UsersId, showModal, setShowModal }: any) => {
  const [userData, setUserData] = React.useState<any>({})

  const usersRepository = React.useMemo(() => new UsersRepository(), [])
  const fields = ['UsersId', 'UserName', 'FirstName', 'LastName', 'Phone', 'Email']

  const getData = React.useCallback(async () => {
    const { mainData } = await usersRepository.getData({ id: UsersId, modelName: 'Users' })
    setUserData(mainData)
  }, [UsersId, usersRepository])

  React.useEffect(() => {
    showModal && getData()
  }, [getData, showModal])

  const saveData = async () => {
    setShowModal(false)
    await usersRepository.saveData({
      modelName: USERS_MODEL,
      idName: 'UsersId',
      dataToSave: Object.assign({}, ...fields.map((field) => ({ [field]: userData[field] }))),
    })
    //After save, need to update data in other places for example if the user changes their info while viewing the
    //users table, the users table needs to refresh to reflect the change... similarly with changelogs etc...
    setUserData({})
    //  callBack()
  }

  const handleChange = ({ name = '', value = '' }) => {
    name && setUserData((st: any) => ({ ...st, [name]: value }))
  }

  const handlePhoneChange = ({ name = '', value = '' }) => {
    name && setUserData((st: any) => ({ ...st, [name]: value.replace(/[^0-9]/g, '') }))
  }

  return (
    <CModal
      show={showModal}
      onClose={() => {
        setUserData({})
        setShowModal(false)
      }}
      color='info'
    >
      <CModalHeader closeButton>
        <CModalTitle>Edit Profile</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CRow>
          <CCol xs='12'>
            <FieldsSection header='User Info'>
              <CFormGroup row>
                {/* <CCol xs='12' sm='4'>
                  <InputField
                    name='UserName'
                    type='username'
                    label='User Name'
                    value={userData.UserName}
                    onChange={handleChange}
                  />
                </CCol> */}
                <CCol>
                  <InputField
                    name='FirstName'
                    type='text'
                    label='First Name'
                    value={userData.FirstName}
                    onChange={handleChange}
                  />
                </CCol>
                <CCol>
                  <InputField
                    name='LastName'
                    type='text'
                    label='Last Name'
                    value={userData.LastName}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12'>
                  <InputField
                    name='Phone'
                    type='phone'
                    label='Phone'
                    value={userData.Phone}
                    onChange={handlePhoneChange}
                  />
                </CCol>
              </CFormGroup>
              <CFormGroup row>
                <CCol xs='12'>
                  <InputField
                    name='Email'
                    type='email'
                    label='Email'
                    value={userData.Email}
                    onChange={handleChange}
                  />
                </CCol>
              </CFormGroup>
            </FieldsSection>
          </CCol>
        </CRow>
      </CModalBody>
      <CModalFooter>
        <CButton color='info' onClick={saveData}>
          Save
        </CButton>
        <CButton
          color='secondary'
          onClick={() => {
            setUserData({})
            setShowModal(false)
          }}
        >
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  )
}

export default SmsDetailModal
