// Generated with util/create-search-page.js
import { CButton, CCardHeader } from '@coreui/react'
import LoadingButton from 'src/shared/components/LoadingButton'
interface propsHeader {
  status?: any
  saveData?: any
  onCancel?: any
}
const ProcsHeader: React.FC<propsHeader> = ({ status, saveData, onCancel }) => {
  const isLoading = status === 'saving'
  return (
    <CCardHeader className='d-flex align-items-center card-accent-secondary'>
      Procedure Details
      <LoadingButton
        className='mr-1 ml-auto'
        style={{ float: 'right' }}
        loading={isLoading}
        onClick={saveData}
      >
        Save
      </LoadingButton>
      <CButton
        className='mr-2'
        type='button'
        color='secondary'
        style={{ float: 'right' }}
        onClick={onCancel}
      >
        Cancel
      </CButton>
    </CCardHeader>
  )
}

export default ProcsHeader
