import { CCol, CFormGroup } from '@coreui/react'
import React from 'react'
import { FieldsSection, InputField } from 'src/shared'
import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsMemberSettings {
  memberData?: any
  handleChange?: any
  handleSwitchChange?: any
}
const MemberSettings: React.FC<PropsMemberSettings> = ({
  memberData = {},
  handleChange,
  handleSwitchChange,
}) => {
  // console.log(memberData)
  const {
    SettingAllowPush,
    SettingAutoPay,
    SettingPaperless,
    SettingFrozen,
    SettingPaymentDay,
    SettingAutoPayType,
    SettingAutoPayAmount,
    IsSecuredCard,
    BanksId_SettingAutoPay,
    PlaidAccessToken,
    ExperianNumberLatePay,
    ExperianCreditUtilPercent,
    ExperianNoOfInquiries,
    ExperianAccountAvgAge,
    ExperianCreditMixRating,
    ExperianCreditScore,
  }: any = memberData
  return (
    <FieldsSection header='Member Settings'>
      <CFormGroup row>
        <CCol xs='12' lg='3' className='pr-lg-1'>
          <InputField
            name='SettingAllowPush'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Push Notifications'
          >
            <CustomSwitch
              name='SettingAllowPush'
              className='d-block'
              value={SettingAllowPush}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
        <CCol xs='12' lg='3' className='my-3 my-lg-0 pr-lg-1'>
          <InputField
            name='SettingAutoPay'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Autopay'
          >
            <CustomSwitch
              name='SettingAutoPay'
              className='d-block'
              value={SettingAutoPay}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
        <CCol xs='12' lg='3' className='pr-lg-2'>
          <InputField
            name='SettingPaperless'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Paperless'
          >
            <CustomSwitch
              name='SettingPaperless'
              className='d-block'
              value={SettingPaperless}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
        <CCol xs='12' lg='3' className='pr-lg-3 mb-3 my-lg-0 pl-lg-2'>
          <InputField
            name='SettingFrozen'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0 }}
            label='Card Frozen'
          >
            <CustomSwitch
              name='SettingFrozen'
              className='d-block'
              value={SettingFrozen}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        {/* <CCol xs='12' lg='8' className='pl-lg-0 my-3 my-lg-0'> */}
        <CCol xs='12' lg='3' className='pr-lg-1'>
          <InputField
            name='SettingPaymentDay'
            type='text'
            label='Payment Day'
            value={SettingPaymentDay}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3' className='my-3 my-lg-0 pr-lg-1'>
          <InputField
            name='SettingAutoPayType'
            type='text'
            label='AutoPay Type'
            value={SettingAutoPayType}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3' className='pr-lg-2'>
          <InputField
            name='SettingAutoPayAmount'
            type='currency'
            alignment='right'
            label='AutoPay Amount'
            value={SettingAutoPayAmount}
            decimals={2}
            onChange={handleChange}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>
        <CCol xs='12' lg='3' className='pr-lg-3 mb-3 my-lg-0 pl-lg-2'>
          <InputField
            name='IsSecuredCard'
            type='fancyswitch'
            labelStyles={{ marginBottom: 0, marginLeft: '3px' }}
            label='Is Secured Card'
          >
            <CustomSwitch
              name='IsSecuredCard'
              className='d-block'
              value={IsSecuredCard}
              updateData={handleSwitchChange}
            />
          </InputField>
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='3' className='pr-lg-1'>
          <InputField
            name='BanksId_SettingAutoPay'
            type='text'
            label='BanksId Setting AutoPay'
            value={BanksId_SettingAutoPay}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3' className='my-3 my-lg-0 pr-lg-1'>
          <InputField
            name='PlaidAccessToken'
            type='text'
            label='Plaid Access Token'
            value={PlaidAccessToken}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3' className='pr-lg-2'>
          <InputField
            name='ExperianNumberLatePay'
            type='text'
            label='Experian Number Late Pay'
            value={ExperianNumberLatePay}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3' className='pr-lg-3 mb-3 my-lg-0 pl-lg-2'>
          <InputField
            name='ExperianCreditUtilPercent'
            type='currency'
            alignment='right'
            label='Experian Credit Util Percent'
            value={ExperianCreditUtilPercent}
            onChange={handleChange}
            decimals={2}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol xs='12' lg='3' className='pr-lg-1'>
          <InputField
            name='ExperianNoOfInquiries'
            type='text'
            label='Experian No Of Inquiries'
            value={ExperianNoOfInquiries}
            onChange={handleChange}
          />
        </CCol>
        <CCol xs='12' lg='3' className='my-3 my-lg-0 pr-lg-1'>
          <InputField
            name='ExperianAccountAvgAge'
            type='currency'
            alignment='right'
            label='Experian Account Avg Age'
            value={ExperianAccountAvgAge}
            onChange={handleChange}
            decimals={2}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>
        <CCol xs='12' lg='3' className='pr-lg-2'>
          <InputField
            name='ExperianCreditMixRating'
            type='currency'
            alignment='right'
            label='Experian Credit Mix Rating'
            value={ExperianCreditMixRating}
            onChange={handleChange}
            decimals={2}
            showSeparator
            placeholder='$0.00'
          />
        </CCol>
        <CCol xs='12' lg='3' className='pr-lg-3 mb-3 my-lg-0 pl-lg-2'>
          <InputField
            name='ExperianCreditScore'
            type='text'
            label='Experian Credit Score'
            value={ExperianCreditScore}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}
export default React.memo(MemberSettings)
