import { ColumnData } from 'library-soft-datatable'

// Generated with util/create-search-page.js
export const ProcsTestTableColumns: ColumnData[] = [
  {
    label: 'ProcsId',
    name: 'ProcsId',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'Params',
    name: 'Params',
  },
  {
    label: 'TimeStampStart',
    name: 'TimeStampStart',
  },
  {
    label: 'TimeStampEnd',
    name: 'TimeStampEnd',
  },
  {
    label: 'Executiontime',
    name: 'Executiontime',
    type: 'number',
    alignment: 'center',
  },
  {
    label: 'IsError',
    name: 'IsError',
    type: 'boolCheckbox',
    alignment: 'center',
  },
  {
    label: 'ErrorMessage',
    name: 'ErrorMessage',
  },
  {
    label: 'ErrorStackTrace',
    name: 'ErrorStackTrace',
  },
  {
    label: '',
    name: 'actionData',
    alignment: 'right',
    type: 'action',
    actions: ['edit', 'delete'],
    sortable: false,
  },
]
