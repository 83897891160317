// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection } from 'src/shared'
import { InputLabel } from 'src/shared/inputFields/InputLabel'
interface PropsInfoForm {
  modelData: any
  handleChange: any
  title: string
}

const TitleInfo = ({ modelData, title = 'Model Name' }: PropsInfoForm) => {
  const { 
    TitleNumber,
    TitleDualOwnershipStatus,
    TitleRegisteredOwner1,
    TitleRegisteredOwner2,
    TitleRegistrationValidThrough,
    TitleLienInfo,
    TitleLienHolder,
    TitleTitleType,
    TitleTitleStatus,
  } = modelData
  return (
    <FieldsSection header={title}>
      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputLabel 
            label={'Number'}
            value={TitleNumber ?? '⠀'}
          />
          <InputLabel
            label='Dual Ownership Status'
            value={TitleDualOwnershipStatus ?? '⠀'}
          />
          <InputLabel
            label='Registered Owner 1'
            value={TitleRegisteredOwner1 ?? '⠀'}
          />
          <InputLabel
            label='Registered Owner 2'
            value={TitleRegisteredOwner2 ?? '⠀'}
          />
          <InputLabel
            label='Registration Valid Through'
            value={TitleRegistrationValidThrough ?? '⠀'}
          />
          <InputLabel
            label='Lien Info'
            value={TitleLienInfo ?? '⠀'}
          />
          <InputLabel
            label='Lien Holder'
            value={TitleLienHolder ?? '⠀'}
          />
          <InputLabel
            label='Type'
            value={TitleTitleType ?? '⠀'}
          />
          <InputLabel
            label='Status'
            value={TitleTitleStatus ?? '⠀'}
          />
        </CCol>
      </CFormGroup>
    </FieldsSection>
  )
}

export default TitleInfo
