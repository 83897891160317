import axios from 'axios'
import { APIError } from 'src/services/API/APIError'
import { addAuthToHeader } from 'src/services/authHelper'
import { BASE_API, MODELS, SAVE, SERVICES } from 'src/services/routeConst'

interface RequestBody {
  Model: string
  Extension?: any
  Params: Object
}
interface Props {
  idName?: string
  body: RequestBody
}
const mapRequestData = (idName: string, saveData: Object): Object[] => {
  let dataToSave: Object[] = []
  Object.keys(saveData ?? {}).forEach((e) => {
    if (e === idName) {
      return
    }
    dataToSave.push({
      Name: e,
      Value: saveData[e],
    })
  })
  return dataToSave
}
export const SaveModel = async ({ idName = 'Id', body }: Props) => {
  try {
    const dataToSave = mapRequestData(idName, body?.Params ?? {})
    const Id =
      typeof body?.Params['Id'] === 'undefined' || body?.Params['Id'] === null
        ? 0
        : body?.Params['Id']
    const { data } = await axios.patch(
      `${BASE_API}${SERVICES}${MODELS}/${SAVE}`,
      {
        [idName]: Id,
        Model: body.Model,
        Extensions: body.Extension ?? null,
        Params: dataToSave,
      },
      { ...addAuthToHeader('ADMIN') },
    )
    return {
      data: data.data,
      status: 'success',
      error: data.error,
      message: data.info,
    }
  } catch (error: any) {
    throw new APIError({
      status: 'error',
      message: `Error saving ${body.Model}!`,
      error: error.response,
    })
  }
}
