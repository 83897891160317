import { CCol, CFormGroup } from '@coreui/react'
// import { RadioGroup, Radio } from 'react-radio-input'

import { DnServicesInfo, LeadInfo, VehicleInfo } from "./components";

// import CustomSwitch from 'src/shared/inputFields/CustomSwitch'
interface PropsInfoForm {
  modelData?: any
  handleChange: any
  title?: string
}
// const RadioGroupTs: any = RadioGroup
const LeadsInfo = ({ modelData, handleChange }: PropsInfoForm) => {
  // const mapComboToData = () =>
  console.log(modelData);
  
  return (
    <>
    <CFormGroup row>
      <CCol className='pr-lg-2 pl-lg-2 mb-1'>
        <LeadInfo title='General Info' modelData={modelData} handleChange={handleChange} />
      </CCol>
    </CFormGroup>
    <CFormGroup row>
      <CCol className='pr-lg-2 pl-lg-2 mb-1'>
        <VehicleInfo title='Vehicle' modelData={modelData} handleChange={handleChange}/>
      </CCol>
    </CFormGroup>
    <CFormGroup row>
      <CCol className='pr-lg-2 pl-lg-2 mb-1'>
        <DnServicesInfo title='Services' modelData={modelData} handleChange={handleChange}/>
      </CCol>
    </CFormGroup>
    </>
  )
}

export default LeadsInfo