import React from 'react'
// import { FieldsSection } from 'src/shared'
import AddressFinder from 'src/shared/inputFields/AddressFinder'
interface PropsMemberAddress {
  modelData: any
  handleChange: any
  handleAddressChange?: any
  modelName?: string
}
const AddressComponent = ({
  modelName = '',
  modelData,
  handleChange,
  handleAddressChange,
}: /* handleAddressChange, */
PropsMemberAddress) => {
  const mapModelNameToObject = (data: any): Object => {
    const addressValue = {}
    Object.keys(data?.value ?? ({} as Object)).forEach((key) => {
      addressValue[`${modelName}${key}`] = data.value[key]
    })
    return addressValue
  }
  return (
    <AddressFinder
      modelName={modelName}
      address={{
        Address: modelData[`${modelName}Address`] ?? '',
        City: modelData[`${modelName}City`] ?? '',
        State: modelData[`${modelName}State`] ?? '',
        Zip: modelData[`${modelName}Zip`] ?? '',
      }}
      handleAddressDataChange={(data: any) => {
        const addressValue = mapModelNameToObject(data)
        handleAddressChange({ ...addressValue })
      }}
      handleChange={(data: any) => {
        handleChange({ ...data, name: `${modelName}${data.name}` })
      }}
    />
  )
}
export default React.memo(AddressComponent)
