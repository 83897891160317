import React, { useState } from 'react'
// import { Redirect, useLocation } from 'react-router-dom'
import { CCard, CCardBody, CCardGroup, CCol, CContainer, CRow } from '@coreui/react'
import Image from '../../assets/img/background_login.png'
import { connect } from 'react-redux'
import { setUser } from 'src/redux/actions/authActions'
// import axios from 'axios'
// import { BASE_API } from 'src/services/routeConst'
import { useMsal } from '@azure/msal-react'
import MicrosoftLoginButton from './MicrosoftLoginButton'
import { toast } from 'react-toastify'

// import { toast } from 'react-toastify'
// import { APIError } from 'src/services/API/APIError'
// import { login } from 'src/services/authHelper'

// const loginError = (error) =>
//   new APIError({
//     status: 'error',
//     message: `Failed to communicate with authentication server`,
//     error: error.response,
//   })

const Login: React.FC<any> = () => {
  const { instance } = useMsal()
  // const { state } = useLocation()
  const [loggingIn, setLoggingIn] = useState(false)
  const handleLogin = () => {
    setLoggingIn(true)

    try {
      instance.loginRedirect()
    } catch (error: any) {
      setLoggingIn(false)
      toast.error(error.message)
    }
  }
  // const isSignedIn = instance.getAllAccounts()?.length > 0
  return (
    <div
      style={{
        backgroundImage: `url('${Image}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
      className='c-app c-dark-theme c-default-layout flex-row align-items-center'
      // onKeyUp={(e) => e.key === 'Enter' && login()}
    >
      <CContainer>
        <CRow className='justify-content-center'>
          <CCol md='8'>
            <CCardGroup>
              <CCard
                className='text-white bg-transparent  py-5 d-md-down-none  '
                style={{
                  width: '40%',
                  borderRight: '0.4px solid rgba(255,255,255, 0.4)',
                }}
              >
                <CCardBody className='d-flex justify-content-center align-content-center'>
                  <img className='img-fluid w-75' src='/logo.png' alt='logo' />
                </CCardBody>
              </CCard>
              <CCard className='pl-4 bg-transparent'>
                <CCardBody>
                  <h1 className='mb-0'>Login</h1>
                  <p className='text-muted'>Sign In to your account</p>
                  <CRow>
                    <CCol xs='12'>
                      <MicrosoftLoginButton disabled={loggingIn} onClick={handleLogin} />
                      {/* <p className='text-center mt-2' style={{ width: 215 }}>
                        {isSignedIn ? 'Logging in...' : ''}
                      </p> */}
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    loggedInUser: state.loggedInUser,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    setUser: (data: any) => dispatch(setUser(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
