import { FILES_MODEL } from './../../../../../services/routeConst'
import { WidgetFile } from './../ModalSelectImage'
import { useCallback, useEffect, useState } from 'react'
import { SearchModel } from 'src/services/modelNew/Search/Search'
import { ALERTIMAGEDOWNLOAD } from 'src/services/routeConst'
import DefaultFile from 'src/assets/img/default-file.png'
export const useGetImagesFiles = (metasId): any => {
  const [files, setFiles] = useState<WidgetFile[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const filesToBase64Request = useCallback(async (files: WidgetFile[]): Promise<WidgetFile[]> => {
    let base64Images: WidgetFile[] = []
    if (files.length) {
      for (let index = 0; index < files.length; index++) {
        const url = files[index].Description?.includes('image')
          ? `${ALERTIMAGEDOWNLOAD}/${files[index].FilesId}`
          : DefaultFile
        base64Images.push({ ...files[index], url: url })
      }
      return base64Images
    }
    return []
  }, [])
  const dowloadFiles = useCallback(async () => {
    setIsLoading(true)
    const { data } = await SearchModel({
      modelName: FILES_MODEL,
      data: {
        Page: 1,
        PageSize: 100,
        Extention: 'ByMetasId',
        MetasId: metasId,
      },
    })
    const listFields: WidgetFile[] = data ?? []
    const imagesArray = await filesToBase64Request(listFields)
    setFiles(imagesArray)
    setIsLoading(false)
  }, [filesToBase64Request, metasId])
  useEffect(() => {
    dowloadFiles()
  }, [dowloadFiles])
  return [files, isLoading, dowloadFiles, setIsLoading]
}
