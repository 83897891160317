// Generated with util/create-search-page.js
import { CCol, CFormGroup } from '@coreui/react'
import { FieldsSection, InputField } from 'src/shared'
// import AddressInfo from '../AddressComponent'

interface PropsInfoForm {
  modelData: any
  handleChange: any
  handleAddressChange: any
  title: string
}

const InspectorInfo = ({
  modelData,
  handleChange,
  title = 'Model Name',
  // handleAddressChange,
}: // handleAddressChange,
PropsInfoForm) => {
  return (
    <FieldsSection header={title} text={''}>
      <CFormGroup row>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='FirstName'
            type='text'
            label='First Name'
            value={modelData?.FirstName ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='LastName'
            type='text'
            label='Last Name'
            value={modelData?.LastName ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='4' className='pr-lg-2 pl-lg-2'>
          <InputField
            name='PhoneMobile'
            type='phone'
            label='Phone Mobile'
            value={modelData?.PhoneMobile ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>

      <CFormGroup row>
        <CCol md='12' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='Address'
            type='text'
            label='Address '
            value={modelData?.Address ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      <CFormGroup row>
        <CCol md='6' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='City'
            type='text'
            label='City'
            value={modelData?.City ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='3' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='State'
            type='text'
            label='State'
            value={modelData?.State ?? ''}
            onChange={handleChange}
          />
        </CCol>
        <CCol md='3' className='pr-lg-2 pl-lg-2 mb-1'>
          <InputField
            name='Zip'
            type='text'
            label='Zip'
            value={modelData?.Zip ?? ''}
            onChange={handleChange}
          />
        </CCol>
      </CFormGroup>
      {/* <AddressInfo
        modelData={modelData ?? {}}
        handleChange={handleChange}
        handleAddressChange={handleAddressChange}
      /> */}
    </FieldsSection>
  )
}

export default InspectorInfo
